<template>
  <div class="shadow skeleton">
    <v-card elevation="0" height="100%">
      <v-card-title class="justify-center">
        <h4 class="card-title">{{ title }}</h4>
      </v-card-title>

      <v-card-text>
        <div class="engagement-container" data-test-groupScore>
          <v-skeleton-loader type="button" class="skeleton-average" />
        </div>

        <div class="fast-comparison-container">
          <div class="participation-rate-container">
            <span class="rate-span">{{ rate }}</span>

            <v-skeleton-loader type="button" class="skeleton-average-b" />
          </div>

          <div class="line"></div>

          <div class="answers-rate-container">
            <span class="rate-span">{{ opinions }}</span>

            <v-skeleton-loader type="button" class="skeleton-average-b" />
          </div>
        </div>
      </v-card-text>

      <div class="footer-card-engagement">
        <div class="footer">
          <HowToBeginAndWatchAVideo videoType="climate.overallAverage" />
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
export default {
  components: {
    HowToBeginAndWatchAVideo,
  },
  data() {
    return {
      title: this.$t('engagementPage.cardAverageEngagement.cardTitle'),
      rate: this.$t('engagementPage.cardAverageEngagement.participationRate'),
      opinions: this.$t('engagementPage.cardAverageEngagement.answeredOpnions'),
    }
  },
}
</script>

<style src="../style.scss" lang="scss" scoped></style>
