<template>
  <div id="action-plan-page" data-test-action-plan-page class="page-container">
    <PageNavigation />
    <router-view :groupID="groupID" />
  </div>
</template>

<script>
import PageNavigation from './Partials/PageNavigation/PageNavigation.vue'

export default {
  name: 'ActionPlanPage',
  props: {
    groupID: {
      type: String,
      default: '',
    },
  },
  components: {
    PageNavigation,
  },
  created() {
    this.$store.commit('setCurrentTab', 'ActionPlanPage')
  },
}
</script>
