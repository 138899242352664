<template>
  <div class="stats hidden-sm-and-down" @click="$emit('click', value)">
    <div class="stats-icon" :class="{ active: active }">
      <i class="fi fi-br-stats mt-1"></i>
    </div>
    <label class="ml-2 stats-label" :class="{ active: active }">{{
      label
    }}</label>
  </div>
</template>

<script>
export default {
  name: 'BadgeReports',
  props: {
    value: String || Number,
    active: Boolean,
    label: String,
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
