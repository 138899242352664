<template>
  <SkeletonLoader v-if="loading" />
  <div v-else class="status" :class="_typeVariation + ' ' + _getShort">
    <div class="icon-wrapper" v-if="_variationIcon">
      <v-icon
        v-if="iconConfig.lib === 'material'"
        class="icon-container"
        size="14"
      >
        {{ _variationIcon }}
      </v-icon>
      <i
        v-else-if="iconConfig.lib === 'flaticon'"
        :class="['fi', _variationIcon]"
      ></i>
    </div>
    <span class="value" data-test-variation-value>
      {{ _variationValue }}
      <abbr v-if="variation != 0">{{ _getType }}</abbr>
    </span>
  </div>
</template>

<script>
import SkeletonLoader from '@/components/Variation/parts/SkeletonLoader'

export default {
  name: 'Variation',
  components: {
    SkeletonLoader,
  },
  props: {
    variation: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      default: '',
    },
    short: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inverted: Boolean,
    iconConfig: {
      type: Object,
      default: () => ({
        lib: 'material',
        positiveIcon: 'mdi-trending-up',
        negativeIcon: 'mdi-trending-down',
      }),
    },
    hideMinusSign: Boolean,
  },
  computed: {
    _typeVariation() {
      switch (true) {
        case this.variation < 0 && !this.inverted:
          return 'variation-error'
        case this.variation > 0 && !this.inverted:
          return 'variation-success'
        case this.variation < 0 && this.inverted:
          return 'variation-success variation-inverted'
        case this.variation > 0 && this.inverted:
          return 'variation-error variation-inverted'
        case !this.variation:
          return 'variation-empty'
        default:
          return 'variation-neutral'
      }
    },
    _variationIcon() {
      switch (true) {
        case this.variation < 0:
          return this.iconConfig.negativeIcon
        case this.variation > 0:
          return this.iconConfig.positiveIcon
        default:
          return ''
      }
    },
    _variationValue() {
      if (this.hideMinusSign) {
        return Math.abs(this.variation)
      }
      return this.variation
    },
    _getType() {
      if (this.type == 'pts') {
        return 'pts'
      } else if (this.type == 'percent') {
        return '%'
      } else if (this.type == 'min') {
        return this.$t('variation.minutes')
      } else if (!this.variation) {
        return '-'
      }
      return ''
    },
    _getShort() {
      const isShort = this.short == true

      switch (true) {
        case isShort && this.type == '':
          return 'short'
        case (isShort && this.type == 'pts') ||
          (isShort && this.type == 'percent'):
          return 'short pts'
        case isShort && this.type == 'min':
          return 'short min'
        default:
          return ''
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
