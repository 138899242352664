<template>
  <v-card
    id="engagement-recommendations"
    max-width="100%"
    class="engagement-wrapper"
  >
    <div class="engagement-wrapper-header">
      <div class="engagement-wrapper-header-container">
        <v-card-title class="engagement-wrapper__title">
          <div class="crown">
            <i class="fi fi-sr-crown"></i>
          </div>
          <p>
            {{ $t('engagementRecommendations.title') }}
          </p>
        </v-card-title>
        <div class="how-to-container">
          <HowToBeginAndWatchAVideo videoType="recommendations" />
        </div>
      </div>
      <v-card-subtitle class="engagement-wrapper__subtitle">{{
        $t('engagementRecommendations.subtitle')
      }}</v-card-subtitle>
    </div>
    <div class="skeleton">
      <v-skeleton-loader
        v-for="i in 5"
        :key="i"
        type="button"
        class="skeleton-box"
      />
    </div>
    <v-skeleton-loader type="button" class="skeleton-line" />
  </v-card>
</template>
<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
export default {
  components: {
    HowToBeginAndWatchAVideo,
  },
  props: {
    type: String,
    title: String,
  },
}
</script>
<style src="../style.scss" lang="scss" scoped></style>
