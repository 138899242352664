<template>
  <div id="avatar" role="img" class="avatar-box">
    <v-avatar
      data-test-avatar-component
      v-if="text || src || icon"
      :color="hasColor ? color : ''"
      :class="hasBorder ? 'bordered' : ''"
      :size="size"
      v-bind="avatarAttr"
    >
      <img
        data-test-avatar-component-image
        v-if="src && !error"
        :alt="alt"
        :src="src"
        @error="error = true"
        v-bind="displayAttr"
      />
      <span
        data-test-avatar-component-initials
        v-else-if="text"
        class="avatar-name"
        :style="{ fontSize: textSize }"
        v-bind="displayAttr"
        >{{ getInitials(text) }}</span
      >
      <i
        data-test-avatar-component-icon
        v-else-if="icon"
        :class="icon"
        class="mt-1"
        aria-hidden="true"
        v-bind="displayAttr"
      ></i>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: 'AvatarComponent',
  props: {
    text: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '28px',
    },
    icon: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    id: {
      default: '',
    },
    textSize: {
      type: String,
      default: '0.5rem',
    },
    avatarAttr: {
      type: Object,
      default: () => ({}),
    },
    displayAttr: {
      type: Object,
      default: () => ({}),
    },
    hasColor: {
      type: Boolean,
      default: true,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
      colors: [
        '#382560',
        '#253919',
        '#39168e',
        '#4a315a',
        '#6b2337',
        '#552680',
        '#623551',
        '#83271e',
        '#5d4329',
        '#723829',
        '#38428b',
        '#6033ab',
        '#813171',
        '#6726c2',
        '#8b3063',
        '#3c5f35',
        '#804748',
        '#735227',
        '#405889',
        '#964218',
        '#a3335d',
        '#6e518f',
        '#8c4091',
        '#4a6180',
        '#a42f9e',
        '#4c57ca',
        '#585ab4',
        '#286f6c',
        '#566f33',
        '#6351e6',
        '#46775b',
        '#806b27',
        '#c63762',
        '#4d7d28',
        '#cd3c43',
        '#c0428e',
        '#5572b7',
        '#c74855',
        '#a5681a',
        '#e0323c',
        '#de3821',
        '#94699b',
        '#ab4bea',
        '#e3395d',
        '#be605b',
        '#be4ad4',
        '#e4349d',
        '#ec357c',
        '#836cf1',
        '#d65d35',
        '#ee4914',
        '#a369db',
        '#e040c4',
        '#cf6577',
        '#5581f2',
        '#de3ce7',
        '#468ae0',
        '#e26159',
        '#529a67',
        '#a779d4',
        '#af80a0',
        '#d5775e',
        '#9c9434',
        '#db7a22',
        '#cf6ddb',
        '#8e8be8',
        '#48a3ba',
        '#639dcc',
        '#e5739e',
        '#749ebc',
        '#8da076',
        '#67a962',
        '#c78a9c',
        '#e273cb',
        '#b39678',
        '#60a0e3',
        '#d38d56',
        '#c887ce',
        '#ce8d8d',
        '#54abaf',
        '#56ae8b',
        '#d48f7e',
        '#cc956c',
        '#5ab540',
        '#a19bd7',
        '#af9dc8',
        '#b1a667',
        '#dc974a',
        '#d98fbc',
        '#6db3a1',
        '#4ebd62',
        '#96b252',
        '#ea971a',
        '#4ec12e',
        '#cba632',
        '#36bddc',
        '#9fba2a',
        '#49c890',
        '#37c8b7',
        '#5cd621',
      ],
    }
  },
  methods: {
    getInitials(newName) {
      return newName
        .split(' ')
        .map((name) => name.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('')
    },
    getColor() {
      let id = (this.id || '').toString()

      // Generating ID based on text if empty ID
      if (!id || !id.length) id = this.generateID(this.text)

      const newId = id
        .toString()
        .replace(/[^0-9]/g, '')
        .replace(/[^0-9]\w/g, (c) => c.charCodeAt(0).toString())

      return newId % 100
    },
    generateID(text) {
      if (!text || !text.length) return '0'

      const split = text.toString().split(' ')
      const first = split[0] || ''
      const last = split[1] || ''

      return `${this.generateCode(first, 3)}${this.generateCode(last, 3)}`
    },
    generateCode(str, length = 3) {
      let code = ''
      for (let i = 0; i < length; i++) {
        const char = str.charCodeAt(i)
        if (!char) break
        code += char.toString()
      }
      return code
    },
  },
  computed: {
    color() {
      if (!!this.src && !this.error) return

      return this.colors[this.getColor()]
    },
  },
  watch: {
    src() {
      this.error = false
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
