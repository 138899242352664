<template>
  <div class="d-flex flex-column">
    <div class="content" data-test-anonymity-explanation>
      <v-icon
        class="content-icon"
        color="neutral1"
        v-text="'fi-rr-incognito'"
      />
      <span
        data-test-survey-header-anon1
        class="content-text-stronger ms-1"
        v-text="`${$t('survey.header.anon')}`"
      />
      <span v-html="'&nbsp;'" />
      <span
        data-test-survey-header-anon2
        class="content-text"
        v-text="`${$t('survey.header.share')}`"
      />
      <span v-html="'&nbsp;'" />
      <a
        data-test-anonymity-details
        :href="$t('survey.header.detailsURL')"
        target="_blank"
        class="content-text-stronger content-text-link"
        v-text="`${$t('survey.header.details')}`"
      />
    </div>

    <div class="d-flex justify-center survey-header">
      <img
        @click="backToHome()"
        data-test-logo-teamculture
        class="survey-header__logo"
        :src="require('@/assets/images/logo.svg')"
        alt=""
      />

      <img
        @click="backToHome()"
        class="survey-header__logo--client"
        :src="clientLogo"
        alt=""
        data-test-logo-company
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyHeader',
  props: {
    clientLogo: {
      type: String,
    },
  },
  methods: {
    backToHome() {
      window.location = '/engagement/climate'
      // this.$router.push({ name: 'ClimatePage' })
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
