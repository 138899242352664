<template>
  <div class="d-flex align-center">
    <v-tooltip
      data-test-avatar-tooltip
      top
      :z-index="zIndex"
      :disabled="!tooltip"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-avatar
          data-test-avatar
          v-for="(item, index) of _max"
          :key="index"
          :size="size"
          :style="{ marginLeft: index ? `-${size / 2}px` : 0 }"
          class="content-avatar"
          v-bind="attrs"
          v-on="on"
        >
          <span
            v-if="item == _max && _overflow"
            data-test-avatar-overflow
            :style="{ ..._font }"
            class="content-avatar-text"
            v-text="`+${_overflow}`"
          />
          <AvatarComponent
            data-test-avatar-group-avatar-component
            v-else-if="avatars.length"
            :id="avatars[index].id"
            :size="size.toString()"
            :src="avatars[index].image"
            :text="avatars[index].text"
          />
        </v-avatar>
      </template>
      <div class="d-flex flex-column px-2 py-1">
        <span
          v-for="name of _names"
          data-test-avatar-name
          :key="name"
          class="pa-1"
          v-text="name"
        />
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import AvatarComponent from '@/components/AvatarComponent/AvatarComponent'

export default {
  name: 'AvatarGroup',
  components: { AvatarComponent },
  props: {
    tooltip: {
      type: Boolean,
      default: true,
    },
    avatars: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      required: false,
      default: () => 2,
    },
    size: {
      type: Number,
      default: () => 48,
    },
    zIndex: {
      type: Number,
      default: () => 1999,
    },
  },
  computed: {
    _max: function () {
      return this.max < this.avatars.length
        ? this.max
        : this.avatars.length || 1
    },
    _overflow: function () {
      const haveOverflow = this.avatars.length > this.max
      const overflow = this.avatars.length - this.max
      return haveOverflow ? overflow + 1 : 0
    },
    _font: function () {
      return {
        fontSize: `${this.size * 0.03}em`,
      }
    },
    _names: function () {
      return this.avatars.map((e) => e.text)
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
