<template>
  <div class="shadow">
    <v-card elevation="0" height="100%">
      <v-card-title class="card-title-slot align-start flex-column">
        <div class="d-flex align-center card-title-wrapper">
          <h2
            class="card-title"
            v-text="$t('engagementPage.cardParticipationReport.cardTitle')"
          ></h2>

          <div>
            <HowToBeginAndWatchAVideo
              videoType="participation.report"
              firstLabelAlternative
              class="how-to-container"
            ></HowToBeginAndWatchAVideo>
          </div>
        </div>
        <p
          class="card-subtitle"
          v-text="$t('engagementPage.cardParticipationReport.cardSubtitle')"
        ></p>
      </v-card-title>

      <v-card-text class="card-container">
        <slot />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

export default {
  name: 'CardParticipationReport',
  components: {
    HowToBeginAndWatchAVideo,
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
