var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"cultural-maturity-container"},[_c('v-container',[_c('div',{staticClass:"cultural-maturity-header"},[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('engagementPage.cardCulturalMaturity.title'))}})]),(_vm._isLoading)?[_c('div',{staticClass:"cultural-maturity-content loading"},[_c('div',{staticClass:"maturity-level"},[_c('v-skeleton-loader',{staticClass:"loading-vertical-bar vertical-level",attrs:{"type":"chip"}})],1),_c('div',{staticClass:"maturity-details"},[_c('div',{staticClass:"maturity-details-title",attrs:{"data-test-score":""}},[_c('v-skeleton-loader',{staticClass:"loading-score",attrs:{"type":"chip"}}),_c('v-skeleton-loader',{staticClass:"loading-variation",attrs:{"type":"button"}})],1),_c('v-skeleton-loader',{staticClass:"loading-maturity",attrs:{"type":"list-item","max-width":"200px"}}),_c('v-skeleton-loader',{staticClass:"loading-maturity",attrs:{"type":"list-item-three-line"}})],1)]),_c('div',{staticClass:"cultural-maturity-footer loading"},[_c('v-skeleton-loader',{staticClass:"loading-maturity",attrs:{"type":"list-item-two-line"}}),_c('div',{staticClass:"maturity-footer__links"},[_c('HowToBeginAndWatchAVideo',{attrs:{"videoType":"culture.maturity"}})],1)],1)]:[_c('div',{staticClass:"cultural-maturity-content"},[_c('div',{staticClass:"maturity-level"},[_c('VerticalLevelBar',{attrs:{"type":"culture","groupMarker":_vm._groupScore,"accountMarker":_vm._accountScore,"marketMarker":_vm._marketScore,"marketPermission":true}})],1),_c('div',{staticClass:"maturity-details"},[_c('div',{staticClass:"maturity-details-title",attrs:{"data-test-score":""}},[_c('span',{staticClass:"maturity-details-title__text",style:({
                'font-size': _vm.calcScoreStretchFontSize(_vm.cultureResults.score)
                  .fontSize,
              }),attrs:{"data-test-maturity-details-title-text":""},domProps:{"textContent":_vm._s(_vm.calcScoreStretchFontSize(_vm.cultureResults.score).text)}}),_c('Variation',{staticClass:"variation-div",attrs:{"variation":_vm.variation,"type":"percent","short":""}})],1),_c('label',{staticClass:"maturity-details__label",domProps:{"textContent":_vm._s(
              _vm.cultureResults.maturity.current
                ? _vm.$t(`engagementPage.cardCulturalMaturity.subtitle`, {
                    current: _vm.cultureResults.maturity.current,
                  })
                : _vm.$t(`engagementPage.cardCulturalMaturity.subtitleEmpty`)
            )}}),(_vm._cultureRank)?_c('span',{staticClass:"maturity-details__text",domProps:{"innerHTML":_vm._s(
              _vm.$t(`engagementPage.cardCulturalMaturity.text`, {
                rank: _vm._cultureRank,
              })
            )}}):_c('span',{staticClass:"maturity-details__text",attrs:{"data-cy":"rank"},domProps:{"innerHTML":_vm._s(_vm.$t(`engagementPage.cardCulturalMaturity.textEmpty`))}})])]),_c('div',{staticClass:"cultural-maturity-footer"},[_c('span',{staticClass:"maturity-footer__text",domProps:{"textContent":_vm._s(
            _vm.resultsBenchmark.date
              ? _vm.$t(`engagementPage.cardCulturalMaturity.details`, {
                  date: _vm.formatDate(_vm.resultsBenchmark.date),
                })
              : _vm.$t(`engagementPage.cardCulturalMaturity.detailsEmptyStage`, {
                  date: _vm.formatDate(_vm.resultsBenchmark.date),
                })
          )}}),_c('div',{staticClass:"maturity-footer__links"},[_c('HowToBeginAndWatchAVideo',{attrs:{"videoType":"culture.maturity"}})],1)])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }