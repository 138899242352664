<template>
  <div data-test-select-variation>
    <v-select
      class="variation-select"
      v-model="period"
      :items="variationPeriods"
      hide-details
      solo
      :menu-props="variationSelectMenuProps"
      item-color="redLighten2"
      color="neutral6"
      @change="onChange"
    >
      <template v-slot:item="{ item }">
        <span class="variation-select-item">
          {{ item.text }}
        </span>
        <i
          v-if="!hasPermission && item.lockedToFreePlan"
          class="fi fi-sr-crown"
          data-test-icon-not-permission
        ></i>
      </template>
      <template v-slot:prepend-inner>
        <i class="fi fi-br-calendar"></i>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'variation-select',
  props: {
    hasPermission: Boolean,
  },
  data() {
    return {
      period: 'real-time',
      variationSelectMenuProps: {
        'z-index': '100',
        'nudge-top': -50,
        closeOnClick: true,
      },
      variationPeriods: [
        {
          value: 'real-time',
          text: this.$t('variation.realTime'),
          lockedToFreePlan: false,
        },
        {
          value: 'last-30',
          text: this.$t('variation.last30'),
          lockedToFreePlan: true,
        },
        {
          value: 'last-90',
          text: this.$t('variation.last90'),
          lockedToFreePlan: true,
        },
        {
          value: 'last-180',
          text: this.$t('variation.last180'),
          lockedToFreePlan: true,
        },
        {
          value: 'last-360',
          text: this.$t('variation.last360'),
          lockedToFreePlan: true,
        },
        {
          value: 'all',
          text: this.$t('variation.all'),
          lockedToFreePlan: true,
        },
      ],
    }
  },
  methods: {
    onChange(value) {
      const selectedItem = this.variationPeriods.find(
        (item) => item.value === value
      )

      if (selectedItem.lockedToFreePlan && !this.hasPermission) {
        this.$store.commit('openUpdateYourPackageDialog', 'variation')
        this.$nextTick(() => (this.period = 'real-time'))
        return
      }

      this.$emit('period-change', this.period)
    },
  },
  watch: {
    hasPermission(newValue) {
      if (!newValue) {
        this.period = 'real-time'
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
