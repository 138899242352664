<template>
  <v-card id="loaderMap" flat>
    <!-- header -->
    <v-skeleton-loader type="list-item" width="100%" />

    <v-divider />

    <!-- content -->
    <div class="py-4">
      <section class="hz-scroll">
        <div class="d-flex align-center mb-2" v-for="(item, i) in 12" :key="i">
          <div class="d-flex">
            <v-skeleton-loader
              type="list-item"
              width="12.625rem"
              height="2.625rem"
            />
          </div>

          <v-skeleton-loader
            v-for="(subitem, j) in handleQuantity($vuetify.breakpoint.name)"
            :key="j"
            class="rounded-lg mr-1"
            width="4rem"
            min-width="48px"
            height="2.375rem"
            type="avatar"
            tile
          />
        </div>
      </section>
    </div>
  </v-card>
</template>

<script>
export default {
  methods: {
    handleQuantity(value) {
      switch (value) {
        case 'xs':
          return 5
        case 'sm':
          return 12
        case 'md':
          return 16
        case 'lg':
          return 26
        case 'xl':
          return 30
      }

      return ''
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
