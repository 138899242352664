<template>
  <v-container fluid class="wrap">
    <full-screen-modal :isShow="show" footer header>
      <template v-slot:header>
        <culture-management-header
          prependTeamCultureIcon
          backArrowIcon
          howToBeginAndWatchAVideoKey="culture.management"
          howToBeginAndWatchAVideo
          titleInput
          :titleInputPlaceholder="$t('cultureCode.header.configurationName')"
          :titleInputValue="cultureCode.name"
          :autoSaveLabel="!!_cultureId"
          @update:titleInputValue="updateName($event)"
          @close="close()"
        ></culture-management-header>
      </template>

      <v-container fluid class="wrap pa-0 ma-0">
        <v-col class="content-container">
          <div class="content-area">
            <v-expansion-panels v-model="openedPanel" multiple flat>
              <v-expansion-panel
                data-test-expansion-panel-group-selection
                data-test-expansion-panel-select-groups
              >
                <v-expansion-panel-header class="expansion-title">
                  <h3 v-text="$t('cultureCode.expansion.first')"></h3>
                </v-expansion-panel-header>

                <v-divider></v-divider>

                <v-expansion-panel-content class="expansion-panel-group">
                  <GroupSelector
                    ref="groupSelector"
                    :fullGroups="groups"
                    :wiredGroup="false"
                    :firstDefault="false"
                    :noAcme="false"
                    @applyGroups="updateGroups($event)"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel data-test-expansion-panel-add-values-org>
                <v-expansion-panel-header class="expansion-title">
                  <h3 v-text="$t('cultureCode.expansion.second.title')"></h3>
                </v-expansion-panel-header>

                <v-divider></v-divider>

                <v-expansion-panel-content
                  class="expansion-panel-organizational-values"
                >
                  <v-card
                    @click="addValue()"
                    class="card-value add-new"
                    data-test-add-new-value
                  >
                    <v-icon size="24px" class="fi fi-rr-plus"></v-icon>
                    <span
                      v-text="
                        $t('cultureCode.expansion.second.cardValue.newValue')
                      "
                    ></span>
                  </v-card>

                  <v-menu
                    v-for="(value, i) in cultureCode.values"
                    content-class="pl-2 pa-1 elevation-0"
                    :ref="'valueMenu'"
                    :key="i"
                    :close-on-content-click="false"
                    offset-x
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        data-test-card-organizational-values
                        class="card-value"
                      >
                        <div class="card-value-header">
                          <p
                            data-test-organizational-value-name
                            v-text="value.name"
                          ></p>

                          <v-btn
                            data-test-edit-organizational-values
                            icon
                            x-small
                            class="edit-icon"
                            @click="editValue(value, i)"
                          >
                            <v-icon
                              size="10px"
                              class="fi fi-rr-pencil"
                            ></v-icon>
                          </v-btn>
                        </div>

                        <v-chip
                          class="behavior-chip"
                          :class="{ 'with-value': value.behaviors.length >= 1 }"
                        >
                          <span
                            v-if="value.behaviors.length <= 0"
                            v-text="
                              $t(
                                'cultureCode.expansion.second.cardValue.noBehavior'
                              )
                            "
                          ></span>
                          <span
                            v-else
                            v-text="
                              $t(
                                'cultureCode.expansion.second.cardValue.behavior',
                                {
                                  count: value.behaviors.length || 0,
                                }
                              )
                            "
                          ></span>
                        </v-chip>

                        <v-btn v-bind="attrs" v-on="on" text x-small plain>
                          <span
                            v-text="
                              $t(
                                'cultureCode.expansion.second.cardValue.addBehavior'
                              )
                            "
                          ></span>
                        </v-btn>
                      </v-card>
                    </template>

                    <QuickSelectionBehaviors
                      :behaviors="availableBehaviors"
                      :value="value"
                      :valueIndex="i"
                      @update:value="updateOrganizationalValue($event)"
                      @close="$refs.valueMenu[i].save()"
                    />
                  </v-menu>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel
                data-test-expansion-panel-behavior-list
                class="expansion-panel-behaviors-container"
              >
                <v-expansion-panel-header class="expansion-title">
                  <h3 v-text="$t('cultureCode.expansion.third.title')"></h3>
                </v-expansion-panel-header>

                <v-divider></v-divider>

                <v-expansion-panel-content
                  class="expansion-panel-behaviors"
                  data-test-expansion-panel-behavior
                >
                  <div class="search-order-menu">
                    <div class="search-container">
                      <v-text-field
                        data-test-text-field-search-behaviors
                        v-model="search"
                        class="search-input"
                        :label="$t('cultureCode.expansion.third.search')"
                        hide-details="auto"
                        solo
                        flat
                      >
                        <v-icon size="14" slot="prepend">fi-rr-search</v-icon>
                      </v-text-field>
                    </div>

                    <v-spacer></v-spacer>

                    <v-btn-toggle
                      v-model="toggleViewBtn"
                      class="display-pattern"
                      borderless
                      mandatory
                    >
                      <v-btn :value="true" small icon>
                        <v-icon
                          data-test-grid-view
                          small
                          class="fi fi-rr-grid"
                        ></v-icon>
                      </v-btn>
                      <v-btn
                        :value="false"
                        small
                        icon
                        data-test-btn-menu-burguer
                      >
                        <v-icon
                          data-test-table-view
                          small
                          class="fi fi-rr-align-justify"
                        ></v-icon>
                      </v-btn>
                    </v-btn-toggle>

                    <div class="display-order" data-test-order-by>
                      <span
                        class="label"
                        v-text="$t('cultureCode.orderByBehavior.orderBy') + ':'"
                      ></span>

                      <v-select
                        class="select"
                        hide-details
                        v-model="orderBy"
                        item-text="name"
                        item-value="value"
                        :items="[
                          {
                            name: $t('cultureCode.orderByBehavior.votes'),
                            value: 'votes',
                          },
                          {
                            name: $t('cultureCode.orderByBehavior.probability'),
                            value: 'probability',
                          },
                        ]"
                      ></v-select>
                    </div>
                  </div>

                  <template v-if="availableBehaviors.length > 0">
                    <div v-if="toggleViewBtn" class="grid-view">
                      <v-lazy
                        v-for="(item, i) in availableBehaviors"
                        :key="i"
                        min-height="95.6px"
                        min-width="345px"
                      >
                        <CardBehavior
                          data-test-card-behaviors
                          :behavior="item"
                          :values="cultureCode.values"
                          :orderBy="orderBy"
                          @update:value="updateOrganizationalValue($event)"
                          @openBehaviorDetailHistory="openDetailBehaviorDialog"
                        />
                      </v-lazy>
                    </div>

                    <div v-else class="list-view">
                      <TableBehaviors
                        :behaviors="availableBehaviors"
                        :values="cultureCode.values"
                        :orderBy="orderBy"
                        data-test-table-behaviors
                        @update:value="updateOrganizationalValue($event)"
                        @openBehaviorDetailHistory="openDetailBehaviorDialog"
                      />
                    </div>
                  </template>

                  <div v-else class="behaviors-empty">
                    <span
                      class="empty-text"
                      v-text="$t('cultureCode.expansion.third.empty')"
                    ></span>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>

        <v-col class="aside-container">
          <aside class="aside">
            <div class="card-cultural-aside">
              <div class="div-projection">
                <h3
                  class="projection"
                  v-text="$t('cultureCode.aside.culturalProjection')"
                ></h3>
              </div>
              <div class="div-percentage">
                <h3
                  class="percentage-title"
                  v-text="$t('cultureCode.aside.culturalPercentualTitle')"
                ></h3>
                <div v-if="!projectionLoading" class="percentage">
                  <h3
                    data-test-percentage
                    v-text="
                      projection.culturalAlignment !== null
                        ? projection.culturalAlignment + '%'
                        : '--'
                    "
                  ></h3>
                </div>

                <v-skeleton-loader v-else class="loader" type="button" />
              </div>

              <div class="percentual-text">
                <h5 v-text="$t('cultureCode.aside.percentualText')"></h5>
                <a
                  href="#"
                  v-text="$t('cultureCode.aside.howToCulturalAlignment')"
                ></a>
              </div>
            </div>

            <div class="card-cultural-aside">
              <div class="div-projection">
                <h3
                  class="projection"
                  v-text="$t('cultureCode.aside.culturalMaturity')"
                ></h3>
              </div>

              <div class="culture-vertical-bar" v-if="!projectionLoading">
                <VerticalLevelBar
                  data-test-vertical-level-bar-projection-maturity
                  :groupMarker="projection.maturity.current.score"
                  :accountMarker="projection.maturity.projected.score"
                  type="behavior"
                >
                  <template v-slot:groupMarker>
                    <div class="actual-cultural-percentage-bar">
                      <h3>
                        <span
                          class="cultural-percentage"
                          data-test-actual-cultural-percentage
                          v-text="
                            projection.maturity.current.score !== null
                              ? projection.maturity.current.score + '%'
                              : '--'
                          "
                        ></span>
                        <span
                          class="cultural-label"
                          v-text="$t('cultureCode.aside.actualCultural')"
                        ></span>
                        <span
                          class="cultural-level"
                          v-text="
                            $t('cultureCode.aside.culturalLevel', {
                              count:
                                projection.maturity.current.level !== null
                                  ? projection.maturity.current.level
                                  : '--',
                            })
                          "
                        ></span>
                      </h3>
                    </div>
                  </template>
                  <template v-slot:accountMarker>
                    <div class="projected-cultural-percentage-bar">
                      <h3>
                        <span
                          class="cultural-percentage"
                          v-text="
                            projection.maturity.projected.score !== null
                              ? projection.maturity.projected.score + '%'
                              : '--'
                          "
                        ></span>
                        <span
                          class="cultural-label"
                          v-text="$t('cultureCode.aside.projectedMaturity')"
                        ></span>
                        <span
                          class="cultural-level"
                          v-text="
                            $t('cultureCode.aside.culturalLevel', {
                              count:
                                projection.maturity.projected.level !== null
                                  ? projection.maturity.projected.level
                                  : '--',
                            })
                          "
                        ></span>
                      </h3>
                    </div>
                  </template>
                </VerticalLevelBar>
              </div>

              <v-skeleton-loader
                v-else
                class="loader"
                type="image"
                height="150"
              />

              <div class="percentual-text">
                <h5 v-text="$t('cultureCode.aside.projectionText')"></h5>
                <a
                  href="#"
                  v-text="$t('cultureCode.aside.howToCulturalMaturity')"
                ></a>
              </div>
            </div>
          </aside>
        </v-col>

        <ManageCultureValue
          ref="manageValue"
          :behaviors="availableBehaviors"
          :autoSaveLabel="!!_cultureId"
          @update:value="updateOrganizationalValue($event)"
          @create:value="createOrganizationalValue($event)"
          @remove:value="removeOrganizationalValue($event)"
        />
      </v-container>

      <BehaviorDetailDialog
        ref="behaviorDetailDialog"
        :title="detailBehavior.behavior?.name"
        :behaviorData="detailBehavior.behavior"
        :behaviorHistory="detailBehavior.history"
        :rankingBy="orderBy"
        :hideMetrics="true"
      />

      <template v-slot:footer>
        <div class="culture-code-footer">
          <v-btn
            v-if="_cultureId"
            data-test-btn-remove-culture-code
            plain
            class="action-remove"
            @click="deleteCultureCode()"
          >
            <v-icon size="16">fi-rr-trash</v-icon>
            <span v-text="$t('cultureManagement.footer.remove')"></span>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            data-test-btn-close-culture-code
            plain
            class="action-close"
            @click="close()"
          >
            <span v-text="$t('cultureManagement.footer.close')"></span>
          </v-btn>

          <v-btn
            v-if="!_cultureId"
            data-test-save-culture
            class="action-save-new"
            elevation="0"
            :disabled="cultureCode.groups.length === 0"
            @click="createCultureCode()"
          >
            <span v-text="$t('cultureManagement.footer.save')"></span>
          </v-btn>
        </div>
      </template>
    </full-screen-modal>
  </v-container>
</template>

<script>
import FullScreenModal from '@/components/FullScreenModal/FullScreenModal.vue'
import CultureManagementHeader from '@/pages/EngagementPage/Tabs/Culture/Partials/CultureManagementHeader/Header'
import GroupSelector from '@/pages/EngagementPage/Tabs/Culture/Partials/GroupSelector/GroupSelector.vue'
import ManageCultureValue from '@/pages/EngagementPage/Tabs/Culture/Partials/ManageCultureValue/ManageCultureValue.vue'
import VerticalLevelBar from '@/components/VerticalLevelBar/VerticalLevelBar.vue'
import QuickSelectionBehaviors from '@/pages/EngagementPage/Tabs/Culture/Partials/QuickSelectionBehaviors/QuickSelectionBehaviors.vue'
import CardBehavior from '@/pages/EngagementPage/Tabs/Culture/Partials/CardBehavior/CardBehavior.vue'
import TableBehaviors from '@/pages/EngagementPage/Tabs/Culture/Partials/TableBehaviors/TableBehaviors.vue'
import BehaviorDetailDialog from '@/pages/EngagementPage/Tabs/Culture/Partials/BehaviorDetailDialog/BehaviorDetailDialog.vue'

import {
  createCultureCode,
  updateCultureCode,
  getCultureGroups,
  deleteCultureCode,
  getCultureResults,
  getCultureBehaviors,
  getProjection,
  getCultureResultsAdvancedHistory,
} from '@/service/culture'

export default {
  inject: ['providedBehaviors'],

  components: {
    GroupSelector,
    ManageCultureValue,
    VerticalLevelBar,
    FullScreenModal,
    CultureManagementHeader,
    QuickSelectionBehaviors,
    CardBehavior,
    TableBehaviors,
    BehaviorDetailDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    cultureCodeSelected: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      detailBehavior: {
        behavior: null,
        history: {
          metrics: [],
          dates: [],
        },
      },

      cultureCode: {
        name: '',
        groups: [],
        values: [],
      },
      groups: [],
      openedPanel: [0, 1, 2],
      behaviors: [],
      availableBehaviors: [],
      isEditOrganizationalValue: false,
      dialogCardAddCultureValue: false,
      search: '',
      inputAddBehavior: false,
      valueId: '',
      orderBy: 'probability',
      toggleViewBtn: true,

      projectionLoading: false,
      projection: {
        culturalAlignment: null,
        maturity: {
          current: {
            score: null,
            level: null,
          },
          projected: {
            score: null,
            level: null,
          },
        },
      },
    }
  },

  async mounted() {
    if (this._cultureId) {
      this.cultureCode = {
        name: this.cultureCodeSelected.name,
        values: this.cultureCodeSelected.values,
        groups: this.cultureCodeSelected.groups.map((e) => e.id),
      }
    }

    this.getGroups()
    this.getCultureResults()
    this.getProjection()
    this.handleAvailableBehaviors()
  },

  watch: {
    cultureCode: {
      handler() {
        this.handleAvailableBehaviors()
      },
      deep: true,
    },

    orderBy() {
      this.orderBehavior(this.availableBehaviors)
    },

    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.searchBehaviors()
      }
    },
  },

  computed: {
    _cultureId() {
      return this.$route.params.cultureId
    },
  },

  methods: {
    async createCultureCode() {
      try {
        const payload = {
          ...this.cultureCode,
          values: this.cultureCode.values.map((value) => {
            return {
              ...value,
              behaviors: value.behaviors.map((behavior) => behavior.id),
            }
          }),
        }

        await createCultureCode(payload).then((res) => {
          this.$root.$emit('display-alert', {
            title: this.$t('cultureManagement.cultureCodeCreated'),
            type: 'success',
          })

          this.$emit('create-culture-code', {
            ...res.data,
            culturalAlignmentAverage: 0,
            unfavorableCultureAverage: 0,
            maturityLevelAverage: 0,
          })

          this.close()
        })
      } catch (err) {
        this.$root.$emit('display-alert', {
          title: err.response.data.message,
          type: 'error',
        })
      }
    },

    async updateCultureCode() {
      try {
        const payload = {
          id: this._cultureId,
          ...this.cultureCode,
          values: this.cultureCode.values.map((value) => {
            return {
              ...value,
              behaviors: value.behaviors.map((behavior) => behavior.id),
            }
          }),
        }

        await updateCultureCode(payload).then((res) => {
          this.$emit('refresh-culture-code', res.data)
        })
      } catch (err) {
        this.$root.$emit('display-alert', {
          title: err.response.data.message,
          type: 'error',
        })
      }
    },

    async deleteCultureCode() {
      try {
        await deleteCultureCode(this._cultureId).then(() => {
          this.$root.$emit('display-alert', {
            title: this.$t('cultureManagement.cultureCodeDeleted'),
            type: 'success',
          })

          this.$emit('delete-culture-code', this._cultureId)
          this.close()
        })
      } catch (err) {
        this.$root.$emit('display-alert', {
          title: err.response.data.message,
          type: 'error',
        })
      }
    },

    async getProjection() {
      const payload = {
        groups: this.cultureCode.groups.filter(Boolean),
        behaviors: [],
      }

      this.cultureCode.values.forEach((element) => {
        element.behaviors.forEach((behavior) => {
          payload.behaviors.push(behavior.id)
        })
      })

      this.projectionLoading = true

      if (
        payload.behaviors.length === 0 ||
        this.cultureCode.groups.length === 0
      ) {
        return
      }

      await getProjection(payload).then((res) => {
        this.projection = res.data
        this.projectionLoading = false
      })
    },

    updateName(event) {
      this.cultureCode.name = event

      if (this._cultureId) this.updateCultureCode()
    },

    updateGroups(event) {
      this.cultureCode.groups = event.map((element) => element.id)

      this.getCultureResults()
      if (this.cultureCodeSelected.id) {
        this.updateCultureCode()
      }

      this.search = ''
      this.openedPanel = [0, 1, 2]
      this.getProjection()
    },

    updateOrganizationalValue(event) {
      this.cultureCode.values.splice(event.index, 1, event.value)
      this.getProjection()

      if (this._cultureId) this.updateCultureCode()
    },

    createOrganizationalValue(event) {
      this.cultureCode.values.push(event.value)
      this.getProjection()

      if (this._cultureId) this.updateCultureCode()
    },

    async getCultureResults() {
      await this.fetchBehaviorRankings().then((data) => {
        this.behaviors = data

        this.handleAvailableBehaviors()
      })
    },

    async fetchBehaviorRankings() {
      if (this.cultureCode.groups.length === 0) {
        return []
      }

      const payload = {
        groups: this.cultureCode.groups.filter(Boolean),
      }

      const { data } = await getCultureResults(payload)
      const ranking = data.ranking || []

      return ranking
    },

    removeOrganizationalValue(event) {
      this.cultureCode.values.splice(event, 1)

      if (this.cultureCodeSelected.id) this.updateCultureCode()
    },

    handleAvailableBehaviors(search = null) {
      const availableBehaviorsIds = new Set()

      this.cultureCode.values
        .flatMap((element) => element.behaviors)
        .map((behavior) => behavior.id)
        .forEach((id) => availableBehaviorsIds.add(id))

      const filtered = this.behaviors.filter((e) => {
        return (
          !availableBehaviorsIds.has(e.id) &&
          (!search || e.name.toLowerCase().includes(search.toLowerCase()))
        )
      })

      this.orderBehavior(filtered)
    },

    orderBehavior(value) {
      // votes
      if (this.orderBy === 'votes') value.sort((a, b) => b.votes - a.votes)

      // probabilty
      if (this.orderBy === 'probability')
        value.sort((a, b) => b.probability - a.probability)

      this.availableBehaviors = value
    },

    closeAddCulture() {
      this.$emit('update:close', false)
      this.$router.push({ name: 'cultureManagement' })
      this.isEditOrganizationalValue = false
    },

    searchBehaviors() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.handleAvailableBehaviors(this.search)
      }, 500)
    },

    async getGroups() {
      await getCultureGroups().then((res) => {
        this.groups = res.data.map((element) => {
          const haveCultureCode =
            element.haveCultureCode &&
            this.cultureCode.groups.find((group) => group === element.id)
              ? false
              : true

          return {
            ...element,
            disabled: haveCultureCode,
            haveCultureCode: haveCultureCode,
          }
        })

        this.startSelectedGroups()
      })
    },

    async startSelectedGroups() {
      let payload = []

      const findGroup = (array) => {
        array.forEach((element) => {
          const group = this.cultureCode.groups.find(
            (currentGroup) => currentGroup === element.id
          )

          if (group) {
            payload.push(element)
          }
          if (element.children && element.children.length > 0) {
            findGroup(element.children)
          }
        })

        return true
      }

      await findGroup(this.groups)

      this.$refs.groupSelector.handleStartSelection(payload)
      this.$refs.groupSelector.startSelection = payload
      this.getCultureResults()
    },

    addValue() {
      this.$refs.manageValue.openDrawer()
    },

    editValue(value, index) {
      const behaviorsIDs = value.behaviors.map((behavior) => behavior.id)
      const payload = {
        ...value,
        behaviors: this.behaviors.filter((behavior) =>
          behaviorsIDs.includes(behavior.id)
        ),
        index: index,
      }

      this.$refs.manageValue.openDrawer(payload)
    },

    handleOpenBehaviorSelect(value) {
      if (value) {
        this.valueId = value.id
      }
      this.inputAddBehavior = !this.inputAddBehavior
    },

    close() {
      this.$emit('close')
    },

    openDetailBehaviorDialog(behavior) {
      this.detailBehavior = {
        behavior: behavior,
        history: {
          metrics: [],
          dates: [],
        },
      }

      // const groupIDs = this.cultureCode.groups
      // this.fetchCulturalResultsAdvancedHistory(groupIDs, behavior.id)

      this.$refs.behaviorDetailDialog.openDialog()
    },

    async fetchCulturalResultsAdvancedHistory(groupIDs, behaviorID) {
      const { data } = await getCultureResultsAdvancedHistory(
        groupIDs,
        behaviorID
      )

      if (!data.dates) {
        this.detailBehavior.history.metrics = []
        this.detailBehavior.history.dates = []
        return
      }

      this.detailBehavior.history.metrics = data.metrics
      this.detailBehavior.history.dates = data.dates
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
