var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`slider-component d-flex flex-column align-center
  ${_vm.sm ? 'sm' : ''}`},[_c('span',{staticClass:"accessibility-hide-text",attrs:{"tabindex":"0"},domProps:{"textContent":_vm._s(_vm.$t('answerSlider.accessibility.help1'))}}),(_vm.video)?_c('div',{staticClass:"video-component d-flex justify-center ma-4"},[_c('VideoComponent',{staticClass:"video",attrs:{"video":_vm.video,"autoplay":true}})],1):_vm._e(),(!_vm.readOnly)?_c('div',{staticClass:"d-flex align-self-stretch help-container no-select"},[(!_vm.initialized || _vm.dragging || _vm.pressing)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"help-label",domProps:{"textContent":_vm._s(_vm.$t('answerSlider.help'))}}),_c('i',{staticClass:"fi fi-rr-arrow-small-right icon-arrow-right right-animation"})]):_vm._e(),_c('div',{class:`confirm-container
      ${!_vm.initialized || _vm.dragging || _vm.pressing ? 'no-opacity' : ''}`,style:(`padding: 0 ${_vm.px}px`),on:{"click":function($event){return _vm.emitInput(true)}}},[_c('br'),_c('div',{staticClass:"confirm-sub-container"},[_c('span',{ref:"confirm",style:({ left: _vm.getLeft }),attrs:{"id":"confirm-text"},domProps:{"textContent":_vm._s(_vm.$t('answerSliderCard.confirm'))}})])])]):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-n2 full-width"},[_c('div',{staticClass:"flex-grow-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],ref:"slider",class:`slider
          slider_thumb-${_vm.initialized ? 'check' : 'menu'}
          ${_vm.dragging ? 'slider_thumb-active' : ''}`,attrs:{"data-test-input-range-slider":"","type":"range","min":_vm.min,"max":_vm.max,"disabled":_vm.readOnly,"step":_vm.pressing ? 10 : null,"tabindex":"0","role":"slider","aria-valuetext":`${_vm.value}% ${
          _vm.selected ? _vm.$t('answerSlider.accessibility.helpSelected') : ''
        }`},domProps:{"value":(_vm.value)},on:{"mousedown":function($event){return _vm.handleMouseDown()},"mouseup":function($event){return _vm.handleMouseUp()},"mousemove":function($event){return _vm.handleMouseMove()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 0)return null;if('button' in $event && $event.button !== 2)return null;_vm.pressing = true},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 0)return null;if('button' in $event && $event.button !== 2)return null;_vm.pressing = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.emitInput(true)}],"__r":function($event){_vm.value=$event.target.value}}}),_c('div',{staticClass:"slider_ticks-container d-flex justify-space-between"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"slider_tick d-flex flex-column align-center",attrs:{"data-test-number-drag-aswer-slider":""},on:{"mousedown":function($event){return _vm.handleSliderTickClick(item)}}},[_c('div',{staticClass:"line mt-n2"}),_c('span',{class:`values mt-1 l2 no-select
              ${
                _vm.isSelected(index)
                  ? 'selected ' + _vm.getColor(_vm.value) + '--text'
                  : ''
              }`,domProps:{"textContent":_vm._s(item.text)}})])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }