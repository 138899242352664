<template>
  <div class="card">
    <SelectedLoader v-if="loadingSelected && selected"></SelectedLoader>

    <PillarsLoader v-else-if="loading"></PillarsLoader>

    <div
      v-else
      id="pillars-of-engagement-group"
      class="card"
      data-test-pillars-of-engagement-group
    >
      <div class="d-flex align-center pt-2">
        <v-btn
          v-if="selected"
          class="ms-2 me-n3"
          icon
          color="neutral2"
          @click="$emit('back')"
        >
          <v-icon v-text="'fi-rr-arrow-left'"></v-icon>
        </v-btn>

        <div class="header-container flex-grow-1">
          <v-row>
            <v-col class="history">
              <label
                class="history-title"
                v-text="
                  selected
                    ? (metricsDetails[0] || {}).label
                    : $t('pillarsOfEngagementGroup.card.title')
                "
              ></label>
              <div>
                <HowToBeginAndWatchAVideo
                  class="mt-1 ml-1"
                  videoType="climate.pillars"
                ></HowToBeginAndWatchAVideo>
              </div>
            </v-col>

            <v-col
              v-if="!selected"
              class="stats mr-4 hidden-sm-and-down"
              cols="auto"
              @click="goToFullReport"
              data-test-button-details-pillar
            >
              <div :class="fullReport ? 'stats-icon-changed' : 'stats-icon'">
                <i class="fi fi-br-stats"></i>
              </div>
              <label
                :class="fullReport ? 'stats-text-changed' : 'stats-text'"
                class="ml-1"
                v-text="$t('engagementPage.cardSmartPillars.report')"
              ></label>
            </v-col>
          </v-row>
          <v-row class="subtitle pe-3 me-3">
            <span
              v-if="selected"
              v-html="(metricsDetails[0] || {}).text"
            ></span>
            <span
              v-else
              v-text="$t('pillarsOfEngagementGroup.card.subtitle')"
            ></span>
          </v-row>
        </div>
      </div>

      <v-divider class="custom-divider"></v-divider>

      <v-item-group v-show="!fullReport" class="card-items">
        <v-row
          v-for="(metricsItem, key) in _handleMetricsGroup"
          :key="key"
          data-test-pillars-engagement
        >
          <v-col cols="12" md="12" v-if="!_isEmptyStage" class="metric-title">
            <label
              :class="{
                'green-text': key === 'doingWell',
                'red-text': key !== 'doingWell',
              }"
              class="green-text"
              v-text="metricsItem.label"
            ></label>
          </v-col>
          <div v-if="!_isEmptyStage">
            <div
              class="empty-stage-text"
              v-if="metricsItem.data.length === 0 && key === 'doingWell'"
            >
              <img
                src="@/assets/images/pillarsOfEngagement/warning.png"
                class="image-description"
                alt="warning"
              />
              <span>
                {{
                  $t('pillarsOfEngagementGroup.metrics.doingWell.emptyStage')
                }}
                <a
                  class="text-link"
                  :href="
                    $t('pillarsOfEngagementGroup.metrics.doingWell.link.url')
                  "
                  v-text="
                    $t('pillarsOfEngagementGroup.metrics.doingWell.link.label')
                  "
                ></a>
              </span>
            </div>

            <div
              class="empty-stage-text"
              v-else-if="metricsItem.data.length === 0 && key !== 'doingWell'"
            >
              <img
                src="@/assets/images/pillarsOfEngagement/doing-well.png"
                class="image-description"
                alt="doing-well"
              />
              <span
                v-text="
                  $t('pillarsOfEngagementGroup.metrics.warning.emptyStage')
                "
              ></span>
            </div>
          </div>

          <v-col
            class="items"
            v-for="(item, index) in metricsItem.data"
            :key="index"
            cols="12"
            md="6"
          >
            <v-item>
              <v-card
                class="d-flex align-center item"
                light
                @click="$emit('clicked-item', item.metricID)"
                elevation="0"
                data-test-pillar-engagement
              >
                <div
                  v-if="!hideQuickDetail"
                  class="grade-tooltip"
                  data-test-grade-pillar-group
                  @mouseenter="handleMouseEnter(item.metricID)"
                  @mouseleave="handleMouseLeave()"
                >
                  <div class="tooltip-container" :show-side="index % 2">
                    <QuickDetail
                      class="hidden-sm-and-down"
                      :benchmarkPermissions="benchmarkPermissions"
                      :hasSelectedGroup="hasSelectedGroup"
                      :correlationsData="correlations"
                      :quicklyComparisonData="quicklyComparisonData"
                      :pillarAndFactorData="item.submetrics"
                      :hideInformations="!_canEngagementBenchmarkResults"
                      :hideCorrelatedPillars="
                        !_canEngagementClimateCorrelationResults
                      "
                      :showCorrelatedPillars="_showCorrelationResults"
                    ></QuickDetail>
                  </div>
                </div>
                <PillarAndFactor
                  data-test-pillar-and-factor
                  class="pillar-item-group"
                  :image="item.image"
                  :title="item.label"
                  :score="getScore(item.score)"
                  :grade="getScore(item.score)"
                  :variation="hasMetricVariation(item.metricID)"
                  :variationLoading="variation.loading"
                  :locked="false"
                ></PillarAndFactor>
              </v-card>
            </v-item>
          </v-col>
        </v-row>

        <v-row v-if="_isEmptyStage">
          <div class="empty-stage-footer">
            <span
              class="empty-stage-footer-text"
              v-text="$t('pillarsOfEngagementGroup.card.emptyStage')"
            ></span>
          </div>
        </v-row>
      </v-item-group>

      <div v-show="fullReport">
        <div
          v-for="(details, id) in metricsDetails"
          :key="id"
          data-test-details-pillars-list
        >
          <v-row class="first-container-detail">
            <v-col
              class="pillar-detail"
              cols="12"
              md="6"
              data-test-pillar-detail
            >
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
                min-height="200"
              >
                <v-item>
                  <v-card class="d-flex align-center item" light elevation="0">
                    <div
                      v-if="!hideQuickDetail"
                      class="grade-tooltip"
                      data-test-grade-pillar-group
                      @mouseenter="handleMouseEnter(details.metricID)"
                      @mouseleave="handleMouseLeave()"
                    >
                      <div
                        class="tooltip-container tooltip-detail"
                        :show-side="0"
                      >
                        <QuickDetail
                          class="hidden-sm-and-down"
                          :correlationsData="correlations"
                          :quicklyComparisonData="quicklyComparisonData"
                          :pillarAndFactorData="details.submetrics"
                          :hideInformations="!_canEngagementBenchmarkResults"
                          :hideCorrelatedPillars="
                            !_canEngagementClimateCorrelationResults
                          "
                          :showCorrelatedPillars="_showCorrelationResults"
                        ></QuickDetail>
                      </div>
                    </div>

                    <PillarAndFactor
                      :image="details.image"
                      :title="details.label"
                      :score="details.score"
                      :grade="details.score"
                      :text="details.text"
                      :variation="hasMetricVariation(details.metricID)"
                      :variationLoading="variation.loading"
                      :locked="false"
                    ></PillarAndFactor>
                  </v-card>
                </v-item>
              </v-lazy>

              <div class="pillar-action-plan" data-test-pillar-action-plan>
                <v-lazy
                  :options="{
                    threshold: 0.5,
                  }"
                  transition="fade-transition"
                >
                  <ReliabilityRate
                    :score="convertReliabilityRate(details.reliabilityRate)"
                    data-test-details-pillars-reliability
                  ></ReliabilityRate>
                </v-lazy>

                <div class="correlated-pillars">
                  <div
                    v-if="!hideQuickDetail"
                    class="correlated-pillars-text"
                    @mouseenter="handleMouseEnter(details.metricID)"
                    @mouseleave="handleMouseLeave()"
                  >
                    <CorrelatedPillarsDialog
                      :loading="correlatedPillarsLoading"
                      :correlatedPillars="correlations"
                      :hideCorrelatedPillars="
                        !_canEngagementClimateCorrelationResults
                      "
                      :showCorrelatedPillars="_showCorrelationResults"
                    ></CorrelatedPillarsDialog>
                  </div>

                  <div v-if="_showPillarPulse" class="pulse-pillar">
                    <span>
                      <i class="fi-rr-pulse"></i>
                      {{ $t('pulsePillar') }}
                    </span>
                  </div>
                </div>

                <ButtonNewActionPlan
                  v-if="_canNewActionPlan"
                  data-test-new-action-plan-for-a-pillar
                  :metricID="details.metricID"
                  :groupID="getGroupId"
                ></ButtonNewActionPlan>
              </div>
            </v-col>

            <v-col
              class="history-detail"
              cols="12"
              md="6"
              data-test-history-detail
            >
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
                min-height="240"
              >
                <HistoryChart
                  class="mr-2 mt-2"
                  :height="250"
                  :metrics="pillarsAndFactorsHistory.metrics[id]"
                  :dates="pillarsAndFactorsHistory.dates"
                  :loading="pillarsAndFactorsHistoryLoading"
                ></HistoryChart>
              </v-lazy>

              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
                min-height="112"
              >
                <BenchmarkBadge
                  :loading="pillarsBenchmarksLoading"
                  :rank="getBenchmarkRank(id)"
                  status="overallAverage"
                  :locked="pillarsBenchmarksLocked"
                  :percentage="getBenchmarkScore(id)"
                ></BenchmarkBadge>
              </v-lazy>
            </v-col>
          </v-row>

          <v-expansion-panels flat v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div
                  class="detail-title"
                  v-text="$t('pillarsOfEngagementGroup.details.detailTitle')"
                ></div>
              </v-expansion-panel-header>

              <v-divider class="custom-divider"></v-divider>

              <v-expansion-panel-content>
                <div id="answers-and-factors" data-test-answers-and-factors>
                  <div
                    v-for="(submetrics, index2) in details.submetrics"
                    :key="`${index2}-submetrics`"
                  >
                    <div class="submetrics-details" data-test-factors>
                      <div class="factors-pillar" data-test-factors-pillar>
                        <v-lazy
                          :options="{
                            threshold: 0.5,
                          }"
                          transition="fade-transition"
                          min-height="100"
                          width="100%"
                        >
                          <PillarAndFactor
                            :title="submetrics.label"
                            :score="submetrics.score"
                            :grade="submetrics.score"
                            :text="submetrics.text"
                            :variation="
                              hasSubmetricsVariation(submetrics.submetricID)
                            "
                            :variationLoading="variation.loading"
                            :locked="false"
                          ></PillarAndFactor>
                        </v-lazy>

                        <div
                          class="factors-pillar-action-plan"
                          data-test-factors-reliability
                        >
                          <v-lazy
                            :options="{
                              threshold: 0.5,
                            }"
                            transition="fade-transition"
                          >
                            <ReliabilityRate
                              :score="
                                convertReliabilityRate(details.reliabilityRate)
                              "
                              data-test-details-pillars-reliability
                            ></ReliabilityRate>
                          </v-lazy>
                        </div>
                      </div>

                      <div
                        class="submetrics-details-history"
                        data-test-submetrics-details-history
                      >
                        <v-lazy
                          :options="{
                            threshold: 0.5,
                          }"
                          transition="fade-transition"
                          min-height="240"
                        >
                          <HistoryChart
                            class="mr-2 mt-2"
                            :height="250"
                            :metrics="
                              pillarsAndFactorsHistory.factors[id][index2]
                            "
                            :dates="pillarsAndFactorsHistory.dates"
                            :loading="pillarsAndFactorsHistoryLoading"
                          ></HistoryChart>
                        </v-lazy>
                      </div>
                    </div>

                    <div class="question-title" data-test-question-title>
                      <div
                        v-text="
                          $t('pillarsOfEngagementGroup.details.questionsTitle1')
                        "
                      ></div>
                      <div
                        v-text="
                          $t('pillarsOfEngagementGroup.details.questionsTitle2')
                        "
                      ></div>
                      <div
                        v-text="
                          $t('pillarsOfEngagementGroup.details.questionsTitle3')
                        "
                      ></div>
                    </div>

                    <v-divider class="custom-divider"></v-divider>

                    <div class="question-details" data-test-question-container>
                      <div
                        class="question-container"
                        v-for="(question, index3) in submetrics.questions"
                        :key="`${index3}-questions`"
                        data-test-factor-questions
                      >
                        <div class="question" data-test-question-details>
                          <v-lazy
                            :options="{
                              threshold: 0.5,
                            }"
                            transition="fade-transition"
                            min-height="22"
                          >
                            <Question
                              :questionType="question.type"
                              :question="question.text"
                              :questionID="question.id"
                              :positiveQuestion="question.positiveQuestion"
                              :negativeQuestion="question.constructiveQuestion"
                              :frequencies="question.frequencies || []"
                              :score="
                                convertReliabilityRate(question.reliabilityRate)
                              "
                              :items="getEmojis(question, submetrics, details)"
                            ></Question>
                          </v-lazy>
                        </div>

                        <div class="reliability-desktop">
                          <v-lazy
                            :options="{
                              threshold: 0.5,
                            }"
                            transition="fade-transition"
                          >
                            <ReliabilityRate
                              :score="
                                convertReliabilityRate(question.reliabilityRate)
                              "
                              :showLabel="false"
                            ></ReliabilityRate>
                          </v-lazy>
                        </div>

                        <div class="frequencies">
                          <v-lazy
                            :options="{
                              threshold: 0.5,
                            }"
                            transition="fade-transition"
                          >
                            <QuestionProgressBar
                              data-test-question-progressBar
                              class="questions-frequencies"
                              :frequencies="question.frequencies || []"
                            ></QuestionProgressBar>
                          </v-lazy>
                        </div>

                        <div class="reliability-mobile">
                          <v-lazy
                            :options="{
                              threshold: 0.5,
                            }"
                            transition="fade-transition"
                          >
                            <ReliabilityRate
                              :score="
                                convertReliabilityRate(question.reliabilityRate)
                              "
                            ></ReliabilityRate>
                          </v-lazy>
                        </div>
                      </div>
                    </div>

                    <v-divider class="custom-divider"></v-divider>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div
            v-if="!hideOpinions"
            class="opinions-container"
            data-test-opinions-container
          >
            <LastOpinions
              :opinions="getOpinion(details.metricID)"
              :metricID="details.metricID"
              type="eNPS"
              :title="getMetricLabel(details.metricID)"
            ></LastOpinions>
          </div>

          <v-divider class="custom-divider"></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PillarAndFactor from '@/components/PillarAndFactor/PillarAndFactor.vue'
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import HistoryChart from '@/components/HistoryChart/HistoryChart.vue'
import BenchmarkBadge from '@/components/BenchmarkBadge/BenchmarkBadge.vue'
import QuickDetail from '@/components/QuickDetail/QuickDetail.vue'
import ReliabilityRate from '@/components/ReliabilityRate/ReliabilityRate.vue'
import ButtonNewActionPlan from '@/pages/EngagementPage/Tabs/Climate/Partials/ButtonNewActionPlan/ButtonNewActionPlan.vue'

import { mapGetters } from 'vuex'
import { _permissions } from '@/helpers/ability/engagement'
import Question from '@/components/Question/Question.vue'
import QuestionProgressBar from '@/components/QuestionProgressBar/QuestionProgressBar.vue'

import getEmojis from '@/mixins/getEmojis'
import CorrelatedPillarsDialog from '../CorrelatedPillarsDialog/CorrelatedPillarsDialog.vue'
import LastOpinions from '@/components/LastOpinions/LastOpinions.vue'

import PillarsLoader from './parts/PillarsLoader/PillarsLoader'
import SelectedLoader from './parts/SelectedLoader/SelectedLoader'

export default {
  name: 'PillarsOfEngagementGroup',
  mixins: [getEmojis],
  components: {
    PillarAndFactor,
    HowToBeginAndWatchAVideo,
    HistoryChart,
    BenchmarkBadge,
    QuickDetail,
    CorrelatedPillarsDialog,
    ReliabilityRate,
    Question,
    QuestionProgressBar,
    LastOpinions,
    ButtonNewActionPlan,
    PillarsLoader,
    SelectedLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    loadingSelected: {
      type: Boolean,
      default: true,
    },
    metrics: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasSelectedGroup: {
      type: Boolean,
    },
    fullReport: {
      type: Boolean,
      required: true,
      default: false,
    },
    pillarsAndFactorsHistory: {
      type: Object,
      default: () => ({}),
    },
    pillarsAndFactorsHistoryLoading: {
      type: Boolean,
      default: false,
    },
    pillarsBenchmarks: {
      type: Array,
      default: () => [],
    },
    pillarsBenchmarksLoading: {
      type: Boolean,
      default: false,
    },
    pillarsBenchmarksLocked: {
      type: Boolean,
      default: false,
    },
    correlations: {
      type: Array,
      required: false,
    },
    quicklyComparisonData: {
      type: Object,
      required: false,
    },
    correlatedPillars: {
      type: Array,
      default: () => [],
    },
    correlatedPillarsLoading: {
      type: Boolean,
      default: false,
    },
    benchmarkPermissions: {
      type: Boolean,
      default: false,
    },
    metricsDetails: {
      type: Array,
      default: () => [],
    },
    pillarDetailsLoading: {
      type: Boolean,
      default: false,
    },
    opinions: {
      type: Object,
      required: false,
      default: () => {},
    },
    selected: {
      type: String,
    },
    variation: {
      type: Object,
      require: true,
      default: () => {},
    },

    hideOpinions: {
      type: Boolean,
      default: false,
    },
    hideQuickDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      systemPlan: 'advanced',
      pillarsBenchmarkLoading: false,
      panel: [],
      items: 10,
    }
  },

  computed: {
    ...mapGetters([
      'getGroupId',
      'getClientPackage',
      'getIsBasicPlanAdminUser',
    ]),

    _canNewActionPlan() {
      return (
        this._canEngagementActionPlansCreator ||
        this._canEngagementActionPlansTemplatesCreator
      )
    },

    _canEngagementActionPlansCreator() {
      return this.$can('access', _permissions.engagement_action_plans_creator)
    },
    _canEngagementActionPlansTemplatesCreator() {
      return this.$can(
        'access',
        _permissions.engagement_action_plans_templates_creator
      )
    },

    _showPillarPulse() {
      /**
       *  @TODO - [#3ewmc5c] - Ocultar o impulsionar pilar, vamos validar mais pra frente
       */

      return process.env.NODE_ENV === 'development'
    },

    _canEngagementBenchmarkResults() {
      return this.$can('access', _permissions.engagement_benchmark_results)
    },

    _showCorrelationResults() {
      if (this._canEngagementClimateCorrelationResults) return true

      return this.getIsBasicPlanAdminUser
    },

    _canEngagementClimateCorrelationResults() {
      return this.$can(
        'access',
        _permissions.engagement_climate_correlation_results
      )
    },

    _handleMetricsGroup() {
      const metricsGroup = {
        doingWell: {
          label: this.$t('pillarsOfEngagementGroup.metrics.doingWell.label'),
          data: this.metrics.filter((element) => element.score > 4.9),
        },
        warning: {
          label: this.$t('pillarsOfEngagementGroup.metrics.warning.label'),
          data: this.metrics.filter((element) => element.score <= 4.9),
        },
      }
      return metricsGroup
    },

    _isEmptyStage() {
      return this.metrics.filter((element) => element.score == null).length > 0
    },

    _variationMetricsHash() {
      const variation = {}
      const { metrics } = this.variation
      metrics.forEach((element) => {
        variation[element.metricID] = { ...element }
      })
      return variation
    },

    _variationSubMetricsHash() {
      const submetrics = {}
      const { metrics } = this.variation
      metrics.forEach((element) => {
        element.submetrics.forEach(
          (child) => (submetrics[child.submetricID] = { ...child })
        )
      })
      return submetrics
    },
  },
  methods: {
    getOpinion(pKey) {
      if (this.opinions[pKey]) return this.opinions[pKey].opinions
      return []
    },
    getMetricLabel(pKey) {
      if (this.opinions[pKey]) return this.opinions[pKey].metric.label
      return ''
    },
    getScore(score) {
      return score
    },
    goToFullReport() {
      this.$emit('goToFullReport')
    },
    getFactorHistories(id, id2) {
      return this.pillarsAndFactorsHistory.factors[id][id2]
    },
    getBenchmarkScore(id) {
      return this.pillarsBenchmarks[id] && this.pillarsBenchmarks[id].score
        ? this.pillarsBenchmarks[id].score
        : 0
    },
    getBenchmarkRank(id) {
      return this.pillarsBenchmarks[id] && this.pillarsBenchmarks[id].rank
        ? this.pillarsBenchmarks[id].rank
        : 0
    },
    convertReliabilityRate(reliabilityRate) {
      const reliabilityValues = {
        veryLow: 20,
        low: 40,
        medium: 60,
        high: 80,
        veryHigh: 100,
        default: null,
      }

      return reliabilityValues[reliabilityRate] || reliabilityValues.default
    },
    hasMetricVariation(metricID) {
      if (this._variationMetricsHash[metricID]) {
        return this._variationMetricsHash[metricID].score
      }
      return null
    },
    hasSubmetricsVariation(submetricID) {
      if (this._variationSubMetricsHash[submetricID]) {
        return this._variationSubMetricsHash[submetricID].score
      }
      return null
    },

    handleMouseEnter(metricID) {
      if (this.hideQuickDetail) {
        return
      }

      this.$emit('hoverOn:pillarGroup', metricID)
    },
    handleMouseLeave() {
      this.$emit('hoverOff:pillarGroup')
    },
  },

  mounted() {
    this.panel = [...Array(this.items).keys()].map((k, i) => i)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
