<template>
  <div class="card-container">
    <v-row id="analytics-report" data-test-analytics-report>
      <v-col sm="12" md="4" lg="4">
        <CardProgressLine
          id="total"
          data-test-progress-total-card
          :title="localeText('total.title')"
          :label="localeText('total.label')"
          :total="total"
          :series="historyTotal"
          :labels="dates"
          :variation="variation.total"
          variationShort
        />
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardProgressLine
          id="concluded"
          data-test-progress-concluded-card
          :title="localeText('concluded.title')"
          :label="localeText('concluded.label')"
          :total="concluded"
          :series="historyConcluded"
          :labels="dates"
          :variation="variation.closed"
          variationShort
        />
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardProgressLine
          id="delayed"
          data-test-progress-delayed-card
          :title="localeText('delayed.title')"
          :label="localeText('delayed.label')"
          :total="delayed"
          :series="historyDelayed"
          :labels="dates"
          :variation="variation.expired"
          variationShort
        />
      </v-col>
      <v-col sm="12" md="12" lg="6">
        <CardVerticalBars
          id="actionPlan"
          :title="localeText('report.title')"
          :noDataLabel="localeText('report.noData')"
          :categories="categories"
          :series="series"
          :labels="labels"
        />
      </v-col>
      <v-col sm="12" md="12" lg="6">
        <CardTasksReport
          :items="tasksReport.items"
          :page="tasksReport.page"
          :pageLength="tasksReport.pageLength"
          :loading="tasksReport.loading"
          @handleUpdate="handleUpdate"
          @handleSort="handleSort"
        />
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardProgressCircle
          id="status"
          :title="localeText('status.title')"
          :data="status"
          legendPosition="left"
          @legendClick="legendClickStatus"
          :showLegendEmptyStage="showLegendEmptyStage.status"
        />
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardAverageTime
          id="resolution"
          data-test-average-resolution-card
          :title="localeText('resolution.title')"
          :time="resolutionTime"
          :variation="variation.completeActionPlans"
        />
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardAverageTime
          id="conclusion"
          data-test-average-conclusion-card
          :title="localeText('conclusion.title')"
          :time="conclusionTime"
          :variation="variation.completeTasks"
        />
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardProgressCircle
          id="success-insuccess"
          :title="localeText('successInsuccess.title')"
          :data="successInsuccess"
          legendPosition="bottom"
          @legendClick="legendClickSuccessInsuccess"
          :showLegendEmptyStage="showLegendEmptyStage.successInsuccess"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardProgressLine from '@/components/CardProgressLine/CardProgressLine.vue'
import CardVerticalBars from '@/components/CardVerticalBars/CardVerticalBars.vue'
import CardProgressCircle from '@/components/CardProgressCircle/CardProgressCircle.vue'
import CardAverageTime from '@/components/CardAverageTime/CardAverageTime.vue'
import CardTasksReport from '../../Partials/CardTasksReport/CardTasksReport.vue'

import {
  getActionPlanSummaryHistory,
  getActionPlanSummary,
  getActionPlansSummaryAssignees,
  getVariation,
} from '@/service/actionPlans'

import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'ActionPlanPage',
  components: {
    CardProgressLine,
    CardVerticalBars,
    CardProgressCircle,
    CardAverageTime,
    CardTasksReport,
  },
  data() {
    return {
      resolutionTime: 0,
      conclusionTime: 0,
      total: 0,
      concluded: 0,
      delayed: 0,
      historyTotal: [],
      historyConcluded: [],
      historyDelayed: [],
      dates: [],
      categories: [],
      series: [],
      labels: [],
      status: [
        {
          label: this.localeText('status.open'),
          color: variables.pinkColor2,
          value: 0,
        },
        {
          label: this.localeText('status.delayed'),
          color: variables.violet,
          value: 0,
        },
        {
          label: this.localeText('status.concluded'),
          color: variables.yellowColor3,
          value: 0,
        },
      ],
      originStatus: [
        {
          label: this.localeText('status.open'),
          color: variables.pinkColor2,
          value: 0,
        },
        {
          label: this.localeText('status.delayed'),
          color: variables.violet,
          value: 0,
        },
        {
          label: this.localeText('status.concluded'),
          color: variables.yellowColor3,
          value: 0,
        },
      ],
      successInsuccess: [
        {
          label: this.localeText('successInsuccess.successLabel'),
          color: variables.greenColor5,
          value: 0,
        },
        {
          label: this.localeText('successInsuccess.insuccessLabel'),
          color: variables.blueColor4,
          value: 0,
        },
      ],
      originalSuccessInsuccess: [
        {
          label: this.localeText('successInsuccess.successLabel'),
          color: variables.greenColor5,
          value: 0,
        },
        {
          label: this.localeText('successInsuccess.insuccessLabel'),
          color: variables.blueColor4,
          value: 0,
        },
      ],
      showLegendEmptyStage: {
        status: false,
        successInsuccess: false,
      },
      tasksReport: {
        page: 1,
        pageLength: 3,
        loading: false,
        items: [],
      },
      variation: {},
    }
  },
  props: {
    groupID: {
      type: String,
      default: '',
    },
    indicatorID: {
      type: String,
      default: 'action-plan',
    },
    variationPeriod: {
      type: String,
      default: 'real-time',
    },
  },
  watch: {
    groupID(id) {
      this.fetchSummary(id)
      this.fetchSummaryHistory(id)
      this.fetchTasksReport(id)

      this.fetchVariation()
    },
    variationPeriod() {
      this.fetchVariation()
    },
  },
  methods: {
    async fetchSummary(groupID) {
      const { data: summary } = await getActionPlanSummary(groupID)
      this.showLegendEmptyStage.status = false
      this.showLegendEmptyStage.successInsuccess = false
      if (!summary.total) {
        this.fillEmptyCount()
        this.fillEmptyAverageTime()
        this.fillEmptyVerticalBars()
        this.fillEmptyActionPlanStatus()
        this.fillEmptySuccessInsuccess()
      } else {
        this.fillCount(summary)
        this.fillAverageTime(summary)
        this.fillVerticalBars(summary)
        this.fillActionPlanStatus(summary)
        this.fillSuccessInsuccess(summary)
      }
    },
    async fetchSummaryHistory(groupID) {
      const { data: history } = await getActionPlanSummaryHistory(groupID)
      if (!history.dates) {
        this.fillEmptyHistory()
      } else {
        this.fillHistory(history)
      }
    },
    fillHistory(data) {
      this.historyTotal = data.total
      this.historyConcluded = data.closed
      this.historyDelayed = data.delayed
      this.dates = data.dates
    },
    fillEmptyHistory() {
      this.historyTotal = []
      this.historyConcluded = []
      this.historyDelayed = []
      this.dates = []
    },
    fillCount(data) {
      this.total = data.total
      this.concluded = data.closed
      this.delayed = data.delayed
    },
    fillEmptyCount() {
      this.total = 0
      this.concluded = 0
      this.delayed = 0
    },
    fillAverageTime(data) {
      this.resolutionTime = data.averageActionPlansClosingTime
      this.conclusionTime = data.averageTasksClosingTime
    },
    fillEmptyAverageTime() {
      this.resolutionTime = 0
      this.conclusionTime = 0
    },
    fillVerticalBars(data) {
      this.categories = this.localeText('categories')
      this.labels = this.localeText('labels')
      this.series = [
        { name: this.labels[0], data: [] },
        { name: this.labels[1], data: [] },
        { name: this.labels[2], data: [] },
      ]
      const { climate, culture, participation } = data.types
      this.series[0].data = Object.values(climate)
      this.series[1].data = Object.values(culture)
      this.series[2].data = Object.values(participation)
    },
    fillEmptyVerticalBars() {
      this.labels = this.localeText('labels')
      this.categories = []
      this.series = []
    },
    fillActionPlanStatus(data) {
      this.status[0].value = data.open
      this.status[1].value = data.delayed
      this.status[2].value = data.closed
      this.originStatus[0].value = data.open
      this.originStatus[1].value = data.delayed
      this.originStatus[2].value = data.closed
    },
    fillEmptyActionPlanStatus() {
      this.status = [
        {
          label: this.localeText('status.open'),
          color: variables.pinkColor2,
          value: 0,
        },
        {
          label: this.localeText('status.delayed'),
          color: variables.violet,
          value: 0,
        },
        {
          label: this.localeText('status.concluded'),
          color: variables.yellowColor3,
          value: 0,
        },
      ]
      this.originStatus = [
        {
          label: this.localeText('status.open'),
          color: variables.pinkColor2,
          value: 0,
        },
        {
          label: this.localeText('status.delayed'),
          color: variables.violet,
          value: 0,
        },
        {
          label: this.localeText('status.concluded'),
          color: variables.yellowColor3,
          value: 0,
        },
      ]
    },
    fillSuccessInsuccess(data) {
      this.successInsuccess[0].value = data.successClosed
      this.successInsuccess[1].value = data.delayedClosed
      this.originalSuccessInsuccess[0].value = data.successClosed
      this.originalSuccessInsuccess[1].value = data.delayedClosed
    },
    fillEmptySuccessInsuccess() {
      this.successInsuccess = [
        {
          label: this.localeText('successInsuccess.successLabel'),
          color: variables.greenColor5,
          value: 0,
        },
        {
          label: this.localeText('successInsuccess.insuccessLabel'),
          color: variables.blueColor4,
          value: 0,
        },
      ]
      this.originalSuccessInsuccess = [
        {
          label: this.localeText('successInsuccess.successLabel'),
          color: variables.greenColor5,
          value: 0,
        },
        {
          label: this.localeText('successInsuccess.insuccessLabel'),
          color: variables.blueColor4,
          value: 0,
        },
      ]
    },
    localeText(name) {
      return this.$t(`engagementPage.actionPlan.cards.${name}`)
    },
    legendClickStatus(index) {
      const value = this.originStatus[index].value
      if (value !== 0) {
        if (this.status[index].value === 0) {
          this.status[index].value = value
        } else {
          this.status[index].value = 0
        }
        this.showLegendEmptyStage.status = true
      }
    },
    legendClickSuccessInsuccess(index) {
      const value = this.originalSuccessInsuccess[index].value
      if (value !== 0) {
        if (this.successInsuccess[index].value === 0) {
          this.successInsuccess[index].value = value
        } else {
          this.successInsuccess[index].value = 0
        }
        this.showLegendEmptyStage.successInsuccess = true
      }
    },
    handleUpdate(newPage) {
      this.tasksReport.page = newPage
      const groupID = this.groupID !== '' ? this.groupID : undefined
      this.fetchTasksReport(groupID)
    },
    handleSort(sortOrder, value) {
      const groupID = this.groupID !== '' ? this.groupID : undefined
      this.fetchTasksReport(groupID, sortOrder, value)
    },
    async fetchTasksReport(groupID, sortOrder = 'asc', sortBy = 'name') {
      this.tasksReport.loading = true

      const { data, headers } = await getActionPlansSummaryAssignees({
        groupID,
        limit: 9,
        offset: (this.tasksReport.page - 1) * 9,
        sortOrder,
        sortBy,
      })
      this.tasksReport.loading = false

      this.tasksReport.items = data.map((item) => {
        return {
          id: item.assignee.id,
          name: item.assignee.name,
          photo: item.assignee.photo,
          avatar: item.assignee.avatar,
          open: item.open,
          closed: item.closed,
          total: item.total,
        }
      })

      const xCount = parseInt(headers['x-count']) || 0

      this.tasksReport.pageLength = Math.ceil(xCount / 9)
    },
    async fetchVariation() {
      const { data } = await getVariation({
        groupID: this.groupID,
        period: this.variationPeriod,
      })

      this.variation = data
    },
  },
  mounted() {
    this.$store.commit('setCurrentTab', 'ActionPlanPage')
    this.fetchSummary(this.groupID)
    this.fetchSummaryHistory(this.groupID)
    this.fetchTasksReport(this.groupID)
    this.fetchVariation()
  },
}
</script>
