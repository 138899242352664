const answers = [
  {
    answerID: 'coachingMentoring',
  },
  {
    answerID: 'fun',
  },
  {
    answerID: 'continuousImprovement',
  },
  {
    answerID: 'insecurityEmployment',
  },
  {
    answerID: 'coherence',
  },
  {
    answerID: 'frugality',
  },
  {
    answerID: 'recognition',
  },
  {
    answerID: 'alertness',
  },
  {
    answerID: 'holisticThinking',
  },
  {
    answerID: 'individualism',
  },
  {
    answerID: 'rigidity',
  },
  {
    answerID: 'control',
  },
]
const emojis = ['😠', '🙁', '😐', '🙂', '😄']

const calcScore = (index) => {
  if (index <= 3) {
    return -2
  }

  if (index <= 5) {
    return -1
  }

  if (index <= 6) {
    return 0
  }

  if (index <= 8) {
    return 1
  }

  if (index >= 9) {
    return 2
  }
}

const calcFelling = (score) => {
  const fellingPerScore = {
    verybad: -2,
    bad: -1,
    neutral: 0,
    good: 1,
    verygood: 2,
  }

  return Object.keys(fellingPerScore).find(
    (key) => fellingPerScore[key] === score
  )
}

const calcOffsetScore = (index) => {
  const neutral = '😐'
  const offset = emojis.indexOf(neutral)

  return index - offset
}

const mixin = {
  methods: {
    getEmojis(question, submetric = {}, metric = {}) {
      if (!question) {
        return
      }

      const { type } = question

      switch (type) {
        case 'percent':
          return this.generateItemsTypePercent(question, metric)
        case 'radio-card':
          return this.generateItemsTypeRadioCard(question, metric)
        case 'radio-string':
          return this.generateItemsTypeRadioString()
        case 'slide-number':
          return this.generateItemsTypeSlideNumber()
        case 'star-rating':
          return this.generateItemsTypeStarRating(question)
        case 'checkbox-string':
          return this.generateItemsTypeCheckboxString()
        default:
          return answers
      }
    },

    camelToKebabCase(str) {
      if (!str) return
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    },

    generateItemsTypePercent(question, metric) {
      const { frequencies } = question

      if (!Array.isArray(frequencies)) {
        return []
      }

      return frequencies
        .map((item) => {
          if (item.score !== null && Number.isInteger(item.score)) {
            const felling = calcFelling(item.score)

            return {
              score: item.score,
              image: `https://s3.amazonaws.com/teamculture/${
                question.type
              }s/${this.camelToKebabCase(metric.metricID)}/${
                question.questionID
              }/${felling}.svg`,
            }
          }
        })
        .filter(Boolean)
    },

    generateItemsTypeRadioCard(question, metric) {
      const { frequencies } = question

      if (!Array.isArray(frequencies)) {
        return []
      }

      return frequencies
        .map((item) => {
          if (
            item.score !== null &&
            Number.isInteger(item.score) &&
            item.score !== 0
          ) {
            const felling = calcFelling(item.score)

            return {
              score: item.score,
              image: `https://s3.amazonaws.com/teamculture/${question.type}s/${metric.metricID}/${question.questionID}/${felling}.svg`,
            }
          }
        })
        .filter(Boolean)
    },

    generateItemsTypeRadioString() {
      return emojis.map((emoji, index) => ({
        ...answers[index],
        emoji: emoji,
        score: calcOffsetScore(index),
      }))
    },

    generateItemsTypeSlideNumber() {
      const items = Array.from({ length: 11 }, (_, index) => ({
        text: index.toString() ? index.toString() : '1',
        score: calcScore(index),
      }))

      return items
    },

    generateItemsTypeStarRating(question) {
      const { frequencies } = question

      if (!Array.isArray(frequencies)) {
        return []
      }

      return frequencies
        .map((item) => {
          if (item.score !== null && Number.isInteger(item.score)) {
            return {
              score: item.score,
            }
          }
        })
        .filter(Boolean)
    },

    generateItemsTypeCheckboxString() {
      return answers.map((item) => ({
        ...item,
        image: `https://static.teamculture.com.br/images/culture/${item.answerID}.svg`,
      }))
    },
  },
}

export default mixin
