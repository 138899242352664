<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="closeDialog"
    width="969px"
    height="max-content"
  >
    <v-card>
      <div class="title-container">
        <p class="title-container-title" v-text="title"></p>
        <v-btn class="button-close" icon @click="closeDialog" small>
          <v-icon size="12" class="fi fi-br-cross"></v-icon>
        </v-btn>
      </div>

      <v-divider class="custom-divider"></v-divider>

      <DetailBehavior
        :data="behaviorData"
        :metrics="behaviorHistory.metrics"
        :dates="behaviorHistory.dates"
        :type="rankingBy"
        :hideMetrics="hideMetrics"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import DetailBehavior from '@/components/DetailBehavior/DetailBehavior.vue'

export default {
  props: {
    title: String,
    behaviorHistory: Object,
    behaviorData: Object,
    hideMetrics: {
      type: Boolean,
      default: false,
    },
    rankingBy: {
      type: String,
      default: 'position',
    },
  },
  components: { DetailBehavior },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    openDialog() {
      this.isOpen = true
    },
    closeDialog() {
      this.isOpen = false
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
