<template>
  <div id="history-chart" class="content">
    <div v-if="loading">
      <v-skeleton-loader type="button" class="ma-2 skeleton-history" />
    </div>
    <div v-if="isEmpty" class="empty-info">
      {{ noDataLabel || $t('historyChart.noData') }}
    </div>
    <div v-if="!loading && !isEmpty" class="content-icons">
      <div
        @click="handleZoom('in')"
        class="content-icons__zoom"
        id="zoomIn"
        :class="{ disable: countZoom === 0 }"
      >
        <i class="fi fi-rr-add"></i>
      </div>
      <div
        @click="handleZoom('out')"
        class="content-icons__zoom"
        id="zoomOut"
        :class="{ disable: countZoom === 3 }"
      >
        <i class="fi fi-rr-circle"></i>
        <i class="fi fi-rr-minus-small overlay"></i>
      </div>
    </div>
    <apexchart
      v-if="!loading"
      type="line"
      :height="height"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'HistoryChart',
  data() {
    return {
      countZoom: 1,
    }
  },
  props: {
    noDataLabel: {
      type: String,
      default: '',
    },
    vision: {
      type: String,
      default: '',
    },
    metrics: {
      type: Array,
      default: () => [],
      require: true,
    },
    dates: {
      type: Array,
      default: () => [],
      require: true,
    },
    hideSingleSerie: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 300,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tooltipValueInteger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSingleSerie() {
      if (this.hideSingleSerie) {
        return false
      }
      return !this.emptyMetrics
    },
    isEmpty() {
      return !this.loading && (this.emptyMetrics || this.emptyDates)
    },
    emptyMetrics() {
      if (this.metrics.length === 0) return true
      return this.metrics.some((metric) => metric.scores.length === 0)
    },
    emptyDates() {
      return this.dates.length === 0
    },
    series() {
      return this.metrics.map((metric) => ({
        name: metric.name,
        data: metric.scores,
      }))
    },
    categories() {
      return this.dates.map((date) =>
        moment(date, this.$t('historyChart.dateFormat.api')).format(
          this.$t('historyChart.dateFormat.apex')
        )
      )
    },
    xaxis() {
      return {
        ...this.toDateInterval(),
        ...this.toTickAmount(),
        ...this.toLabels(),
      }
    },
    options() {
      const vm = this
      return {
        colors: [
          '#feb019',
          '#00e396',
          '#26a0fc',
          '#f700fb',
          '#fb005d',
          '#6100fb',
          '#00c1fb',
          '#fb8b00',
          '#fb0000',
          '#55fb00',
          '#fb007f',
          '#86a6ff',
          '#ff86df',
        ],
        chart: {
          id: 'chart',
          height: vm.height,
          type: 'line',
          fontFamily: 'poppins',
          toolbar: {
            tools: {
              download: true,
              pan: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
            },
          },
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler()
            },

            beforeResetZoom() {
              if (vm.countZoom !== 1) {
                vm.handleZoom('reset')
                return
              }

              return {
                xaxis: {
                  type: 'datetime',
                  categories: vm.categories,
                  ...vm.toDateInterval(),
                  ...vm.toTickAmount(),
                  ...vm.toLabels(),
                },
              }
            },
          },
          animations: {
            enabled: false,
          },
        },
        legend: {
          showForSingleSeries: this.isSingleSerie,
        },
        tooltip: {
          marker: {
            show: true,
          },
          x: {
            formatter(value) {
              return moment(value).format(vm.$t('historyChart.dateFormat.day'))
            },
          },
          y: {
            formatter(value) {
              if (
                typeof value !== 'undefined' &&
                value != null &&
                isNaN(value) === false
              ) {
                const number = vm.tooltipValueInteger ? 0 : 2
                return value
              }
              return null
            },
          },
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: vm.categories,
          ...vm.xaxis,
        },
        yaxis: {
          labels: {
            formatter(value) {
              return parseInt(value)
            },
          },
        },
      }
    },
  },
  methods: {
    currentVision() {
      return {
        0: 'biweekly',
        1: 'quarter',
        2: 'months',
        3: 'years',
      }[this.countZoom]
    },
    toTickAmount() {
      return {
        tickAmount: {
          biweekly: 7,
          quarter: 6,
          months: 12,
          years: 5,
        }[this.currentVision()],
      }
    },
    toDateInterval() {
      if (this.emptyDates) {
        return {
          min: moment().subtract(90, 'days').valueOf(),
          max: moment().valueOf(),
        }
      }
      const recentDate = moment(this.dates[0])
      return {
        biweekly: {
          min: recentDate.subtract(15, 'days').valueOf(),
        },
        quarter: {
          min: recentDate.subtract(90, 'days').valueOf(),
        },
        months: {
          min: recentDate.subtract(12, 'months').valueOf(),
        },
        years: {
          min: recentDate.subtract(5, 'years').valueOf(),
        },
      }[this.currentVision()]
    },
    toLabels() {
      const vm = this
      return {
        labels: {
          formatter(value) {
            const date = new Date(value)
            return moment(date).format(
              vm.$t(`historyChart.dateFormat.${vm.currentVision()}`)
            )
          },
        },
      }
    },
    handleZoom(type) {
      if (type === 'in') {
        this.countZoom -= 1
      }

      if (type === 'out') {
        this.countZoom += 1
      }

      if (type === 'reset') {
        this.countZoom = 1
      }
    },
  },
  beforeMount() {
    moment().locale(this.$t('historyChart.dateLocale'))
    this.countZoom = ['biweekly', 'quarter', 'months', 'years'].indexOf(
      this.vision
    )
    if (this.countZoom === -1) {
      this.countZoom = 1
    }
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
