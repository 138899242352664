<template>
  <div id="preferences" class="d-flex flex-column">
    <v-card-title class="card-title">
      <span class="neutral2--text">
        {{ $t('cultureManagement.groupSelector.menu.preferences.title') }}
      </span>

      <v-spacer />

      <a class="info--text" @click.prevent="back()">
        {{ $t('cultureManagement.groupSelector.menu.preferences.back') }}
      </a>
    </v-card-title>

    <v-divider />

    <section class="content content-preferences">
      <div class="d-flex align-center">
        <v-checkbox
          v-model="preferences.selectChildren"
          class="select-children my-0"
          hide-details
        />

        <span class="item-label neutral5--text">
          {{
            $t(
              'cultureManagement.groupSelector.menu.preferences.options.selectChildren'
            )
          }}
        </span>
      </div>
    </section>

    <v-divider />

    <v-row no-gutters>
      <v-col cols="6">
        <v-btn class="text-none" height="33" block text tile @click="clear()">
          <span class="bttn-label">
            {{ $t('cultureManagement.groupSelector.menu.preferences.clear') }}
          </span>
        </v-btn>
      </v-col>

      <v-col cols="6">
        <v-btn
          data-test-preferences-button-apply
          class="text-none rounded-br-lg"
          color="primary"
          height="33"
          block
          tile
          @click="submit()"
        >
          <span class="bttn-label">
            {{ $t('cultureManagement.groupSelector.menu.preferences.apply') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      preferences: {
        selectChildren: false,
      },
    }
  },

  methods: {
    submit() {
      this.$emit('apply', this.preferences)
      this.back()
    },

    clear() {
      this.preferences = {
        selectChildren: false,
      }
    },

    back() {
      this.$emit('back')
    },
  },
}
</script>

<style src="../style.scss" lang="scss" scoped />
