import api from '@/service/Http'

/**
 * @type {ParticipationResults}
 *
 * @property {String} date
 * @property {Number} surveyCycle
 * @property {Number} responders
 * @property {Number} confirmed
 * @property {Number} confirmedRespondersRate
 * @property {Number} invited
 * @property {Number} invitedRespondersRate
 */

export async function getEngagementParticipation(groupID) {
  const params = {}

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get('/engagement/participations', {
    params,
  })
}
/**
 * Histórico de Participação
 * @type {ParticipationHistory}
 *
 * @property {Array<Metrics>} invitedRespondersRate
 * @property {Array<Metrics>} confirmedRespondersRate
 * @property {Array<String>} dates
 */
/**
 * Retorna o histórico simplificado de clima
 * @param {String} groupID Código único do grupo
 * @returns {ParticipationHistory}
 */
export async function getParticipationResultsHistory(groupID) {
  return (await api()).get('/engagement/participations/history', {
    params: { groupID },
  })
}

/**
 * @typedef {Object} Variation
 * @property {Number} confirmed
 * @property {Number} confirmedRespondersRate
 * @property {Number} invited
 * @property {Number} invitedRespondersRate
 * @property {Number} responders
 */

/**
 * Retorna a variação do contexto de participação
 * @param {String} groupID Código único do grupo
 * @param {String} period Período da variação
 * @returns {Variation}
 */
export async function getVariation({ groupID = null, period = 'real-time' }) {
  const params = { period }
  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get('/engagement/participations/variation', {
    params,
  })
}

/**
 * Exporta a participação
 * @param {Object} payload
 * @param {String} payload.groupID Código único do grupo
 * @returns {Blob}
 */
export async function exportParticipation(payload) {
  return (await api()).post('/engagement/participations/export', payload, {
    responseType: 'blob',
  })
}
