<template>
  <div class="survey-other__container">
    <div
      data-test-survey-other-subject-title
      class="survey-other__title"
      tabindex="0"
    >
      <span
        v-text="
          'Realize o Scan do QRCode para convidar sua equipe para plataforma.'
        "
      />
      <img class="content-card-image" :src="shareImage" alt="" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getSubjects, getSurvey } from '@/service/survey'
// import AnswerCheckbox from '@/components/AnswerCheckbox/AnswerCheckbox'
import shareImage from '@/assets/qr-code-b.png'

export default {
  name: 'SurveyOther',
  // components: { AnswerCheckbox },
  data() {
    return {
      shareImage,
      /** { image, label, metricID, type } **/
      items: [],
    }
  },
  beforeMount() {
    // this.fetchSubjects()
  },
  computed: {
    ...mapState(['survey']),
  },
  methods: {
    ...mapActions([
      'setSurveyState',
      'setSurveyQuestions',
      'setSurveyStepIndex',
      'setSurveyOptions',
    ]),

    handleInput(data) {
      if (!data) return

      this.survey.haveAnswer = data.some((e) => e.vote)

      const answered = data.find((e) => e.vote)
      if (!answered) return

      const result = this.items.find((e) => e.answerID === answered.answerID)
      if (!result) return

      const payload = { type: result.type, metricID: result.answerID }

      this.setSurveyOptions(payload)
      getSurvey(payload).then(({ data }) => {
        this.setSurveyQuestions({ questions: data })
        this.setSurveyStepIndex({ index: 0 })
        this.setSurveyState({ state: 'steps' })
      })
    },
    async fetchSubjects() {
      const subjects = await getSubjects()
        .then(({ data }) => data)
        .catch((err) => {
          console.log(err.message)
        })

      this.items = subjects.map((e) => ({
        answerID: e.metricID,
        description: '',
        image: e.image,
        text: e.label,
        type: e.type,
      }))
    },
  },
}
</script>

<style scoped lang="scss" src="./style.scss" />
