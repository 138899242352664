<template>
  <div v-if="showIframeForm" class="iframe-form">
    <iframe title="Formulário" src="https://teamculture.wispform.com/068c44e1">
      <v-img
        class="not-found-bg"
        alt="background"
        :src="require('@/assets/images/NotFound/background.png')"
      ></v-img>
    </iframe>
  </div>
  <div
    v-else
    class="d-flex flex-column align-self-center align-center"
    data-test-survey-page
  >
    <div v-if="_accessibility" class="d-flex justify-center">
      <VideoComponent
        data-test-video-explanation-accessibility
        class="accessibility-video"
        :autoplay="true"
        :video="$t('survey.initPage.accessibility.videoURL')"
      />
    </div>

    <!--Body Labels-->
    <div class="d-flex flex-column" tabindex="0" data-test-title-survey>
      <!--Title-->
      <div class="d-flex flex-wrap page-title my-4 py-2 justify-center">
        <span v-text="$t('survey.initPage.title')" />
        <span class="ms-1 contrast" v-text="'Teamculture. 👋'" />
      </div>

      <!--Subtitle-->
      <span
        class="page-subtitle"
        v-text="$t('survey.initPage.subtitle')"
        data-test-subtitle-survey
      />
    </div>

    <!--Init Survey Button-->
    <div class="my-8">
      <v-btn
        id="survey-init-btn"
        data-test-button-survey
        data-test-survey-init-btn
        class="button pa-lg-6 pa-xl-8"
        elevation="0"
        abindex="0"
        :x-small="$vuetify.breakpoint.xsOnly"
        :title="$t('survey.initPage.accessibility.initSurvey')"
        @click="initSurvey"
      >
        {{ $t('survey.initPage.button') }}
        <v-icon
          right
          :x-small="$vuetify.breakpoint.mdAndDown"
          v-text="'fi-rr-arrow-small-right'"
        />
      </v-btn>
    </div>

    <!--Accessibility Float Button-->
    <v-fab-transition>
      <div class="d-flex align-center float" data-test-accessibility-area>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              v-if="$vuetify.breakpoint.mdAndUp"
              class="float-text"
            >
              <span
                class="align-self-center"
                v-text="$t('survey.initPage.floatButton')"
              />
            </div>

            <v-btn
              v-bind="attrs"
              v-on="on"
              data-test-survey-accessibility-btn
              id="survey-accessibility-btn"
              :x-large="$vuetify.breakpoint.mdAndUp"
              fab
              @click="setAccessibility"
            >
              <v-img
                v-if="!_accessibility"
                :src="require('@/assets/images/Survey/Accessibility1.svg')"
              />
              <v-img
                v-else
                :src="require('@/assets/images/Survey/Accessibility2.svg')"
              />
            </v-btn>
          </template>

          <div class="tooltip-label">
            <span v-if="!_accessibility">{{
              $t('survey.initPage.tooltipDesativateFloatButton')
            }}</span>
            <span v-else>{{
              $t('survey.initPage.tooltipActivateFloatButton')
            }}</span>
          </div>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { getSurvey, putSignLanguage } from '@/service/survey'
import VideoComponent from '@/components/VideoComponent/VideoComponent'

import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'SurveyInit',
  components: { VideoComponent },
  props: {
    showIframeForm: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    getIsSignedIn(value) {
      if (value) this.defineAccessibilityByKeyPermission()
    },
  },
  computed: {
    ...mapState(['survey']),
    ...mapGetters(['getIsSignedIn']),
    _accessibility: function () {
      return this.survey.accessibility
    },
    _canEngagementSurveyLibras() {
      return this.$can('access', _permissions.engagement_survey_libras)
    },
  },
  mounted() {
    this.setSurveyState({ state: 'init' })
  },
  methods: {
    ...mapActions([
      'setSurveyQuestions',
      'setSurveyState',
      'setSurveyAccessibility',
    ]),

    initSurvey() {
      const payload = {
        type: this.survey.options.type,
        metricID: this.survey.options.metricID,
        accessibility: this._accessibility,
      }
      getSurvey(payload)
        .then(({ data }) => {
          this.setSurveyQuestions({ questions: data })
          this.setSurveyState({ state: 'steps' })
        })
        .catch((err) => console.log(err.message || err))
    },
    setAccessibility() {
      const payload = { activate: !this._accessibility }

      putSignLanguage(payload).then(() => {
        this.setSurveyAccessibility(payload.activate)
      })
    },
    openHelp() {
      window.open(this.$t('survey.initPage.helpLink'), '_blank')
    },
    defineAccessibilityByKeyPermission() {
      if (this._canEngagementSurveyLibras) {
        this.setSurveyAccessibility(this._canEngagementSurveyLibras)
      }
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
