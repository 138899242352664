<template>
  <div id="treeview" class="d-flex flex-column">
    <v-card-title class="card-title">
      <span class="neutral2--text">
        {{ $t('cultureManagement.groupSelector.menu.treeview.title') }}
      </span>

      <v-spacer />

      <a
        class="info--text"
        @click="$emit('show-preferences')"
        data-test-preferences-button
      >
        {{ $t('cultureManagement.groupSelector.menu.treeview.preferences') }}
      </a>
    </v-card-title>

    <v-divider />

    <section class="content">
      <v-treeview
        v-model="selection"
        ref="tree"
        class="tree custom-treeview"
        item-text="label"
        selected-color="primary"
        item-key="id"
        :selection-type="selectChildren ? 'leaf' : 'independent'"
        :items="items"
        return-object
        selectable
      >
        <template v-slot:label="{ item }">
          <span class="item-label neutral5--text">
            {{ item.label }}
          </span>
        </template>
      </v-treeview>
    </section>

    <v-divider />

    <v-row no-gutters>
      <v-col cols="6">
        <v-btn class="text-none" height="33" block text tile @click="clear()">
          <span class="bttn-label">
            {{ $t('cultureManagement.groupSelector.menu.treeview.clear') }}
          </span>
        </v-btn>
      </v-col>

      <v-col cols="6">
        <v-btn
          data-test-button-apply
          class="text-none rounded-br-lg"
          color="primary"
          height="33"
          block
          tile
          @click="submit()"
        >
          <span class="bttn-label">
            {{ $t('cultureManagement.groupSelector.menu.treeview.apply') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      allParentNodes: true,
      selection: [],
    }
  },

  props: {
    setSelection: [],
    items: [],
    selectChildren: null,
    startSelection: [],
    wiredGroup: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    setSelection() {
      this.selection = this.setSelection
    },
    startSelection() {
      this._startValue()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.selection = this.setSelection
    })
    this._startValue()
  },

  computed: {
    _items() {
      const replaceChildren = (obj, parent) => {
        const clone = Object.assign({}, obj)
        delete clone.children

        if (parent) clone.parent = parent
        return clone
      }

      const addItems = (arr, parent) => {
        const items = arr.reduce((acc, e) => {
          acc.push(replaceChildren(e, parent))

          if (e.children) {
            acc.push(addItems(e.children, e.id))
          }
          return acc
        }, [])

        return items.flat()
      }

      return addItems(this.items).reduce((acc, e) => {
        acc[e.id] = e
        return acc
      }, {})
    },

    // clone selection with node parents
    _selection() {
      const proxy = {}

      const addParents = (e, all) => {
        const parentId = this._items[e.id].parent
        if (parentId) {
          if (all) addParents(this._items[parentId])
          proxy[parentId] = this._items[parentId]
        }
      }

      this.selection.forEach((e) => {
        addParents(e, this.allParentNodes)
        proxy[e.id] = e
      })

      return Object.values(proxy)
    },
  },

  methods: {
    ...mapMutations(['setGroupIds']),

    submit() {
      let groups = []

      if (this.selection.length > 0) groups = this._selection.map((e) => e.id)

      if (this.wiredGroup) {
        this.setGroupIds(groups)
      }

      this.$emit('submit', this.selection)
    },

    clear() {
      this.selection = []
      this.$emit('clear')
    },

    _startValue() {
      this.selection = this.startSelection
    },
  },
}
</script>

<style src="../style.scss" lang="scss" scoped />
