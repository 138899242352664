<template>
  <v-btn
    text
    color="primary"
    class="btn-new-action-plan"
    @click="newActionPlan"
  >
    {{ $t('btnNewActionPlan') }}
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonNewActionPlan',
  inject: ['actionPlan'],
  props: {
    metricID: String,
    groupID: String,
  },
  methods: {
    newActionPlan() {
      this.actionPlan.new = {
        metric: {
          id: this.metricID || '',
        },
        type: 'climate',
        group: {
          id: this.groupID || '',
        },
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
