<template>
  <v-card
    id="listOfItems"
    data-test-list-of-items
    class="items-container"
    v-click-outside="clickOutside"
    elevation="0"
  >
    <template v-for="(item, index) in items">
      <div
        data-test-list-of-items-item
        class="item-container"
        :class="[
          { 'item-hover': hasHover },
          'item-' + item.entityType,
          'item-' + item.entityClass,
          'item-' + item.status,
          { checked: index === checked },
        ]"
        :key="index"
        :checked="index === checked"
        @click="handleClick(item)"
      >
        <EntityDetail
          :item="item"
          :hasTooltip="hasTooltip"
          :secondLineFields="secondLineFields"
          :canTitleClick="hasClicked"
          @titleClicked="hasClicked ? $emit('titleClicked', item) : null"
        />
        <div class="item-right-actions">
          <div class="item-action-progress" v-if="hasBar">
            <BorderedItem
              v-if="item.entityType === 'initiative'"
              :data="haveStatus(item)"
              :tooltipOnHover="true"
              :tooltipAttrs="{
                top: false,
                bottom: true,
                'z-index': 203,
              }"
            />
            <ProgressBar
              v-else
              type="percentage"
              :data="progress(item)"
              :lockProgress="true"
              :hideFooter="true"
            />
          </div>
          <div
            v-if="hasRemove"
            data-test-list-of-items-item-remove
            class="item-action-remove"
            @click="handleRemove(item)"
          >
            <v-icon size="0.875rem">fi-rr-cross-circle</v-icon>
          </div>
          <div class="item-checked-icon" v-if="hasChecked && index === checked">
            <v-icon color="#DD0041">fi-br-check</v-icon>
          </div>
        </div>
      </div>
    </template>
    <div class="without-items" v-if="!hasItem">
      <div class="without-items-text">
        <span>{{ $t(`ListItems.noData${type}`) }}</span>
      </div>
    </div>
    <div
      v-if="hasAdd"
      class="add-item"
      :class="{ alignment: !hasItem }"
      @click="handleAddItem"
    >
      <v-icon color="primary">fi-sr-plus-small</v-icon>
      <span> {{ $t(`ListItems.add${type}`) }}</span>
    </div>
  </v-card>
</template>

<script>
import EntityDetail from '@/components/EntityDetail/EntityDetail.vue'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import BorderedItem from '@/components/DataTable/Parts/BorderedItem/BorderedItem.vue'
//import { mapGetters } from 'vuex'

export default {
  name: 'ListOfItems',
  components: { EntityDetail, ProgressBar, BorderedItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hasRemove: {
      type: Boolean,
      default: false,
    },
    hasBar: {
      type: Boolean,
      default: false,
    },
    hasAdd: {
      type: Boolean,
      default: false,
    },
    hasClicked: {
      type: Boolean,
      default: false,
    },
    secondLineFields: {
      type: Array,
      default: () => ['owners', 'group', 'cycle'],
    },
    type: {
      type: String,
      default: '',
    },
    hasHover: {
      type: Boolean,
      default: false,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    hasChecked: {
      type: Boolean,
      default: true,
    },
    checked: {
      type: Number,
    },
  },
  methods: {
    clickOutside() {
      this.$emit('clickOutside')
    },
    haveName(item) {
      if ('owners' in item) {
        if (item.owners.length !== 0) {
          return item.owners[0].name
        }
        return ''
      }
      return ''
    },
    getAdditionalNames(item) {
      if (item.owners.length < 2) return ''

      return ` +${item.owners.length - 1}`
    },
    havePhoto(item) {
      if ('owners' in item) {
        if (item.owners.length !== 0) {
          if (item.owners[0].photo) {
            return item.owners[0].photo
          }
          if (item.owners[0].image) {
            return item.owners[0].image
          }
        }
        return ''
      }
      return ''
    },
    haveID(item) {
      if ('owners' in item) {
        if (item.owners.length !== 0) {
          return item.owners[0].id
        }
        return ''
      }
      return ''
    },
    progress(item) {
      let status = 'on-time'
      let currentValue = 0

      if ('status' in item && item.status !== null) {
        status = item.status
      }

      if ('progress' in item && item.progress !== null) {
        currentValue = item.progress
      }

      return {
        status,
        currentValue,
        startValue: 0,
        goalValue: 100,
      }
    },
    itemTitle(title) {
      if (title.length > 116) {
        return title.slice(0, 116) + '...'
      }
      return title
    },
    haveStatus(item) {
      if (item.workflowStatus) {
        return {
          text: item.workflowStatus.label,
          //bgColor: this.workflowColors[item.workflowStatus.id],
        }
      }
      return { text: '--' }
    },
    handleRemove(item) {
      this.$emit('removeItem', item)
    },
    handleAddItem() {
      this.$emit('addItem')
    },
    handleClick(node) {
      this.$emit('clickedItem', node)
    },
  },
  computed: {
    //...mapGetters(['workflowColors']),
    hasItem() {
      return this.items.length !== 0
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
