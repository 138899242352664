var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{ref:"behaviorMenu",attrs:{"content-class":"content-menu elevation-0","close-on-content-click":false,"offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',{staticClass:"card-behaviors",attrs:{"data-test-card-behavior":""}},[_c('div',{staticClass:"card-behaviors-container"},[_c('div',{staticClass:"behavior-container"},[_c('div',{staticClass:"behavior-image"},[_c('v-img',{attrs:{"src":_vm.behavior.image,"alt":_vm.behavior.description,"max-height":"36px","max-width":"36px","width":"36px"}})],1),_c('div',{staticClass:"behavior-details"},[_c('div',{staticClass:"behavior-title-section"},[_c('div',{staticClass:"behavior-title"},[_c('span',{staticClass:"behavior-name",domProps:{"textContent":_vm._s(_vm.behavior.name)}}),_c('v-tooltip',{attrs:{"top":"","max-width":"18.75rem"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"tooltip-icon"},'span',attrs,false),on),[_c('v-icon',{staticClass:"fi-rr-interrogation",attrs:{"size":"0.75rem"}})],1)]}}],null,true)},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.behavior.description)}})])])],1),_c('div',{staticClass:"behavior-position"},[(_vm.orderBy === 'votes')?_c('span',{domProps:{"textContent":_vm._s(
                    _vm.behavior.position !== null
                      ? _vm.behavior.position + 'º'
                      : '--'
                  )}}):_c('span',{domProps:{"textContent":_vm._s(
                    _vm.behavior.positionProbability !== null
                      ? _vm.behavior.positionProbability + 'º'
                      : '--'
                  )}})])]),_c('div',{staticClass:"behavior-subtitle-section"},[_c('div',{staticClass:"behavior-more-details"},[_c('span',{domProps:{"textContent":_vm._s(
                    _vm.$t('engagementPage.culture.cultureCode.moreDetails')
                  )},on:{"click":_vm.openDetailBehaviorDialog}})]),_c('div',{staticClass:"behavior-info"},[_c('span',{staticClass:"behavior-votes",class:{
                    active: _vm.orderBy === 'votes',
                  },domProps:{"textContent":_vm._s(
                    _vm.$t('engagementPage.culture.cultureCode.countVotes', {
                      count: _vm.behavior.votes || 0,
                    })
                  )}}),_c('span',{staticClass:"behavior-percent",class:{
                    active: _vm.orderBy === 'probability',
                  },domProps:{"textContent":_vm._s(
                    _vm.behavior.probability ? _vm.behavior.probability + '%' : '0%'
                  )}})])])])]),_c('div',{staticClass:"card-action"},[_c('v-btn',_vm._g(_vm._b({staticClass:"card-link-company-value",attrs:{"data-test-bind-value":"","text":"","small":"","plain":""}},'v-btn',attrs,false),on),[_c('span',{domProps:{"textContent":_vm._s(
                '+ ' +
                _vm.$t('engagementPage.culture.cultureCode.bindToCompanyValue')
              )}})])],1)])])]}}])},[_c('QuickSelectionValues',_vm._g({attrs:{"values":_vm.values,"behavior":_vm.behavior},on:{"close":function($event){return _vm.$refs.behaviorMenu.save()}}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }