<template>
  <SkeletonLoader v-if="loading" :title="title" :type="videoType" />
  <div v-else :id="`card-history-${id}`" class="shadow">
    <v-card elevation="0" height="100%">
      <v-container class="px-3 py-2">
        <v-row>
          <v-col class="history">
            <label class="history-title">{{ title }}</label>
            <HowToBeginAndWatchAVideo
              class="mt-1 ml-1"
              :videoType="videoType"
              short
            />
          </v-col>
          <v-col
            class="stats"
            :class="{ 'stats-disable': disableChangeVision }"
            cols="auto"
            data-test-show-advanced-history
            @click="handleHistoryVision"
          >
            <div :class="changeVision ? 'stats-icon-changed' : 'stats-icon'">
              <i class="fi fi-br-stats"></i>
            </div>
            <label
              :class="changeVision ? 'stats-text-changed' : 'stats-text'"
              class="ml-1"
              >{{ changeVisionButton }}</label
            >
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="custom-divider" />
      <HistoryChart
        class="ma-4"
        :height="getHeight"
        :metrics="metrics"
        :dates="dates"
      />
      <BenchmarkBadge
        v-if="benchmark"
        :id="`benchmark-${id}`"
        class="ml-4"
        :rank="benchmark.rank"
        status="overallAverage"
        :locked="!benchmarkPermissions"
        :percentage="benchmark.score"
      />
    </v-card>
  </div>
</template>

<script>
import SkeletonLoader from './parts/SkeletonLoader.vue'
import HistoryChart from '@/components/HistoryChart/HistoryChart.vue'
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import BenchmarkBadge from '@/components/BenchmarkBadge/BenchmarkBadge.vue'

export default {
  name: 'CardHistory',
  components: {
    HistoryChart,
    HowToBeginAndWatchAVideo,
    BenchmarkBadge,
    SkeletonLoader,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    videoType: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    changeVisionButton: {
      type: String,
      default: '',
    },
    changeVision: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Array,
      default: () => [],
    },
    dates: {
      type: Array,
      default: () => [],
    },
    benchmark: {
      type: Object,
    },
    large: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: Number,
      default: 270,
    },
    maxHeight: {
      type: Number,
      default: 370,
    },
    benchmarkPermissions: {
      type: Boolean,
    },
  },
  computed: {
    getHeight() {
      if (this.large) {
        return this.maxHeight
      }
      return this.minHeight
    },
    disableChangeVision() {
      return (
        !this.changeVision &&
        (this.metrics.length === 0 || this.dates.length === 0)
      )
    },
  },
  methods: {
    handleHistoryVision() {
      this.$emit('handleHistoryVision')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
