<template>
  <v-banner class="no-border" sticky color="white" elevation="1">
    <div class="d-flex flex-row align-center">
      <div>
        <v-avatar
          v-if="prependTeamCultureIcon"
          class="mr-4 cursor-pointer"
          @click="emitClose()"
        >
          <v-img
            contain
            :alt="$t('cultureManagement.user')"
            :src="require('@/assets/images/Culture/Group1.png')"
          />
        </v-avatar>
      </div>
      <div v-if="backArrowIcon" @click="emitClose()" data-test-arrow-left>
        <v-icon class="cursor-pointer color-neutral-1">fi-br-arrow-left</v-icon>
      </div>
      <div v-if="!titleInput" class="ml-3">
        <span v-if="title" class="ml-0 pl-0 title-primary">
          {{ title }}
        </span>
        <v-subheader v-if="subTitle" class="ml-0 pl-0">
          {{ subTitle }}
        </v-subheader>
      </div>
      <div v-if="titleInput" class="ml-3 align-center input-container">
        <input
          type="text"
          class="text-field name-input"
          :placeholder="titleInputPlaceholder"
          v-model="propTitleInputValue"
          data-test-culture-code-name
        />
        <div class="pencil-icon" v-if="loadedMessage">
          <i class="fi-rr-pencil"></i>
        </div>
      </div>
      <v-spacer></v-spacer>
      <span class="saved-auto">
        <template v-if="!isLoading">{{ loadedMessage }}</template>
        <v-icon v-else class="icon-loading is-animating"
          >fi-rr-rotate-right</v-icon
        >
      </span>
      <span
        v-if="autoSaveLabel"
        class="auto-save-label mr-6"
        v-text="$t('cultureCode.header.autoSave')"
      ></span>
      <div
        v-if="howToBeginAndWatchAVideo"
        class="how-to-container flex-shrink-0"
      >
        <HowToBeginAndWatchAVideo :videoType="howToBeginAndWatchAVideoKey" />
      </div>
      <v-btn v-if="closeIcon" class="ml-1" icon @click="emitClose()">
        <v-icon>fi-rr-cross</v-icon>
      </v-btn>
    </div>
  </v-banner>
</template>
<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

export default {
  name: 'Header',
  components: { HowToBeginAndWatchAVideo },
  props: {
    prependTeamCultureIcon: {
      type: Boolean,
      default: false,
    },
    backArrowIcon: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadedMessage: {
      type: String,
      default: '',
    },
    howToBeginAndWatchAVideo: {
      type: Boolean,
      default: false,
    },
    howToBeginAndWatchAVideoKey: {
      type: String,
      default: 'culture.management',
    },
    title: {
      type: String,
      default: '',
    },
    titleInput: {
      type: Boolean,
      default: false,
    },
    titleInputValue: {
      type: String,
      default: '',
    },
    titleInputPlaceholder: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    autoSaveLabel: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      interval: null,
    }
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
    inputDebounce() {
      clearTimeout(this.interval)
      this.interval = setTimeout(async () => {
        this.$emit('update:titleInput', this.titleInputValue)
      }, 500)
    },
  },
  computed: {
    propTitleInputValue: {
      get() {
        return this.titleInputValue
      },
      set(value) {
        clearTimeout(this.interval)
        this.interval = setTimeout(async () => {
          this.$emit('update:titleInputValue', value)
        }, 500)
      },
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
