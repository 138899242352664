const languageMap = {
  en: 'en-US',
  es: 'es-AR',
  br: 'pt-BR',
}

/**
 * Calculates the language code to be used in an API request based on the input language code.
 * @param {string} lang - The input language code.
 * @returns {string} The language code to be used in the API request.
 */
export function calcLanguageToApi(lang) {
  const langAsLocale = calcLanguageToLocale(lang)
  return languageMap[langAsLocale] || 'pt-BR'
}

/**
 * Generates a language code based on the input language.
 * @param {string} lang - The language to generate the code for.
 * @return {string} The generated language code.
 */
export function calcLanguageToLocale(lang) {
  const language = lang?.match(/en(-US)?|es(-AR)?/i) ? lang.slice(0, 2) : 'br'
  return language
}
