<template>
  <div
    :class="[
      'overflow-hidden navbar-wrapper',
      _hasAnotherNavigation && 'navbar-wrapper-another-navigation',
    ]"
  >
    <v-bottom-navigation
      ref="navigation"
      v-model="tabModel"
      id="context-menu"
      class="page-nav"
      active-class="page-nav__active"
      background-color="neutral"
      height="50px"
      @change="handleNavigation"
    >
      <v-btn
        v-if="_canEngagementClimate"
        :ripple="false"
        class="page-nav__btn"
        value="ClimatePage"
        data-test-climate-tab
        :class="{ active: tabModel === 'ClimatePage' }"
      >
        <span>{{ $t('engagementPage.navigation.climate') }}</span>
      </v-btn>

      <v-btn
        v-if="_canEngagementCulture || _canBuy.CulturePage"
        class="page-nav__btn"
        value="CulturePage"
        data-test-culture-tab
        :class="{ active: tabModel === 'CulturePage' }"
      >
        <span>
          {{ $t('engagementPage.navigation.culture') }}
          <v-icon
            v-if="_canBuy.CulturePage"
            class="upgrade-icon"
            color="secondary"
            size="0.875rem"
          >
            fi-sr-crown
          </v-icon>
        </span>
      </v-btn>

      <v-btn
        v-if="_canEngagementParticipations"
        class="page-nav__btn"
        value="ParticipationPage"
        data-test-participation-tab
        :class="{ active: tabModel === 'ParticipationPage' }"
      >
        <span>{{ $t('engagementPage.navigation.participation') }}</span>
      </v-btn>

      <v-btn
        v-if="_canEngagementGroupsComparation || _canBuy.ComparisonPage"
        class="page-nav__btn"
        value="ComparisonPage"
        data-test-comparison-tab
        :class="{ active: tabModel === 'ComparisonPage' }"
      >
        <span>
          {{ $t('engagementPage.navigation.comparison') }}
          <v-icon
            v-if="_canBuy.ComparisonPage"
            class="upgrade-icon"
            color="secondary"
            size="0.875rem"
          >
            fi-sr-crown
          </v-icon>
        </span>
      </v-btn>

      <v-btn
        v-if="_canEngagementBenchmark || _canBuy.BenchmarkPage"
        data-test-benchmark-tab
        class="page-nav__btn"
        value="BenchmarkPage"
        :class="{ active: tabModel === 'BenchmarkPage' }"
      >
        <span>
          {{ $t('engagementPage.navigation.benchmark') }}
          <v-icon
            v-if="_canBuy.BenchmarkPage"
            class="upgrade-icon"
            color="secondary"
            size="0.875rem"
          >
            fi-sr-crown
          </v-icon>
        </span>
      </v-btn>

      <v-btn
        v-if="_canEngagementOpinions"
        class="page-nav__btn"
        value="OpinionsPage"
        data-test-opinions-tab
        :class="{ active: tabModel === 'OpinionsPage' }"
      >
        <span>{{ $t('engagementPage.navigation.opinions') }}</span>
      </v-btn>

      <v-btn
        v-if="_canEngagementActionPlans || _canBuy.ActionPlanPage"
        class="page-nav__btn"
        value="ActionPlanPage"
        data-test-action-plan-tab
        :class="{ active: tabModel === 'ActionPlanPage' }"
      >
        <span>
          {{ $t('engagementPage.navigation.actionPlan') }}
          <v-icon
            v-if="_canBuy.ActionPlanPage"
            class="upgrade-icon"
            color="secondary"
            size="0.875rem"
          >
            fi-sr-crown
          </v-icon>
        </span>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { _permissions } from '@/helpers/ability/engagement'
import scrollTabNavigation from '@/mixins/scrollTabNavigation'

import { mapGetters } from 'vuex'

export default {
  name: 'PageNavigation',

  inject: ['callUpdateYourPackage'],
  mixins: [scrollTabNavigation],

  data() {
    return {
      tabModel: '',
      navigationId: 'context-menu',
    }
  },

  computed: {
    ...mapGetters(['getCurrentTab', 'getIsBasicPlanAdminUser']),

    _hasAnotherNavigation() {
      if (this._canBuy?.[this.tabModel]) {
        return false
      }

      return ['BenchmarkPage', 'OpinionsPage', 'ActionPlanPage'].includes(
        this.tabModel
      )
    },

    _canBuy() {
      const showTabIfAvailable = (canAccess, IsBasicPlanAdminUser) => {
        if (canAccess) {
          return false
        }

        return IsBasicPlanAdminUser && !canAccess
      }

      return {
        CulturePage: showTabIfAvailable(
          this._canEngagementCulture,
          this.getIsBasicPlanAdminUser
        ),
        ComparisonPage: showTabIfAvailable(
          this._canEngagementGroupsComparation,
          this.getIsBasicPlanAdminUser
        ),
        BenchmarkPage: showTabIfAvailable(
          this._canEngagementBenchmark,
          this.getIsBasicPlanAdminUser
        ),
        ActionPlanPage: showTabIfAvailable(
          this._canEngagementActionPlans,
          this.getIsBasicPlanAdminUser
        ),
      }
    },

    _canEngagementClimate() {
      return this.$can('access', _permissions.engagement_climate)
    },
    _canEngagementCulture() {
      return this.$can('access', _permissions.engagement_culture)
    },
    _canEngagementOpinions() {
      return this.$can('access', _permissions.engagement_opinions)
    },
    _canEngagementActionPlans() {
      return this.$can('access', _permissions.engagement_action_plans)
    },
    _canEngagementBenchmark() {
      return this.$can('access', _permissions.engagement_benchmark)
    },
    _canEngagementGroupsComparation() {
      return this.$can('access', _permissions.engagement_groups_comparation)
    },
    _canEngagementParticipations() {
      return this.$can('access', _permissions.engagement_participations)
    },
  },

  methods: {
    handleNavigation(tab) {
      if (this._canBuy?.[tab]) {
        const PageTypes = {
          CulturePage: 'culture',
          ComparisonPage: 'variation',
          BenchmarkPage: 'benchmark',
          ActionPlanPage: 'action',
        }[tab]

        this.callUpdateYourPackage(PageTypes)
        return
      }

      if (!this.$router.currentRoute.path.includes(tab)) {
        this.$store.commit('setCurrentTab', tab)

        const alreadyOnRoute = this.$route.name === tab
        if (alreadyOnRoute) return

        this.$router.push({ name: tab }).catch((err) => !!err)
      }
    },
  },

  watch: {
    tabModel(newValue, oldValue) {
      if (this._canBuy?.[newValue]) {
        this.tabModel = oldValue
      }
    },

    getCurrentTab(val) {
      if (val !== this.tabModel) {
        this.$store.commit('setCurrentTab', val)
        this.tabModel = val
      }
    },
  },

  mounted() {
    this.scrollToTab(this.$route.name, this.navigationId)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
