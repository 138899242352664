<template>
  <LateralMenuItem
    class="mb-3"
    data-test-sidebar-favorite-filters
    :title="$t('favoriteFilters.title')"
    :isMini="mini"
  >
    <template v-slot:title-icon="{ size }">
      <v-icon
        id="favorite-filters-star-icon__end"
        :size="size"
        v-text="'fi-sr-star'"
      />
    </template>
    <template v-slot:default>
      <div class="d-flex flex-column">
        <v-skeleton-loader
          v-if="loading"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <!--Empty Stage-->
        <span
          data-test-span-empty-stage
          v-if="(!favorites.items || !favorites.items.length) && !loading"
          class="text__body-2"
          v-text="$t('favoriteFilters.empty')"
        />
        <!--Items-->
        <div v-else-if="!loading" class="d-flex flex-column">
          <div
            data-test-list-favorite
            v-for="(item, index) of favorites.items"
            :key="index"
            class="d-flex align-center"
          >
            <!--show-item-->
            <v-hover v-if="!isEditMode(index)" v-slot:default="{ hover }">
              <span class="favorite-filter__item" v-ripple>
                <span
                  class="py-1 px-2 text__no-select"
                  :class="[
                    hover || isSelected(index)
                      ? 'text__body-1'
                      : 'text__body-2',
                    isSelected(index) ? 'favorite-filter__item--selected' : '',
                  ]"
                  v-text="item.name"
                  @click="handleSelect(index)"
                />
                <v-btn
                  v-if="!_isEditing && hover"
                  small
                  icon
                  @click="handleEdit(index)"
                >
                  <v-icon x-small v-text="'fi-rr-pencil'" color="neutral2" />
                </v-btn>
              </span>
            </v-hover>

            <!--edit-item-->
            <v-text-field
              v-else
              data-test-input-text-field-favorite
              class="favorite-filter__edit text__body-2 mt-1"
              v-model="favorites.itemEditValue"
              :label="$t('favoriteFilters.label')"
              :rules="rules"
              hide-details="auto"
              autofocus
              outlined
              dense
              @focusout="handleSave"
              @keydown.enter="handleSave"
              @keydown.esc="handleCancel"
            >
              <template v-slot:append>
                <div class="d-flex">
                  <!--save-->
                  <v-btn
                    :disabled="!_isValidEdit"
                    icon
                    small
                    @click="handleSave()"
                  >
                    <v-icon color="success" x-small v-text="'fi-rr-check'" />
                  </v-btn>

                  <!--cancel-->
                  <v-btn icon small @click="handleCancel()">
                    <v-icon small v-text="'fi-rr-cross-small'" />
                  </v-btn>
                </div>
              </template>
            </v-text-field>

            <!--remove-item-->
            <div
              class="flex-grow-1 d-flex justify-end align-center"
              :class="_isEditing && 'favorite-filter__remove'"
            >
              <v-btn small icon @click="handleRemove(item)">
                <v-icon x-small v-text="'fi-rr-trash'" color="neutral5" />
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LateralMenuItem>
</template>

<script>
import { mapState } from 'vuex'
import LateralMenuItem from '@/components/LateralMenuItem/LateralMenuItem'

export default {
  name: 'FavoriteFilters',
  components: {
    LateralMenuItem,
  },
  props: {
    mini: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules: [(v) => !!v || this.$t('favoriteFilters.rules.required')],
    }
  },
  computed: {
    ...mapState(['favorites']),
    _isEditing() {
      return this.favorites.itemEditIndex != null
    },
    _isValidEdit() {
      return (
        !!this.favorites.itemEditValue &&
        this.favorites.itemEditValue.length > 0
      )
    },
  },
  methods: {
    isEditMode(index) {
      if (!this._isEditing) return
      return this.favorites.itemEditIndex == index
    },
    isSelected(index) {
      return this.favorites.itemSelectedIndex == index
    },
    handleSelect(index) {
      const selectedIndex = this.favorites.itemSelectedIndex

      this.favorites.itemSelectedIndex = selectedIndex != index ? index : null

      const item = this.favorites.items[this.favorites.itemSelectedIndex]

      if (item) this.$emit('select', item)
    },
    handleEdit(index) {
      this.favorites.itemEditIndex = index
      this.favorites.itemEditValue = this.favorites.items[index].name
    },
    handleRemove(item) {
      const payload = { id: item.id }
      this.$emit('remove', payload)
    },
    handleCancel() {
      this.handleResetEditMode()
    },
    handleSave() {
      if (!this.favorites.itemEditValue || !this.favorites.itemEditValue.length)
        return this.handleFocus()

      const index = this.favorites.itemEditIndex
      if (index == null) return

      this.favorites.items[index].name = this.favorites.itemEditValue

      const payload = {
        id: this.favorites.items[index].id,
        groups: this.favorites.items[index].groups,
        payload: { name: this.favorites.itemEditValue },
      }
      this.$emit('save', payload)

      this.handleResetEditMode()
    },
    handleFocus() {
      const element = document.querySelector('.favorite-filter__edit')
      if (!element) return

      return element.focus()
    },
    handleResetEditMode() {
      this.favorites.itemEditIndex = null
      this.favorites.itemEditValue = null
    },
  },
}
</script>

<style scoped lang="scss" src="./style.scss" />
