<template>
  <section id="culture-page" class="culture-container" data-test-culture-page>
    <v-row>
      <v-col sm="12" md="12" lg="4">
        <CardCulturalMaturity
          data-test-cultural-maturity-card
          :resultsBenchmark="resultsBenchmark"
          :cultureResults="cultureResults"
          :cultureRankByGroup="!!groupID"
          :loading="
            maturityLoading.resultsBenchmark || maturityLoading.cultureResults
          "
          :variation="variation.maturity"
        ></CardCulturalMaturity>
      </v-col>

      <v-col sm="12" md="12" lg="8">
        <CardHistory
          :loading="loading.history"
          id="cultureEvolution"
          :title="$t(`${currentVision}.title`)"
          :changeVisionButton="$t(`${currentVision}.changeVisionButton`)"
          :videoType="currentVision"
          :metrics="history.metrics"
          :dates="history.dates"
          :changeVision="changeVision"
          :large="!changeVision"
          :minHeight="200"
          :maxHeight="300"
          :benchmark="!changeVision ? null : benchmark"
          @handleHistoryVision="handleHistoryVision"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12">
        <BehaviorClassification
          :cultureResults="cultureResults"
          :groupID="groupID"
          videoType="culture.behaviorsRanking"
          :alertText="$t('culturePage.alertText')"
          :advancedHistory="advancedHistory"
          :completeVision="completeBehaviorVision"
          :detailBehaviorHistory="detailBehaviorHistory"
          @handleVision="handleBehaviorVision"
          @openDetailBehaviorDialog="openDetailBehaviorDialog"
          :unfavorableCultureVariation="variation.unfavorableCulture"
          :culturalAlignmentVariation="variation.culturalAlignment"
          :rankingVariation="variation.ranking"
        />
      </v-col>
    </v-row>

    <full-screen-modal :isShow="showCultureManagement" footer>
      <router-view></router-view>
      <template v-slot:footer>
        <v-spacer></v-spacer>

        <v-btn
          plain
          class="action-close"
          @click="closeCultureManagementModal()"
        >
          <span v-text="$t('cultureManagement.footer.close')"></span>
        </v-btn>
      </template>
    </full-screen-modal>
  </section>
</template>

<script>
import CardHistory from '@/components/CardHistory/CardHistory.vue'
import CardCulturalMaturity from './Partials/CardCulturalMaturity/CardCulturalMaturity.vue'
import BehaviorClassification from './Partials/BehaviorClassification/BehaviorClassification.vue'
import toggleCultureManagementModalMixins from '@/mixins/toggleCultureManagementModal.js'
const FullScreenModal = () =>
  import('@/components/FullScreenModal/FullScreenModal.vue')

import {
  getCultureResultsHistory,
  getCultureResultsAdvancedHistory,
  getCultureResults,
  getVariation,
} from '@/service/culture'

import {
  getBenchmarkResults,
  getBenchmarkResultsHistory,
} from '@/service/benchmark'

export default {
  components: {
    CardHistory,
    BehaviorClassification,
    FullScreenModal,
    CardCulturalMaturity,
  },
  mixins: [toggleCultureManagementModalMixins],
  name: 'CulturePage',
  props: {
    groupID: {
      type: String,
      default: '',
    },
    indicatorID: {
      type: String,
      default: 'culture',
    },
    variationPeriod: {
      type: String,
      default: 'real-time',
    },
  },

  data() {
    return {
      loading: {
        history: false,
      },
      history: {
        metrics: [],
        dates: [],
      },
      advancedHistory: {
        metrics: [],
        dates: [],
      },
      changeVision: false,
      currentVision: 'cardCultureEvolution',
      cultureResults: {
        maturity: {
          current: 0,
        },
        ranking: [],
        presentCulture: {},
        culturalAlignment: {},
        unfavorableCulture: {},
        score: 0,
      },
      benchmark: {
        rank: 0,
        score: 0,
        locked: false,
      },
      completeBehaviorVision: false,
      alreadyCompleteBehaviorVision: false,
      resultsBenchmark: {
        date: '',
        id: '',
        indicators: [
          {
            id: 'maturityLevel',
            account: { score: -1, rank: 0 },
            group: { score: -1, rank: 0 },
            average: { score: -1, rank: 0 },
          },
        ],
      },
      maturityLoading: {
        cultureResults: true,
        resultsBenchmark: true,
        cultureRank: true,
      },
      rank: 0,
      detailBehaviorHistory: {
        metrics: [],
        dates: [],
      },
      variation: {},
    }
  },

  computed: {
    showCultureManagement: {
      set(data) {
        this.$store.commit('toggleShowCultureManagement', data)
      },
      get() {
        return this.$store.getters.getShowCultureManagement
      },
      rank() {
        if (this.$props.groupID) {
          return this.resultsBenchmark.indicators[0].group &&
            this.resultsBenchmark.indicators[0].group.rank
            ? this.resultsBenchmark.indicators[0].group.rank
            : null
        }
        return this.resultsBenchmark.indicators[0].account &&
          this.resultsBenchmark.indicators[0].account.rank
          ? this.resultsBenchmark.indicators[0].account.rank
          : null
      },
    },
  },

  watch: {
    groupID(id) {
      this.alreadyCompleteBehaviorVision = false
      if (id === '') {
        this.fetchHistory()
        this.fetchBenchmark()
        if (this.completeBehaviorVision) {
          this.fetchCultureResultsAdvancedHistory()
        }
      }
      if (id) {
        this.fetchHistory(id)
        this.fetchBenchmark(id)
        if (this.completeBehaviorVision) {
          this.fetchCultureResultsAdvancedHistory(id)
        }
      }
      this.fetchGroupInfo(id)
      this.fetchVariation()
    },
    variationPeriod() {
      this.fetchVariation()
    },
  },

  methods: {
    closeCultureManagementModal() {
      this.toggleCultureManagementModal(false)
      this.$router.push({ name: 'CulturePage' })
    },
    checkNeedShowCultureManagementModal() {
      if (this.$route.name === 'cultureManagement')
        this.showCultureManagement = true
    },
    async fetchHistory(groupID) {
      if (this.changeVision) {
        this.fetchCultureBenchmarkHistory(groupID)
      } else {
        this.fetchCultureResultsHistory(groupID)
      }
    },
    async fetchCultureResultsHistory(groupID) {
      this.loading.history = true

      const { data } = await getCultureResultsHistory(groupID)
      if (!data.dates) {
        this.history = { metrics: [], dates: [] }
      } else {
        this.history = data
      }

      this.loading.history = false
    },
    async fetchCultureBenchmarkHistory(groupID) {
      this.loading.history = true
      this.maturityLoading.cultureRank = true

      const { data } = await getBenchmarkResultsHistory(
        'maturityLevel',
        groupID
      )
      if (!data.dates) {
        this.history = { metrics: [], dates: [] }
      } else {
        const history = { dates: data.dates, metrics: [] }
        const indicators = data.indicators[0]
        delete indicators.id
        const names = {
          group: this.$t('history.indicators.group'),
          account: this.$t('history.indicators.account'),
          average: this.$t('history.indicators.average'),
        }
        for (const metric in indicators) {
          history.metrics.push({
            name: names[metric],
            scores: indicators[metric],
          })
        }
        this.history = history
      }
      this.getRank()

      this.loading.history = false
      this.maturityLoading.cultureRank = false
    },
    async fetchBenchmark(groupID) {
      this.maturityLoading.resultsBenchmark = true

      const { data } = await getBenchmarkResults('maturityLevel', groupID)
      const benchmarks = data.indicators[0]
      this.resultsBenchmark = data
      if (!data.id) {
        this.benchmark = {
          score: 0,
          rank: 0,
          locked: false,
        }
      } else if (groupID === '' || groupID === undefined) {
        this.benchmark = {
          ...benchmarks.account,
          locked: false,
        }
      } else {
        this.benchmark = {
          ...benchmarks.group,
          locked: false,
        }
      }

      this.maturityLoading.resultsBenchmark = false
    },
    async fetchCultureResultsAdvancedHistory(groupID) {
      const { data } = await getCultureResultsAdvancedHistory(groupID)
      if (!data.dates) {
        this.advancedHistory = { metrics: [], dates: [] }
      } else {
        this.advancedHistory = data
      }
    },
    async handleHistoryVision() {
      this.currentVision = this.changeVision
        ? 'cardCultureEvolution'
        : 'cardMaturityLevel'
      this.changeVision = !this.changeVision
      const groupID = this.groupID !== '' ? this.groupID : undefined
      await this.fetchHistory(groupID)
    },
    async handleBehaviorVision() {
      this.completeBehaviorVision = !this.completeBehaviorVision
      localStorage.setItem(
        'preferences.culture.behaviorRankings',
        this.completeBehaviorVision
      )
      if (!this.alreadyCompleteBehaviorVision) {
        this.alreadyCompleteBehaviorVision = true
        const groupID = this.groupID !== '' ? this.groupID : undefined
        await this.fetchCultureResultsAdvancedHistory(groupID)
      }
    },
    async fetchGroupInfo(groupID) {
      this.maturityLoading.cultureResults = true

      const payload = {
        groups: groupID ? [groupID] : [],
      }

      const { data } = await getCultureResults(payload)
      this.cultureResults = data

      this.maturityLoading.cultureResults = false
    },
    getUserPreferences() {
      this.completeBehaviorVision =
        localStorage.getItem('preferences.culture.behaviorRankings') === 'true'
    },
    openDetailBehaviorDialog(behaviorID) {
      const groupID = this.groupID !== '' ? this.groupID : undefined
      this.fetchCulturalResultsAdvancedHistory(groupID, behaviorID)
    },
    async fetchCulturalResultsAdvancedHistory(groupID, behaviorID) {
      const { data } = await getCultureResultsAdvancedHistory(
        groupID,
        behaviorID
      )
      if (!data.dates) {
        this.detailBehaviorHistory.metrics = []
        this.detailBehaviorHistory.dates = []
      } else {
        this.detailBehaviorHistory.metrics = data.metrics
        this.detailBehaviorHistory.dates = data.dates
      }
    },
    getRank() {
      if (this.$props.groupID) {
        this.rank = this.resultsBenchmark.indicators[0].group.rank
      } else {
        this.rank = this.resultsBenchmark.indicators[0].account.rank
      }
    },
    async fetchVariation() {
      const groupID = this.groupID || null
      const period = this.variationPeriod

      const { data } = await getVariation({ groupID, period })

      this.variation = data
    },
  },

  mounted() {
    this.getUserPreferences()

    this.fetchGroupInfo(this.groupID)

    this.fetchHistory()
    this.fetchBenchmark()

    if (this.completeBehaviorVision) {
      this.fetchCultureResultsAdvancedHistory()
    }
    this.checkNeedShowCultureManagementModal()
    this.fetchVariation()
  },

  created() {
    this.$store.commit('setCurrentTab', 'CulturePage')
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
