<template>
  <div class="vertical-level" v-if="type">
    <div v-if="!isDisabled" class="vertical-level-bars">
      <div
        class="vertical-level-bars-group"
        data-test-vertical-level-bars-group
      >
        <div
          class="vertical-level-bar"
          v-for="(bar, i) in typesDefititions[type].bars"
          :key="i"
          :style="{
            backgroundColor: bar.color,
            height: levelHeight,
            top: levelPosition(i),
          }"
        ></div>
      </div>
      <div
        v-if="isValid(groupMarker)"
        id="groupMarkerBar"
        class="vertical-level-marker"
        :style="{ top: barPosition(groupMarker) }"
      >
        <div class="marker-container">
          <div class="marker-container-icon account">
            <i class="fi fi-rr-caret-right"></i>
          </div>
          <div class="marker-container-bar"></div>
          <slot name="groupMarker"></slot>
        </div>
      </div>
      <div
        v-if="isValid(accountMarker)"
        id="accountMarkerBar"
        class="vertical-level-marker"
        :style="{ top: barPosition(accountMarker) }"
      >
        <div class="marker-container">
          <div class="marker-container-icon account">
            <i
              :class="[
                'fi',
                {
                  'fi-rr-building': ['nps', 'culture'].includes(type),
                  'fi-rr-caret-right': type === 'behavior',
                },
              ]"
            ></i>
          </div>
          <div class="marker-container-bar"></div>
          <slot name="accountMarker"></slot>
        </div>
      </div>
      <div
        v-if="isValid(marketMarker)"
        id="marketMarkerBar"
        class="vertical-level-marker"
        :style="{ top: barPosition(marketMarker) }"
      >
        <div class="marker-container">
          <div class="marker-container-icon account">
            <i
              :class="[
                'fi',
                {
                  'fi-rr-flag': ['nps', 'culture'].includes(type),
                  'fi-rr-caret-right': type === 'behavior',
                },
              ]"
            ></i>
          </div>
          <div class="marker-container-bar"></div>
          <slot name="marketMarker"></slot>
        </div>
      </div>
    </div>
    <div class="vertical-level-labels">
      <span v-for="(label, i) in typesDefititions[type].labels" :key="i">
        {{ label }}
      </span>
    </div>
    <div v-if="isDisabled" id="disabledBar" class="vertical-level-marker">
      <div class="marker-container">
        <div class="marker-container-icon account">
          <i class="fi fi-rr-caret-right"></i>
        </div>
        <div class="marker-container-bar"></div>
      </div>
    </div>
    <div class="labels-subtitle" v-if="['nps', 'culture'].includes(type)">
      <div class="labels-subtitle-title">
        <span>{{ $t('verticalLevelBar.title') }}</span>
      </div>
      <div class="labels-subtitle-values d-flex flex-column">
        <div
          v-if="isValid(groupMarker)"
          id="group"
          class="labels-subtitle-values-single d-flex justify-space-between"
        >
          <div class="subtitle-name d-flex align-center">
            <i class="fi fi-rr-caret-right"></i>
            <span id="group-label">{{ $t('verticalLevelBar.group') }}</span>
          </div>
          <span class="label-value">
            {{ groupMarker }}
          </span>
        </div>
        <div
          v-if="isValid(marketMarker)"
          id="market"
          class="labels-subtitle-values-single d-flex justify-space-between"
        >
          <div class="subtitle-name d-flex align-center">
            <i class="fi fi-rr-flag"></i>
            <span id="market-label">{{ $t('verticalLevelBar.market') }}</span>
          </div>
          <span
            v-if="marketPermission"
            class="label-value"
            data-test-marketMarker
          >
            {{ marketMarker }}
          </span>
          <div
            @click="openUpdateYourPackageDialog('benchmark')"
            v-else
            id="market-locked"
            class="value-locked vertical-level-pointer"
          >
            <i class="fi fi-sr-lock"></i>
          </div>
        </div>
        <div
          v-if="isValid(accountMarker)"
          id="account"
          class="labels-subtitle-values-single d-flex justify-space-between"
        >
          <div class="subtitle-name d-flex align-center">
            <i class="fi fi-rr-building"></i>
            <span id="account-label">{{ $t('verticalLevelBar.account') }}</span>
          </div>
          <span class="label-value" data-test-account>
            {{ accountMarker }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'
import { mapMutations } from 'vuex'

export default {
  name: 'VerticalLevelBar',
  props: {
    type: {
      type: String,
      required: true,
      validator: function (val) {
        return ['nps', 'culture', 'behavior'].indexOf(val) !== -1
      },
    },
    groupMarker: {
      type: Number,
    },
    accountMarker: {
      type: Number,
    },
    marketMarker: {
      type: Number,
    },
    marketPermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const culture = {
      labels: [100, 50, 0],
      bars: [
        {
          color: variables.blueColor,
          min: 81,
          max: 100,
        },
        {
          color: variables.blueColor2,
          min: 61,
          max: 80,
        },
        {
          color: variables.yellowColor2,
          min: 41,
          max: 60,
        },
        {
          color: variables.orangeColor,
          min: 21,
          max: 40,
        },
        {
          color: variables.redColor,
          max: 20,
          min: 0,
        },
      ],
    }

    return {
      typesDefititions: {
        nps: {
          labels: [100, 50, -100],
          bars: [
            {
              color: variables.greenColor2,
              min: 76,
              max: 100,
            },
            {
              color: variables.greenColor,
              min: 51,
              max: 75,
            },
            {
              color: variables.yellowColor1,
              min: 1,
              max: 50,
            },
            {
              color: variables.redColor2,
              min: -100,
              max: 0,
            },
          ],
        },
        culture: culture,
        behavior: culture,
      },
    }
  },
  computed: {
    isDisabled() {
      return this.isValid(this.groupMarker) ||
        this.isValid(this.accountMarker) ||
        this.isValid(this.marketMarker)
        ? false
        : true
    },
    maxValue() {
      return this.labels[0]
    },
    minValue() {
      return this.labels[this.labels.length - 1]
    },
    unit() {
      return 100 / (Math.abs(this.maxValue) + Math.abs(this.minValue) + 1)
    },
    levelHeight() {
      return 100 / this.bars.length + '%'
    },
    bars() {
      return this.typesDefititions[this.type].bars
    },
    labels() {
      return this.typesDefititions[this.type].labels
    },
  },
  methods: {
    ...mapMutations(['openUpdateYourPackageDialog']),
    barPosition(val) {
      let pos
      this.bars.forEach((el, i) => {
        if (val >= el.min && val <= el.max) {
          let max = Math.abs(el.max)
          let min = Math.abs(el.min)
          let localUnit = 100 / Math.abs(max - min)
          let diff =
            (100 / this.bars.length) * ((Math.abs(max - val) * localUnit) / 100)
          let range = (100 / this.bars.length) * i
          pos = diff + range
        }
      })
      return pos + '%'
    },
    isValid(val) {
      return val >= this.minValue && val <= this.maxValue ? true : false
    },
    levelPosition(pos) {
      return (100 / this.bars.length) * pos + '%'
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
