import api from '@/service/Http'

export const getGroupsFullTree = async () =>
  (await api()).get('/management/groups/tree')

export const getGroupsResults = async (data) =>
  (await api()).post('/engagement/groups-comparison/groups-results', data)

export const getGroupsVariation = async (data) =>
  (await api()).post('/engagement/groups-comparison/groups-variation', data)

export const getAccountResults = async () =>
  (await api()).get('/engagement/groups-comparison/account-results')

export const getAccountVariation = async (data) =>
  (await api()).get(
    `/engagement/groups-comparison/account-variation?period=${data.period}&type=${data.type}`
  )

/**
 *
 * @param {Object} payload
 * @param {String} payload.type Tipo de relatório
 * @param {Array<String>} payload.groups groupIDs a serem exportados
 * @returns {Blob}
 */
export async function exportGroupsComparison(payload) {
  return (await api()).post('/engagement/groups-comparison/export', payload, {
    responseType: 'blob',
  })
}
