<template>
  <div data-test-tree-view id="treeview">
    <div
      data-test-tree-view-filtered-items
      ref="treeview"
      v-if="filteredItems.length > 0"
    >
      <template v-for="(item, index) in filteredItems">
        <node-item
          data-test-tree-view-node-item
          :key="index"
          :alwayExpand="alwayExpand"
          :node="item"
          :actives="actives"
          :opened="opened"
          :hideElements="hideElements"
          :showRemoveIcon="!persistent"
          @update:active="submit($event)"
          @update:expanded="handleExpanded($event)"
        />
      </template>
    </div>

    <div v-else class="empty" data-test-tree-view-empty>
      <span class="content-label">{{ $t('itemSelector.noResults') }}</span>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import NodeItem from '../NodeItem/NodeItem.vue'

import {
  BreakAsThreadPromises,
  SearchWithAbortController,
  BigSearchToUniqueItems,
} from '@/helpers/search'

export default {
  components: { NodeItem },

  data() {
    return {
      filteredItems: [],
      actives: [],
      opened: [],

      historySearchControllers: {},
      loading: false,
    }
  },

  props: {
    selectedItem: null,

    items: {
      type: Array,
    },

    tab: {
      type: String,
      default: '',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    search: {
      type: String,
      default: '',
    },

    hideElements: {
      type: Array,
      default: () => [],
    },

    alwayExpand: {
      type: Boolean,
      default: false,
    },

    highlightQuantity: {
      type: Number,
      default: 4,
    },

    submitMode: {
      type: Boolean,
      default: false,
    },

    showAvatar: {
      type: Boolean,
      default: true,
    },

    selectHierarchy: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    items() {
      this.handleItems()
    },

    hideElements() {
      this.handleItems()
    },

    selectedItem: {
      handler(v) {
        this.resetCurrentActive(v)
      },
      immediate: true,
      deep: true,
    },

    search(v) {
      this.searchItems(v)
    },
  },

  beforeMount() {
    this.handleItems()

    if (this.selectedItem) {
      this.resetCurrentActive(this.selectedItem)
    }

    if (this.selectedItem) {
      this.actives = this.selectedItem.data
    }
  },

  methods: {
    submit(data) {
      const indexOfDataOnActives = this.actives.findIndex(
        (e) => e.id === data.id
      )
      const isDataNotOnActives = indexOfDataOnActives < 0

      const isDataOriginSame =
        !!this.selectedItem && this.selectedItem.origin === this.tab
      if (!isDataNotOnActives && isDataOriginSame) {
        this.removeItemFromActives(indexOfDataOnActives)
      }

      if (isDataNotOnActives) {
        this.addItemToActives(data)
      }

      this.$emit('submit', {
        data: this.actives,
        origin: this.tab,
      })

      if (this.submitMode) {
        this.actives = []
      }
    },

    removeItemFromActives(indexOfItem) {
      if (this.persistent && this.actives.length <= 1) {
        return
      }

      if (this.multiple && this.selectHierarchy) {
        const item = this.actives[indexOfItem]
        const family = this.handlehierarchy([item])

        family.forEach((el) => {
          const index = this.actives.findIndex((active) => active.id === el.id)
          if (index >= 0) this.actives.splice(index, 1)
        })

        return
      }

      this.actives.splice(indexOfItem, 1)
    },

    addItemToActives(item) {
      if (this.multiple) {
        if (this.selectHierarchy) {
          const family = this.handlehierarchy([item])
          this.actives = structuredClone([...this.actives, ...family])
          return
        }

        this.actives.push(item)
        return
      }

      this.actives = [item]
    },

    handlehierarchy(array) {
      let data = []

      array.forEach((el) => {
        data.push(el)

        if (el.children && el.children.length) {
          data = [...data, ...this.handlehierarchy(el.children)]
        }
      })

      return data
    },

    async handleItems() {
      if (this.search) {
        this.searchItems(this.search)
        return
      }

      this.filterItems()
    },

    filterItems() {
      this.filteredItems = this.items.filter((item) => {
        return !this.hideElements.includes(item.id)
      })

      this.$emit('loading', false)
    },

    async searchItems(value) {
      for (const identity in this.historySearchControllers) {
        this.historySearchControllers[identity].controller.abort()
      }

      if (typeof value !== 'string' || value.trim().length === 0) {
        this.filterItems()
        return
      }

      const controller = {
        identity: uuidv4(),
        controller: new AbortController(),
      }
      this.historySearchControllers[controller.identity] = controller

      this.filteredItems = []

      this.$emit('loading', true)
      setTimeout(() => {
        this.callFilterItemsDeep(value, controller)
      }, 0)
    },

    async callFilterItemsDeep(search, controller) {
      const promises = BreakAsThreadPromises(
        this.items,
        search,
        controller,
        this.deepTree
      )

      Promise.all(promises)
        .then(() => {
          this.handleSuccessFilterSearch()
        })
        .catch((err) => {
          this.handleErrorFilterSearchAborted(err)
        })
        .finally(() => {
          delete this.historySearchControllers[controller.identity]
        })
    },

    handleSuccessFilterSearch() {
      this.filteredItems = BigSearchToUniqueItems(this.filteredItems)
      this.$emit('loading', false)
    },

    handleErrorFilterSearchAborted(err) {
      const callHasBeenAborted =
        err && 'name' in err && err.name === 'AbortError'
      if (callHasBeenAborted) {
        delete this.historySearchControllers[err.message]
        this.$emit('loading', true)
      }
    },

    deepTree(items, search, controller) {
      return SearchWithAbortController(
        items,
        search,
        controller,
        this.filteredItems,
        this.hideElements
      )
    },

    resetCurrentActive(value) {
      const hasData = Array.isArray(value?.data) && value.data.length > 0

      const isEmptyValue = !value || !hasData
      const isSingleSelection = !this.multiple || !hasData

      if (isEmptyValue) {
        if (isSingleSelection) {
          this.actives = []
        }

        return
      }

      if (value.origin === this.tab) {
        this.actives = value.data
        return
      }

      this.actives = []
    },

    handleActive(id) {
      return !!this.actives.find((e) => e.id === id)
    },

    async buildTreeIndicesFromItems(items, tree = [], parentObj = {}) {
      const promises = items.map((item) => {
        if (Array.isArray(item?.children) && item.children.length > 0) {
          tree.push({ id: item.id, ...parentObj })

          return this.buildTreeIndicesFromItems(item.children, tree, {
            parentId: item.id,
          })
        }

        return Promise.resolve()
      })

      await Promise.all(promises)
    },

    async handleOpenAll() {
      this.$emit('loading', true)

      const treeIndices = []
      await this.buildTreeIndicesFromItems(this.filteredItems, treeIndices)
        .then(() => {
          this.opened = treeIndices
          this.$emit('expanded')
        })
        .catch(() => {
          this.opened = []
        })
        .finally(() => {
          this.$emit('loading', false)
        })
    },

    handleCloseAll() {
      this.opened = []
    },

    handleExpanded(event) {
      this.$emit('expand:item', event)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
