<template>
  <div data-test-language-menu>
    <v-menu
      content-class="elevation-neutral border-neutral"
      nudgeTop="5"
      transition="slide-x-transition"
      offset-y
      top
      z-index="100"
      min-width="308px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="btn-language"
          :class="{ 'btn-language-text': !isMiniVariant }"
          text
          tile
          plain
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="1.5rem">fi-rr-globe</v-icon>
          <div v-if="!isMiniVariant" class="btn-language-text">
            <span
              class="btn-language-text-name"
              v-text="
                $t('ui-components.siteNavigation.language.changeLanguageLabel')
              "
            ></span>
          </div>
        </v-btn>
      </template>

      <div data-test-language-menu-options class="language-menu-options">
        <v-btn
          text
          tile
          elevation="0"
          data-test-menu-user="'en-US'"
          class="language-menu-options-item"
          @click="handleChangeLanguage('en')"
        >
          <v-img
            :src="`${CDN_BASE_URL}images/languages/en-US.svg`"
            max-height="26"
            max-width="26"
          ></v-img>
          <span v-text="$t('ui-components.siteNavigation.language.en')"></span>
        </v-btn>

        <v-divider></v-divider>

        <v-btn
          text
          tile
          elevation="0"
          data-test-menu-user="'pt-BR'"
          class="language-menu-options-item"
          @click="handleChangeLanguage('pt')"
        >
          <v-img
            :src="`${CDN_BASE_URL}images/languages/pt-BR.svg`"
            max-height="26"
            max-width="26"
          ></v-img>
          <span v-text="$t('ui-components.siteNavigation.language.pt')"></span>
        </v-btn>

        <v-divider></v-divider>

        <v-btn
          text
          tile
          elevation="0"
          data-test-menu-user="'es-AR'"
          class="language-menu-options-item"
          @click="handleChangeLanguage('es')"
        >
          <v-img
            :src="`${CDN_BASE_URL}images/languages/es-AR.svg`"
            max-height="26"
            max-width="26"
          ></v-img>
          <span v-text="$t('ui-components.siteNavigation.language.es')"></span>
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LanguageMenu',
  props: {
    currentLanguage: {
      type: String,
      default: 'pt-BR',
    },
    isMiniVariant: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    handleChangeLanguage(lang) {
      this.$emit('selected-language', lang)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
