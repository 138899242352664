var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-care-content"},[(_vm._isMultipleSelectGroup)?_c('div',{attrs:{"data-test-multigroup-select":""}},[_c('ItemSelector',{ref:"comparisonItemSelector",attrs:{"currentValue":_vm._groupIds,"menuOptions":_vm._groupSelectorOptions,"highlightQuantity":8,"inputConfig":{
        label: _vm.$t('groupCare.multipleFieldLabel'),
        clearable: true,
      },"menuConfig":{
        showFooter: true,
        hideSideActions: true,
        showActionSlot: true,
      },"localSearch":true,"multiple":true,"fillWidth":true,"selectHierarchy":_vm.preferences.stagedSelectHierarchy},on:{"update:item":function($event){return _vm.handleMultiGroupSelect($event)}},scopedSlots:_vm._u([{key:"sideActions",fn:function({ currentTab }){return [_c('div',{staticClass:"preferences-label",attrs:{"data-test-preferences-button":""},on:{"click":function($event){return _vm.handleTab(
              currentTab,
              currentTab === 'preferences' ? 'groups' : 'preferences'
            )}}},[_vm._v(" "+_vm._s(_vm.$t( `groupCare.${ currentTab === 'preferences' ? 'back' : 'preferences' }` ))+" ")])]}},{key:"preferences",fn:function(){return [_c('div',[_c('section',{staticClass:"preferences-content"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{attrs:{"hide-details":""},model:{value:(_vm.preferences.selectHierarchy),callback:function ($$v) {_vm.$set(_vm.preferences, "selectHierarchy", $$v)},expression:"preferences.selectHierarchy"}}),_c('span',{staticClass:"checkbox-label"},[_vm._v(" "+_vm._s(_vm.$t('groupCare.checkboxHierarchy'))+" ")])],1)])])]},proxy:true},{key:"footer",fn:function({ currentTab }){return [_c('v-row',{staticClass:"footer-item-selector",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-none rounded-0",attrs:{"height":"2rem","block":"","text":"","tile":""},on:{"click":function($event){return _vm.handleClear(currentTab)}}},[_c('span',{staticClass:"bttn-label"},[_vm._v(" "+_vm._s(_vm.$t('groupCare.clear'))+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-none rounded-0",attrs:{"data-test-button-apply":"","color":"primary","height":"2rem","block":"","tile":""},on:{"click":function($event){return _vm.handleApply(currentTab)}}},[_c('span',{staticClass:"bttn-label"},[_vm._v(" "+_vm._s(_vm.$t('groupCare.apply'))+" ")])])],1)],1)]}}],null,false,2702603084)})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm._isMultipleSelectGroup),expression:"!_isMultipleSelectGroup"}],attrs:{"data-test-group-select":""}},[_c('ItemSelector',{ref:"singleItemSelector",attrs:{"menuOptions":_vm._groupSelectorOptions,"currentValue":_vm.selectedGroup,"highlightQuantity":8,"inputConfig":{ label: _vm.$t('groupCare.fieldLabel') },"persistent":true,"localSearch":true,"watchCurrent":true,"fillWidth":true},on:{"update:item":function($event){return _vm.handleGroupSelect($event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }