<template>
  <div class="reliability-container">
    <span
      v-if="showLabel"
      class="reliability-label"
      v-text="$t(`reliabilityRate.reliability`)"
    ></span>
    <span
      data-test-rate-label
      class="reliability-rate"
      :class="[reliabilityColor]"
      v-text="getRateLabel()"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    score: Number,
    showLabel: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      reliabilityColor: '',
    }
  },
  methods: {
    getRateLabel() {
      if ([null, undefined].includes(this.score)) {
        return '--'
      }

      if (this.score >= 0 && this.score <= 20) {
        this.reliabilityColor = 'veryLow-rate'
        return this.$t('reliabilityRate.veryLow')
      }

      if (this.score > 20 && this.score <= 40) {
        this.reliabilityColor = 'low-rate'
        return this.$t('reliabilityRate.low')
      }

      if (this.score > 40 && this.score <= 60) {
        this.reliabilityColor = 'medium-rate'
        return this.$t('reliabilityRate.average')
      }

      if (this.score > 60 && this.score <= 80) {
        this.reliabilityColor = 'high-rate'
        return this.$t('reliabilityRate.high')
      }

      if (this.score > 80 && this.score <= 100) {
        this.reliabilityColor = 'veryHigh-rate'
        return this.$t('reliabilityRate.veryHigh')
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
