<template>
  <v-card
    id="indicator"
    class="indicator-card no-select"
    height="2.375rem"
    :width="mini ? '4.5rem' : '12.625rem'"
    outlined
    data-test-indicator
    @click.stop="submit()"
  >
    <div id="content" class="d-flex align-center fill-height">
      <!-- image indicator -->
      <section v-if="indicator.image" class="label-spacing">
        <v-img
          class="indicator-image"
          max-height="24"
          max-width="24"
          height="24"
          width="24"
          :src="indicator.image"
        />
      </section>

      <!-- children icon -->
      <section v-else class="label-spacing">
        <v-icon small> mdi-subdirectory-arrow-right </v-icon>
      </section>

      <!-- label -->
      <span v-if="!mini" class="neutral4--text card-label">
        {{ indicator.label }}
      </span>

      <v-spacer />

      <!-- expand icon -->
      <v-icon
        v-if="indicator.submetrics && indicator.submetrics.length > 0"
        x-small
        class="ml-1"
      >
        {{ open ? 'fi fi-rr-caret-up' : 'fi fi-rr-caret-down' }}
      </v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    indicator: {},

    mini: {
      type: Boolean,
      default: false,
    },

    open: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    submit() {
      if (!this.indicator.submetrics || this.indicator.submetrics.length < 1)
        return

      if (!this.open) this.$emit('reveal-children', this.indicator.id)
      else this.$emit('hide-children', this.indicator.id)

      this.$emit('handle-open')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
