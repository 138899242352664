<template>
  <v-row>
    <v-col
      v-for="currentCard in TOTAL_CARDS"
      sm="12"
      md="6"
      lg="6"
      :key="currentCard"
    >
      <v-card elevation="0" height="100%" class="pa-4">
        <v-skeleton-loader type="text" class="mb-7"></v-skeleton-loader>
        <div>
          <v-row class="mb">
            <v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
            <v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
            <v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
            <v-col><v-skeleton-loader type="text"></v-skeleton-loader></v-col>
          </v-row>
          <v-skeleton-loader type="table-row-divider@9"></v-skeleton-loader>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'BenchmarkRankingLoader',
  created() {
    this.TOTAL_CARDS = 6
  },
}
</script>
