<template>
  <div class="last-opinions">
    <div class="top-container">
      <div class="title ml-1">
        <h3>{{ $t(`lastOpinions.title`) + ' ' + npsName }}</h3>
      </div>
    </div>
    <v-divider class="custom-divider" />

    <div class="body-container">
      <div class="opinions" v-if="opinions.length >= 1">
        <div
          @click="goToOpinionsPage(opinion.id)"
          class="opinions-details"
          v-for="(opinion, x) in opinions.slice(0, 4)"
          :key="opinion.id"
          :id="`opinion-${x + 1}`"
        >
          <img
            v-if="calcScore(opinion.score)"
            :src="
              require(`@/assets/images/LastOpinions/${calcScore(
                opinion.score
              )}.png`)
            "
          />
          <span>{{ opinion.message }}</span>
          <img
            class="opinions-details-arrow"
            src="@/assets/images/LastOpinions/arrow_right.png"
          />
        </div>
      </div>
      <div v-else class="unavailable-opinions">
        {{ $t('lastOpinions.notAllowed') }}
      </div>
    </div>

    <div @click="goToOpinionsPage()" class="footer-container">
      <v-icon>mdi-message-outline</v-icon>
      <span id="showAllText" data-test-show-all-text>{{
        $t('lastOpinions.showAll') + ' ' + npsName
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LastOpinions',
  props: {
    opinions: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    metricID: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    showMoreOptions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    npsName() {
      let name = ''
      if (this.type === 'enps') {
        name = 'eNPS'
      }
      if (this.type === 'lnps') {
        name = 'LNPS'
      }
      return this.title ? this.title : name
    },
  },
  methods: {
    calcScore(val) {
      if (val >= 0 && val < 7) {
        return 'detractor'
      } else if (val >= 7 && val < 9) {
        return 'passive'
      } else if (val >= 9 && val <= 10) {
        return 'promoter'
      } else {
        return false
      }
    },
    goToOpinionsPage(id) {
      this.$store.commit('setCurrentTab', 'OpinionsPage')

      this.$router.push({
        name: 'ReceivedOpinionsPage',
        params: { ...(id && { selectedOpinionId: id }) },
        query: {
          type: this.type,
          ...(this.metricID && { metricID: this.metricID }),
        },
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
