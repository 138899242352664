<template>
  <SkeletonLoader v-if="loading" />
  <div
    v-else
    id="card-pillars-segmentation"
    class="shadow"
    data-test-card-pillars
  >
    <v-card elevation="0">
      <div class="d-flex align-center pt-2">
        <v-btn
          v-if="selected"
          class="ms-2 me-n3"
          icon
          color="neutral2"
          @click="$emit('back')"
        >
          <v-icon v-text="'fi-rr-arrow-left'" />
        </v-btn>

        <div class="header-container flex-grow-1">
          <v-row>
            <v-col class="history">
              <div class="icon" v-if="!_haveSmartMetrics">
                <i class="fi fi-sr-crown" />
              </div>
              <label
                class="history-title"
                v-text="
                  selected
                    ? (smartMetricsAdvanced[0] || {}).label
                    : $t('engagementPage.cardSmartPillars.title')
                "
              />
              <div>
                <HowToBeginAndWatchAVideo
                  class="mt-1 ml-1"
                  videoType="climate.smartMetrics"
                />
              </div>
            </v-col>
            <v-col
              v-if="!selected && _haveSmartMetrics"
              class="stats mr-4 hidden-sm-and-down"
              cols="auto"
              @click="goToFullReport"
              data-test-button-smart-pillars-detail
            >
              <div :class="fullReport ? 'stats-icon-changed' : 'stats-icon'">
                <i class="fi fi-br-stats"></i>
              </div>
              <label
                :class="fullReport ? 'stats-text-changed' : 'stats-text'"
                class="ml-1"
                >{{ $t('engagementPage.cardSmartPillars.report') }}</label
              >
            </v-col>
          </v-row>
          <v-row class="subtitle">
            {{
              this.selected
                ? (smartMetricsAdvanced[0] || {}).text
                : $t('engagementPage.cardSmartPillars.subtitle')
            }}
          </v-row>
        </div>
      </div>
      <v-divider class="custom-divider"></v-divider>
      <v-item-group
        v-show="!fullReport"
        class="card-items"
        data-test-smart-pillars
      >
        <v-row class="pillars-container" data-test-pillars>
          <v-col
            data-test-pillar
            class="items-segmentation"
            v-for="(pillar, index) in _visiblePillars"
            :key="pillar.id"
            md="6"
          >
            <v-item class="pillar">
              <v-card
                class="d-flex align-center item"
                :class="{ 'item-locked': _lockPillars }"
                light
                @click.prevent="openSmartMetrics(pillar.metricID)"
                elevation="0"
                data-test-smart-pillar
              >
                <div
                  v-if="_haveSmartMetrics && !hideQuicklyComparison"
                  class="grade-tooltip"
                  data-test-grade-pillar
                  @mouseenter="handleMouseEnter(pillar.metricID)"
                  @mouseleave="handleMouseLeave()"
                >
                  <div class="tooltip-container" :show-side="index % 2">
                    <QuicklyComparison
                      class="hidden-sm-and-down"
                      v-if="comparisonMetrics.account.rank !== undefined"
                      :group-score="_groupScore"
                      :hasSelectedGroup="hasSelectedGroup"
                      :account-score="comparisonMetrics.account.score"
                      :market-score="comparisonMetrics.average.score"
                      :group-percent="_groupRank"
                      :account-percent="comparisonMetrics.account.rank"
                      :market-percent="comparisonMetrics.average.rank"
                      :hide-market-score="_hideMarket"
                      :percentageOfCompanies="_percentageCompanies"
                    />
                  </div>
                </div>
                <PillarAndFactor
                  class="pillar-item"
                  :image="pillar.image"
                  :title="pillar.label"
                  :grade="pillar.score"
                  :variation="hasMetricVariation(pillar.metricID)"
                  :variationLoading="variation.loading"
                  :score="pillar.score"
                  :locked="!_haveSmartMetrics"
                ></PillarAndFactor>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
      <div
        v-show="fullReport"
        class="full-report-container"
        data-test-details-pillars
      >
        <div
          v-for="(smartMetricsDetail, id) in smartMetricsAdvanced"
          :key="id"
          class="container-smart-metric-detail"
        >
          <div class="smart-metric-container">
            <div class="smart-metric-pillar" data-test-smart-pillar-detail>
              <v-item class="pillar">
                <v-card class="d-flex align-center item" light elevation="0">
                  <div
                    v-if="_haveSmartMetrics && !hideQuicklyComparison"
                    class="grade-tooltip"
                    data-test-grade-pillar
                    @mouseenter="handleMouseEnter(smartMetricsDetail.metricID)"
                    @mouseleave="handleMouseLeave()"
                  >
                    <div class="tooltip-container" :show-side="id % 2">
                      <QuicklyComparison
                        class="hidden-sm-and-down"
                        v-if="comparisonMetrics.account.rank !== undefined"
                        :group-score="_groupScore"
                        :hasSelectedGroup="hasSelectedGroup"
                        :account-score="comparisonMetrics.account.score"
                        :market-score="comparisonMetrics.average.score"
                        :group-percent="_groupRank"
                        :account-percent="comparisonMetrics.account.rank"
                        :market-percent="comparisonMetrics.average.rank"
                        :hide-market-score="_hideMarket"
                        :percentageOfCompanies="_percentageCompanies"
                      />
                    </div>
                  </div>
                  <PillarAndFactor
                    :image="smartMetricsDetail.image"
                    :title="smartMetricsDetail.label"
                    :score="smartMetricsDetail.score"
                    :grade="smartMetricsDetail.score"
                    :text="smartMetricsDetail.text"
                    :variation="hasMetricVariation(smartMetricsDetail.metricID)"
                    :variationLoading="variation.loading"
                    :locked="!_haveSmartMetrics"
                  ></PillarAndFactor>
                </v-card>
              </v-item>
              <div class="smart-metric-reliability">
                <ReliabilityRate
                  :score="
                    convertReliabilityRate(smartMetricsDetail.reliabilityRate)
                  "
                  data-test-pillar-reliability-rate
                />
                <ButtonNewActionPlan
                  data-test-new-action-plan-for-a-pillar-segmentation
                  :metricID="smartMetricsDetail.metricID"
                  :groupID="getGroupId"
                />
              </div>
            </div>
            <div class="smart-metric-history" data-test-history-container>
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
                min-height="250"
              >
                <HistoryChart
                  class="mr-2 mt-2"
                  :height="250"
                  :metrics="history.metrics[id]"
                  :dates="history.dates"
                  :loading="historyLoading"
                />
              </v-lazy>
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
                min-height="112"
              >
                <BenchmarkBadge
                  :loading="smartPillarsBenchmarksLoading"
                  :rank="getBenchmarkRank(id)"
                  status="overallAverage"
                  :locked="smartPillarsBenchmarksLocked"
                  :percentage="getBenchmarkScore(id)"
                />
              </v-lazy>
            </div>
          </div>
          <div class="question-title">
            <div>
              {{ $t('pillarsOfEngagementGroup.details.questionsTitle1') }}
            </div>
            <div>
              {{ $t('pillarsOfEngagementGroup.details.questionsTitle2') }}
            </div>
            <div>
              {{ $t('pillarsOfEngagementGroup.details.questionsTitle3') }}
            </div>
          </div>
          <v-divider class="custom-divider"></v-divider>

          <div
            class="question-container"
            v-for="(question, index) in smartMetricsDetail.questions"
            :key="index"
            data-test-smart-pillars-questions
          >
            <div class="question" data-test-smart-pillars-question>
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
                min-height="22"
              >
                <Question
                  data-test-smart-questions
                  :questionType="question.type"
                  :question="question.text"
                  :questionID="question.id"
                  :positiveQuestion="question.positiveQuestion"
                  :negativeQuestion="question.constructiveQuestion"
                  :frequencies="question.frequencies"
                  :score="convertReliabilityRate(question.reliabilityRate)"
                  :items="getEmojis(question, {}, smartMetricsDetail)"
                />
              </v-lazy>
            </div>
            <div
              class="reliability-desktop"
              data-test-smart-pillars-reliability
            >
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
              >
                <ReliabilityRate
                  :score="convertReliabilityRate(question.reliabilityRate)"
                  :showLabel="false"
                />
              </v-lazy>
            </div>
            <div class="frequencies" data-test-smart-pillars-frequencies>
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
              >
                <QuestionProgressBar
                  class="questions-frequencies"
                  :frequencies="question.frequencies"
                />
              </v-lazy>
            </div>
            <div class="reliability-mobile">
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="fade-transition"
              >
                <ReliabilityRate
                  :score="convertReliabilityRate(question.reliabilityRate)"
                />
              </v-lazy>
            </div>
          </div>
          <div
            v-if="!hideOpinions"
            class="opinions-container"
            data-test-smart-opinions
          >
            <LastOpinions
              :opinions="getOpinion(smartMetricsDetail.metricID)"
              :metricID="smartMetricsDetail.metricID"
              type="eNPS"
              :title="getMetricLabel(smartMetricsDetail.metricID)"
            />
          </div>
          <v-divider class="custom-divider"></v-divider>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import PillarAndFactor from '@/components/PillarAndFactor/PillarAndFactor.vue'
import QuicklyComparison from '@/components/QuicklyComparison/QuicklyComparison.vue'
import HistoryChart from '@/components/HistoryChart/HistoryChart.vue'
import BenchmarkBadge from '@/components/BenchmarkBadge/BenchmarkBadge.vue'

import { mapGetters } from 'vuex'
import ReliabilityRate from '@/components/ReliabilityRate/ReliabilityRate.vue'
import Question from '@/components/Question/Question.vue'
import QuestionProgressBar from '@/components/QuestionProgressBar/QuestionProgressBar.vue'

import LastOpinions from '@/components/LastOpinions/LastOpinions.vue'
import ButtonNewActionPlan from '@/pages/EngagementPage/Tabs/Climate/Partials/ButtonNewActionPlan/ButtonNewActionPlan.vue'
import SkeletonLoader from '@/pages/EngagementPage/Tabs/Climate/Partials/PillarSegmentation/parts/SkeletonLoader.vue'

import getEmojis from '@/mixins/getEmojis'
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'CardPillarSegmentation',
  mixins: [getEmojis],
  components: {
    HowToBeginAndWatchAVideo,
    PillarAndFactor,
    SkeletonLoader,
    QuicklyComparison,
    HistoryChart,
    BenchmarkBadge,
    LastOpinions,
    ReliabilityRate,
    Question,
    QuestionProgressBar,
    ButtonNewActionPlan,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    pillars: {
      type: Array,
      requred: true,
    },
    comparisonMetrics: {
      type: Object,
      requred: true,
    },
    fullReport: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Object,
      default: () => ({}),
    },
    historyLoading: {
      type: Boolean,
      default: false,
    },
    smartPillarsBenchmarks: {
      type: Array,
      default: () => [],
    },
    smartPillarsBenchmarksLoading: {
      type: Boolean,
      default: false,
    },
    smartPillarsBenchmarksLocked: {
      type: Boolean,
      default: false,
    },
    opinions: {
      type: Object,
      required: false,
      default: () => {},
    },
    smartMetricsAdvanced: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
    },
    hasSelectedGroup: {
      type: Boolean,
    },
    variation: {
      type: Object,
      require: true,
      default: () => {},
    },

    hideOpinions: {
      type: Boolean,
      default: false,
    },
    hideQuicklyComparison: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getGroupId', 'getIsBasicPlanAdminUser']),
    _lockPillars() {
      if (this._haveSmartMetrics) return false
      return this.getIsBasicPlanAdminUser && !this._haveSmartMetrics
    },
    _haveSmartMetrics() {
      return this.$can('access', _permissions.engagement_climate_smart_metrics)
    },
    _haveBurnout() {
      return this.$can(
        'access',
        _permissions.engagement_climate_smart_metrics_burnout
      )
    },
    _hideMarket() {
      return !this.$can('access', _permissions.engagement_benchmark_results)
    },
    _visiblePillars() {
      const userPillars = this.$props.pillars
        ? this.$props.pillars.filter((pillar) => pillar.metricID !== 'burnout')
        : null
      if (
        (this._haveSmartMetrics && this._haveBurnout) ||
        !this._haveSmartMetrics
      ) {
        return this.$props.pillars
      }
      return userPillars
    },
    _groupScore() {
      if (this.comparisonMetrics.hasOwnProperty('group')) {
        return this.comparisonMetrics.group.score
      }
      return -1
    },
    _groupRank() {
      if (this.comparisonMetrics.hasOwnProperty('group')) {
        return this.comparisonMetrics.group.rank
      }
      return -1
    },
    _percentageCompanies() {
      if (this.comparisonMetrics.hasOwnProperty('group')) {
        return this.comparisonMetrics.group.rank
      }
      return this.comparisonMetrics.account.rank
    },
    _variationHash() {
      const variation = {}
      const { smartMetrics } = this.variation
      smartMetrics.forEach((element) => {
        variation[element.metricID] = { ...element }
      })
      return variation
    },
  },
  methods: {
    openSmartMetrics(metricID) {
      if (!this._haveSmartMetrics) return
      this.$emit('clicked-item', metricID)
    },
    goToFullReport() {
      this.$emit('goToFullReport')
    },
    getBenchmarkScore(id) {
      return this.smartPillarsBenchmarks[id] &&
        this.smartPillarsBenchmarks[id].score
        ? this.smartPillarsBenchmarks[id].score
        : 0
    },
    getBenchmarkRank(id) {
      return this.smartPillarsBenchmarks[id] &&
        this.smartPillarsBenchmarks[id].rank
        ? this.smartPillarsBenchmarks[id].rank
        : 0
    },
    getOpinion(pKey) {
      if (this.opinions[pKey]) return this.opinions[pKey].opinions
      return []
    },
    getMetricLabel(pKey) {
      if (this.opinions[pKey]) return this.opinions[pKey].metric.label
      return ''
    },
    convertReliabilityRate(reliabilityRate) {
      const reliabilityValues = {
        veryLow: 20,
        low: 40,
        medium: 60,
        high: 80,
        veryHigh: 100,
        default: null,
      }

      return reliabilityValues[reliabilityRate] || reliabilityValues.default
    },
    hasMetricVariation(metricID) {
      if (this._variationHash[metricID]) {
        return this._variationHash[metricID].score
      }
      return null
    },

    handleMouseEnter(metricID) {
      if (this.hideQuicklyComparison) {
        return
      }

      this.$emit('hoverOn:pillar', metricID)
    },
    handleMouseLeave() {
      this.$emit('hoverOff:pillar')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
