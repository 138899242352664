<template>
  <div
    v-if="_handleAllow"
    data-test-selector-node-container
    class="d-flex flex-column node-container"
    :class="{ 'node-active': _isActive }"
  >
    <v-lazy
      min-height="2.0625rem"
      :key="node.id"
      :value="isNotLazy"
      :options="{
        threshold: 0.5,
      }"
    >
      <div
        data-test-node-item
        data-test-node-item-responsible
        class="d-flex align-center node"
        :class="{ 'node-expansible': showExpandIcon }"
        @click="submit()"
      >
        <v-icon
          v-if="showExpandIcon"
          data-test-node-item-expand
          :data-test-node-item-expanded="_isOpened || isExpanded"
          class="expand-icon"
          :color="expandedColor(alwayExpand, node)"
          v-text="expandedIcon(_isOpened, isExpanded)"
          @click.stop="toggleExpand()"
        />

        <div class="d-flex align-center node-container">
          <AvatarComponent
            v-if="showAvatar"
            :id="node.id || ''"
            :text="node.label"
            :src="node.image || ''"
            :size="'21'"
          />

          <v-icon v-if="showIcon" small> {{ node.typeIcon }} </v-icon>

          <span
            data-test-node-item-label
            data-test-selector-node-item-label
            class="node-label"
            v-text="node.label"
          />

          <v-icon
            v-if="hasRemoveIcon(_isActive, showRemoveIcon)"
            data-test-node-item-remove
            class="unselect-item"
            v-text="'mdi-close'"
          />
        </div>
      </div>
    </v-lazy>

    <section
      v-if="hasChildrens(_isOpened, isExpanded, node)"
      data-test-node-children-container
    >
      <template v-for="child in node.children">
        <node-item
          class="node-inner"
          v-on="$listeners"
          :key="child.id"
          :node="child"
          :actives="actives"
          :opened="opened"
          :hideElements="hideElements"
          :alwayExpand="alwayExpand"
          :showAvatar="showAvatar"
          :showRemoveIcon="showRemoveIcon"
          :showExpandIcon="showExpandIcon"
        />
      </template>
    </section>
  </div>
</template>

<script>
import AvatarComponent from '@/components/AvatarComponent/AvatarComponent'

export default {
  name: 'nodeItem',

  components: { AvatarComponent },

  data() {
    return {
      isExpanded: false,
      isNotLazy: false,
    }
  },

  props: {
    node: {
      type: Object,
      required: true,
    },

    actives: {
      type: Array,
      default: () => [],
    },

    opened: {
      type: Array,
      default: () => [],
    },

    hideElements: {
      type: Array,
      default: () => [],
    },

    alwayExpand: {
      type: Boolean,
      default: false,
    },

    showAvatar: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: false,
    },

    showRemoveIcon: {
      type: Boolean,
      default: false,
    },

    showExpandIcon: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    _isActive() {
      return this.calcNodeIndex(this.actives, this.node)
    },

    _isOpened() {
      return this.calcNodeIndex(this.opened, this.node)
    },

    _handleAllow() {
      return !this.hideElements.includes(this.node.id)
    },
  },

  watch: {
    _isOpened(value) {
      this.handleCollapse(value)
    },
  },

  methods: {
    submit() {
      if (this.node.locked === true) {
        return
      }

      this.$emit('update:active', this.node)
    },

    toggleExpand() {
      this.isExpanded = !this.isExpanded

      if (this.isExpanded) {
        this.$emit('update:expanded', this.node.id)
      }
    },

    handleCollapse(isOpened) {
      if (!isOpened && this.isExpanded) {
        this.isExpanded = false
      }
    },

    calcNodeIndex(list, node) {
      if (!list.length) {
        return false
      }

      const index = list.findIndex((e) => e && e.id === node.id)
      return index >= 0
    },

    hasChildrens(isOpened, isExpanded, node) {
      const hasChildrens =
        Array.isArray(node?.children) && node.children.length > 0
      const showMoreItens = isOpened || isExpanded

      return showMoreItens && hasChildrens
    },

    hasRemoveIcon(isActive, showRemoveIcon) {
      return showRemoveIcon && isActive
    },

    expandedIcon(isOpened, isExpanded) {
      if (isOpened || isExpanded) {
        return 'mdi-menu-down'
      }

      return 'mdi-menu-right'
    },
    expandedColor(alwayExpand, node) {
      if (!Array.isArray(node?.children)) {
        return 'transparent'
      }

      const isAlwayExpand = alwayExpand
      const hasChildrens = node.children.length

      if (isAlwayExpand || hasChildrens) {
        return 'grey'
      }

      return 'transparent'
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
