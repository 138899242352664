<template>
  <div id="CardOpinionBox" class="shadow">
    <v-card flat elevation="0">
      <div v-if="loading" class="loading">
        <div class="loading-header">
          <div class="loading-avatar">
            <v-skeleton-loader
              width="30"
              height="30"
              type="avatar"
            ></v-skeleton-loader>
            <v-skeleton-loader height="18" type="text"></v-skeleton-loader>
          </div>
          <div class="loading-actions">
            <v-skeleton-loader
              width="24"
              height="24"
              type="chip"
            ></v-skeleton-loader>
            <v-skeleton-loader
              width="145"
              height="33"
              type="button"
            ></v-skeleton-loader>
          </div>
        </div>
        <div class="loading-tags">
          <v-skeleton-loader
            width="16"
            height="16"
            type="button"
          ></v-skeleton-loader>
          <div class="loading-tags-chips">
            <v-skeleton-loader height="16" type="chip"></v-skeleton-loader>
          </div>
          <v-skeleton-loader
            width="16"
            height="16"
            type="chip"
          ></v-skeleton-loader>
        </div>
        <div class="loading-score">
          <v-skeleton-loader
            min-width="34"
            width="34"
            height="42"
            type="image"
          ></v-skeleton-loader>
          <div class="loading-score-container">
            <div class="loading-score-text">
              <v-skeleton-loader
                width="130"
                height="18"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                width="100%"
                height="18"
                type="text"
              ></v-skeleton-loader>
            </div>
            <div class="loading-score-text">
              <v-skeleton-loader
                width="130"
                height="18"
                type="text"
              ></v-skeleton-loader>
              <v-skeleton-loader
                width="100%"
                height="18"
                type="text"
              ></v-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="loading-body">
          <div class="loading-body-content">
            <v-skeleton-loader
              width="100%"
              height="100%"
              type="image"
            ></v-skeleton-loader>
          </div>
          <div class="loading-body-footer">
            <v-skeleton-loader
              width="100%"
              height="100%"
              type="image"
            ></v-skeleton-loader>
          </div>
        </div>
      </div>

      <div v-else-if="empty" class="empty-info">
        <EmptyOpinionsTip />
      </div>

      <template v-else>
        <OpinionBox
          class="opinion-box"
          :opinion="opinion.data"
          :ownerIdentity="opinion.ownerIdentity"
          :chunks="opinion.chunks.data"
          :chunksLoading="opinion.chunks.loading"
          :tags="tags"
          :height="height - 455"
          @handleMarkAsReaded="$emit('handleMarkAsReaded', $event)"
          @handleMarkAsUnread="$emit('handleMarkAsUnread', $event)"
          @handleSendMessage="$emit('handleSendMessage', $event)"
          @handleCloseOpinion="$emit('handleCloseOpinion', $event)"
          @handleTagsInfiniteScroll="$emit('handleTagsInfiniteScroll', $event)"
          @handleAddTag="$emit('handleAddTag', $event)"
          @handleRemoveTag="$emit('handleRemoveTag', $event)"
          @handleCreateTag="$emit('handleCreateTag', $event)"
        />
      </template>
    </v-card>
  </div>
</template>

<script>
import EmptyOpinionsTip from '@/pages/EngagementPage/Tabs/Opinions/Partials/EmptyOpinionsTip/EmptyOpinionsTip.vue'
import OpinionBox from '@/components/OpinionBox/OpinionBox.vue'

export default {
  name: 'CardOpinionBox',
  components: {
    OpinionBox,
    EmptyOpinionsTip,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    empty: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    tags: {
      type: Object,
    },
    opinion: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: -1,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
