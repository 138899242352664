<template>
  <div
    id="action-plans-page"
    data-test-action-plan-tab-content
    class="card-container"
  >
    <v-row>
      <v-col sm="12" md="12" lg="12">
        <DataTableActionPlans
          :items="actionPlans.items"
          :page="actionPlans.page"
          :loading="actionPlans.loading"
          :pageLength="actionPlans.pageLength"
          @handleUpdate="handleUpdate"
          @handleSort="handleSort"
          @handleSearch="handleSearch"
          @handleDelete="handleDelete"
          @handleEdit="handleEdit"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DataTableActionPlans from '../../Partials/DataTableActionPlans/DataTableActionPlans.vue'
import { getActionsPlans, deletePlan } from '@/service/actionPlans'

export default {
  name: 'ActionPlansPage',
  inject: ['actionPlan'],
  components: {
    DataTableActionPlans,
  },
  data() {
    return {
      actionPlans: {
        page: 1,
        pageLength: 1,
        items: [],
        loading: false,
        limit: 20,
        search: '',
      },
      filterValues: {},
    }
  },
  props: {
    groupID: {
      type: String,
      default: '',
    },
  },
  watch: {
    groupID(id) {
      this.fetchActionPlans(id)
    },
    filterValues() {
      this.fetchActionPlans(this.groupID)
    },
  },
  methods: {
    async handleDelete(actionPlan) {
      await deletePlan(actionPlan.id)
      this.fetchActionPlans(this.groupID)
    },
    handleEdit(actionPlan) {
      this.actionPlan.edit = actionPlan.id
    },
    handleUpdate(newPage) {
      this.actionPlans.page = newPage
      this.fetchActionPlans(this.groupID)
    },
    handleSort(sortOrder, value) {
      this.fetchActionPlans(this.groupID, sortOrder, value)
    },
    handleSearch(sortOrder, search) {
      this.actionPlans.search = search
      this.fetchActionPlans(this.groupID, sortOrder, 'title')
    },
    async fetchActionPlans(groupID, sortOrder = 'asc', sortBy = 'title') {
      this.actionPlans.items = []
      this.actionPlans.loading = true
      const params = {
        groupID,
        limit: this.actionPlans.limit,
        offset: (this.actionPlans.page - 1) * this.actionPlans.limit,
        sortOrder,
        sortBy,
        ...this.filterValues,
      }
      if (this.actionPlans.search && this.actionPlans.search !== '') {
        params.title = this.actionPlans.search
      }
      const { data, headers } = await getActionsPlans(params)
      this.actionPlans.loading = false
      const xCount = parseInt(headers['x-count']) || 0
      this.actionPlans.items = [...data]
      this.actionPlans.pageLength = Math.ceil(xCount / this.actionPlans.limit)
    },
    filterChangesListener(filterValues) {
      this.filterValues = filterValues
    },
  },
  mounted() {
    this.fetchActionPlans(this.groupID)
    this.$root.$on('action-plans:filter-changes', this.filterChangesListener)
    this.$root.$on('action-plans:reload', this.fetchActionPlans)
  },
  beforeDestroy() {
    this.$root.$off('action-plans:filter-changes', this.filterChangesListener)
    this.$root.$off('action-plans:reload', this.fetchActionPlans)
  },
}
</script>
