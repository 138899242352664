<template>
  <div
    class="d-flex flex-column align-center justify-center full-width flex-wrap"
    :class="`${sm ? 'sm' : ''}`"
  >
    <!--Accessibility-->
    <span
      class="accessibility-hide-text"
      tabindex="0"
      v-text="accessibilityText"
    />

    <!--AccessibilitySelected-->
    <span
      class="accessibility-hide-text"
      role="region"
      aria-live="polite"
      v-text="accessibilitySelectedText"
    />

    <!--ChecklistContainer-->
    <div
      class="checklist-container d-flex flex-wrap justify-center"
      :class="`${maxState ? 'max' : ''}`"
    >
      <!--Checkbox-->
      <div
        data-test-component-checkbox
        v-for="(item, index) of items"
        :key="index"
        class="checkbox-container d-flex align-self-start"
        :class="`${isSelected(index) ? 'selected' : 'non-select'}`"
        @click="select(index)"
        @keyup.enter.space="select(index)"
        tabindex="0"
        role="checkbox"
        :aria-checked="isSelected(index).toString()"
      >
        <span
          class="accessibility-hide-text"
          v-text="getAccessibilityCheckboxText(item, index)"
        />
        <img
          class="checkbox-image align-self-center"
          :src="item.image"
          aria-hidden="true"
        />
        <span
          data-test-component-checkbox-item-text
          class="checkbox-label flex-grow-1 align-self-center no-select"
          v-text="item.text"
          aria-hidden="true"
        />
        <div class="checkbox-icon">
          <v-tooltip
            bottom
            color="neutral1"
            open-delay="100"
            max-width="350"
            open-on-focus
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="1em"
                v-bind="attrs"
                v-on="on"
                v-text="'fi-rr-interrogation'"
              />
            </template>
            <span class="checkbox-description" v-text="item.description" />
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnswerCheckbox',
  props: {
    sm: {
      type: Boolean,
    },
    items: {
      type: Array,
      required: true,
    },
    init: {
      type: Array,
    },
    max: {
      type: Number,
    },
  },

  data() {
    return {
      selected: [],
    }
  },

  computed: {
    maxState: function () {
      if (!this.max) return false

      return this.selectedCount >= this.max
    },
    selectedCount: function () {
      const selected = this.selectedItems
      if (!selected || !selected.length) return 0

      return selected.length
    },
    selectedItems: function () {
      return this.selected
        .map((e, index) => {
          if (e.vote) return index
        })
        .filter((e) => !!e || e == 0)
    },
    accessibilityText: function () {
      const anon = this.$t('answerCheckbox.accessibility.anon')
      const options = `${this.items.length} ${this.$t(
        'answerCheckbox.accessibility.options'
      )}`
      const select = `${this.$t('answerCheckbox.accessibility.selectMax')} ${
        this.max || this.items.length
      } ${this.$t('answerCheckbox.accessibility.option')}`

      return `${anon} ${options} ${select}`
    },
    accessibilitySelectedText: function () {
      // Empty
      if (!this.selectedCount)
        return `${this.$t('answerCheckbox.accessibility.selectedEmpty')}`

      /** example1 **/
      /*
        selectedMaxText: ''
        selectedText: Selecionado 2 de 4.
        selectedItemsText: 'Itens escolhidos: Reconhecimento, Prontidão.'
        uncheckInfoText: ''
      */

      /** example2 **/
      /*
        selectedMaxText: 'Quantidade máxima selecionada.'
        selectedText: 'Selecionado 4 de 4.'
        selectedItemsText: 'Itens escolhidos: Fazer a diferença, Preguiça, Reconhecimento, Prontidão.'
        uncheckInfoText: 'Se quiser mudar a sua escolha, volte e selecione um item para desmarcá-lo antes de marcar um novo item.'
      */

      const selectedMax = `${this.$t(
        'answerCheckbox.accessibility.selectedMax'
      )}.`
      const selectedMaxText = `${this.maxState ? selectedMax : ''}`
      const selectedOf = `${this.$t('answerCheckbox.accessibility.of')} ${
        this.max || this.items.length
      }.`
      const selectedText = `${this.$t(
        'answerCheckbox.accessibility.selected'
      )} ${this.selectedCount} ${selectedOf}`
      const selectedItems = this.selectedItems.map((e, i) => this.items[i].text)
      const selectedItemsText = `${this.$t(
        'answerCheckbox.accessibility.selectedItems'
      )} ${selectedItems.toString()}.`
      const uncheckInfo = `${this.$t(
        'answerCheckbox.accessibility.uncheckInfo'
      )}.`
      const uncheckInfoText = `${this.maxState ? uncheckInfo : ''}`

      return `${selectedMaxText} ${selectedText} ${selectedItemsText} ${uncheckInfoText}`
    },
  },

  beforeMount() {
    this.selected = this[`${this.init ? 'init' : 'items'}`].map((e) => ({
      answerID: e.answerID,
      vote: e.vote || false,
    }))
  },

  methods: {
    select(i) {
      if (!i && i != 0) return

      const element = this.selected[i]
      if (!element) return

      // Max state
      if (!element['vote'] && this.maxState) return

      this.selected[i]['vote'] = !element['vote']
      this.emitInput()
    },
    isSelected(index) {
      return this.selected[index]['vote']
    },
    getAccessibilityCheckboxText(item, index) {
      const cardName = `${item.text}.`
      const description = `${this.$t(
        'answerCheckbox.accessibility.description'
      )}: ${item.description}`

      return !this.isSelected(index) ? `${cardName} ${description}` : cardName
    },
    emitInput() {
      this.$emit('input', this.buildEmit())
    },
    buildEmit() {
      return this.selected.map((e) => ({
        answerID: e['answerID'],
        vote: e['vote'],
      }))
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
