<template>
  <div class="shadow">
    <v-card id="card-history-ghost" elevation="0" height="100%">
      <v-container>
        <v-row>
          <v-col class="history">
            <label class="history-title">{{ title }}</label>
            <HowToBeginAndWatchAVideo :videoType="type" short />
          </v-col>
          <v-col class="stats" cols="auto">
            <v-skeleton-loader type="button" class="skeleton-history-header" />
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="custom-divider" />

      <div class="skeleton-b">
        <v-skeleton-loader
          v-for="i in 12"
          :key="i"
          type="button"
          class="skeleton-vertical"
        />
      </div>

      <v-skeleton-loader type="button" class="skeleton-history-footer" />
    </v-card>
  </div>
</template>
<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
export default {
  components: {
    HowToBeginAndWatchAVideo,
  },
  props: {
    type: String,
    title: String,
  },
}
</script>
<style src="../style.scss" lang="scss" scoped></style>
