import api from '@/service/Http'

export const getPeople = async (data) => {
  let endpoint = `/management/people?limit=${data.limit}&offset=${data.offset}`
  if (data.name) endpoint += `&name=${data.name}`
  if (data.role) endpoint += `&role=${data.role}`
  if (data.module) endpoint += `&module=${data.module}`
  return (await api()).get(endpoint, {
    ...(data.signal ? { signal: data.signal } : {}),
  })
}
/**
 * @typedef {Object} AttributeValue
 * @property {String} id
 * @property {String} label
 */

/**
 * @typedef {Object} Attribute
 * @property {String} id
 * @property {String} label
 * @property {Array<AttributeValue>} values
 */

/**
 * Retorna a lista de atributos
 * @param {Number} params.limit quantidade de itens por requisição
 * @param {Number} params.offset a partir de qual posição deve fazer a busca
 * @returns {Array<Attribute>}
 */
export async function getAttributesList({ limit = 20, offset = 0 }) {
  const params = {}

  if (limit) {
    params.limit = limit
  }

  if (offset || offset === 0) {
    params.offset = offset
  }

  return (await api()).get('/management/people/attributes', {
    params,
  })
}
