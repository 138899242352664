<template>
  <div
    class="forbidden-content d-flex flex-column fill-height"
    data-test-forbidden-page
  >
    <!--title-->
    <div class="d-flex justify-center">
      <span
        data-test-forbidden-title
        tabindex="0"
        class="forbidden-content-header"
        v-text="$t('survey.forbiddenPage.title')"
      />
    </div>

    <!--video-->
    <div v-if="_accessibility" class="d-flex justify-center">
      <VideoComponent
        data-test-video-explanation-accessibility
        class="forbidden-content-accessibility-video"
        :autoplay="true"
        :video="$t('survey.forbiddenPage.accessibility.videoURL')"
      />
    </div>

    <!--img-->
    <div v-else class="d-flex justify-center">
      <img
        class="forbidden-content-image"
        :src="require('@/assets/images/Survey/forbidden.svg')"
        alt=""
      />
    </div>

    <!--text1-->
    <div class="d-flex justify-center flex-wrap">
      <span
        data-test-forbidden-text1
        tabindex="0"
        class="forbidden-content-text"
        v-text="$t('survey.forbiddenPage.text1')"
      />
      <span v-html="'&nbsp;'" />
      <span
        class="forbidden-content-text-link"
        v-text="$t('survey.forbiddenPage.refresh')"
        @click="refresh()"
      />
    </div>

    <!--text2-->
    <div class="d-flex justify-center flex-wrap">
      <span
        data-test-forbidden-text2
        tabindex="0"
        class="forbidden-content-text"
        v-text="$t('survey.forbiddenPage.text2')"
      />
    </div>

    <!--Accessibility Float Button-->
    <v-fab-transition>
      <div class="d-flex align-center float" data-test-accessibility-area>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              v-if="$vuetify.breakpoint.mdAndUp"
              class="float-text"
            >
              <span
                class="align-self-center"
                v-text="$t('survey.initPage.floatButton')"
              />
            </div>

            <v-btn
              v-bind="attrs"
              v-on="on"
              data-test-survey-accessibility-btn
              id="survey-accessibility-btn"
              :x-large="$vuetify.breakpoint.mdAndUp"
              fab
              @click="setAccessibility"
            >
              <v-img
                v-if="!_accessibility"
                :src="require('@/assets/images/Survey/Accessibility1.svg')"
              />
              <v-img
                v-else
                :src="require('@/assets/images/Survey/Accessibility2.svg')"
              />
            </v-btn>
          </template>

          <div class="tooltip-label">
            <span v-if="!_accessibility">{{
              $t('survey.initPage.tooltipDesativateFloatButton')
            }}</span>
            <span v-else>{{
              $t('survey.initPage.tooltipActivateFloatButton')
            }}</span>
          </div>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { putSignLanguage } from '@/service/survey'
import VideoComponent from '@/components/VideoComponent/VideoComponent'
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'ForbiddenPage',
  components: { VideoComponent },
  data: () => ({}),
  watch: {
    getIsSignedIn(value) {
      if (value) this.defineAccessibilityByKeyPermission()
    },
  },
  computed: {
    ...mapState(['survey']),
    ...mapGetters(['getIsSignedIn']),

    _accessibility: function () {
      return this.survey.accessibility
    },
    _canEngagementSurveyLibras() {
      return this.$can('access', _permissions.engagement_survey_libras)
    },
  },
  methods: {
    ...mapActions(['setSurveyAccessibility']),

    refresh() {
      window.location.reload()
    },
    setAccessibility() {
      const payload = { activate: !this._accessibility }
      putSignLanguage(payload).then(() => {
        this.setSurveyAccessibility(payload.activate)
      })
    },
    defineAccessibilityByKeyPermission() {
      if (this._canEngagementSurveyLibras) {
        this.setSurveyAccessibility(this._canEngagementSurveyLibras)
      }
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
