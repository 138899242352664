<template>
  <div class="shadow">
    <v-card
      elevation="0"
      class="3"
      height="100%"
      data-test-card-confirmed-responders-rate
    >
      <v-card-title class="flex-column align-start card-title-slot">
        <h4 class="card-title">
          {{ $t('engagementPage.cardConfirmedRespondersRate.cardTitle') }}
        </h4>
        <p class="card-subtitle">
          {{
            $t('engagementPage.cardConfirmedRespondersRate.cardSubtitle', {
              confirmed: this.numberOfConfirmed,
              responders: this.numberOfResponders,
            })
          }}
        </p>
      </v-card-title>

      <div v-if="loading" class="load-content">
        <v-skeleton-loader width="300" type="list-item" />
        <v-skeleton-loader width="200" type="list-item" />
        <v-skeleton-loader width="300" type="list-item" />
      </div>

      <section v-else>
        <v-card-text>
          <div
            class="d-flex align-center flex-column pt-9"
            data-test-percentage-confirmed
          >
            <EngagementAverage
              :score="numberConfirmedRespondersRate"
              type="participation"
              enablePercentage
            />
            <div
              class="
                d-flex
                justify-center
                align-center
                card-variation-container
              "
            >
              <Variation short :variation="variation" />
              <span class="ml-2 variation-text">{{ variationText }}</span>
            </div>
            <p
              class="card-description px-md-6 mt-5"
              data-test-explanatory-message
            >
              {{
                $t(
                  'engagementPage.cardConfirmedRespondersRate.cardDescription',
                  {
                    confirmed: this.numberOfConfirmed,
                    responders: this.numberOfResponders,
                    days: this.surveyCycle,
                  }
                )
              }}
            </p>
          </div>
        </v-card-text>
      </section>
    </v-card>
  </div>
</template>

<script>
import EngagementAverage from '@/components/EngagementAverage/EngagementAverage.vue'
import Variation from '@/components/Variation/Variation.vue'

export default {
  name: 'CardConfirmedRespondersRate',
  components: {
    EngagementAverage,
    Variation,
  },
  props: {
    confirmedRespondersRate: Number,
    confirmed: Number,
    responders: Number,
    surveyCycle: Number,
    variation: Number,
    variationText: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    numberConfirmedRespondersRate() {
      if (!this.confirmedRespondersRate) {
        return 0
      }

      return this.confirmedRespondersRate
    },
    numberOfConfirmed() {
      if (!this.confirmed) {
        return 0
      }

      return this.confirmed
    },
    numberOfResponders() {
      if (!this.responders) {
        return 0
      }

      return this.responders
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
