<template>
  <div
    id="received-opinions-page"
    data-test-received-opinions-page
    class="card-container"
  >
    <v-row v-if="listView">
      <v-col sm="12" md="12" lg="12">
        <CardListOpinions
          ref="cardListOpinions"
          :tags="tags"
          :data="opinions.data"
          :loading="opinions.loading"
          :currentPage="opinions.currentPage"
          :countPages="opinions.countPages"
          :countOpinions="opinions.countOpinions"
          @handleOpinions="handleOpinions"
          @handleOpinionsOrder="handleOpinionsOrder"
          @handleSelecteOpinion="handleAnSelecteOpinion($event, false, false)"
          @handleOpenSidebar="handleAnSelecteOpinion($event, true, true)"
          @handleTagsInfiniteScroll="handleTagsInfiniteScroll"
          @handleAddTag="addTag"
          @handleRemoveTag="removeTag"
          @handleCreateTag="createTag"
        ></CardListOpinions>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col id="conversations">
        <div class="conversations-box conversations-lista">
          <CardOpinionList
            ref="cardOpinionList"
            data-test-card-opinion-list
            :conversationsList="conversations.data"
            :loading="conversations.loading"
            :loadingInfinite="conversations.loadingInfinite"
            :canInfinite="conversations.infinite"
            :selectedOpinion="selectedOpinion.data.id"
            :height="screenHeight"
            @handleSelecteOpinion="handleAnSelecteOpinion"
            @handleInfiniteScroll="handleAnInfiniteScroll"
          ></CardOpinionList>
        </div>

        <div class="conversations-box conversations-detalhes">
          <CardOpinionBox
            data-test-card-opinion-box
            :loading="conversations.loading"
            :empty="conversations.data.length === 0"
            :opinion="selectedOpinion"
            :tags="tags"
            :height="screenHeight"
            @handleMarkAsReaded="markAsReaded"
            @handleMarkAsUnread="markAsUnread"
            @handleSendMessage="sendMessage"
            @handleCloseOpinion="closeSelectedOpinion"
            @handleTagsInfiniteScroll="handleTagsInfiniteScroll"
            @handleAddTag="addTag"
            @handleRemoveTag="removeTag"
            @handleCreateTag="createTag"
          ></CardOpinionBox>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardListOpinions from '@/pages/EngagementPage/Tabs/Opinions/Partials/CardListOpinions/CardListOpinions.vue'
import CardOpinionBox from '@/pages/EngagementPage/Tabs/Opinions/Partials/CardOpinionBox/CardOpinionBox.vue'
import CardOpinionList from '@/pages/EngagementPage/Tabs/Opinions/Partials/CardOpinionList/CardOpinionList.vue'

import {
  addTagToOpinion,
  closeOpinion,
  createOpinionTag,
  getOpinionInfo,
  getOpinionMessages,
  getOpinionTags,
  getOpinions,
  markOpinionAsReaded,
  markOpinionAsUnread,
  removeTagFromOpinion,
  sendOpinionMessage,
} from '@/service/opinions'

import moment from 'moment'
import { mapGetters } from 'vuex'

import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'ReceivedOpinionsPage',
  components: {
    CardListOpinions,
    CardOpinionList,
    CardOpinionBox,
  },

  props: {
    groupID: {
      type: String,
      default: '',
    },
    listView: {
      type: Boolean,
      default: false,
    },
    selectedOpinionId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      sortOrder: 'desc',
      opinions: {
        data: [],
        loading: true,
        limit: 9,
        offset: 0,
        currentPage: 0,
        countPages: 1,
        countOpinions: 0,
      },
      conversations: {
        data: [],
        loading: true,
        loadingInfinite: false,
        infinite: true,
        limit: 20,
        offset: 0,
        countOpinions: 0,
      },
      selectedOpinion: {
        data: {
          id: '0',
          closed: true,
          isAnonymous: true,
          isRead: true,
          isPositive: true,
          quantitativeQuestion: '',
          qualitativeQuestion: '',
          score: 0,
          rating: 'no-comments',
          lastMessage: {
            name: '',
            photo: '',
          },
          tags: [],
        },
        chunks: {
          data: [],
          loading: true,
          infinite: true,
          limit: 20,
          offset: 0,
        },
        ownerIdentity: {},
      },
      tags: {
        data: [],
        loading: true,
        infinite: true,
        limit: 20,
        offset: 0,
        countTags: 0,
      },
      screenHeight: -1,
      filterValues: {},
      fetchMounted: true,
    }
  },

  computed: {
    ...mapGetters(['getUserDetails', 'getGroupSeleted']),

    _canEngagementOpinionsTags() {
      return this.$can('access', _permissions.engagement_opinions_tags)
    },
  },

  watch: {
    groupID() {
      this.handleViewMode()
    },

    listView(isListView) {
      this.handleViewMode(isListView)
    },
  },

  methods: {
    handleViewMode(isListView = this.listView) {
      this.resetSelectedOpinion()

      if (isListView) {
        this.opinions.data = []
        this.opinions.offset = 0

        this.$nextTick(() => {
          this.$refs.cardListOpinions.setCurrentPage(1)
        })

        this.listViewMode()
        return
      }

      this.sortOrder = 'desc'
      this.conversations.data = []
      this.conversations.offset = 0
      this.conversations.infinite = true

      this.$nextTick(() => {
        this.$refs.cardOpinionList.holdSpamInfinityScroll = false
      })

      this.sideViewMode()
    },

    listViewMode() {
      this.fetchOpinions()
    },
    sideViewMode() {
      this.fetchConversations()
    },

    resetSelectedOpinion() {
      this.selectedOpinion.data.id = '0'
      this.$router
        .replace({
          path: this.$router.path,
          params: { selectedOpinionId: null },
        })
        .catch(() => null)
    },

    async fetchOpinions(groupID = this.groupID, sortOrder = this.sortOrder) {
      this.opinions.loading = true

      return await getOpinions({
        limit: this.opinions.limit,
        offset: this.opinions.offset,
        groupID,
        sortOrder,
        ...this.filterValues,
      })
        .then(({ data, headers }) => {
          if (data.length === 0) {
            this.opinions.data = []
            this.opinions.countOpinions = 0
            this.opinions.countPages = 1
            return
          }

          this.opinions.data = data

          const xCount = parseInt(headers['x-count']) || 0
          this.opinions.countOpinions = xCount
          this.opinions.countPages = Math.ceil(xCount / this.opinions.limit)
        })
        .finally(() => {
          this.opinions.loading = false
        })
    },
    handleOpinionsOrder(sortOrder) {
      this.sortOrder = sortOrder
      this.fetchOpinions(this.groupID, this.sortOrder)
    },
    handleOpinions(pageNumber) {
      this.opinions.offset = (pageNumber - 1) * this.opinions.limit
      this.fetchOpinions()
    },

    async fetchAnConversation(
      groupID = this.groupID,
      opinionID = this.selectedOpinionId
    ) {
      const { data } = await getOpinionInfo({
        opinionID,
        groupID,
      })

      return data
    },
    async fetchConversations(
      groupID = this.groupID,
      sortOrder = this.sortOrder
    ) {
      this.conversations.loading = true

      await getOpinions({
        limit: this.conversations.limit,
        offset: 0,
        groupID,
        sortOrder,
        ...this.filterValues,
      })
        .then(async ({ data, headers }) => {
          if (data.message || data.length === 0) {
            this.conversations.data = []
            this.conversations.offset = 0
            this.conversations.countOpinions = 0
            this.conversations.infinite = false

            this.resetSelectedOpinion()
            return
          }

          let updatedData = [...data]

          if (this.selectedOpinionId) {
            const selectedOpinionIndex = Array.prototype.findIndex.call(
              data,
              (dataItem) => dataItem.id === this.selectedOpinionId
            )

            if (selectedOpinionIndex === -1) {
              const selectedOpinionData = await this.fetchAnConversation(
                groupID,
                this.selectedOpinionId
              )

              if (selectedOpinionData) {
                updatedData = [...data, selectedOpinionData]
              }
            }
          }

          this.conversations.data = updatedData
          this.conversations.offset = data.length

          const xCount = parseInt(headers['x-count']) || 0
          if (xCount <= this.conversations.offset && xCount > 0) {
            this.conversations.infinite = false
          }

          this.conversations.countOpinions = xCount
        })
        .finally(() => {
          this.conversations.loading = false
        })
    },
    async handleAnInfiniteScroll(
      groupID = this.groupID,
      sortOrder = this.sortOrder
    ) {
      if (!this.conversations.infinite) {
        return
      }

      this.conversations.loadingInfinite = true
      await getOpinions({
        limit: this.conversations.limit,
        offset: this.conversations.offset,
        groupID,
        sortOrder,
        ...this.filterValues,
      })
        .then(({ data, headers }) => {
          if (data.message || data.length === 0) {
            this.conversations.infinite = false
            return
          }

          let handledInfinityScrollDedup = this.conversations.data.concat(data)
          handledInfinityScrollDedup = handledInfinityScrollDedup.filter(
            (value, index, self) =>
              self.findLastIndex((value2) => value2.id === value.id) === index
          )

          this.conversations.data = handledInfinityScrollDedup
          this.conversations.offset = this.conversations.data.length

          const xCount = parseInt(headers['x-count']) || 0
          if (xCount <= this.conversations.offset && xCount > 0) {
            this.conversations.infinite = false
          }
        })
        .finally(() => {
          this.conversations.loadingInfinite = false
        })
    },

    getOpinionInfoGroup() {
      if (!this.getGroupSeleted?.length) {
        return {}
      }

      const group = this.getGroupSeleted?.[0]

      if (!group) {
        return {}
      }

      return {
        group: {
          id: group.id,
          name: group.label,
        },
      }
    },

    async refreshOpinionInfo(opinionID) {
      const { data } = await getOpinionInfo({
        opinionID: opinionID,
      })

      const hotReplace = this.getOpinionInfoGroup()

      this.updateConversationsRecursive(opinionID, {
        ...data,
        ...hotReplace,
      })

      return data
    },

    handleAnSelecteOpinion: async function (
      opinion,
      open = true,
      sidebar = false
    ) {
      if (!opinion) {
        return
      }

      opinion = await this.refreshOpinionInfo(opinion.id)
      this.fetchOpinionMessages(opinion.id)

      let updatedSelecteOpinion = {
        ...this.selectedOpinion,
        data: {
          ...opinion,
        },
      }

      if (opinion.id !== this.selectedOpinion.data.id) {
        updatedSelecteOpinion = {
          ...updatedSelecteOpinion,
          chunks: {
            data: [],
            loading: true,
            infinite: true,
            limit: 20,
            offset: 0,
          },
          ownerIdentity: {},
        }
      }

      this.selectedOpinion = updatedSelecteOpinion
      this.$router
        .replace({
          path: this.$router.path,
          params: { selectedOpinionId: opinion.id },
        })
        .catch((_err) => {
          /* remove erro do log */
        })

      if (!opinion.isRead && (open || this.$vuetify.breakpoint.width >= 768)) {
        this.markAsReaded()
      }

      if ((this.$vuetify.breakpoint.width <= 768 && open) || sidebar === true) {
        this.openSidebar()
      }
    },
    async fetchOpinionMessages(opinionID) {
      if (!opinionID) {
        return
      }

      this.selectedOpinion.chunks.loading = true
      const { data } = await getOpinionMessages({ opinionID })
      this.selectedOpinion.chunks.loading = false

      await this.handleOpinionMessages(data, false)
    },
    sortAscending: function (a, b) {
      if (a.date > b.date) {
        return 1
      }
      if (a.date < b.date) {
        return -1
      }
      return 0
    },
    async handleOpinionMessages(messages, append = true) {
      let chunks = this.selectedOpinion.chunks.data
      let ownerIdentity = this.selectedOpinion.ownerIdentity

      let { continuousIndex } = this.getContinuousIndex()

      if (!append) {
        chunks = []
        continuousIndex = 0
      }
      messages.forEach((message, index) => {
        const chunk = chunks.find(
          (chunkItem) => chunkItem.date === message.date
        )

        if (message.owner && !message.isAnonymous) {
          ownerIdentity = message.author
        }

        if (chunk) {
          chunk.messages.push({ id: continuousIndex + index, ...message })
        } else {
          chunks.push({
            date: message.date,
            messages: [{ id: continuousIndex + index, ...message }],
          })
        }
      })

      if (chunks.length > 1 && chunks !== this.selectedOpinion.chunks.data) {
        chunks.sort((a, b) => this.sortAscending(a, b))
      }

      this.selectedOpinion.chunks.data = chunks
      this.selectedOpinion.ownerIdentity = ownerIdentity
    },
    openSidebar() {
      this.$store.commit('setOpinionBoxProps', {
        selectedOpinion: this.selectedOpinion,
        tags: this.tags,
        handleMarkAsReaded: this.markAsReaded,
        handleMarkAsUnread: this.markAsUnread,
        handleSendMessage: this.sendMessage,
        handleCloseOpinion: this.closeSelectedOpinion,
        handleTagsInfiniteScroll: this.handleTagsInfiniteScroll,
        handleAddTag: this.addTag,
        handleRemoveTag: this.removeTag,
        handleCreateTag: this.createTag,
      })

      this.$store.commit('openOpinionBox')
    },
    async markAsReaded() {
      const opinionID = this.selectedOpinion.data.id
      await markOpinionAsReaded({
        opinionID,
      }).then((_response) => {
        const data = { isRead: true }
        this.updateConversationsRecursive(opinionID, data)
      })
    },
    async markAsUnread() {
      const opinionID = this.selectedOpinion.data.id
      await markOpinionAsUnread({
        opinionID,
      }).then((_response) => {
        const data = { isRead: false }
        this.updateConversationsRecursive(opinionID, data)
      })
    },
    getContinuousIndex: function () {
      let continuousIndex = 0

      try {
        const chunksData = this.selectedOpinion.chunks.data
        const lastMessageChunk = chunksData[chunksData.length - 1].messages
        continuousIndex = lastMessageChunk[lastMessageChunk.length - 1].id + 1
      } catch (_err) {
        /* remove erro do log */
      }

      return { continuousIndex }
    },
    updateOutgoingStatus: function (opinionID, continuousIndex, status) {
      if (opinionID !== this.selectedOpinion.data.id) {
        return
      }

      const chunks = this.selectedOpinion.chunks.data
      let lastMessageChunk = chunks[chunks.length - 1].messages

      let outgoingData = lastMessageChunk.find(
        (data) => data.id === continuousIndex
      )
      const index = lastMessageChunk.indexOf(outgoingData)
      outgoingData.messageStatus = status
      lastMessageChunk.fill(outgoingData, index, index + 1)

      this.selectedOpinion.chunks.data[chunks.length - 1].messages =
        lastMessageChunk
    },
    async sendMessage({ message }) {
      const opinionID = this.selectedOpinion.data.id
      const today = moment().format('YYYY-MM-DD')
      const userData = this.getUserDetails

      const { continuousIndex } = this.getContinuousIndex()
      let messageStatus = 'sending'
      const messageData = {
        mine: true,
        author: {
          ...userData,
        },
        message: message,
        date: today,
        isAnonymous: false,
        closed: false,
        rating: null,
        owner: false,
        messageStatus,
      }
      this.handleOpinionMessages([messageData])

      await sendOpinionMessage({
        opinionID,
        message,
        isAnonymous: false,
      })
        .then(async (response) => {
          if (response.status === 204) {
            this.updateConversationsRecursive(
              opinionID,
              {
                closed: false,
                lastMessage: {
                  ...userData,
                  message: message,
                  date: today,
                },
              },
              true
            )

            messageStatus = 'sent'
            this.updateOutgoingStatus(opinionID, continuousIndex, messageStatus)
          } else {
            return Promise.reject(response)
          }
        })
        .catch((_err) => {
          messageStatus = 'error'
          this.updateOutgoingStatus(opinionID, continuousIndex, messageStatus)
        })
    },
    async closeSelectedOpinion() {
      const opinionID = this.selectedOpinion.data.id
      const today = moment().format('YYYY-MM-DD')
      const userData = this.getUserDetails

      const { continuousIndex } = this.getContinuousIndex()
      let messageStatus = 'sending'
      const messageData = {
        mine: true,
        author: {
          ...userData,
        },
        message: null,
        date: today,
        isAnonymous: false,
        closed: true,
        rating: null,
        owner: false,
        messageStatus,
      }
      this.handleOpinionMessages([messageData])

      await closeOpinion({
        opinionID,
      })
        .then(async (response) => {
          if (response.status === 204) {
            const data = { closed: true }
            this.updateConversationsRecursive(opinionID, data)

            messageStatus = 'sent'
            this.updateOutgoingStatus(opinionID, continuousIndex, messageStatus)
          } else {
            return Promise.reject(response)
          }
        })
        .catch((_err) => {
          messageStatus = 'error'
          this.updateOutgoingStatus(opinionID, continuousIndex, messageStatus)
        })
    },
    updateConversationsRecursive(id, props, incrementReplies = false) {
      if (this.selectedOpinion.data.id === id) {
        const data = this.selectedOpinion.data

        if (incrementReplies) {
          props.totalReplies = data.totalReplies + 1
        }

        this.selectedOpinion.data = {
          ...data,
          ...props,
        }
      }

      const fn = (dataItem) => dataItem.id === id
      const conversationsIndex = this.conversations.data.findIndex(fn)
      if (conversationsIndex !== -1) {
        const dataItemConversations =
          this.conversations.data[conversationsIndex]

        if (incrementReplies) {
          props.totalReplies = dataItemConversations.totalReplies + 1
        }

        this.conversations.data[conversationsIndex] = {
          ...dataItemConversations,
          ...props,
        }
        this.conversations.data = [...this.conversations.data]
      }

      const opinionsIndex = this.opinions.data.findIndex(fn)
      if (opinionsIndex !== -1) {
        const dataItemOpinions = this.opinions.data[opinionsIndex]

        if (incrementReplies) {
          props.totalReplies = dataItemOpinions.totalReplies + 1
        }

        this.opinions.data[opinionsIndex] = {
          ...dataItemOpinions,
          ...props,
        }
        this.opinions.data = [...this.opinions.data]
      }
    },
    removeOrUpdateTagsForConversationsOrOpinions(
      index,
      stateName,
      tags,
      remove
    ) {
      const currentDataItems = this[stateName].data[index]

      if (remove) {
        this[stateName].data[index] = {
          ...currentDataItems,
          tags: [...currentDataItems.tags.filter((tag) => !tags.includes(tag))],
        }
      } else {
        this[stateName].data[index] = {
          ...currentDataItems,
          tags: [...currentDataItems.tags, ...tags],
        }
      }

      this[stateName].data = [...this[stateName].data]
    },
    updateConversationsRecursiveTags(id, tags, remove = false) {
      if (this.selectedOpinion.data.id === id) {
        const data = this.selectedOpinion.data
        if (remove) {
          this.selectedOpinion.data = {
            ...data,
            tags: [...data.tags.filter((tag) => !tags.includes(tag))],
          }
        } else {
          this.selectedOpinion.data = {
            ...data,
            tags: [...data.tags, ...tags],
          }
        }
      }

      const fn = (dataItem) => dataItem.id === id
      const conversationsIndex = this.conversations.data.findIndex(fn)
      if (conversationsIndex !== -1) {
        this.removeOrUpdateTagsForConversationsOrOpinions(
          conversationsIndex,
          'conversations',
          tags,
          remove
        )
      }

      const opinionsIndex = this.opinions.data.findIndex(fn)
      if (opinionsIndex !== -1) {
        this.removeOrUpdateTagsForConversationsOrOpinions(
          opinionsIndex,
          'opinions',
          tags,
          remove
        )
      }
    },
    async fetchOpinionTags() {
      if (!this._canEngagementOpinionsTags) {
        return
      }

      this.tags.loading = true

      const { data, headers } = await getOpinionTags({
        limit: this.tags.limit,
        offset: this.tags.offset,
      })
        .catch((_err) => !!_err)
        .finally(() => {
          this.tags.loading = false
        })

      if (data.message || data.length === 0) {
        this.tags.data = []
        this.tags.countTags = 0
        this.tags.offset = 0
      } else {
        this.tags.data = data
        this.tags.offset = data.length + 1
        this.tags.countTags = parseInt(headers['x-count']) || 0
      }
    },
    async handleTagsInfiniteScroll() {
      if (this.tags.infinite) {
        const { data, headers } = await getOpinionTags({
          limit: this.tags.limit,
          offset: this.tags.offset,
        })
        if (data.message || data.length === 0) {
          this.tags.infinite = false
        } else {
          let handledInfinityScrollDedup = this.tags.data.concat(data)
          handledInfinityScrollDedup = handledInfinityScrollDedup.filter(
            (value, index, self) =>
              self.findLastIndex((value2) => value2.id === value.id) === index
          )

          this.tags.data = handledInfinityScrollDedup
          this.tags.offset = this.tags.data.length + 1
        }

        const xCount = parseInt(headers['x-count']) || 0
        if (xCount <= this.tags.offset && xCount > 0) {
          this.tags.infinite = false
        }
      }
    },
    async addTag({ tag, opinionID = '' }) {
      if (opinionID === '') {
        opinionID = this.selectedOpinion.data.id
      }

      const tagID = tag.id
      await addTagToOpinion({
        opinionID,
        tagID,
      })
        .then(async (response) => {
          if (response.status === 204) {
            this.updateConversationsRecursiveTags(opinionID, [tag])
          } else {
            return Promise.reject(response)
          }
        })
        .catch((_err) => {})
    },
    async removeTag({ tag, opinionID = '' }) {
      if (opinionID === '') {
        opinionID = this.selectedOpinion.data.id
      }

      const tagID = tag.id
      await removeTagFromOpinion({
        opinionID,
        tagID,
      })
        .then(async (response) => {
          if (response.status === 204) {
            this.updateConversationsRecursiveTags(opinionID, [tag], true)
          } else {
            return Promise.reject(response)
          }
        })
        .catch((_err) => {})
    },
    async createTag({ label, opinionID }) {
      await createOpinionTag({
        label,
      })
        .then(async (response) => {
          if (response.status === 201) {
            this.tags.data = [...this.tags.data, response.data]
            this.addTag({ tag: response.data, opinionID })
          } else {
            return Promise.reject(response)
          }
        })
        .catch((_err) => {})
    },

    handleHeight() {
      setTimeout(() => {
        this.screenHeight = window.innerHeight
      }, 500)
    },

    filterChangesListener(filterValues) {
      this.filterValues = filterValues

      this.handleViewMode(this.listView)
    },
  },

  created() {
    if (this.$route.query.metricID) {
      this.$root.$emit('set-filter:opinions', {
        metricID: this.$route.query.metricID,
      })

      this.fetchMounted = false
    }

    this.$root.$on('opinions:filter-changes', this.filterChangesListener)
  },
  beforeDestroy() {
    this.$root.$off('opinions:filter-changes', this.filterChangesListener)
  },
  beforeMount() {
    this.fetchOpinionTags()

    if (this.fetchMounted) {
      if (this.listView) {
        this.listViewMode()
      } else {
        this.sideViewMode()
      }
    }

    this.handleHeight()

    window.addEventListener('resize', this.handleHeight)
  },
  beforeUpdate() {
    // @TODO removido temporariamente
    // try {
    //   this.$crisp.push(['do', 'chat:hide'])
    // } catch (err) {
    //   setTimeout(() => {
    //     this.$crisp.push(['do', 'chat:hide'])
    //   }, 1000)
    // }

    const selectedOpinionIndex = this.conversations.data.findIndex(
      (data) => data.id === this.selectedOpinionId
    )

    if (selectedOpinionIndex !== -1) {
      if (this.selectedOpinion.data.id !== this.selectedOpinionId) {
        this.handleAnSelecteOpinion(
          this.conversations.data[selectedOpinionIndex],
          false
        )
      }
    } else {
      this.handleAnSelecteOpinion(this.conversations.data[0], false)
    }

    this.$store.commit('setOpinionBoxProps', {
      selectedOpinion: this.selectedOpinion,
      tags: this.tags,
      handleMarkAsReaded: this.markAsReaded,
      handleMarkAsUnread: this.markAsUnread,
      handleSendMessage: this.sendMessage,
      handleCloseOpinion: this.closeSelectedOpinion,
      handleTagsInfiniteScroll: this.handleTagsInfiniteScroll,
      handleAddTag: this.addTag,
      handleRemoveTag: this.removeTag,
      handleCreateTag: this.createTag,
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
