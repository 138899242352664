<template>
  <v-sheet
    data-test-heat-cell
    id="heatcell"
    class="rounded-lg pointer-hand"
    height="2.375rem"
    width="4rem"
    :color="handleColor"
    :dark="note !== undefined"
    label
    @click="openUpdatePackage()"
  >
    <div id="content" class="d-flex align-center justify-center fill-height">
      <!-- lock account type -->
      <div v-if="lock">
        <v-icon class="lock-icon" color="neutral2" small> fi-sr-lock </v-icon>
      </div>

      <!-- values -->
      <div v-else class="d-flex">
        <span v-if="note" class="note-label" data-test-note-label>
          {{ note }}

          <span v-if="mode === 'pontuation'">
            {{ handlePercent ? '%' : '' }}
          </span>
        </span>

        <span v-else class="note-label neutral6--text"> -- </span>

        <v-icon
          v-if="mode === 'variation' && note !== 0"
          class="ml-1"
          size="14"
        >
          {{ handleIcon }}
        </v-icon>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapMutations } from 'vuex'
import variables from '@/styles/abstracts/_colors.scss'

export default {
  props: {
    note: Number,

    column: Object,

    lock: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: '',
    },

    indicator: {
      type: String,
      default: '',
    },
  },

  computed: {
    handleColor() {
      const value = this.note
      if (this.lock) return variables.neutral8

      if (this.mode === 'pontuation') {
        // pontuation
        if (value === null) return variables.neutral8
        if (value < 5) return variables.redColor2
        if (value >= 5 && value < 7.5) return variables.yellowColor1
        if (value >= 7.5) return variables.greenColor
      } else {
        // variation
        if (value <= -2) return variables.redColor6
        if (value > -2 && value < 0) return variables.redColor2
        if (value === 0) return variables.neutral8
        if (value > 0 && value < 2) return variables.greenColor
        if (value >= 2) return variables.greenColor2
      }

      return variables.neutral8
    },

    handleIcon() {
      if (this.note < 0) return 'mdi-trending-down'
      if (this.note > 0) return 'mdi-trending-up'
      return ''
    },

    handlePercent() {
      const indicators = [
        'maturityLevel',
        'culturalAlignment',
        'unfavorableCulture',
        'participation',
      ]

      // enps and lnps -100 to 100

      return indicators.includes(this.indicator)
    },
  },

  methods: {
    ...mapMutations(['openUpdateYourPackageDialog']),

    openUpdatePackage() {
      if (this.lock) {
        try {
          this.openUpdateYourPackageDialog('variation')
        } catch (e) {
          return
        }
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
