import axios from 'axios'

const host =
  process.env.VUE_APP_API_AUTHENTICATION_URL || 'http://localhost:3000/v1'

export async function fetchAuthenticationToken(token) {
  const { data } = await axios({
    method: 'post',
    url: `${host}/auth/custom-token`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  return data
}
