<template>
  <LateralMenuItem
    :title="$t('MarketSegmentAndTeamSizeFilter.title')"
    :isMini="isMini"
  >
    <template v-slot:title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>

    <form>
      <v-row class="ma-0">
        <v-select
          v-if="!hideMarketSegment"
          data-test-market-segment
          v-model="marketSegment"
          :items="marketSegmentList"
          :label="$t('MarketSegmentAndTeamSizeFilter.marketSegment.label')"
          outlined
          hide-details
          height="44px"
          dense
          clearable
          :menu-props="{ 'content-class': 'select-filter' }"
          item-color="redLighten2"
          @change="onInputChanges"
        >
        </v-select>
      </v-row>
      <v-row :class="['mx-0 mb-0', !hideMarketSegment ? 'mt-4' : 'mt-0']">
        <v-select
          data-test-team-size
          v-model="teamSize"
          :items="teamSizeList"
          :label="$t('MarketSegmentAndTeamSizeFilter.teamSize.label')"
          outlined
          hide-details
          height="44px"
          dense
          clearable
          :menu-props="{ 'content-class': 'select-filter' }"
          item-color="redLighten2"
          @change="onInputChanges"
        >
        </v-select>
      </v-row>
    </form>
  </LateralMenuItem>
</template>

<script>
import LateralMenuItem from '@/components/LateralMenuItem/LateralMenuItem.vue'

export default {
  name: 'MarketSegmentAndTeamSizeFilter',
  components: { LateralMenuItem },
  props: {
    isMini: Boolean,
    hideMarketSegment: Boolean,
  },
  data() {
    return {
      marketSegment: null,
      teamSize: null,
      marketSegmentList: [
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.marketSegment.values.industry'
          ),
          value: 'industry',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.marketSegment.values.retail'
          ),
          value: 'retail',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.marketSegment.values.education'
          ),
          value: 'education',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.marketSegment.values.technology'
          ),
          value: 'technology',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.marketSegment.values.construction'
          ),
          value: 'construction',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.marketSegment.values.other'
          ),
          value: 'other',
        },
      ],
      teamSizeList: [
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.teamSize.values.1to100'
          ),
          value: '1-100',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.teamSize.values.100to500'
          ),
          value: '100-500',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.teamSize.values.500to1000'
          ),
          value: '500-1000',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.teamSize.values.1000to5000'
          ),
          value: '1000-5000',
        },
        {
          text: this.$t(
            'MarketSegmentAndTeamSizeFilter.teamSize.values.5000toMore'
          ),
          value: '5000-more',
        },
      ],
      debounceTimeOut: null,
    }
  },
  methods: {
    onInputChanges() {
      clearTimeout(this.debounceTimeOut)

      this.debounceTimeOut = setTimeout(() => {
        this.sendFilterValues()
      }, 800)
    },
    sendFilterValues() {
      const filterValues = {
        marketSegment: this.marketSegment,
        teamSize: this.teamSize,
      }

      this.$root.$emit(
        'market-segment-and-team-size:filter-changes',
        filterValues
      )
    },
    clearForm() {
      this.marketSegment = null
      this.teamSize = null
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
