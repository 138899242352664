<template>
  <div>
    <div class="page-header">
      <h1
        data-test-pageTitle
        class="page-header__title"
        v-text="$t('engagementPage.pageTitle')"
      ></h1>
      <div class="how-to-begin-container">
        <HowToBeginAndWatchAVideo
          videoType="engagementPage"
        ></HowToBeginAndWatchAVideo>
      </div>
    </div>

    <v-row justify="space-between" class="mb-3 mt-2">
      <v-col cols="12" sm="6" md="5" lg="4" xl="3" class="py-0">
        <div id="engagement-page__group--content" class="d-flex">
          <GroupCare
            :selectedGroup="selectedGroup"
            :fullGroups="fullGroups"
            :groups="groups"
          ></GroupCare>

          <div
            class="d-flex justify-center align-center"
            :class="'engagement-page__favorite-button'"
            v-if="_canEngagementFavoriteFilters"
          >
            <v-btn
              icon
              small
              :loading="favoriteLoading"
              @click="handleFavorite()"
            >
              <v-icon
                data-test-star-icon1
                small
                :color="_isFavorite ? 'primary' : 'neutral3'"
                v-text="_isFavorite ? 'fi-sr-star' : 'fi-rr-star'"
              ></v-icon>

              <v-icon
                id="favorite-filters-star-icon__start"
                class="favorite-filters-star-absolute"
                color="primary"
                size="1.125rem"
                v-text="'fi-sr-star'"
                style="opacity: 0"
              ></v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>

      <v-col class="py-0 mt-2 mt-sm-0">
        <div class="d-sm-flex align-center justify-end">
          <ExportResults
            v-if="_exportablesOptions.length"
            :exportablesOptions="_exportablesOptions"
            class="mr-sm-2 mb-2 mb-sm-0"
            @alert="alertError"
          ></ExportResults>

          <v-btn
            v-if="_canNewActionPlan && _showNewActionPlan"
            :block="$vuetify.breakpoint.xsOnly"
            class="text-none mr-sm-2 mb-2 mb-sm-0"
            color="primary"
            dark
            depressed
            data-test-new-action-plan-btn
            @click.prevent="handleOpenActionPlanView()"
            data-test-btn-new-action-plan
          >
            <v-icon left>fi-rr-plus-small</v-icon>
            <span v-text="$t('actionPlan.sideBar.newPlanBttn')"></span>
          </v-btn>

          <VariationSelect
            v-if="_showVariationSelect"
            :hasPermission="_canEngagementPageVariation"
            @period-change="onPeriodChange"
          ></VariationSelect>

          <ShareResults
            v-if="_showShareResults"
            ref="shareResults"
            :people="people"
            :results="results"
            :links="links"
            :type="_context"
            @create-link="createLink($event)"
            @get-links="getLinks($event)"
            @delete-link="deleteLink($event)"
            @update-link="updateLink($event)"
            @get-result-sharing="getResultSharing($event)"
            @create-result-sharing="createResultSharing($event)"
            @update-result-sharing="updateResultSharing($event)"
            @delete-result-sharing="deleteResultSharing($event)"
            @get-people="getPeople($event)"
          ></ShareResults>
        </div>
      </v-col>
    </v-row>

    <PageNavigation></PageNavigation>

    <router-view
      :groupID="_isMulti ? multipleSelect : selectedGroupID"
      :variationPeriod="variationPeriod"
    ></router-view>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { _permissions } from '@/helpers/ability/engagement'
import { search } from '@/helpers/tree'
import CultureCodeGroups from '@/mixins/CultureCodeGroups'

import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import PageNavigation from '@/components/PageNavigation/PageNavigation.vue'

import ExportResults from '@/pages/EngagementPage/Partials/ExportResults/ExportResults.vue'
import GroupCare from '@/pages/EngagementPage/Partials/GroupCare/GroupCare.vue'
import ShareResults from '@/pages/EngagementPage/Partials/ShareResults/ShareResults.vue'
import VariationSelect from '@/pages/EngagementPage/Partials/VariationSelect/VariationSelect.vue'

import { addFavoriteFilters } from '@/service/favoriteFilters'
import { getPeople } from '@/service/people'
import { exportBenchmark } from '@/service/benchmark'
import { exportClimate } from '@/service/climate'
import { exportCulture } from '@/service/culture'
import {
  exportGroupsComparison,
  getGroupsFullTree,
} from '@/service/groupComparison'
import { exportParticipation } from '@/service/participation'

import {
  createLink,
  deleteLink,
  getLinks,
  updateLink,
} from '@/service/sharedLinks'

import {
  createResultSharing,
  deleteResultSharing,
  getResultSharing,
  updateResultSharing,
} from '@/service/resultsSharing'

export default {
  name: 'EngagementPage',
  mixins: [CultureCodeGroups],

  components: {
    ExportResults,
    GroupCare,
    HowToBeginAndWatchAVideo,
    PageNavigation,
    ShareResults,
    VariationSelect,
  },

  inject: ['actionPlan'],

  data() {
    return {
      favoriteLoading: false,
      selectedGroup: null,
      selectedGroupID: null,
      multipleSelect: [],
      groups: [],
      fullGroups: [],
      variationPeriod: 'real-time',
      people: [],
      results: [],
      links: [],
      demographicReportIsSelected: false,
      demographicReportWillBeDeselected: false,
    }
  },

  computed: {
    ...mapState(['groupId', 'groupIds', 'favorites']),

    _isProduction() {
      return process.env.NODE_ENV === 'production'
    },

    _canNewActionPlan() {
      return (
        this._canEngagementActionPlansCreator ||
        this._canEngagementActionPlansTemplatesCreator ||
        this._canEngagementActionPlansTemplates
      )
    },

    _canEngagementActionPlansTemplates() {
      return this.$can('access', _permissions.engagement_action_plans_templates)
    },
    _canEngagementActionPlansCreator() {
      return this.$can('access', _permissions.engagement_action_plans_creator)
    },
    _canEngagementActionPlansTemplatesCreator() {
      return this.$can(
        'access',
        _permissions.engagement_action_plans_templates_creator
      )
    },

    _showNewActionPlan() {
      return this.$route.name === 'ActionPlansPage'
    },
    _showVariationSelect() {
      const pagesToHideVariationSelect = [
        'ReceivedOpinionsPage',
        'ActionPlansPage',
      ]

      return !pagesToHideVariationSelect.includes(this.$route.name)
    },
    _canEngagementPageVariation() {
      const permissionsByPage = {
        ClimatePage: () =>
          this.$can('access', _permissions.engagement_climate_variation),

        ParticipationPage: () =>
          this.$can('access', _permissions.engagement_participations_variation),

        OpinionsAnalyticsReportPage: () =>
          this.$can('access', _permissions.engagement_opinions_variation),

        ActionPlanAnalyticsReportPage: () =>
          this.$can('access', _permissions.engagement_action_plans_variation),

        IndicatorsPage: () =>
          this.$can('access', _permissions.engagement_benchmark_variation),

        RankingPage: () =>
          this.$can('access', _permissions.engagement_benchmark_variation),

        InternalRankingPage: () =>
          this.$can('access', _permissions.engagement_benchmark_variation),

        CulturePage: () =>
          this.$can('access', _permissions.engagement_culture_variation),

        ComparisonPage: () =>
          this.$can(
            'access',
            _permissions.engagement_groups_comparation_variation
          ),
      }

      return permissionsByPage[this.$route.name]
        ? permissionsByPage[this.$route.name]()
        : false
    },

    _canEngagementFavoriteFilters() {
      return this.$can('access', _permissions.engagement_favorite_filters)
    },
    _isFavorite() {
      if (this._isMulti) {
        const index = this.favorites.items
          .map((e) => e.groups.map((g) => g.id || '').toString())
          .indexOf((this.groupIds || '').toString())

        return index >= 0
      }

      return this.favorites.items.some((e) =>
        e.groups.some(({ id }) => id === this.selectedGroupID)
      )
    },
    _isMulti() {
      const multiRoutes = ['ComparisonPage']
      return multiRoutes.includes(this.$route.name)
    },
    _context() {
      const route = this.$route.name
      const contextsByRoute = {
        ClimatePage: 'climate',
        CulturePage: 'culture',
      }

      return contextsByRoute[route]
    },
    _showShareResults() {
      switch (this._context) {
        case 'climate':
          return !this._isProduction
        case 'culture':
          return !this._isProduction
        default:
          return false
      }
    },
    _exportablesOptions() {
      const defaultsExportable = {
        xlsx: {
          id: 'xlsx',
          label: this.$t('exportable.xlsx'),
        },
        csv: {
          id: 'csv',
          label: this.$t('exportable.csv'),
        },
        pptx: {
          id: 'pptx',
          label: this.$t('exportable.pptx'),
        },
      }

      let exportableOptions = []

      const addExportableOption = (type, endpoint = null, payload = {}) => {
        exportableOptions.push({
          ...defaultsExportable[type],
          endpoint,
          payload: {
            type,
            ...payload,
          },
        })
      }

      const checkAccessAndAddExportableOption = (
        routeName,
        permission,
        type,
        endpoint = null,
        payload = {
          groupID: this.selectedGroupID,
        }
      ) => {
        if (this.$route.name === routeName && this.$can('access', permission)) {
          addExportableOption(type, endpoint, payload)
        }
      }

      checkAccessAndAddExportableOption(
        'ClimatePage',
        _permissions.engagement_climate_export_xlsx,
        'xlsx',
        exportClimate
      )
      checkAccessAndAddExportableOption(
        'ClimatePage',
        _permissions.engagement_climate_export_pptx,
        'pptx',
        exportClimate
      )
      checkAccessAndAddExportableOption(
        'ClimatePage',
        _permissions.engagement_climate_export_csv,
        'csv',
        exportClimate
      )

      checkAccessAndAddExportableOption(
        'CulturePage',
        _permissions.engagement_culture_export_xlsx,
        'xlsx',
        exportCulture
      )
      checkAccessAndAddExportableOption(
        'CulturePage',
        _permissions.engagement_culture_export_pptx,
        'pptx',
        exportCulture
      )

      checkAccessAndAddExportableOption(
        'BenchmarkPage',
        _permissions.engagement_benchmark_export_xlsx,
        'xlsx',
        exportBenchmark
      )

      checkAccessAndAddExportableOption(
        'ComparisonPage',
        _permissions.engagement_groups_comparation_export_csv,
        'csv',
        exportGroupsComparison,
        {
          groups: this.groupIds,
        }
      )

      checkAccessAndAddExportableOption(
        'ParticipationPage',
        _permissions.engagement_participations_export_xlsx,
        'xlsx',
        exportParticipation
      )

      checkAccessAndAddExportableOption(
        'ParticipationPage',
        _permissions.engagement_participations_export_pptx,
        'pptx',
        exportParticipation
      )

      return exportableOptions
    },
  },

  beforeMount() {
    this.getGroupsFullTree()
  },

  async mounted() {
    this.groups = await this.getGroups()
  },

  methods: {
    ...mapMutations(['setGroupId']),
    ...mapActions(['addFavorite']),

    clearChoice(val) {
      if (!val) this.setGroupId(null)
      if (this.demographicReportID) this.demographicReportID = null
    },

    closeExplanationNPS() {
      this.$store.commit('closeExplanationNPSDialog')
    },

    handleOpenActionPlanView() {
      this.actionPlan.open = true
    },

    async getGroupsFullTree() {
      try {
        await getGroupsFullTree().then((res) => {
          this.fullGroups = res.data
          this.selectedGroup = {
            data: [res.data.find((el) => !el.id)],
            origin: 'groups',
          }
        })
      } catch (err) {
        // err
      }
    },
    onPeriodChange(period) {
      this.variationPeriod = period
    },

    async handleFavorite() {
      const isFavoritable = !this._isFavorite

      this.favoriteLoading = true
      const favorite = await this.addFavoriteRequest().catch((err) => {
        this.alertError({
          title: this.$t('favoriteFilters.errors.post.title'),
          description: err.response.data.message,
        })
      })

      if (!favorite) {
        this.favoriteLoading = false
        return
      }

      this.addFavorite(favorite)

      const selectedIndex = this.favorites.items.length - 1
      this.favorites.itemSelectedIndex = selectedIndex
      this.favorites.itemEditIndex = selectedIndex
      this.favorites.itemEditValue = favorite.name

      this.favoriteLoading = false
      if (isFavoritable) {
        this.handleFavoriteAnimation()
      }
    },
    async addFavoriteRequest() {
      const findGroupsById = async (groups, ids) => {
        const result = []

        groups.forEach(async (group) => {
          if (ids.includes(group.id || '')) {
            result.push({ id: group.id, name: group.label })
          }

          if (group.children && group.children.length > 0) {
            const childGroups = await findGroupsById(group.children, ids)
            result.push(...childGroups)
          }
        })

        return result
      }

      const selectedGroupIds = this._isMulti ? this.groupIds : [this.groupId]
      const groups = await findGroupsById(this.groups, selectedGroupIds)

      const payload = {
        name: groups[0].name,
        multi: this._isMulti,
        groups,
      }

      return await addFavoriteFilters(payload).then(({ data }) => {
        return { ...payload, id: data.id }
      })
    },
    handleFavoriteAnimation() {
      const start = document.querySelector('#favorite-filters-star-icon__start')
      const end = document.querySelector('#favorite-filters-star-icon__end')
      const content = document.querySelector(
        '#engagement-page__group--content .engagement-page__favorite-button .v-btn__content'
      )

      if (!start || !end || !content) {
        return
      }

      const { x: x1, y: y1 } = start.getBoundingClientRect()
      const { x: x2, y: y2 } = end.getBoundingClientRect()

      const element = start.cloneNode(true)
      Object.assign(element.style, {
        position: 'absolute',
        zIndex: '99999999999',
        opacity: '1',
      })

      content.appendChild(element)

      const destination = x2 - x1
      const slope = (y2 - y1) / destination
      let tick = 2 //px

      const animationStep = () => {
        const posX = Number(element.style.left.replace(/[^0-9\-.]+/g, ''))

        const percentTraced = (posX * 100) / destination
        if (percentTraced >= 30) {
          tick = 10
        }

        if (posX >= destination) {
          setTimeout(() => {
            content.removeChild(element)
          }, 100)

          return
        }

        const newPosX = posX + tick
        const newPosY = slope * newPosX
        Object.assign(element.style, {
          left: `${newPosX}px`,
          top: `${newPosY - 3}px`,
        })

        requestAnimationFrame(animationStep)
      }

      requestAnimationFrame(animationStep)
    },

    handleFavoriteSelectedCheck() {
      if (!this._isFavorite) return

      // -> Single
      if (!this._isMulti) {
        const index = this.favorites.items
          .map((e) => (e.groups[0] || {}).id || null)
          .indexOf(this.multipleSelect)

        if (index < 0) return

        this.favorites.itemSelectedIndex = index
      }

      // -> Multiple
      if (!this.selectedGroupID) return
      const index = this.favorites.items
        .map((e) => (e.groups || []).map((g) => g.id))
        .findIndex((e) => e.toString() == this.selectedGroupID.toString())

      if (index < 0) return

      this.favorites.itemSelectedIndex = index
    },
    handleFavoriteResetSelection() {
      this.favorites.itemSelectedIndex = null
      this.favorites.itemEditIndex = null
      this.favorites.itemEditValue = null
    },

    async getResultSharing(payload) {
      try {
        const res = await getResultSharing(payload)
        this.results = res.data
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async createResultSharing(payload) {
      try {
        await createResultSharing(payload)
        this.$refs.shareResults.refreshViews()
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async updateResultSharing(payload) {
      try {
        await updateResultSharing(payload)
        this.$refs.shareResults.refreshViews()
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async deleteResultSharing(payload) {
      try {
        await deleteResultSharing(payload)
        this.$refs.shareResults.refreshViews()
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async getPeople(payload) {
      try {
        const res = await getPeople(payload)
        this.people = res.data
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async getLinks(payload) {
      try {
        const res = await getLinks(payload)
        this.links = res.data
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async createLink(payload) {
      try {
        await createLink(payload)
        this.$refs.shareResults.refreshLinks()
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async deleteLink(payload) {
      try {
        await deleteLink(payload)
        this.$refs.shareResults.refreshLinks()
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    async updateLink(payload) {
      try {
        await updateLink(payload)
        this.$refs.shareResults.refreshLinks()
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    alertError(data) {
      this.$root.$emit('display-alert', {
        title: data.title || data.message,
        description: data.description || '',
        type: 'error',
      })
    },
    demographicReportClickListener(ID) {
      this.demographicReportWillBeDeselected = !ID
      this.demographicReportIsSelected = !!ID
    },
  },

  created() {
    this.$root.$on(
      'app:click:demographic-report',
      this.demographicReportClickListener
    )
  },

  beforeDestroy() {
    this.$root.$off(
      'app:click:demographic-report',
      this.demographicReportClickListener
    )
  },

  watch: {
    $route: function () {
      if (this.$route.name === 'ComparisonPage') {
        this.handleFavoriteResetSelection()
      }
      const hideVariationOnTabs = ['ReceivedOpinionsPage']
      this.variationShow = !hideVariationOnTabs.includes(this.$route.name)
    },
    groupId: function (v) {
      if (
        this.demographicReportIsSelected ||
        this.demographicReportWillBeDeselected
      ) {
        this.selectedGroupID = v
        return
      }

      const result = search({ id: null, children: this.groups }, 'id', v)

      if (!result) {
        this.alertError({
          title: this.$t('favoriteFilters.errors.notFound.title'),
          description: this.$t('favoriteFilters.errors.notFound.description'),
        })
        return
      }

      if (v === null) {
        const objWithNullId = result.children.find((obj) => obj.id === null)
        this.selectedGroup = {
          data: [objWithNullId],
          origin: 'groups',
        }
        this.selectedGroupID = v
        this.handleFavoriteResetSelection()
        this.handleFavoriteSelectedCheck()
        return
      }

      this.selectedGroup = {
        data: [result],
        origin: 'groups',
      }
      this.selectedGroupID = v

      this.handleFavoriteSelectedCheck()
    },
    groupIds: function (v) {
      this.multipleSelect = v
      this.handleFavoriteSelectedCheck()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
