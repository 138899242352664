<template>
  <SkeletonLoader v-if="loading" />
  <div v-else class="shadow">
    <v-card elevation="0" height="100%">
      <v-card-title class="justify-center">
        <h4 class="card-title">
          {{ $t('engagementPage.cardAverageEngagement.cardTitle') }}
        </h4>
      </v-card-title>
      <v-card-text>
        <div class="engagement-container" data-test-groupScore>
          <div
            class="average-container"
            :class="{
              'no-info-cursor':
                score === null || hideQuickComparison.includes('climate'),
            }"
            @mouseenter="handleMouseEnter('climate')"
            @mouseleave="handleMouseLeave()"
          >
            <EngagementAverage :score="score" />
            <div
              class="tooltip-container"
              v-if="
                _haveQuickComparison && !hideQuickComparison.includes('climate')
              "
              data-test-engagementHover
            >
              <QuicklyComparison
                class="hidden-sm-and-down"
                :groupScore="_groupScore"
                :hasSelectedGroup="hasSelectedGroup"
                :accountScore="comparisonMetrics.account.score"
                :marketScore="comparisonMetrics.average.score"
                :groupPercent="_groupRank"
                :accountPercent="comparisonMetrics.account.rank"
                :marketPercent="comparisonMetrics.average.rank"
                :hide-market-score="_hideMarket"
                :percentageOfCompanies="_percentageCompanies"
              />
            </div>
          </div>
          <div class="engagement-subtitle">
            <Variation
              :variation="variation.score"
              :loading="variation.loading"
              data-test-average-variation
            />
            <span>
              {{ $t('engagementPage.variationTextDays', { days: 7 }) }}
            </span>
          </div>
          <p class="engagement-description">
            {{ $t('engagementPage.cardAverageEngagement.reportDescription') }}
          </p>
        </div>
        <div class="fast-comparison-container">
          <div
            class="participation-rate-container"
            :class="{
              'no-info-cursor':
                participationRate === null ||
                hideQuickComparison.includes('participation'),
            }"
            @mouseenter="handleMouseEnter('participation')"
            @mouseleave="handleMouseLeave()"
          >
            <span>{{
              $t('engagementPage.cardAverageEngagement.participationRate')
            }}</span>
            <div
              class="tooltip-container"
              v-if="
                _haveQuickComparison &&
                !hideQuickComparison.includes('participation')
              "
            >
              <QuicklyComparison
                class="hidden-sm-and-down"
                :groupScore="_groupScore"
                :hasSelectedGroup="hasSelectedGroup"
                :accountScore="comparisonMetrics.account.score"
                :marketScore="comparisonMetrics.average.score"
                :groupPercent="_groupRank"
                :accountPercent="comparisonMetrics.account.rank"
                :marketPercent="comparisonMetrics.average.rank"
                :hide-market-score="_hideMarket"
                :percentageOfCompanies="_percentageCompanies"
                :percentage="true"
                data-test-participation-quick-comparison
              />
            </div>
            <h1 data-test-participation-rate>
              {{ participationRate ? participationRate + '%' : '--' }}
            </h1>
            <Variation
              :variation="variation.participation"
              :loading="variation.loading"
              type="percent"
              data-test-participation-variation
            />
          </div>
          <div class="line"></div>
          <div
            class="answers-rate-container"
            :class="{
              'no-info-cursor':
                opinionsRate === null ||
                hideQuickComparison.includes('answeredOpinions'),
            }"
            @mouseenter="handleMouseEnter('answeredOpinions')"
            @mouseleave="handleMouseLeave()"
          >
            <span>{{
              $t('engagementPage.cardAverageEngagement.answeredOpnions')
            }}</span>
            <div
              class="tooltip-container"
              v-if="
                _haveQuickComparison &&
                !hideQuickComparison.includes('answeredOpinions')
              "
            >
              <QuicklyComparison
                class="hidden-sm-and-down"
                :groupScore="_groupScore"
                :hasSelectedGroup="hasSelectedGroup"
                :accountScore="comparisonMetrics.account.score"
                :marketScore="comparisonMetrics.average.score"
                :groupPercent="_groupRank"
                :accountPercent="comparisonMetrics.account.rank"
                :marketPercent="comparisonMetrics.average.rank"
                :hide-market-score="_hideMarket"
                :percentageOfCompanies="_percentageCompanies"
                :percentage="true"
                data-test-participation-quick-comparison
              />
            </div>
            <h1 data-test-opinions-rate>
              {{ opinionsRate ? opinionsRate + '%' : '--' }}
            </h1>
            <Variation
              :variation="variation.answeredOpinions"
              :loading="variation.loading"
              type="percent"
              data-test-opinions-variation
            />
          </div>
        </div>
      </v-card-text>
      <div class="footer-card-engagement">
        <div class="footer">
          <HowToBeginAndWatchAVideo videoType="climate.overallAverage" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import SkeletonLoader from './parts/SkeletonLoader.vue'
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'CardAverageEngagement',
  components: {
    EngagementAverage: () =>
      import('@/components/EngagementAverage/EngagementAverage.vue'),
    Variation: () => import('@/components/Variation/Variation.vue'),
    HowToBeginAndWatchAVideo: () =>
      import(
        '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
      ),
    QuicklyComparison: () =>
      import('@/components/QuicklyComparison/QuicklyComparison.vue'),
    SkeletonLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    score: {
      type: Number,
    },
    participationRate: {
      type: Number,
    },
    opinionsRate: {
      type: Number,
    },
    comparisonMetrics: {
      type: Object,
      requred: true,
    },
    hasSelectedGroup: {
      type: Boolean,
    },
    variation: {
      type: Object,
      requred: true,
      default: () => {},
    },

    hideQuickComparison: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _hideMarket() {
      return !this.$can('access', _permissions.engagement_benchmark_results)
    },
    _groupScore() {
      if (this.comparisonMetrics.hasOwnProperty('group')) {
        return this.comparisonMetrics.group.score
      }
      return -1
    },
    _groupRank() {
      if (this.comparisonMetrics.hasOwnProperty('group')) {
        return this.comparisonMetrics.group.rank
      }
      return -1
    },
    _percentageCompanies() {
      if (this.comparisonMetrics.hasOwnProperty('group')) {
        return this.comparisonMetrics.group.rank
      }
      return this.comparisonMetrics.account.rank
    },
    _haveQuickComparison() {
      return !!this.comparisonMetrics.account.score
    },
  },

  methods: {
    handleMouseEnter(indicatorID) {
      if (this.hideQuickComparison.includes(indicatorID)) {
        return
      }

      this.$emit('hoverOn:overall-average', indicatorID)
    },
    handleMouseLeave() {
      this.$emit('hoverOff:overall-average')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
