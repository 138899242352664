<template>
  <section class="content-section">
    <div class="content-section-title">
      {{ $t('shareResults.sharedLinks.title') }}
    </div>

    <v-form v-model="valid" class="mb-3" ref="form" auto-complete="off">
      <v-row class="form-margin" align="start" no-gutters>
        <v-col cols="12" sm="4" class="pr-sm-2 mb-2 mb-sm-0">
          <v-text-field
            data-test-field-name
            v-model="form.name"
            :label="$t('shareResults.sharedLinks.form.name')"
            class="custom-input"
            hide-details="auto"
            color="neutral5"
            :rules="rules"
            outlined
            dense
          />
        </v-col>

        <v-col cols="12" sm="3" class="pr-sm-2 mb-2 mb-sm-0">
          <v-select
            data-test-field-select
            v-model="form.access"
            :label="$t('shareResults.sharedLinks.form.access')"
            class="custom-input"
            hide-details="auto"
            color="neutral5"
            item-text="label"
            item-value="value"
            :items="accessList"
            :rules="rules"
            outlined
            dense
          >
            <template v-slot:item="{ item }">
              <span class="select-item" data-test-access-list-item>{{
                item.label
              }}</span>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="3" class="pr-sm-2 mb-2 mb-sm-0">
          <v-text-field
            data-test-field-password
            v-model="form.password"
            :label="$t('shareResults.sharedLinks.form.password')"
            :type="showPass ? 'text' : 'password'"
            class="custom-input"
            hide-details="auto"
            color="neutral5"
            :rules="rules"
            outlined
            dense
          >
            <template v-slot:append>
              <v-btn
                color="neutral5"
                x-small
                icon
                @click="showPass = !showPass"
              >
                <v-icon>
                  {{ showPass ? 'fi-rr-eye-crossed' : 'fi-rr-eye' }}
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="2">
          <v-btn
            data-test-btn-submit-link
            class="text-none"
            color="primary"
            height="2.5rem"
            depressed
            block
            @click="createSharedLink()"
          >
            <label class="bttn-label">
              {{ $t('shareResults.sharedLinks.form.generate') }}
            </label>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="links"
      class="custom-table"
      height="100%"
      :no-data-text="$t('shareResults.noData')"
      hide-default-footer
      disable-pagination
      disable-sort
      dense
    >
      <!-- acccess -->
      <template v-slot:[`item.access`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="clickable" v-bind="attrs" v-on="on">
              <span v-if="!!item.access" class="mr-1">
                {{ $t(`shareResults.permissions.${item.access}`) }}
              </span>

              <span v-else class="mr-1">
                {{ $t(`shareResults.noInformation`) }}
              </span>

              <v-icon color="neutral5" x-small>fi fi-sr-pencil</v-icon>
            </div>
          </template>

          <v-list dense>
            <v-list dense>
              <v-list-item-group
                v-model="item.access"
                @change="updateLink(item)"
              >
                <template v-for="(subitem, i) in accessList">
                  <v-list-item
                    :key="i"
                    :value="subitem.value"
                    active-class="bgActive primary--text"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action class="mr-2">
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-title>{{ subitem.label }}</v-list-item-title>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-list>
        </v-menu>
      </template>

      <!-- link -->
      <template v-slot:[`item.link`]="{ item }">
        <div class="clickable" @click="copy(item.code)">
          <span class="mr-1"> {{ shareUrl }}{{ item.code }} </span>
          <v-icon color="neutral5" x-small>fi-rr-copy</v-icon>
        </div>
      </template>

      <!-- action -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon small @click="deleteLink(item.id)">
          <v-icon color="neutral5" small> fi-rr-trash </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- pagination -->
    <div class="d-flex justify-center align-center py-3">
      <v-btn class="mr-1" tile icon x-small @click="prev()">
        <v-icon x-small>fi fi-br-angle-left</v-icon>
      </v-btn>

      <v-sheet
        color="grayColor2"
        height="18"
        width="18"
        class="d-flex align-center justify-center fill-height rounded-md"
      >
        <span class="label-pagination"> {{ pagination.page }} </span>
      </v-sheet>

      <v-btn class="ml-1" tile icon x-small @click="next()">
        <v-icon x-small>fi fi-br-angle-right</v-icon>
      </v-btn>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      valid: false,
      showPass: false,
      form: {
        name: '',
        access: '',
        password: '',
      },
      headers: [
        {
          text: this.$t('shareResults.sharedLinks.table.name'),
          value: 'name',
        },
        {
          text: this.$t('shareResults.sharedLinks.table.access'),
          value: 'access',
        },
        {
          text: this.$t('shareResults.sharedLinks.table.link'),
          value: 'link',
        },
        {
          text: this.$t('shareResults.sharedLinks.table.actions'),
          value: 'actions',
          width: '6em',
          align: 'center',
        },
      ],
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
      },
    }
  },

  props: {
    type: null,
    accessList: [],
    links: [],
  },

  mounted() {
    this.getLinks()
  },

  computed: {
    ...mapGetters(['getGroupId']),

    rules() {
      return [(v) => !!v || this.$t('shareResults.sharedLinks.requiredField')]
    },

    shareUrl() {
      return 'https://teamculture.com.br/'
    },
  },

  methods: {
    async createSharedLink() {
      if (!this.$refs.form.validate()) return

      const payload = {
        module: this.type,
        groupID: this.getGroupId || null,
        name: this.form.name,
        access: this.form.access,
        password: this.form.password,
      }

      this.$emit('create-link', payload)

      this.$refs.form.reset()
    },

    async getLinks() {
      const payload = {
        module: this.type,
        groupID: this.getGroupId || null,
      }

      this.$emit('get-links', payload)
    },

    async deleteLink(id) {
      this.$emit('delete-link', id)
    },

    async updateLink(data) {
      const payload = {
        sharedLinkID: data.id,
        body: {
          access: data.access,
          name: data.name,
        },
      }

      this.$emit('update-link', payload)
    },

    copy(code) {
      const url = this.shareUrl + code
      navigator.clipboard.writeText(url)
    },

    prev() {
      if (this.pagination.page === 1) return

      this.pagination.page--
      this.pagination.offset = this.pagination.offset - this.pagination.limit
      this.getLinks()
    },

    next() {
      if (this.links.length < this.pagination.limit) return

      this.pagination.page++
      this.pagination.offset = this.pagination.offset + this.pagination.limit
      this.getLinks()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
