<template>
  <div id="vertical-bars">
    <apexchart type="bar" height="480" :options="options" :series="series" />
    <div v-if="isEmpty" class="empty-info">
      {{ noDataLabel }}
    </div>
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'

export default {
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => [],
    },
    noDataLabel: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isEmpty() {
      return this.types.length === 0 || this.values.length === 0
    },
    customColors() {
      if (this.isEmpty) {
        return [variables.neutral8]
      }
      return this.colors
    },
    minHeight() {
      if (this.isEmpty) {
        return 0
      }
      return 130
    },
    series() {
      if (this.isEmpty) {
        return this.labels.map((label) => {
          return {
            name: label,
            data: [null],
          }
        })
      }
      return this.values
    },
    categories() {
      if (this.isEmpty) {
        return []
      }
      return this.types
    },
    options() {
      const vm = this
      return {
        series: this.series,
        chart: {
          type: 'bar',
          height: 480,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        colors: this.customColors,
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        fill: {
          opacity: 1,
        },
        xaxis: {
          labels: {
            show: !vm.isEmpty,
            offsetY: 50,
            offsetX: -20,
            rotate: -90,
            rotateAlways: true,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: false,
            formatter(value) {
              if (value.length > 12) {
                const [first, ...final] = value.split(' ')
                return [first, final.join(' ').trimStart()]
              }
              return value
            },
            minHeight: vm.minHeight,
            style: {
              colors: variables.neutral1,
              fontSize: '12px',
              fontFamily: 'poppins',
              fontWeight: 400,
              lineHeight: '15px',
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          categories: this.categories,
        },
        legend: {
          position: 'bottom',
          showForNullSeries: true,
          horizontalAlign: 'left',
          markers: {
            shape: 'circle',
            radius: 50,
          },
        },
      }
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
