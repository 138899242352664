<template>
  <div id="pillars-of-engagement-group" class="card">
    <div>
      <v-btn icon color="neutral2">
        <v-icon v-text="'fi-rr-arrow-left'" />
      </v-btn>

      <div class="header-container">
        <v-row>
          <v-col class="history">
            <label
              class="history-title"
              v-text="$t('pillarsOfEngagementGroup.card.title')"
            />
            <div>
              <HowToBeginAndWatchAVideo videoType="climate.pillars" />
            </div>
          </v-col>
          <v-col class="stats" cols="auto">
            <v-skeleton-loader type="button" class="skeleton-header" />
          </v-col>
        </v-row>
        <v-row class="subtitle">
          <span>{{ $t('pillarsOfEngagementGroup.card.subtitle') }}</span>
        </v-row>
      </div>
    </div>
    <v-divider class="custom-divider"></v-divider>
    <v-item-group class="skeleton-box">
      <div class="skeleton" v-for="i in 3" :key="i">
        <div class="skeleton-icon">
          <v-skeleton-loader type="button" class="skeleton-icon--loader" />
        </div>
        <div class="skeleton-bar">
          <v-skeleton-loader type="button" class="skeleton-bar--loader" />
          <v-skeleton-loader type="button" class="skeleton-bar--loader" />
        </div>
        <div class="skeleton-value">
          <v-skeleton-loader type="button" class="skeleton-value--loader" />
        </div>
      </div>
    </v-item-group>
  </div>
</template>
<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
export default {
  components: {
    HowToBeginAndWatchAVideo,
  },
  props: {
    title: String,
  },
}
</script>
<style src="../style.scss" lang="scss" scoped></style>
