<template>
  <div class="action-plans">
    <div v-if="!loading">
      <TemplateList
        v-if="window === 1"
        ref="templates"
        :templates="templates"
        :displayNewPlan="_canNewActionPlan"
        @back="handleBack()"
        @close="close()"
        @new-plan="createPlan()"
        @get-templates="getTemplates($event)"
        @delete-template="deleteTemplate($event)"
        @open-template="openTemplate($event)"
      ></TemplateList>

      <NewPlan
        v-if="window === 2"
        ref="newPlan"
        :template="template"
        :planData="planData"
        :groups="groups"
        :indicators="indicators"
        :people="people"
        :tasks="tasks"
        :locked="!_canNewActionPlan"
        :canCreateNewTemplate="_canEngagementActionPlansTemplatesCreator"
        :canCreateNewPlan="_canEngagementActionPlansCreator"
        @back="handleBack()"
        @close="close()"
        @get-people="getPeople($event)"
        @get-indicators="getIndicators($event)"
        @save-template="saveTemplate($event)"
        @new-plan="newPlan($event)"
        @edit-plan="editPlan($event)"
        @new-task-edit="newTaskEdit($event)"
        @edit-task="handleEditTask($event)"
        @delete-task="deleteTask($event)"
        @get-groups="getGroups($event)"
      ></NewPlan>
    </div>
  </div>
</template>

<script>
import NewPlan from './Partials/NewPlan/NewPlan'
import TemplateList from './Partials/TemplateList/TemplateList'

import {
  deleteTask,
  deleteTemplate,
  editPlan,
  editTask,
  getIndicators,
  getPlan,
  getTasks,
  getTemplateById,
  getTemplates,
  newPlan,
  newTask,
  saveTemplate,
} from '@/service/actionPlans'
import { getGroupsTree } from '@/service/groups'
import { getPeople } from '@/service/people'

import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'ActionPlans',
  components: {
    NewPlan,
    TemplateList,
  },

  inject: ['actionPlan'],

  props: {
    planID: null,
    planTemplate: null,
  },

  data() {
    return {
      window: 1,
      loading: true,

      template: null,
      planData: null,

      templates: [],
      groups: [],
      indicators: [],
      people: [],
      tasks: [],
    }
  },

  computed: {
    _canNewActionPlan() {
      return (
        this._canEngagementActionPlansCreator ||
        this._canEngagementActionPlansTemplatesCreator
      )
    },

    _canEngagementActionPlansTemplates() {
      return this.$can('access', _permissions.engagement_action_plans_templates)
    },
    _canEngagementActionPlansCreator() {
      return this.$can('access', _permissions.engagement_action_plans_creator)
    },
    _canEngagementActionPlansTemplatesCreator() {
      return this.$can(
        'access',
        _permissions.engagement_action_plans_templates_creator
      )
    },
  },

  created() {
    this.handleOverflow('hidden')
  },

  beforeDestroy() {
    this.handleOverflow('scroll')
  },

  beforeMount() {
    if (!this._canEngagementActionPlansTemplates) {
      this.window = 2
    }

    if (this.planID) {
      this.openPlan(this.planID)
    } else if (this.planTemplate) {
      this.openPropTemplate()
    } else {
      this.loading = false
    }
  },

  methods: {
    handleOverflow(overflowValue) {
      const htmlElement = document.querySelector('html')
      htmlElement.style.overflowY = overflowValue
    },

    // get templates
    async getTemplates(payload) {
      try {
        await getTemplates(payload).then((res) => {
          this.templates = res.data
          this.$refs.templates.stopLoad()
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // delete template
    async deleteTemplate(id) {
      try {
        await deleteTemplate(id).then((res) => {
          this.alertSuccess({
            title: this.$t('actionPlan.sideBar.form.deletedTemplate'),
          })
          this.$refs.templates.getTemplates()
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // submit save template
    async saveTemplate(payload) {
      try {
        await saveTemplate(payload).then(() => {
          this.alertSuccess({
            title: this.$t('actionPlan.sideBar.form.savedTemplate'),
          })
          this.$refs.newPlan.handleSavedTemplate()
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // submit create a new plan
    async newPlan(payload) {
      try {
        await newPlan(payload).then(() => {
          this.$root.$emit('update-action-plan', { newPlan: true })
          this.$root.$emit('action-plans:reload')
          this.alertSuccess({
            title: this.$t('actionPlan.sideBar.form.createdPlan'),
          })
          this.close()
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // submit edit current plan
    async editPlan(payload) {
      try {
        await editPlan(payload).then(() => {
          this.$root.$emit('update-action-plan')
          this.$root.$emit('action-plans:reload')
          this.alertSuccess({
            title: this.$t('actionPlan.sideBar.planUpdated'),
          })
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // get available groups
    async getGroups() {
      try {
        const { data } = await getGroupsTree()
        this.groups = data.filter((group) => group.id !== null)
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // get list of people
    async getPeople(payload) {
      try {
        await getPeople(payload).then((res) => {
          this.people = res.data
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // get indicators by type and group
    async getIndicators(payload) {
      try {
        await getIndicators(payload).then((res) => {
          this.indicators = res.data
          this.$refs.newPlan.handleIndicator(this.indicators)
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // submit for edit mode
    async handleEditTask(payload) {
      try {
        await editTask(payload).then(() => {
          this.$root.$emit('update-action-plan')
          this.getTasks(payload.actionPlanID)
          this.alertSuccess({
            title: this.$t('actionPlan.sideBar.taskUpdated'),
          })
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // create a new task on edit mode
    async newTaskEdit(payload) {
      try {
        await newTask(payload).then(() => {
          this.$root.$emit('update-action-plan')
          this.getTasks(payload.actionPlanID)
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // delete task
    async deleteTask(payload) {
      try {
        await deleteTask(payload).then(() => {
          this.$root.$emit('update-action-plan')
          this.getTasks(payload.actionPlanID)
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // get tasks on edit mode
    async getTasks(id) {
      try {
        await getTasks(id).then((res) => {
          const items = res.data?.map((el) => {
            return {
              ...el,
              assignedTo:
                el.assignedTo?.map((e) => {
                  return {
                    ...e,
                    id: e.id,
                    label: e.name,
                    image: e.photo,
                  }
                }) || [],
            }
          })

          this.tasks = items || []
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // get data of template
    async openTemplate(id) {
      try {
        await getTemplateById(id).then((res) => {
          this.template = { savedTemplate: true, ...res.data }
          this.window = 2
        })
      } catch (err) {
        this.alertError(err.response.data)
      }
    },

    // get data of edit plan
    async openPlan(id) {
      try {
        await getPlan(id).then((res) => {
          this.planData = {
            editMode: true,
            ...res.data,
            tasks:
              res.data?.tasks?.map((el) => {
                return {
                  ...el,
                  assignedTo:
                    el.assignedTo?.map((e) => {
                      return {
                        ...e,
                        id: e.id,
                        label: e.name,
                        image: e.photo,
                      }
                    }) || [],
                }
              }) || [],
          }
          this.window = 2
          this.loading = false
        })
      } catch (err) {
        this.alertError(err.response.data)
        this.close()
      }
    },

    // handle to 2 window
    createPlan() {
      this.template = {}
      this.window = 2
    },

    openPropTemplate() {
      this.template = this.planTemplate
      this.loading = false
      this.window = 2
    },

    handleBack() {
      if (!this._canEngagementActionPlansTemplates) {
        this.close()
      }

      if (this.window === 1) {
        this.close()
      } else {
        this.planData = null
        this.template = null
        this.indicators = []
        this.tasks = []
        this.window = 1
      }
    },

    // display alertbar
    alertError(data) {
      this.$root.$emit('display-alert', {
        title: data.title || data.message,
        description: data.description || '',
        type: 'error',
      })
    },

    alertSuccess(data) {
      this.$root.$emit('display-alert', {
        title: data.title || data.message,
        description: data.description || '',
        type: 'success',
      })
    },

    close() {
      this.actionPlan.open = false
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
