<template>
  <v-snackbar
    v-model="show"
    :data-test-alert="message.type"
    id="alertbar"
    class="pa-0"
    width="600"
    :timeout="-1"
    bottom
    light
    left
  >
    <div class="d-flex flex-column content">
      <div class="d-flex align-center content-header">
        <v-avatar
          v-if="message.type === 'success'"
          id="successIcon"
          color="greenColor"
          class="mr-2"
          size="16"
        >
          <v-icon color="white" size="8"> fi fi-rr-check </v-icon>
        </v-avatar>

        <v-avatar v-else id="errorIcon" color="redColor" class="mr-2" size="16">
          <v-icon color="white" size="8"> fi fi-rr-cross </v-icon>
        </v-avatar>

        <span
          id="title"
          data-test-alert-content
          :class="`content-header-title content-${message.type}`"
        >
          {{ message.title }}
        </span>

        <v-spacer />

        <v-btn id="closeIcon" x-small icon @click.prevent="close()">
          <v-icon small>fi fi-rr-cross-small</v-icon>
        </v-btn>
      </div>

      <div class="content-section">
        <span
          id="description"
          class="content-section-description"
          v-html="message.description"
        >
        </span>
      </div>
    </div>

    <v-progress-linear
      id="progress"
      absolute
      bottom
      :color="message.type === 'success' ? 'greenColor' : 'redColor'"
      :value="Math.floor(100 * (currentTime / timeout))"
    />
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      interval: null,
      timeout: 10000,
      currentTime: 0,
      message: {
        title: '',
        description: '',
        type: 'success',
      },
    }
  },

  beforeMount() {
    this.$root.$on('display-alert', (data) => {
      this.displayAlert(data)
    })
  },

  beforeDestroy() {
    this.$root.$off('display-alert')
  },

  watch: {
    show(v) {
      this.handleShow(v)
    },
  },

  methods: {
    // call alert
    displayAlert(data) {
      this.message = data
      if (data.timeout) this.timeout = data.timeout
      this.show = true
    },

    // handle start or close alert
    handleShow(value) {
      if (!!value) this.startTimer()
      else this.close()
    },

    // start timer
    startTimer() {
      this.interval = setInterval(() => {
        this.handleTimer()
      }, 100)
    },

    // handle progress timer
    handleTimer() {
      this.currentTime += 100
      if (this.timeout <= this.currentTime) this.close()
    },

    // close
    close() {
      clearInterval(this.interval)
      this.show = false
      this.currentTime = 0
      this.timeout = 10000
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
