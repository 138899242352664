var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-entity",attrs:{"data-test-entity-detail":"","id":"entity-detail"}},[_c('div',{staticClass:"item-first-line"},[_c('div',{staticClass:"item-icon"},[_c('v-img',{attrs:{"src":require(`@/assets/icons/${_vm.item.entityType}.png`),"alt":_vm.item.entityType,"width":"1.63rem","height":"1.63rem"}})],1),_c('div',{staticClass:"item-text"},[_c('TruncatedArea',{attrs:{"refText":_vm.refTruncatedAreaTitleText,"zIndex":_vm.zIndex,"tooltipOnHover":""},scopedSlots:_vm._u([{key:"outside",fn:function({ setHover }){return [_c('div',{ref:"titleText",staticClass:"item-text-title truncated-text text-on-hover",class:{
              truncate: _vm.hasTooltip,
              'cursor-pointer': _vm.canTitleClick,
            },attrs:{"data-test-entity-detail-title":""},on:{"click":_vm.titleClicked,"mouseover":function($event){return setHover(true)},"mouseleave":function($event){return setHover(false)}}},[_c('span',{attrs:{"data-test-entity-detail-title-text":""},domProps:{"textContent":_vm._s(_vm.item.title)}})])]}},{key:"default",fn:function(){return [_c('div',{attrs:{"data-test-entity-detail-title-tooltip":""},domProps:{"textContent":_vm._s(_vm.item.title)}})]},proxy:true}])}),_c('div',{staticClass:"item-text-code"},[_c('span',[_vm._v(_vm._s(_vm.item.code))])])],1)]),_c('div',{staticClass:"item-second-line"},[_vm._l((_vm.secondLineFields),function(field){return [(field === 'owners' && _vm.haveName(_vm.item))?_c('div',{key:field,staticClass:"item-text-info owners"},[_c('AvatarGroup',{attrs:{"avatars":_vm.item.owners.map((e) => ({
              id: e.id,
              image: e.photo,
              text: e.name,
            })),"size":14}}),_c('span',{domProps:{"textContent":_vm._s(_vm.haveName(_vm.item))}}),(_vm.item.owners && _vm.item.owners.length > 1)?_c('v-tooltip',{attrs:{"z-index":_vm.zIndex,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.getAdditionalNames(_vm.item))}},'span',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column px-2 py-1"},_vm._l((_vm.item.owners
                .filter((e, i) => i > 0)
                .map((e) => e.name)),function(name){return _c('span',{key:name,staticClass:"pa-1",domProps:{"textContent":_vm._s(name)}})}),0)]):_vm._e()],1):_vm._e(),(field === 'group')?_c('div',{key:field,staticClass:"item-text-info group"},[_c('span',{attrs:{"data-test-group-name":""},domProps:{"textContent":_vm._s(_vm.item.group.name)}})]):_vm._e(),(field === 'cycle')?_c('div',{key:field,staticClass:"item-text-info cycle"},[_c('span',{attrs:{"data-test-cycle-title":""},domProps:{"textContent":_vm._s(_vm.item.cycle.title)}})]):_vm._e(),(field === 'class' && _vm.item.entityClass)?_c('div',{key:field,staticClass:"item-text-info class"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t(`ViewEntity.entityClass.${_vm.item.entityClass}`))}})]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }