import { ability } from '@/config/ability'
import { AbilityBuilder } from '@casl/ability'
export * from '@/config/ability'

export const defineAbility = (permissions = [], additional = false) => {
  const isArray = Array.isArray(permissions)
  const isStringArray = permissions.every((key) => typeof key === 'string')

  if (!isArray || !isStringArray) {
    permissions = []
  }

  let ability_ = new AbilityBuilder()
  if (additional) {
    ability_ = ability
  }

  permissions.forEach((key) => ability_.can('access', key))
  ability.update(ability_.rules)
}
