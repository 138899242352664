<template>
  <div data-test-sidebar-action-plan-filter>
    <LateralMenuItem :title="$t('actionPlanFilter.title')" :isMini="isMini">
      <template v-slot:title-icon="{ size }">
        <v-icon :size="size">mdi-flash</v-icon>
      </template>

      <div id="actionPlanFilterContent">
        <v-row
          v-for="filter in _selects"
          :key="filter.key"
          class="mx-0 mt-1 mb-4"
        >
          <v-select
            :[`${filter.dataTestKey}`]="''"
            v-model="filterValues[filter.stateKey]"
            hide-details
            outlined
            dense
            clearable
            height="44px"
            width="100%"
            :items="filter.items"
            :label="filter.label"
            :menu-props="{ 'content-class': 'select-filter' }"
            @change="onInputChanges"
          >
            <template v-slot:append>
              <i class="fi fi-br-caret-down select-icon"></i>
            </template>
          </v-select>
        </v-row>
        <v-row class="mx-0 mt-0">
          <v-menu
            ref="menu"
            v-model="datepicker.show"
            transition="scale-transition"
            offset-y
            min-width="auto"
            z-index="1000001"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                data-test-deadline-input
                :value="_deadlineRangeText"
                hide-details
                :label="$t('actionPlanFilter.deadline')"
                clearable
                outlined
                placeholder=""
                width="100%"
                height="44px"
                dense
                v-bind="attrs"
                v-on="on"
                readonly
                @click:clear="clearDeadline"
              ></v-text-field>
            </template>
            <v-date-picker
              data-test-deadline-datepicker
              v-model="datepicker.value"
              no-title
              scrollable
              :locale="$i18n.locale"
              color="primary"
              range
              @input="filterValues.deadline = formatDate(datepicker.value)"
              @change="onInputChanges"
            ></v-date-picker>
          </v-menu>
        </v-row>
      </div>
    </LateralMenuItem>
  </div>
</template>

<script>
import moment from 'moment'
import LateralMenuItem from '@/components/LateralMenuItem/LateralMenuItem.vue'

export default {
  name: 'ActionPlanFilter',
  components: {
    LateralMenuItem,
  },
  props: {
    isMini: Boolean,
    creatorsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statusList: [
        {
          text: this.$t('actionPlanFilter.statusFilter.doing'),
          value: 'doing',
        },
        { text: this.$t('actionPlanFilter.statusFilter.done'), value: 'done' },
        {
          text: this.$t('actionPlanFilter.statusFilter.delayed'),
          value: 'delayed',
        },
      ],
      typeList: [
        {
          text: this.$t('actionPlanFilter.typeFilter.generic'),
          value: 'generic',
        },
        {
          text: this.$t('actionPlanFilter.typeFilter.climate'),
          value: 'climate',
        },
        {
          text: this.$t('actionPlanFilter.typeFilter.culture'),
          value: 'culture',
        },
        {
          text: this.$t('actionPlanFilter.typeFilter.participation'),
          value: 'participation',
        },
      ],
      metricLsit: [
        {
          text: this.$t('actionPlanFilter.metricFilter.invitedRespondersRate'),
          value: 'invitedRespondersRate',
        },
        {
          text: this.$t(
            'actionPlanFilter.metricFilter.confirmedRespondersRate'
          ),
          value: 'confirmedRespondersRate',
        },
        { text: this.$t('actionPlanFilter.metricFilter.enps'), value: 'enps' },
        { text: this.$t('actionPlanFilter.metricFilter.lnps'), value: 'lnps' },
        {
          text: this.$t('actionPlanFilter.metricFilter.managerRelationship'),
          value: 'managerRelationship',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.ambassadorship'),
          value: 'ambassadorship',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.alignment'),
          value: 'alignment',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.wellness'),
          value: 'wellness',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.personalGrowth'),
          value: 'personalGrowth',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.happiness'),
          value: 'happiness',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.satisfaction'),
          value: 'satisfaction',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.recognition'),
          value: 'recognition',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.peersRelationship'),
          value: 'peersRelationship',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.feedback'),
          value: 'feedback',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.diversity'),
          value: 'diversity',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.psychologicalSafety'),
          value: 'psychologicalSafety',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.burnout'),
          value: 'burnout',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.maturityLevel'),
          value: 'maturityLevel',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.culturalAlignment'),
          value: 'culturalAlignment',
        },
        {
          text: this.$t('actionPlanFilter.metricFilter.unfavorableCulture'),
          value: 'unfavorableCulture',
        },
      ],
      filterValues: {
        deadline: null,
        status: null,
        creator: null,
        type: null,
        metric: null,
      },
      deadline: null,
      datepicker: {
        show: false,
        value: [],
      },
      debounceTimeOut: null,
    }
  },
  computed: {
    _selects() {
      return [
        {
          key: 'status-slct',
          label: this.$t('actionPlanFilter.status'),
          items: this.statusList,
          stateKey: 'status',
          dataTestKey: 'data-test-status-filter',
        },
        {
          key: 'creators-slct',
          label: this.$t('actionPlanFilter.creator'),
          items: this.creatorsList,
          stateKey: 'creator',
          dataTestKey: 'data-test-creator-filter',
        },
        {
          key: 'types-slct',
          label: this.$t('actionPlanFilter.type'),
          items: this.typeList,
          stateKey: 'type',
          dataTestKey: 'data-test-type-filter',
        },
        {
          key: 'metrics-slct',
          label: this.$t('actionPlanFilter.metric'),
          items: this.metricLsit,
          stateKey: 'metric',
          dataTestKey: 'data-test-metric-filter',
        },
      ]
    },
    _deadlineRangeText() {
      const formattedDate = this.datepicker.value.map((date) => {
        return moment(date).format('DD/MM/YYYY')
      })
      return formattedDate.join(' - ')
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      return moment(this.datepicker.value).format('DD/MM/YYYY')
    },
    clearDeadline() {
      this.datepicker.value = []
      this.filterValues.deadline = null
      this.onInputChanges()
    },
    onInputChanges() {
      clearTimeout(this.debounceTimeOut)

      this.debounceTimeOut = setTimeout(() => {
        this.sendFilterValues()
      }, 800)
    },
    sendFilterValues() {
      const filterValues = {
        personID: this.filterValues.creator,
        indicatorID: this.filterValues.metric,
        type: this.filterValues.type,
        status: this.filterValues.status,
        fromDate: this.datepicker.value[0] || null,
        toDate: this.datepicker.value[1] || null,
      }

      this.$root.$emit('action-plans:filter-changes', filterValues)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
