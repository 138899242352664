<template>
  <div
    id="analytics-report-page"
    class="card-container"
    data-test-analytics-report-page
  >
    <v-row>
      <v-col sm="12" md="4" lg="4">
        <CardProgressLine
          id="answered"
          data-test-card-answered
          :title="localeText('answered.title')"
          :label="localeText('answered.label')"
          :total="opinionsAdvancedSummary.answered.value"
          :series="opinionsAdvancedSummary.answered.series"
          :labels="opinionsAdvancedSummary.answered.labels"
          :variation="variation.answered"
          :loading="opinionsAdvancedSummary.loading"
        ></CardProgressLine>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardProgressLine
          id="received"
          data-test-card-received
          :title="localeText('received.title')"
          :label="localeText('received.label')"
          :total="opinionsAdvancedSummary.received.value"
          :series="opinionsAdvancedSummary.received.series"
          :labels="opinionsAdvancedSummary.received.labels"
          :variation="variation.received"
          :loading="opinionsAdvancedSummary.loading"
        ></CardProgressLine>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardProgressCircle
          id="concluded"
          :title="localeText('concludedOpinions.title')"
          :data="opinionsAdvancedSummary.concluded"
          legendPosition="bottom"
          @legendClick="legendClickConcluded"
          :showLegendEmptyStage="showLegendEmptyStage.concluded"
          :loading="opinionsAdvancedSummary.loading"
        ></CardProgressCircle>
      </v-col>
      <v-col sm="12" md="12" lg="12">
        <CardVerticalBars
          id="opinions"
          :title="localeText('opinionsReport.title')"
          :categories="opinionsAdvancedSummary.report.categories"
          :series="opinionsAdvancedSummary.report.series"
          :labels="opinionsAdvancedSummary.report.labels"
          :noDataLabel="localeText('opinionsReport.noData')"
          :loading="opinionsAdvancedSummary.loading"
        ></CardVerticalBars>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardProgressCircle
          id="ratings"
          :title="localeText('ratingsOpinions.title')"
          :data="opinionsAdvancedSummary.ratings"
          legendPosition="left"
          @legendClick="legendClickRatings"
          :showLegendEmptyStage="showLegendEmptyStage.ratings"
          :loading="opinionsAdvancedSummary.loading"
        ></CardProgressCircle>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardAverageTime
          id="answer"
          data-test-card-answer
          :title="localeText('averageTime.answerTitle')"
          :time="opinionsAdvancedSummary.averageAnswerTime"
          :variation="calcVariationToMinutes(variation.averageAnswerTime)"
          variationInverted
          :loading="opinionsAdvancedSummary.loading"
        ></CardAverageTime>
      </v-col>
      <v-col sm="12" md="4" lg="4">
        <CardAverageTime
          id="conclusion"
          data-test-card-conclusion
          :title="localeText('averageTime.conclusionTitle')"
          :time="opinionsAdvancedSummary.averageConclusionTime"
          :variation="calcVariationToMinutes(variation.averageClosingTime)"
          variationInverted
          :loading="opinionsAdvancedSummary.loading"
        ></CardAverageTime>
      </v-col>
      <v-col sm="12" md="12" lg="12">
        <CardCloudTags
          :tags="opinionsAdvancedSummary.tags"
          :loading="opinionsAdvancedSummary.loading"
        ></CardCloudTags>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardVerticalBars from '@/components/CardVerticalBars/CardVerticalBars.vue'
import CardProgressLine from '@/components/CardProgressLine/CardProgressLine.vue'
import CardAverageTime from '@/components/CardAverageTime/CardAverageTime.vue'
import CardProgressCircle from '@/components/CardProgressCircle/CardProgressCircle.vue'
import CardCloudTags from '../../Partials/CardCloudTags/CardCloudTags.vue'

import {
  getEngagementOpinionsAdvancedSummary,
  getEngagementOpinionsAdvancedSummaryHistory,
  getVariation,
} from '@/service/opinions'

import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'AnalyticsReportPage',
  components: {
    CardVerticalBars,
    CardProgressLine,
    CardProgressCircle,
    CardAverageTime,
    CardCloudTags,
  },
  data() {
    return {
      opinionsAdvancedSummary: {
        loading: false,
        originalConcluded: [
          {
            label: this.localeText('concludedOpinions.labels.open'),
            color: variables.greenColor5,
            value: 0,
          },
          {
            label: this.localeText('concludedOpinions.labels.done'),
            color: variables.blueColor4,
            value: 0,
          },
        ],
        concluded: [
          {
            label: this.localeText('concludedOpinions.labels.open'),
            color: variables.greenColor5,
            value: 0,
          },
          {
            label: this.localeText('concludedOpinions.labels.done'),
            color: variables.blueColor4,
            value: 0,
          },
        ],
        report: {
          categories: [],
          series: [],
          labels: [],
        },
        ratings: [
          {
            label: this.localeText('ratingsOpinions.labels.very-unhappy'),
            color: variables.redColor6,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.unhappy'),
            color: variables.redColor2,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.no-comments'),
            color: variables.yellowColor6,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.good'),
            color: variables.greenColor,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.love-it'),
            color: variables.greenColor2,
            value: 0,
          },
        ],
        originalRatings: [
          {
            label: this.localeText('ratingsOpinions.labels.very-unhappy'),
            color: variables.redColor6,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.unhappy'),
            color: variables.redColor2,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.no-comments'),
            color: variables.yellowColor6,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.good'),
            color: variables.greenColor,
            value: 0,
          },
          {
            label: this.localeText('ratingsOpinions.labels.love-it'),
            color: variables.greenColor2,
            value: 0,
          },
        ],
        received: {
          value: 0,
          series: [],
          labels: [],
        },
        answered: {
          value: 0,
          series: [],
          labels: [],
        },
        averageConclusionTime: 0,
        averageAnswerTime: 0,
        tags: [],
      },
      showLegendEmptyStage: {
        concluded: false,
        ratings: false,
      },
      variation: {},
    }
  },
  props: {
    groupID: {
      type: String,
      default: '',
    },
    variationPeriod: {
      type: String,
      default: 'real-time',
    },
  },
  watch: {
    groupID(id) {
      if (id) {
        this.fetchOpinionsAdvancedSummary(id)
      }
      if (id === '') {
        this.fetchOpinionsAdvancedSummary()
      }

      this.fetchVariation()
    },
    variationPeriod() {
      this.fetchVariation()
    },
  },
  methods: {
    calcVariationToMinutes(variationInMiliseconds) {
      const variationInMinutes = variationInMiliseconds / 1000 / 60
      return Math.trunc(variationInMinutes)
    },

    async fetchVariation() {
      const { data } = await getVariation({
        groupID: this.groupID,
        period: this.variationPeriod,
      })

      this.variation = data
    },
    async fetchOpinionsAdvancedSummary(groupID) {
      this.opinionsAdvancedSummary.loading = true
      const { data: advanced } = await getEngagementOpinionsAdvancedSummary(
        groupID
      )
      const { data: history } =
        await getEngagementOpinionsAdvancedSummaryHistory(groupID)
      this.opinionsAdvancedSummary.loading = false

      this.showLegendEmptyStage.concluded = false
      this.showLegendEmptyStage.ratings = false

      if (!advanced.opinions.closed) {
        this.fillEmptyProgressCircles()
        this.fillEmptyVerticalBars()
        this.fillEmptyAverageTimes()
        this.fillEmptyCountAnswers()
        this.fillEmptyCloudTags()
      } else {
        this.fillProgressCircles(advanced)
        this.fillVerticalBars(advanced)
        this.fillAverageTimes(advanced)
        this.fillCountAnswers(advanced)
        this.fillCloudTags(advanced)
      }
      if (!history.dates) {
        this.fillEmptyProgressLines()
      } else {
        this.fillProgressLines(history)
      }
    },
    fillProgressCircles(data) {
      data.ratings.map((rating) => {
        const ratingIndex = {
          veryUnhappy: 0,
          unhappy: 1,
          noComments: 2,
          good: 3,
          loveIt: 4,
        }[rating.id]
        this.opinionsAdvancedSummary.ratings[ratingIndex].value = rating.value
        this.opinionsAdvancedSummary.originalRatings[ratingIndex].value =
          rating.value
      })
      this.opinionsAdvancedSummary.concluded[0].value = data.opinions.closed
      this.opinionsAdvancedSummary.concluded[1].value = data.opinions.open
      this.opinionsAdvancedSummary.originalConcluded[0].value =
        data.opinions.closed
      this.opinionsAdvancedSummary.originalConcluded[1].value =
        data.opinions.open
    },
    fillEmptyProgressCircles() {
      this.opinionsAdvancedSummary.ratings =
        this.opinionsAdvancedSummary.ratings.map((rating) => {
          return { ...rating, value: 0 }
        })
      this.opinionsAdvancedSummary.concluded =
        this.opinionsAdvancedSummary.concluded.map((concluded) => {
          return { ...concluded, value: 0 }
        })
      this.opinionsAdvancedSummary.originalRatings =
        this.opinionsAdvancedSummary.ratings.map((rating) => {
          return { ...rating, value: 0 }
        })
      this.opinionsAdvancedSummary.originalConcluded =
        this.opinionsAdvancedSummary.concluded.map((concluded) => {
          return { ...concluded, value: 0 }
        })
    },
    fillCountAnswers(data) {
      this.opinionsAdvancedSummary.received.value = data.opinions.received
      this.opinionsAdvancedSummary.answered.value = data.opinions.answered
    },
    fillEmptyCountAnswers() {
      this.opinionsAdvancedSummary.received.value = 0
      this.opinionsAdvancedSummary.answered.value = 0
    },
    fillProgressLines(data) {
      this.opinionsAdvancedSummary.received.series = data.opinionsReceived
      this.opinionsAdvancedSummary.received.labels = data.dates
      this.opinionsAdvancedSummary.answered.series = data.opinionsAnswered
      this.opinionsAdvancedSummary.answered.labels = data.dates
    },
    fillEmptyProgressLines() {
      this.opinionsAdvancedSummary.received.series = []
      this.opinionsAdvancedSummary.received.labels = []
      this.opinionsAdvancedSummary.answered.series = []
      this.opinionsAdvancedSummary.answered.labels = []
    },
    fillAverageTimes(data) {
      this.opinionsAdvancedSummary.averageConclusionTime =
        data.opinions.averageClosingTime
      this.opinionsAdvancedSummary.averageAnswerTime =
        data.opinions.averageAnswerTime
    },
    fillEmptyAverageTimes() {
      this.opinionsAdvancedSummary.averageConclusionTime = 0
      this.opinionsAdvancedSummary.averageAnswerTime = 0
    },
    fillVerticalBars(data) {
      this.opinionsAdvancedSummary.report.categories = data.metrics.map(
        (metric) => metric.label
      )
      this.opinionsAdvancedSummary.report.series = [
        { name: this.localeText('opinionsReport.positive'), data: [] },
        { name: this.localeText('opinionsReport.constructive'), data: [] },
      ]
      this.labels = [
        this.localeText('opinionsReport.positive'),
        this.localeText('opinionsReport.constructive'),
      ]
      data.metrics.map((metric) => {
        this.opinionsAdvancedSummary.report.series[0].data.push(metric.positive)
        this.opinionsAdvancedSummary.report.series[1].data.push(
          metric.constructive
        )
      })
    },
    fillEmptyVerticalBars() {
      this.opinionsAdvancedSummary.report = {
        categories: [],
        series: [],
        labels: [
          this.localeText('opinionsReport.positive'),
          this.localeText('opinionsReport.constructive'),
        ],
      }
    },
    fillCloudTags(data) {
      this.opinionsAdvancedSummary.tags = data.tags
    },

    fillEmptyCloudTags() {
      this.opinionsAdvancedSummary.tags = []
    },
    legendClickConcluded(index) {
      const value = this.opinionsAdvancedSummary.originalConcluded[index].value
      if (value !== 0) {
        if (this.opinionsAdvancedSummary.concluded[index].value === 0) {
          this.opinionsAdvancedSummary.concluded[index].value = value
        } else {
          this.opinionsAdvancedSummary.concluded[index].value = 0
        }
        this.showLegendEmptyStage.concluded = true
      }
    },
    legendClickRatings(index) {
      const value = this.opinionsAdvancedSummary.originalRatings[index].value
      if (value !== 0) {
        if (this.opinionsAdvancedSummary.ratings[index].value === 0) {
          this.opinionsAdvancedSummary.ratings[index].value = value
        } else {
          this.opinionsAdvancedSummary.ratings[index].value = 0
        }
        this.showLegendEmptyStage.ratings = true
      }
    },
    localeText(name) {
      return this.$t(`engagementPage.opinions.cards.${name}`)
    },
  },
  mounted() {
    const groupID = this.groupID !== '' ? this.groupID : undefined
    this.fetchOpinionsAdvancedSummary(groupID)
    this.fetchVariation()
  },
}
</script>
