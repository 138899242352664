<template>
  <v-card class="card">
    <v-text-field
      v-model="search"
      :placeholder="$t('cultureManagement.quickSelectionBehaviors.search')"
      hide-details
      dense
      solo
      flat
      @keyup="searchBehaviors()"
    >
      <template v-slot:prepend>
        <v-icon class="icon-field" color="neutral5" size="16px">
          fi-rr-search
        </v-icon>
      </template>
    </v-text-field>

    <v-divider />

    <div class="content">
      <v-list
        v-if="filteredBehaviors.length > 0 || _behaviors.length > 0"
        class="list"
        width="100%"
        dense
      >
        <v-list-item
          v-for="(item, i) in filteredBehaviors.length > 0
            ? filteredBehaviors
            : _behaviors"
          :key="i"
          class="custom-item"
          @click="submit(item)"
        >
          <v-list-item-avatar size="22">
            <v-img :src="item.image" contain />
          </v-list-item-avatar>

          <v-list-item-title class="custom-item-label">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <div v-else class="empty-content">
        <span class="empty-text">
          {{ $t('cultureManagement.quickSelectionBehaviors.empty') }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      interval: null,
      search: '',
      selected: [],
      filteredBehaviors: [],
    }
  },

  props: {
    behaviors: {
      type: Array,
      default: () => [],
    },

    value: {
      type: Object,
      default: () => [],
    },

    valueIndex: {
      type: Number,
      default: 0,
    },
  },

  beforeMount() {
    this.defaultSelected()
  },

  watch: {
    value() {
      this.defaultSelected()
    },
  },

  computed: {
    _behaviors() {
      return this.behaviors.filter(
        (e) => !this.selected.map((j) => j.id).includes(e.id)
      )
    },
  },

  methods: {
    submit(value) {
      this.selected.push(value)

      const payload = {
        index: this.valueIndex,
        value: {
          ...this.value,
          behaviors: this.selected,
        },
      }

      this.$emit('update:value', payload)
      this.$emit('close')
    },

    searchBehaviors() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.filteredBehaviors = this._behaviors.filter((e) =>
          e.name.toLowerCase().includes(this.search.toLowerCase())
        )
      }, 250)
    },

    defaultSelected() {
      this.selected = [...this.value.behaviors]
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
