<template>
  <div class="TruncatedArea-wrapper">
    <slot name="outside" v-bind="{ setHover: setHover, hover }" />
    <v-tooltip
      data-test-truncated-tooltip
      v-if="isTruncated"
      :[tooltipPosition]="true"
      :z-index="zIndex"
      allowOverflow
      open-on-hover
      v-bind="tooltipAttrs"
      :value="tooltipOnHover ? hover : undefined"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="tooltipOnHover ? {} : on"
          class="truncated-icon"
          :class="{ 'tooltip-on-hover': tooltipOnHover }"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </div>
      </template>
      <div class="truncated-to-full-text-tooltip">
        <slot />
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'TruncatedArea',
  props: {
    refText: null,

    tooltipPosition: {
      type: String,
      default: 'top',
    },
    zIndex: {
      type: Number,
      default: 9999,
    },

    tooltipAttrs: {
      type: Object,
      default: () => ({}),
    },
    tooltipOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isTruncated: false,
      hover: false,
    }
  },

  computed: {
    width() {
      return this.$vuetify.breakpoint.width
    },
  },

  methods: {
    setHover(hover) {
      this.hover = hover
    },

    callOnNextTick(fn) {
      this.$nextTick(() => {
        fn()
      })
    },

    isTruncatingText() {
      const el = this.refText
      if (el) {
        const verticalPixelTolerance = 3
        const textTruncatedVertically =
          el.scrollHeight > el.offsetHeight + verticalPixelTolerance
        const textTruncatedHorizontally = el.scrollWidth > el.offsetWidth

        this.isTruncated = textTruncatedVertically || textTruncatedHorizontally
      }
    },
  },

  watch: {
    width() {
      this.isTruncatingText()
    },
  },

  mounted() {
    this.callOnNextTick(this.isTruncatingText)
  },

  updated() {
    this.callOnNextTick(this.isTruncatingText)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
