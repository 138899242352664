<template>
  <div id="data-table-action-plans" data-test-table-action-plans class="shadow">
    <v-card elevation="0" height="100%">
      <v-text-field
        :class="{ blur: _emptyItems }"
        v-model="search"
        prepend-inner-icon="fi fi-rr-search"
        :label="searchLabel"
        single-line
        hide-details
        @keyup="handleSearch"
      ></v-text-field>

      <v-data-table
        v-if="loading"
        :items="[]"
        hide-default-header
        hide-default-footer
        class="ml-4 mr-4"
        :mobileBreakpoint="0"
        :fixed-header="true"
        height="60vh"
      >
        <template v-slot:header>
          <thead class="custom-table-header" :class="{ blur: _emptyItems }">
            <tr>
              <th v-for="header in headers" :key="header.value">
                <span class="d-flex align-center">
                  <span class="mr-2">{{ header.text }}</span>
                  <div
                    class="d-flex flex-column"
                    @click="handleSort(header.value)"
                  >
                    <i
                      :class="getFilterActiveClass(header.value, 'asc')"
                      class="fi-sr-caret-up"
                    />
                    <i
                      :class="getFilterActiveClass(header.value, 'desc')"
                      class="fi-sr-caret-down"
                    />
                  </div>
                </span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body>
          <tr v-for="skeletonIndex in TOTAL_SKELETONS" :key="skeletonIndex">
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
            <td><v-skeleton-loader type="button" class="skeleton-item" /></td>
          </tr>
        </template>
      </v-data-table>

      <v-data-table
        v-else
        :items="items.length !== 0 ? items : fakeItems"
        hide-default-header
        hide-default-footer
        :page="currentPage"
        :items-per-page="itemsPerPage"
        :class="{ 'no-scroll': items.length == 0 }"
        :mobileBreakpoint="0"
        :fixed-header="true"
        height="60vh"
      >
        <template v-slot:header>
          <thead class="custom-table-header" :class="{ blur: _emptyItems }">
            <tr>
              <th v-for="header in headers" :key="header.value">
                <span class="d-flex align-center">
                  <span class="mr-2">{{ header.text }}</span>
                  <div
                    v-if="!['actions'].includes(header.value)"
                    class="d-flex flex-column"
                    @click="handleSort(header.value)"
                  >
                    <i
                      :class="getFilterActiveClass(header.value, 'asc')"
                      class="fi-sr-caret-up"
                    />
                    <i
                      :class="getFilterActiveClass(header.value, 'desc')"
                      class="fi-sr-caret-down"
                    />
                  </div>
                </span>
              </th>
            </tr>
            <v-divider class="custom-divider-absolute" />
          </thead>
        </template>
        <template v-slot:body="{ items: actionPlansList }">
          <div
            v-if="items.length < 1 && search.length > 0"
            class="table-empty d-flex text-center justify-center align-center"
          >
            <span data-test-data-table-empty>
              {{ $t('itemSelector.noResults') }}
            </span>
          </div>

          <tbody v-else>
            <tr
              v-for="actionPlan in actionPlansList"
              :key="actionPlan.id"
              width="100%"
              :class="{ blur: _emptyItems }"
            >
              <td class="action-plan-title py-2">
                <div>
                  {{ actionPlan.title }}
                </div>
              </td>
              <td class="action-plan-group">
                {{ actionPlan.group ? actionPlan.group.name : '--' }}
              </td>
              <td class="action-plan-type">
                {{
                  $t(
                    `actionPlansPage.dataTableActionPlans.typeFilter.${actionPlan.type}`
                  )
                }}
              </td>
              <td class="action-plan-metric">
                {{ actionPlan.metric ? actionPlan.metric.label : '--' }}
              </td>
              <td>
                <v-chip
                  v-if="actionPlan.progress"
                  :class="getChipClasses(actionPlan.progress.initial)"
                  :color="getColorCurrent(actionPlan.progress.initial)"
                >
                  {{
                    actionPlanProgressEmpty(
                      actionPlan.type,
                      actionPlan.progress.initial
                    )
                  }}
                </v-chip>
                <v-chip v-else :color="getColorCurrent()">--</v-chip>
              </td>
              <td>
                <v-chip
                  v-if="actionPlan.progress"
                  :class="getChipClasses(actionPlan.progress.current)"
                  :color="getColorCurrent(actionPlan.progress.current)"
                >
                  {{
                    actionPlanProgressEmpty(
                      actionPlan.type,
                      actionPlan.progress.current
                    )
                  }}
                </v-chip>
                <v-chip v-else :color="getColorCurrent()">--</v-chip>
              </td>
              <td>
                <v-chip
                  v-if="actionPlan.progress"
                  class="custom-row__chips"
                  :class="getChipClasses(actionPlan.progress.goal)"
                  :color="getColorCurrent(actionPlan.progress.goal)"
                >
                  {{
                    actionPlanProgressEmpty(
                      actionPlan.type,
                      actionPlan.progress.goal
                    )
                  }}
                </v-chip>

                <v-chip v-else :color="getColorCurrent()">--</v-chip>
              </td>
              <td
                class="action-plan-date"
                :class="{ 'deadline-date': deadlinePast(actionPlan.dueDate) }"
              >
                {{ formatDate(actionPlan.dueDate) }}
              </td>
              <td>
                <v-chip
                  :class="getChipClasses(actionPlan.status, 'status')"
                  :color="getColorStatus(actionPlan.status)"
                >
                  <div>
                    {{
                      $t(
                        `actionPlansPage.dataTableActionPlans.statusFilter.${actionPlan.status}`
                      )
                    }}
                  </div>
                </v-chip>
              </td>
              <td class="action-plan-options">
                <div class="d-flex align-center justify-space-around">
                  <v-btn
                    data-test-edit-btn
                    icon
                    @click="handleEdit(actionPlan)"
                  >
                    <img src="@/assets/images/ActionPlansIcons/editIcon.png" />
                  </v-btn>
                  <v-btn
                    data-test-delete-btn
                    icon
                    @click="handleDelete(actionPlan)"
                  >
                    <img
                      src="@/assets/images/ActionPlansIcons/deleteIcon.png"
                    />
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>

        <template v-slot:footer>
          <div v-if="_emptyItems" class="empty-stage">
            <span class="empty-stage__title mt-8">
              {{ $t('actionPlansPage.dataTableActionPlans.emptyStage.title') }}
            </span>

            <a
              :href="
                $t('actionPlansPage.dataTableActionPlans.emptyStage.tipLink')
              "
              class="empty-stage__subtitle mt-1 mb-8"
            >
              {{
                $t('actionPlansPage.dataTableActionPlans.emptyStage.subtitle')
              }}
            </a>
          </div>

          <div class="action-plan-footer" v-if="items.length > 0">
            <v-pagination v-model="currentPage" :length="pageLength" />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DataTableActionPlans',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLength: {
      type: Number,
      default: 20,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      search: '',
      searchLabel: this.$t('actionPlansPage.dataTableActionPlans.searchText'),
      headers: [
        {
          text: this.$t('actionPlansPage.dataTableActionPlans.headerGrid.name'),
          value: 'title',
        },
        {
          text: this.$t(
            'actionPlansPage.dataTableActionPlans.headerGrid.group'
          ),
          value: 'group',
        },
        {
          text: this.$t('actionPlansPage.dataTableActionPlans.headerGrid.type'),
          value: 'type',
        },
        {
          text: this.$t(
            'actionPlansPage.dataTableActionPlans.headerGrid.metric'
          ),
          value: 'metric',
        },
        {
          text: this.$t(
            'actionPlansPage.dataTableActionPlans.headerGrid.initial'
          ),
          value: 'progress-initial',
        },
        {
          text: this.$t(
            'actionPlansPage.dataTableActionPlans.headerGrid.current'
          ),
          value: 'progress-current',
        },
        {
          text: this.$t('actionPlansPage.dataTableActionPlans.headerGrid.goal'),
          value: 'progress-goal',
        },
        {
          text: this.$t(
            'actionPlansPage.dataTableActionPlans.headerGrid.deadline'
          ),
          value: 'due-date',
        },
        {
          text: this.$t(
            'actionPlansPage.dataTableActionPlans.headerGrid.status'
          ),
          value: 'status',
        },
        {
          text: this.$t(
            'actionPlansPage.dataTableActionPlans.headerGrid.actions'
          ),
          value: 'actions',
        },
      ],
      fakeItems: [
        {
          id: '624649ff25c6fcf94510000d',
          title: 'Melhorando o índice de reconhecimento',
          group: {
            id: '61a993c1faa15c00288d25e4',
            name: 'Gerência de Tecnologia',
          },
          tasks: {
            total: 2,
            completed: 2,
          },
          status: 'done',
          metric: {
            id: 'recognition',
            label: 'Reconhecimento',
          },
          progress: {
            initial: 1.5,
            current: 5.6,
            goal: 7.9,
          },
          dueDate: '2022-02-01',
          type: 'climate',
        },
        {
          id: '624649ff386269f182e52e9f',
          title: 'Festa Empresa',
          group: {
            id: '61a993c1faa15c00288d25e4',
            name: 'Gerência de Tecnologia',
          },
          tasks: {
            total: 1,
            completed: 2,
          },
          status: 'doing',
          metric: null,
          progress: null,
          dueDate: '2022-05-01',
          type: 'generic',
        },
      ],
      currentPage: 1,
      sortOrder: 'none',
      sortBy: 'none',
      interval: null,
    }
  },
  computed: {
    _emptyItems() {
      return this.items.length == 0 && this.search === '' && !this.loading
    },
  },
  watch: {
    currentPage(newPage) {
      this.$emit('handleUpdate', newPage)
    },
  },
  methods: {
    actionPlanProgressEmpty(planType, planProgress) {
      if (planType === 'generic' && planProgress === null) {
        return '--'
      }
      return planProgress
    },
    handleDelete(actionPlan) {
      this.$emit('handleDelete', actionPlan)
    },
    handleEdit(actionPlan) {
      this.$emit('handleEdit', actionPlan)
    },
    handleSort(value) {
      let changedNow = false
      this.sortBy = value

      if (this.sortOrder === 'none') {
        this.sortOrder = 'asc'
        changedNow = true
      }

      if (!changedNow) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      }

      this.$emit('handleSort', this.sortOrder, value)
    },
    handleSearch() {
      if (this.interval) {
        clearInterval(this.interval)
      }

      this.interval = setTimeout(() => {
        if (this.sortOrder === 'none') {
          this.sortOrder = 'asc'
        }
        this.$emit('handleSearch', this.sortOrder, this.search)
      }, 500)
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    deadlinePast(date) {
      if (moment().isAfter(moment(date))) return true

      return false
    },
    getColorCurrent(current) {
      switch (true) {
        case current == null:
          return 'neutral8'
        case current >= 0 && current < 5:
          return 'redColor2'
        case current >= 5 && current < 7:
          return 'secondary'
        case current >= 7:
          return 'greenColor'
        default:
          return 'neutral8'
      }
    },
    getChipClasses(current, type = 'current') {
      const classNames = []
      if (current == null && type === 'current') {
        classNames.push('null-chip')
        return classNames
      }
      if (type === 'status' && (current === 'done' || current === 'delayed')) {
        classNames.push('white-chip')
        return classNames
      }
      if ((current >= 0 && current < 5) || current >= 7) {
        classNames.push('white-chip')
        return classNames
      }
      return classNames
    },
    getColorStatus(status) {
      switch (status) {
        case 'doing':
          return 'secondary'
        case 'done':
          return 'greenColor'
        case 'delayed':
          return 'redColor2'
        default:
          return 'neutral8'
      }
    },
    getFilterActiveClass(headerValue, sortOrderValue) {
      return this.sortBy === headerValue && this.sortOrder === sortOrderValue
        ? 'filter-active'
        : ''
    },
  },
  mounted() {
    this.currentPage = this.page
    this.TOTAL_SKELETONS = 10
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
