<template>
  <div>
    <v-card elevation="0" outlined class="pb-6" height="350">
      <v-card-title class="justify-center card-title-slot">
        <h4
          class="card-title"
          v-text="$t('engagementPage.cardResponders.cardTitle')"
        ></h4>
      </v-card-title>

      <div v-if="loading" class="load-content">
        <v-skeleton-loader width="300" type="list-item"></v-skeleton-loader>
        <v-skeleton-loader width="200" type="list-item"></v-skeleton-loader>
        <v-skeleton-loader width="300" type="list-item"></v-skeleton-loader>
      </div>

      <section v-else>
        <v-card-text>
          <div
            :class="['d-flex align-center', 'flex-column', _chartPaddingTop]"
            data-test-responders
          >
            <EngagementAverage
              id="respondersChart"
              :score="_participationResponders"
              :absoluteScore="_numberOfResponders"
              :participationMaxValue="invited"
              type="participation"
            ></EngagementAverage>
            <div
              class="
                d-flex
                justify-center
                align-center
                card-variation-container
              "
            >
              <Variation short :variation="variation"></Variation>
              <span class="ml-2 variation-text" v-text="variationText"></span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="_canManagementParticipations"
            :href="_linkManagement"
            target="_blank"
            class="card-button"
            elevation="0"
            outlined
            data-test-manage-link
          >
            <span v-text="$t('engagementPage.cardResponders.button')"></span>
          </v-btn>
        </v-card-actions>
      </section>
    </v-card>
  </div>
</template>

<script>
import EngagementAverage from '@/components/EngagementAverage/EngagementAverage.vue'
import Variation from '@/components/Variation/Variation.vue'

import { calculateParticipation } from '@/helpers/participation'
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'CardResponders',
  components: {
    EngagementAverage,
    Variation,
  },
  props: {
    responders: Number,
    invited: Number,
    detailLink: String,
    variation: Number,
    variationText: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _canManagementParticipations() {
      return this.$can(
        'access',
        _permissions.engagement_participations_answers_management
      )
    },

    _participationResponders() {
      const { invited, responders } = this
      return calculateParticipation(invited, responders)
    },
    _linkManagement() {
      const linkManagement = new URL(
        'management/people-and-groups/people',
        process.env.VUE_APP_MANAGEMENT_LINK
      )
      return linkManagement
    },
    _numberOfResponders() {
      if (!this.responders) {
        return 0
      }

      return this.responders
    },
    _chartPaddingTop() {
      if (this._numberOfResponders === 0) {
        return 'pt-6'
      }

      return 'pt-8'
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
