<template>
  <div class="history-nps-chart">
    <v-row class="period-select-row" justify="end">
      <v-col cols="auto" align-self="end">
        <select
          class="period-select"
          v-model="currentZoomLevel"
          :disabled="_isEmpty"
        >
          <option
            v-for="(item, index) in selectItems"
            :value="item.value"
            :key="index"
            v-text="item.name"
          ></option>
        </select>
      </v-col>
    </v-row>

    <apexchart
      class="apex-history-nps-chart"
      ref="historyNpsChart"
      :height="_options.chart.height || '315px'"
      :options="_options"
      :series="_npsSeries"
    ></apexchart>

    <HistoryNPSChartTooltip
      class="tooltip"
      ref="tooltip"
      :tooltip-data="_tooltipData"
      :type="type"
    ></HistoryNPSChartTooltip>
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'
import HistoryNPSChartTooltip from '@/components/HistoryNPSChartTooltip/HistoryNPSChartTooltip'

import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/pt-br'

import { extendMoment } from 'moment-range'

const momentWithRange = extendMoment(moment)

const ZoomLevels = {
  Month: 1,
  Quarter: 2,
  Year: 3,
}

export default {
  name: 'HistoryNPSChart',
  components: {
    HistoryNPSChartTooltip,
  },

  props: {
    nps: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'enps',
    },
  },
  data() {
    const { month, quarter, year } = this.$t('historyNpsChart.select')
    const { Month, Quarter, Year } = ZoomLevels

    return {
      tooltipDataPointIndex: null,
      zoomLevels: ZoomLevels,
      currentZoomLevel: Month,
      selectItems: [
        {
          name: month,
          value: Month,
        },
        {
          name: quarter,
          value: Quarter,
        },
        {
          name: year,
          value: Year,
        },
      ],
    }
  },
  computed: {
    _isEmpty() {
      return this.nps.length === 0
    },
    _npsData() {
      if (this._isEmpty) {
        return [{ name: '', data: Array(12).fill(100) }]
      }

      const mapNpsDataToSeriesCounts = (series) => {
        const skippedData = series.map((item) => item?.skiped ?? null)
        const promotersData = series.map((item) => item?.promoters ?? null)
        const passivesData = series.map((item) => item?.passives ?? null)
        const detractorsData = series.map((item) => item?.detractors ?? null)

        return {
          skippedData,
          promotersData,
          passivesData,
          detractorsData,
        }
      }

      let series = []

      if (this.currentZoomLevel === ZoomLevels.Month) {
        series = this.getMonthlyNPSData(this.nps)
      }

      if (this.currentZoomLevel === ZoomLevels.Quarter) {
        series = this.getQuartersNPSData(this.nps, true)
      }

      if (this.currentZoomLevel === ZoomLevels.Year) {
        series = this.getYearsNPSData(this.nps)
      }

      const { skippedData, detractorsData, passivesData, promotersData } =
        mapNpsDataToSeriesCounts(series)

      const { detractors, passive, promoters, skipped } =
        this.$t('historyNpsChart')

      return [
        { name: skipped, data: skippedData },
        { name: promoters, data: promotersData },
        { name: passive, data: passivesData },
        { name: detractors, data: detractorsData },
      ]
    },
    _npsSeries() {
      const [skippers, ...promotersPassiveDetractors] = this._npsData

      if (this._isEmpty) {
        return [skippers]
      }

      return promotersPassiveDetractors
    },
    _tooltipData() {
      const dataPointIndex = this.tooltipDataPointIndex
      const [skiped, promoters, passives, detractors] = this._npsData

      const skippedCount = skiped?.data?.[dataPointIndex] || 0
      const promotersCount = promoters?.data?.[dataPointIndex] || 0
      const passivesCount = passives?.data?.[dataPointIndex] || 0
      const detractorsCount = detractors?.data?.[dataPointIndex] || 0

      return { skippedCount, promotersCount, passivesCount, detractorsCount }
    },
    _categories() {
      const { firstNpsDate, lastNpsDate } = this.getNpsDates(this.nps)

      if (this.currentZoomLevel === ZoomLevels.Quarter) {
        return this.getCategoriesQuarters(firstNpsDate, lastNpsDate)
      }

      if (this.currentZoomLevel === ZoomLevels.Year) {
        return this.getCategoriesYears(firstNpsDate, lastNpsDate)
      }

      return this.getCategoriesMonthly(firstNpsDate)
    },
    _options() {
      return this.buildApexStackedBarChartsOptions(this._categories)
    },
  },

  methods: {
    buildApexStackedBarChartsOptions: function (categories) {
      const categoriesLength = this._categories.length
      const min =
        this.currentZoomLevel === ZoomLevels.Month
          ? categoriesLength - 11
          : categoriesLength - 3
      const xAxisBasicConfig = {
        min,
        max: categoriesLength,
      }

      return {
        colors: this._isEmpty
          ? [variables.neutral8]
          : [variables.greenColor, variables.yellowColor1, variables.redColor2],
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          height: 315,
          toolbar: {
            show: true,
            tools: {
              zoom: false,
              zoomin: false,
              zoomout: false,
            },
          },
          zoom: {
            enabled: true,
          },
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler()
            },
            beforeResetZoom: () => ({ xaxis: xAxisBasicConfig }),
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
          },
        },
        tooltip: {
          enabled: !this._isEmpty,
          shared: true,
          intersect: false,
          custom: ({ dataPointIndex }) => {
            this.tooltipDataPointIndex = dataPointIndex
            return this.$refs.tooltip.$el.outerHTML
          },
        },
        xaxis: {
          type: 'category',
          ...xAxisBasicConfig,
          categories,
          tickPlacement: 'on',
          tickAmount: 'dataPoints',
          labels: {
            hideOverlappingLabels: false,
            formatter: this.formatCategoryLabel,
          },
        },
        yaxis: {
          labels: {
            formatter: (label) => label,
          },
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
      }
    },

    formatCategoryLabel(date) {
      if (this.currentZoomLevel !== ZoomLevels.Month) {
        return date
      }

      momentWithRange.locale(this.$t('historyNpsChart.dateLocale'))

      return date ? momentWithRange(date).format('MMM/YYYY') : ''
    },

    getCategoriesYears(startDate, endDate) {
      const startYear = momentWithRange(endDate).endOf('year')
      const endYear = momentWithRange(startDate).endOf('year')

      const yearsRange = Array.from(
        momentWithRange().range(startYear, endYear).by('year')
      )

      return yearsRange.map((year) => year.year())
    },
    getCategoriesQuarters(startDate, endDate) {
      const startOfLastQuarter = momentWithRange(endDate).endOf('quarter')
      const endOfFirstQuarter = momentWithRange(startDate).endOf('quarter')

      const quartersRange = Array.from(
        momentWithRange()
          .range(startOfLastQuarter, endOfFirstQuarter)
          .by('quarters')
      )

      return quartersRange.map(
        (quarter) => `${quarter.year()}/Q${quarter.quarter()}`
      )
    },
    getCategoriesMonthly(startDate) {
      const startMonth = momentWithRange(startDate).startOf('month')
      const endMonth = momentWithRange(startMonth).subtract(11, 'month')

      const monthRange = Array.from(
        momentWithRange().range(endMonth, startMonth).by('month')
      )

      return monthRange.map((date) => date.format('YYYY-MM'))
    },

    getNpsDates(npsData) {
      const firstNps = npsData?.[0]
      const lastNps = npsData?.[npsData.length - 1]

      const firstNpsDate = firstNps?.date
      const lastNpsDate = lastNps?.date

      return { firstNpsDate, lastNpsDate }
    },

    getQuartersNPSData(npsData) {
      const { firstNpsDate, lastNpsDate } = this.getNpsDates(npsData)
      const categories = this.getCategoriesQuarters(
        firstNpsDate || new Date(),
        lastNpsDate
      )

      const getQuarterIdentifier = (momentObj) => {
        return `${momentObj.year()}/Q${momentObj.quarter()}`
      }

      const quartersGroupedNpsData = npsData.reduce(
        (npsDataByQuarter, item) => {
          const quarter = getQuarterIdentifier(momentWithRange(item.date))
          if (
            !npsDataByQuarter[quarter] ||
            momentWithRange(item.date).isAfter(npsDataByQuarter[quarter].date)
          ) {
            npsDataByQuarter[quarter] = item
          }

          return npsDataByQuarter
        },
        {}
      )

      return categories.map((quarter) => quartersGroupedNpsData?.[quarter])
    },
    getYearsNPSData(npsData) {
      const { firstNpsDate, lastNpsDate } = this.getNpsDates(npsData)
      const categories = this.getCategoriesYears(
        firstNpsDate || new Date(),
        lastNpsDate
      )

      const yearsGroupedNpsData = npsData.reduce((npsDataByYear, item) => {
        const year = momentWithRange(item.date).format('YYYY')
        if (
          !npsDataByYear[year] ||
          momentWithRange(item.date).isAfter(npsDataByYear[year].date)
        ) {
          npsDataByYear[year] = item
        }

        return npsDataByYear
      }, {})

      return categories.map((year) => yearsGroupedNpsData?.[year])
    },
    getMonthlyNPSData(npsData) {
      const categories = this.getCategoriesMonthly(
        npsData[0]?.date || new Date()
      )

      const monthlyGroupedNpsData = npsData.reduce((npsDataByMonth, item) => {
        const yearMonth = momentWithRange(item.date).format('YYYY-MM')
        if (
          !npsDataByMonth[yearMonth] ||
          momentWithRange(item.date).isAfter(npsDataByMonth[yearMonth].date)
        ) {
          npsDataByMonth[yearMonth] = item
        }

        return npsDataByMonth
      }, {})

      return categories.map((yearMonth) => monthlyGroupedNpsData?.[yearMonth])
    },
  },

  beforeUpdate() {
    momentWithRange.locale(this.$t('historyNpsChart.dateLocale'))
  },
}
</script>

<style src="./style.scss" lang="scss" />
