<template>
  <v-menu
    content-class="content-menu elevation-0"
    ref="behaviorMenu"
    :close-on-content-click="false"
    offset-x
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card class="card-behaviors" data-test-card-behavior>
        <div class="card-behaviors-container">
          <div class="behavior-container">
            <div class="behavior-image">
              <v-img
                :src="behavior.image"
                :alt="behavior.description"
                max-height="36px"
                max-width="36px"
                width="36px"
              />
            </div>

            <div class="behavior-details">
              <div class="behavior-title-section">
                <div class="behavior-title">
                  <span class="behavior-name" v-text="behavior.name"></span>

                  <v-tooltip top max-width="18.75rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tooltip-icon">
                        <v-icon
                          class="fi-rr-interrogation"
                          size="0.75rem"
                        ></v-icon>
                      </span>
                    </template>

                    <div>
                      <span v-text="behavior.description"></span>
                    </div>
                  </v-tooltip>
                </div>

                <div class="behavior-position">
                  <span
                    v-if="orderBy === 'votes'"
                    v-text="
                      behavior.position !== null
                        ? behavior.position + 'º'
                        : '--'
                    "
                  ></span>
                  <span
                    v-else
                    v-text="
                      behavior.positionProbability !== null
                        ? behavior.positionProbability + 'º'
                        : '--'
                    "
                  ></span>
                </div>
              </div>

              <div class="behavior-subtitle-section">
                <div class="behavior-more-details">
                  <span
                    @click="openDetailBehaviorDialog"
                    v-text="
                      $t('engagementPage.culture.cultureCode.moreDetails')
                    "
                  ></span>
                </div>

                <div class="behavior-info">
                  <span
                    class="behavior-votes"
                    :class="{
                      active: orderBy === 'votes',
                    }"
                    v-text="
                      $t('engagementPage.culture.cultureCode.countVotes', {
                        count: behavior.votes || 0,
                      })
                    "
                  ></span>
                  <span
                    class="behavior-percent"
                    :class="{
                      active: orderBy === 'probability',
                    }"
                    v-text="
                      behavior.probability ? behavior.probability + '%' : '0%'
                    "
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <div class="card-action">
            <v-btn
              v-bind="attrs"
              v-on="on"
              data-test-bind-value
              text
              small
              plain
              class="card-link-company-value"
            >
              <span
                v-text="
                  '+ ' +
                  $t('engagementPage.culture.cultureCode.bindToCompanyValue')
                "
              ></span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </template>

    <QuickSelectionValues
      v-on="$listeners"
      :values="values"
      :behavior="behavior"
      @close="$refs.behaviorMenu.save()"
    />
  </v-menu>
</template>

<script>
import QuickSelectionValues from '@/pages/EngagementPage/Tabs/Culture/Partials/QuickSelectionValues/QuickSelectionValues.vue'

export default {
  components: {
    QuickSelectionValues,
  },

  props: {
    behavior: {
      type: Object,
      required: true,
    },

    values: {
      type: Array,
      default: () => [],
    },

    orderBy: {
      type: String,
      default: 'probability',
    },
  },

  methods: {
    openDetailBehaviorDialog() {
      this.$emit('openBehaviorDetailHistory', this.behavior)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
