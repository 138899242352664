<template>
  <div id="cloud-tags">
    <div v-if="loading" class="loading">
      <v-skeleton-loader
        v-bind="loadingSkeleton"
        class="chart"
        type="avatar@40"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="loadingSkeleton"
        class="legend"
        type="chip@40"
      ></v-skeleton-loader>
    </div>

    <div v-else>
      <apexchart
        id="cloudtags-chart"
        type="treemap"
        height="350"
        :class="{ blur: _isEmpty }"
        :options="_options"
        :series="series"
      ></apexchart>

      <div v-if="_isEmpty" class="empty-info">
        <p v-text="$t('cloudTagsChart.locked')"></p>
      </div>
    </div>
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'
import randomColors from '@/helpers/randomColors/randomColors.js'

export default {
  name: 'CloudTagsChart',
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loadingSkeleton: {
        boilerplate: true,
      },
      colors: randomColors,
      neutral1: variables.neutral1,
      series: [],
    }
  },
  computed: {
    _options() {
      const vm = this
      return {
        chart: {
          id: 'cloudtags-chart',
          height: 350,
          type: 'treemap',
          fontFamily: 'Poppins',
          toolbar: {
            show: vm._isEmpty ? false : true,
            offsetX: -12,
            offsetY: -10,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler()
            },
            legendClick: (...args) => vm.legendToggle(vm, ...args),
          },
        },
        plotOptions: {
          treemap: {
            distributed: false,
            enableShades: false,
          },
        },
        tooltip: {
          enabled: vm._isEmpty ? false : true,
        },
        responsive: [
          {
            breakpoint: 600,
            options: { chart: { toolbar: { show: false } } },
          },
        ],
        legend: {
          show: true,
          showForSingleSeries: true,
          fontSize: '12px',
          fontFamily: 'Poppins',
          labels: { colors: vm.neutral1, useSeriesColors: false },
          position: 'bottom',
          horizontalAlign: 'left',
          offsetY: 8,
          markers: {
            width: 10,
            height: 10,
            offsetY: 1,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 0,
          },
          onItemHover: { highlightDataSeries: false },
          onItemClick: { toggleDataSeries: vm._isEmpty ? false : true },
        },
        ...vm._additionalOptions,
        colors: vm.colors,
      }
    },
    _isEmpty() {
      return !this.tags || !this.tags.length
    },
    _additionalOptions() {
      if (this._isEmpty) {
        return {
          noData: { text: this.$t('cloudTagsChart.noData') },
          states: {
            hover: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
            active: {
              filter: {
                type: 'none',
                value: 0,
              },
            },
          },
        }
      }
      return {}
    },
  },
  methods: {
    legendToggle(vm, chartContext, seriesIndex, w) {
      let series = w.config.series
      let seriesSize = series[seriesIndex].data[0].y

      if (seriesSize != 0) {
        series[seriesIndex].data[0].y = 0
      } else {
        series[seriesIndex].data[0].y = vm.tags[seriesIndex].value
      }

      chartContext.updateSeries(series)
      series.forEach((s, sI) => {
        if (s.data[0].y == 0)
          vm.$el
            .querySelector(` .apexcharts-legend-series[rel='${sI + 1}']`)
            .classList.add('apexcharts-inactive-legend')
      })
    },
  },
  watch: {
    tags: {
      deep: true,
      handler(newValue) {
        if (!newValue || !newValue.length) {
          const fakeTagName = this.$t('cloudTagsChart.fakeTagName')
          for (let i = 0; i < 10; i++) {
            this.series.push({
              name: fakeTagName,
              data: [{ x: fakeTagName, y: Math.floor(Math.random() * 100) }],
            })
          }
        } else {
          this.series = newValue.map((tag) => ({
            name: tag.label,
            data: [{ x: tag.label, y: tag.value }],
          }))
        }
      },
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
