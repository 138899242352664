<template>
  <div id="correlated-pillars" class="content">
    <div class="header-container">
      <div class="header-title">
        <h3 id="title" class="title">
          {{ $t('correlatedPillars.title') }}
        </h3>
      </div>
    </div>

    <v-divider class="border-divider" />

    <v-skeleton-loader
      v-if="isLoading"
      class="loading"
      type="list-item-three-line"
    />

    <div
      class="content-correlated-pillars"
      v-else-if="!isLoading && showCorrelatedPillars"
    >
      <div v-if="isEmpty && !isBlocked" class="empty-info">
        {{ $t('correlatedPillars.emptyInfo') }}
      </div>
      <i
        v-if="isBlocked"
        @click.stop.prevent="goToAquisitionPage()"
        :class="
          showAxelTip
            ? `lock-icon-centered fi fi-sr-lock`
            : `lock-icon-centered-no-alex-tip fi fi-sr-lock`
        "
      ></i>
      <div
        :class="`mb-1 color-${level.id} ${isBlocked ? 'blur' : ''}`"
        v-for="level in levels"
        :key="level.id"
        :id="level.id"
      >
        <label class="text-level">{{ level.title }}</label>
        <br />
        <v-badge
          class="badge"
          v-for="(pillar, index) in level.pillars"
          :key="index"
          :color="level.color"
          inline
          :content="pillar.label"
        ></v-badge>
      </div>
    </div>

    <v-divider
      class="border-divider-bottom"
      v-if="showAxelTip && showCorrelatedPillars"
    />

    <div class="content-correlated-pillars" v-if="showAxelTip">
      <div id="axel-tip" class="axel-tip">
        <div class="tip-icon">
          <img src="@/assets/images/CorrelatedPillars/axel-tip.svg" />
        </div>
        <div class="text-axel-tip">
          <p id="tip">
            <strong>{{ $t('correlatedPillars.tip.title') }} </strong>
            {{ $t('correlatedPillars.tip.content') }}
          </p>
          <p id="tipExample">
            {{ $t('correlatedPillars.tip.example') }}
          </p>
          <p id="tipUpdateFrequency">
            {{ $t('correlatedPillars.tip.updateFrequency') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'CorrelatedPillars',
  inject: ['callUpdateYourPackage'],
  props: {
    showCorrelatedPillars: {
      type: Boolean,
      default: () => true,
    },
    showAxelTip: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    correlations: {
      type: Array,
      required: true,
      default: () => [],
    },
    isBlocked: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      ghostText: [
        'Lorem ipsum dolor',
        'Dolor sit amet',
        'Consectetur',
        'Pellentesque congue',
      ],
      correlationsData: [],
      emptyLevels: [
        {
          id: 'very-high-empty',
          color: variables.neutral8,
          title: this.$t('correlatedPillars.level.veryHigh'),
          pillars: [
            {
              label: 'N/A',
              value: 0,
            },
            {
              label: 'N/A',
              value: 0,
            },
            {
              label: 'N/A',
              value: 0,
            },
          ],
        },
        {
          id: 'high-empty',
          color: variables.neutral8,
          title: this.$t('correlatedPillars.level.high'),
          pillars: [
            {
              label: 'N/A',
              value: 0,
            },
            {
              label: 'N/A',
              value: 0.55,
            },
          ],
        },
        {
          id: 'medium-empty',
          color: variables.neutral8,
          title: this.$t('correlatedPillars.level.medium'),
          pillars: [
            {
              label: 'N/A',
              value: 0,
            },
          ],
        },
      ],
    }
  },
  created() {
    this.handleCorrelations()
  },
  watch: {
    correlations: {
      deep: true,
      handler(newValue) {
        if (this.isBlocked) {
          this.correlationsData = newValue.map((elem) => {
            return { ...elem, label: this.ghostTextGenerate() }
          })
        } else {
          this.correlationsData = newValue
        }
      },
    },
  },
  computed: {
    isLoading() {
      return this.correlationsData.length > 0 || !this.loading ? false : true
    },
    isEmpty() {
      return this.correlationsData.length === 0
    },
    levels() {
      let levels = [
        {
          id: 'very-high',
          color: variables.greenColor4,
          title: this.$t('correlatedPillars.level.veryHigh'),
          pillars: this.correlationsData.filter((p) => p.value > 0.75),
        },
        {
          id: 'high',
          color: variables.greenColor3,
          title: this.$t('correlatedPillars.level.high'),
          pillars: this.correlationsData.filter(
            (p) => p.value > 0.5 && p.value <= 0.75
          ),
        },
        {
          id: 'medium',
          color: variables.secondary,
          title: this.$t('correlatedPillars.level.medium'),
          pillars: this.correlationsData.filter(
            (p) => p.value > 0.25 && p.value <= 0.5
          ),
        },
        {
          id: 'low',
          color: variables.redColor2,
          title: this.$t('correlatedPillars.level.low'),
          pillars: this.correlationsData.filter(
            (p) => p.value > 0 && p.value <= 0.25
          ),
        },
        {
          id: 'very-low',
          color: variables.redColor,
          title: this.$t('correlatedPillars.level.veryLow'),
          pillars: this.correlationsData.filter((p) => p.value <= 0),
        },
      ]
      levels = levels.filter((level) => level.pillars.length > 0)
      if (this.isEmpty) {
        levels = this.emptyLevels
      }
      return levels
    },
  },
  watch: {
    correlations: function () {
      this.handleCorrelations()
    },
  },
  methods: {
    handleCorrelations() {
      if (this.isBlocked) {
        this.correlationsData = this.correlations.map((elem) => {
          return { ...elem, label: this.ghostTextGenerate() }
        })
      } else {
        this.correlationsData = this.correlations
      }
    },
    goToAquisitionPage() {
      this.callUpdateYourPackage('pillar')
    },
    ghostTextGenerate() {
      return this.ghostText[Math.floor(Math.random() * this.ghostText.length)]
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
