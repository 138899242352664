<template>
  <div :id="`card-progress-line-${id}`" class="shadow">
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card v-else elevation="0" height="100%">
      <div class="px-4 py-3">
        <span class="title">{{ title }}</span>
      </div>
      <v-divider class="custom-divider" />
      <div class="d-flex flex-column px-4">
        <span class="value-title pt-1">{{ number }}</span>
        <Variation
          :variation="variation"
          :short="variationShort"
          data-test-progress-line-variation
        />
      </div>
      <ProgressLine
        :series="[
          {
            name: label,
            data: series,
          },
        ]"
        :labels="labels"
      />
    </v-card>
  </div>
</template>

<script>
import ProgressLine from '@/components/ProgressLine/ProgressLine.vue'
import Variation from '@/components/Variation/Variation.vue'

export default {
  name: 'CardProgressLine',
  components: {
    ProgressLine,
    Variation,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    variation: {
      type: Number,
      default: 0,
    },
    variationShort: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    number() {
      if (!this.total) {
        return '--'
      }
      return this.total
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
