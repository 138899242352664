<template>
  <div class="card-container" data-test-comparison-page>
    <HeatMap
      ref="heatmap"
      :indicators="indicators"
      :columns="groups"
      :lockMarket="!_canEngagementBenchmarkResults"
      :showVariation="_canEngagementGroupsComparationVariation"
      :lockVariation="_lockVariation"
      @reveal-children="requestChildren($event)"
      @mode-update="updateMode($event)"
      @callUpdateYourPackage="callUpdateYourPackage('variation')"
    >
      <span slot="title" v-text="$t('comparisonPage.title')"></span>
      <span slot="subtitle" v-text="$t('comparisonPage.subtitle')"></span>
    </HeatMap>
  </div>
</template>

<script>
import HeatMap from '@/components/HeatMap/HeatMap.vue'

import { _permissions } from '@/helpers/ability/engagement'

import {
  getAccountResults,
  getAccountVariation,
  getGroupsResults,
  getGroupsVariation,
} from '@/service/groupComparison'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ComparisonPage',
  inject: ['callUpdateYourPackage'],

  components: {
    HeatMap,
  },

  props: {
    variationPeriod: {
      type: String,
      default: 'real-time',
    },
  },

  data() {
    return {
      mode: 'pontuation',
      type: '',
      account: {},
      market: {},
      groups: [],
      indicators: [],
    }
  },

  computed: {
    ...mapState(['groupIds']),
    ...mapGetters(['getIsBasicPlanAdminUser']),

    _canEngagementBenchmarkResults() {
      return this.$can('access', _permissions.engagement_benchmark_results)
    },

    _lockVariation() {
      if (this._canEngagementGroupsComparationVariation) {
        return false
      }

      return (
        this.getIsBasicPlanAdminUser &&
        !this._canEngagementGroupsComparationVariation
      )
    },
    _canEngagementGroupsComparationVariation() {
      return this.$can(
        'access',
        _permissions.engagement_groups_comparation_variation
      )
    },
  },

  created() {
    this.$store.commit('setCurrentTab', 'ComparisonPage')
  },

  beforeMount() {
    this.getData()
  },

  watch: {
    groupIds() {
      this.getData()
    },

    variationPeriod() {
      this.getData()
    },
  },

  methods: {
    async getData() {
      const groups = (this.groupIds || []).length
        ? await this.requestGroups(this.groupIds)
        : []
      await this.getDefault()

      this.groups = [this.market, this.account, ...groups]
    },

    async getDefault() {
      try {
        if (this.mode === 'pontuation') {
          // return groups with pontuation mode
          await getAccountResults().then((res) => {
            this.handleDefault(res.data)
          })
        } else {
          // return groups with variation mode
          const payload = {
            period: this.variationPeriod,
            type: this.type,
          }

          await getAccountVariation(payload).then((res) => {
            this.handleDefault(res.data)
          })
        }
      } catch (err) {
        // err
      }
    },

    async requestGroups(data) {
      try {
        if (this.mode === 'pontuation') {
          // return groups with pontuation mode
          const payload = {
            groups: data,
          }

          return await getGroupsResults(payload).then((res) =>
            res.data.map((e) => this.handleGroup(e))
          )
        } else {
          // return groups with variation mode
          const payload = {
            groups: data,
            period: this.variationPeriod,
            type: this.type,
          }

          return await getGroupsVariation(payload).then((res) =>
            res.data.map((e) => this.handleGroup(e))
          )
        }
      } catch (err) {
        // err
      }
    },

    // change group to map default
    handleGroup(data) {
      return {
        column: {
          id: data.group.id,
          inactive: data.group.inactive,
          name: data.group.name,
          children: this.groupIds.length
            ? data.group.subgroups.filter((el) => this.groupIds.includes(el))
            : data.group.subgroups,
          parents: data.group.parents,
        },
        indicators: data.indicators,
        open: false,
      }
    },

    // handle default groups and indicators
    handleDefault(data) {
      if (this.indicators.length < 1) this.indicators = data.indicators

      this.market = {
        column: {
          id: 'market',
          name: this.$t('comparisonPage.market'),
        },
        indicators: data.market,
      }

      this.account = {
        column: {
          id: 'account',
          name: this.$t('comparisonPage.account'),
        },
        indicators: data.account,
      }
    },

    // change mode of view
    async updateMode(data) {
      this.mode = data.mode
      this.type = data.type

      this.getData()
    },

    // request children by id
    async requestChildren(data) {
      const groups = await this.requestGroups(data.children)

      this.$refs.heatmap.addColumns(groups, data.parentId)
    },
  },
}
</script>
