<template>
  <div class="content d-flex flex-column align-center">
    <!--Accessibility-->
    <div class="accessibility-hide-text" tabindex="0">
      <span v-text="$t('survey.feedback.accessibility')" />
    </div>

    <!--Title-->
    <span class="content-title" v-text="title" tabindex="0" />

    <!--Component-->
    <div class="content-body d-flex justify-center">
      <AnswerOpen
        :profile-id="currentUser.id"
        :profile-name="currentUser.name"
        :profile-image="currentUser.photo"
        :placeholder="$t('answerOpen.placeholder')"
        @change="handleChange"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AnswerOpen from '@/components/AnswerOpen/AnswerOpen'

export default {
  name: 'SurveyFeedback',

  components: {
    AnswerOpen,
  },

  computed: {
    ...mapState({
      survey: (state) => state.survey,
      currentUser: (state) => state.currentUser,
    }),

    question: function () {
      return this.survey.questions[this.survey.step.index]
    },

    title: function () {
      if (!this.question) return

      const { answers, answered } = this.question
      return answers.find((e) => e.answerID == answered.answerID)['feedback']
    },
  },

  mounted() {
    const element = document.querySelector('.accessibility-hide-text')
    if (element) element.focus()
  },

  methods: {
    ...mapActions(['setSurveyFeedbackAnswer']),

    handleChange(change) {
      this.setSurveyFeedbackAnswer({ opinion: change })
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
