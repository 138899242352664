import { render, staticRenderFns } from "./LanguageMenu.vue?vue&type=template&id=5c637a8c&scoped=true&"
import script from "./LanguageMenu.vue?vue&type=script&lang=js&"
export * from "./LanguageMenu.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=5c637a8c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c637a8c",
  null
  
)

export default component.exports