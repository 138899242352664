var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"items-container",attrs:{"id":"listOfItems","data-test-list-of-items":"","elevation":"0"}},[_vm._l((_vm.items),function(item,index){return [_c('div',{key:index,staticClass:"item-container",class:[
        { 'item-hover': _vm.hasHover },
        'item-' + item.entityType,
        'item-' + item.entityClass,
        'item-' + item.status,
        { checked: index === _vm.checked },
      ],attrs:{"data-test-list-of-items-item":"","checked":index === _vm.checked},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('EntityDetail',{attrs:{"item":item,"hasTooltip":_vm.hasTooltip,"secondLineFields":_vm.secondLineFields,"canTitleClick":_vm.hasClicked},on:{"titleClicked":function($event){_vm.hasClicked ? _vm.$emit('titleClicked', item) : null}}}),_c('div',{staticClass:"item-right-actions"},[(_vm.hasBar)?_c('div',{staticClass:"item-action-progress"},[(item.entityType === 'initiative')?_c('BorderedItem',{attrs:{"data":_vm.haveStatus(item),"tooltipOnHover":true,"tooltipAttrs":{
              top: false,
              bottom: true,
              'z-index': 203,
            }}}):_c('ProgressBar',{attrs:{"type":"percentage","data":_vm.progress(item),"lockProgress":true,"hideFooter":true}})],1):_vm._e(),(_vm.hasRemove)?_c('div',{staticClass:"item-action-remove",attrs:{"data-test-list-of-items-item-remove":""},on:{"click":function($event){return _vm.handleRemove(item)}}},[_c('v-icon',{attrs:{"size":"0.875rem"}},[_vm._v("fi-rr-cross-circle")])],1):_vm._e(),(_vm.hasChecked && index === _vm.checked)?_c('div',{staticClass:"item-checked-icon"},[_c('v-icon',{attrs:{"color":"#DD0041"}},[_vm._v("fi-br-check")])],1):_vm._e()])],1)]}),(!_vm.hasItem)?_c('div',{staticClass:"without-items"},[_c('div',{staticClass:"without-items-text"},[_c('span',[_vm._v(_vm._s(_vm.$t(`ListItems.noData${_vm.type}`)))])])]):_vm._e(),(_vm.hasAdd)?_c('div',{staticClass:"add-item",class:{ alignment: !_vm.hasItem },on:{"click":_vm.handleAddItem}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fi-sr-plus-small")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t(`ListItems.add${_vm.type}`)))])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }