<template>
  <div class="overflow-hidden navbar-wrapper">
    <v-bottom-navigation
      v-model="value"
      class="page-nav"
      active-class="page-nav__active"
      background-color="neutral"
      height="40px"
      @change="handleNavigation"
    >
      <v-btn
        :ripple="false"
        class="page-nav__btn"
        value="ActionPlansPage"
        :class="{ active: value === 'ActionPlansPage' }"
      >
        <span>{{
          $t('engagementPage.actionPlan.navigation.actionPlans')
        }}</span>
      </v-btn>
      <v-btn
        v-if="canShowReportAnalytics"
        :ripple="false"
        class="page-nav__btn"
        data-test-action-plans-analytics-report-tab
        value="ActionPlanAnalyticsReportPage"
        :class="{ active: value === 'ActionPlanAnalyticsReportPage' }"
      >
        <span>{{
          $t('engagementPage.actionPlan.navigation.analyticsReport')
        }}</span>
      </v-btn>
      <v-spacer></v-spacer>
      <div class="d-flex mt-1">
        <div class="mt-2 mr-3">
          <HowToBeginAndWatchAVideo
            firstLabelAlternative
            :videoType="
              value === 'ActionPlansPage'
                ? 'actionPlan.general'
                : 'actionPlan.analytics'
            "
          />
        </div>
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
import scrollTabNavigation from '@/mixins/scrollTabNavigation'
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'PageNavigation',
  components: {
    HowToBeginAndWatchAVideo,
  },
  mixins: [scrollTabNavigation],
  data() {
    return {
      value: '',
    }
  },
  computed: {
    canShowReportAnalytics() {
      return this.$can('access', _permissions.engagement_action_plans_reports)
    },
  },
  methods: {
    handleNavigation(tab) {
      if (!tab) {
        return
      }
      if (!this.$router.currentRoute.path.includes(tab)) {
        this.$router.push({ name: tab })
      }
    },
  },
  created() {
    if (this.$route.name === 'ActionPlanPage') {
      this.value = 'ActionPlansPage'
      this.handleNavigation('ActionPlansPage')
    } else {
      this.value = this.$route.name
    }
  },
  mounted() {
    this.scrollToTab('ActionPlanPage', 'context-menu')
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
