<template>
  <v-card class="video-component__content">
    <video
      data-test-video-accessibilit
      ref="video"
      :class="`${initialized ? 'video--initialized' : 'video--stopped'}`"
      width="100%"
      :controls="initialized && !ended"
      controlslist="nodownload"
      playsinline
      :muted="autoplay"
      :autoplay="autoplay"
      @play="handlePlay()"
      @pause="handlePause()"
      @ended="handleEnd()"
      @timeupdate="handleCurrentTime"
    >
      <source :src="video" type="video/mp4" />
    </video>

    <!--Control Buttons-->
    <v-overlay
      class="video-overlay"
      absolute
      v-if="!initialized || ended"
      :value="!initialized || ended"
      @keydown.enter.space="$refs.video.play()"
    >
      <div
        v-ripple
        class="video-component__overlay overlay-button"
        @click="$refs.video.play()"
      >
        <v-icon v-if="ended" class="overlay-button-icon fi-rr-rotate-right" />
        <v-icon v-else class="overlay-button-icon fi-sr-play" />
      </div>
    </v-overlay>

    <!--Text Overlay-->
    <div
      v-if="text && !initialized"
      class="overlay-text video-component__overlay__text"
    >
      <span v-text="text" />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'VideoComponent',
  props: {
    video: {
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    text: {
      required: false,
    },
  },
  data: () => ({
    initialized: false,
    playing: false,
    paused: false,
    ended: false,
  }),
  methods: {
    handlePlay() {
      this.playing = true
      this.paused = false
      this.ended = false
    },
    handlePause() {
      this.playing = false
      this.paused = true
    },
    handleEnd() {
      this.playing = false
      this.paused = true
      this.ended = true
    },
    handleCurrentTime(e) {
      this.initialized = e.target.currentTime > 0
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
