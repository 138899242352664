<template>
  <div class="overflow-hidden navbar-wrapper">
    <v-bottom-navigation
      v-model="value"
      class="page-nav"
      active-class="page-nav__active"
      background-color="neutral"
      height="40px"
      @change="handleNavigation"
    >
      <v-btn
        :ripple="false"
        class="page-nav__btn"
        value="IndicatorsPage"
        :class="{ active: value === 'IndicatorsPage' }"
        data-test-benchmark-indicators
      >
        <span>{{ $t('engagementPage.benchmark.navigation.indicators') }}</span>
      </v-btn>
      <v-btn
        :ripple="false"
        class="page-nav__btn"
        value="RankingPage"
        :class="{
          active: value === 'RankingPage',
          'active-report': value === 'InternalRankingPage',
        }"
        data-test-benchmark-ranking
      >
        <span>{{ $t('engagementPage.benchmark.navigation.ranking') }}</span>
      </v-btn>

      <v-spacer></v-spacer>
      <a
        href="#"
        @click="$emit('rankingScaleClick', true)"
        v-if="$route.name === 'IndicatorsPage'"
        class="ranking-scale"
      >
        {{ $t('engagementPage.benchmark.navigation.rankingScale') }}
      </a>

      <v-btn
        :ripple="false"
        class="page-nav__btn internal-ranking"
        value="InternalRankingPage"
        v-if="$route.name !== 'IndicatorsPage'"
        :class="{ active: value === 'InternalRankingPage' }"
        data-test-button-internal-rank
        @click="handleBackNavigation('RankingPage')"
      >
        <span
          ><i class="fi fi-br-stats"></i>
          {{ $t('engagementPage.benchmark.navigation.internalRanking') }}</span
        >
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import scrollTabNavigation from '@/mixins/scrollTabNavigation'

export default {
  name: 'PageNavigation',
  mixins: [scrollTabNavigation],
  data() {
    return {
      value: '',
    }
  },
  methods: {
    handleNavigation(tab) {
      if (!tab) {
        return
      }

      if (!this.$router.currentRoute.path.includes(tab)) {
        this.$router.push({ name: tab })
      }
    },

    handleBackNavigation(routeName) {
      if (this.value === 'InternalRankingPage') {
        this.value = routeName
        this.$router.push({ name: routeName })
      }
    },
  },
  created() {
    if (this.$route.name === 'BenchmarkPage') {
      this.value = 'IndicatorsPage'
      this.handleNavigation('IndicatorsPage')
    } else {
      this.value = this.$route.name
    }
  },
  mounted() {
    this.scrollToTab('BenchmarkPage', 'context-menu')
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
