<template>
  <div data-test-groupScore class="groupScore-align">
    <section v-if="score">
      <div class="bg-gradient" :class="bgColor">
        <div id="chart" class="content">
          <span id="label0">0</span>
          <apexchart
            :id="id"
            type="radialBar"
            :options="options"
            :series="series"
            :color="getColor()"
            :label="getLabel()"
          ></apexchart>
          <span
            id="label10"
            :class="{ 'label-100': this.type === 'participation' }"
          >
            {{ lastScore }}
          </span>
        </div>
      </div>
    </section>

    <section v-else data-test-groupNoData class="card-no-data">
      <span class="card-no-data-index__low">0</span>
      <span class="card-no-data-index__high">{{ lastScore }}</span>
      <div class="card-no-data__chart"></div>
      <div class="card-no-data__label">
        <p>N/A</p>
        <p class="card-no-data__result">
          {{ noData }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'EngagementAverage',
  data() {
    return {
      bgColor: '',
    }
  },
  props: {
    score: Number,
    type: String,
    enablePercentage: Boolean,
    id: { type: String, default: 'apexchart' },
    absoluteScore: { type: Number, default: null },
    participationMaxValue: {
      type: Number,
      default: 100,
    },
    maxValue: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    getColor() {
      if (this.type === 'participation') {
        return this.getColorForParticipationType()
      }

      if (this.score >= 0 && this.score < 5) {
        this.bgColor = 'low'
        return variables.redColor
      } else if (this.score < 7.5) {
        this.bgColor = 'md'
        return variables.yellowColor
      } else {
        this.bgColor = 'high'
        return variables.greenColor
      }
    },
    getColorForParticipationType() {
      if (this.score >= 0 && this.score < 50) {
        this.bgColor = 'low'
        return variables.redColor
      } else if (this.score >= 50 && this.score < 75) {
        this.bgColor = 'md'
        return variables.yellowColor
      } else {
        this.bgColor = 'high'
        return variables.greenColor
      }
    },
    getLabel() {
      if (this.type == 'participation') {
        return this.getLabelForParticipationType()
      }

      if (this.score <= 4.4) {
        return this.$i18n.t('engagementAvarage.low')
      } else if (this.score > 4.4 && this.score <= 5.4) {
        return this.$i18n.t('engagementAvarage.ok')
      } else if (this.score > 5.4 && this.score <= 7.4) {
        return this.$i18n.t('engagementAvarage.good')
      } else if (this.score > 7.4 && this.score <= 8.9) {
        return this.$i18n.t('engagementAvarage.veryGood')
      } else if (this.score >= 9 && this.score <= 10) {
        return this.$i18n.t('engagementAvarage.amazing')
      }

      return ''
    },
    getLabelForParticipationType() {
      if (this.score < 45) {
        return this.$i18n.t('engagementAvarage.participation.veryLow')
      } else if (this.score >= 45 && this.score < 55) {
        return this.$i18n.t('engagementAvarage.participation.low')
      } else if (this.score >= 55 && this.score < 75) {
        return this.$i18n.t('engagementAvarage.participation.medium')
      } else if (this.score >= 75 && this.score < 90) {
        return this.$i18n.t('engagementAvarage.participation.good')
      } else if (this.score >= 90) {
        return this.$i18n.t('engagementAvarage.participation.veryGood')
      }

      return ''
    },
    getScoreFormatterFunction() {
      const _this = this
      const enablePercentage = this.enablePercentage

      let formatterFunction = function (val) {
        const formatterValue = Math.pow(10, 2)
        const value = Math.floor((val / 10) * formatterValue) / formatterValue

        const formattedScore = Number(value.toFixed(2))

        return !enablePercentage ? formattedScore : `${formattedScore}%`
      }

      if (this.type === 'participation') {
        formatterFunction = function (val) {
          const formattedScore = _this.absoluteScore ? _this.absoluteScore : val
          return !enablePercentage ? formattedScore : `${formattedScore}%`
        }
      }

      return formatterFunction
    },
  },
  computed: {
    series() {
      if (this.type === 'participation') {
        if (!this.score) {
          return [0]
        }
        return [this.score]
      }

      return [this.score * 10]
    },
    options() {
      return {
        bgColor: '',
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#e7e7e7',
              strokeWidth: '100%',
              margin: 8,
              dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                color: '#e7e7e7',
                opacity: 1,
                blur: 2,
              },
            },
            hollow: {
              margin: 15,
              size: '66%',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                offsetY: 28,
                show: true,
                color: '#333',
                fontSize: '14px',
                fontFamily: 'poppins',
              },
              value: {
                formatter: this.getScoreFormatterFunction(),
                color: '#2c3a4b',
                fontSize: '40px',
                show: true,
                offsetY: -15,
                fontWeight: '600',
                fontFamily: 'poppins',
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: 'solid',
          colors: [this.getColor()],
        },
        labels: [this.getLabel()],
      }
    },
    lastScore() {
      if (this.type === 'participation') {
        return this.participationMaxValue
      }
      return this.maxValue
    },
    noData() {
      if (this.type === 'participation') {
        return this.$t('engagementAvarage.noResults')
      }

      return this.$t('engagementAvarage.noData')
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
