<template>
  <div class="shadow">
    <v-card elevation="0" height="100%" data-test-card-invited-responders-rate>
      <v-card-title class="flex-column align-start card-title-slot">
        <h4 class="card-title">
          {{ $t('engagementPage.cardInvitedRespondersRate.cardTitle') }}
        </h4>
        <p class="card-subtitle">
          {{
            $t('engagementPage.cardInvitedRespondersRate.cardSubtitle', {
              invited: this.numberOfInvited,
              responders: this.numberOfResponders,
            })
          }}
        </p>
      </v-card-title>

      <div v-if="loading" class="load-content">
        <v-skeleton-loader width="300" type="list-item" />
        <v-skeleton-loader width="200" type="list-item" />
        <v-skeleton-loader width="300" type="list-item" />
      </div>

      <section v-else>
        <v-card-text>
          <div
            class="d-flex align-center flex-column pt-9"
            data-test-percentage-invited
          >
            <EngagementAverage
              :score="numberInvitedRespondersRate"
              type="participation"
              enablePercentage
            />
            <div class="d-flex justify-center align-center">
              <Variation short :variation="variation" />
              <span class="ml-2 variation-text">{{ variationText }}</span>
            </div>
            <p
              class="card-description px-md-6 mt-5"
              data-test-invited-explanatory-message
            >
              {{
                $t('engagementPage.cardInvitedRespondersRate.cardDescription', {
                  invited: this.numberOfInvited,
                  responders: this.numberOfResponders,
                  days: this.surveyCycle,
                })
              }}
            </p>
          </div>
        </v-card-text>
      </section>
    </v-card>
  </div>
</template>

<script>
import EngagementAverage from '@/components/EngagementAverage/EngagementAverage.vue'
import Variation from '@/components/Variation/Variation.vue'

export default {
  name: 'CardInvitedRespondersRate',
  components: {
    EngagementAverage,
    Variation,
  },
  props: {
    invitedRespondersRate: Number,
    invited: Number,
    responders: Number,
    surveyCycle: Number,
    variation: Number,
    variationText: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    numberInvitedRespondersRate() {
      if (!this.invitedRespondersRate) {
        return 0
      }

      return this.invitedRespondersRate
    },
    numberOfInvited() {
      if (!this.invited) {
        return 0
      }

      return this.invited
    },
    numberOfResponders() {
      if (!this.responders) {
        return 0
      }

      return this.responders
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
