import api from '@/service/Http'

/**
 * Grupo com hierarquia
 * @type {GroupTree}
 *
 * @property {String} id
 * @property {String} name
 * @property {String} costCenter
 * @property {Boolean} inactive
 * @property {Array<GroupTree>} children grupos herdeiros
 */

/**
 * Return list of groups with hierarchy
 *
 * @returns {Array<GroupTree>}
 */
export async function getGroupsTree() {
  return (await api()).get('/management/groups/tree')
}

export const getGroups = async (data) => {
  let endpoint = `/management/groups?limit=${data.limit}&offset=${data.offset}`
  if (!!data.name) endpoint += `&name=${data.name}`

  return (await api()).get(endpoint)
}

/**
 * @typedef {Object} People
 *
 * @property {String} id
 * @property {String} name
 * @property {String} photo
 * @property {String} email
 * @property {String} status
 * @property {String} role
 */

/**
 * @param {Object} param
 * @param {String} param.groupID Group unique code
 * @param {Number} param.limit Results limiter
 * @param {Number} param.offset Position where should start looking for results
 * @returns {Array.<People>} List of people in the group
 */
export async function listPeopleInTheGroup({ groupID, limit = 5, offset = 0 }) {
  const params = {}

  if (limit) {
    params.limit = limit
  }

  if (offset >= 0) {
    params.offset = offset
  }

  return (await api()).get(`/management/groups/${groupID}/people`, { params })
}

export const getGroupPeople = async (data) =>
  (await api()).get(`/management/groups/${data.groupID}/people`)

export const getGroupShareResult = async (data) =>
  (await api()).get(
    `/management/groups/${data.groupID}/results-sharing?limit=${data.limit}&offset=${data.offset}&module=${data.module}`
  )

export const updateGroupShareResult = async (data) =>
  (await api()).put(
    `/management/groups/${data.groupID}/results-sharing/${data.personID}`,
    data.body
  )

export const shareGroupResult = async (data) =>
  (await api()).put(
    `/management/groups/${data.groupID}/results-sharing/${data.personID}`
  )

export const removeGroupSharePerson = async (data) =>
  (await api()).delete(
    `/management/groups/${data.groupID}/results-sharing/${data.personID}`
  )
