function BreakAsThreadPromises(items, search, controller, callbackPromiseFn) {
  const numCores = navigator.hardwareConcurrency || 4
  const chunkSize = Math.ceil(items.length / numCores)

  const promises = []
  for (let i = 0; i < numCores; i++) {
    const start = i * chunkSize
    const end = start + chunkSize

    promises.push(
      callbackPromiseFn(
        items.slice(start, end),
        search.toLowerCase(),
        controller
      )
    )
  }

  return promises
}

function ItemPromise(item, search, bigSearch, shallowIgnore) {
  return new Promise((resolve) => {
    const itemLabel = item.label.toLowerCase()

    if (itemLabel.match(search) && !shallowIgnore.includes(item.id)) {
      bigSearch.push(item)
    }

    resolve()
  })
}

function ChildrenPromise(
  item,
  search,
  controller,
  bigSearch,
  shallowIgnore,
  callbackPromiseFn
) {
  if (Array.isArray(item.children) && item.children.length > 0) {
    return callbackPromiseFn(
      item.children,
      search,
      controller,
      bigSearch,
      shallowIgnore
    )
  }

  return new Promise((resolve) => {
    resolve()
  })
}

function SearchWithAbortController(
  items,
  search,
  controller,
  bigSearch,
  shallowIgnore
) {
  if (typeof search === 'string') {
    search = search.toLowerCase()
  }

  return new Promise((resolve, reject) => {
    const promises = items.flatMap((item) => {
      if (controller.controller.signal.aborted) {
        throw new DOMException(controller.identity, 'AbortError')
      }

      return [
        ItemPromise(item, search, bigSearch, shallowIgnore),
        ChildrenPromise(
          item,
          search,
          controller,
          bigSearch,
          shallowIgnore,
          SearchWithAbortController
        ),
      ]
    })

    Promise.all(promises)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function BigSearchToUniqueItems(bigSearch) {
  const uniqueItems = Array.from(new Set(bigSearch.map((item) => item.id))).map(
    (id) => {
      return bigSearch.find((item) => item.id === id)
    }
  )

  return uniqueItems
}

module.exports = {
  BreakAsThreadPromises,
  SearchWithAbortController,
  BigSearchToUniqueItems,
  ItemPromise,
  ChildrenPromise,
}
