import { render, staticRenderFns } from "./AnswerSliderCard.vue?vue&type=template&id=27109bf2&scoped=true&"
import script from "./AnswerSliderCard.vue?vue&type=script&lang=js&"
export * from "./AnswerSliderCard.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=27109bf2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27109bf2",
  null
  
)

export default component.exports