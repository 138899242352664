<template>
  <div class="overflow-hidden navbar-wrapper">
    <v-bottom-navigation
      v-model="tabModel"
      class="page-nav"
      active-class="page-nav__active"
      background-color="neutral"
      height="40px"
      @change="handleNavigation"
    >
      <v-btn
        :ripple="false"
        class="page-nav__btn"
        value="ReceivedOpinionsPage"
        :class="{ active: tabModel === 'ReceivedOpinionsPage' }"
      >
        <span>{{
          $t('engagementPage.opinions.navigation.receivedOpinions')
        }}</span>
      </v-btn>

      <v-btn
        v-if="
          _canEngagementOpinionsReports || _canBuy.OpinionsAnalyticsReportPage
        "
        data-test-opinions-analytics-report-page
        :ripple="false"
        class="page-nav__btn"
        value="OpinionsAnalyticsReportPage"
        :class="{ active: tabModel === 'OpinionsAnalyticsReportPage' }"
      >
        <span>
          {{ $t('engagementPage.opinions.navigation.analyticsReport') }}
          <v-icon
            v-if="_canBuy.OpinionsAnalyticsReportPage"
            class="upgrade-icon"
            color="secondary"
            size="0.875rem"
          >
            fi-sr-crown
          </v-icon>
        </span>
      </v-btn>

      <v-spacer></v-spacer>

      <div class="d-flex mt-1">
        <div :class="_onReceivedOpinions ? 'video-mt-short' : 'mt-2 mr-3'">
          <HowToBeginAndWatchAVideo
            firstLabelAlternative
            :videoType="
              tabModel === 'ReceivedOpinionsPage'
                ? 'opinions.general'
                : 'opinions.analytics'
            "
            :short="_onReceivedOpinions"
          ></HowToBeginAndWatchAVideo>
        </div>

        <div
          class="mr-3 mb-2 d-flex align-center cursor-pointer"
          v-if="_onReceivedOpinions"
          @click="handleListView"
        >
          <i
            data-test-list-view
            aria-hidden="true"
            class="fi fi-br-interlining list-view-button"
            :class="
              listView ? 'list-view-button-enable' : 'list-view-button-disable'
            "
          ></i>

          <span
            class="ml-2"
            :class="
              listView ? 'list-view-title-enable' : 'list-view-title-disable'
            "
          >
            {{ $t('engagementPage.opinions.navigation.listViewTitle') }}
          </span>
        </div>
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

import { _permissions } from '@/helpers/ability/engagement'
import scrollTabNavigation from '@/mixins/scrollTabNavigation'

import { mapGetters } from 'vuex'

const CURRENT_TAB = 'OpinionsPage'
const ROUTES_VALID = ['ReceivedOpinionsPage', 'OpinionsAnalyticsReportPage']

export default {
  name: 'PageNavigation',

  inject: ['callUpdateYourPackage'],
  mixins: [scrollTabNavigation],

  components: {
    HowToBeginAndWatchAVideo,
  },

  props: {
    listView: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tabModel: 'ReceivedOpinionsPage',
    }
  },

  computed: {
    ...mapGetters(['getIsBasicPlanAdminUser']),

    _onReceivedOpinions() {
      return this.tabModel === 'ReceivedOpinionsPage'
    },

    _canBuy() {
      const showTabIfAvailable = (canAccess, IsBasicPlanAdminUser) => {
        if (canAccess) {
          return false
        }

        return IsBasicPlanAdminUser && !canAccess
      }

      return {
        OpinionsAnalyticsReportPage: showTabIfAvailable(
          this._canEngagementOpinionsReports,
          this.getIsBasicPlanAdminUser
        ),
      }
    },

    _canEngagementOpinionsReports() {
      return this.$can('access', _permissions.engagement_opinions_reports)
    },
  },

  methods: {
    handleNavigation(tab) {
      if (!ROUTES_VALID.includes(tab)) {
        return
      }

      if (this._canBuy?.[tab]) {
        const PageTypes = {
          OpinionsAnalyticsReportPage: 'analytics',
        }[tab]

        this.callUpdateYourPackage(PageTypes)
        return
      }

      if (!this.$router.currentRoute.path.includes(tab)) {
        const alreadyOnRoute = this.$route.name === tab
        if (alreadyOnRoute) return

        this.$router.push({ name: tab }).catch((err) => !!err)
      }
    },

    handleListView() {
      this.$emit('handleListView')
    },
  },

  created() {
    const ReceivedOpinionsPage = 'ReceivedOpinionsPage'

    if (this.$route.name === CURRENT_TAB) {
      this.tabModel = ReceivedOpinionsPage
      this.handleNavigation(ReceivedOpinionsPage)
      return
    }

    if (ROUTES_VALID.includes(this.$route.name)) {
      this.tabModel = this.$route.name
    }

    this.tabModel = ReceivedOpinionsPage
  },
  mounted() {
    this.scrollToTab(CURRENT_TAB, 'context-menu')
  },

  watch: {
    tabModel(newValue, oldValue) {
      if (!ROUTES_VALID.includes(newValue) && ROUTES_VALID.includes(oldValue)) {
        this.tabModel = oldValue
      }

      if (this._canBuy?.[newValue]) {
        this.tabModel = oldValue
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
