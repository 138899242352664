<template>
  <div id="detail-behavior">
    <template v-if="!isLoading">
      <div
        class="detail-behavior-header"
        v-if="!hideMetrics || data.unfavorable === true || data.value !== null"
      >
        <div
          v-if="data.unfavorable === false && data.value !== null"
          class="commitment"
        >
          <span v-text="data.value.name"></span>
        </div>
        <div v-else class="commitment-empty" />

        <div
          v-if="data.unfavorable === true && data.value === null"
          class="unfavorable-container"
        >
          <div class="unfavorable">
            <span>{{ $t('detailBehavior.unfavorable') }}</span>
            <v-tooltip top z-index="99999" max-width="18.75rem">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="tooltip-icon-unfavorable">
                  <v-icon class="fi-rr-interrogation" size="0.75rem" />
                </span>
              </template>

              <div>
                <span v-text="$t('detailBehavior.unfavorableInfo')"></span>
              </div>
            </v-tooltip>
          </div>
        </div>
        <div v-else class="unfavorable-empty" />
      </div>

      <div class="behavior-content">
        <div id="behavior-description">
          <div class="behavior-details">
            <div class="behavior-header">
              <div class="behavior-title">
                <v-img
                  max-height="60px"
                  max-width="60px"
                  :src="data.image"
                  :alt="data.name"
                />

                <div class="name-container">
                  <span class="name" v-text="data.name"></span>
                  <Variation :variation="variation" short />
                </div>
              </div>

              <div class="behavior-position">
                <v-icon
                  v-if="score <= 10"
                  class="ranking-trophy fi fi-sr-trophy"
                />
                <span class="score">
                  <strong>{{ position(score) }}</strong>
                </span>
              </div>
            </div>

            <p class="description" v-text="data.description"></p>

            <div class="behavior-info">
              <div>
                <div class="stats-fields">
                  <span v-text="$t('detailBehavior.statsFields.votes')" />
                  <v-tooltip top max-width="18.75rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tooltip-icon">
                        <v-icon class="fi-rr-interrogation" size="0.75rem" />
                      </span>
                    </template>

                    <div>
                      <span
                        v-text="$t('detailBehavior.statsInfo.votes')"
                      ></span>
                    </div>
                  </v-tooltip>
                </div>
                <div class="stats-fields">
                  <span v-text="$t('detailBehavior.statsFields.probability')" />
                  <v-tooltip top max-width="18.75rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tooltip-icon">
                        <v-icon class="fi-rr-interrogation" size="0.75rem" />
                      </span>
                    </template>

                    <div>
                      <span
                        v-text="$t('detailBehavior.statsInfo.probability')"
                      ></span>
                    </div>
                  </v-tooltip>
                </div>
                <div class="stats-fields">
                  <span
                    v-text="$t('detailBehavior.statsFields.visualizedBehavior')"
                  />
                  <v-tooltip top max-width="18.75rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tooltip-icon">
                        <v-icon class="fi-rr-interrogation" size="0.75rem" />
                      </span>
                    </template>

                    <div>
                      <span
                        v-text="
                          $t('detailBehavior.statsInfo.visualizedBehavior')
                        "
                      ></span>
                    </div>
                  </v-tooltip>
                </div>
                <div class="stats-fields">
                  <span
                    v-text="$t('detailBehavior.statsFields.reliabilityRate')"
                  />
                  <v-tooltip top max-width="18.75rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tooltip-icon">
                        <v-icon class="fi-rr-interrogation" size="0.75rem" />
                      </span>
                    </template>

                    <div>
                      <span
                        v-text="$t('detailBehavior.statsInfo.reliabilityRate')"
                      ></span>
                    </div>
                  </v-tooltip>
                </div>
                <div class="stats-fields">
                  <span
                    v-text="$t('detailBehavior.statsFields.maturityLevel')"
                  />
                  <v-tooltip top max-width="18.75rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tooltip-icon">
                        <v-icon class="fi-rr-interrogation" size="0.75rem" />
                      </span>
                    </template>

                    <div>
                      <span
                        v-text="$t('detailBehavior.statsInfo.maturityLevel')"
                      ></span>
                    </div>
                  </v-tooltip>
                </div>
              </div>

              <div class="stats-values">
                <p v-text="votes(data.votes)"></p>
                <p v-text="probability(data.probability)"></p>
                <p v-text="seen(data.seen)"></p>
                <p
                  :class="
                    data.reliabilityRate ? data.reliabilityRate : 'default'
                  "
                  v-text="reliabilityRate(data.reliabilityRate)"
                ></p>
                <p v-text="maturityLevel(data.maturityLevel)"></p>
              </div>
            </div>
          </div>
        </div>

        <div id="behavior-history" v-if="!hideMetrics">
          <v-lazy
            :options="{
              threshold: 0.5,
            }"
            transition="fade-transition"
            class="history-chart"
          >
            <HistoryChart
              hideSingleSerie
              :metrics="metrics"
              :dates="dates"
              tooltipValueInteger
            />
          </v-lazy>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="behavior-header">
        <div class="commitment-empty" />

        <div class="unfavorable-empty" />
      </div>

      <div class="behavior-content">
        <div id="behavior-description" class="loading">
          <div class="behavior-details">
            <div class="behavior-header">
              <div class="behavior-title">
                <v-skeleton-loader type="button" class="skeleton-icon" />
                <div class="name-container">
                  <v-skeleton-loader type="button" class="skeleton-title" />
                </div>
              </div>

              <div class="behavior-position">
                <v-skeleton-loader type="button" class="skeleton-position" />
              </div>
            </div>

            <v-skeleton-loader
              type="button"
              class="description skeleton-description"
            />

            <div class="behavior-info">
              <div>
                <v-skeleton-loader
                  v-for="i in 5"
                  :key="i"
                  type="button"
                  class="skeleton-mediumline"
                />
              </div>
              <div>
                <v-skeleton-loader
                  v-for="i in 5"
                  :key="i"
                  type="button"
                  class="skeleton-shortline"
                />
              </div>
            </div>
          </div>
        </div>

        <div id="behavior-history" class="loading">
          <v-skeleton-loader type="button" class="skeleton-history mr-3" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Variation from '@/components/Variation/Variation'
import HistoryChart from '@/components/HistoryChart/HistoryChart'

export default {
  props: {
    dates: {
      type: Array,
      default: () => [],
      require: true,
    },
    metrics: {
      type: Array,
      default: () => [],
      require: true,
    },
    data: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      default: 'position',
    },
    variation: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hideMetrics: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Variation,
    HistoryChart,
  },
  computed: {
    score() {
      if (this.type === 'position') {
        return this.data.position
      }
      if (this.type === 'probability') {
        return this.data.positionProbability
      }
      return this.data.position
    },
  },
  methods: {
    position(value) {
      if (!value) {
        return '--'
      }
      return value
    },
    votes(value) {
      const text = this.$t('detailBehavior.statsTypes.votes')
      if (!value) {
        return `-- ${text}`
      }
      return `${value} ${text}`
    },
    probability(value) {
      const text = this.$t('detailBehavior.statsTypes.probability')
      if (!value) {
        return `--`
      }
      return `${value} ${text}`
    },
    seen(value) {
      const text = this.$t('detailBehavior.statsTypes.visualizedBehavior')
      if (!value) {
        return `-- ${text}`
      }
      return `${value} ${text}`
    },
    reliabilityRate(value) {
      const valueKey = {
        low: 'detailBehavior.statsTypes.reliabilityRateLow',
        medium: 'detailBehavior.statsTypes.reliabilityRateMedium',
        high: 'detailBehavior.statsTypes.reliabilityRateHigh',
      }[value]
      if (!valueKey) {
        return this.$t('detailBehavior.statsTypes.emptyInfo')
      }
      return this.$t(valueKey)
    },
    maturityLevel(value) {
      const text = this.$t('detailBehavior.statsTypes.maturityLevel')
      if (!value) {
        return this.$t('detailBehavior.statsTypes.emptyInfo')
      }
      return `${text} ${value}`
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
