<template>
  <div
    data-test-quickly-comparison
    id="comparacao-rapida"
    class="comparacao-card"
    :class="{ benchmark: type === 'benchmark' }"
  >
    <div class="comparacao-card-header">
      <p class="comparacao-card-header__title" v-text="title"></p>
      <a
        v-if="type !== 'benchmark'"
        @click="handleAction"
        class="comparacao-card-header__action"
        v-text="$t('quicklyComparison.action')"
      ></a>
    </div>

    <div class="comparacao-card-content">
      <div v-if="_showGroupsScore" class="content-item">
        <p
          id="groupScore"
          data-test-comparisonGroupScore
          v-if="isValidScore(groupScore)"
          class="content-item__value"
          :class="_groupColor"
          v-text="groupScore + (percentage ? '%' : '')"
        ></p>

        <p v-else data-test-comparisonGroupScore v-text="'--'"></p>

        <div id="group" class="content-item__text">
          <div class="d-flex">
            <i class="fi fi-rr-caret-right"></i>
            <p v-text="$t('quicklyComparison.items.group')"></p>
          </div>
        </div>
      </div>

      <div v-if="type === 'benchmark' && _Score" class="content-item">
        <p
          id="groupScore"
          data-test-comparisonGroupScore
          class="content-item__value"
          :class="_groupColor"
          v-text="_Score"
        ></p>

        <div id="group" class="content-item__text">
          <p
            class="text-capitalize mx-auto"
            v-text="$t('quicklyComparison.benchmark.actual')"
          ></p>

          <Variation
            :variation="variation"
            :[`data-test-variation-${variationDataName}`]="''"
          ></Variation>
        </div>
      </div>

      <div v-if="type === 'benchmark' && !_Score" class="content-item__empty">
        <p v-text="'--'"></p>

        <div class="content-item__text">
          <p
            class="text-capitalize mx-auto"
            v-text="$t('quicklyComparison.benchmark.actual')"
          ></p>

          <Variation
            :variation="variation"
            :[`data-test-variation-${variationDataName}`]="''"
          ></Variation>
        </div>
      </div>

      <div class="content-item" v-if="type !== 'benchmark'">
        <p
          data-test-comparisonAccountScore
          v-if="isValidScore(accountScore)"
          class="content-item__value"
          v-text="accountScore + (percentage ? '%' : '')"
        ></p>

        <p v-else data-test-comparisonAccountScore v-text="'--'"></p>

        <div id="account" class="content-item__text">
          <div class="d-flex">
            <i v-if="type !== 'benchmark'" class="fi fi-rr-building"></i>
            <p v-text="$t('quicklyComparison.items.account')"></p>
          </div>
        </div>
      </div>

      <div class="badge" v-if="type === 'benchmark'">
        <img
          :src="require(`@/assets/images/BenchmarkBadge/${_rankName}.png`)"
          :alt="_rankName"
        />

        <p
          class="badge__title"
          :class="{
            'mt-n2': ['bronze', 'silver', 'gold'].indexOf(_rankName) >= 0,
          }"
          v-text="$t(`quicklyComparison.benchmark.rank.${_rankName}`)"
        ></p>
      </div>

      <div
        v-if="type === 'benchmark' && marketScore === null"
        class="content-item__empty my-auto"
      >
        <p v-text="'--'"></p>

        <div class="content-item__text">
          <p
            class="text-capitalize mx-auto"
            v-text="$t('quicklyComparison.items.market')"
          ></p>
        </div>
      </div>

      <div
        v-else
        class="blur-wrapper"
        @click.stop.prevent="goToAquisitionPage()"
      >
        <div
          data-test-comparisonMarketLockedIcon
          v-if="hideMarketScore"
          class="blur-icon"
        >
          <i class="fi fi-sr-lock"></i>
        </div>

        <div class="content-item">
          <p
            data-test-comparisonMarketScore
            class="content-item__value blur"
            :class="{ active: hideMarketScore }"
            v-if="isValidScore(marketScore)"
            v-text="marketScore + (percentage ? '%' : '')"
          ></p>

          <p v-else data-test-comparisonMarketScore v-text="'--'"></p>

          <div id="market" class="content-item__text">
            <div class="d-flex">
              <i v-if="type !== 'benchmark'" class="fi fi-rr-flag"></i>
              <p v-text="$t('quicklyComparison.items.market')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="comparacao-card-graph">
      <BenchmarkProgressBar
        data-test-benchmarkProgressBar
        :benchmarkPermissions="benchmarkPermissions"
        :groupMarker="groupPercent"
        :accountMarker="accountPercent"
        :marketMarker="_marketMarker"
      ></BenchmarkProgressBar>

      <div class="modal hidden-sm-and-down" :class="{ up: !groupScore }">
        <div class="modal-header">
          <p v-text="$t('quicklyComparison.benchmark.modalTitle')"></p>
        </div>

        <div class="modal-content">
          <div class="modal-content-item mb-1" v-if="groupScore">
            <div class="modal-content-item__icon">
              <i class="fi fi-rr-caret-right"></i>
            </div>

            <div
              class="modal-content-item__text"
              data-test-item-group
              v-text="$t('quicklyComparison.items.group')"
            ></div>
          </div>

          <div
            class="modal-content-value"
            v-if="groupScore"
            v-text="groupPercent || '--'"
          ></div>

          <div class="modal-content-item mb-1">
            <div class="modal-content-item__icon">
              <i class="fi fi-rr-flag"></i>
            </div>

            <div
              class="modal-content-item__text"
              data-test-item-market
              v-text="$t('quicklyComparison.items.market')"
            ></div>
          </div>

          <div class="modal-content-value" v-text="marketPercent || '--'"></div>

          <div class="modal-content-item">
            <div class="modal-content-item__icon">
              <i class="fi fi-rr-building"></i>
            </div>

            <div
              class="modal-content-item__text"
              data-test-item-account
              v-text="$t('quicklyComparison.items.account')"
            ></div>
          </div>

          <div
            class="modal-content-value"
            v-text="accountPercent || '--'"
          ></div>
        </div>
      </div>
    </div>

    <div class="comparacao-card-footer">
      <p v-if="type !== 'benchmark'">
        {{ $t('quicklyComparison.subtitle1') }}
        <strong>
          {{ percentageOfCompanies }}%
          {{ $t('quicklyComparison.subtitle2') }}
        </strong>
      </p>

      <p
        v-if="type === 'benchmark' && percentageOfCompanies"
        class="comparacao-card-footer__text"
      >
        {{ $t('quicklyComparison.benchmark.subtitle1') }}
        <strong>
          {{ percentageOfCompanies }}%
          {{ $t('quicklyComparison.benchmark.subtitle2') }}
        </strong>
        {{ $t('quicklyComparison.benchmark.subtitle3') }}
      </p>

      <p
        v-if="type === 'benchmark' && !percentageOfCompanies"
        v-text="$t('quicklyComparison.benchmark.subtitleEmpty')"
      ></p>
    </div>
  </div>
</template>

<script>
import BenchmarkProgressBar from '@/components/BenchmarkProgressBar/BenchmarkProgressBar.vue'
import Variation from '@/components/Variation/Variation.vue'

export default {
  name: 'QuicklyComparison',
  components: {
    BenchmarkProgressBar,
    Variation,
  },
  props: {
    groupPercent: {
      type: Number,
    },
    accountPercent: {
      type: Number,
    },
    marketPercent: {
      type: Number,
    },
    groupScore: {
      type: Number,
    },
    accountScore: {
      type: Number,
    },
    marketScore: {
      type: Number,
    },
    hideMarketScore: {
      type: Boolean,
      default: true,
    },
    percentageOfCompanies: {
      type: Number,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    hasSelectedGroup: {
      type: Boolean,
    },
    benchmarkPermissions: {
      type: Boolean,
    },
    type: String,
    title: {
      type: String,
      default() {
        return this.$t('quicklyComparison.title')
      },
    },
    variation: Number,
    variationDataName: String,
  },
  computed: {
    _showGroupsScore() {
      if (!this.hasSelectedGroup) {
        return false
      }

      if (this.type === 'benchmark') {
        return false
      }

      const groupScore = this.groupScore

      return groupScore >= 0 && groupScore <= 100
    },

    _Score() {
      if (this.groupScore > 0) {
        return this.groupScore
      }

      if (this.groupScore <= 0) {
        return false
      }

      if (this.accountScore > 0) {
        return this.accountScore
      }

      return false
    },

    _groupColor() {
      const rank = this.groupPercent || this.accountPercent
      if (rank >= 0 && rank <= 25) {
        return 'low'
      }

      if (rank > 25 && rank <= 75) {
        return 'mid'
      }

      return 'high'
    },
    _rankName() {
      const rank = this.groupPercent || this.accountPercent

      switch (true) {
        case !rank:
          return 'unranked'
        case rank < 55:
          return 'bronze'
        case rank < 65:
          return 'silver'
        case rank < 75:
          return 'gold'
        case rank < 85:
          return 'platinum'
        case rank < 95:
          return 'diamond'
        default:
          return 'champion'
      }
    },

    _marketMarker() {
      if (this.hideMarketScore) {
        return 0
      }

      return this.marketPercent
    },
  },
  methods: {
    isValidScore(score) {
      if ([null, undefined].includes(score)) {
        return false
      }

      return true
    },

    goToAquisitionPage() {
      if (!this.hideMarketScore) {
        return
      }

      this.$store.commit('openUpdateYourPackageDialog', 'benchmark')
    },

    handleAction() {
      this.$router.push('/engagement/comparison')
      this.$store.commit('setCurrentTab', 'ComparisonPage')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
