<template>
  <div data-test-sidebar-group-people>
    <LateralMenuItem
      :title="$t('groupPeople.title')"
      :isMini="isMini"
      howToType="sidebarGroupPeople"
    >
      <template v-slot:title-icon="{ size }">
        <v-icon :size="size">mdi-account-multiple</v-icon>
      </template>
      <template v-slot:header-action v-if="canEdit">
        <v-btn
          class="pa-0 ma-0"
          icon
          small
          @click="handleManageGroup"
          data-test-manage-group-people
        >
          <v-icon size="14">mdi-pencil</v-icon>
        </v-btn>
      </template>

      <div>
        <div v-if="!isLoading" class="people-list">
          <div
            v-for="people in getPeopleList"
            :key="people.id"
            class="people-list-row"
            data-test-group-people-list-item
          >
            <v-avatar
              size="28"
              class="people-list-avatar"
              :color="people.color"
            >
              <img v-if="people.photo" :src="people.photo" :alt="people.name" />
              <span v-else class="people-list-initials">{{
                getNameInitials(people.name)
              }}</span>
            </v-avatar>

            <div>
              <p class="people-list-name">
                {{ people.name }}
                <span v-if="getRoleLabel(people.role)">
                  <i class="fi fi-ss-circle-small"></i>
                  {{ getRoleLabel(people.role) }}
                </span>
              </p>
              <p class="people-list-email">{{ people.email }}</p>
            </div>
          </div>
        </div>
        <div
          v-if="getPeopleList.length === 0 && !isLoading"
          class="empty-group-people"
          data-test-empty-group-people
        >
          <template v-if="canCreate">
            <p>
              {{ $t('groupPeople.emptyDesc') }}
            </p>
            <a
              @click="handleManageGroup"
              href="#"
              data-test-empty-manage-group-people
            >
              {{ $t('groupPeople.addPeople') }}
            </a>
          </template>
          <template v-else>
            <p>
              {{ $t('groupPeople.empty') }}
            </p>
          </template>
        </div>
        <v-row v-if="!isLoading && getPeopleList.length > 0">
          <v-col>
            <div
              class="show-more-container"
              @click="handleManageGroup"
              data-test-show-more-group-people
            >
              {{ $t('groupPeople.showMore') }}
              <i class="fi fi-sr-caret-down"></i>
            </div>
          </v-col>
        </v-row>
        <div v-if="isLoading" class="mt-n2">
          <v-skeleton-loader
            v-for="index in 4"
            :key="index"
            height="56"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </div>
      </div>
    </LateralMenuItem>
  </div>
</template>

<script>
import LateralMenuItem from '@/components/LateralMenuItem/LateralMenuItem.vue'
import randomColors from '@/helpers/randomColors/randomColors.js'
export default {
  name: 'GroupPeople',
  props: {
    isMini: Boolean,
    groupID: String,
    peopleList: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
  },
  components: { LateralMenuItem },
  data() {
    return {
      canEdit: false,
      canCreate: false,
      colorList: [],
    }
  },
  async created() {
    this.colorList = randomColors
  },
  computed: {
    getPeopleList() {
      return this.peopleList.map((person) => {
        const color =
          person.photo == null || person.photo == ''
            ? this.getRandomColor()
            : ''
        return { ...person, color }
      })
    },
  },
  methods: {
    getRoleLabel(role) {
      if (role === 'manager') {
        return 'Gestão'
      } else if (role === 'viewer') {
        return 'Acompanhar'
      }

      return null
    },
    getNameInitials(name) {
      const splitName = name.split(' ')
      return splitName[0][0] + splitName[splitName.length - 1][0]
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colorList.length)
      const randomColor = this.colorList[randomIndex]

      return randomColor
    },
    handleManageGroup() {
      const LinkEngagementLegacy = new URL(
        `groups/${this.groupID}`,
        process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK
      )

      window.open(LinkEngagementLegacy.href, '_blank')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
