<template>
  <LateralMenuItem :title="$t('opinionsFilter.title')" :isMini="isMini">
    <template v-slot:title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>

    <form>
      <v-row class="ma-0">
        <v-autocomplete
          data-test-metric-select
          v-model="metricID"
          :items="metricList"
          :label="$t('opinionsFilter.metric')"
          outlined
          hide-details
          height="44px"
          dense
          clearable
          :menu-props="{ 'content-class': 'select-filter' }"
          item-color="redLighten2"
          @change="onInputChanges"
        >
        </v-autocomplete>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <v-autocomplete
          v-model="questionID"
          :items="_listQuestionsForSelect"
          :label="$t('opinionsFilter.question')"
          outlined
          hide-details
          height="44px"
          dense
          clearable
          :menu-props="{ 'content-class': 'select-filter' }"
          item-color="redLighten2"
          @change="onInputChanges"
        >
        </v-autocomplete>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <fieldset class="btn-group-fieldset">
          <label for="opn-f-closed"
            >{{ $t('opinionsFilter.closed.title') }}:</label
          >
          <v-btn-toggle
            id="opn-f-closed"
            v-model="closed"
            group
            @change="onInputChanges"
          >
            <v-btn :value="false">
              {{ $t('opinionsFilter.closed.opt1') }}
            </v-btn>
            <v-btn :value="true" data-test-closed-btn-toggle-concluded>
              {{ $t('opinionsFilter.closed.opt2') }}
            </v-btn>
          </v-btn-toggle>
        </fieldset>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <fieldset class="btn-group-fieldset">
          <label for="opn-f-reply"
            >{{ $t('opinionsFilter.reply.title') }}:</label
          >
          <v-btn-toggle
            id="opn-f-reply"
            v-model="reply"
            group
            @change="onInputChanges"
          >
            <v-btn :value="true" data-test-reply-btn-toggle-yes>
              {{ $t('opinionsFilter.reply.opt1') }}
            </v-btn>
            <v-btn :value="false">
              {{ $t('opinionsFilter.reply.opt2') }}
            </v-btn>
          </v-btn-toggle>
        </fieldset>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <fieldset class="btn-group-fieldset">
          <label for="opn-f-readed"
            >{{ $t('opinionsFilter.readed.title') }}:</label
          >
          <v-btn-toggle
            id="opn-f-readed"
            v-model="readed"
            group
            @change="onInputChanges"
          >
            <v-btn :value="true" data-test-readed-btn-toggle-yes>
              {{ $t('opinionsFilter.readed.opt1') }}
            </v-btn>
            <v-btn :value="false">
              {{ $t('opinionsFilter.readed.opt2') }}
            </v-btn>
          </v-btn-toggle>
        </fieldset>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <fieldset class="btn-group-fieldset">
          <label for="opn-f-rated"
            >{{ $t('opinionsFilter.rated.title') }}:</label
          >
          <v-btn-toggle
            id="opn-f-rated"
            v-model="rated"
            group
            @change="onInputChanges"
          >
            <v-btn :value="true" data-test-rated-btn-toggle-yes>
              {{ $t('opinionsFilter.rated.opt1') }}
            </v-btn>
            <v-btn :value="false">
              {{ $t('opinionsFilter.rated.opt2') }}
            </v-btn>
          </v-btn-toggle>
        </fieldset>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <v-select
          data-test-rated-score-select
          v-model="ratedScore"
          :items="ratedScoreList"
          :label="$t('opinionsFilter.ratedScore')"
          outlined
          hide-details
          height="44px"
          dense
          clearable
          :menu-props="{ 'content-class': 'select-filter' }"
          item-color="redLighten2"
          @change="onInputChanges"
        >
        </v-select>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <fieldset class="btn-group-fieldset">
          <label for="opn-f-feeling"
            >{{ $t('opinionsFilter.feeling.title') }}:</label
          >
          <v-btn-toggle
            id="opn-f-feeling"
            v-model="feeling"
            group
            @change="onInputChanges"
          >
            <v-btn value="positive">
              {{ $t('opinionsFilter.feeling.opt1') }}
            </v-btn>
            <v-btn
              value="constructive"
              data-test-feeling-btn-toggle-constructive
            >
              {{ $t('opinionsFilter.feeling.opt2') }}
            </v-btn>
          </v-btn-toggle>
        </fieldset>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4" v-if="_hasFilterTags">
        <v-select
          data-test-tags-select
          v-model="tagsIDs"
          :items="_listTagsForSelect"
          :label="$t('opinionsFilter.tags')"
          outlined
          hide-details
          dense
          multiple
          :menu-props="{ 'content-class': 'select-filter' }"
          item-color="redLighten2"
          @change="onInputChanges"
        >
          <template v-slot:selection="{ item }">
            <v-chip
              >{{ item.text }}
              <v-btn
                icon
                x-small
                class="ml-1"
                width="max-content"
                height="max-content"
                @click.stop="() => removeTag(item.value)"
              >
                <v-icon size="16" color="neutral5">mdi-close</v-icon>
              </v-btn>
            </v-chip>
          </template>
        </v-select>
      </v-row>
      <v-row class="mx-0 mb-0 mt-4">
        <v-menu
          ref="menu"
          v-model="showFromToDateMenu"
          transition="scale-transition"
          offset-y
          min-width="auto"
          z-index="1000001"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              data-test-opinions-data-range-input
              id="opinions-date-range"
              :value="_fromToDateRangeText"
              outlined
              hide-details
              :label="$t('opinionsFilter.dateFilter')"
              placeholder=""
              v-bind="attrs"
              clearable
              width="100%"
              height="44px"
              dense
              v-on="on"
              readonly
              @click:clear="clearFromToDate"
            ></v-text-field>
          </template>
          <v-date-picker
            data-test-opinions-date-range-datepicker
            v-model="fromToDateArray"
            no-title
            scrollable
            :locale="$i18n.locale"
            color="primary"
            range
            @change="onInputChanges"
          ></v-date-picker>
        </v-menu>
      </v-row>
    </form>
  </LateralMenuItem>
</template>

<script>
import moment from 'moment'
import LateralMenuItem from '@/components/LateralMenuItem/LateralMenuItem.vue'
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'OpinionsFilter',
  components: { LateralMenuItem },
  props: {
    isMini: Boolean,
    tags: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      metricID: null,
      questionID: null,
      closed: null,
      reply: null,
      readed: null,
      rated: null,
      ratedScore: null,
      feeling: null,
      tagsIDs: [],
      fromToDateArray: [],
      showFromToDateMenu: false,
      metricList: [
        { text: this.$t('opinionsFilter.metricFilter.enps'), value: 'enps' },
        { text: this.$t('opinionsFilter.metricFilter.lnps'), value: 'lnps' },
        {
          text: this.$t('opinionsFilter.metricFilter.managerRelationship'),
          value: 'managerRelationship',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.managerRelationship:trust'
          ),
          value: 'managerRelationship:trust',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.managerRelationship:communication'
          ),
          value: 'managerRelationship:communication',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.managerRelationship:collaboration'
          ),
          value: 'managerRelationship:collaboration',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.ambassadorship'),
          value: 'ambassadorship',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.ambassadorship:promotor'),
          value: 'ambassadorship:promotor',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.ambassadorship:pride'),
          value: 'ambassadorship:pride',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.alignment'),
          value: 'alignment',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.alignment:values'),
          value: 'alignment:values',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.alignment:vision'),
          value: 'alignment:vision',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.alignment:ethics'),
          value: 'alignment:ethics',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.wellness'),
          value: 'wellness',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.wellness:stress'),
          value: 'wellness:stress',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.wellness:personal_health'),
          value: 'wellness:personal_health',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.wellness:mental_health'),
          value: 'wellness:mental_health',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.personalGrowth'),
          value: 'personalGrowth',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.personalGrowth:autonomy'),
          value: 'personalGrowth:autonomy',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.personalGrowth:mastery'),
          value: 'personalGrowth:mastery',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.personalGrowth:purpose'),
          value: 'personalGrowth:purpose',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.happiness'),
          value: 'happiness',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.happiness:work_happiness'),
          value: 'happiness:work_happiness',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.happiness:work_balance'),
          value: 'happiness:work_balance',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.satisfaction'),
          value: 'satisfaction',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.satisfaction:compensation'
          ),
          value: 'satisfaction:compensation',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.satisfaction:role'),
          value: 'satisfaction:role',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.satisfaction:workplace'),
          value: 'satisfaction:workplace',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.recognition'),
          value: 'recognition',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.recognition:recognition_quality'
          ),
          value: 'recognition:recognition_quality',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.recognition:recognition_frequency'
          ),
          value: 'recognition:recognition_frequency',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.peersRelationship'),
          value: 'peersRelationship',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.peersRelationship:collaboration'
          ),
          value: 'peersRelationship:collaboration',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.peersRelationship:trust'),
          value: 'peersRelationship:trust',
        },
        {
          text: this.$t(
            'opinionsFilter.metricFilter.peersRelationship:communication'
          ),
          value: 'peersRelationship:communication',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.feedback'),
          value: 'feedback',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.feedback:suggestions'),
          value: 'feedback:suggestions',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.feedback:quality'),
          value: 'feedback:quality',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.feedback:frequency'),
          value: 'feedback:frequency',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.diversity'),
          value: 'diversity',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.psychologicalSafety'),
          value: 'psychologicalSafety',
        },
        {
          text: this.$t('opinionsFilter.metricFilter.burnout'),
          value: 'burnout',
        },
      ],
      ratedScoreList: [
        {
          text: this.$t('opinionsFilter.ratedScoreFilter.very-unhappy '),
          value: 'very-unhappy ',
        },
        {
          text: this.$t('opinionsFilter.ratedScoreFilter.unhappy'),
          value: 'unhappy',
        },
        {
          text: this.$t('opinionsFilter.ratedScoreFilter.no-comments'),
          value: 'no-comments',
        },
        {
          text: this.$t('opinionsFilter.ratedScoreFilter.good'),
          value: 'good',
        },
        {
          text: this.$t('opinionsFilter.ratedScoreFilter.love-it'),
          value: 'love-it',
        },
      ],
      debounceTimeOut: null,
    }
  },
  computed: {
    _fromToDateRangeText() {
      const formattedDate = this.fromToDateArray.map((date) => {
        return moment(date).format('DD/MM/YYYY')
      })

      return formattedDate.join(' - ')
    },
    _listTagsForSelect() {
      return this.tags.map((tag) => ({
        text: tag.label,
        value: tag.id,
      }))
    },
    _listQuestionsForSelect() {
      return this.questions.map((question) => ({
        text: question.text,
        value: question.questionID,
      }))
    },

    _hasFilterTags() {
      return this.$can('access', _permissions.engagement_opinions_tags)
    },
  },
  methods: {
    clearFromToDate() {
      this.fromToDateArray = []
      this.onInputChanges()
    },
    removeTag(value) {
      const index = this.tagsIDs.indexOf(value)
      this.tagsIDs.splice(index, 1)
      this.onInputChanges()
    },
    onInputChanges() {
      clearTimeout(this.debounceTimeOut)

      this.debounceTimeOut = setTimeout(() => {
        this.sendFilterValues()
      }, 800)
    },
    sendFilterValues() {
      const filterValues = {
        metricID: this.metricID,
        questionID: this.questionID,
        reply: this.reply,
        readed: this.readed,
        closed: this.closed,
        rated: this.rated,
        ratedScore: this.ratedScore,
        feeling: this.feeling,
        fromDate: this.fromToDateArray[0],
        toDate: this.fromToDateArray[1],
        tagsID: this.tagsIDs,
      }

      this.$root.$emit('opinions:filter-changes', filterValues)
    },

    handleFilter(event) {
      this.metricID = event.metricID
      this.onInputChanges()
    },
  },
  beforeMount() {
    this.$root.$on('set-filter:opinions', this.handleFilter)
  },
  beforeDestroy() {
    this.$root.$off('set-filter:opinions', this.handleFilter)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
