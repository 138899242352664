var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test-sidebar-action-plans":""}},[_c('LateralMenuItem',{attrs:{"title":_vm.$t('actionPlans.title'),"isMini":_vm.isMini,"howToType":"sidebarActionPlans"},scopedSlots:_vm._u([{key:"title-icon",fn:function({ size }){return [_c('v-icon',{attrs:{"size":size}},[_vm._v("mdi-text")])]}},(_vm._canCreate)?{key:"header-action",fn:function(){return [_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"icon":"","small":"","data-test-create-action-plan":""},on:{"click":_vm.createActionPlan}},[_c('i',{staticClass:"fi fi-br-plus"})])]},proxy:true}:null],null,true)},[_c('div',{attrs:{"id":"actionPlansContent","data-test-action-plans-content":""}},[(_vm.locked)?_c('div',{staticClass:"blocked",attrs:{"data-test-action-plans-blocked":""},on:{"click":function($event){return _vm.callUpdateYourPackage()}}},[_c('div',{staticClass:"glass"}),_c('div',{staticClass:"blocked-icon"},[_c('i',{staticClass:"fi fi-sr-lock"})])]):_vm._e(),_vm._l((_vm._actionPlansList),function(plan){return _c('div',{key:plan.id,staticClass:"action-plan-container",attrs:{"data-test-action-plan-item":""}},[_c('div',{staticClass:"action-plan-content-container"},[_c('h3',{domProps:{"textContent":_vm._s(plan.title)}}),_c('p',{attrs:{"data-test-action-plan-metric":""}},[_vm._v(" "+_vm._s(_vm.$t('actionPlans.pillar'))+": "),_c('span',[_vm._v(_vm._s(plan.metric.label))])]),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('actionPlans.initial'))}}),_c('div',{class:[
                    'action-plan-score',
                    _vm.getScoreClass(
                      plan.progress.initial,
                      plan.type,
                      plan.metric.id
                    ),
                  ],attrs:{"data-test-action-plan-initial":""},domProps:{"textContent":_vm._s(_vm.formatScore(plan.progress.initial, plan.type))}})])]),_c('v-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('actionPlans.current'))}}),_c('div',{class:[
                    'action-plan-score',
                    _vm.getScoreClass(
                      plan.progress.current,
                      plan.type,
                      plan.metric.id
                    ),
                  ],attrs:{"data-test-action-plan-current":""},domProps:{"textContent":_vm._s(_vm.formatScore(plan.progress.current, plan.type))}})])]),_c('v-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('actionPlans.goal'))}}),_c('div',{class:[
                    'action-plan-score',
                    _vm.getScoreClass(
                      plan.progress.goal,
                      plan.type,
                      plan.metric.id
                    ),
                  ],attrs:{"data-test-action-plan-goal":""},domProps:{"textContent":_vm._s(_vm.formatScore(plan.progress.goal, plan.type))}})])]),_c('v-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('actionPlans.tasks'))}}),_c('div',{staticClass:"action-plan-tasks",attrs:{"data-test-action-plan-tasks":""}},[_c('span',[_vm._v(" "+_vm._s(plan.tasks.completed)+" ")]),_vm._v(" / "+_vm._s(plan.tasks.total)+" ")])])])],1)],1),_c('div',{staticClass:"action-plan-edit-container"},[_c('v-btn',{attrs:{"icon":"","data-test-edit-action-plan":""},on:{"click":() => _vm.editActionPlan(plan)}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("mdi-pencil")])],1)],1)])}),(_vm._actionPlansList.length === 0 && !_vm.showLoading)?_c('div',{staticClass:"empty-action-plan",attrs:{"data-test-empty-action-plans":""}},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t('actionPlans.emptyActionPlansDesc'))}}),(_vm._canCreate)?_c('a',{attrs:{"href":"#","data-test-empty-create-action-plan":""},domProps:{"textContent":_vm._s(_vm.$t('actionPlans.createFirstActionPlan'))},on:{"click":_vm.createActionPlan}}):_vm._e()]):_vm._e(),_c('div',[(_vm.displayShowMoreAction)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"show-more-container",attrs:{"data-test-show-more-action-plans":""},on:{"click":_vm.showMoreActionPlans}},[_vm._v(" "+_vm._s(_vm.$t('actionPlans.showMore'))+" "),_c('i',{staticClass:"fi fi-sr-caret-down"})])])],1):_vm._e()],1),(_vm.showLoading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }