<template>
  <div id="auth-page">
    <div class="auth">
      <div v-if="!_isMobile" class="auth-presentation">
        <div class="auth-citation">
          <blockquote>
            <p v-text="$t('LoginPage.citation.text')"></p>
          </blockquote>
          <cite v-text="$t('LoginPage.citation.author')"></cite>
        </div>

        <v-img
          alt="TeamCulture"
          class="auth-image"
          :src="require(`@/assets/presentation/bg.png`)"
          eager
          contain
          height="50vh"
          width="50vw"
        ></v-img>
      </div>

      <div class="auth-form">
        <div class="login-page">
          <v-img
            alt="TeamCulture"
            :src="require('@/assets/logo.png')"
            class="login-logo"
            eager
            contain
            position="left center"
            max-height="1.875rem"
          ></v-img>

          <p class="login-title" v-text="$t('LoginPage.title')"></p>
          <span class="login-subtitle" v-text="$t('LoginPage.subtitle')"></span>

          <div v-if="isLoading" class="login-loading">
            <v-progress-circular
              color="primary"
              :size="50"
              indeterminate
            ></v-progress-circular>

            <div class="login-loading-container">
              <p
                class="login-loading-text"
                v-text="$t('LoginPage.loading.text')"
              ></p>
              <span
                class="login-loading-description"
                v-text="$t('LoginPage.loading.description')"
              ></span>
            </div>
          </div>

          <div v-if="isError" class="login-error">
            <div class="login-error-icon">
              <v-icon size="30">fi-rr-cross-circle</v-icon>
            </div>

            <div class="login-error-container">
              <p class="login-error-text" v-text="_error_text"></p>
              <span
                class="login-error-description"
                v-html="_error_description"
              ></span>
            </div>
          </div>

          <div v-if="!isLoading" class="login-back">
            <v-btn
              :href="_dashboardLink"
              x-large
              width="100%"
              class="login-sso-btn primary"
            >
              <span v-text="$t('LoginPage.back')"></span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projectAuth, signInWithCustomToken } from '@/firebase/config'
import { fetchAuthenticationToken } from '@/service/authentication-legacy'

import { calcLanguageToLocale } from '@/helpers/locale'

export default {
  name: 'LoginPage',
  inject: ['changeLanguage'],

  data() {
    return {
      isError: false,
      isLoading: false,

      customError: {
        text: 'LoginPage.error.text',
        description: 'LoginPage.error.description',
      },
    }
  },

  computed: {
    _isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    _dashboardLink() {
      return `${process.env.VUE_APP_ENGAGEMENT_LEGACY_LINK}/dashboard`
    },

    _error_text() {
      return this.$t(this.customError.text)
    },
    _error_description() {
      return this.$t(this.customError.description)
    },
  },

  beforeMount() {
    const { l: language = false } = this.$route.query

    if (language) {
      const lang = calcLanguageToLocale(language)
      this.changeLanguage(lang)
    }
  },

  async mounted() {
    const { t: token = null } = this.$route.query

    if (!token) {
      return Promise.resolve()
    }

    localStorage.setItem('teamculture:token', token)
    return await this.customTokenLogin(token)
  },

  methods: {
    customErrorMessage(error) {
      this.isError = true

      const defaultMessage = {
        text: 'LoginPage.error.text',
        description: 'LoginPage.error.description',
      }

      this.customError.text = error?.text || defaultMessage.text
      this.customError.description =
        error?.description || defaultMessage.description
    },

    async customTokenLogin(token) {
      try {
        this.isLoading = true
        this.isError = false

        const { customToken } = await fetchAuthenticationToken(token)

        projectAuth.tenantId = process.env.VUE_APP_FB_TENANTID

        // signout from existing account
        await projectAuth.signOut()

        return signInWithCustomToken(projectAuth, customToken)
          .then((_userCredential) => {
            // Signed in
            // const user = userCredential.user
            // console.log({ user })
            // console.log({ userCredential })
          })
          .catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            console.log({ errorCode })
            console.log({ errorMessage })
            console.log({ error })
          })
          .finally(() => {
            this.isLoading = false
          })
      } catch (_) {
        this.isLoading = false
        this.customErrorMessage()
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
