<template>
  <div data-test-internal-ranking-page>
    <v-row v-if="!isLoading" class="row-benchmark-ranking">
      <v-col sm="12" md="6" lg="6">
        <RankingCard
          :type="'climate'"
          :title="
            $t('engagementPage.benchmarkCards.generalClimateAverage.title')
          "
          :headers="RankingCardHeaders"
          :ranking="_RankingClimate"
          :currentRanking="benchmarkRanking.climate.currentRanking"
        ></RankingCard>
      </v-col>

      <v-col sm="12" md="6" lg="6">
        <RankingCard
          :type="'enps'"
          :title="$t('engagementPage.benchmarkCards.enps.title')"
          :headers="RankingCardHeaders"
          :ranking="_RankingEnps"
          :currentRanking="benchmarkRanking.enps.currentRanking"
        ></RankingCard>
      </v-col>

      <v-col sm="12" md="6" lg="6">
        <RankingCard
          :type="'lnps'"
          :title="$t('engagementPage.benchmarkCards.lnps.title')"
          :headers="RankingCardHeaders"
          :ranking="_RankingLnps"
          :currentRanking="benchmarkRanking.lnps.currentRanking"
        ></RankingCard>
      </v-col>

      <v-col sm="12" md="6" lg="6">
        <RankingCard
          :type="'maturityLevel'"
          :title="$t('engagementPage.benchmarkCards.culturalMaturity.title')"
          :headers="RankingCardHeaders"
          :ranking="_RankingMaturityLevel"
          :currentRanking="benchmarkRanking.maturityLevel.currentRanking"
        ></RankingCard>
      </v-col>

      <v-col sm="12" md="6" lg="6">
        <RankingCard
          :type="'climate-pillars'"
          :title="$t('engagementPage.benchmarkCards.climatePillars.title')"
          :headers="RankingCardHeaders"
          :ranking="_RankingClimatePillars.ranking"
          :currentRanking="_RankingClimatePillars.currentRanking"
          :pillarsItens="_benchmarkRankingIndicators.slice(4, 14)"
          :currentPillar="benchmarkIndicators.climatePillars"
          @selectPillar="handleClimatePillars"
        ></RankingCard>
      </v-col>

      <v-col sm="12" md="6" lg="6">
        <RankingCard
          :type="'smart-pillars'"
          :title="$t('engagementPage.benchmarkCards.smartPillars.title')"
          :headers="RankingCardHeaders"
          :ranking="_RankingSmartPillars.ranking"
          :currentRanking="_RankingSmartPillars.currentRanking"
          :pillarsItens="_benchmarkRankingIndicators.slice(14, 17)"
          :currentPillar="benchmarkIndicators.smartPillars"
          @selectPillar="handleSmartPillars"
        ></RankingCard>
      </v-col>
    </v-row>

    <SkeletonLoader v-else class="row-benchmark-ranking"></SkeletonLoader>
  </div>
</template>

<script>
import SkeletonLoader from '@/pages/EngagementPage/Tabs/Benchmark/Partials/Skeleton/Skeleton.vue'
import RankingCard from '@/pages/EngagementPage/Tabs/Benchmark/Partials/RankingCard/RankingCard.vue'

import {
  getBenchmarkInternalRankingResults,
  getBenchmarkInternalRankingVariation,
} from '@/service/benchmark'

export default {
  name: 'InternalRankingPage',
  components: {
    SkeletonLoader,
    RankingCard,
  },

  props: {
    groupID: {
      type: String,
      default: '',
    },
    indicatorID: {
      type: String,
      default: 'climate',
    },
    variationPeriod: String,
  },

  data() {
    return {
      benchmarkRanking: {
        climate: {},
        enps: {},
        lnps: {},
        maturityLevel: {},
        all: [],
      },

      benchmarkVariation: {
        climate: {},
        enps: {},
        lnps: {},
        maturityLevel: {},
        all: [],
      },

      benchmarkIndicators: {
        climatePillars: 'managerRelationship',
        smartPillars: 'diversity',
      },

      filters: {},

      isLoading: false,
      RankingCardHeaders: [
        {
          text: this.$t('engagementPage.benchmarkCards.rankingHeaders.rank'),
          align: 'start',
          sortable: false,
          value: 'position',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'variation',
        },
        {
          text: this.$t('engagementPage.benchmarkCards.rankingHeaders.group'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('engagementPage.benchmarkCards.rankingHeaders.size'),
          align: 'start',
          value: 'teamSize',
          sortable: false,
        },
        {
          text: this.$t('engagementPage.benchmarkCards.rankingHeaders.note'),
          align: 'start',
          value: 'score',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    _benchmarkRankingIndicators() {
      return this.benchmarkRanking.all
        .filter((item) => item !== undefined)
        .map((item) => ({
          id: item.indicatorID,
          label: this.$t(
            `engagementPage.benchmarkCards.metricLabels.${item.indicatorID}`
          ),
        }))
    },

    _RankingClimate() {
      return this.calcRankingWithVariation(
        this.benchmarkRanking.climate,
        this.benchmarkVariation.climate
      )
    },
    _RankingEnps() {
      return this.calcRankingWithVariation(
        this.benchmarkRanking.enps,
        this.benchmarkVariation.enps
      )
    },
    _RankingLnps() {
      return this.calcRankingWithVariation(
        this.benchmarkRanking.lnps,
        this.benchmarkVariation.lnps
      )
    },
    _RankingMaturityLevel() {
      return this.calcRankingWithVariation(
        this.benchmarkRanking.maturityLevel,
        this.benchmarkVariation.maturityLevel
      )
    },

    _RankingClimatePillars() {
      return this.getBenchmarkIndicators(
        this.benchmarkIndicators.climatePillars
      )
    },
    _RankingSmartPillars() {
      return this.getBenchmarkIndicators(this.benchmarkIndicators.smartPillars)
    },
  },

  methods: {
    handleClimatePillars(indicatorID) {
      this.benchmarkIndicators.climatePillars = indicatorID
    },
    handleSmartPillars(indicatorID) {
      this.benchmarkIndicators.smartPillars = indicatorID
    },

    getBenchmarkIndicators(indicatorID) {
      const Ranking = this.benchmarkRanking.all.find(
        (ranking) => ranking.indicatorID === indicatorID
      )
      if (!Ranking) {
        return {
          ranking: [],
          currentRanking: {},
        }
      }

      const Variation = this.benchmarkVariation.all.find(
        (variation) => variation.indicatorID === indicatorID
      )

      return {
        ranking: this.calcRankingWithVariation(Ranking, Variation),
        currentRanking: Ranking.currentRanking,
      }
    },

    calcRankingItem(rankingItem) {
      const DefaultEmpty = '--'

      const showDefaultOnUndefined = (value) => {
        if ([undefined, null].includes(value)) {
          return DefaultEmpty
        }

        return value
      }

      return {
        ...rankingItem,
        position: showDefaultOnUndefined(rankingItem?.position),
        score: showDefaultOnUndefined(rankingItem?.score),
      }
    },

    calcRankingWithVariation(
      { currentRanking = {}, ranking = [] },
      {
        currentRanking: currentRankingVariation = {},
        ranking: rankingVariation = [],
      }
    ) {
      const currentRankingObj = this.calcRankingItem({
        ...currentRankingVariation,
        ...currentRanking,
      })

      if (!currentRanking?.id && !ranking?.length) {
        return []
      }

      if (!ranking?.length) {
        return [currentRankingObj]
      }

      const result = ranking
        .map((item) => {
          const rankingItem = {
            ...rankingVariation.find(
              (element) => element.position === item.position
            ),
            ...item,
          }

          return this.calcRankingItem(rankingItem)
        })
        .sort((a, b) => a.position - b.position)

      if (!result.find((item) => item.id === currentRankingObj.id)) {
        result.push(currentRankingObj)
      }

      return result
    },

    async fetchBenchmarkRanking(groupID = this.groupID) {
      const { data } = await getBenchmarkInternalRankingResults(
        groupID,
        this.filters
      )

      if (!data) {
        this.benchmarkRanking = {
          climate: [],
          enps: [],
          lnps: [],
          maturityLevel: [],
          all: [],
        }

        return
      }

      this.benchmarkRanking.all = data

      const indicatorIDs = ['climate', 'enps', 'lnps', 'maturityLevel']
      indicatorIDs.forEach((indicatorID) => {
        const indicatorData = data.find(
          (item) => item.indicatorID === indicatorID
        )
        this.benchmarkRanking[indicatorID] = indicatorData
      })
    },

    async fetchBenchmarkVariationRanking(
      groupID = this.groupID,
      variationPeriod = this.variationPeriod,
      filters = this.filters
    ) {
      const { data } = await getBenchmarkInternalRankingVariation(
        groupID,
        variationPeriod,
        filters
      )

      if (!data) {
        this.benchmarkVariation = {
          climate: [],
          enps: [],
          lnps: [],
          maturityLevel: [],
          all: [],
        }

        return
      }

      this.benchmarkVariation.all = data

      const indicatorIDs = ['climate', 'enps', 'lnps', 'maturityLevel']
      indicatorIDs.forEach((indicatorID) => {
        const indicatorData = data.find(
          (item) => item.indicatorID === indicatorID
        )
        this.benchmarkVariation[indicatorID] = indicatorData
      })
    },

    async fetchRankings() {
      this.isLoading = true

      try {
        await this.fetchBenchmarkVariationRanking()
        await this.fetchBenchmarkRanking()

        this.handleClimatePillars(this.benchmarkIndicators.climatePillars)
        this.handleSmartPillars(this.benchmarkIndicators.smartPillars)
      } finally {
        this.isLoading = false
      }
    },

    handleFilterListener(values) {
      this.$set(this, 'filters', structuredClone(values))
    },
  },

  watch: {
    groupID() {
      this.fetchRankings()
    },
    filters() {
      this.fetchRankings()
    },
    variationPeriod() {
      this.fetchRankings()
    },
  },

  created() {
    this.$store.commit('setCurrentTab', 'BenchmarkPage')

    this.fetchRankings()

    this.$root.$on(
      'market-segment-and-team-size:filter-changes',
      this.handleFilterListener
    )
  },
  beforeDestroy() {
    this.$root.$off(
      'market-segment-and-team-size:filter-changes',
      this.handleFilterListener
    )
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
