<template>
  <LoaderMap v-if="loading" id="loadermap"></LoaderMap>

  <v-card v-else id="heatmap" flat>
    <!-- header -->
    <v-row class="header-content" align="center" no-gutters>
      <!-- slots -->
      <v-col cols="12" md="6" lg="8" xl="10" class="mb-4 mb-md-0">
        <!-- slots -->
        <div class="d-flex flex-column">
          <div class="d-flex align-center">
            <h1 class="neutral1--text header-title">
              <slot name="title"></slot>
            </h1>

            <div class="how-to-container">
              <HowToBeginAndWatchAVideo
                videoType="comparison.groupsComparation"
              ></HowToBeginAndWatchAVideo>
            </div>
          </div>

          <h2 class="neutral4--text header-subtitle">
            <slot name="subtitle"></slot>
          </h2>
        </div>
      </v-col>

      <!-- mode -->
      <v-col cols="12" md="6" lg="4" xl="2" class="mb-4 mb-md-0">
        <!-- mode -->
        <div class="d-flex">
          <v-spacer></v-spacer>

          <v-btn
            class="pontuation-bttn text-none rounded-lg mr-2"
            width="6.5625rem"
            height="2.375rem"
            :color="_pontuationActiveColor"
            :text="mode !== 'pontuation'"
            outlined
            large
            @click="updateMode('pontuation')"
          >
            <span
              class="mode-btts-label"
              v-text="$t('heatmap.pontuation')"
            ></span>
          </v-btn>

          <v-menu
            v-if="showVariation || lockVariation"
            class="variation-bttn"
            content-class="mt-2 elevation-neutral rounded-lg"
            max-width="300"
            offset-y
            z-index="99"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="text-none rounded-lg"
                width="6.5625rem"
                height="2.375rem"
                :color="_variationActiveColor"
                :text="mode !== 'variation'"
                outlined
                large
              >
                <span
                  class="mode-btts-label"
                  data-test-button-variation
                  v-text="$t('heatmap.variation')"
                ></span>

                <v-icon class="ml-1" small>fi fi-rr-caret-down</v-icon>
              </v-btn>
            </template>

            <v-card class="rounded-lg" elevation="0">
              <v-radio-group
                v-model="type"
                class="ma-0"
                column
                hide-details
                :readonly="lockVariation"
                @change="updateMode('variation')"
              >
                <template v-for="(item, index) in types">
                  <v-radio
                    data-test-options-value
                    color="primary"
                    class="px-4 py-2 mb-0"
                    :class="{
                      'custom-radio-active': type === item.value,
                      'custom-radio-inactive': type !== item.value,
                    }"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                    @click="callUpdateYourPackage"
                  >
                    <template v-slot:label>
                      {{ item.label }}

                      <v-icon
                        v-if="lockVariation"
                        class="upgrade-icon"
                        color="secondary"
                        size="0.875rem"
                      >
                        fi-sr-crown
                      </v-icon>
                    </template>
                  </v-radio>
                </template>
              </v-radio-group>
            </v-card>
          </v-menu>
        </div>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <!-- content -->
    <div class="d-flex flex-column pa-4">
      <!-- heat map -->
      <section class="mb-4 hz-scroll">
        <div class="d-flex">
          <section class="d-flex flex-column indicator-spacing">
            <!-- collapse -->
            <div class="d-flex mb-1">
              <v-spacer></v-spacer>

              <v-btn class="text-none" text @click="mini = !mini" small>
                <v-icon v-if="mini" class="mr-1" color="grey" small>
                  fi fi-rr-arrow-small-right
                </v-icon>

                <v-icon v-if="!mini" class="mr-1" color="grey" small>
                  fi fi-rr-arrow-small-left
                </v-icon>

                <span
                  v-if="!mini"
                  class="grey--text recall-label"
                  v-text="$t('heatmap.recall')"
                ></span>
              </v-btn>
            </div>

            <!-- indicators -->
            <div
              v-for="(item, index) in activeIndicators"
              id="indicators"
              class="m-bottom-row"
              :key="index"
            >
              <Indicator
                :indicator="item"
                :open="item.open"
                :mini="mini"
                @handle-open="item.open = !item.open"
                @reveal-children="addIndicators($event)"
                @hide-children="removeIndicators($event)"
              ></Indicator>
            </div>
          </section>

          <!-- columns -->
          <section class="d-flex">
            <div
              v-for="(item, index) in activeColumns"
              class="m-left"
              :key="index"
            >
              <!-- column header -->
              <div class="m-bottom" id="columns">
                <Column
                  :column="item.column"
                  :open="item.open"
                  v-on="$listeners"
                  @handle-open="item.open = !item.open"
                  @hide-children="removeColumns($event)"
                ></Column>
              </div>

              <!-- notes of column -->
              <div
                v-for="(note, n) in handleNotes(item.indicators)"
                class="m-bottom-row cells"
                :key="n"
              >
                <Heatcell
                  id="heatcell"
                  :column="item.column"
                  :mode="mode"
                  :note="note.value"
                  :indicator="note.indicator"
                  :lock="item.column.id === 'market' && lockMarket"
                ></Heatcell>
              </div>
            </div>
          </section>
        </div>
      </section>

      <!-- footer -->
      <div class="d-flex align-center">
        <!-- subtitle -->
        <div class="d-flex flex-column">
          <span
            class="legend-title neutral1--text mb-2"
            v-text="$t('heatmap.subtitle')"
          ></span>

          <div class="d-flex">
            <v-sheet
              v-for="(item, i) in subtitle[mode]"
              class="legend-chip text-center"
              :key="i"
              :color="item.color"
              label
              dark
            >
              <div class="d-flex align-center justify-center fill-height">
                <span
                  class="white--text legend-label"
                  v-text="item.label"
                ></span>
              </div>
            </v-sheet>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Column from './Parts/Column/Column'
import Heatcell from './Parts/Heatcell/Heatcell'
import Indicator from './Parts/Indicator/Indicator'
import LoaderMap from './Parts/LoaderMap/LoaderMap'

import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'HeatMap',

  components: {
    Indicator,
    Heatcell,
    Column,
    LoaderMap,
    HowToBeginAndWatchAVideo,
  },

  props: {
    indicators: [],
    columns: [],
    lockMarket: {
      type: Boolean,
      default: false,
    },
    lockVariation: {
      type: Boolean,
      default: false,
    },
    showVariation: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: true,
      mini: false,

      activeIndicators: [],
      activeColumns: [],
      removeList: [],

      type: '',
      types: [
        { label: this.$t('heatmap.varOptions.byDate'), value: 'by-date' },
        { label: this.$t('heatmap.varOptions.byAccount'), value: 'by-account' },
        { label: this.$t('heatmap.varOptions.byMarket'), value: 'by-market' },
      ],

      mode: 'pontuation',
      subtitle: {
        pontuation: [
          {
            label: this.$t('heatmap.subtitles.low'),
            color: variables.redColor2,
          },
          {
            label: this.$t('heatmap.subtitles.average'),
            color: variables.yellowColor1,
          },
          {
            label: this.$t('heatmap.subtitles.high'),
            color: variables.greenColor,
          },
        ],
        variation: [
          {
            label: this.$t('heatmap.subtitles.veryLow'),
            color: variables.redColor6,
          },
          {
            label: this.$t('heatmap.subtitles.low'),
            color: variables.redColor2,
          },
          {
            label: this.$t('heatmap.subtitles.high'),
            color: variables.greenColor,
          },
          {
            label: this.$t('heatmap.subtitles.veryHigh'),
            color: variables.greenColor2,
          },
        ],
      },
    }
  },

  computed: {
    _pontuationActiveColor() {
      return this.mode === 'pontuation' ? 'primary' : 'neutral4'
    },

    _variationActiveColor() {
      return this.mode === 'variation' ? 'primary' : 'neutral4'
    },
  },

  methods: {
    async renderMap() {
      this.loading = true

      await this.startIndicators()
      await this.startColumns()

      this.loading = false
    },

    async startIndicators() {
      this.activeIndicators = await this.indicators.map((e) => {
        return {
          ...e,
          open: false,
        }
      })
    },

    async startColumns() {
      let columns = this.columns

      await columns.forEach((e) => {
        if (!!e.column.children && e.column.children.length > 0) {
          for (let i = 0; i < e.column.children.length; i++) {
            const id = e.column.children[i]

            if (this.columns.filter((j) => j.column.id === id).length > 0) {
              e.open = true
              break
            }
          }
        }
      })

      this.activeColumns = columns
    },

    // returns the grades of the column of a indicator
    handleNotes(values) {
      return this.activeIndicators.map((e) => {
        return { indicator: e.id, value: values[e.id] }
      })
    },

    // opening subindicator, adding from array
    addIndicators(id) {
      for (let i = 0; i < this.indicators.length; i++) {
        const e = this.indicators[i]

        if (e.id === id) {
          const index = this.activeIndicators.findIndex((e) => e.id === id)

          e.submetrics.forEach((sub) => {
            this.activeIndicators.splice(index + 1, 0, sub)
          })

          break
        }
      }
    },

    // closing subindicator, removing from array
    removeIndicators(id) {
      this.removeList = []

      this.activeIndicators.forEach((e) => {
        if (e.id.includes(`${id}:`)) {
          this.removeList.push(e.id)
        }
      })

      this.activeIndicators = this.activeIndicators.filter(
        (e) => !this.removeList.includes(e.id)
      )
    },

    // opening subcolumn, adding from array
    addColumns(values, parentId) {
      if (!values || values.length < 1) return

      values.forEach((e) => {
        for (let index = 0; index < this.activeColumns.length; index++)
          if (this.activeColumns[index].column.id === parentId) {
            this.activeColumns.splice(index + 1, 0, e)
            break
          }
      })
    },

    // closing subcolumn, removing from array
    async removeColumns(parentId) {
      this.removeList = []

      await this.deepChildrens(parentId)

      this.activeColumns = this.activeColumns.filter(
        (e) => !this.removeList.includes(e.column.id)
      )
    },

    deepChildrens(id) {
      this.activeColumns.forEach((e) => {
        if (!!e.column.parents && e.column.parents.includes(id)) {
          this.removeList.push(e.column.id)

          if (!!e.column.children && e.column.children.length > 0) {
            this.removeList = this.removeList.concat(e.children)
            this.deepChildrens(e.column.id)
          }
        }
      })
    },

    // emit selected mode
    async updateMode(modeValue) {
      this.mode = modeValue

      if (this.mode === 'pontuation') {
        this.type = ''
      }

      this.loading = true

      const payload = {
        mode: this.mode,
        type: this.type,
        actives: await this.activeColumns.map((e) => e.column.id),
      }

      this.$emit('mode-update', payload)
    },

    callUpdateYourPackage() {
      if (this.lockVariation) {
        this.$emit('callUpdateYourPackage')
      }
    },
  },

  watch: {
    columns() {
      this.renderMap()
    },

    indicators() {
      this.renderMap()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
