<template>
  <div id="benchmark-chart">
    <div class="progress-bar">
      <div class="progress-bar-indexes">
        <span class="progress-bar-indexes__lower">0</span>
        <span class="progress-bar-indexes__higher">100</span>
      </div>
      <div class="progress-bar-wrapper">
        <div class="progress-bar-item"></div>
        <div class="progress-bar-item"></div>
        <div class="progress-bar-item"></div>
      </div>
      <div
        id="marker-group"
        v-if="isValid(groupMarker)"
        class="progress-bar-marker"
        :style="{ left: `${groupMarker}%` }"
      >
        <div class="marker-container">
          <div class="marker-container-icon account">
            <i class="fi fi-rr-caret-right"></i>
          </div>
          <div class="marker-container-bar"></div>
        </div>
      </div>
      <div
        id="marker-account"
        v-if="isValid(accountMarker)"
        class="progress-bar-marker"
        :style="{ left: `${accountMarker}%` }"
      >
        <div class="marker-container">
          <div class="marker-container-icon">
            <i class="fi fi-rr-building"></i>
          </div>
          <div class="marker-container-bar"></div>
        </div>
      </div>
      <div
        id="marker-market"
        v-if="isValid(marketMarker)"
        class="progress-bar-marker"
        :style="{ left: `${marketMarker}%` }"
      >
        <div class="marker-container">
          <div class="marker-container-icon">
            <i class="fi fi-rr-flag"></i>
          </div>
          <div class="marker-container-bar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BenchmarkProgressBar',
  props: {
    groupMarker: {
      type: Number,
    },
    accountMarker: {
      type: Number,
    },
    marketMarker: {
      type: Number,
    },
  },
  methods: {
    isValid(val) {
      if (!val) {
        return false
      }

      if (val < 1 || val > 100) {
        return false
      }

      return true
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
