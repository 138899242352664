<template>
  <div v-if="show" data-test-add-culture-value>
    <SideBar :autoSaveLabel="autoSaveLabel" @close="close()" @back="close()">
      <template v-slot:title>{{ currentTitle }}</template>

      <template v-slot:body>
        <v-form v-model="valid" ref="form" v-on:submit.prevent>
          <div class="form-content-section pt-5">
            <v-text-field
              data-test-manage-culture-form-name
              v-model="form.name"
              class="custom-input"
              :label="$t('cultureManagement.manageCultureValue.name')"
              :rules="rules"
              outlined
              @keyup="handleChangeName()"
            />

            <v-textarea
              data-test-manage-culture-form-description
              v-model="form.description"
              class="custom-textarea"
              row-height="40"
              rows="2"
              :label="$t('cultureManagement.manageCultureValue.description')"
              auto-grow
              outlined
              @keyup="handleChangeDescription()"
            />
          </div>

          <div class="form-content-header">
            <span
              class="form-content-header-title"
              v-text="$t('cultureManagement.manageCultureValue.titleBehaviors')"
            ></span>
          </div>

          <div class="form-content-section">
            <!-- added behaviors -->
            <v-data-table
              class="custom-table mb-2"
              :no-data-text="
                $t('cultureManagement.manageCultureValue.emptyBehaviors')
              "
              :headers="headers"
              :items="form.behaviors"
              hide-default-footer
              disable-pagination
              disable-sort
              dense
            >
              <template v-slot:[`item.name`]="{ item }">
                <div class="behavior-item">
                  <v-img
                    :src="item.image"
                    max-width="22px"
                    max-height="22px"
                    width="22px"
                    contain
                  />

                  <span
                    data-test-table-behavior-name
                    class="behavior-label"
                    v-text="item.name"
                  ></span>
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <i
                  data-test-btn-delete-behavior
                  data-test-remove-behavior
                  class="fi fi-rr-cross-circle behavior-action-remove"
                  @click="removeBehavior(item.id)"
                />
              </template>
            </v-data-table>

            <!-- insert behaviors -->
            <v-autocomplete
              data-test-manage-culture-form-behaviors
              v-model="modelComplete"
              class="auto-complete custom-input"
              attach
              append-icon=""
              :items="_behaviors"
              item-text="name"
              item-value="id"
              :menu-props="{ offsetY: true, top: true }"
              :no-data-text="
                $t('cultureManagement.manageCultureValue.noResultFound')
              "
              :label="$t('cultureManagement.manageCultureValue.behavior')"
              return-object
              outlined
              filled
              @change="addBehavior($event)"
            >
              <template v-slot:item="{ item }">
                <div class="behavior-item">
                  <v-img
                    :src="item.image"
                    max-width="26px"
                    max-height="26px"
                    width="26px"
                    contain
                  />

                  <span
                    class="behavior-label"
                    data-test-manage-culture-form-behavior-label
                    v-text="item.name"
                  ></span>

                  <v-tooltip top max-width="18.75rem">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="tooltip-icon">
                        <v-icon
                          class="fi-rr-interrogation"
                          size="0.75rem"
                        ></v-icon>
                      </span>
                    </template>

                    <div>
                      <span v-text="item.description"></span>
                    </div>
                  </v-tooltip>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </v-form>
      </template>

      <template v-slot:footer>
        <div class="culture-value-footer">
          <v-btn
            v-if="editMode"
            data-test-btn-delete-card-culture-value
            plain
            class="action-remove"
            @click="removeValue()"
          >
            <v-icon size="16">fi-rr-trash</v-icon>
            <span
              v-text="$t('cultureManagement.manageCultureValue.remove')"
            ></span>
          </v-btn>

          <v-spacer />

          <v-btn
            data-test-manage-culture-form-close
            plain
            class="action-close"
            @click="close()"
          >
            <span
              v-text="$t('cultureManagement.manageCultureValue.back')"
            ></span>
          </v-btn>

          <v-btn
            v-if="!editMode"
            data-test-manage-culture-form-submit
            class="action-save-new"
            elevation="0"
            @click="submit()"
          >
            <span
              v-text="$t('cultureManagement.manageCultureValue.save')"
            ></span>
          </v-btn>
        </div>
      </template>
    </SideBar>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar/SideBar.vue'

export default {
  data() {
    return {
      show: false,
      interval: null,
      valid: false,
      editMode: false,
      valueId: null,
      valueIndex: null,
      headers: [
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'Ações',
          value: 'actions',
          width: '3rem',
          align: 'center',
        },
      ],
      modelComplete: [],
      form: {
        name: '',
        description: '',
        behaviors: [],
      },
    }
  },

  props: {
    behaviors: {
      type: Array,
      require: true,
    },

    autoSaveLabel: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SideBar,
  },

  computed: {
    rules() {
      return [(v) => !!v || 'Campo obrigatório']
    },

    currentTitle() {
      return this.editMode ? 'Detalhe de um valor' : 'Adicionar um novo valor'
    },

    _behaviors() {
      const actualBehaviors = this.form.behaviors.map((e) => e.id)

      return this.behaviors.filter((e) => {
        return !actualBehaviors.includes(e.id)
      })
    },
  },

  methods: {
    openDrawer(event) {
      if (event) {
        if (event.id) this.valueId = event.id
        this.valueIndex = event.index

        this.form = {
          name: event.name,
          description: event.description,
          behaviors: event.behaviors,
        }

        this.editMode = true
      }

      this.show = true
    },

    submit() {
      if (!this.$refs.form.validate()) return

      const payload = {
        index: this.valueIndex,
        value: this.valueId
          ? { id: this.valueId, ...this.form }
          : { ...this.form },
      }

      if (this.editMode) {
        this.$emit('update:value', payload)
        return
      }

      this.$emit('create:value', payload)
      this.close()
    },

    handleChangeName() {
      if (!this.editMode || !this.form.name) return

      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.submit()
      }, 500)
    },

    handleChangeDescription() {
      if (!this.editMode || !this.form.description) return

      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.submit()
      }, 500)
    },

    addBehavior(event) {
      this.modelComplete = []
      this.form.behaviors.push(event)
      if (this.editMode) this.submit()
    },

    removeBehavior(id) {
      const index = this.form.behaviors.findIndex((e) => e.id === id)

      if (index >= 0) {
        this.form.behaviors.splice(index, 1)
        if (this.editMode) this.submit()
      }
    },

    async removeValue() {
      this.$emit('remove:value', this.valueIndex)
      this.close()
    },

    close() {
      this.show = false
      this.editMode = false
      this.valueId = null
      this.valueIndex = null
      this.form = {
        name: '',
        description: '',
        behaviors: [],
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
