<template>
  <v-menu
    id="groupselector"
    v-model="model"
    content-class="mt-2"
    max-width="450"
    :close-on-content-click="false"
    offset-y
    style="z-index: 999"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="mb-4">
        <Textfield
          class="group-selector-textfield"
          data-test-comboBox-group
          :value="moreSelection"
          :showAll="preferences.showAll"
          :selection="selected"
          :variationColor="variationColor"
        />
      </div>
    </template>

    <!-- menu view -->
    <v-card class="rounded-lg">
      <v-window v-model="window">
        <!-- treeview -->
        <v-window-item :value="1">
          <Treeview
            ref="treeview"
            data-test-treeview-button
            :setSelection="setSelection"
            :selectChildren="preferences.selectChildren"
            :items="
              fullGroups.filter((group) => (noAcme ? group.id && noAcme : true))
            "
            :startSelection="startSelection"
            :wiredGroup="wiredGroup"
            @show-preferences="window = 2"
            @clear="handleDefaultSelection()"
            @submit="handleSelection($event)"
          />
        </v-window-item>

        <!-- preferences -->
        <v-window-item :value="2">
          <Preferences @back="window = 1" @apply="handlePreferences" />
        </v-window-item>
      </v-window>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import Treeview from './parts/Treeview'
import Preferences from './parts/Preferences'
import Textfield from './parts/Textfield'

export default {
  data() {
    return {
      model: false,
      window: 1,
      preferences: {
        selectChildren: false,
        showAll: false,
      },
      fullSelection: [],
      moreSelection: '',
      startSelection: [],
      selected: [],
      setSelection: [],
    }
  },

  props: {
    fullGroups: null,
    variationColor: {
      type: Boolean,
      default: false,
    },
    wiredGroup: {
      type: Boolean,
      default: true,
    },
    firstDefault: {
      type: Boolean,
      default: true,
    },
    noAcme: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    Treeview,
    Preferences,
    Textfield,
  },

  watch: {
    ['preferences.showAll'](v) {
      this.handleSelection(this.fullSelection)
    },
    fullGroups() {
      if (!this.firstDefault) return

      this.handleDefaultSelection()
    },
  },

  computed: {
    ...mapState(['groupIds']),
    _placeholder() {
      if (!this.fullGroups || !this.fullGroups.length) return

      const group = this.fullGroups.find((group) => !group.id) || {
        label: null,
      }
      return group.label
    },
  },
  methods: {
    handlePreferences(payload) {
      const { selectChildren, showAll } = payload

      this.preferences.selectChildren = selectChildren
      this.preferences.showAll = showAll
    },

    handleSelection(data) {
      if (data.length < 1) {
        this.selected = []
        this.fullSelection = []
        this.moreSelection = ''
      } else this.setSelectedValue(data)
      this.model = false
      this.$emit('applyGroups', data)
    },
    handleDefaultSelection() {
      if (Array.isArray(this.fullGroups) && this.fullGroups.length) {
        this.setSelectedValue(this.firstDefault ? [this.fullGroups[0]] : [])
      }
    },

    handleStartSelection(data) {
      if (data.length < 1) return
      this.setSelectedValue(data)
      this.model = false
      this.$emit('applyGroups', data)
    },

    resetValue() {
      this.selected = []
      this.fullSelection = []
      this.moreSelection = ''
    },

    setSelectedValue(data) {
      this.fullSelection = data

      if (!this.preferences.showAll && data.length) {
        this.selected = [data[0].label]

        if (data.length > 1) {
          this.moreSelection = `${this.$t(
            'cultureManagement.groupSelector.textField.andMore'
          )} ${data.length - 1}`

          return
        }

        this.moreSelection = ''
        return
      }

      this.selected = data.map((e) => e.label)

      this.moreSelection = ''
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
