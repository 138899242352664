import { render, staticRenderFns } from "./SurveyInit.vue?vue&type=template&id=119263b5&scoped=true&"
import script from "./SurveyInit.vue?vue&type=script&lang=js&"
export * from "./SurveyInit.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=119263b5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119263b5",
  null
  
)

export default component.exports