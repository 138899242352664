const mixin = {
  methods: {
    scrollToTab(tabName, navigationId) {
      const navigationEl = document.getElementById(navigationId)
      let isOverflown = navigationEl
        ? navigationEl.scrollWidth > navigationEl.clientWidth
        : false

      if (isOverflown) {
        const tab = document.querySelector(`[value="${tabName}"]`)

        const { x: tabDistanceFromLeft, width } = tab.getBoundingClientRect()
        const totalDistanceFromLeft = tabDistanceFromLeft + width

        if (totalDistanceFromLeft > navigationEl.clientWidth) {
          navigationEl.scroll({ left: tabDistanceFromLeft - width })
        }
      }
    },
  },
}

export default mixin
