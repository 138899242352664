<template>
  <div
    id="behavior-classification"
    data-test-behavior-classification
    class="behavior-container behavior__card"
  >
    <div class="behavior__header">
      <div class="header">
        <div class="line">
          <span
            class="header-title"
            v-text="$t('engagementPage.behaviorClassification.title')"
          ></span>

          <HowToBeginAndWatchAVideo :videoType="videoType" />
        </div>

        <span
          class="header-subtitle"
          v-text="$t('engagementPage.behaviorClassification.subtitle')"
        ></span>
      </div>

      <div
        class="hidden-sm-and-down full-report"
        :class="{ enable: completeVision }"
        @click="handleVision"
      >
        <div class="icon">
          <i class="fi fi-br-stats"></i>
        </div>
        <span
          class="label"
          v-text="$t('engagementPage.behaviorClassification.fullReport')"
        ></span>
      </div>
    </div>

    <div class="behavior__groups">
      <CultureSelections
        data-test-present-culture
        :selectionTitle="
          $t('engagementPage.behaviorClassification.presentCulture')
        "
        selectionImage="present"
        :reliabilityRate="presentCulture.reliabilityRate"
        :selectionVotes="presentCulture.votes"
        :class="[filterActive === 'present' ? 'active' : '', 'present']"
        @click.native="filterActive = 'present'"
      />

      <CultureSelections
        data-test-cultural-alignment
        selectionImage="alignment"
        :selectionTitle="
          $t('engagementPage.behaviorClassification.culturalAlignment')
        "
        :selectionPercent="culturalAlignment.score"
        :selectionVotes="culturalAlignment.votes"
        :class="[filterActive === 'alignment' ? 'active' : '']"
        :variation="culturalAlignmentVariation"
        :showSettings="true"
        :settings="!culturalAlignment?.cultureCode?.id"
        @click.native="filterActive = 'alignment'"
      />

      <CultureSelections
        data-test-unfavorable-culture
        selectionImage="unfavorable"
        :selectionTitle="
          $t('engagementPage.behaviorClassification.unfavorableCulture')
        "
        :selectionPercent="unfavorableCulture.score"
        :selectionVotes="unfavorableCulture.votes"
        :class="[filterActive === 'unfavorable' ? 'active' : '']"
        :variation="unfavorableCultureVariation"
        @click.native="filterActive = 'unfavorable'"
      ></CultureSelections>
    </div>

    <v-alert
      v-if="!completeVision && !culturalAlignment?.cultureCode?.id"
      dense
      rounded
      color="yellowColor4"
      class="behavior__alert hidden-sm-and-down"
    >
      <template #prepend>
        <div class="icon">
          <v-icon class="fi fi-rr-exclamation" size="0.875rem"></v-icon>
        </div>
      </template>

      <template #append>
        <v-btn
          class="action"
          elevation="0"
          text
          small
          @click="handleOpenConfigureModal"
        >
          <v-icon small class="fi fi-rr-settings"></v-icon>
          <span class="label" v-text="$t('engagementPage.toSetup')"></span>
        </v-btn>
      </template>

      <span class="text" v-text="alertText"></span>
    </v-alert>

    <div class="behavior__filters">
      <v-btn-toggle
        v-show="!completeVision"
        v-model="displayList"
        class="display-pattern"
        borderless
        mandatory
      >
        <v-btn :value="false" small icon>
          <v-icon data-test-grid-view small class="fi fi-rr-grid"></v-icon>
        </v-btn>

        <v-btn :value="true" small icon>
          <v-icon
            data-test-table-view
            small
            class="fi fi-rr-align-justify"
          ></v-icon>
        </v-btn>
      </v-btn-toggle>

      <div class="display-order" data-test-order-by>
        <span
          class="label"
          v-text="$t('engagementPage.behaviorClassification.orderBy') + ':'"
        ></span>

        <v-select
          v-model="displayOrderBy"
          class="select"
          hide-details
          item-text="name"
          item-value="value"
          :items="[
            {
              name: $t('engagementPage.behaviorClassification.pontuation'),
              value: 'votes',
            },
            {
              name: $t('engagementPage.behaviorClassification.probability'),
              value: 'probability',
            },
          ]"
        ></v-select>
      </div>
    </div>

    <div
      id="behavior-classification-complete-vision"
      data-test-behavior-complete-vision
      class="behavior__content complete-vision"
      v-if="completeVision"
    >
      <template v-if="!_filteredRanking.length">
        <DetailBehavior
          class="item-complete"
          v-for="i in 10"
          :key="i"
          isLoading
        />
      </template>

      <template v-else-if="_filteredRanking.length > 0">
        <v-lazy
          class="item-complete"
          v-for="item in _filteredRanking"
          :key="item.id"
          min-height="335px"
        >
          <DetailBehavior
            :type="displayOrderBy"
            :data="item"
            :metrics="getAdvancedMetric(item.id)"
            :dates="advancedHistory.dates"
          />
        </v-lazy>
      </template>
    </div>

    <div
      data-test-behavior-minified-vision
      class="behavior__content"
      :class="{
        list: displayList,
        grid: !displayList,
      }"
      v-else
    >
      <BehaviorDetailDialog
        ref="behaviorDetailDialog"
        :title="selectedBehavior.name"
        :behaviorData="selectedBehavior"
        :behaviorHistory="detailBehaviorHistory"
      />

      <CultureTable
        data-test-table
        v-if="displayList"
        :items="_filteredRanking"
        :active="displayOrderBy"
        @openBehaviorDetailHistory="openDetailBehaviorDialog"
      />

      <div
        class="cards-wrapper"
        data-test-grid
        data-test-behavior-cards
        v-if="!displayList"
      >
        <template v-if="!_filteredRanking.length" data-test-grid-empty>
          <v-skeleton-loader
            v-for="i in 25"
            :key="i"
            width="200"
            height="230"
            type="image"
          ></v-skeleton-loader>
        </template>

        <template v-else-if="_filteredRanking.length > 0">
          <v-lazy
            v-for="item in _filteredRanking"
            :key="item.id"
            min-height="248px"
            min-width="202px"
          >
            <CultureCard
              :key="item.id"
              :cardTop="item.value"
              :cardImg="item.image"
              :cardTitle="item.name"
              :cardId="item.id"
              :cardScore="item.reliabilityRate"
              :cardPosition="
                displayOrderBy === 'votes'
                  ? item.position
                  : item.positionProbability
              "
              :cardpercent="item.probability"
              :cardVotos="item.votes"
              :cardUnfavorable="item.unfavorable"
              :cardDescription="item.description"
              :cardVariation="item.variation"
              :showTrophy="
                displayOrderBy === 'votes'
                  ? item.position <= 10
                  : item.positionProbability <= 10
              "
              :detailBehaviorHistory="detailBehaviorHistory"
              :detailBehaviorData="item"
              :active="displayOrderBy"
              @openDetailBehaviorDialog="() => openDetailBehaviorDialog(item)"
            ></CultureCard>
          </v-lazy>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CultureCard from '../CultureCard/CultureCard.vue'
import CultureTable from '../CultureTable/CultureTable.vue'
import CultureSelections from '../CultureSelections/CultureSelections.vue'
import BehaviorDetailDialog from '../BehaviorDetailDialog/BehaviorDetailDialog.vue'
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import DetailBehavior from '@/components/DetailBehavior/DetailBehavior.vue'

import toggleCultureManagementModalMixin from '@/mixins/toggleCultureManagementModal'

export default {
  name: 'BehaviorClassification',
  components: {
    CultureTable,
    CultureCard,
    CultureSelections,
    DetailBehavior,
    HowToBeginAndWatchAVideo,
    BehaviorDetailDialog,
  },
  mixins: [toggleCultureManagementModalMixin],

  props: {
    groupID: {
      type: String,
      default: '',
    },
    cultureResults: {
      type: Object,
      default: () => {
        return {
          cultureResults: {
            maturity: {
              current: 0,
            },
            ranking: [],
            presentCulture: {},
            culturalAlignment: {},
            unfavorableCulture: {},
            score: 0,
          },
        }
      },
    },
    videoType: String,
    alertText: String,
    advancedHistory: {
      type: Object,
      default: () => {},
    },
    completeVision: {
      type: Boolean,
      default: false,
    },
    detailBehaviorHistory: {
      type: Object,
      default: () => {
        return {
          metrics: [],
          dates: [],
        }
      },
    },
    unfavorableCultureVariation: Number,
    culturalAlignmentVariation: Number,
    rankingVariation: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      culturalAlignment: {},
      unfavorableCulture: {},
      presentCulture: {},

      ranking: [],

      displayOrderBy: 'probability',
      displayList: false,
      filterActive: 'present',

      historyCache: [],
      selectedBehavior: {},
    }
  },
  computed: {
    _filteredRanking() {
      return this.handleItensByFilter(this.filterActive)
    },
  },
  watch: {
    cultureResults: {
      handler() {
        const {
          presentCulture,
          culturalAlignment,
          unfavorableCulture,
          ranking,
        } = this.cultureResults
        this.presentCulture = Object.assign({}, presentCulture)
        this.culturalAlignment = Object.assign({}, culturalAlignment)
        this.unfavorableCulture = Object.assign({}, unfavorableCulture)
        this.ranking = [...ranking]
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleOpenConfigureModal() {
      this.toggleCultureManagementModal(true)
      this.$router.push({ name: 'cultureManagement' })
    },

    handleItensByFilter(filterActive) {
      let ranking = structuredClone(this.ranking)

      if (filterActive === 'unfavorable') {
        ranking = ranking.filter((el) => el.unfavorable === true)
      }

      if (filterActive === 'alignment') {
        ranking = ranking.filter((el) => el?.value?.name)
      }

      ranking = this.calcRankingByOrder(ranking, this.displayOrderBy)
      ranking = this.calcRankingWithVariation(ranking)

      return ranking
    },

    getRankingVariation(rankingID) {
      const variationObj = this.rankingVariation.find(
        (ranking) => ranking.id === rankingID
      )

      if (!variationObj) {
        return null
      }

      if (this.displayOrderBy === 'votes') {
        return variationObj.position
      }

      if (this.displayOrderBy === 'probability') {
        return variationObj.positionProbability
      }

      return null
    },

    calcRankingWithVariation(ranking) {
      return ranking.map((rankingItem) => ({
        ...rankingItem,
        variation: this.getRankingVariation(rankingItem.id),
      }))
    },

    calcRankingByOrder(ranking, order) {
      if (!order) {
        return ranking
      }

      const orderBy = {
        votes: 'position',
        probability: 'positionProbability',
      }[order]

      return ranking.sort((a, b) => {
        if (orderBy in a && orderBy in b) {
          return a[orderBy] - b[orderBy]
        }

        return b[order] - a[order]
      })
    },

    getAdvancedMetric(id) {
      if (!Array.isArray(this.historyCache[id])) {
        const metric = this.advancedHistory.metrics.find((el) => {
          return el.id === id
        })

        if (metric) {
          this.historyCache[id] = [metric]
        }
      }

      return this.historyCache[id] || []
    },

    handleVision() {
      this.$emit('handleVision')
    },

    openDetailBehaviorDialog(behavior) {
      this.$emit('openDetailBehaviorDialog', behavior.id)
      this.selectedBehavior = behavior
      this.$refs.behaviorDetailDialog.openDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
