<template>
  <div id="CardOpinionList" class="shadow">
    <v-card flat elevation="0">
      <div v-if="isEmpty" class="empty-info">
        <v-img
          max-width="244"
          max-height="183"
          width="100%"
          position="left bottom"
          aspect-ratio="1"
          :src="
            require(`@/assets/images/OpinionsConversation/no-content-cropped.png`)
          "
          alt="no-content-image"
        ></v-img>
        <p
          v-text="$t('engagementPage.opinions.cards.opinionList.noContent')"
        ></p>
      </div>

      <template v-else>
        <ul v-if="isLoading" class="loading loading-list">
          <template v-for="skeletonIndex in AMOUNT_OF_SKELETONS">
            <div
              class="loading-item"
              :key="'opinion-list-' + 'l' + skeletonIndex"
            >
              <div class="loading-left">
                <v-skeleton-loader
                  width="30"
                  height="30"
                  type="avatar"
                ></v-skeleton-loader>
              </div>

              <div class="loading-center">
                <div class="loading-center-top">
                  <v-skeleton-loader
                    height="30"
                    type="text@2"
                  ></v-skeleton-loader>
                </div>
                <div class="loading-center-bottom">
                  <v-skeleton-loader
                    width="22"
                    height="20"
                    type="chip"
                    class="loading-group"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    height="20"
                    type="text"
                    class="loading-metric"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    width="22"
                    height="20"
                    type="chip"
                    class="loading-metric-extended"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    width="22"
                    height="20"
                    type="chip"
                    class="loading-sensor"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    width="22"
                    height="20"
                    type="chip"
                    class="loading-rating"
                  ></v-skeleton-loader>
                </div>
              </div>

              <div class="loading-right">
                <v-skeleton-loader
                  width="20"
                  height="18"
                  type="chip"
                ></v-skeleton-loader>
              </div>
            </div>
            <v-divider
              v-if="skeletonIndex < AMOUNT_OF_SKELETONS"
              :key="'opinion-list-' + 'ld' + skeletonIndex"
            ></v-divider>
          </template>
        </ul>

        <ul
          v-else
          v-on:scroll="handleInfiniteScroll"
          class="opinion-list"
          data-test-opinions-list
        >
          <template v-for="(opinion, index) in conversationsList">
            <li
              :key="'opinion-list-' + opinion.id"
              v-on:click="handleSelecteOpinion(opinion)"
              :class="{
                'opinion-message-closed': opinion.closed,
                'opinion-message-read': opinion.isRead,
                'opinion-message-unread': !opinion.isRead,
                'opinion-message-anonymous': opinion.isAnonymous,
                'opinion-message-selected': opinion.id === selectedOpinion,

                positive: opinion.isPositive,
                constructive: !opinion.isPositive,
              }"
              class="opinion-item"
              :data-closed="opinion.closed.toString()"
              :data-read="opinion.isRead.toString()"
              :data-anonymous="opinion.isAnonymous.toString()"
              :data-positive="opinion?.isPositive?.toString()"
              :data-replies="opinion.totalReplies"
              :data-group="opinion.group.name"
              :data-pilar="fullPilarFactor[index].pilar.label"
              :data-fator="fullPilarFactor[index].fator.label"
              :data-time-passed="opinion.lastMessage.date"
            >
              <div class="opinion-avatar opinion-left">
                <div class="avatar-box">
                  <Avatar
                    v-if="opinion.closed"
                    :src="
                      require(`@/assets/images/OpinionsConversation/fi-br-check.svg`)
                    "
                    :displayAttr="{
                      class: 'closed-icon',
                      alt: 'closed',
                      'max-width': '16',
                      'aspect-ratio': '1',
                      contain: true,
                    }"
                    size="30px"
                  ></Avatar>
                  <Avatar
                    v-else-if="normalizedAnonymous(opinion.lastMessage)"
                    :src="
                      require(`@/assets/images/OpinionsConversation/anonymous.svg`)
                    "
                    :displayAttr="{
                      class: 'anonymous-icon',
                      alt: 'anonymous',
                      'max-width': '22',
                      'aspect-ratio': '1',
                      contain: true,
                    }"
                    size="30px"
                  ></Avatar>
                  <Avatar
                    v-else
                    :src="opinion.lastMessage.photo"
                    :text="opinion.lastMessage.name"
                    :color="getAvatarIdToColors[`${opinion.lastMessage.id}`]"
                    :displayAttr="{ alt: 'user' }"
                    size="30px"
                  ></Avatar>
                </div>
              </div>

              <div class="opinion-content opinion-center">
                <div class="opinion-content-header opinion-content-top">
                  <div class="message">
                    <strong v-text="opinion.lastMessage.name + ': '"></strong>
                    <span v-text="opinion.lastMessage.message"></span>
                  </div>
                  <div
                    class="responses"
                    v-text="
                      `(${opinion.totalReplies}) ${$t(
                        'engagementPage.opinions.cards.opinionList.responses'
                      )}`
                    "
                  ></div>
                </div>

                <div class="opinion-content-action opinion-content-bottom">
                  <v-tooltip
                    top
                    allowOverflow
                    open-on-click
                    v-bind="groupTooltipAttr"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="opinion-group">
                        <v-img
                          max-width="10"
                          max-height="10"
                          aspect-ratio="1"
                          contain
                          class="group-icon"
                          :src="
                            require(`@/assets/images/OpinionsConversation/fi-sr-users.svg`)
                          "
                          alt="group-icon"
                        ></v-img>
                      </div>
                    </template>
                    <div class="opinion-group-tooltip tooltip-right">
                      <p>
                        <span
                          v-text="
                            $t(
                              'engagementPage.opinions.cards.opinionList.group.title'
                            ) + ': '
                          "
                        ></span>
                        <strong v-text="opinion.group.name"></strong>
                      </p>
                      <span
                        v-text="
                          $t(
                            'engagementPage.opinions.cards.opinionList.group.explanation'
                          )
                        "
                      ></span>
                    </div>
                  </v-tooltip>

                  <div class="opinion-metric">
                    <div
                      class="title"
                      v-text="fullPilarFactor[index].fullString"
                    ></div>
                  </div>

                  <v-tooltip
                    open-on-click
                    allowOverflow
                    v-bind="
                      metricTooltipAttrLine(fullPilarFactor[index]).position
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="opinion-metric-extended"
                      >
                        <v-img
                          max-width="10"
                          max-height="10"
                          aspect-ratio="1"
                          contain
                          class="metric-icon"
                          :src="
                            require(`@/assets/images/OpinionsConversation/fi-rr-menu-dots.svg`)
                          "
                          alt="metric-icon"
                        ></v-img>
                      </div>
                    </template>
                    <div
                      class="opinion-metric-extended-tooltip"
                      :class="metricTooltipAttr.tooltipClass"
                      v-text="fullPilarFactor[index].fullString"
                    ></div>
                  </v-tooltip>

                  <div
                    class="opinion-sensor"
                    :class="{
                      positive: opinion.isPositive,
                      constructive: !opinion.isPositive,
                    }"
                  >
                    <v-img
                      v-if="opinion.isPositive"
                      max-width="14"
                      max-height="12"
                      aspect-ratio="1"
                      contain
                      class="sensor-icon"
                      :src="
                        require(`@/assets/images/OpinionsConversation/fi-rr-thumbs-up.svg`)
                      "
                      alt="thumbs-up"
                    ></v-img>
                    <v-img
                      v-else
                      max-width="14"
                      max-height="12"
                      aspect-ratio="1"
                      contain
                      class="sensor-icon"
                      :src="
                        require(`@/assets/images/OpinionsConversation/fi-rr-thumbs-down.svg`)
                      "
                      alt="thumbs-down"
                    ></v-img>
                  </div>

                  <div
                    class="opinion-rating"
                    v-text="ratedScore(opinion.rating)"
                  ></div>
                </div>
              </div>

              <div class="opinion-info opinion-right">
                <div
                  class="opinion-data"
                  v-text="timePassed(opinion.lastMessage.date)"
                ></div>
              </div>
            </li>

            <v-divider
              v-if="index < conversationsList.length - 1"
              :key="'opinion-list-' + 'd' + opinion.id"
            ></v-divider>
          </template>

          <template v-if="loadingInfinite">
            <template v-for="skeletonIndex in AMOUNT_OF_SKELETONS">
              <div
                class="loading-item"
                :key="'opinion-list-' + 'l' + skeletonIndex"
              >
                <div class="loading-left">
                  <v-skeleton-loader
                    width="30"
                    height="30"
                    type="avatar"
                  ></v-skeleton-loader>
                </div>

                <div class="loading-center">
                  <div class="loading-center-top">
                    <v-skeleton-loader
                      height="30"
                      type="text@2"
                    ></v-skeleton-loader>
                  </div>
                  <div class="loading-center-bottom">
                    <v-skeleton-loader
                      width="22"
                      height="20"
                      type="chip"
                      class="loading-group"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      height="20"
                      type="text"
                      class="loading-metric"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      width="22"
                      height="20"
                      type="chip"
                      class="loading-metric-extended"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      width="22"
                      height="20"
                      type="chip"
                      class="loading-sensor"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      width="22"
                      height="20"
                      type="chip"
                      class="loading-rating"
                    ></v-skeleton-loader>
                  </div>
                </div>

                <div class="loading-right">
                  <v-skeleton-loader
                    width="20"
                    height="18"
                    type="chip"
                  ></v-skeleton-loader>
                </div>
              </div>
              <v-divider
                v-if="skeletonIndex < AMOUNT_OF_SKELETONS"
                :key="'opinion-list-' + 'ld' + skeletonIndex"
              ></v-divider>
            </template>
          </template>
        </ul>

        <v-divider></v-divider>

        <div class="scroll-down">
          <template v-if="loadingInfinite">
            <span class="loading-dots scroll-loading"></span>
          </template>

          <template v-else-if="canInfinite">
            <v-img
              max-width="12"
              max-height="18"
              aspect-ratio="1"
              contain
              class="scroll-icon"
              :src="
                require(`@/assets/images/OpinionsConversation/fi-rr-mouse.svg`)
              "
              alt="scroll-down"
            ></v-img>
            <span
              v-text="
                $t('engagementPage.opinions.cards.opinionList.scrollDown')
              "
            ></span>
          </template>

          <template v-else>
            <span
              v-text="$t('engagementPage.opinions.cards.opinionList.noScroll')"
            ></span>
          </template>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import variables from '@/styles/abstracts/_colors.scss'
import Avatar from '@/components/Avatar/Avatar.vue'

export default {
  name: 'CardOpinionList',
  components: {
    Avatar,
  },
  data: function () {
    return {
      variables,
      holdSpamInfinityScroll: false,
    }
  },
  props: {
    conversationsList: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => true,
    },

    loadingInfinite: {
      type: Boolean,
      default: () => false,
    },
    canInfinite: {
      type: Boolean,
      default: false,
    },

    selectedOpinion: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      default: -1,
    },
  },
  created() {
    this.AMOUNT_OF_SKELETONS = 20
  },
  computed: {
    ...mapGetters(['getAvatarIdToColors']),
    isLoading() {
      return !(this.conversationsList.length > 0 || !this.loading)
    },
    isEmpty() {
      return this.conversationsList.length === 0 && !this.loading
    },
    fullPilarFactor() {
      return this.conversationsList.map((opinion) => {
        let pilar = opinion.metric
        let fator = opinion.submetric
        const defaultString = this.$t(
          'engagementPage.opinions.cards.opinionList.openConversation'
        )
        let fullString

        const hasPillar = !!pilar
        const hasFator = !!fator

        switch (true) {
          case hasPillar && hasFator:
            fullString = [pilar.label, fator.label].filter(Boolean).join(', ')
            break
          case hasPillar && !hasFator:
            fullString = [pilar.label].filter(Boolean).join(', ')
            fator = { label: '' }
            break
          case !hasPillar && hasFator:
            fullString = [defaultString, fator.label].filter(Boolean).join(', ')
            pilar = { label: defaultString }
            break
          default:
            fullString = defaultString
            pilar = { label: defaultString }
            fator = { label: '' }
            break
        }

        return {
          pilar,
          fator,
          defaultString,
          fullString,
        }
      })
    },

    groupTooltipAttr() {
      let nudgeRight = 113
      let maxWidth = 263
      let nudgeBottom = 7
      if (this.$vuetify.breakpoint.width <= 330) {
        nudgeRight = 81
        maxWidth = 200
      }

      return {
        nudgeRight,
        nudgeBottom,
        maxWidth,
      }
    },
    metricTooltipAttr() {
      let position = {
        right: true,
        nudgeLeft: 38,
        nudgeTop: 35,
        maxWidth: 'auto',
      }

      let tooltipClass = 'tooltip-right'

      if (this.$vuetify.breakpoint.width <= 600) {
        position = {
          left: true,
          nudgeRight: 38,
          nudgeTop: 35,
          maxWidth: this.$vuetify.breakpoint.width / 1.8,
        }

        tooltipClass = 'tooltip-left'
      }

      return {
        position,
        tooltipClass,
      }
    },
  },
  methods: {
    timePassed(lastMessageDate) {
      const locale = this.$t(
        'engagementPage.opinions.cards.opinionList.moment.locale'
      )
      moment.locale(locale)
      moment.defaultFormat = this.$t(
        'engagementPage.opinions.cards.opinionList.moment.apiFormat'
      )

      const dayLabel = this.$t(
        'engagementPage.opinions.cards.opinionList.moment.day'
      )
      const weekLabel = this.$t(
        'engagementPage.opinions.cards.opinionList.moment.week'
      )
      const monthLabel = this.$t(
        'engagementPage.opinions.cards.opinionList.moment.month'
      )
      const yearLabel = this.$t(
        'engagementPage.opinions.cards.opinionList.moment.year'
      )

      moment.updateLocale(locale, {
        relativeTime: {
          future: 'em %s',
          past: 'há %s',
          s: '0s',
          ss: '%dsec',
          m: '1m',
          mm: '%dmin',
          h: '1h',
          hh: '%dh',
          d: `1${dayLabel}`,
          dd: `%d${dayLabel}`,
          w: `1${weekLabel}`,
          ww: `%d${weekLabel}`,
          M: `1${monthLabel}`,
          MM: `%d${monthLabel}`,
          y: `1${yearLabel}`,
          yy: `%d${yearLabel}`,
        },
      })

      return moment(lastMessageDate, moment.defaultFormat).fromNow(true)
    },
    ratedScore(avaliationNote) {
      switch (avaliationNote) {
        case 'very-unhappy':
          return '😠'
        case 'unhappy':
          return '🙁'
        case 'no-comments':
          return '😐'
        case 'good':
          return '😃'
        case 'love-it':
          return '😍'
      }
    },
    normalizedAnonymous(lastMessage) {
      return (
        lastMessage.name ===
        this.$t('engagementPage.opinions.cards.opinionList.anonymous')
      )
    },
    metricTooltipAttrLine(fullPilarFactor) {
      const metricTooltipAttr = this.metricTooltipAttr
      let updatedPositions = {
        ...metricTooltipAttr.position,
      }

      if (
        fullPilarFactor.fullString.length >= 25 &&
        this.$vuetify.breakpoint.width <= 600
      )
        updatedPositions.nudgeTop =
          updatedPositions.nudgeTop + fullPilarFactor.fullString.length / 9

      return {
        ...metricTooltipAttr,
        position: {
          ...updatedPositions,
        },
      }
    },
    handleInfiniteScroll(params) {
      const pixelTolerance = 5
      const currentPositionScroll =
        params.target.clientHeight + params.target.scrollTop + pixelTolerance
      const hasCurrentPositionPassedScrollableContent =
        currentPositionScroll >= params.target.scrollHeight

      if (hasCurrentPositionPassedScrollableContent) {
        this.confirmInfinityScroll()
      }
    },

    confirmInfinityScroll() {
      if (this.holdSpamInfinityScroll || this.loadingInfinite) {
        return
      }

      this.holdSpamInfinityScroll = true

      this.$emit('handleInfiniteScroll')
    },
    handleSelecteOpinion(opinion) {
      this.$emit('handleSelecteOpinion', opinion)
    },
  },
  watch: {
    loadingInfinite() {
      this.holdSpamInfinityScroll = false
    },

    conversationsList() {
      const colores = this.$vuetify.theme.themes.light.treemapColors

      this.conversationsList.map((opinion) => {
        if (!opinion.lastMessage.id) return

        const idm = opinion.lastMessage.id
        const idx = Math.floor(Math.random() * colores.length)

        this.$store.commit('setAvatarIdToColors', {
          idx: idm,
          color: colores[idx],
        })
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
