<template>
  <div class="content d-flex flex-column">
    <span
      data-test-survey-open-title
      class="content-title pa-2 mx-2"
      v-text="$t('answerOpen.title')"
    />
    <v-divider class="d2" />
    <div class="pa-2">
      <v-textarea
        class="content-text"
        v-model="answer"
        :placeholder="placeholder"
        persistent-placeholder
        no-resize
        auto-grow
        filled
        solo
        flat
        hide-spin-buttons
        hide-details
        color="neutral2"
        background-color="neutral"
        @input="handleChange"
      />
      <div class="d-flex align-center justify-start">
        <span
          role="region"
          aria-live="assertive"
          class="accessibility-hide-text"
          v-text="
            $t(`answerOpen.accessibility.${assigned ? 'nonAnon' : 'anon'}`)
          "
        />

        <v-switch
          dense
          v-model="assigned"
          hide-details
          inset
          :class="`ms-2 ${assigned ? 'active' : ''}`"
          @keypress.enter="assigned = !assigned"
          :aria-label="$t('answerOpen.accessibility.switchHelp')"
          @change="emitChange"
        />

        <!--Anon Label-->
        <div class="d-flex mt-5 ms-n2">
          <span
            v-if="!assigned"
            v-text="$t('answerOpen.anonymous')"
            class="anon"
          />
          <div v-else class="d-flex">
            <Avatar
              :text="profileName"
              :id="profileId"
              :src="profileImage"
              size="1.25em"
            />
            <span
              class="non-anon align-self-center ms-1"
              v-text="profileName"
            />
          </div>
        </div>

        <v-tooltip
          bottom
          open-delay="100"
          max-width="350px"
          color="neutral1"
          open-on-focus
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="align-self-center mx-1 mt-5"
              color="neutral5"
              v-bind="attrs"
              v-on="on"
              size="12"
              v-text="'fi-rr-interrogation'"
            />
          </template>
          <span
            class="description"
            v-text="$t(`answerOpen.anonymousHelp${assigned ? 2 : 1}`)"
          />
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar/Avatar'

export default {
  name: 'AnswerOpen',
  components: { Avatar },
  props: {
    profileId: {
      default: '',
    },
    profileName: {
      required: true,
    },
    profileImage: {
      required: false,
    },
    placeholder: {
      required: true,
    },
  },
  data() {
    return {
      answer: '',
      assigned: false,
      toggle: [],
      interval: null,
    }
  },
  methods: {
    handleChange() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(this.emitChange, 250)
    },
    handleToggle() {
      this.emitChange()
    },

    emitChange() {
      this.$emit('change', { text: this.answer, assigned: this.assigned })
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
