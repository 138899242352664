<template>
  <div
    class="content d-flex flex-column align-center"
    :class="anim"
    :style="{ opacity }"
  >
    <div class="accessibility-hide-text" tabindex="0">
      <span v-text="progress" />
    </div>

    <!--Title-->
    <span
      class="content-title"
      v-text="title"
      tabindex="0"
      data-test-tittle-survey
    />

    <!--Component-->
    <div
      data-test-survey-component-questions
      class="content-body d-flex justify-center"
    >
      <component
        :data-test-survey-component-question="question.type"
        class="fill-width"
        :key="index"
        :is="component"
        :items="items"
        :video="video"
        :max="max"
        :init="init"
        @input="handleInput"
        @select="handleSelected"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AnswerCheckbox from '@/components/AnswerCheckbox/AnswerCheckbox'
import AnswerRadioEmoticons from '@/components/AnswerRadioEmoticons/AnswerRadioEmoticons'
import AnswerRadioCards from '@/components/AnswerRadioCards/AnswerRadioCards'
import AnswerStars from '@/components/AnswerStars/AnswerStars'
import AnswerSlider from '@/components/AnswerSlider/AnswerSlider'
import AnswerSliderCard from '@/components/AnswerSliderCard/AnswerSliderCard'

export default {
  name: 'SurveySteps',
  components: {
    AnswerCheckbox,
    AnswerRadioEmoticons,
    AnswerRadioCards,
    AnswerStars,
    AnswerSlider,
    AnswerSliderCard,
  },

  data() {
    return {
      anim: '', // slide-left, slide-right
      opacity: 1,
    }
  },

  computed: {
    ...mapState(['survey']),

    index: function () {
      const element = document.querySelector('.accessibility-hide-text')
      if (element) element.focus()

      return this.survey.step.index
    },
    length: function () {
      if (!this.survey.questions) return

      return this.survey.questions.length
    },
    progress: function () {
      const qIndex = `${this.$t('survey.footer.questionIndex')} ${Number(
        this.index + 1
      )}`
      const qSize = `${this.$t('survey.footer.ofIndex')} ${this.length}`
      return `${qIndex} ${qSize}`
    },
    question: function () {
      return this.survey.questions[this.index]
    },
    title: function () {
      if (!this.question) return

      return this.survey.questions[this.index]['text']
    },
    items: function () {
      if (!this.question) return

      const { type, answers } = this.question

      // Emoticons
      if (type == 'radio-string')
        return ['😠', '🙁', '😐', '🙂', '😄'].map((e, index) => ({
          emoji: e,
          ...answers[index],
        }))

      // Cultural
      if (type == 'checkbox-string')
        return answers.map((e, index) => ({
          ...answers[index],
          image: `https://static.teamculture.com.br/images/culture/${e.answerID}.svg`,
        }))

      return answers
    },
    video: function () {
      if (!this.question) return

      return this.question['video']
    },
    max: function () {
      if (!this.question) return

      const { type } = this.question

      if (type == 'checkbox-string') return 4

      return null
    },
    init: function () {
      if (!this.question) return

      const { answered } = this.question
      if (!answered) return

      // Array Answered (Cultural)
      if (Array.isArray(answered)) return answered

      return Number(answered['value'])
    },
    component: function () {
      if (!this.question) return

      const { type } = this.question
      const types = {
        'checkbox-string': AnswerCheckbox,
        'radio-string': AnswerRadioEmoticons,
        'radio-card': AnswerRadioCards,
        'star-rating': AnswerStars,
        'slide-number': AnswerSlider,
        percent: AnswerSliderCard,
      }

      return types[type] || null
    },
  },

  watch: {
    index: function (e, o) {
      // Animate (From Footer Click)
      if (!this.anim || !this.anim.length) {
        this.opacity = 0
        this.anim = `slide-${e > o ? 'right' : 'left'}`

        setTimeout(() => {
          this.opacity = 1
          this.anim = ''
        }, 150)
      }
    },
  },

  methods: {
    ...mapActions([
      'setSurveyStepAnswer',
      'setSurveyStepIndex',
      'setSurveyState',
    ]),
    handleInput(input) {
      this.setSurveyStepAnswer({ index: this.index, answer: input })
    },
    handleSelected(input) {
      this.setSurveyStepAnswer({ index: this.index, answer: input })

      if (this.survey.state == 'steps') {
        const newIndex = Number(this.index) + 1

        // End Steps -> Next [Finish|Feedback]
        if (!this.survey.questions[newIndex]) {
          const { answered } = this.survey.questions[this.index]

          // Feedback|Finish
          return this.setSurveyState({
            state: `${answered ? 'feedback' : 'finish'}`,
          })
        }

        this.opacity = 0
        this.anim = 'slide-right'

        setTimeout(() => {
          this.opacity = 1
          this.anim = ''
          this.setSurveyStepIndex({ index: newIndex })
        }, 400)
      }
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
