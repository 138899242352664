var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"card-content culture-code-card",class:{ 'border-card': _vm.isActive },attrs:{"data-test-culture-code-card":""}},[(_vm.isActive)?_c('v-card-subtitle',{staticClass:"selected-culture"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('cultureManagement.card.cardActive'))}})]):_vm._e(),_c('v-img',{staticClass:"culture-code-image",attrs:{"min-width":"200px","min-height":"100px","src":require('@/assets/images/managementCulture/card-cultural-content-image.png'),"alt":_vm.$t('cultureManagement.title')}}),_c('v-card-subtitle',{staticClass:"culture-subtitle"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('cultureManagement.card.culturalMaturity'))}}),_c('span',{staticClass:"level"},[_c('span',{domProps:{"textContent":_vm._s('-')}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('engagementPage.culture.cultureCodeCard.level'))}}),_c('span',{domProps:{"textContent":_vm._s(
          _vm.culture.maturityLevelAverage ? _vm.culture.maturityLevelAverage : '0'
        )}})])]),_c('v-card-title',{staticClass:"culture-name"},[_c('span',{domProps:{"textContent":_vm._s(_vm.culture.name)}})]),_c('v-divider'),_c('div',{staticClass:"culture-values-groups"},[_c('v-chip',{staticClass:"chip-value-pink"},[_c('span',{domProps:{"textContent":_vm._s(_vm.culture.values.length + _vm.$t('cultureManagement.card.values'))}})]),_c('v-chip',{staticClass:"chip-group-gray"},[_c('span',{domProps:{"textContent":_vm._s(_vm.culture.groups.length + _vm.$t('cultureManagement.card.groups'))}})])],1),_c('v-divider'),_c('div',{staticClass:"culture-stats"},[_c('div',{staticClass:"cultural-alignment"},[_c('v-img',{attrs:{"max-height":"20px","max-width":"20px","width":"20px","src":require('@/assets/images/managementCulture/icons/cultural-alignment.svg'),"alt":_vm.$t('engagementPage.behaviorClassification.culturalAlignment')}}),_c('span',{staticClass:"label",domProps:{"textContent":_vm._s(
          _vm.culture.culturalAlignmentAverage !== null
            ? _vm.culture.culturalAlignmentAverage + '%'
            : '--'
        )}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"unfavorable-culture"},[_c('v-img',{attrs:{"max-height":"20px","max-width":"20px","width":"20px","src":require('@/assets/images/managementCulture/icons/unfavorable-culture.svg'),"alt":_vm.$t('engagementPage.behaviorClassification.unfavorableCulture')}}),_c('span',{staticClass:"label",domProps:{"textContent":_vm._s(
          _vm.culture.unfavorableCultureAverage !== null
            ? _vm.culture.unfavorableCultureAverage + '%'
            : '--'
        )}})],1)],1),_c('v-divider'),_c('div',{staticClass:"more-information"},[_c('v-btn',{staticClass:"btn-more-information",attrs:{"data-test-btn-more-details":"","depressed":"","text":"","small":"","ripple":false},on:{"click":function($event){return _vm.$emit('clicked')}}},[_vm._v(_vm._s(_vm.$t('cultureManagement.card.moreDetails')))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }