<script>
import VMenu from 'vuetify/lib/components/VMenu/VMenu'
import { convertToUnit } from 'vuetify/lib/util/helpers'

export default {
  name: 'RightMenu',
  extends: VMenu,
  computed: {
    styles() {
      const base = {
        minWidth: this.calculatedMinWidth,
        maxWidth: this.calculatedMaxWidth,
        top: this.calculatedTop,
        transformOrigin: this.origin,
        zIndex: this.zIndex || this.activeZIndex,
      }

      const leftStyle = {
        left: this.calculatedLeft,
      }

      const rightStyle = {
        right: convertToUnit(
          document.body.clientWidth - this.dimensions.activator.right
        ),
      }

      return Object.assign(base, this.right ? rightStyle : leftStyle)
    },
  },
  methods: {
    updatePosition() {
      this.$nextTick(() => {
        this.onResize()
      })
    },
  },

  watch: {
    nudgeTop() {
      this.updatePosition()
    },
  },
}
</script>
