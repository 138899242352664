<template>
  <div class="history-nps-chart-tooltip">
    <div class="apexcharts-tooltip-title">
      {{ $t(`historyNpsChartTooltip.${type}`) }}
    </div>
    <div class="apexcharts-tooltip-series-group apexcharts-active promoters">
      <span class="apexcharts-tooltip-marker" />
      <div class="apexcharts-tooltip-text">
        <div class="apexcharts-tooltip-y-group">
          <span class="apexcharts-tooltip-text-y-label">{{
            $t('historyNpsChartTooltip.promoters')
          }}</span>
          <span class="apexcharts-tooltip-text-y-value"
            >{{ tooltipData.promotersCount }}%</span
          >
        </div>
        <div class="apexcharts-tooltip-goals-group">
          <span class="apexcharts-tooltip-text-goals-label"></span>
          <span class="apexcharts-tooltip-text-goals-value"></span>
        </div>
        <div class="apexcharts-tooltip-z-group">
          <span class="apexcharts-tooltip-text-z-label"></span>
          <span class="apexcharts-tooltip-text-z-value"></span>
        </div>
      </div>
    </div>
    <div class="apexcharts-tooltip-series-group apexcharts-active passives">
      <span class="apexcharts-tooltip-marker" />
      <div class="apexcharts-tooltip-text">
        <div class="apexcharts-tooltip-y-group">
          <span class="apexcharts-tooltip-text-y-label"
            >{{ $t('historyNpsChartTooltip.passive') }}
          </span>
          <span class="apexcharts-tooltip-text-y-value"
            >{{ tooltipData.passivesCount }}%</span
          >
        </div>
        <div class="apexcharts-tooltip-goals-group">
          <span class="apexcharts-tooltip-text-goals-label"></span>
          <span class="apexcharts-tooltip-text-goals-value"></span>
        </div>
        <div class="apexcharts-tooltip-z-group">
          <span class="apexcharts-tooltip-text-z-label"></span>
          <span class="apexcharts-tooltip-text-z-value"></span>
        </div>
      </div>
    </div>
    <div class="apexcharts-tooltip-series-group apexcharts-active detractors">
      <span class="apexcharts-tooltip-marker" />
      <div class="apexcharts-tooltip-text">
        <div class="apexcharts-tooltip-y-group">
          <span class="apexcharts-tooltip-text-y-label"
            >{{ $t('historyNpsChartTooltip.detractors') }}
          </span>
          <span class="apexcharts-tooltip-text-y-value"
            >{{ tooltipData.detractorsCount }}%</span
          >
        </div>
        <div class="apexcharts-tooltip-goals-group">
          <span class="apexcharts-tooltip-text-goals-label"> </span>
          <span class="apexcharts-tooltip-text-goals-value"> </span>
        </div>
        <div class="apexcharts-tooltip-z-group">
          <span class="apexcharts-tooltip-text-z-label"></span>
          <span class="apexcharts-tooltip-text-z-value"></span>
        </div>
      </div>
    </div>
    <div class="apexcharts-tooltip-series-group apexcharts-active skipped">
      <span class="apexcharts-tooltip-marker" />
      <div class="apexcharts-tooltip-text">
        <div class="apexcharts-tooltip-y-group">
          <span class="apexcharts-tooltip-text-y-label"
            >{{ $t('historyNpsChartTooltip.skipped') }}
          </span>
          <span class="apexcharts-tooltip-text-y-value"
            >{{ tooltipData.skippedCount }}%</span
          >
        </div>
        <div class="apexcharts-tooltip-goals-group">
          <span class="apexcharts-tooltip-text-goals-label"></span>
          <span class="apexcharts-tooltip-text-goals-value"></span>
        </div>
        <div class="apexcharts-tooltip-z-group">
          <span class="apexcharts-tooltip-text-z-label"></span>
          <span class="apexcharts-tooltip-text-z-value"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tooltipData: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'enps',
    },
  },
}
</script>

<style src="./style.scss" lang="scss" />
