<template>
  <div
    id="climate-page"
    data-test-climate-page
    class="card-container"
    :class="{ 'show-details': showDetails }"
  >
    <v-row>
      <v-col
        v-if="showComponent(stateComponents.ENGAGEMENT_AVG)"
        sm="12"
        md="4"
        lg="4"
      >
        <CardAverageEngagement
          :score="_score"
          :participationRate="_participationRate"
          :hasSelectedGroup="!!groupID"
          :opinionsRate="opinionsRate"
          :loading="loading.avgCard"
          :comparisonMetrics="comparisonMetrics"
          :variation="variation"
          :hideQuickComparison="
            demographicReportID
              ? ['climate', 'participation', 'answeredOpinions']
              : []
          "
          @hoverOn:overall-average="fetchComparisonByMetric($event)"
          @hoverOff:overall-average="resetQuickComparison"
          data-test-card-climate
        />
      </v-col>
      <v-col
        v-if="showComponent(stateComponents.ENGAGEMENT_HISTORY)"
        sm="12"
        md="8"
        lg="8"
      >
        <CardHistory
          id="climate"
          :loading="loading.historyCard"
          :benchmarkPermissions="_canEngagementBenchmarkResults"
          :title="$t('cardHistoryClimate.title')"
          :changeVisionButton="$t('cardHistoryClimate.changeVisionButton')"
          videoType="climate.history"
          :metrics="history.metrics"
          :dates="history.dates"
          :changeVision="advancedHistory"
          :large="advancedHistory"
          :benchmark="advancedHistory ? null : benchmark.climate"
          @handleHistoryVision="handleAdvancedHistory"
        />
      </v-col>
      <v-col
        v-if="showComponent(stateComponents.PILLAR)"
        sm="12"
        md="12"
        lg="12"
      >
        <PillarsOfEngagementGroup
          :loading="loading.pillars"
          :loadingSelected="loading.selectedPillar"
          :hasSelectedGroup="!!groupID"
          :metrics="_pillarsMetrics"
          :pillarsBenchmarks="pillarsBenchmarks"
          :pillarsBenchmarksLoading="pillarsBenchmarksLoading"
          :pillarsBenchmarksLocked="!_canEngagementBenchmarkResults"
          :pillarsAndFactorsHistory="pillarsAndFactorsHistory"
          :pillarsAndFactorsHistoryLoading="pillarsAndFactorsHistoryLoading"
          :correlatedPillars="correlatedPillars"
          :correlatedPillarsLoading="correlatedPillarsLoading"
          :benchmarkPermissions="_canEngagementBenchmarkResults"
          @goToFullReport="handlePillarsOfEngagementVision(true)"
          :correlations="correlations"
          :quicklyComparisonData="quicklyComparisonData"
          @hoverOn:pillarGroup="fetchData($event)"
          @hoverOff:pillarGroup="resetQuickDetail"
          :metricsDetails="pillarsDetails"
          :pillarDetailsLoading="pillarDetailsLoading"
          :fullReport="pillarsOfEngagementVision"
          :opinions="opinionsOfPillarsOfEngagementGroup"
          :selected="selectedPillar"
          @clicked-item="handleSelectPillar"
          @back="handleRemovePillarSelection"
          :variation="variation"
          :hideQuickDetail="!!demographicReportID"
          :hideOpinions="!!demographicReportID"
        ></PillarsOfEngagementGroup>
      </v-col>
      <v-col
        v-if="showComponent(stateComponents.SMART_PILLAR) && _showSmartPillars"
        sm="12"
        md="12"
        lg="12"
      >
        <CardPillarSegmentation
          :loading="loading.smartPillars"
          data-test-segmentation-pillars
          :hasSelectedGroup="!!groupID"
          :pillars="_pillars"
          :comparisonMetrics="comparisonMetrics"
          :opinions="opinionsOfPillarSegmentation"
          @hoverOn:pillar="fetchQuickComparison($event)"
          @hoverOff:pillar="resetQuickComparison"
          :fullReport="smartPillarsVision"
          :history="smartPillarsHistory"
          :historyLoading="smartPillarsHistoryLoading"
          :smartPillarsBenchmarks="smartPillarsBenchmarks"
          :smartPillarsBenchmarksLoading="smartPillarsBenchmarksLoading"
          :smartPillarsBenchmarksLocked="smartPillarsBenchmarksLocked"
          :smartMetricsAdvanced="smartMetricsAdvanced"
          @goToFullReport="handleSmartPillarsVision(true)"
          :selected="selectedSmartPillar"
          @clicked-item="handleSelectSmartPillar"
          @back="handleRemovePillarSmartSelection"
          :variation="variation"
          :hideQuicklyComparison="!!demographicReportID"
          :hideOpinions="!!demographicReportID"
        ></CardPillarSegmentation>
      </v-col>
      <v-col
        v-if="
          showComponent(stateComponents.ENPS) &&
          _canEngagementClimateMetricsENPS
        "
        sm="12"
        md="12"
        lg="12"
      >
        <CardNPSEngagement
          type="enps"
          :loading="loading.enps"
          :benchmark="benchmark.enps"
          :results="results.enps"
          :resultsHistory="resultsHistory.enps"
          :verticalLevelBar="verticalLevelBar.enps"
          :marketPermission="_canEngagementBenchmarkResults"
          :opinions="opinions.enps"
          :variation="variation"
          :hideOpinions="
            !!!demographicReportID && !_canEngagementClimateQuestions
          "
        />
      </v-col>
      <v-col
        v-if="
          showComponent(stateComponents.LNPS) &&
          _canEngagementClimateMetricsLNPS
        "
        sm="12"
        md="12"
        lg="12"
      >
        <CardNPSEngagement
          type="lnps"
          :loading="loading.lnps"
          :benchmark="benchmark.lnps"
          :results="results.lnps"
          :resultsHistory="resultsHistory.lnps"
          :verticalLevelBar="verticalLevelBar.lnps"
          :marketPermission="_canEngagementBenchmarkResults"
          :opinions="opinions.lnps"
          :variation="variation"
          :hideOpinions="
            !!!demographicReportID && !_canEngagementClimateQuestions
          "
        />
      </v-col>
      <v-col
        v-if="
          showComponent(stateComponents.RECOMMENDATION) && _showRecommendations
        "
        sm="12"
        md="12"
        lg="12"
      >
        <EngagementRecommendations
          :loading="loading.recommendations"
          :recommendations="recommendations"
          :lockRecommendations="_lockRecommendations"
          :canNewPlan="_canNewActionPlan"
        ></EngagementRecommendations>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardAverageEngagement from './Partials/CardAverageEngagement/CardAverageEngagement.vue'
import CardHistory from '@/components/CardHistory/CardHistory.vue'
import EngagementRecommendations from '@/components/EngagementRecommendations/EngagementRecommendations.vue'

import CardPillarSegmentation from './Partials/PillarSegmentation/CardPillarSegmentation.vue'
import CardNPSEngagement from './Partials/CardNPSEngagement/CardNPSEngagement.vue'
import PillarsOfEngagementGroup from './Partials/PillarsOfEngagementGroup/PillarsOfEngagementGroup.vue'

import { debounce } from 'lodash'
import { mapState, mapGetters } from 'vuex'
import { getEngagementParticipation } from '@/service/participation'

import {
  getClimateResults,
  getClimateResultsHistory,
  getClimateResultsAdvancedHistory,
  getClimateNPSResults,
  getClimateNPSResultsHistory,
  getClimateCorrelations,
  getClimateCorrelationResults,
  getClimateAdvancedResultsMetrics,
  getClimateAdvancedResultSmartMetrics,
  getVariation,
} from '@/service/climate'

import {
  getBenchmarkResultsHistory,
  getBenchmarkResults,
} from '@/service/benchmark'

import { getRecommendationsResults } from '@/service/recommendations'

import { getOpinions, getEngagementOpinionsSummary } from '@/service/opinions'
import { _permissions } from '@/helpers/ability/engagement'

const stateComponents = Object.freeze({
  ENGAGEMENT_AVG: 'ENGAGEMENT_AVG',
  ENGAGEMENT_HISTORY: 'ENGAGEMENT_HISTORY',
  RECOMMENDATION: 'RECOMMENDATION',
  ENPS: 'ENPS',
  LNPS: 'LNPS',
  PILLAR: 'PILLAR',
  SMART_PILLAR: 'SMART_PILLAR',
})

export default {
  name: 'ClimatePage',
  components: {
    EngagementRecommendations,
    CardNPSEngagement,
    CardAverageEngagement,
    CardHistory,
    CardPillarSegmentation,
    PillarsOfEngagementGroup,
  },

  props: {
    groupID: {
      type: String,
      default: '',
    },
    indicatorID: {
      type: String,
      default: 'climate',
    },
    variationPeriod: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: {
        avgCard: false,
        historyCard: false,
        pillars: false,
        selectedPillar: false,
        smartPillars: false,
        enps: false,
        lnps: false,
        recommendations: false,
      },
      stateComponents,
      states: {
        main: '*',
        selectedPillar: [
          stateComponents.PILLAR,
          stateComponents.RECOMMENDATION,
        ],
        selectedSmartPillar: [
          stateComponents.SMART_PILLAR,
          stateComponents.RECOMMENDATION,
        ],
      },
      state: 'main',
      comparisonMetrics: {
        account: {
          rank: undefined,
          score: undefined,
        },
        average: {
          rank: undefined,
          score: undefined,
        },
        group: {
          rank: undefined,
          score: undefined,
        },
      },
      listComparisonMetrics: {
        climate: {
          account: {
            rank: null,
            score: null,
          },
          average: {
            rank: null,
            score: null,
          },
          group: {
            rank: null,
            score: null,
          },
        },
        participation: {
          account: {
            rank: null,
            score: null,
          },
          average: {
            rank: null,
            score: null,
          },
          group: {
            rank: null,
            score: null,
          },
        },
        answeredOpinions: {
          account: {
            rank: null,
            score: null,
          },
          average: {
            rank: null,
            score: null,
          },
          group: {
            rank: null,
            score: null,
          },
        },
      },
      groupInfo: {},
      accountInfo: {},
      history: {
        metrics: [],
        dates: [],
      },
      pillarsAndFactorsHistory: {
        factors: [[], [], [], [], [], [], [], [], [], []],
        metrics: [[], [], [], [], [], [], [], [], [], []],
        dates: [],
      },
      pillarsAndFactorsHistoryLoading: false,
      smartPillarsHistory: {
        metrics: [[], [], []],
        dates: [],
      },
      smartPillarsHistoryLoading: false,
      verticalLevelBar: {
        enps: {
          group: undefined,
          account: undefined,
          average: undefined,
        },
        lnps: {
          group: undefined,
          account: undefined,
          average: undefined,
        },
      },
      results: {
        enps: {
          score: 0,
          promoters: 0,
          detractors: 0,
          passives: 0,
          skiped: 0,
        },
        lnps: {
          score: 0,
          promoters: 0,
          detractors: 0,
          passives: 0,
          skiped: 0,
        },
      },
      resultsHistory: {
        enps: [],
        lnps: [],
      },
      benchmark: {
        climate: {
          rank: 0,
          score: 0,
          locked: false,
        },
        enps: {
          rank: 0,
          score: 0,
          locked: false,
        },
        lnps: {
          rank: 0,
          score: 0,
          locked: false,
        },
      },
      opinions: {
        enps: [],
        lnps: [],
      },
      groupParticipationRate: 0,
      accountParticipationRate: 0,
      opinionsRate: 0,
      advancedHistory: false,
      showDetails: false,
      showCreatePlan: false,
      recommendations: [],
      sections: {},
      approvalDetails: '',
      smartPillarsVision: false,
      smartMetricsAdvanced: [],
      historyMetricsNames: {
        group: this.$t('history.indicators.group'),
        account: this.$t('history.indicators.account'),
        average: this.$t('history.indicators.average'),
      },
      pillarsBenchmarks: [],
      pillarsBenchmarksLocked: false,
      pillarsBenchmarksLoading: false,
      smartPillarsBenchmarks: [],
      smartPillarsBenchmarksLocked: false,
      smartPillarsBenchmarksLoading: false,
      metricIDs: [],
      correlations: [],
      quicklyComparisonData: {},
      submetricIDs: [],
      correlatedPillars: [],
      correlatedPillarsLoading: false,
      pillarsOfEngagementVision: false,
      pillarsDetails: [],
      pillarDetailsLoading: false,
      opinionsOfPillarSegmentation: {},
      opinionsOfPillarsOfEngagementGroup: {},
      selectedPillar: null,
      selectedSmartPillar: null,
      variation: {
        metrics: [],
        smartMetrics: [],
        lnps: {},
        enps: {},
        score: null,
        participation: null,
        answeredOpinions: null,
        loading: false,
      },
    }
  },

  computed: {
    ...mapState(['demographicReportID']),
    ...mapGetters(['getIsBasicPlanAdminUser']),

    _canNewActionPlan() {
      return (
        this._canEngagementActionPlansCreator ||
        this._canEngagementActionPlansTemplatesCreator
      )
    },

    _canEngagementActionPlansCreator() {
      return this.$can('access', _permissions.engagement_action_plans_creator)
    },
    _canEngagementActionPlansTemplatesCreator() {
      return this.$can(
        'access',
        _permissions.engagement_action_plans_templates_creator
      )
    },

    _showRecommendations() {
      if (this._canAcademyRecommendations) return true

      return this.getIsBasicPlanAdminUser
    },

    _lockRecommendations() {
      if (this._canAcademyRecommendations) return false

      return this.getIsBasicPlanAdminUser && !this._canAcademyRecommendations
    },

    _canAcademyRecommendations() {
      return this.$can('access', _permissions.academy_recommendations)
    },

    _lockBenchmark() {
      if (this._canEngagementBenchmarkResults) return false
      return (
        this.getIsBasicPlanAdminUser && !this._canEngagementBenchmarkResults
      )
    },

    _showSmartPillars() {
      if (this._canEngagementClimateSmartMetrics) return true
      return (
        this.getIsBasicPlanAdminUser && !this._canEngagementClimateSmartMetrics
      )
    },

    _show() {
      const showList = this.states[this.state]

      const invalidState = !showList || !showList.length
      if (invalidState) {
        return []
      }

      const showAllComponents = showList[0] == '*'
      if (showAllComponents) {
        return Object.keys(this.stateComponents)
      }

      return this.states[this.state]
    },

    _score() {
      if (!this.groupID) {
        return this.accountInfo.score
      }

      return this.groupInfo.score
    },
    _participationRate() {
      if (!this.groupID) {
        return this.accountParticipationRate
      }

      return this.groupParticipationRate
    },
    _pillars() {
      if (!this.groupID) {
        return this.accountInfo.smartMetrics
      }

      return this.groupInfo.smartMetrics
    },
    _pillarsMetrics() {
      if (!this.groupID) {
        return this.accountInfo.metrics
      }

      return this.groupInfo.metrics
    },

    _canEngagementClimateSmartMetrics() {
      return this.$can('access', _permissions.engagement_climate_smart_metrics)
    },

    _canEngagementClimateCorrelationResults() {
      return this.$can(
        'access',
        _permissions.engagement_climate_correlation_results
      )
    },

    _canEngagementBenchmarkResults() {
      return this.$can('access', _permissions.engagement_benchmark_results)
    },

    _canEngagementClimateMetricsLNPS() {
      return this.$can('access', _permissions.engagement_climate_metrics_lnps)
    },

    _canEngagementClimateMetricsENPS() {
      return this.$can('access', _permissions.engagement_climate_metrics_enps)
    },

    _canEngagementClimateQuestions() {
      return (
        this.$can('access', _permissions.engagement_climate_questions) &&
        this.$can('access', _permissions.engagement_opinions)
      )
    },
  },
  watch: {
    _canAcademyRecommendations() {
      this.fetchRecommendationsResults(this.groupID, this.selectedPillar)
    },
    state(newState) {
      this.$nextTick(() => {
        this.scrollToUp()
      })

      // Verificando se existe o state informado, caso nao exista é colocado o primeiro state do array ('main')
      const stateKeys = Object.keys(this.states)
      if (!stateKeys.includes(newState)) {
        this.state = stateKeys[0]
      }
    },
    selectedPillar(newPillar) {
      this.state = newPillar ? 'selectedPillar' : 'main'
    },
    selectedSmartPillar(newSmartPillar) {
      this.state = newSmartPillar ? 'selectedSmartPillar' : 'main'
    },
    groupID() {
      this.initialFetchs()
    },
    demographicReportID() {
      this.initialFetchs()
    },
    variationPeriod() {
      this.fetchVariationClimate()
    },
  },
  methods: {
    showComponent(componentKey) {
      return this._show.some((key) => key === componentKey)
    },

    fetchComparisonByMetric(metricID) {
      if (!this.listComparisonMetrics[metricID]) return
      this.comparisonMetrics = this.listComparisonMetrics[metricID]
    },

    async fetchQuickComparisonClimateByMetric(metricID) {
      await getBenchmarkResults(metricID, this.groupID).then(({ data }) => {
        this.listComparisonMetrics[metricID] = structuredClone(
          data.indicators[0]
        )
      })
    },

    async fetchQuickComparison(metricID) {
      await getBenchmarkResults(metricID, this.groupID).then(({ data }) => {
        if (!data?.indicators?.length) {
          this.resetQuickComparison()
          return
        }

        this.comparisonMetrics = structuredClone(data.indicators[0])
      })
    },
    resetQuickComparison() {
      this.comparisonMetrics = {
        account: {
          rank: undefined,
          score: undefined,
        },
        average: {
          rank: undefined,
          score: undefined,
        },
        group: {
          rank: undefined,
          score: undefined,
        },
      }
    },

    async fetchHistory(groupID = this.groupID) {
      if (this.advancedHistory) {
        await this.fetchAdvancedHistory(groupID)
        return
      }

      if (this._canEngagementBenchmarkResults && !this.demographicReportID) {
        await this.fetchBenchmarkHistory(groupID)
        return
      }

      await this.fetchSimpleHistory(groupID)
    },
    async fetchAdvancedHistory(
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      this.loading.historyCard = true

      return await getClimateResultsAdvancedHistory(
        groupID,
        demographicReportID
      )
        .then(({ data }) => {
          if (!data.dates) {
            this.history = { metrics: [], dates: [] }
            return
          }

          const joinedMetrics = data.metrics
            .concat(data.smartMetrics)
            .filter((i) => !i.id.includes(':'))

          this.history.dates = data.dates
          this.history.metrics = joinedMetrics
        })
        .finally(() => {
          this.loading.historyCard = false
        })
    },
    async fetchSimpleHistory(
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      this.loading.historyCard = true

      return await getClimateResultsHistory(groupID, demographicReportID)
        .then(({ data }) => {
          if (!data.dates) {
            this.history = { metrics: [], dates: [] }
            return
          }

          this.history = data
        })
        .finally(() => {
          this.loading.historyCard = false
        })
    },
    async fetchBenchmarkHistory(groupID = this.groupID) {
      this.loading.historyCard = true

      return await getBenchmarkResultsHistory('climate', groupID)
        .then(({ data }) => {
          if (!data.dates) {
            this.history = { metrics: [], dates: [] }
            return
          }

          const history = { dates: data.dates, metrics: [] }
          const indicators = data.indicators[0]
          delete indicators.id

          for (const metric in indicators) {
            history.metrics.push({
              name: this.historyMetricsNames[metric],
              scores: indicators[metric],
            })
          }

          if (history.metrics.length === 3) {
            this.rotateRight(history.metrics)
          }

          this.history = history
        })
        .finally(() => {
          this.loading.historyCard = false
        })
    },
    async handleAdvancedHistory() {
      this.advancedHistory = !this.advancedHistory
      await this.fetchHistory()
    },

    async fetchNPSResults(
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      this.loading.enps = true
      getClimateNPSResults('enps', groupID, demographicReportID)
        .then((enps) => {
          this.results.enps = enps.data
        })
        .finally(() => {
          this.loading.enps = false
        })

      this.loading.lnps = true
      getClimateNPSResults('lnps', groupID, demographicReportID)
        .then((lnps) => {
          this.results.lnps = lnps.data
        })
        .finally(() => {
          this.loading.lnps = false
        })
    },
    async fetchBenchmark(indicatorID, groupID = this.groupID) {
      this.benchmark[indicatorID].locked = this._lockBenchmark

      const initializeVerticalLevelBar = (indicatorID) => {
        this.verticalLevelBar[indicatorID] = {
          group: undefined,
          account: undefined,
          average: undefined,
        }
      }

      const updateVerticalLevelBar = (indicatorID, metrics, benchmarks) => {
        for (let metric of metrics) {
          if (benchmarks[metric]) {
            this.verticalLevelBar[indicatorID][metric] =
              benchmarks[metric].score
            return
          }

          this.verticalLevelBar[indicatorID][metric] = undefined
        }
      }

      const processIndicatorID = (indicatorID, data, metrics, benchmarks) => {
        if (!data.id) {
          initializeVerticalLevelBar(indicatorID)
          return
        }

        updateVerticalLevelBar(indicatorID, metrics, benchmarks)
      }

      const updateBenchmarkWithDefaultValues = (indicatorID) => {
        this.benchmark[indicatorID] = {
          ...this.benchmark[indicatorID],
          score: 0,
          rank: 0,
        }
      }

      const updateBenchmarkWithAccountValues = (indicatorID, benchmarks) => {
        this.benchmark[indicatorID] = {
          ...this.benchmark[indicatorID],
          ...benchmarks.account,
        }
      }

      const updateBenchmarkWithGroupValues = (indicatorID, benchmarks) => {
        this.benchmark[indicatorID] = {
          ...this.benchmark[indicatorID],
          ...benchmarks.group,
        }
      }

      await getBenchmarkResults(indicatorID, groupID).then(({ data }) => {
        const metrics = ['account', 'average', 'group']
        const benchmarks = data.indicators[0]

        if (['enps', 'lnps'].includes(indicatorID)) {
          processIndicatorID(indicatorID, data, metrics, benchmarks)
        }

        if (!data.id) {
          updateBenchmarkWithDefaultValues(indicatorID)
          return
        }

        if (!groupID) {
          updateBenchmarkWithAccountValues(indicatorID, benchmarks)
          return
        }

        updateBenchmarkWithGroupValues(indicatorID, benchmarks)
      })
    },
    async fetchGroupInfo(
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      this.loading.pillars = true
      this.loading.smartPillars = true

      await getClimateResults(groupID, demographicReportID)
        .then(({ data }) => {
          if (!groupID) {
            this.accountInfo = { ...data }
            this.groupInfo = {}
            return
          }

          this.accountInfo = {}
          this.groupInfo = { ...data }
        })
        .finally(() => {
          this.loading.pillars = false
          this.loading.smartPillars = false
        })
    },
    async fetchGroupParticipation(groupID = this.groupID) {
      await getEngagementParticipation(groupID).then(({ data }) => {
        if (!groupID) {
          this.accountParticipationRate = data.confirmedRespondersRate
          return
        }

        this.groupParticipationRate = data.confirmedRespondersRate
      })
    },

    async fetchRecommendationsResults(id, metricId = null) {
      if (!this._canAcademyRecommendations) return
      this.loading.recommendations = true
      this.recommendations = []

      return await getRecommendationsResults(id, metricId)
        .then(({ data }) => {
          this.recommendations = structuredClone(data)
        })
        .finally(() => {
          this.loading.recommendations = false
        })
    },

    async fetchOpinions(metricID, groupID = this.groupID) {
      await getOpinions({
        metricID,
        groupID,
        limit: 4,
        offset: 0,
        sortBy: 'first-message',
      }).then(({ data }) => {
        this.opinions[metricID] = []

        data.map((opinion) => {
          this.opinions[metricID].push({
            id: opinion.id,
            message: opinion.lastMessage.message,
            score: opinion.score,
          })
        })
      })
    },

    async fetchOpinionsOfPillarsOfEngagementGroup(groupID = this.groupID) {
      const metrics = this._pillarsMetrics?.filter(
        ({ metricID }) =>
          this.pillarsOfEngagementVision &&
          (!this.selectedPillar || metricID === this.selectedPillar)
      )

      const getOpinionsByMetric = metrics.map(async (metric) => {
        return getOpinions({
          metricID: metric.metricID,
          groupID,
          limit: 4,
          offset: 0,
          sortBy: 'first-message',
        }).then(({ data }) => {
          return {
            metric: metric,
            opinions: data.map((opinion) => ({
              id: opinion.id,
              message: opinion.lastMessage.message,
              score: opinion.score,
            })),
          }
        })
      })

      const allOpinionsByMetric = await Promise.all(getOpinionsByMetric)
      const opinionsOfPillarsOfEngagementGroup = Object.fromEntries(
        allOpinionsByMetric.map((key) => [key.metric.metricID, key])
      )

      this.opinionsOfPillarsOfEngagementGroup =
        opinionsOfPillarsOfEngagementGroup
    },
    async fetchOpinionsOfPillarsSegmentation(groupID = this.groupID) {
      const smartMetrics = this._pillars?.filter(
        ({ metricID }) =>
          this.smartPillarsVision &&
          (!this.selectedSmartPillar || metricID === this.selectedSmartPillar)
      )

      const promises = smartMetrics.map(async (metric) => {
        return getOpinions({
          metricID: metric.metricID,
          groupID,
          limit: 4,
          offset: 0,
          sortBy: 'first-message',
        }).then(({ data }) => {
          return {
            metric: metric,
            opinions: data.map((opinion) => ({
              id: opinion.id,
              message: opinion.lastMessage.message,
              score: opinion.score,
            })),
          }
        })
      })

      const allOpinionsByMetric = await Promise.all(promises)
      const opinionsOfPillarSegmentation = Object.fromEntries(
        allOpinionsByMetric.map((key) => [key.metric.metricID, key])
      )

      this.opinionsOfPillarSegmentation = opinionsOfPillarSegmentation
    },
    async fetchOpinionsRate(groupID = this.groupID) {
      this.loading.avgCard = true

      await getEngagementOpinionsSummary('climate', groupID).then(
        ({ data }) => {
          const { answered } = data
          if (answered !== null) {
            this.opinionsRate = answered
            return
          }
          this.opinionsRate = null
        }
      )

      this.loading.avgCard = false
    },
    async fetchNPSResultsHistory(
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      getClimateNPSResultsHistory('enps', groupID, demographicReportID).then(
        ({ data: enps }) => {
          if (enps.length === 0) {
            this.resultsHistory.enps = []
            return
          }

          this.resultsHistory.enps = enps
        }
      )

      getClimateNPSResultsHistory('lnps', groupID, demographicReportID).then(
        ({ data: lnps }) => {
          if (lnps.length === 0) {
            this.resultsHistory.lnps = []
            return
          }

          this.resultsHistory.lnps = lnps
        }
      )
    },

    handlePillarsOfEngagementVision(refreshPillarsVision = false) {
      if (this.metricIDs.length === 0) {
        this.metricIDs = this._pillarsMetrics.map((metric) => metric.metricID)
      }

      this.pillarsOfEngagementVision = !this.pillarsOfEngagementVision

      if (refreshPillarsVision && this.pillarsOfEngagementVision) {
        this.loading.pillars = true
        this.getPillarsOfEngagementVision().finally(() => {
          this.loading.pillars = false
        })
      }
    },
    async handleSelectPillar(metricID) {
      if (!metricID) {
        return
      }

      this.loading.selectedPillar = true

      this.selectedPillar = metricID
      this.handlePillarsOfEngagementVision()

      this.fetchRecommendationsResults(this.groupID, metricID)
      if (!this.pillarsOfEngagementVision) {
        this.loading.selectedPillar = false
        return
      }

      this.getPillarsOfEngagementVision().finally(() => {
        this.loading.selectedPillar = false
      })
    },
    async handleRemovePillarSelection() {
      this.loading.pillars = true

      this.selectedPillar = null
      this.handlePillarsOfEngagementVision()

      this.fetchRecommendationsResults(this.groupID)
      if (!this.pillarsOfEngagementVision) {
        this.loading.pillars = false
        return
      }

      this.getPillarsOfEngagementVision().finally(() => {
        this.loading.pillars = false
      })
    },

    handleSmartPillarsVision(refreshPillarsVision = false) {
      if (this.submetricIDs.length === 0) {
        this.submetricIDs = this._pillars.map((m) => m.metricID)
      }

      this.smartPillarsVision = !this.smartPillarsVision

      if (refreshPillarsVision) {
        this.loading.smartPillars = true
        this.getSmartPillarsVision()
      }
    },
    async handleSelectSmartPillar(metricID) {
      if (!metricID) {
        return
      }

      this.loading.smartPillars = true

      this.selectedSmartPillar = metricID
      this.handleSmartPillarsVision()

      this.fetchRecommendationsResults(this.groupID, metricID)
      if (!this.smartPillarsVision) {
        this.loading.smartPillars = false
        return
      }

      this.getSmartPillarsVision().finally(() => {
        this.loading.smartPillars = false
      })
    },
    async handleRemovePillarSmartSelection() {
      this.loading.smartPillars = true

      this.selectedSmartPillar = null
      this.handleSmartPillarsVision()

      this.fetchRecommendationsResults(this.groupID)
      if (!this.smartPillarsVision) {
        this.loading.smartPillars = false
        return
      }

      this.getSmartPillarsVision().finally(() => {
        this.loading.smartPillars = false
      })
    },

    async fetchClimateAdvancedResultsMetrics(
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      this.pillarDetailsLoading = true
      this.pillarsAndFactorsHistoryLoading = true

      this.pillarsAndFactorsHistory = {
        factors: [[], [], [], [], [], [], [], [], [], []],
        metrics: [[], [], [], [], [], [], [], [], [], []],
        dates: [],
      }

      getClimateResultsAdvancedHistory(groupID, demographicReportID, true)
        .then(({ data }) => {
          this.pillarsAndFactorsHistory.dates = data.dates

          const allMetricsOrSelected = (metrics, selectedPillar) => {
            return metrics.filter((metric) => {
              if (!selectedPillar) {
                return true
              }

              return metric.id.includes(selectedPillar)
            })
          }

          const mapMetricsToData = (metrics, historyMetricsNames, groupID) => {
            return metrics.map((metric) => [
              {
                name: historyMetricsNames[groupID ? 'group' : 'account'],
                scores: metric.scores || [],
              },
            ])
          }

          const mapFactorsToData = (metrics, historyMetricsNames, groupID) => {
            return metrics.map((metric) => {
              const submetrics = metric?.submetrics || []

              return mapMetricsToData(submetrics, historyMetricsNames, groupID)
            })
          }

          const pillars = allMetricsOrSelected(
            this.sortByMetricID(data.metrics, 'history'),
            this.selectedPillar
          )

          const metrics = mapMetricsToData(
            pillars,
            this.historyMetricsNames,
            this.groupID
          )
          if (metrics.length > 0) {
            this.pillarsAndFactorsHistory.metrics = metrics
          }

          const factors = mapFactorsToData(
            pillars,
            this.historyMetricsNames,
            this.groupID
          )
          if (factors.length > 0) {
            this.pillarsAndFactorsHistory.factors = factors
          }
        })
        .finally(() => {
          this.pillarsAndFactorsHistoryLoading = false
        })

      await getClimateAdvancedResultsMetrics(
        undefined,
        groupID,
        demographicReportID
      )
        .then(({ data }) => {
          if (this.selectedPillar) {
            const details = data.find(
              ({ metricID }) => metricID === this.selectedPillar
            )

            this.pillarsDetails.push(details)
            return
          }

          this.pillarsDetails = data
        })
        .finally(() => {
          this.pillarDetailsLoading = false
        })
    },
    async fetchDetailPillarsAndFactors(groupID = this.groupID) {
      this.pillarsAndFactorsHistoryLoading = true
      this.pillarDetailsLoading = true
      this.pillarsDetails = []

      if (this.demographicReportID) {
        await this.fetchClimateAdvancedResultsMetrics()
        return
      }

      const { data } = await getBenchmarkResultsHistory(
        'metrics',
        groupID,
        true
      )

      this.pillarsAndFactorsHistory = {
        dates: data.dates,
        factors: [],
        metrics: [],
      }

      if (!data.dates) {
        await this.fetchClimateAdvancedResultsMetrics()
        return
      }

      const getSelectedPillar = async (pillars, selectedPillar) => {
        for (const pillar of pillars) {
          if (pillar.id === selectedPillar) {
            return pillar
          }
        }
      }

      const generatePillarsAndFactorsHistory = async (pillars, indicators) => {
        const factors = indicators.filter((indicator) =>
          indicator.id.includes(':')
        )

        const requests = pillars.map((pillar) =>
          this.generatePillars(pillar, factors)
        )

        await Promise.all(requests)

        this.pillarsAndFactorsHistoryLoading = false
        this.pillarDetailsLoading = false
      }

      const pillars = this.sortByMetricID(
        data.indicators.filter((i) => !i.id.includes(':')),
        'history'
      )

      if (!this.selectedPillar) {
        await generatePillarsAndFactorsHistory(pillars, data.indicators)
        return
      }

      const selectedPillar = await getSelectedPillar(
        pillars,
        this.selectedPillar
      )
      await generatePillarsAndFactorsHistory([selectedPillar], data.indicators)
    },
    async generatePillars(currentPillar, _factors) {
      this.loading.pillars = true

      const pillarID = currentPillar.id
      const { data } = await getClimateAdvancedResultsMetrics(
        pillarID,
        this.groupID,
        this.demographicReportID
      )

      const details = data[0]

      delete currentPillar.id
      if (!this._canEngagementBenchmarkResults) {
        delete currentPillar.average
      }

      const metrics = Object.entries(currentPillar).map(([metric, scores]) => ({
        name: this.historyMetricsNames[metric],
        scores: scores || [],
      }))

      if (metrics.length === 3) {
        this.rotateRight(metrics)
      }

      const filteredFactors = _factors.filter(
        (indicator) => !!indicator?.id?.includes(pillarID)
      )
      const factors = this.parseFactors(filteredFactors)

      this.pillarsDetails.push(details)
      this.pillarsAndFactorsHistory.metrics.push(metrics)
      this.pillarsAndFactorsHistory.factors.push(factors)

      this.loading.pillars = false
    },
    parseFactors(filteredFactors) {
      const factorsAgregated = []

      for (const factor in filteredFactors) {
        const metrics = []

        delete filteredFactors[factor].id
        if (!this._canEngagementBenchmarkResults) {
          delete filteredFactors[factor].average
        }

        for (const metric in filteredFactors[factor]) {
          metrics.push({
            name: this.historyMetricsNames[metric],
            scores: filteredFactors[factor][metric],
          })
        }

        if (metrics.length === 3) {
          this.rotateRight(metrics)
        }

        factorsAgregated.push(metrics)
      }

      return factorsAgregated
    },

    async fetchClimateAdvancedResultSmartMetrics(
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      this.smartPillarsHistoryLoading = true
      this.loading.smartPillars = true
      this.smartPillarsHistory = {
        metrics: [[], [], []],
        dates: [],
      }

      getClimateResultsAdvancedHistory(groupID, demographicReportID)
        .then(({ data }) => {
          this.smartPillarsHistory.dates = data.dates

          const smartPillars = this.sortByMetricID(
            data.smartMetrics,
            'history',
            this.submetricIDs
          )

          const metrics = smartPillars
            .filter((metric) => {
              if (!this.selectedSmartPillar) {
                return true
              }

              return metric.id.includes(this.selectedSmartPillar)
            })
            .map((metric) => [
              {
                name: this.historyMetricsNames[
                  this.groupID ? 'group' : 'account'
                ],
                scores: metric.scores || [],
              },
            ])

          if (metrics.length > 0) {
            this.smartPillarsHistory.metrics = metrics
          }
        })
        .finally(() => {
          this.smartPillarsHistoryLoading = false
        })

      await getClimateAdvancedResultSmartMetrics(
        undefined,
        groupID,
        demographicReportID
      )
        .then(({ data }) => {
          if (this.selectedSmartPillar) {
            const details = data.find(
              ({ metricID }) => metricID === this.selectedSmartPillar
            )

            this.smartMetricsAdvanced.push(details)
            return
          }

          this.smartMetricsAdvanced = data
        })
        .finally(() => {
          this.loading.smartPillars = false
        })
    },
    async fetchSmartPillarsHistory(groupID = this.groupID) {
      this.smartPillarsHistoryLoading = true
      this.smartMetricsAdvanced = []

      if (this.demographicReportID) {
        await this.fetchClimateAdvancedResultSmartMetrics()
        return
      }

      const { data } = await getBenchmarkResultsHistory(
        'smartMetrics',
        groupID,
        true
      )

      this.smartPillarsHistory = {
        dates: data.dates,
        metrics: [],
      }

      if (!data.dates) {
        this.fetchClimateAdvancedResultSmartMetrics()
        return
      }

      const smartPillars = this.sortByMetricID(
        data.indicators,
        'history',
        this.submetricIDs
      )

      const getSelectedSmartPillar = async (
        smartPillars,
        selectedSmartPillar
      ) => {
        for (const pillar of smartPillars) {
          if (pillar.id === selectedSmartPillar) {
            return pillar
          }
        }
      }

      const generateSmartPillars = async (smartPillars, groupID) => {
        const requests = smartPillars.map((smartPillars) =>
          this.generateSmartPillars(smartPillars, groupID)
        )

        await Promise.all(requests)

        this.smartPillarsHistoryLoading = false
      }

      if (!this.selectedSmartPillar) {
        await generateSmartPillars(smartPillars, groupID)
        return
      }

      const selectedSmartPillar = await getSelectedSmartPillar(
        smartPillars,
        this.selectedSmartPillar
      )
      await generateSmartPillars([selectedSmartPillar], groupID)
    },
    async generateSmartPillars(
      indicator,
      groupID = this.groupID,
      demographicReportID = this.demographicReportID
    ) {
      this.loading.smartPillars = true

      await getClimateAdvancedResultSmartMetrics(
        indicator.id,
        groupID,
        demographicReportID
      )
        .then(({ data }) => {
          delete indicator.id
          if (!this._canEngagementBenchmarkResults) {
            delete indicator.average
          }

          const metrics = Object.entries(indicator).map(([metric, scores]) => ({
            name: this.$t(`history.indicators.${metric}`),
            scores: scores || [],
          }))

          if (metrics.length === 3) {
            this.rotateRight(metrics)
          }

          this.smartMetricsAdvanced.push(data[0])
          this.smartPillarsHistory.metrics.push(metrics)
        })
        .finally(() => {
          this.loading.smartPillars = false
        })
    },
    async fetchPillarsBenchmark(groupID = this.groupID) {
      this.pillarsBenchmarksLocked = false

      this.pillarsBenchmarksLoading = true
      await getBenchmarkResults('metrics', groupID)
        .then(({ data }) => {
          if (!data?.date) {
            this.pillarsBenchmarks = []

            return
          }

          if (!this._canEngagementBenchmarkResults) {
            this.pillarsBenchmarksLocked = true
            return
          }

          let benchmarks = []
          if (groupID) {
            benchmarks = data.indicators.map((indicator) => ({
              [indicator.id]: indicator.group,
            }))
          } else {
            benchmarks = data.indicators.map((indicator) => ({
              [indicator.id]: indicator.account,
            }))
          }

          benchmarks = this.sortByMetricID(benchmarks, 'benchmark')
          this.pillarsBenchmarks = benchmarks
        })
        .finally(() => {
          this.pillarsBenchmarksLoading = false
        })
    },
    async fetchSmartPillarsBenchmark(groupID = this.groupID) {
      this.smartPillarsBenchmarksLocked = false

      this.smartPillarsBenchmarksLoading = true
      await getBenchmarkResults('smartMetrics', groupID)
        .then(({ data }) => {
          if (!data?.date) {
            this.smartPillarsBenchmarks = []
            return
          }

          if (!this._canEngagementBenchmarkResults) {
            this.smartPillarsBenchmarksLocked = true
            return
          }

          let benchmarks = []
          if (groupID) {
            benchmarks = data.indicators.map((indicator) => ({
              [indicator.id]: indicator.group,
            }))
          } else {
            benchmarks = data.indicators.map((indicator) => ({
              [indicator.id]: indicator.account,
            }))
          }

          benchmarks = this.sortByMetricID(
            benchmarks,
            'benchmark',
            this.submetricIDs
          )
          this.smartPillarsBenchmarks = benchmarks
        })
        .finally(() => {
          this.smartPillarsBenchmarksLoading = false
        })
    },
    async fetchCorrelatedPillars(groupID = this.groupID) {
      this.correlatedPillars = []
      this.correlatedPillarsLoading = true

      const metricIDs = this.metricIDs.filter(
        (metricID) =>
          this.pillarsOfEngagementVision &&
          (!this.selectedPillar || metricID === this.selectedPillar)
      )

      const correlationPromises = metricIDs.map((metricID) =>
        getClimateCorrelationResults(metricID, groupID)
      )

      const correlationResults = await Promise.all(correlationPromises)

      for (const { data } of correlationResults) {
        const isEmpty = data?.correlations?.every(
          (correlations) => correlations.value === null
        )

        if (isEmpty) {
          this.correlatedPillars.push([])
        } else {
          this.correlatedPillars.push(data.correlations)
        }
      }

      this.correlatedPillarsLoading = false
    },
    sortByMetricID(array, type, keys = this.metricIDs) {
      const newArray = []
      for (let metricID of keys) {
        let metric
        if (type == 'benchmark') {
          metric = array.find((i) => Object.keys(i)[0] == metricID)[metricID]
        } else if (type == 'history') {
          metric = array.find((i) => i.id == metricID)
        }
        newArray.push(metric)
      }
      return newArray
    },
    rotateRight(array) {
      const firstElement = array.shift()
      array.push(firstElement)
    },

    async fetchBenchmarkPillarGroup(metricId) {
      await getBenchmarkResults(metricId, this.groupID).then(({ data }) => {
        const indicators = data.indicators[0]

        this.quicklyComparisonData = {
          groupScore: indicators?.group?.score ?? null,
          accountScore: indicators?.account?.score ?? null,
          marketScore: indicators?.average?.score ?? null,
          groupPercent: indicators?.group?.rank ?? null,
          accountPercent: indicators?.account?.rank ?? null,
          marketPercent: indicators?.average?.rank ?? null,
          percentageOfCompanies:
            (indicators?.group ?? indicators?.account)?.rank ?? null,
        }
      })
    },
    async fetchCorrelations(metricId) {
      this.correlatedPillarsLoading = true

      await getClimateCorrelations(metricId, this.groupID)
        .then(({ data }) => {
          this.correlations = data?.correlations ?? []
        })
        .finally(() => {
          this.correlatedPillarsLoading = false
        })
    },

    resetQuickDetail() {
      this.correlations = []

      const QuicklyComparisonDataReset = {
        marketScore: null,
        accountPercent: null,
        accountScore: null,
        marketPercent: null,
      }

      if (this.groupID) {
        QuicklyComparisonDataReset.groupScore = null
      }

      this.quicklyComparisonData = QuicklyComparisonDataReset
    },

    fetchData(metricId) {
      this.fetchBenchmarkPillarGroup(metricId)

      if (this._canEngagementClimateCorrelationResults) {
        this.fetchCorrelations(metricId)
      }
    },

    scrollToUp() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    async fetchVariationClimate(
      groupID = this.groupID,
      period = this.variationPeriod
    ) {
      this.variation.loading = true
      await getVariation({ groupID, period })
        .then(({ data: variation }) => {
          this.variation.metrics = variation.metrics || []
          this.variation.smartMetrics = variation.smartMetrics || []
          this.variation.lnps = variation.lnps || {}
          this.variation.enps = variation.enps || {}
          this.variation.score = variation.score || null
          this.variation.participation = variation.participation || null
          this.variation.answeredOpinions = variation.answeredOpinions || null
        })
        .finally(() => {
          this.variation.loading = false
        })
    },

    resetCardAverageEngagement() {
      this.accountParticipationRate = null
      this.groupParticipationRate = null
      this.opinionsRate = null
    },

    resetBenchmark() {
      this.benchmark = {
        climate: {
          rank: 0,
          score: 0,
          locked: false,
        },
        enps: {
          rank: 0,
          score: 0,
          locked: false,
        },
        lnps: {
          rank: 0,
          score: 0,
          locked: false,
        },
      }
    },

    resetPillarsOfEngagementGroup() {
      this.pillarsBenchmarks = []
    },

    resetCardPillarSegmentation() {
      this.smartPillarsBenchmarks = []
    },

    resetVariation() {
      this.variation.metrics = []
      this.variation.smartMetrics = []
      this.variation.lnps = {}
      this.variation.enps = {}
      this.variation.score = null
      this.variation.participation = null
      this.variation.answeredOpinions = null
    },

    async getPillarsOfEngagementVision() {
      await this.fetchDetailPillarsAndFactors()

      if (this.demographicReportID) {
        this.resetPillarsOfEngagementGroup()
        return
      }

      await this.fetchPillarsBenchmark()
      await this.fetchOpinionsOfPillarsOfEngagementGroup()
    },

    async getSmartPillarsVision() {
      await this.fetchSmartPillarsHistory()

      if (this.demographicReportID) {
        this.resetCardPillarSegmentation()
        return
      }

      await this.fetchSmartPillarsBenchmark()
      await this.fetchOpinionsOfPillarsSegmentation()
    },

    async getBenchmarks() {
      if (this.demographicReportID) {
        this.resetBenchmark()
        return
      }

      await this.fetchBenchmark('climate')
      await this.fetchBenchmark('enps')
      await this.fetchBenchmark('lnps')
    },

    async getCardAverageEngagement() {
      if (this.demographicReportID) {
        this.resetCardAverageEngagement()
        return
      }

      await this.fetchGroupParticipation()
      await this.fetchOpinionsRate()
      await this.fetchQuickComparisonClimateByMetric('climate')
      await this.fetchQuickComparisonClimateByMetric('participation')
      await this.fetchQuickComparisonClimateByMetric('answeredOpinions')
    },

    async getOpinionsRate() {
      if (this.demographicReportID) {
        return
      }

      await this.fetchOpinions('enps')
      await this.fetchOpinions('lnps')
    },

    async getVariationClimate() {
      if (this.demographicReportID) {
        this.resetVariation()
        return
      }

      await this.fetchVariationClimate()
    },

    initialFetchs: debounce(async function () {
      this.fetchGroupInfo()
      this.fetchHistory()
      this.getBenchmarks()
      this.fetchNPSResults()
      this.getOpinionsRate()
      this.getCardAverageEngagement()
      this.fetchNPSResultsHistory()

      if (this.pillarsOfEngagementVision) {
        this.loading.pillars = true
        this.loading.selectedPillar = true

        this.getPillarsOfEngagementVision().finally(() => {
          this.loading.pillars = false
          this.loading.selectedPillar = false
        })
      }

      if (this.smartPillarsVision) {
        this.loading.smartPillars = true

        this.getSmartPillarsVision().finally(() => {
          this.loading.smartPillars = false
        })
      }

      this.fetchRecommendationsResults(this.groupID, this.selectedPillar)
      this.getVariationClimate()
    }, 500),
  },
  created() {
    this.$store.commit('setCurrentTab', 'ClimatePage')
  },
  mounted() {
    this.initialFetchs()
  },
}
</script>
