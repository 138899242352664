<template>
  <v-card class="cultural-maturity-container">
    <v-container>
      <div class="cultural-maturity-header">
        <h3 v-text="$t('engagementPage.cardCulturalMaturity.title')"></h3>
      </div>

      <template v-if="_isLoading">
        <div class="cultural-maturity-content loading">
          <div class="maturity-level">
            <v-skeleton-loader
              class="loading-vertical-bar vertical-level"
              type="chip"
            ></v-skeleton-loader>
          </div>

          <div class="maturity-details">
            <div class="maturity-details-title" data-test-score>
              <v-skeleton-loader
                class="loading-score"
                type="chip"
              ></v-skeleton-loader>

              <v-skeleton-loader
                class="loading-variation"
                type="button"
              ></v-skeleton-loader>
            </div>

            <v-skeleton-loader
              class="loading-maturity"
              type="list-item"
              max-width="200px"
            ></v-skeleton-loader>

            <v-skeleton-loader
              class="loading-maturity"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </div>
        </div>

        <div class="cultural-maturity-footer loading">
          <v-skeleton-loader
            class="loading-maturity"
            type="list-item-two-line"
          ></v-skeleton-loader>

          <div class="maturity-footer__links">
            <HowToBeginAndWatchAVideo
              videoType="culture.maturity"
            ></HowToBeginAndWatchAVideo>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="cultural-maturity-content">
          <div class="maturity-level">
            <VerticalLevelBar
              type="culture"
              :groupMarker="_groupScore"
              :accountMarker="_accountScore"
              :marketMarker="_marketScore"
              :marketPermission="true"
            ></VerticalLevelBar>
          </div>

          <div class="maturity-details">
            <div class="maturity-details-title" data-test-score>
              <span
                class="maturity-details-title__text"
                data-test-maturity-details-title-text
                :style="{
                  'font-size': calcScoreStretchFontSize(cultureResults.score)
                    .fontSize,
                }"
                v-text="calcScoreStretchFontSize(cultureResults.score).text"
              ></span>

              <Variation
                :variation="variation"
                type="percent"
                class="variation-div"
                short
              ></Variation>
            </div>

            <label
              class="maturity-details__label"
              v-text="
                cultureResults.maturity.current
                  ? $t(`engagementPage.cardCulturalMaturity.subtitle`, {
                      current: cultureResults.maturity.current,
                    })
                  : $t(`engagementPage.cardCulturalMaturity.subtitleEmpty`)
              "
            ></label>

            <span
              class="maturity-details__text"
              v-if="_cultureRank"
              v-html="
                $t(`engagementPage.cardCulturalMaturity.text`, {
                  rank: _cultureRank,
                })
              "
            ></span>
            <span
              class="maturity-details__text"
              v-else
              data-cy="rank"
              v-html="$t(`engagementPage.cardCulturalMaturity.textEmpty`)"
            ></span>
          </div>
        </div>

        <div class="cultural-maturity-footer">
          <span
            class="maturity-footer__text"
            v-text="
              resultsBenchmark.date
                ? $t(`engagementPage.cardCulturalMaturity.details`, {
                    date: formatDate(resultsBenchmark.date),
                  })
                : $t(`engagementPage.cardCulturalMaturity.detailsEmptyStage`, {
                    date: formatDate(resultsBenchmark.date),
                  })
            "
          ></span>

          <div class="maturity-footer__links">
            <HowToBeginAndWatchAVideo
              videoType="culture.maturity"
            ></HowToBeginAndWatchAVideo>
          </div>
        </div>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import VerticalLevelBar from '@/components/VerticalLevelBar/VerticalLevelBar.vue'
import Variation from '@/components/Variation/Variation.vue'
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

export default {
  name: 'CardCulturalMaturity',
  components: {
    VerticalLevelBar,
    Variation,
    HowToBeginAndWatchAVideo,
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    resultsBenchmark: {
      type: Object,
      default: () => ({
        date: '',
        id: '',
        indicators: [],
      }),
    },
    cultureResults: {
      type: Object,
      default: () => ({
        maturity: {
          current: 0,
        },
        score: '',
      }),
    },
    cultureRankByGroup: {
      type: Boolean,
      default: false,
    },
    variation: Number,
  },
  computed: {
    _accountScore() {
      return this.calcVerticalBarValue(this.resultsBenchmark, 'account')
    },
    _groupScore() {
      return this.calcVerticalBarValue(this.resultsBenchmark, 'group')
    },
    _marketScore() {
      return this.calcVerticalBarValue(this.resultsBenchmark, 'average')
    },

    _cultureRank() {
      const accountRank = this.getResultsBenchmarkIndicatorScoreByKey(
        this.resultsBenchmark,
        'account',
        'rank'
      )
      const groupRank = this.getResultsBenchmarkIndicatorScoreByKey(
        this.resultsBenchmark,
        'group',
        'rank'
      )

      return this.cultureRankByGroup ? groupRank : accountRank
    },

    _isLoading() {
      return !(!this.cultureResults.maturity || !this.loading)
    },
  },
  methods: {
    getResultsBenchmarkIndicatorScoreByKey(
      resultsBenchmark,
      type,
      key = 'score'
    ) {
      const maturityLevelIndicator = resultsBenchmark?.indicators?.find(
        (indicator) => indicator?.id === 'maturityLevel'
      )
      const indicatorType = maturityLevelIndicator?.[type]
      return indicatorType?.[key]
    },

    calcVerticalBarValue(resultsBenchmark, type) {
      const score = this.getResultsBenchmarkIndicatorScoreByKey(
        resultsBenchmark,
        type
      )

      return score ?? -1
    },

    calcScoreStretchFontSize(score) {
      const text = score ? score + '%' : '--'
      const fontSize = text.length > 5 ? '2.7rem' : '3.56rem'

      return {
        fontSize,
        text,
      }
    },

    formatDate(date) {
      if (!date) {
        return ''
      }

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
