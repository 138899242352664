<template>
  <div id="card-cloud-tags" class="shadow">
    <v-card elevation="0" height="100%">
      <v-card-title class="header-title">
        <h3>{{ $t('engagementPage.opinions.cards.cloudTags.title') }}</h3>
      </v-card-title>
      <v-divider class="custom-divider" />
      <CloudTagsChart class="ma-6" :loading="loading" :tags="tags" />
    </v-card>
  </div>
</template>

<script>
import CloudTagsChart from '@/components/CloudTagsChart/CloudTagsChart.vue'

export default {
  name: 'CardCloudTags',
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CloudTagsChart,
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
