<template>
  <div>
    <div class="skeleton" v-if="!sections.length">
      <v-card v-for="i in 2" :key="i" elevation="0" class="pt-2 pl-4 pr-4 pb-2">
        <div class="title">
          <v-skeleton-loader
            class="title__text"
            type="text"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="title__button"
            type="button"
          ></v-skeleton-loader>
        </div>

        <div class="content pt-4">
          <v-skeleton-loader
            class="content__infor mr-2"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="content__infor ml-2"
            type="image"
          ></v-skeleton-loader>
        </div>
      </v-card>
    </div>

    <PageCard
      v-for="(section, i) in sections"
      :key="section.id"
      :id="section.id"
      :class="{ 'mt-9': i > 0 }"
      :title="$t(`benchmarkPage.card.titles.${section.id}`)"
      :subtitle="$t(`benchmarkPage.card.subtitles.${section.id}`)"
      :video-type="`benchmark.indicators.${section.id}`"
      permission="engagement.benchmark.results"
    >
      <template #append>
        <BadgeReports
          :value="section.id"
          :active="showDetails.includes(section.id)"
          :label="$t('benchmarkPage.btnReports')"
          @click="handleShowDetails(section.id, section.itens)"
          data-test-complete-report
        />
      </template>

      <div v-show="showDetails.includes(section.id)">
        <v-row v-for="item in section.itens" :key="item.id" class="custom-row">
          <v-col cols="12" sm="12" md="4" class="custom-col">
            <QuicklyComparison
              type="benchmark"
              v-bind="comparisonAttrs(item, section.id)"
            />
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <v-lazy>
              <v-container>
                <HistoryChart
                  :metrics="getResultHistoryIndicators(item.id)"
                  :dates="history.dates"
                  data-test-benchmark-history
                />
              </v-container>
            </v-lazy>
          </v-col>
        </v-row>
      </div>

      <div v-show="!showDetails.includes(section.id)">
        <v-row class="custom-row-card justify-space-between">
          <v-col v-for="item in section.itens" :key="item.id" sm="12" md="6">
            <v-col class="custom-col">
              <QuicklyComparison
                type="benchmark"
                v-bind="comparisonAttrs(item, section.id)"
              />
            </v-col>
          </v-col>
        </v-row>
      </div>
    </PageCard>
  </div>
</template>

<script>
import QuicklyComparison from '@/components/QuicklyComparison/QuicklyComparison.vue'
import {
  getBenchmarkResults,
  getBenchmarkResultsHistory,
} from '@/service/benchmark'
import PageCard from '@/components/PageCard/PageCard.vue'
import BadgeReports from '@/components/BadgeReports/BadgeReports.vue'
import HistoryChart from '@/components/HistoryChart/HistoryChart.vue'

import { getVariation as getVariationClimate } from '@/service/climate'
import { getVariation as getVariationCulture } from '@/service/culture'

export default {
  name: 'IndicatorsPage',
  components: {
    PageCard,
    QuicklyComparison,
    BadgeReports,
    HistoryChart,
  },
  props: {
    groupID: {
      type: String,
      default: '',
    },
    variationPeriod: String,
  },
  data: () => ({
    showRankingScale: false,
    sections: [],
    showDetails: [],
    isMounted: false,
    history: [],
    indicatorsReference: [
      ['globals', 'climate', 'enps', 'lnps', 'maturityLevel'],
      [
        'engagementIndicators',

        'managerRelationship',
        'managerRelationship:collaboration',
        'managerRelationship:trust',
        'managerRelationship:communication',

        'ambassadorship',
        'ambassadorship:promotor',
        'ambassadorship:pride',

        'alignment',
        'alignment:values',
        'alignment:vision',
        'alignment:ethics',

        'wellness',
        'wellness:stress',
        'wellness:personal_health',
        'wellness:mental_health',

        'personalGrowth',
        'personalGrowth:autonomy',
        'personalGrowth:mastery',
        'personalGrowth:purpose',

        'happiness',
        'happiness:work_happiness',
        'happiness:work_balance',

        'satisfaction',
        'satisfaction:compensation',
        'satisfaction:role',
        'satisfaction:workplace',

        'recognition',
        'recognition:recognition_quality',
        'recognition:recognition_frequency',

        'peersRelationship',
        'peersRelationship:collaboration',
        'peersRelationship:trust',
        'peersRelationship:communication',

        'feedback',
        'feedback:suggestions',
        'feedback:quality',
        'feedback:frequency',
      ],
      ['smartPillars', 'psychologicalSafety', 'burnout', 'diversity'],
      ['participationAndOpinions', 'participation', 'answeredOpinions'],
    ],
    climateVariation: {},
    cultureVariation: {},
  }),
  watch: {
    async groupID(val) {
      await this.fetchVariation()

      if (val) {
        this.fetchBenchmarkResults(val)
        this.fetchBenchmarkResultsHistory(val)
        return
      }
      this.fetchBenchmarkResults()
      this.fetchBenchmarkResultsHistory()
    },
    _mobile: function (val) {
      if (val) {
        this.sections.forEach((el) => {
          if (
            localStorage.getItem(`preferences.benchmark.${el.id}`) == 'true'
          ) {
            localStorage.setItem(`preferences.benchmark.${el.id}`, 'false')
          }
        })
        this.showDetails = []
      }
    },
    variationPeriod() {
      this.fetchVariation()
    },
  },
  computed: {
    _mobile() {
      return (
        this.isMounted && ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)
      )
    },
  },
  methods: {
    getResultHistoryIndicators(indicatorID) {
      const currentHistoryInfor = []

      let names = {
        group: this.$t('history.indicators.group'),
        account: this.$t('history.indicators.account'),
        average: this.$t('history.indicators.average'),
      }

      if (this.history.indicators && this.history.indicators.length) {
        const currentIndicator = [...this.history.indicators].filter((el) => {
          return el.id == indicatorID
        })[0]

        for (const metric in currentIndicator) {
          if (metric !== 'id') {
            currentHistoryInfor.push({
              name: names[metric],
              scores: currentIndicator[metric],
            })
          }
        }
      }

      return currentHistoryInfor
    },
    async fetchBenchmarkResults(groupID) {
      await getBenchmarkResults(undefined, groupID, true).then(({ data }) =>
        this.filterBenchmarkResult(data.indicators)
      )
    },
    async fetchBenchmarkResultsHistory(groupID) {
      await getBenchmarkResultsHistory(undefined, groupID, true).then(
        ({ data }) => this.filterBenchmarkResultHistory(data)
      )
    },
    filterBenchmarkResultHistory(data) {
      if (!data.dates) {
        this.history = { indicators: [], dates: [] }
      } else {
        this.history = {
          dates: [...data.dates],
          indicators: [...data.indicators],
        }
      }

      this.sections.forEach((el) => {
        if (localStorage.getItem(`preferences.benchmark.${el.id}`) == 'true') {
          this.showDetails.push(el.id)
        }
      })
    },
    filterBenchmarkResult(indicators) {
      const filteredItens = this.indicatorsReference.reduce(
        (acumulator, section) => {
          const item = {}

          item.id = section[0]
          item.itens = []

          section.slice(1).forEach((currentIndicator) => {
            const result = indicators.find(
              (indicator) => indicator.id === currentIndicator
            )

            if (result) item.itens.push(result)
          })

          acumulator.push(item)

          return acumulator
        },
        []
      )

      this.sections = [...filteredItens.map((i) => Object.assign({}, i))]
    },
    findMetricVariationInArray(variationObjectKey, metricID) {
      const variation = this.climateVariation[variationObjectKey].find(
        (metricVariation) => metricVariation.metricID === metricID
      )

      return variation ? variation.score : null
    },
    getVariation(sectionID, metricID) {
      const hasClimateVariation =
        Object.keys(this.climateVariation).length !== 0

      const hasCultureVariation =
        Object.keys(this.cultureVariation).length !== 0

      const globalsVariationByMetricID = {
        climate: hasClimateVariation ? this.climateVariation.score : null,
        enps: hasClimateVariation ? this.climateVariation.enps.score : null,
        lnps: hasClimateVariation ? this.climateVariation.lnps.score : null,
        maturityLevel: hasCultureVariation
          ? this.cultureVariation.maturity
          : null,
      }

      const participationAndOpinionsVariationByMetricID = {
        participation: hasClimateVariation
          ? this.climateVariation.participation
          : null,
        answeredOpinions: hasClimateVariation
          ? this.climateVariation.answeredOpinions
          : null,
      }

      switch (sectionID) {
        case 'globals':
          return globalsVariationByMetricID[metricID]
        case 'engagementIndicators':
          return hasClimateVariation
            ? this.findMetricVariationInArray('metrics', metricID)
            : null
        case 'smartPillars':
          return hasClimateVariation
            ? this.findMetricVariationInArray('smartMetrics', metricID)
            : null
        case 'participationAndOpinions':
          return participationAndOpinionsVariationByMetricID[metricID]
        default:
          return null
      }
    },
    comparisonAttrs(item, sectionID) {
      this.$t.bind(this)
      const variation = this.getVariation(sectionID, item.id)

      const obj = {
        title: this.$t(`benchmarkPage.quicklyComparison.${item.id}`),
        marketScore: item.average.score,
        accountScore: item.account.score,
        accountPercent: item.account.rank,
        marketPercent: item.average.rank,
        hideMarketScore: false,
        percentageOfCompanies: item.account.rank,
        variation: variation,
        variationDataName: item.id,
      }

      if (item.hasOwnProperty('group')) {
        obj.groupScore = item.group.score
        obj.groupPercent = item.group.rank
        obj.percentageOfCompanies = item.group.rank
      }

      return obj
    },
    handleShowDetails(section, items) {
      this.fetchBenchmarkResultsHistory()
      if (this.showDetails.includes(section, items)) {
        localStorage.setItem(`preferences.benchmark.${section}`, 'false')

        items.forEach((el) => {
          delete this.history[`${el.id}`]
        })

        this.showDetails = this.showDetails.filter((s) => s !== section)
        return
      }

      localStorage.setItem(`preferences.benchmark.${section}`, 'true')
      this.showDetails.push(section)
    },
    async fetchVariation() {
      getVariationCulture({
        groupID: this.groupID,
        period: this.variationPeriod,
      }).then(({ data }) => {
        this.cultureVariation = data
      })

      getVariationClimate({
        groupID: this.groupID,
        period: this.variationPeriod,
      }).then(({ data }) => {
        this.climateVariation = data
      })
    },
  },
  async mounted() {
    await this.fetchVariation()

    this.fetchBenchmarkResults(this.groupID).then(() => {
      this.sections.forEach((el) => {
        if (localStorage.getItem(`preferences.benchmark.${el.id}`) == 'true') {
          this.showDetails.push(el.id)
        }
      })
      if (this.showDetails.length)
        this.fetchBenchmarkResultsHistory(this.groupID)
    })

    this.isMounted = true
  },
  created() {
    this.$store.commit('setCurrentTab', 'BenchmarkPage')
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
