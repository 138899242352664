<template>
  <div :id="`card-vertical-bars-${id}`" class="shadow">
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card v-else elevation="0" height="100%">
      <div class="px-4 py-3">
        <span class="title">
          {{ title }}
        </span>
      </div>
      <v-divider class="custom-divider" />
      <VerticalBars
        :types="types"
        :values="values"
        :colors="colors"
        :labels="customLabels"
        :noDataLabel="noDataLabel"
      />
    </v-card>
  </div>
</template>

<script>
import VerticalBars from '@/components/VerticalBars/VerticalBars.vue'
import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'CardVerticalBars',
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    series: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
    noDataLabel: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VerticalBars,
  },
  data() {
    return {
      colors: [variables.greenColor5, variables.yellowColor6, variables.violet],
      types: [],
      values: [],
      customLabels: [],
    }
  },
  watch: {
    series(newVal) {
      this.values = newVal
    },
    categories(newVal) {
      this.types = newVal
    },
    labels(newVal) {
      this.customLabels = newVal
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
