import Vue from 'vue'
import Vuex from 'vuex'

import currentUser from './modules/currentUser'

import { SideModule } from './modules/side'
import { DialogModule } from './modules/dialog'

Vue.use(Vuex)

/**
 * @typedef {'free' | 'basic' | 'advanced' | 'enterprise'} PackageType
 */

/**
 * @type {import('vuex').Store}
 */
export default new Vuex.Store({
  modules: {
    currentUser,

    Side: SideModule,
    Dialog: DialogModule,
  },

  state: {
    Engagement: {
      isSignedIn: true,
      currentTab: '',
    },

    account: {},

    client: {
      ...JSON.parse(sessionStorage.getItem('client') || '{}'),

      /**
       * @type {PackageType}
       */
      package: 'basic',
      paymentMethod: 'card',

      id: 'MOCK_ID',
      name: 'John Doe',
      photo: null,
    },

    groupSelected: [],
    groupId: '',
    groupIds: [],

    demographicReportID: null,

    survey: {
      state: 'init', // init, steps, feedback, finish, forbidden, other //
      accessibility: false,
      blocked: false,
      finished: false,
      questions: [],
      options: {
        type: null,
        metricID: null,
      },
      step: {
        index: 0,
      },
      haveAnswer: false,
      isAnsweringOtherSubject: false,
      answers: {
        feedback: null,
        opinion: null,
        other: null,
      },
    },

    favorites: {
      itemSelectedIndex: null,
      itemEditIndex: null,
      itemEditValue: null,
      items: [],
    },

    avatar: {
      idToColors: {},
    },
  },

  mutations: {
    SET_SIGNED_IN(state, payload) {
      state.Engagement.isSignedIn = payload
    },
    setCurrentTab(state, payload) {
      state.Engagement.currentTab = payload
    },

    SET_ACCOUNT(state, payload) {
      Vue.set(state, 'account', payload)
    },

    SET_CLIENT(state, payload) {
      Vue.set(state, 'client', payload)
    },
    changeClientPackage(state, payload) {
      Vue.set(state.client, 'package', payload)
    },

    SET_GROUP_ID(state, payload) {
      state.groupId = payload
    },
    SET_GROUP_IDS(state, payload) {
      state.groupIds = payload
    },
    setGroupIds(state, payload) {
      Vue.set(state, 'groupIds', payload)
    },
    setGroupId(state, payload) {
      Vue.set(state, 'groupId', payload)
      Vue.set(state, 'demographicReportID', null)
    },
    setGroupSelected(state, payload) {
      Vue.set(state, 'groupSelected', payload)
    },

    SET_DemographicReportID(state, payload) {
      state.demographicReportID = payload
    },

    SET_SURVEY(state, payload) {
      state.survey = payload
    },
    SET_SURVEY_STATE(state, payload) {
      state.survey.state = payload
      state.survey.haveAnswer = false
    },
    SET_SURVEY_QUESTIONS(state, payload) {
      state.survey.questions = payload
    },
    SET_SURVEY_STEP_ANSWER(state, payload) {
      state.survey.questions[payload.index]['answered'] = payload.answer
      state.survey.haveAnswer = !!payload.answer
    },
    SET_SURVEY_FEEDBACK_ANSWER(state, payload) {
      const index = state.survey.questions.length - 1
      state.survey.questions[index]['answered']['opinion'] = payload
      state.survey.haveAnswer =
        !!payload && !!payload.text && !!payload.text.length
    },
    SET_SURVEY_STEP_INDEX(state, payload) {
      state.survey.step.index = payload
      state.survey.haveAnswer = false
    },
    SET_SURVEY_ACCESSIBILITY(state, payload) {
      state.survey.accessibility = payload
    },
    SET_SURVEY_BLOCKED(state, payload) {
      state.survey.blocked = payload
    },
    SET_SURVEY_OPTIONS(state, payload) {
      state.survey.options = payload
    },
    SET_SURVEY_ANSWERS(state, payload) {
      state.survey.answers = payload
    },

    SET_FAVORITES(state, payload) {
      state.favorites.items = payload
      state.favorites.itemSelectedIndex = null
      state.favorites.itemEditIndex = null
      state.favorites.itemEditValue = null
    },
    ADD_FAVORITE(state, payload) {
      state.favorites.items.push(payload)
    },
    REMOVE_FAVORITE(state, index) {
      state.favorites.items.splice(index, 1)
      state.favorites.itemSelectedIndex = null
      state.favorites.itemEditIndex = null
      state.favorites.itemEditValue = null
    },

    setAvatarIdToColors(state, payload) {
      if (state.avatar.idToColors[`${payload.idx}`]) {
        return
      }

      state.avatar.idToColors[`${payload.idx}`] = payload.color
    },
  },

  actions: {
    setSignedInLogin({ commit }) {
      commit('SET_SIGNED_IN', true)
    },
    setSignedInLogout({ commit }) {
      commit('SET_SIGNED_IN', false)
    },
    setAccount({ commit }, payload) {
      commit('SET_ACCOUNT', payload)
    },
    setClient({ commit }, payload) {
      commit('SET_CLIENT', payload)
    },

    setGroupId({ commit }, payload) {
      commit('SET_GROUP_ID', payload)
      commit('SET_DemographicReportID', null)
    },
    setGroupIds({ commit }, payload) {
      commit('SET_GROUP_IDS', payload)
    },

    setDemographicReportID({ commit }, payload) {
      commit('SET_DemographicReportID', payload)
    },

    setSurvey({ commit }, payload) {
      commit('SET_SURVEY', payload)
    },
    setSurveyState({ commit }, payload) {
      commit('SET_SURVEY_STATE', payload.state)
    },
    setSurveyQuestions({ commit }, { questions }) {
      commit('SET_SURVEY_QUESTIONS', questions)
    },
    setSurveyStepAnswer({ commit }, { index, answer }) {
      commit('SET_SURVEY_STEP_ANSWER', { index, answer })
    },
    setSurveyFeedbackAnswer({ commit }, { opinion }) {
      commit('SET_SURVEY_FEEDBACK_ANSWER', opinion)
    },
    setSurveyStepIndex({ commit }, { index }) {
      commit('SET_SURVEY_STEP_INDEX', index)
    },
    setSurveyAccessibility({ commit }, payload) {
      commit('SET_SURVEY_ACCESSIBILITY', payload)
    },
    setSurveyBlocked({ commit }, payload) {
      commit('SET_SURVEY_BLOCKED', payload)
    },
    setSurveyOptions({ commit }, { type, metricID }) {
      commit('SET_SURVEY_OPTIONS', { type, metricID })
    },
    resetSurveyOptions({ commit }) {
      commit('SET_SURVEY_OPTIONS', { type: null, metricID: null })
    },
    resetSurveyAnswers({ commit }) {
      commit('SET_SURVEY_ANSWERS', [])
      commit('SET_SURVEY_QUESTIONS', [])
      commit('SET_SURVEY_STEP_INDEX', 0)
    },

    setFavorites({ commit }, payload) {
      commit('SET_FAVORITES', payload)
    },
    addFavorite({ commit }, payload) {
      commit('ADD_FAVORITE', payload)
    },
    removeFavorite({ commit }, { index }) {
      commit('REMOVE_FAVORITE', index)
    },
  },

  getters: {
    getIsSignedIn: ({ Engagement }) => Engagement.isSignedIn,
    getCurrentTab: ({ Engagement }) => Engagement.currentTab,

    getUserDetailsLoggable: (state) => {
      return {
        accountName: state.account.name,
        planID: state.client.planID,

        userID: state.client.id,
        userName: state.client.name,
        userEmail: state.client.email,
      }
    },

    getUserDetails: (state) => {
      return {
        id: state.client.id,
        name: state.client.name,
        photo: state.client.photo,
      }
    },
    getPaymentMethod: (state) => state.client.paymentMethod,
    getClientPackage: (state) => state.client.package,
    getClientPermissions: (state) => state.client.permissions || [],
    getIsBasicPlanAdminUser: (state) => {
      const isAdmin = !!state.client?.isAdmin
      const isBasic = state.client.package === 'basic'

      return isAdmin && isBasic
    },

    getGroupId: ({ groupId }) => groupId,
    getGroupSeleted: ({ groupSelected }) => groupSelected,

    getAvatarIdToColors: (state) => state.avatar.idToColors || {},
  },
})
