<template>
  <div :id="`card-average-time-${id}`" class="shadow">
    <v-skeleton-loader v-if="loading" type="image"></v-skeleton-loader>
    <v-card v-else elevation="0" height="100%" ref="card">
      <div class="px-4 py-3">
        <span class="title">{{ title }}</span>
      </div>
      <v-divider class="custom-divider" />
      <div class="card-average-time_body">
        <div v-if="!_isEmpty" class="flex-column">
          <div
            class="flex"
            :style="{ 'font-size': fontSize, 'line-height': fontSize }"
          >
            <span v-if="_days != 0" class="value">{{ _days }}</span>
            <span v-if="_days != 0" class="unit">{{
              $t('engagementPage.opinions.cards.averageTime.units.day')
            }}</span>
            <span v-if="_hours !== 0" class="value">{{ _hours }}</span>
            <span v-if="_hours !== 0" class="unit">{{
              $t('engagementPage.opinions.cards.averageTime.units.hour')
            }}</span>
            <span v-if="_days == 0" class="value">{{ _minutes }}</span>
            <span v-if="_days == 0" class="unit">{{
              $t('engagementPage.opinions.cards.averageTime.units.minute')
            }}</span>
          </div>
          <Variation
            class="variation"
            :variation="variation"
            type="min"
            short
            :inverted="variationInverted"
            data-test-progress-line-variation
          />
        </div>
        <div v-else>
          <div class="flex">
            <span id="empty-value" class="value">--</span>
          </div>
          <Variation
            class="variation"
            :variation="variation"
            type="min"
            short
            :inverted="variationInverted"
            data-test-progress-line-variation
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Variation from '@/components/Variation/Variation.vue'
import moment from 'moment'

export default {
  name: 'CardAverageTime',
  components: {
    Variation,
  },
  data() {
    return {
      fontSize: 4.375,
    }
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    time: {
      type: Number,
      default: 0,
    },
    variation: {
      type: Number,
      default: 0,
    },
    variationInverted: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    window.addEventListener('resize', this.calcFontSize)
  },
  destroyed() {
    window.removeEventListener('resize', this.calcFontSize)
  },
  methods: {
    calcFontSize() {
      const card = this.$refs?.card?.$el
      const width = card?.getBoundingClientRect().width
      let size = width / 105

      if (size > 4.375) size = 4.375
      if (size < 3.0) size = 3.0

      this.fontSize = size + 'rem'
    },
  },
  computed: {
    _isEmpty() {
      return this._days == 0 && this._hours == 0 && this._minutes == 0
    },
    _momentTime() {
      return moment.duration(this.time, 'milliseconds')
    },
    _days() {
      return this._momentTime.asDays().toFixed(0)
    },
    _hours() {
      return this._momentTime.hours()
    },
    _minutes() {
      return this._momentTime.minutes()
    },
  },
  mounted() {
    this.calcFontSize()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
