<template>
  <div class="card">
    <QuicklyComparison
      :benchmarkPermissions="benchmarkPermissions"
      :hasSelectedGroup="hasSelectedGroup"
      :groupScore="quicklyComparisonData['groupScore']"
      :accountScore="quicklyComparisonData['accountScore']"
      :marketScore="quicklyComparisonData['marketScore']"
      :groupPercent="quicklyComparisonData['groupPercent']"
      :accountPercent="quicklyComparisonData['accountPercent']"
      :marketPercent="quicklyComparisonData['marketPercent']"
      :hideMarketScore="
        quicklyComparisonData['hideMarketScore'] || hideInformations
      "
      :percentageOfCompanies="quicklyComparisonData['percentageOfCompanies']"
    ></QuicklyComparison>

    <div class="factors-card">
      <div class="factors-card-detail">
        <span
          class="factors-card-title"
          v-text="$t('quickDetail.factorsCard.title')"
        ></span>
      </div>

      <div class="factors-card-scrollbar">
        <PillarAndFactor
          v-for="(item, index) in pillarAndFactorData"
          :key="index"
          :image="item.image"
          :title="item.label"
          :score="item.score"
          :grade="item.score"
          :locked="false"
          small
        ></PillarAndFactor>
      </div>
    </div>

    <div class="correlated-pillars-card">
      <CorrelatedPillars
        v-if="showCorrelatedPillars"
        :isBlocked="hideCorrelatedPillars"
        :correlations="correlationsData"
        :showAxelTip="false"
        :loading="false"
      ></CorrelatedPillars>
    </div>
  </div>
</template>

<script>
import QuicklyComparison from '@/components/QuicklyComparison/QuicklyComparison.vue'
import CorrelatedPillars from '@/components/CorrelatedPillars/CorrelatedPillars.vue'
import PillarAndFactor from '@/components/PillarAndFactor/PillarAndFactor.vue'

export default {
  name: 'QuickDetail',
  components: {
    QuicklyComparison,
    CorrelatedPillars,
    PillarAndFactor,
  },
  props: {
    showCorrelatedPillars: {
      type: Boolean,
    },
    hideCorrelatedPillars: {
      type: Boolean,
    },
    hideInformations: {
      type: Boolean,
      required: true,
    },
    pillarAndFactorData: {
      type: Array,
      required: false,
    },
    quicklyComparisonData: {
      type: Object,
      required: true,
    },
    correlationsData: {
      type: Array,
      required: false,
    },
    pillarFontSize: {
      type: String,
      default: '12px',
    },
    hasSelectedGroup: {
      type: Boolean,
    },
    benchmarkPermissions: {
      type: Boolean,
    },
  },
  data() {
    return {}
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
