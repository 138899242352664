<template>
  <SkeletonLoader v-if="loading" :type="type" :notesName="notesName" />
  <div v-else :id="`${type}-card`" class="shadow">
    <v-card elevation="0" height="100%">
      <div class="header-container">
        <div class="header">
          <div class="header-title">
            <h3>{{ $t(`cardNPSEngagement.title.${type}`) }}</h3>
            <div class="header-component">
              <HowToBeginAndWatchAVideo :videoType="`climate.${type}`" />
            </div>
          </div>
          <p class="subtitle">
            {{ $t(`cardNPSEngagement.subtitle.${type}`) }}
          </p>
        </div>
      </div>
      <v-divider class="custom-divider" />
      <v-row class="mt-2 mx-4">
        <v-col
          sm="12"
          md="6"
          lg="5"
          class="d-flex"
          :class="{ 'custom-margin-top-col': _large }"
        >
          <VerticalLevelBar
            :id="`${type}-vertical-level-bar`"
            :groupMarker="verticalLevelBar.group"
            :accountMarker="verticalLevelBar.account"
            :marketMarker="verticalLevelBar.average"
            :marketPermission="marketPermission"
            type="nps"
          />
          <div class="custom-margin-score">
            <div class="score">
              <label class="score-number">{{ results.score | toScore }}</label>
              <Variation
                class="ml-1"
                :variation="_variationHistory.score"
                :loading="variation.loading"
                :data-test-variation="`${type}-score`"
              />
            </div>
            <BenchmarkBadge
              :id="`benchmark-${type}`"
              :rank="benchmark.rank"
              :status="type"
              :locked="benchmark.locked"
              :percentage="benchmark.score"
              short
            />
          </div>
        </v-col>
        <v-col sm="12" md="6" lg="7">
          <HistoryNPSChart
            class="custom-history-bottom-margin"
            :class="{ 'custom-history-width': _large }"
            :id="`${type}-history`"
            :nps="resultsHistory"
            :type="type"
          />
        </v-col>
      </v-row>
      <v-divider class="custom-divider" />
      <v-row class="mt-2">
        <v-col sm="6" md="3" lg="3" v-for="name in notesName" :key="name">
          <div class="content-center">
            <img
              width="40px"
              height="40px"
              :src="require(`@/assets/images/CardNPSEngagement/${name}.svg`)"
            />
            <div class="ml-3">
              <div class="score">
                <label class="score-number-small">{{
                  results[name] | toScore | toPercent
                }}</label>
                <Variation
                  class="ml-1 variation-size"
                  :variation="_variationHistory[name]"
                  :loading="variation.loading"
                  :data-test-variation="`${type}-${name}`"
                />
              </div>
              <label class="score-number-title">{{
                $t(`cardNPSEngagement.notes.${name}`)
              }}</label>
            </div>
          </div>
        </v-col>
      </v-row>

      <LastOpinions
        v-if="!hideOpinions"
        :id="`${type}-opinions`"
        :opinions="opinions"
        :metricID="type"
        :type="type"
      />
    </v-card>
  </div>
</template>

<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import VerticalLevelBar from '@/components/VerticalLevelBar/VerticalLevelBar.vue'
import Variation from '@/components/Variation/Variation.vue'
import BenchmarkBadge from '@/components/BenchmarkBadge/BenchmarkBadge.vue'
import LastOpinions from '@/components/LastOpinions/LastOpinions.vue'
import HistoryNPSChart from '@/components/HistoryNPSChart/HistoryNPSChart.vue'
import SkeletonLoader from './parts/SkeletonLoader.vue'

export default {
  name: 'CardHistoryEngagement',
  components: {
    HowToBeginAndWatchAVideo,
    VerticalLevelBar,
    Variation,
    BenchmarkBadge,
    LastOpinions,
    HistoryNPSChart,
    SkeletonLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      require: true,
    },
    verticalLevelBar: {
      type: Object,
      require: true,
    },
    results: {
      type: Object,
      require: true,
    },
    resultsHistory: {
      type: Array,
      require: true,
    },
    benchmark: {
      type: Object,
      require: true,
    },
    marketPermission: {
      type: Boolean,
      marketPermission: false,
    },
    hideOpinions: {
      type: Boolean,
      default: false,
    },
    opinions: {
      type: Array,
      require: true,
    },
    variation: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      notesName: ['promoters', 'passives', 'detractors', 'skiped'],
    }
  },
  computed: {
    _large() {
      return (
        this.$vuetify.breakpoint.mdAndUp || this.$vuetify.breakpoint.lgAndUp
      )
    },
    _variationHistory() {
      if (this.variation) {
        if (this.type === 'lnps') {
          const { lnps } = this.variation
          return lnps
        } else {
          const { enps } = this.variation
          return enps
        }
      }
      return {
        score: null,
        promoters: null,
        detractors: null,
        passives: null,
        skiped: null,
      }
    },
  },
  filters: {
    toScore(score) {
      if (score === 0 || score === null || score === undefined) return '--'
      return score
    },
    toPercent(value) {
      if (value === 0 || value === null || value === undefined) return value
      return value + '%'
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
