<template>
  <v-card elevation="0" :data-test-ranking-card="type">
    <v-card-text class="card-header">
      <div class="benchmark-page-title" v-text="title"></div>

      <div class="select-pillars" v-if="pillarsItens.length">
        <ItemSelector
          data-test-select-pillars
          :inputConfig="{
            label: $t('engagementPage.benchmarkCards.pillarSelect'),
            showAvatar: false,
            closeOnSelect: true,
          }"
          :menuConfig="{
            attach: true,
            showTabs: false,
          }"
          :menuOptions="[
            {
              value: 'pillars',
              label: $t('engagementPage.benchmarkCards.pillarSelect'),
              type: 'listview',
              items: pillarsItens,
            },
          ]"
          :currentValue="{
            data: [
              pillarsItens.find((item) => item.id === currentPillar),
            ].filter(Boolean),
            origin: 'pillars',
          }"
          :localSearch="true"
          :persistent="true"
          :watchCurrent="true"
          @update:item="handleSelectPillar($event)"
        ></ItemSelector>
      </div>
    </v-card-text>

    <v-data-table
      :data-test-table-ranking="type"
      class="elevation-1"
      :class="{
        blocked: !ranking.length,
      }"
      :items="_ranking"
      :headers="headers"
      :item-class="calcDataTableRowClasses"
      :hide-default-footer="true"
      :items-per-page="11"
    >
      <template v-slot:[`item.marketSegment`]="{ value }">
        {{ getMarketSegmentText(value) }}
      </template>

      <template v-slot:[`item.teamSize`]="{ value }">
        {{ getTeamSizeText(value) }}
      </template>

      <template v-slot:[`item.variation`]="{ value }">
        <Variation
          :variation="parseInt(value)"
          :iconConfig="variationIconConfig"
          hideMinusSign
          short
          xxs
        ></Variation>
      </template>
    </v-data-table>

    <div v-if="!ranking.length" class="blocked-text" data-test-empty-rank-text>
      <div
        v-text="
          $t(
            'engagementPage.benchmarkCards.titleEmptyStageRankingBenchmark.title'
          )
        "
      ></div>
      <div>
        <a
          target="_blank"
          :href="
            $t('engagementPage.benchmarkCards.rankingBenchmarkHowWorks.link')
          "
          v-text="
            $t('engagementPage.benchmarkCards.rankingBenchmarkHowWorks.title')
          "
        ></a>
      </div>
    </div>
  </v-card>
</template>

<script>
import ItemSelector from '@/components/ItemSelector/ItemSelector.vue'
import Variation from '@/components/Variation/Variation'

export default {
  name: 'RankingCard',
  components: {
    Variation,
    ItemSelector,
  },
  props: {
    type: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      required: true,
    },

    currentRanking: {
      type: Object,
    },

    ranking: {
      type: Array,
      required: true,
    },

    pillarsItens: {
      type: Array,
      default: () => [],
    },

    currentPillar: {
      type: String,
    },

    variationIconConfig: {
      type: Object,
      default: () => ({
        lib: 'flaticon',
        positiveIcon: 'fi-rr-caret-up',
        negativeIcon: 'fi-rr-caret-down',
      }),
    },

    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: this.$t('engagementPage.benchmarkCards.rankingHeaders.rank'),
            align: 'start',
            sortable: false,
            value: 'position',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'variation',
          },
          {
            text: this.$t(
              'engagementPage.benchmarkCards.rankingHeaders.account'
            ),
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: this.$t(
              'engagementPage.benchmarkCards.rankingHeaders.segment'
            ),
            align: 'start',
            value: 'marketSegment',
            sortable: false,
          },
          {
            text: this.$t('engagementPage.benchmarkCards.rankingHeaders.note'),
            align: 'start',
            value: 'score',
            sortable: false,
          },
        ]
      },
    },
  },

  computed: {
    _ranking() {
      if (this.ranking.length === 0) {
        return this._ranking_fake
      }

      return this.ranking
    },

    _ranking_fake() {
      return this.getItemsToFillTheEmptyStageTable()
    },
  },

  methods: {
    /**
     * @param {String} value
     */
    getTeamSizeText(value) {
      if ([null, undefined].includes(value)) {
        return '--'
      }

      let sizeText = '' + value
      const peoples = this.$t('engagementPage.benchmarkCards.people')
      if (sizeText.includes('-')) {
        sizeText = sizeText.replace('-', ' - ')
      } else if (!sizeText.includes('+')) {
        sizeText += '+'
      }

      return `${sizeText} ${peoples}`
    },

    /**
     * @param {String} value
     */
    getMarketSegmentText(value) {
      if ([null, undefined].includes(value)) {
        return '--'
      }

      return this.$t(`engagementPage.benchmarkCards.segmentLabels.${value}`)
    },

    handleSelectPillar(pillar) {
      this.$emit('selectPillar', pillar.id, this.type)
    },

    getItemsToFillTheEmptyStageTable() {
      const totalFakeAccounts = 10
      const fakeAccountAsCurrent = Math.floor(Math.random() * totalFakeAccounts)

      const fakeAccounts = Array.from(
        { length: totalFakeAccounts },
        (_, index) => ({
          position: index + 1,
          id: index === fakeAccountAsCurrent ? undefined : 'mock-id-' + index,
          name:
            this.$t('engagementPage.benchmarkCards.rankingFakeExample.name') +
            (index + 1),
          teamSize: null,
          marketSegment: null,
          score: 0,
          variation:
            Math.floor(Math.random() * 10) * (Math.random() > 0.5 ? 1 : -1),
        })
      )

      return fakeAccounts
    },

    calcDataTableRowClasses(item) {
      if (this.currentRanking?.id === item.id) {
        if (item.variation > 0) {
          return 'current-up'
        }

        if (item.variation < 0) {
          return 'current-down'
        }

        return 'current'
      }

      if (item.variation > 0) {
        return 'variation-up'
      }

      if (item.variation < 0) {
        return 'variation-down'
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
