<template>
  <v-data-table
    fixed-header
    item-key="id"
    :items="behaviors"
    :headers="headers"
    :sort-by.sync="orderBy"
    :sort-desc="true"
    :hide-default-footer="true"
    :disable-pagination="true"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <div
          v-for="(item, index) in items"
          :key="item.name"
          class="custom-table-row"
        >
          <td>
            <div class="custom-name" @click="openDetailBehaviorDialog(item)">
              <div class="unfavorable-container">
                <div
                  v-if="item.unfavorable === true && item.value === null"
                  class="unfavorable"
                >
                  <v-img
                    src="@/assets/images/Culture/down.png"
                    alt="unfavorable"
                    max-height="9px"
                    max-width="9px"
                    height="9px"
                  />
                </div>
              </div>

              <div class="pointer">
                <v-img
                  :src="item.image"
                  :alt="item.description"
                  max-height="27px"
                  max-width="27px"
                  width="27px"
                />
              </div>

              <div class="line-title">
                <span class="pointer" v-text="item.name"></span>

                <v-tooltip top max-width="18.75rem">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-icon">
                      <v-icon
                        class="fi-rr-interrogation"
                        size="0.75rem"
                      ></v-icon>
                    </span>
                  </template>

                  <div>
                    <span v-text="item.description"></span>
                  </div>
                </v-tooltip>
              </div>
            </div>
          </td>

          <td>
            <div class="custom-position">
              <span
                v-if="orderBy === 'votes'"
                v-text="item.position !== null ? item.position + 'º' : '--'"
              ></span>
              <span
                v-else
                v-text="
                  item.positionProbability !== null
                    ? item.positionProbability + 'º'
                    : '--'
                "
              ></span>
            </div>
          </td>

          <td class="custom-votes" :class="{ active: orderBy === 'votes' }">
            <span
              v-text="
                $t('engagementPage.culture.cultureCode.countVotes', {
                  count: item.votes || 0,
                })
              "
            ></span>
          </td>

          <td
            class="custom-probability"
            :class="{ active: orderBy === 'probability' }"
          >
            <span
              v-text="item.probability !== null ? item.probability + '%' : '--'"
            ></span>
          </td>

          <td>
            <div class="custom-actions">
              <v-menu
                content-class="pl-2 pa-1 elevation-0"
                :ref="'behaviorMenu-' + index"
                :close-on-content-click="false"
                offset-x
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="add" text plain>
                    <span v-text="$t('tableBehaviors.actionText')"></span>
                  </v-btn>
                </template>

                <QuickSelectionValues
                  v-on="$listeners"
                  :values="values"
                  :behavior="item"
                  @close="$refs[`behaviorMenu-${index}`].save()"
                />
              </v-menu>
            </div>
          </td>
        </div>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import QuickSelectionValues from '@/pages/EngagementPage/Tabs/Culture/Partials/QuickSelectionValues/QuickSelectionValues.vue'

export default {
  components: {
    QuickSelectionValues,
  },

  props: {
    orderBy: {
      type: String,
      required: true,
    },

    behaviors: {
      type: Array,
      default: () => [],
    },

    values: {
      type: Array,
      default: () => [],
    },
  },

  data(vm) {
    return {
      headers: [
        {
          text: vm.$t('tableBehaviors.tableHeader.behavior'),
          value: 'name',
        },
        {
          text: vm.$t('tableBehaviors.tableHeader.rank'),
          value: 'position',
        },
        {
          text: vm.$t('tableBehaviors.tableHeader.votes'),
          value: 'votes',
        },
        {
          text: vm.$t('tableBehaviors.tableHeader.probability'),
          value: 'probability',
        },
        {
          text: vm.$t('tableBehaviors.tableHeader.actions'),
          value: 'actions',
          align: 'center',
          width: '10rem',
          sortable: false,
        },
      ],
    }
  },

  methods: {
    openDetailBehaviorDialog(behavior) {
      this.$emit('openBehaviorDetailHistory', behavior)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
