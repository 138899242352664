import api from '@/service/Http'
import { constructParams } from '@/helpers/api'

/**
 * Histórico de Benchmark
 * @type {BenchmarkHistory}
 * @property {String} id
 * @property {Array<Indicators>} indicators
 * @property {Array<String>} dates
 */

/**
 * Métricas
 * @type {Indicators}
 *
 * @property {String} id
 * @property {Array<Number>} group
 * @property {Array<Number>} account
 * @property {Array<Number>} average
 */

/**
 * Retorna o histórico de benchmark
 * @param {String} groupID Código único do grupo
 * @param {String} indicatorID Código do indicador
 * @returns {BenchmarkHistory}
 */
export async function getBenchmarkResultsHistory(
  indicatorID,
  groupID,
  submetrics
) {
  return (await api()).get('/engagement/benchmark/results/history', {
    params: { indicatorID, groupID, submetrics },
  })
}

/**
 * @type BenchmarkResults
 *
 * @property {String} id
 * @property {String} date yyyy-mm-dd
 * @property {Array<BenchmarkIndicators>} indicators
 */

/**
 * @type BenchmarkIndicators
 *
 * @property {String} id indicatorID
 * @property {Number} score
 * @property {Number} average
 * @property {Number} account
 * @property {Number} rank
 */

/**
 * Benchmark da média geral de engajamento, taxa de participação,
 * opiniões, pilares, pilares inteligentes, eNPS e LNPS
 *
 * @param {String} groupID
 * @param {String} indicatorID Available values : climate, enps,
 * lnps, answeredOpinions, participation, managerRelationship,
 * ambassadorship, alignment, wellness, personalGrowth, happiness,
 * satisfaction, recognition, peersRelationship, feedback, diversity,
 * psychologicalSafety, burnout
 * @returns {BenchmarkResults}
 */
export async function getBenchmarkResults(indicatorID, groupID, submetrics) {
  const params = { indicatorID }
  if (groupID) Object.assign(params, { groupID })
  if (submetrics) Object.assign(params, { submetrics })
  return (await api()).get('/engagement/benchmark/results', {
    params,
  })
}

/**
 *
 * @param {String} groupID Código único do grupo
 * @property {Array<BenchmarkRanking>} ranking
 * @returns {ClimateResults}
 */

export async function getBenchmarkRankingResults(
  groupID,
  { marketSegment = null, teamSize = null }
) {
  return (await api()).get('/engagement/benchmark/ranking/results', {
    params: { groupID, marketSegment, teamSize },
  })
}

/**
 *
 * @param {String} groupID Código único do grupo
 * @property {Array<BenchmarkRanking>} ranking
 * @returns {ClimateResults}
 */
export async function getBenchmarkInternalRankingResults(
  groupID,
  { teamSize = null }
) {
  const params = constructParams(['groupID', 'teamSize'], {
    groupID,
    teamSize,
  })

  return (await api()).get('/engagement/benchmark/internal-ranking/results', {
    params,
  })
}

/**
 *
 * @param {String} groupID Código único do grupo
 * @property {Array<BenchmarkRanking>} ranking
 * @returns {ClimateResults}
 */

export async function getBenchmarkRankingVariation(
  groupID,
  period,
  { marketSegment = null, teamSize = null }
) {
  return (await api()).get('/engagement/benchmark/ranking/variation', {
    params: { groupID, period, marketSegment, teamSize },
  })
}

export async function getBenchmarkInternalRankingVariation(
  groupID,
  period,
  { teamSize = null }
) {
  const params = constructParams(['groupID', 'period', 'teamSize'], {
    groupID,
    period,
    teamSize,
  })

  return (await api()).get('/engagement/benchmark/internal-ranking/variation', {
    params,
  })
}

/**
 *
 * @param {Object} payload
 * @param {String} payload.type Tipo de relatório
 * @param {String} payload.groupID Código único do grupo
 * @returns {Blob}
 */
export async function exportBenchmark(payload) {
  return (await api()).post('/engagement/benchmark/results/export', payload, {
    responseType: 'blob',
  })
}
