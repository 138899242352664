import Login from '@/pages/Login/LoginPage.vue'
import EngagementPage from '@/pages/EngagementPage/EngagementPage.vue'
import ClimatePage from '@/pages/EngagementPage/Tabs/Climate/ClimatePage.vue'
import CulturePage from '@/pages/EngagementPage/Tabs/Culture/CulturePage.vue'
import CultureManagement from '@/pages/EngagementPage/Tabs/Culture/Partials/CultureManagement/CultureManagement.vue'
import CultureCode from '@/pages/EngagementPage/Tabs/Culture/Partials/CultureCode/CultureCode.vue'
import ParticipationPage from '@/pages/EngagementPage/Tabs/Participation/ParticipationPage.vue'
import ComparisonPage from '@/pages/EngagementPage/Tabs/Comparison/ComparisonPage.vue'
import BenchmarkPage from '@/pages/EngagementPage/Tabs/Benchmark/BenchmarkPage.vue'
import IndicatorsPage from '@/pages/EngagementPage/Tabs/Benchmark/Tabs/Indicators/IndicatorsPage.vue'
import RankingPage from '@/pages/EngagementPage/Tabs/Benchmark/Tabs/Ranking/RankingPage.vue'
import InternalRankingPage from '@/pages/EngagementPage/Tabs/Benchmark/Tabs/InternalRanking/InternalRankingPage.vue'
import OpinionsPage from '@/pages/EngagementPage/Tabs/Opinions/OpinionsPage.vue'
import ReceivedOpinionsPage from '@/pages/EngagementPage/Tabs/Opinions/Tabs/ReceivedOpinions/ReceivedOpinionsPage.vue'
import AnalyticsReportPage from '@/pages/EngagementPage/Tabs/Opinions/Tabs/AnalyticsReport/AnalyticsReportPage.vue'
import ActionPlanAnalyticsReportPage from '@/pages/EngagementPage/Tabs/ActionPlan/Tabs/AnalyticsReport/AnalyticsReportPage.vue'
import ActionPlanPage from '@/pages/EngagementPage/Tabs/ActionPlan/ActionPlanPage.vue'
import ActionPlansPage from '@/pages/EngagementPage/Tabs/ActionPlan/Tabs/ActionPlans/ActionPlansPage.vue'
import SurveyPage from '@/pages/SurveyPage/SurveyPage.vue'
import SurveyInit from '@/pages/SurveyPage/Partials/SurveyInit/SurveyInit.vue'
import SurveySteps from '@/pages/SurveyPage/Partials/SurveySteps/SurveySteps.vue'
import SurveyFeedBack from '@/pages/SurveyPage/Partials/SurveyFeedback/SurveyFeedback.vue'
import SurveyFinish from '@/pages/SurveyPage/Partials/SurveyFinish/SurveyFinish.vue'
import SurveyForbidden from '@/pages/SurveyPage/Partials/SurveyForbidden/SurveyForbidden.vue'
import SurveyOther from '@/pages/SurveyPage/Partials/SurveyOther/SurveyOther.vue'
import NotFoundPage from '@/pages/NotFound/NotFoundPage.vue'

import { _permissions } from '@/helpers/ability/engagement'

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    redirect: { name: 'ClimatePage' },
  },
  {
    path: '/engagement',
    name: 'EngagementPage',
    component: EngagementPage,

    meta: {
      requiresAuth: true,
      requiresPermission: _permissions.engagement,
    },
    redirect: { name: 'ClimatePage' },
    children: [
      {
        path: 'climate',
        name: 'ClimatePage',
        component: ClimatePage,
        meta: {
          requiresPermission: _permissions.engagement_climate,
        },
      },
      {
        path: 'culture',
        name: 'CulturePage',
        component: CulturePage,
        meta: {
          requiresPermission: _permissions.engagement_culture,
        },
        children: [
          {
            path: 'management',
            name: 'cultureManagement',
            component: CultureManagement,
            children: [
              {
                path: 'add-culture-code',
                name: 'AddCultureCode',
                component: CultureCode,
                meta: {
                  requiresPermission: _permissions.engagement_culture_creator,
                },
              },
              {
                path: 'edit-culture-code/:cultureId',
                name: 'EditCultureCode',
                component: CultureCode,
              },
            ],
          },
        ],
      },
      {
        path: 'participation',
        name: 'ParticipationPage',
        component: ParticipationPage,
        meta: {
          requiresPermission: _permissions.engagement_participations,
        },
      },
      {
        path: 'comparison',
        name: 'ComparisonPage',
        meta: {
          requiresPermission: _permissions.engagement_groups_comparation,
        },
        component: ComparisonPage,
      },
      {
        path: 'benchmark',
        name: 'BenchmarkPage',
        component: BenchmarkPage,
        meta: {
          requiresPermission: _permissions.engagement_benchmark,
        },
        children: [
          {
            path: 'indicators',
            name: 'IndicatorsPage',
            component: IndicatorsPage,
          },
          {
            path: 'ranking',
            name: 'RankingPage',
            component: RankingPage,
            meta: {
              requiresPermission: _permissions.engagement_benchmark_ranking,
            },
          },
          {
            path: 'internal-ranking',
            name: 'InternalRankingPage',
            component: InternalRankingPage,
            meta: {
              requiresPermission:
                _permissions.engagement_benchmark_internal_ranking,
            },
          },
        ],
      },
      {
        path: 'opinions',
        name: 'OpinionsPage',
        component: OpinionsPage,
        meta: {
          requiresPermission: _permissions.engagement_opinions,
        },
        children: [
          {
            path: 'received-opinions/:selectedOpinionId?',
            name: 'ReceivedOpinionsPage',
            component: ReceivedOpinionsPage,
            props: true,
          },
          {
            path: 'analytics-report',
            name: 'OpinionsAnalyticsReportPage',
            component: AnalyticsReportPage,
            meta: {
              requiresPermission: _permissions.engagement_opinions_reports,
            },
          },
        ],
      },
      {
        path: 'action-plan',
        name: 'ActionPlanPage',
        component: ActionPlanPage,
        meta: {
          requiresPermission: _permissions.engagement_action_plans,
        },
        children: [
          {
            path: 'action-plans',
            name: 'ActionPlansPage',
            component: ActionPlansPage,
          },
          {
            path: 'analytics-report',
            name: 'ActionPlanAnalyticsReportPage',
            component: ActionPlanAnalyticsReportPage,
            meta: {
              requiresPermission: _permissions.engagement_action_plans_reports,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/survey',
    component: SurveyPage,
    meta: {
      requiresAuth: true,
      requiresPermission: _permissions.engagement_survey,
    },
    children: [
      {
        path: '',
        name: 'SurveyInit',
        component: SurveyInit,
      },
      {
        path: 'steps',
        name: 'SurveySteps',
        component: SurveySteps,
      },
      {
        path: 'feedback',
        name: 'SurveyFeedback',
        component: SurveyFeedBack,
      },
      {
        path: 'finish',
        name: 'SurveyFinish',
        component: SurveyFinish,
      },
      {
        path: 'forbidden',
        name: 'SurveyForbidden',
        component: SurveyForbidden,
      },
      {
        path: 'other',
        name: 'SurveyOther',
        component: SurveyOther,
      },
    ],
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },
]
