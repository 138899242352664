<template>
  <v-card
    hover
    height="100%"
    class="culture__card"
    elevation="0"
    @click="openDetailBehaviorDialog"
  >
    <div
      v-if="cardUnfavorable"
      class="culture__banner-icon unfavorable"
      data-test-card-unfavorable
    >
      <div class="icon">
        <v-img
          src="@/assets/images/Culture/down.png"
          alt="unfavorable"
          max-height="14px"
          max-width="14px"
          height="14px"
        ></v-img>
      </div>
    </div>

    <div v-else-if="cardTop && cardTop.name" class="culture__banner">
      <TruncatedArea :refText="refTruncatedAreaBannerText" tooltipOnHover>
        <template #outside="{ setHover }">
          <div
            data-test-culture-banner
            ref="bannerText"
            class="truncated-text text-on-hover"
            @mouseover="setHover(true)"
            @mouseleave="setHover(false)"
          >
            <span data-test-culture-banner-text v-text="cardTop.name" />
          </div>
        </template>
        <template #default>
          <div data-test-culture-banner-tooltip v-text="cardTop.name" />
        </template>
      </TruncatedArea>
    </div>

    <div v-else class="culture__banner-blank"></div>

    <div class="culture__icon">
      <v-img
        :src="cardImg"
        alt="culture"
        max-height="60px"
        max-width="60px"
        height="60px"
      ></v-img>
    </div>

    <div class="culture__name" :id="cardId">
      <p v-text="cardTitle"></p>

      <v-tooltip top max-width="18.75rem">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="tooltip-icon">
            <v-icon class="fi-rr-interrogation" size="0.75rem"></v-icon>
          </span>
        </template>

        <div>
          <span v-text="cardDescription"></span>
        </div>
      </v-tooltip>
    </div>

    <Score class="culture__score" :scoreName="cardScore" />

    <div class="culture__ground">
      <div class="ranking">
        <i v-if="showTrophy" class="ranking-trophy fi fi-sr-trophy"></i>

        <p class="position">
          <template v-if="cardPosition">
            <span v-text="cardPosition"></span>
            <span v-text="'º'"></span>
          </template>

          <span v-else v-text="'--'"></span>
        </p>

        <Variation class="ml-1" xs :variation="cardVariation" short></Variation>
      </div>

      <div class="details">
        <span text class="votes" :class="{ active: active === 'votes' }">
          <span v-text="cardVotos !== null ? cardVotos : '--'"></span>
          <span
            v-text="$t('engagementPage.behaviorClassification.votes')"
          ></span>
        </span>

        <span
          class="divisor"
          :class="{ active: active === 'probability' }"
          v-text="'|'"
        ></span>

        <span class="percent" :class="{ active: active === 'probability' }">
          <span v-text="cardpercent !== null ? cardpercent + '%' : '--'"></span>
        </span>
      </div>
    </div>

    <div class="culture__action">
      <span
        id="behavior-open-modal"
        data-test-behavior-open-modal
        class="hidden-sm-and-down detail-button"
        v-text="$t('engagementPage.behaviorClassification.moreDetails')"
      ></span>
    </div>
  </v-card>
</template>

<script>
import Score from '../Score/Score.vue'
import Variation from '@/components/Variation/Variation.vue'
import TruncatedArea from '@/components/TruncatedArea/TruncatedArea.vue'

export default {
  name: 'CultureCard',
  components: {
    Score,
    Variation,
    TruncatedArea,
  },
  props: {
    groupID: {
      type: String,
      default: '',
    },
    cardTop: {
      type: Object,
      default: () => {},
    },
    cardImg: {
      type: String,
    },
    cardTitle: {
      type: String,
    },
    cardId: {
      type: String,
    },
    cardVotos: {
      type: Number,
    },
    cardpercent: {
      type: Number,
    },
    cardPosition: {
      type: Number,
    },
    cardScore: {
      type: String,
    },
    cardDescription: {
      type: String,
    },
    cardUnfavorable: {
      type: Boolean,
    },
    showTrophy: {
      type: Boolean,
      default: true,
    },
    cardVariation: {
      type: Number,
    },
    active: String,
    detailBehaviorData: {
      type: Object,
      default: () => {},
    },
    detailBehaviorHistory: {
      type: Object,
      default: () => {
        return {
          metrics: [],
          dates: [],
        }
      },
    },
  },
  methods: {
    openDetailBehaviorDialog() {
      this.$emit('openDetailBehaviorDialog', this.cardId)
    },

    setRefTruncatedAreaBannerText() {
      this.refTruncatedAreaBannerText = this.$refs.bannerText
    },
  },

  data() {
    return {
      refTruncatedAreaBannerText: null,
    }
  },

  mounted() {
    this.setRefTruncatedAreaBannerText()
  },

  updated() {
    this.setRefTruncatedAreaBannerText()
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
