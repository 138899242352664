<template>
  <div id="avatar">
    <v-avatar
      :size="size"
      :color="color ? color : text ? _colorRandom : ''"
      v-bind="avatarAttr"
    >
      <v-img v-if="src" :src="src" v-bind="displayAttr" />
      <i v-else-if="icon" :class="icon" class="mt-1" v-bind="displayAttr"></i>
      <span v-else class="avatar-name" v-bind="displayAttr">{{
        getInitials(text)
      }}</span>
    </v-avatar>
  </div>
</template>

<script>
import randomColors from '@/helpers/randomColors/randomColors.js'
export default {
  name: 'Avatar',
  props: {
    id: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '28px',
    },
    src: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    avatarAttr: {
      type: Object,
      default: () => {},
    },
    displayAttr: {
      type: Object,
      default: () => {
        alt: ''
      },
    },
  },

  computed: {
    _colorRandom() {
      if (!!this.src && !this.error) return

      return randomColors[this.getColor()]
    },
  },
  methods: {
    getInitials(newName) {
      if (newName === null || newName === '') return ''
      return newName
        .split(' ')
        .map((name) => name.charAt(0))
        .slice(0, 2)
        .join('')
    },

    getColor() {
      let id = (this.id || '').toString()

      if (!id || !id.length) id = this.generateID(this.text)
      const newId = id
        .toString()
        .replace(/[^0-9]/g, '')
        .replace(/[^0-9]\w/g, (c) => c.charCodeAt(0).toString())

      return newId % 100
    },

    generateID(text) {
      if (!text || !text.length) return '0'

      const split = text.toString().split(' ')
      const first = split[0] || ''
      const last = split[1] || ''
      const id = `${this.generateCode(first, 3)}${
        last ? this.generateCode(last, 3) : ''
      }`

      return id
    },
    generateCode(str, length = 3) {
      let code = ''
      for (let i = 0; i < length; i++) {
        const char = str.charCodeAt(i)
        if (!char) break
        code += char.toString()
      }
      return code
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
