import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import CrispChat from '@dansmaculotte/vue-crisp-chat'
import TreeSelect from '@riophae/vue-treeselect'
import VueApexCharts from 'vue-apexcharts'
import VueMask from 'v-mask'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'apexcharts/dist/apexcharts.css'

import { projectAuth } from '@/firebase/config'
import '@/styles/main.scss'

import { abilitiesPlugin } from '@casl/vue'
import { ability, defineAbility } from './helpers/ability'

Vue.config.productionTip = false
Vue.prototype.CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

Vue.use(CrispChat, {
  websiteId: '8b181a92-2354-4a32-8887-0395ba83aa78',
  disabled: true,
  hideOnLoad: false,
})

// eslint-disable-next-line
Vue.component('treeselect', TreeSelect)
// eslint-disable-next-line
Vue.component('apexchart', VueApexCharts)
Vue.use(VueMask)
Vue.use(VueApexCharts)
Vue.use(abilitiesPlugin, ability)

let app = ''
// initialize app after firebase setup
projectAuth.onAuthStateChanged(async () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      i18n,
      VueApexCharts,
      render: (h) => h(App),
    }).$mount('#app')

    if (window.Cypress) {
      // be visible only during e2e tests
      window.app = app
      window.abilityCan = ability
      window.abilityHelper = defineAbility
    }
  }
})
