import api from '@/service/Http'

export const getSurvey = async ({ accessibility, type, metricID }) => {
  return (await api()).get('/engagement/survey', {
    params: { accessibility, type, metricID },
  })
}

export const sendSurvey = async (payload) => {
  return (await api()).post('/engagement/survey', payload)
}

export const putSignLanguage = async (payload) => {
  return (await api()).put('/engagement/survey/sign-language', payload)
}

export const getSubjects = async () => {
  return (await api()).get('/engagement/survey/subjects')
}
