<template>
  <div class="relative card">
    <v-badge
      :value="settings"
      data-test-settings
      dot
      overlap
      color="primary"
      class="icon-settings hidden-sm-and-down"
      v-if="showSettings"
    >
      <v-btn icon small @click="handleOpenConfigureModal">
        <v-icon small class="fi fi-rr-settings"></v-icon
      ></v-btn>
    </v-badge>

    <div class="card-banner">
      <div class="banner">
        <img
          :src="require(`@/assets/images/Culture/${selectionImage}.png`)"
          class="banner-image"
          :alt="selectionImage"
        />
      </div>

      <div class="card-info">
        <div>
          <div class="card-porcent" v-if="selectionPercent">
            <p>
              <span v-text="selectionPercent"></span>
              <span v-text="'%'"></span>
            </p>

            <Variation
              class="score-variation"
              :short="true"
              :variation="variation"
            />
          </div>

          <p v-else class="card-porcent" v-text="'N/A'"></p>

          <span
            v-if="selectionPercent"
            class="card-impact"
            v-text="$t(`${calcImpact(selectionPercent)}`)"
          ></span>

          <Variation
            class="score-variation"
            v-if="selectionVariation"
            :variation="selectionVariation"
          />
        </div>

        <p
          v-if="selectionStatus"
          class="card-info__semibold"
          v-text="selectionStatus"
        ></p>

        <div v-if="selectionVotes">
          <p class="default line">
            <span v-text="selectionVotes"></span>
            <span
              v-text="$t('engagementPage.behaviorClassification.votes')"
            ></span>
          </p>

          <div class="present-votes">
            <div class="line">
              <span v-text="selectionVotes" class="votes"></span>
              <span
                v-text="$t('engagementPage.behaviorClassification.votes')"
                class="label"
              ></span>
            </div>

            <Score class="card-score" :scoreName="reliabilityRate" />
          </div>
        </div>

        <div v-else>
          <p class="default line">
            <span v-text="'--'"></span>
            <span
              v-text="$t('engagementPage.behaviorClassification.votes')"
            ></span>
          </p>
        </div>
      </div>
    </div>

    <div>
      <span class="card-text" v-text="selectionTitle"></span>
    </div>
  </div>
</template>

<script>
import Variation from '@/components/Variation/Variation.vue'
import Score from '../Score/Score.vue'
import toggleCultureManagementModalMixin from '@/mixins/toggleCultureManagementModal'

export default {
  mixins: [toggleCultureManagementModalMixin],
  name: 'CultureSelections',
  components: { Variation, Score },
  props: {
    selectionTitle: {
      type: String,
      required: true,
    },
    selectionVotes: Number,
    selectionStatus: String,
    selectionPercent: Number,
    selectionVariation: Number,
    selectionImage: String,
    reliabilityRate: String,
    variation: Number,
    showSettings: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    calcImpact(val) {
      if (val < 11) {
        return 'engagementPage.behaviorClassification.veryLow'
      } else if (val >= 11 && val < 20) {
        return 'engagementPage.behaviorClassification.low'
      } else if (val >= 20 && val < 30) {
        return 'engagementPage.behaviorClassification.medium'
      } else if (val >= 30 && val < 40) {
        return 'engagementPage.behaviorClassification.high'
      } else {
        return 'engagementPage.behaviorClassification.veryHigh'
      }
    },

    handleOpenConfigureModal() {
      this.toggleCultureManagementModal(true)
      this.$router.push({ name: 'cultureManagement' })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
