export const engagement = 'engagement'

export const engagement_survey = 'engagement.survey'
export const engagement_survey_libras = 'engagement.survey.libras'

export const engagement_participations = 'engagement.participations'
export const engagement_participations_variation =
  'engagement.participations.variation'
export const engagement_participations_invite =
  'engagement.participations.invite'
export const engagement_participations_export_xlsx =
  'engagement.participations.export.xlsx'
export const engagement_participations_export_pptx =
  'engagement.participations.export.pptx'
export const engagement_participations_confirmation_management =
  'engagement.participations.confirmation.management'
export const engagement_participations_answers_management =
  'engagement.participations.answers.management'

export const engagement_opinions = 'engagement.opinions'
export const engagement_opinions_variation = 'engagement.opinions.variation'
export const engagement_opinions_tags = 'engagement.opinions.tags'
export const engagement_opinions_reports = 'engagement.opinions.reports'

export const engagement_groups_comparation = 'engagement.groups-comparation'
export const engagement_groups_comparation_variation =
  'engagement.groups-comparation.variation'
export const engagement_groups_comparation_export_csv =
  'engagement.groups-comparation.export.csv'

export const engagement_favorite_filters = 'engagement.favorite-filters'

export const engagement_culture = 'engagement.culture'
export const engagement_culture_variation = 'engagement.culture.variation'
export const engagement_culture_export_xlsx = 'engagement.culture.export.xlsx'
export const engagement_culture_export_pptx = 'engagement.culture.export.pptx'
export const engagement_culture_demographic_reports =
  'engagement.culture.demographic-reports'
export const engagement_culture_creator = 'engagement.culture.creator'

export const engagement_climate = 'engagement.climate'
export const engagement_climate_variation = 'engagement.climate.variation'
export const engagement_climate_submetrics = 'engagement.climate.submetrics'
export const engagement_climate_smart_metrics =
  'engagement.climate.smart-metrics'
export const engagement_climate_smart_metrics_burnout =
  'engagement.climate.smart-metrics.burnout'
export const engagement_climate_shared_links = 'engagement.climate.shared-links'
export const engagement_climate_questions = 'engagement.climate.questions'
export const engagement_climate_metrics_lnps = 'engagement.climate.metrics.lnps'
export const engagement_climate_metrics_enps = 'engagement.climate.metrics.enps'
export const engagement_climate_impulse = 'engagement.climate.impulse'
export const engagement_climate_export_xlsx = 'engagement.climate.export.xlsx'
export const engagement_climate_export_pptx = 'engagement.climate.export.pptx'
export const engagement_climate_export_csv = 'engagement.climate.export.csv'
export const engagement_climate_download = 'engagement.climate.download'
export const engagement_climate_demographic_reports =
  'engagement.climate.demographic-reports'
export const engagement_climate_advanced_results =
  'engagement.climate.advanced-results'
export const engagement_climate_correlation_results =
  'engagement.climate.correlation-results'

export const engagement_benchmark = 'engagement.benchmark'
export const engagement_benchmark_variation = 'engagement.benchmark.variation'
export const engagement_benchmark_results = 'engagement.benchmark.results'
export const engagement_benchmark_ranking = 'engagement.benchmark.ranking'
export const engagement_benchmark_internal_ranking =
  'engagement.benchmark.internal-ranking'
export const engagement_benchmark_export_xlsx =
  'engagement.benchmark.export.xlsx'

export const engagement_action_plans = 'engagement.action-plans'
export const engagement_action_plans_variation =
  'engagement.action-plans.variation'
export const engagement_action_plans_templates =
  'engagement.action-plans.templates'
export const engagement_action_plans_templates_creator =
  'engagement.action-plans.templates.creator'
export const engagement_action_plans_reports = 'engagement.action-plans.reports'
export const engagement_action_plans_creator = 'engagement.action-plans.creator'

export const academy_recommendations = 'academy.recommendations'
