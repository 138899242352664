<template>
  <div id="card-list-opinions" class="shadow">
    <v-card elevation="0" height="100%">
      <div class="d-flex justify-space-between mx-4 flex-column flex-sm-row">
        <div class="d-flex pt-4">
          <span class="card-title">{{
            $t('engagementPage.opinions.cards.cardListOpinions.title')
          }}</span>
          <span class="ml-2 card-count-opinions">({{ countOpinions }})</span>
        </div>
        <div class="d-flex">
          <span class="select-title"
            >{{
              $t(
                'engagementPage.opinions.cards.cardListOpinions.sortFilterOption'
              )
            }}:
          </span>
          <div class="ml-2">
            <v-select
              class="select-content"
              v-model="filterSelected"
              :items="filterOptions"
            >
            </v-select>
          </div>
        </div>
      </div>
      <v-divider class="custom-divider" />
      <ListOpinions
        :data="data"
        :tags="tags"
        :loading="loading"
        @handleOpenSidebar="$emit('handleOpenSidebar', $event)"
        @handleTagsInfiniteScroll="$emit('handleTagsInfiniteScroll', $event)"
        @handleAddTag="$emit('handleAddTag', $event)"
        @handleRemoveTag="$emit('handleRemoveTag', $event)"
        @handleCreateTag="$emit('handleCreateTag', $event)"
      />
      <EmptyOpinionsTip v-if="data.length === 0" />
      <v-pagination
        v-if="data.length !== 0"
        class="my-3"
        v-model="page"
        :length="countPages"
        :total-visible="11"
        @input="handleOpinions"
      ></v-pagination>
    </v-card>
  </div>
</template>

<script>
import EmptyOpinionsTip from '../EmptyOpinionsTip/EmptyOpinionsTip.vue'
import ListOpinions from '../ListOpinions/ListOpinions.vue'

export default {
  name: 'CardListOpinions',
  components: {
    EmptyOpinionsTip,
    ListOpinions,
  },
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    countPages: {
      type: Number,
    },
    countOpinions: {
      type: Number,
    },
    tags: {
      type: Object,
    },
  },
  data() {
    return {
      page: this.currentPage + 1,
      filterSelected: 'desc',
      filterOptions: [
        {
          text: this.$t(
            'engagementPage.opinions.cards.cardListOpinions.recent'
          ),
          value: 'desc',
        },
        {
          text: this.$t(
            'engagementPage.opinions.cards.cardListOpinions.oldest'
          ),
          value: 'asc',
        },
      ],
    }
  },
  watch: {
    filterSelected(newVal) {
      this.$emit('handleOpinionsOrder', newVal)
    },
  },
  methods: {
    handleOpinions(pageNumber) {
      this.$emit('handleOpinions', pageNumber)
    },
    setCurrentPage(value) {
      this.page = value
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
