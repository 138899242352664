<template>
  <apexchart
    id="progress-line"
    height="150"
    type="area"
    :options="options"
    :series="series"
  />
</template>

<script>
import moment from 'moment'
export default {
  name: 'ProgressLine',
  props: {
    series: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  computed: {
    options() {
      return {
        chart: {
          type: 'area',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          defaultLocale: this.$i18n.locale,
          locales: [this.$t('apexchart.chart.locale')],
        },
        xaxis: {
          type: 'datetime',
          categories: this.labels,
          min: moment(this.labels[0], this.$t('progressLine.api'))
            .subtract(6, 'days')
            .valueOf(),
          labels: {
            show: true,
            showDuplicates: false,
            format: this.$t('progressLine.dateFormat'),
          },
        },
        yaxis: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          show: false,
          padding: {
            right: 0,
            left: 0,
          },
        },
      }
    },
    isEmpty() {
      return this.labels.length === 0
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
