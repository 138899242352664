<template>
  <div data-test-entity-detail id="entity-detail" class="item-entity">
    <div class="item-first-line">
      <div class="item-icon">
        <v-img
          :src="require(`@/assets/icons/${item.entityType}.png`)"
          :alt="item.entityType"
          width="1.63rem"
          height="1.63rem"
        />
      </div>
      <div class="item-text">
        <TruncatedArea
          :refText="refTruncatedAreaTitleText"
          :zIndex="zIndex"
          tooltipOnHover
        >
          <template #outside="{ setHover }">
            <div
              data-test-entity-detail-title
              ref="titleText"
              class="item-text-title truncated-text text-on-hover"
              :class="{
                truncate: hasTooltip,
                'cursor-pointer': canTitleClick,
              }"
              @click="titleClicked"
              @mouseover="setHover(true)"
              @mouseleave="setHover(false)"
            >
              <span data-test-entity-detail-title-text v-text="item.title" />
            </div>
          </template>
          <template #default>
            <div data-test-entity-detail-title-tooltip v-text="item.title" />
          </template>
        </TruncatedArea>
        <div class="item-text-code">
          <span>{{ item.code }}</span>
        </div>
      </div>
    </div>
    <div class="item-second-line">
      <template v-for="field in secondLineFields">
        <div
          :key="field"
          v-if="field === 'owners' && haveName(item)"
          class="item-text-info owners"
        >
          <AvatarGroup
            :avatars="
              item.owners.map((e) => ({
                id: e.id,
                image: e.photo,
                text: e.name,
              }))
            "
            :size="14"
          />
          <span v-text="haveName(item)" />

          <v-tooltip
            :z-index="zIndex"
            bottom
            v-if="item.owners && item.owners.length > 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                v-text="getAdditionalNames(item)"
              />
            </template>
            <div class="d-flex flex-column px-2 py-1">
              <span
                v-for="name of item.owners
                  .filter((e, i) => i > 0)
                  .map((e) => e.name)"
                :key="name"
                class="pa-1"
                v-text="name"
              />
            </div>
          </v-tooltip>
        </div>
        <div :key="field" v-if="field === 'group'" class="item-text-info group">
          <span data-test-group-name v-text="item.group.name" />
        </div>
        <div :key="field" v-if="field === 'cycle'" class="item-text-info cycle">
          <span data-test-cycle-title v-text="item.cycle.title" />
        </div>
        <div
          :key="field"
          v-if="field === 'class' && item.entityClass"
          class="item-text-info class"
        >
          <span v-text="$t(`ViewEntity.entityClass.${item.entityClass}`)" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AvatarGroup from '@/components/AvatarGroup/AvatarGroup.vue'
import TruncatedArea from '@/components/TruncatedArea/TruncatedArea.vue'

export default {
  name: 'EntityDetail',
  components: { AvatarGroup, TruncatedArea },
  props: {
    item: {
      required: true,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    secondLineFields: {
      type: Array,
      default: () => ['owners', 'group', 'cycle'],
    },
    canTitleClick: {
      type: Boolean,
      default: false,
    },

    zIndex: {
      type: Number,
      default: 300,
    },
  },
  methods: {
    haveName(item) {
      const dontHaveOwnersPropOrLength =
        !item.owners || item.owners.length === 0
      if (dontHaveOwnersPropOrLength) {
        return ''
      }

      return item.owners[0].name
    },
    getAdditionalNames(item) {
      if (item.owners.length < 2) return ''

      return ` +${item.owners.length - 1}`
    },

    titleClicked() {
      this.$emit('titleClicked')
    },

    setRefTruncatedAreaTitleText() {
      this.refTruncatedAreaTitleText = this.$refs.titleText
    },
  },

  data() {
    return {
      refTruncatedAreaTitleText: null,
    }
  },

  mounted() {
    this.setRefTruncatedAreaTitleText()
  },

  updated() {
    this.setRefTruncatedAreaTitleText()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
