<template>
  <v-combobox
    v-model="selection"
    data-test-selection-group-input
    :items="selection"
    :placeholder="placeholder"
    class="custom-combobox"
    :class="{
      'custom-combobox-empty': _isEmpty,
      _inputClass,
      'black-placeholder': !focused,
    }"
    :label="_inputLabel"
    hide-selected
    hide-details
    color="black"
    small-chips
    multiple
    readonly
    outlined
    dense
    flat
    @focus="focused = true"
    @blur="focused = false"
  >
    <template v-slot:selection="{ attrs, item }">
      <v-chip
        class="custom-chip mr-1 my-1"
        :class="{ 'more-itens': value.length > 0 }"
        color="neutral8"
        v-bind="attrs"
      >
        <span class="label">
          {{ item }}
        </span>
      </v-chip>

      <span v-if="!!value" class="text-inside">
        {{ value }}
      </span>
    </template>
  </v-combobox>
</template>

<script>
export default {
  data() {
    return {
      focused: true,
    }
  },

  props: {
    placeholder: {
      type: String,
      value: '',
    },
    value: null,
    selection: [],
    showAll: null,
    variationColor: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _isEmpty() {
      return this.value === ''
    },
    _inputClass() {
      if (this.variationColor && this.selection.length === 0 && !this.focused)
        return 'custom-combobox-filled'

      return 'custom-combobox-outlined'
    },
    _inputLabel() {
      if (this.focused)
        return this.$t('cultureManagement.groupSelector.textField.label')

      if (!this.focused && this.selection.length)
        return this.$t('cultureManagement.groupSelector.textField.label')

      return this.placeholder
    },
  },
}
</script>

<style src="../style.scss" lang="scss" scoped></style>
