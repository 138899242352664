var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content",attrs:{"data-test-custom-input":""}},[_c('div',{class:{ 'error--text': _vm.stateError },style:({ position: 'relative' }),attrs:{"id":"custom-input"}},[_c('div',{class:{ bordered: _vm._hasBorder, unbordered: !_vm._hasBorder }},[(_vm.value !== '' || _vm.stateError)?_c('div',{staticClass:"input-label d-flex"},[(_vm._hasHighlight)?_c('div',{staticClass:"highlight-pulse",attrs:{"data-test-custom-input-label-with-highlight":""}}):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(_vm.label)}})]):_vm._e(),(_vm.readonly && _vm.showTextPresented)?_c('div',{staticClass:"input-presented"},[_c('TruncatedArea',{attrs:{"zIndex":99999,"refText":_vm.refTruncatedAreaPresentedText,"tooltipOnHover":""},scopedSlots:_vm._u([{key:"outside",fn:function({ setHover }){return [_c('div',{ref:"presentedText",staticClass:"truncated-text text-on-hover",class:_vm._hasHighlight ? 'highlight' : '',attrs:{"data-test-custom-input-truncated":""},domProps:{"textContent":_vm._s(_vm.value)},on:{"mouseover":function($event){return setHover(true)},"mouseleave":function($event){return setHover(false)}}})]}},{key:"default",fn:function(){return [_c('span',{attrs:{"data-test-custom-input-tooltip":""},domProps:{"textContent":_vm._s(_vm.value)}})]},proxy:true}],null,false,569135354)})],1):_vm._e(),_c('div',{staticClass:"input-container d-flex"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"input",class:{ 'input-resized': _vm.suffixIcon !== '' },attrs:{"data-test-custom-input-input":"","data-test-input":"","type":_vm.type,"placeholder":_vm.label,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"mouseover":_vm.handleMouseOver,"mouseout":_vm.handleMouseOut,"focusout":_vm.handleMouseOut}}),_vm._t("suffix",function(){return [(_vm.suffixIcon !== '')?_c('div',{staticClass:"input-suffix",class:{
              'input-suffix-action': _vm.suffixAction,
              'input-suffix--active': _vm.stateSuffixToggle && _vm.suffixAction,
            },attrs:{"data-test-custom-input-suffix":""},on:{"click":_vm.handleSuffixClick}},[_c('i',{class:_vm.suffixIcon,attrs:{"title":_vm.suffixTitle}})]):_vm._e()]},null,{
            handleSuffixClick: _vm.handleSuffixClick,
            stateSuffixToggle: _vm.stateSuffixToggle,
            setValue: _vm.setValue,
            value: _vm.value,
          })],2)]),(_vm.showOptions)?_c('div',{staticClass:"input-options"},[_c('div',{staticClass:"input-options-title",domProps:{"textContent":_vm._s(_vm.words('suggestionTitle'))}}),_vm._l((_vm.options),function(option,optionId){return _c('div',{key:optionId,staticClass:"input-option",attrs:{"data-test-custom-input-option":""},domProps:{"textContent":_vm._s(option.name)},on:{"click":function($event){return _vm.handleOptionClick(option)}}})})],2):_vm._e()]),(_vm.stateError && _vm.showTextMandatory)?_c('div',{staticClass:"error-message mt-2",attrs:{"data-test-custom-input-error":""},domProps:{"textContent":_vm._s(_vm.words('requiredField'))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }