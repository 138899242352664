var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"fixed-header":"","item-key":"id","items":_vm.behaviors,"headers":_vm.headers,"sort-by":_vm.orderBy,"sort-desc":true,"hide-default-footer":true,"disable-pagination":true},on:{"update:sortBy":function($event){_vm.orderBy=$event},"update:sort-by":function($event){_vm.orderBy=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item,index){return _c('div',{key:item.name,staticClass:"custom-table-row"},[_c('td',[_c('div',{staticClass:"custom-name",on:{"click":function($event){return _vm.openDetailBehaviorDialog(item)}}},[_c('div',{staticClass:"unfavorable-container"},[(item.unfavorable === true && item.value === null)?_c('div',{staticClass:"unfavorable"},[_c('v-img',{attrs:{"src":require("@/assets/images/Culture/down.png"),"alt":"unfavorable","max-height":"9px","max-width":"9px","height":"9px"}})],1):_vm._e()]),_c('div',{staticClass:"pointer"},[_c('v-img',{attrs:{"src":item.image,"alt":item.description,"max-height":"27px","max-width":"27px","width":"27px"}})],1),_c('div',{staticClass:"line-title"},[_c('span',{staticClass:"pointer",domProps:{"textContent":_vm._s(item.name)}}),_c('v-tooltip',{attrs:{"top":"","max-width":"18.75rem"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"tooltip-icon"},'span',attrs,false),on),[_c('v-icon',{staticClass:"fi-rr-interrogation",attrs:{"size":"0.75rem"}})],1)]}}],null,true)},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(item.description)}})])])],1)])]),_c('td',[_c('div',{staticClass:"custom-position"},[(_vm.orderBy === 'votes')?_c('span',{domProps:{"textContent":_vm._s(item.position !== null ? item.position + 'º' : '--')}}):_c('span',{domProps:{"textContent":_vm._s(
                item.positionProbability !== null
                  ? item.positionProbability + 'º'
                  : '--'
              )}})])]),_c('td',{staticClass:"custom-votes",class:{ active: _vm.orderBy === 'votes' }},[_c('span',{domProps:{"textContent":_vm._s(
              _vm.$t('engagementPage.culture.cultureCode.countVotes', {
                count: item.votes || 0,
              })
            )}})]),_c('td',{staticClass:"custom-probability",class:{ active: _vm.orderBy === 'probability' }},[_c('span',{domProps:{"textContent":_vm._s(item.probability !== null ? item.probability + '%' : '--')}})]),_c('td',[_c('div',{staticClass:"custom-actions"},[_c('v-menu',{ref:'behaviorMenu-' + index,refInFor:true,attrs:{"content-class":"pl-2 pa-1 elevation-0","close-on-content-click":false,"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"add",attrs:{"text":"","plain":""}},'v-btn',attrs,false),on),[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('tableBehaviors.actionText'))}})])]}}],null,true)},[_c('QuickSelectionValues',_vm._g({attrs:{"values":_vm.values,"behavior":item},on:{"close":function($event){_vm.$refs[`behaviorMenu-${index}`].save()}}},_vm.$listeners))],1)],1)])])}),0)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }