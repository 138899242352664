<template>
  <div class="survey-finish__content content fill-height">
    <div v-if="accessibility" class="content-video-body">
      <VideoComponent
        data-test-video-explanation-accessibility
        class="content-accessibility-video"
        :autoplay="true"
        :video="$t('survey.lastPage.accessibility.videoURL')"
      />
    </div>

    <div v-else class="content-image-body">
      <img
        class="content-image"
        :src="require('@/assets/images/Survey/final.svg')"
        alt=""
      />
    </div>

    <div class="content-title-body">
      <span
        tabindex="0"
        class="content-title"
        v-text="title"
        data-test-title-finished-survey
      />
    </div>

    <div class="content-text">
      <span tabindex="0" v-text="text1" data-test-text-finished-survey />
      <span
        v-if="text2"
        tabindex="0"
        class="mt-2"
        v-text="text2"
        data-test-text-finished-survey
      />
    </div>

    <div class="content-card-body" v-if="cards.length">
      <v-card
        data-test-cards-survey-finish
        class="survey-finish__card content-card"
        v-for="(item, index) of items"
        :key="index"
        tabindex="0"
        :title="item.help"
        :data-test-finished-survey-card="item.key"
        @click="item.handleClick"
        :disabled="item.hide"
        v-show="!item.hide"
      >
        <div class="survey-finish__card__title--icon">
          <v-icon v-text="'fi-rr-interrogation'" size="1em" />
        </div>
        <v-card-title class="justify-center">
          <img class="content-card-image" :src="item.image" alt="" />
        </v-card-title>
        <v-card-actions class="justify-center">
          <span class="content-card-text" v-text="item.text" />
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { sendSurvey } from '@/service/survey'
import shareImage from '@/assets/qr-code-sm.png'
import otherImage from '@/assets/images/Survey/other.svg'
import backImage from '@/assets/images/Survey/back.svg'
import VideoComponent from '@/components/VideoComponent/VideoComponent'

export default {
  name: 'SurveyFinish',
  components: { VideoComponent },
  props: {
    blocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cards: [
        {
          key: 'share',
          hide: false,
          image: otherImage,
          text: 'Quero responder novamente',
          help: 'Quero responder novamente',
          handleClick: this.shareSuggestion,
        },
        {
          key: 'subjects',
          hide: false,
          image: shareImage,
          text: 'Quero convidar minha equipe',
          help: 'Quero convidar minha equipe',
          handleClick: this.othersSubjects,
        },
        {
          key: 'back',
          hide: false,
          image: backImage,
          text: this.$t('survey.lastPage.cards.back.text'),
          help: this.$t('survey.lastPage.cards.back.help'),
          handleClick: this.backToHome,
        },
      ],
    }
  },
  computed: {
    ...mapState(['survey']),
    accessibility: function () {
      return this.survey.accessibility
    },
    title: function () {
      return this.$t(
        `survey.lastPage.title.${this.blocked ? 'blocked' : 'finished'}`
      )
    },
    text1: function () {
      return this.$t(
        `survey.lastPage.text1.${this.blocked ? 'blocked' : 'finished'}`
      )
    },
    text2: function () {
      return this.$t(
        `survey.lastPage.text2.${this.blocked ? 'blocked' : 'finished'}`
      )
    },
    items: function () {
      // Accessibility
      if (this.accessibility) return [2].map((index) => this.cards[index])
      // Blocked
      if (this.blocked) return [0, 2].map((index) => this.cards[index])
      // Default
      return [0, 1, 2].map((index) => this.cards[index])
    },
  },

  beforeMount() {
    this.submit()
    this.hideCards()
  },

  methods: {
    ...mapActions([
      'setSurveyState',
      'resetSurveyOptions',
      'resetSurveyAnswers',
    ]),

    submit() {
      const payload = this.survey.questions.map((e) => {
        const buildCultural = (element = {}) => ({
          questionID: 'cultural-votes',
          answers: element.answers.map((a) => ({ answerID: a.answerID })),
          answered: element.answered
            .filter((a) => a.vote)
            .map((a) => ({ answerID: a.answerID })),
        })
        const buildQuestion = (element = {}) => ({
          metricID: element.metricID,
          submetricID: element.submetricID,
          questionID: element.questionID,
          answerID: (element.answered || {}).answerID,
          opinion: (element.answered || {}).opinion
            ? {
                message: element.answered.opinion.text,
                isAnonymous: !element.answered.opinion.assigned,
              }
            : null,
        })

        const isCultural = Array.isArray(e.answered)
        return isCultural ? buildCultural(e) : buildQuestion(e)
      })

      if (!payload || !payload.length) return

      sendSurvey(payload)
        .catch((err) => {
          console.log(err.message)

          this.$root.$emit('display-alert', {
            title: this.$t('survey.lastPage.errors.sendSurvey.title'),
            description: this.$t(
              'survey.lastPage.errors.sendSurvey.description'
            ),
            type: 'error',
          })
        })
        .finally(() => {
          this.resetSurveyOptions()
          this.resetSurveyAnswers()
        })
    },

    hideCards() {
      if (this.survey.isAnsweringOtherSubject) this.cards[1].hide = true
    },
    shareSuggestion() {
      this.setSurveyState({ state: 'init' })
    },
    othersSubjects() {
      this.setSurveyState({ state: 'other' })
    },
    backToHome() {
      window.location = '/engagement/climate'
      // this.$router.push({ name: 'ClimatePage' })
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
