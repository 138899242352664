<template>
  <div>
    <v-chip v-if="scoreName" :color="getColor(scoreName)" text-color="white">
      <span v-text="$t(`${names[scoreName]}`)"></span>

      <v-tooltip top max-width="300px">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="tooltip-icon">
            <i class="fi-rr-interrogation d-flex" />
          </span>
        </template>

        <div>
          <span
            v-text="$t('engagementPage.behaviorClassification.description')"
          ></span>
        </div>
      </v-tooltip>
    </v-chip>

    <v-chip v-else color="neutral8" text-color="neutral5">
      <span v-text="'N/A'"></span>

      <v-tooltip top max-width="300px">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="tooltip-icon">
            <i class="fi-rr-interrogation d-flex" />
          </span>
        </template>

        <div>
          <span
            v-text="$t('engagementPage.behaviorClassification.description')"
          ></span>
        </div>
      </v-tooltip>
    </v-chip>
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'Score',
  props: {
    scoreName: { type: String },
  },
  data() {
    return {
      names: {
        high: 'engagementPage.behaviorClassification.high',
        medium: 'engagementPage.behaviorClassification.medium',
        low: 'engagementPage.behaviorClassification.low',
        veryLow: 'engagementPage.behaviorClassification.veryLow',
      },
    }
  },
  methods: {
    getColor(scoreName) {
      if (scoreName == 'high') return variables.greenColor
      if (scoreName == 'medium') return variables.yellowColor5
      if (scoreName == 'low') return variables.statusError
      if (scoreName == 'veryLow') return variables.statusError
      return variables.neutral8
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
