<template>
  <div :id="`history-participation-${type}`" class="shadow">
    <v-card elevation="0" height="100%" data-test-card-participation-history>
      <label class="px-4 py-2 title">{{
        $t(`cardParticipationHistory.title.${type}`)
      }}</label>

      <v-divider />

      <div v-if="loading" class="load-content">
        <v-skeleton-loader width="100%" height="100%" type="image" />
      </div>

      <HistoryChart
        v-else
        class="ma-6"
        hideSingleSerie
        :metrics="metrics"
        :dates="dates"
      />
    </v-card>
  </div>
</template>

<script>
import HistoryChart from '@/components/HistoryChart/HistoryChart.vue'
export default {
  name: 'CardParticipationHistory',
  components: {
    HistoryChart,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    metrics: {
      type: Array,
      default: () => [],
      require: true,
    },
    dates: {
      type: Array,
      default: () => [],
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
