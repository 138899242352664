<template>
  <div v-if="loading">
    <v-skeleton-loader type="button" class="ma-2 mr-4 skeleton-benchmark" />
  </div>
  <div v-else class="benchmark-container">
    <div class="badge">
      <img
        :class="{ blur: locked }"
        :src="require(`@/assets/images/BenchmarkBadge/${rankName}.png`)"
        :alt="rankName"
      />
      <div class="subtitle">
        <span v-html="$t(`${pathLocked}`)" v-if="locked"></span>
        <span v-html="$t(`${pathRank}`)" v-else></span>
      </div>
    </div>

    <div class="header" v-if="rankName !== 'unranked'">
      <i class="fi fi-sr-crown" v-if="locked" />
      <span
        v-html="
          $t(`${pathStatus}.title`, {
            rank: locked ? $t(`${pathLocked}`) : $t(`${pathRank}`),
          })
        "
      ></span>
    </div>
    <div class="header" v-else>
      <span>{{ $t(`${pathNoRank}title`) }}</span>
    </div>
    <div v-if="locked" class="blur-lock" @click="handleDialogLock">
      <i class="fi fi-sr-lock"></i>
    </div>
    <div class="body" :class="{ blur: locked }" v-if="rankName !== 'unranked'">
      <span v-html="$t(`${pathStatus}.body`, { rank: `${rank}` })"></span>
    </div>
    <div class="body" :class="{ blur: locked }" v-else>
      <span>{{ $t(`${pathNoRank}body`) }}</span>
    </div>
    <div class="footer">
      <span @click="handleDialogNPS" v-if="status !== 'overallAverage'">{{
        $t(`${pathStatus}.footer`)
      }}</span>
      <span @click="handleBenchmarkRankingDialog" v-else>{{
        $t(`${pathStatus}.footer`)
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'BenchmarkBadge',
  props: {
    locked: {
      type: Boolean,
      required: true,
    },
    rank: {
      type: Number,
      default: () => null,
    },
    percentage: {
      type: Number,
    },
    status: {
      type: String,
      required: true,
      validator: function (val) {
        const status = ['overallAverage', 'enps', 'lnps']
        return status.indexOf(val) !== -1
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pathLocked: 'benchmarkBadge.ranks.mysterious',
      pathNoRank: 'benchmarkBadge.noRank.',
    }
  },
  methods: {
    ...mapMutations([
      'openUpdateYourPackageDialog',
      'openExplanationNPSDialog',
      'handleBenchmarkRankingDialog',
    ]),
    handleDialogLock() {
      this.openUpdateYourPackageDialog('benchmark')
    },
    handleDialogNPS() {
      this.openExplanationNPSDialog(this.status)
    },
    handleDialogRank() {
      this.dialogRank = !this.dialogRank
    },
  },
  computed: {
    pathStatus() {
      return 'benchmarkBadge.' + this.status
    },
    rankName() {
      if (!this.rank) return 'unranked'
      if (this.rank < 55) return 'bronze'
      if (this.rank < 65) return 'silver'
      if (this.rank < 75) return 'gold'
      if (this.rank < 85) return 'platinum'
      if (this.rank < 95) return 'diamond'
      return 'champion'
    },
    pathRank() {
      return 'benchmarkBadge.ranks.' + this.rankName
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
