<template>
  <SkeletonLoader v-if="loading" />
  <v-card
    v-else
    id="engagement-recommendations"
    max-width="100%"
    class="engagement-wrapper"
    data-test-recommendations-container
  >
    <div class="engagement-wrapper-header">
      <div class="engagement-wrapper-header-container">
        <v-card-title class="engagement-wrapper__title">
          <div v-if="lockRecommendations" class="crown">
            <i class="fi fi-sr-crown"></i>
          </div>
          <p>
            {{ $t('engagementRecommendations.title') }}
          </p>
        </v-card-title>
        <div class="how-to-container">
          <HowToBeginAndWatchAVideo videoType="recommendations" />
        </div>
      </div>
      <v-card-subtitle class="engagement-wrapper__subtitle">{{
        $t('engagementRecommendations.subtitle')
      }}</v-card-subtitle>
    </div>
    <div
      class="engagement-wrapper-content"
      :class="{ show: showAll }"
      v-if="_recommendations.length"
    >
      <EngagementRecommendationsCard
        v-for="i in _recommendations"
        :key="i.id"
        :card-id="i.id"
        :card-tag="i.metrics[0].label"
        :card-title="i.title"
        :card-image="i.image"
        :card-description="i.description"
        :canNewPlan="i?.canNewPlan || canNewPlan"
      />
    </div>
    <div v-else class="pa-4 text-center">
      {{ $t('engagementRecommendations.noData') }}
    </div>

    <v-card-actions v-if="!showAll" class="engagement-wrapper-footer">
      <i class="fi fi-rr-physics"></i>
      <p
        data-test-engagementRecommendationShowAll
        @click="() => (showAll = true)"
        class="engagement-wrapper-footer__text"
      >
        {{ $t('engagementRecommendations.showMoreCards') }}
      </p>
    </v-card-actions>
    <v-card-actions v-if="showAll" class="engagement-wrapper-footer">
      <i class="fi fi-rr-physics"></i>
      <p
        data-test-engagementRecommendationShowAll
        @click="() => (showAll = false)"
        class="engagement-wrapper-footer__text"
      >
        {{ $t('engagementRecommendations.showLessCards') }}
      </p>
    </v-card-actions>
  </v-card>
</template>

<script>
import EngagementRecommendationsCard from '@/components/EngagementRecommendationsCard/EngagementRecommendationsCard.vue'
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'
import SkeletonLoader from '@/components/EngagementRecommendations/parts/SkeletonLoader.vue'
import mock from '../../../tests/__mocks__/EngagementRecommendations.js'

export default {
  name: 'EngagementRecommendations',
  components: {
    EngagementRecommendationsCard,
    HowToBeginAndWatchAVideo,
    SkeletonLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    recommendations: {
      type: Array,
      required: true,
    },
    lockRecommendations: {
      type: Boolean,
      default: true,
    },
    canNewPlan: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    _recommendations() {
      if (this.lockRecommendations) {
        return mock
      }

      return this.recommendations
    },
  },
  data: () => ({
    showAll: false,
  }),
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
