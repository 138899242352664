<template>
  <!--container-->
  <div
    :class="`slider-component d-flex flex-column
    ${sm ? 'sm' : ''}`"
  >
    <!--Text Accessibility-->
    <span
      class="accessibility-hide-text"
      tabindex="0"
      v-text="$t('answerSliderCard.accessibility.help1')"
    />

    <!--Confirm Text-->
    <div
      data-test-confirm-text
      :class="`confirm-container
        ${!initialized || dragging || pressing ? 'no-opacity' : ''}`"
      :style="`padding: 0 ${px}px`"
      @click="emitInput(true)"
    >
      <div class="confirm-sub-container">
        <span
          id="confirm-text"
          ref="confirm"
          v-text="$t('answerSliderCard.confirm')"
          :style="{ left: getLeft }"
        />
      </div>
    </div>

    <!--Slider-->
    <div class="flex-grow-1">
      <input
        data-test-input-range-slider
        ref="slider"
        v-model="value"
        type="range"
        :class="`slider
            slider_thumb-${initialized ? 'check' : 'menu'}
            ${dragging ? 'slider_thumb-active' : ''}`"
        :min="min"
        :max="max"
        :disabled="readOnly"
        :step="pressing ? 10 : null"
        :aria-valuetext="`${value}% ${
          selected ? $t('answerSliderCard.accessibility.selected') : ''
        }`"
        @mousedown="handleMouseDown()"
        @mouseup="handleMouseUp()"
        @mousemove="handleMouseMove()"
        @keydown.left.right="pressing = true"
        @keyup.left.right="pressing = false"
        @keyup.enter.space="emitInput(true)"
      />
      <!--Text-->
      <div class="help-container d-flex flex-grow-1 no-select">
        <span
          class="help-label text-start flex-grow-1"
          :class="`${value < 50 ? getColor(value) + '--text' : ''}`"
          v-text="items[0]['text']"
          :style="getNegative"
        />
        <div
          class="d-flex help-label-center"
          v-if="!readOnly && (!initialized || dragging)"
        >
          <i class="fi fi-rr-arrow-small-left icon-arrow-left"></i>
          <span v-text="$t('answerSliderCard.drag')" />
          <i
            class="fi fi-rr-arrow-small-right icon-arrow-right right-animation"
          ></i>
        </div>
        <span
          class="help-label flex-grow-1 text-end"
          :class="`${value > 50 ? getColor(value) + '--text' : ''}`"
          v-text="items[items.length - 1]['text']"
          :style="getPositive"
        />
      </div>
    </div>
    <!--card-->
    <div class="slider-card align-self-center">
      <img :src="image" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnswerSliderCard',
  props: {
    sm: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
    init: {
      type: Number,
    },
    items: {
      // [{ answerID: 'answer1a', score: -2, text: '2'},...]
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      value: 50,
      score: 0,
      image: null,
      min: 0,
      max: 100,
      breakpoints: [],
      pressing: false,
      dragging: false,
      clicking: false,
      selected: false,
      initialized: false,
      px: 0,
    }
  },
  watch: {
    ['value']: function (v) {
      this.initialized = true
      this.setColor(v)
      this.setScore(v)
    },
    ['score']: function (s) {
      this.setImage(s)
    },
    ['$vuetify.breakpoint.name']: function () {
      this.setPX()
    },
  },
  computed: {
    getLeft: function () {
      return this.value + '%'
    },
    getNegative: function () {
      if (this.value > 50) return

      const value = (50 - this.value) * 2

      return {
        fontSize: `0.${875 + value}em`,
        fontWeight: 400 + value,
      }
    },
    getPositive: function () {
      if (this.value < 50) return

      const value = (this.value - 50) * 2

      return {
        fontSize: `0.${875 + value}em`,
        fontWeight: 400 + value,
      }
    },
  },
  mounted() {
    this.value = this.init || this.init == 0 ? this.init : 50
    this.setBreakpoints()
    this.setScore(this.value)
    this.setImage(this.score)
    this.setPX()
  },
  methods: {
    setBreakpoints() {
      const uniqueScores = [...new Set(this.items.map((e) => e.score))]
      const scores = uniqueScores.map((score) => ({
        score: score,
        length: this.items.filter((e) => e.score == score).length,
      }))
      const breakpointUnit = this.max / this.items.length
      const breakpointPercent = scores.map((e) => ({
        score: e.score,
        breakpoint: breakpointUnit * e.length,
      }))
      this.breakpoints = breakpointPercent.map((e) => ({
        score: e.score,
        breakpoint: breakpointPercent
          .filter((p) => p.score <= e.score)
          .map((p) => p.breakpoint)
          .reduce((a, b) => a + b, 0),
      }))
    },
    setScore(v) {
      this.score = this.getScore(v)

      this.emitInput()
    },
    getScore(v) {
      for (const e of this.breakpoints) if (v <= e.breakpoint) return e.score
    },
    setColor(v) {
      this.$refs.slider.style.setProperty(
        '--slider_thumb-background',
        `var(--v-${this.getColor(v)}-base`
      )
    },
    getColor(v) {
      if (v < 50) return `sliderTrack1`
      if (v == 50) return 'neutral'
      if (v < 75) return `sliderTrack2`
      if (v <= 100) return `sliderTrack3`
      return 'neutral'
    },
    setImage(s) {
      if (!s && s != 0) return

      const item = this.items.find((e) => e.score == s)
      if (!item) return

      this.image = item.image
    },
    setPX() {
      const element = this.$refs.confirm
      if (!element) return

      this.px = element.clientWidth / 2
    },
    handleMouseDown() {
      this.initClickValue = this.value
      this.clicking = true
      this.dragging = false
    },
    handleMouseUp() {
      if (
        this.initialized &&
        this.clicking &&
        !this.dragging &&
        this.initClickValue == this.value
      )
        this.emitInput(true)

      this.clicking = false
      this.dragging = false
    },
    handleMouseMove() {
      if (this.clicking && !this.dragging) this.dragging = true
    },
    emitInput(next = false) {
      if (!this.initialized && !this.init && this.init != 0) return

      // Answer
      this.$emit('input', this.buildEmit())

      // Next
      if (next) this.$emit('select', this.buildEmit())
    },
    buildEmit() {
      return {
        value: this.value,
        score: this.score,
        answerID: this.items.find((e) => e.score == this.score)['answerID'],
      }
    },
  },
}
</script>

<style scoped lang="scss" src="./style.scss"></style>
