<template>
  <div data-test-opinions-page id="opinions-page" class="page-container">
    <PageNavigation
      :listView="listView"
      @handleListView="handleListView"
    ></PageNavigation>

    <router-view
      :groupID="groupID"
      :listView="listView"
      :variationPeriod="variationPeriod"
    ></router-view>
  </div>
</template>

<script>
import PageNavigation from '@/pages/EngagementPage/Tabs/Opinions/Partials/PageNavigation/PageNavigation.vue'

export default {
  name: 'OpinionsPage',
  components: {
    PageNavigation,
  },

  props: {
    groupID: {
      type: String,
      default: '',
    },
    variationPeriod: {
      type: String,
      default: 'real-time',
    },
  },
  data() {
    return {
      listView: false,
    }
  },

  methods: {
    handleListView() {
      this.listView = !this.listView
    },
  },

  created() {
    this.$store.commit('setCurrentTab', 'OpinionsPage')
  },
}
</script>
