<template>
  <v-dialog v-model="dialog" max-width="840" @input="refresh()">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        data-test-btn-share
        v-bind="attrs"
        v-on="on"
        class="bttn ml-2"
        outlined
        icon
        height="46"
        width="42"
        text
      >
        <v-icon small> fi-rr-share </v-icon>
      </v-btn>
    </template>

    <v-card>
      <div class="d-flex content-header">
        <span class="content-header-title">
          {{ $t('shareResults.title') }}
        </span>

        <v-spacer />

        <v-btn
          color="neutral5"
          small
          icon
          @click="dialog = false"
          data-test-btn-x
        >
          <v-icon>fi-rr-cross-small</v-icon>
        </v-btn>
      </div>

      <section>
        <ViewList
          v-on="$listeners"
          ref="views"
          :type="type"
          :people="people"
          :results="results"
          :accessList="accessList[type]"
        />

        <SharedLinks
          data-test-shared-links
          v-on="$listeners"
          ref="links"
          :type="type"
          :links="links"
          :accessList="accessList[type]"
        />
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewList from './Parts/ViewList/ViewList.vue'
import SharedLinks from './Parts/SharedLinks/SharedLinks.vue'

export default {
  data() {
    return {
      dialog: false,
      accessList: {
        climate: [
          {
            value: 'full',
            label: this.$t(`shareResults.permissions.full`),
          },
          {
            value: 'full-climate',
            label: this.$t(`shareResults.permissions.full-climate`),
          },
          {
            value: 'enps-only',
            label: this.$t(`shareResults.permissions.enps-only`),
          },
          {
            value: 'lnps-only',
            label: this.$t(`shareResults.permissions.lnps-only`),
          },
          {
            value: 'score-only',
            label: this.$t(`shareResults.permissions.score-only`),
          },
          {
            value: 'metrics-summary-only',
            label: this.$t(`shareResults.permissions.metrics-summary-only`),
          },
          {
            value: 'metrics-full-only',
            label: this.$t(`shareResults.permissions.metrics-full-only`),
          },
          {
            value: 'smart-metrics-summary-only',
            label: this.$t(
              `shareResults.permissions.smart-metrics-summary-only`
            ),
          },
          {
            value: 'smart-metrics-full-only',
            label: this.$t(`shareResults.permissions.smart-metrics-full-only`),
          },
        ],
        culture: [
          {
            value: 'full',
            label: this.$t(`shareResults.permissions.full`),
          },
          {
            value: 'full-culture',
            label: this.$t(`shareResults.permissions.full-culture`),
          },
          {
            value: 'maturity-level-with-cultural-evolution-only',
            label: this.$t(
              `shareResults.permissions.maturity-level-with-cultural-evolution-only`
            ),
          },
          {
            value: 'present-culture-only',
            label: this.$t(`shareResults.permissions.present-culture-only`),
          },
          {
            value: 'cultural-alignment-only',
            label: this.$t(`shareResults.permissions.cultural-alignment-only`),
          },
          {
            value: 'unfavorable-culture-only',
            label: this.$t(`shareResults.permissions.unfavorable-culture-only`),
          },
        ],
      },
    }
  },

  props: {
    type: null,
    people: [],
    results: [],
    links: [],
  },

  components: {
    ViewList,
    SharedLinks,
  },

  computed: {
    ...mapGetters(['getGroupId']),
  },

  methods: {
    refresh() {
      this.refreshViews()
      this.refreshLinks()
    },

    refreshLinks() {
      if (!this.$refs.links) return

      this.$refs.links.getLinks()
    },

    refreshViews() {
      if (!this.$refs.views) return

      return this.$refs.views.getResultSharing()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
