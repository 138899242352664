<template>
  <v-data-table
    item-key="id"
    :items="items"
    :headers="headers"
    :sort-by.sync="active"
    :sort-desc="true"
    multi-sort
    :footer-props="{
      'items-per-page-text': $t('cultureTable.footer.lines'),
      'items-per-page-all-text': $t('cultureTable.footer.all'),
    }"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <div v-for="item in items" :key="item.name" class="custom-table-row">
          <td>
            <div class="custom-name" @click="openDetailBehaviorDialog(item)">
              <div class="unfavorable-container">
                <div
                  v-if="item.unfavorable === true && item.value === null"
                  class="unfavorable"
                >
                  <v-img
                    src="@/assets/images/Culture/down.png"
                    alt="unfavorable"
                    max-height="9px"
                    max-width="9px"
                    height="9px"
                  />
                </div>
              </div>

              <div class="pointer">
                <v-img
                  :src="item.image"
                  max-height="27px"
                  max-width="27px"
                  width="27px"
                />
              </div>

              <div class="line-title">
                <span class="pointer" v-text="item.name"></span>

                <v-tooltip top max-width="18.75rem">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-icon">
                      <v-icon
                        class="fi-rr-interrogation"
                        size="0.75rem"
                      ></v-icon>
                    </span>
                  </template>

                  <div>
                    <span v-text="item.description"></span>
                  </div>
                </v-tooltip>
              </div>
            </div>
          </td>

          <td>
            <v-chip
              small
              class="custom-value"
              :class="{ 'with-value': !!item?.value?.name }"
              :color="tableValorColor(item.value)"
            >
              <span v-text="tableValor(item.value)"></span>
            </v-chip>
          </td>

          <td>
            <div class="custom-position">
              <span
                v-if="active === 'votes'"
                v-text="item.position + 'º'"
              ></span>
              <span v-else v-text="item.positionProbability + 'º'"></span>

              <Variation short xs :variation="item.variation" />
            </div>
          </td>

          <td class="custom-votes" :class="{ active: active === 'votes' }">
            <span
              v-text="(item.votes !== null ? item.votes : '--') + ' pts'"
            ></span>
          </td>

          <td
            class="custom-probability"
            :class="{ active: active === 'probability' }"
          >
            <span
              v-text="item.probability !== null ? item.probability + '%' : '--'"
            ></span>
          </td>

          <td>
            <Score class="card-score" :scoreName="item.reliabilityRate" />
          </td>

          <td>
            <v-btn
              data-test-behavior-list-open-modal
              text
              small
              class="custom-button"
              @click="openDetailBehaviorDialog(item)"
            >
              <span
                class="details"
                v-text="$t('engagementPage.behaviorClassification.moreDetails')"
              ></span>
            </v-btn>
          </td>
        </div>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import Score from '../Score/Score.vue'
import variables from '@/styles/abstracts/_colors.scss'
import Variation from '@/components/Variation/Variation.vue'

export default {
  name: 'CultureTable',
  components: {
    Score,
    Variation,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    active: String,
  },

  data(vm) {
    return {
      headers: [
        {
          text: vm.$t('cultureTable.headers.behaviors'),
          align: 'start',
          value: 'name',
        },
        { text: vm.$t('cultureTable.headers.value'), value: 'value.name' },
        { text: vm.$t('cultureTable.headers.rank'), value: 'position' },
        { text: vm.$t('cultureTable.headers.votes'), value: 'votes' },
        {
          text: vm.$t('cultureTable.headers.probability'),
          value: 'probability',
        },
        {
          text: vm.$t('cultureTable.headers.reliabilityRate'),
          value: 'reliabilityRate',
        },
        {
          text: vm.$t('cultureTable.headers.actions'),
          sortable: false,
          value: 'Ações',
        },
      ],
    }
  },
  methods: {
    tableValorColor(item) {
      if (item) {
        if (item.hasOwnProperty('name')) {
          return variables.yellowColor2
        }
      }
      return variables.neutral8
    },
    tableValor(item) {
      if (item) {
        if (item.hasOwnProperty('name')) {
          return item.name
        }
      }
      return 'N/A'
    },
    openDetailBehaviorDialog(behavior) {
      this.$emit('openBehaviorDetailHistory', behavior)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
