<template>
  <div data-test-export-results class="page-selects-export">
    <v-menu
      content-class="elevation-neutral border-neutral"
      offset-y
      bottom
      attach
      transition="slide-y-transition"
      z-index="100"
      min-width="168px"
      nudgeBottom="5"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="export-btn border-neutral"
          large
          text
          :disabled="isDownloading"
        >
          <v-icon v-if="!isDownloading">fi-rr-download</v-icon>
          <v-progress-circular v-else indeterminate color="primary" />
        </v-btn>
      </template>

      <div class="export-menu">
        <template v-for="exportable in exportablesOptions">
          <v-btn
            :key="exportable.id"
            :data-test-export-results="exportable.id"
            text
            tile
            class="export-item"
            @click="handleExportable(exportable)"
          >
            <span v-text="exportable.label" />
          </v-btn>
        </template>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ExportResults',
  props: {
    exportablesOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDownloading: false,
    }
  },

  methods: {
    genDownloadPromise(ObjectURL, downloadLINK) {
      return new Promise((resolve, reject) => {
        const downloadCompleted = () => {
          resolve()

          setTimeout(() => {
            URL.revokeObjectURL(ObjectURL)
          }, 0)
        }

        const downloadCanceled = () => {
          reject(new Error('Download canceled'))
          URL.revokeObjectURL(ObjectURL)
        }

        downloadLINK.addEventListener('click', downloadCompleted)
        downloadLINK.addEventListener('abort', downloadCanceled)
        downloadLINK.addEventListener('error', downloadCanceled)

        downloadLINK.click()
      })
    },

    calcExportFile(response) {
      const filename = response.headers['content-disposition']
        .match(/filename=(?<filename>[^,;]+);?/)[1]
        .trim()

      const filetype = response.headers['content-type']
      const blobProperties = {
        type: filetype,
        encoding: 'UTF-8',
      }
      const blob =
        response.data instanceof Blob
          ? response.data
          : new Blob([response.data], blobProperties)
      const url = window.URL.createObjectURL(blob, blobProperties)

      const link = document.createElement('a')
      link.href = url
      link.download = filename

      return { url, link }
    },

    async handleExportable(exportable) {
      this.isDownloading = true

      try {
        const response = await exportable.endpoint({ ...exportable.payload })

        const { url, link } = this.calcExportFile(response)

        await this.genDownloadPromise(url, link)
      } catch {
        this.$emit('alert', {
          title: this.$t('exportable.errors.title', {
            format: exportable.id,
          }),
          description: this.$t('exportable.errors.description'),
        })
      } finally {
        this.isDownloading = false
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
