<template>
  <div class="class-progress">
    <v-progress-linear
      id="progresslinear"
      :value="scoreProgress"
      class="class-bar"
      :color="colorProgress"
      :background-color="backgroudColor"
    />
  </div>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'

export default {
  props: {
    score: Number,
  },
  data() {
    return {
      backgroudColor: variables.grayColor,
    }
  },
  computed: {
    scoreProgress() {
      return this.score * 10
    },
    colorProgress() {
      if (this.score >= 0 && this.score < 5) {
        return variables.redColor
      } else if (this.score < 7.5) {
        return variables.yellowColor
      }
      return variables.greenColor
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
