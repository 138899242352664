<template>
  <div id="not-found-page" class="not-found">
    <v-img
      class="not-found-bg"
      alt="background"
      :src="require('@/assets/images/NotFound/background.png')"
    ></v-img>

    <div class="not-found-header">
      <v-img
        height="36px"
        contain
        :src="require('@/assets/images/logo.svg')"
        alt="logo"
      ></v-img>
    </div>

    <div class="not-found-content">
      <div class="not-found-title" v-text="$t('notFound.title')"></div>

      <div class="not-found-image">
        <img
          contain
          height="238px"
          alt="feedback"
          :src="require('@/assets/images/NotFound/feedback.png')"
        />
      </div>

      <div class="not-found-help help-home">
        {{ $t('notFound.updatePageMessage') }}

        <router-link
          class="help-strong"
          to="/"
          v-text="$t('notFound.updatePage')"
        ></router-link>
      </div>

      <div class="not-found-help help-email">
        {{ $t('notFound.emailMessage') }}

        <a
          class="help-strong"
          :href="'mailto:' + $t('notFound.email')"
          v-text="$t('notFound.email')"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { calcLanguageToLocale } from '@/helpers/locale'

export default {
  name: 'NotFoundPage',
  inject: ['changeLanguage'],

  created() {
    const lang = calcLanguageToLocale(
      navigator.language || navigator.userLanguage
    )

    this.changeLanguage(lang)
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
