<template>
  <div
    :class="`d-flex flex-column justify-center align-center
    ${sm ? 'sm' : ''}`"
  >
    <div class="accessibility-hide-text" tabindex="0">
      <span v-text="$t('answerStars.accessibility.help1')" />
    </div>

    <v-rating
      data-test-rating-stars
      id="v-rating"
      ref="stars"
      class="star-rating"
      v-model="value"
      :length="5"
      :size="size"
      :readonly="readOnly"
      hover
      full-icon="$starRoundedOutline"
      empty-icon="$starRounded"
      color="primary"
      background-color="neutral6"
    >
    </v-rating>
  </div>
</template>

<script>
export default {
  name: 'AnswerStars',
  props: {
    sm: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
    init: {
      type: Number,
    },
    items: {
      // [{ answerID: 'answer1a', score: -2, text: '2'},...]
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      initValue: this.init,
      value: null,
      score: null,
      size: 100,
    }
  },
  watch: {
    ['$vuetify.breakpoint.name']: function () {
      this.setSize()
    },
    ['value']: function (v) {
      this.setScore(v)
    },
  },
  mounted() {
    this.setSize()
    this.setInitValue()
    this.setStars()
  },
  methods: {
    setInitValue() {
      if (!this.init && this.init != 0) return (this.value = null)

      return (this.value = this.init + 3)
    },
    setStars() {
      this.$refs.stars.$children.forEach((e, index) => {
        const v = index + 1
        e.$el['id'] = `star${v}`
        e.$el.setAttribute('role', `button`)
        e.$el.setAttribute(
          'aria-label',
          `${this.$t('answerStars.accessibility.selectName')} ${v};`
        )
        e.$el.setAttribute('aria-pressed', `false`)
      })
    },
    setScore(value) {
      if (value == null) return

      const selected = value - 1

      this.$refs.stars.$children.forEach((e, index) => {
        e.$el.setAttribute('aria-pressed', (index == selected).toString())
      })

      this.score = value - 3

      this.$emit('input', this.buildEmit())

      // Next
      if (!this.initValue && this.initValue != 0)
        this.$emit('select', this.buildEmit())
      else this.initValue = null
    },
    buildEmit() {
      return {
        value: this.score,
        score: this.score,
        answerID: this.items.find((e) => e.score == this.score)['answerID'],
      }
    },
    setSize() {
      this.size = this.getSize(this.sm ? 'sm' : this.$vuetify.breakpoint.name)
    },
    getSize(breakpoint) {
      const sizes = {
        xs: 44,
        sm: 84,
        md: 104,
        lg: 124,
        xl: 144,
      }

      return sizes[breakpoint] || 100
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss"></style>
