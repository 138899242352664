import api from '@/service/Http'

export const createLink = async (data) =>
  (await api()).post('/shared-links', data)

export const getLinks = async (data) =>
  (await api()).get(
    `/shared-links?module=${data.module}&groupID=${data.groupID}`
  )

export const updateLink = async (data) =>
  (await api()).put(`/shared-links/${data.sharedLinkID}`, data.body)

export const deleteLink = async (data) =>
  (await api()).delete(`/shared-links/${data}`)
