import variables from '@/styles/abstracts/_colors.scss'

import i18n from '@/i18n'
import router from '@/router'

import * as _canKey from '@/helpers/ability/permissions'

/** @param {URL} url */
export function toLogin(url, basePath = '') {
  const token = localStorage.getItem('teamculture:token')

  if (token) {
    url.pathname = basePath + '/login'
    url.searchParams.set('t', token)
  }

  return url.toString()
}

export function genSiteNavigationItens(onClickLogout = () => {}) {
  const CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

  return {
    userLinks: {
      logout: {
        // click: onClickLogout,
        text: i18n.t('ui-components.siteNavigation.user.logout'),
      },
      help: {
        url: '/survey?show=true',
        text: i18n.t('ui-components.siteNavigation.help'),
      },
      settings: {
        url: '/survey?show=true',
        text: i18n.t('ui-components.siteNavigation.user.settings'),
      },
    },
    menuLinks: [
      {
        url: '/survey?show=true',
        image: {
          src: `${CDN_BASE_URL}images/navigation/home.svg`,
          title: i18n.t('ui-components.siteNavigation.Home'),
          color: variables.yellowColor8,
        },
        permissioned: _canKey.profile,
      },
      {
        url: null,
        image: {
          src: `${CDN_BASE_URL}images/navigation/engagement-active.svg`,
          title: i18n.t('ui-components.siteNavigation.Engagement'),
          color: variables.redColor11,
          current: true,
        },
        customEvents: {
          click: () => {
            const navigation = { name: 'EngagementPage' }
            if (router.currentRoute.name !== navigation.name) {
              router.push(navigation)
            }
          },
        },
        permissioned: _canKey.engagement_climate,
      },
      {
        url: '/survey?show=true',
        image: {
          src: `${CDN_BASE_URL}images/navigation/performance.svg`,
          title: i18n.t('ui-components.siteNavigation.Performance'),
          color: variables.blueColor8,
        },
        permissioned: _canKey.performance,
      },
      {
        url: '/survey?show=true',
        image: {
          src: `${CDN_BASE_URL}images/navigation/growth.svg`,
          title: i18n.t('ui-components.siteNavigation.Growth'),
          color: variables.greenColor12,
        },
        permissioned: _canKey.growth,
      },
      {
        url: '/survey?show=true',
        image: {
          src: `${CDN_BASE_URL}images/navigation/advanced-survery.svg`,
          title: i18n.t('ui-components.siteNavigation.Survery'),
          color: variables.pinkColor4,
        },
        permissioned: _canKey.customSurvey,
      },
      {
        url: '/survey?show=true',
        image: {
          src: `${CDN_BASE_URL}images/navigation/custom-survery.svg`,
          title: i18n.t('ui-components.siteNavigation.Poll'),
          color: variables.pinkColor4,
        },
        permissioned: _canKey.engagement,
      },
      {
        url: '/survey?show=true',
        image: {
          src: `${CDN_BASE_URL}images/navigation/management.svg`,
          title: i18n.t('ui-components.siteNavigation.Management'),
          color: variables.grayColor12,
        },
        permissioned: _canKey.management,
      },
    ],
  }
}
