var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('SkeletonLoader'):_c('div',{staticClass:"shadow"},[_c('v-card',{attrs:{"elevation":"0","height":"100%"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('engagementPage.cardAverageEngagement.cardTitle'))+" ")])]),_c('v-card-text',[_c('div',{staticClass:"engagement-container",attrs:{"data-test-groupScore":""}},[_c('div',{staticClass:"average-container",class:{
            'no-info-cursor':
              _vm.score === null || _vm.hideQuickComparison.includes('climate'),
          },on:{"mouseenter":function($event){return _vm.handleMouseEnter('climate')},"mouseleave":function($event){return _vm.handleMouseLeave()}}},[_c('EngagementAverage',{attrs:{"score":_vm.score}}),(
              _vm._haveQuickComparison && !_vm.hideQuickComparison.includes('climate')
            )?_c('div',{staticClass:"tooltip-container",attrs:{"data-test-engagementHover":""}},[_c('QuicklyComparison',{staticClass:"hidden-sm-and-down",attrs:{"groupScore":_vm._groupScore,"hasSelectedGroup":_vm.hasSelectedGroup,"accountScore":_vm.comparisonMetrics.account.score,"marketScore":_vm.comparisonMetrics.average.score,"groupPercent":_vm._groupRank,"accountPercent":_vm.comparisonMetrics.account.rank,"marketPercent":_vm.comparisonMetrics.average.rank,"hide-market-score":_vm._hideMarket,"percentageOfCompanies":_vm._percentageCompanies}})],1):_vm._e()],1),_c('div',{staticClass:"engagement-subtitle"},[_c('Variation',{attrs:{"variation":_vm.variation.score,"loading":_vm.variation.loading,"data-test-average-variation":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('engagementPage.variationTextDays', { days: 7 }))+" ")])],1),_c('p',{staticClass:"engagement-description"},[_vm._v(" "+_vm._s(_vm.$t('engagementPage.cardAverageEngagement.reportDescription'))+" ")])]),_c('div',{staticClass:"fast-comparison-container"},[_c('div',{staticClass:"participation-rate-container",class:{
            'no-info-cursor':
              _vm.participationRate === null ||
              _vm.hideQuickComparison.includes('participation'),
          },on:{"mouseenter":function($event){return _vm.handleMouseEnter('participation')},"mouseleave":function($event){return _vm.handleMouseLeave()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('engagementPage.cardAverageEngagement.participationRate')))]),(
              _vm._haveQuickComparison &&
              !_vm.hideQuickComparison.includes('participation')
            )?_c('div',{staticClass:"tooltip-container"},[_c('QuicklyComparison',{staticClass:"hidden-sm-and-down",attrs:{"groupScore":_vm._groupScore,"hasSelectedGroup":_vm.hasSelectedGroup,"accountScore":_vm.comparisonMetrics.account.score,"marketScore":_vm.comparisonMetrics.average.score,"groupPercent":_vm._groupRank,"accountPercent":_vm.comparisonMetrics.account.rank,"marketPercent":_vm.comparisonMetrics.average.rank,"hide-market-score":_vm._hideMarket,"percentageOfCompanies":_vm._percentageCompanies,"percentage":true,"data-test-participation-quick-comparison":""}})],1):_vm._e(),_c('h1',{attrs:{"data-test-participation-rate":""}},[_vm._v(" "+_vm._s(_vm.participationRate ? _vm.participationRate + '%' : '--')+" ")]),_c('Variation',{attrs:{"variation":_vm.variation.participation,"loading":_vm.variation.loading,"type":"percent","data-test-participation-variation":""}})],1),_c('div',{staticClass:"line"}),_c('div',{staticClass:"answers-rate-container",class:{
            'no-info-cursor':
              _vm.opinionsRate === null ||
              _vm.hideQuickComparison.includes('answeredOpinions'),
          },on:{"mouseenter":function($event){return _vm.handleMouseEnter('answeredOpinions')},"mouseleave":function($event){return _vm.handleMouseLeave()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('engagementPage.cardAverageEngagement.answeredOpnions')))]),(
              _vm._haveQuickComparison &&
              !_vm.hideQuickComparison.includes('answeredOpinions')
            )?_c('div',{staticClass:"tooltip-container"},[_c('QuicklyComparison',{staticClass:"hidden-sm-and-down",attrs:{"groupScore":_vm._groupScore,"hasSelectedGroup":_vm.hasSelectedGroup,"accountScore":_vm.comparisonMetrics.account.score,"marketScore":_vm.comparisonMetrics.average.score,"groupPercent":_vm._groupRank,"accountPercent":_vm.comparisonMetrics.account.rank,"marketPercent":_vm.comparisonMetrics.average.rank,"hide-market-score":_vm._hideMarket,"percentageOfCompanies":_vm._percentageCompanies,"percentage":true,"data-test-participation-quick-comparison":""}})],1):_vm._e(),_c('h1',{attrs:{"data-test-opinions-rate":""}},[_vm._v(" "+_vm._s(_vm.opinionsRate ? _vm.opinionsRate + '%' : '--')+" ")]),_c('Variation',{attrs:{"variation":_vm.variation.answeredOpinions,"loading":_vm.variation.loading,"type":"percent","data-test-opinions-variation":""}})],1)])]),_c('div',{staticClass:"footer-card-engagement"},[_c('div',{staticClass:"footer"},[_c('HowToBeginAndWatchAVideo',{attrs:{"videoType":"climate.overallAverage"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }