<template>
  <div :id="`${type}-card`" class="shadow">
    <v-card elevation="0" height="100%">
      <div class="header-container">
        <div class="header">
          <div class="header-title">
            <h3>{{ $t(`cardNPSEngagement.title.${type}`) }}</h3>
            <div class="header-component">
              <HowToBeginAndWatchAVideo :videoType="type" />
            </div>
          </div>
          <p class="subtitle">
            {{ $t(`cardNPSEngagement.subtitle.${type}`) }}
          </p>
        </div>
      </div>
      <v-divider class="custom-divider" />
      <v-row>
        <v-col sm="12" md="6" lg="5">
          <div class="skeleton">
            <v-skeleton-loader type="button" class="skeleton-vertical" />
            <div class="skeleton-card">
              <v-skeleton-loader type="button" class="skeleton-card--header" />
              <v-skeleton-loader type="button" class="skeleton-card--header2" />
              <v-skeleton-loader type="button" class="skeleton-card--body" />
              <v-skeleton-loader type="button" class="skeleton-card--footer" />
            </div>
          </div>
        </v-col>
        <v-col sm="12" md="6" lg="7">
          <div class="skeleton-b">
            <v-skeleton-loader
              v-for="i in 12"
              :key="i"
              type="button"
              class="skeleton-vertical"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider class="custom-divider" />
      <v-row>
        <v-col sm="6" md="3" lg="3" v-for="name in notesName" :key="name">
          <div class="content-center">
            <img
              width="40px"
              height="40px"
              :src="require(`@/assets/images/CardNPSEngagement/${name}.svg`)"
            />
            <div class="score">
              <label class="score-number-small skeleton-c">
                <v-skeleton-loader type="button" class="skeleton-number" />
                <v-skeleton-loader type="button" class="skeleton-number" />
              </label>
            </div>
            <label class="score-number-title">{{
              $t(`cardNPSEngagement.notes.${name}`)
            }}</label>
          </div>
        </v-col>
      </v-row>
      <div class="skeleton-title">
        <h3>{{ $t(`lastOpinions.title`) + ' ' + type }}</h3>
      </div>
      <v-divider class="custom-divider" />
      <v-row>
        <v-col xs="12">
          <div class="skeleton-d">
            <v-skeleton-loader
              v-for="i in 4"
              :key="i"
              type="button"
              class="skeleton-box"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="skeleton-e">
        <v-col xs="12">
          <v-skeleton-loader type="button" class="skeleton-e--footer" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

export default {
  components: {
    HowToBeginAndWatchAVideo,
  },
  props: {
    type: String,
    notesName: Array,
  },
}
</script>
<style src="../style.scss" lang="scss" scoped></style>
