<template>
  <div
    :class="`slider-component d-flex flex-column align-center
    ${sm ? 'sm' : ''}`"
  >
    <!--Text Accessibility-->
    <span
      class="accessibility-hide-text"
      tabindex="0"
      v-text="$t('answerSlider.accessibility.help1')"
    />

    <!--Video Accessibility-->
    <div v-if="video" class="video-component d-flex justify-center ma-4">
      <VideoComponent class="video" :video="video" :autoplay="true" />
    </div>

    <!--Help Text-->
    <div
      v-if="!readOnly"
      class="d-flex align-self-stretch help-container no-select"
    >
      <!--Drag-->
      <div v-if="!initialized || dragging || pressing" class="d-flex">
        <span class="help-label" v-text="$t('answerSlider.help')" />
        <i
          class="fi fi-rr-arrow-small-right icon-arrow-right right-animation"
        />
      </div>
      <!--Confirm Text-->
      <div
        :class="`confirm-container
        ${!initialized || dragging || pressing ? 'no-opacity' : ''}`"
        :style="`padding: 0 ${px}px`"
        @click="emitInput(true)"
      >
        <br />
        <div class="confirm-sub-container">
          <span
            id="confirm-text"
            ref="confirm"
            v-text="$t('answerSliderCard.confirm')"
            :style="{ left: getLeft }"
          />
        </div>
      </div>
    </div>

    <!--Slider-->
    <div class="d-flex justify-center mt-n2 full-width">
      <div class="flex-grow-1">
        <!--InputRange-->
        <input
          data-test-input-range-slider
          ref="slider"
          v-model="value"
          :class="`slider
            slider_thumb-${initialized ? 'check' : 'menu'}
            ${dragging ? 'slider_thumb-active' : ''}`"
          type="range"
          :min="min"
          :max="max"
          :disabled="readOnly"
          :step="pressing ? 10 : null"
          tabindex="0"
          role="slider"
          :aria-valuetext="`${value}% ${
            selected ? $t('answerSlider.accessibility.helpSelected') : ''
          }`"
          @mousedown="handleMouseDown()"
          @mouseup="handleMouseUp()"
          @mousemove="handleMouseMove()"
          @keydown.left.right="pressing = true"
          @keyup.left.right="pressing = false"
          @keyup.enter.space="emitInput(true)"
        />
        <!--Ticks-->
        <div class="slider_ticks-container d-flex justify-space-between">
          <div
            data-test-number-drag-aswer-slider
            class="slider_tick d-flex flex-column align-center"
            v-for="(item, index) of items"
            :key="index"
            @mousedown="handleSliderTickClick(item)"
          >
            <div class="line mt-n2" />
            <span
              :class="`values mt-1 l2 no-select
                ${
                  isSelected(index)
                    ? 'selected ' + getColor(value) + '--text'
                    : ''
                }`"
              v-text="item.text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoComponent from '@/components/VideoComponent/VideoComponent'

export default {
  name: 'AnswerSlider',
  components: { VideoComponent },
  props: {
    sm: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
    init: {
      type: Number,
    },
    items: {
      // [{ answerID: 'answer1a', score: -2, text: '2'},...]
      type: Array,
      required: true,
    },
    video: {
      type: String,
    },
  },
  data() {
    return {
      value: this.init || this.init == 0 ? this.init : 0,
      score: 0,
      min: 0,
      max: 100,
      breakpoints: [],
      pressing: false,
      dragging: false,
      clicking: false,
      selected: false,
      initialized: false,
      initClickValue: 0,
      px: 0,
    }
  },
  watch: {
    ['value']: function (v) {
      this.initialized = true
      this.setColor(v)
      this.setScore(v)
    },
    ['$vuetify.breakpoint.name']: function () {
      this.setPX()
    },
  },
  computed: {
    getLeft: function () {
      return this.value + '%'
    },
  },
  mounted() {
    this.setBreakpoints()
    this.setScore(this.value)
    this.setColor(this.value)
    this.setPX()
  },
  methods: {
    isSelected(v) {
      const decimal = this.value / 10

      return this.isBetween(v, [decimal - 0.5, decimal + 0.5])
    },
    // ex: (1, [0.5, 1.0]) true
    // ex: (5, [2.5, 3.5]) false
    isBetween(value, range) {
      return value >= range[0] && value < range[1]
    },
    setBreakpoints() {
      const uniqueScores = [...new Set(this.items.map((e) => e.score))]
      const scores = uniqueScores.map((score) => ({
        score: score,
        length: this.items.filter((e) => e.score == score).length,
      }))
      const breakpointUnit = this.max / this.items.length
      const breakpointPercent = scores.map((e) => ({
        score: e.score,
        breakpoint: breakpointUnit * e.length,
      }))
      this.breakpoints = breakpointPercent.map((e) => ({
        score: e.score,
        breakpoint: breakpointPercent
          .filter((p) => p.score <= e.score)
          .map((p) => p.breakpoint)
          .reduce((a, b) => a + b, 0),
      }))
    },
    setScore(v) {
      this.score = this.getScore(v)

      this.emitInput()
    },
    getScore(v) {
      for (const e of this.breakpoints) if (v <= e.breakpoint) return e.score
    },
    setColor(v) {
      this.$refs.slider.style.setProperty(
        '--slider_track-background',
        `var(--v-${this.getColor(v)}-base`
      )
    },
    getColor(v) {
      if (v < 50) return `sliderTrack1`
      if (v < 75) return `sliderTrack2`
      return `sliderTrack3`
    },
    setPX() {
      const element = this.$refs.confirm
      if (!element) return

      this.px = element.clientWidth / 2
    },
    handleMouseDown() {
      this.initClickValue = this.value
      this.clicking = true
      this.dragging = false
    },
    handleMouseUp() {
      if (
        this.initialized &&
        this.clicking &&
        !this.dragging &&
        this.initClickValue == this.value
      )
        this.emitInput(true)

      this.clicking = false
      this.dragging = false
    },
    handleMouseMove() {
      if (this.clicking && !this.dragging) this.dragging = true
    },
    handleSliderTickClick(item) {
      const value = item.text * (this.items.length - 1)

      if (this.value == value) return this.emitInput(true)

      this.value = value
    },
    emitInput(next = false) {
      if (!this.initialized && !this.init && this.init != 0) return

      this.$emit('input', this.buildEmit())

      if (next) this.$emit('select', this.buildEmit())
    },
    buildEmit() {
      return {
        value: this.value,
        score: this.score,
        answerID: this.items.find((e) => this.isSelected(e.text))['answerID'],
      }
    },
  },
}
</script>

<style scoped lang="scss" src="./style.scss"></style>
