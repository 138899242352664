import axios from 'axios'
// import { projectAuth } from '@/firebase/config'
import validateUnauthorized from '@/helpers/axios/on-response-validate-unauthorized'

const baseURL = process.env.VUE_APP_API_BASE_URL

const api = async () => {
  // const token = await projectAuth.currentUser.getIdToken()
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${token}`,
    'X-Apigateway-Api-Userinfo': process.env.VUE_APP_API_TEST_TOKEN,
  }

  const http = axios.create({ baseURL, headers })

  http.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      return Promise.reject(Object.assign(error, validateUnauthorized(error)))
    }
  )
  return http
}

export default api
