<template>
  <div class="howTo-container">
    <div class="howToBegin-container">
      <a
        class="howToBegin-container-link"
        :href="$t(`${pathType}.outsideURL`)"
        target="_blank"
        :data-test-how-to-begin="videoType"
      >
        <i class="fi fi-rr-book howToBegin-container-icon" />
        <span class="howToBegin-container-text">{{
          $t(`${path}.${firstLabelName}`)
        }}</span>
      </a>
    </div>
    <div
      v-if="false"
      class="watchAVideo-container"
      @click="handleDialog()"
      :data-test-watch-a-video="videoType"
    >
      <i class="fi fi-rr-play-alt watchAVideo-container-icon" />
      <span class="watchAVideo-container-text">{{
        $t(`${path}.secondLabel`)
      }}</span>
    </div>
    <v-dialog
      v-model="dialog"
      width="834px"
      :class="{ 'z-index-mobile': $vuetify.breakpoint.smAndDown }"
    >
      <v-card class="rounded-lg video-dialog">
        <div class="top-container">
          <div class="title" data-test-video-dialog-title>
            <h3>
              <b>{{ $t(`${path}.title`) }}</b>
              {{ $t(`${pathType}.secondTitle`) }}
            </h3>
          </div>
          <v-btn
            icon
            @click="handleDialog"
            data-test-close-how-to-dialog
            class="white mr-3 close-dialog-button"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div class="video-container" v-if="dialog">
          <embed
            :src="$t(`${pathType}.url`)"
            width="735"
            height="406"
            data-test-video-how-to
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'howToBeginAndWatchAVideo',

  props: {
    videoType: {
      type: String,
      require: true,
    },
    firstLabelAlternative: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      path: 'howToBeginAndWatchAVideo',
    }
  },
  methods: {
    handleDialog() {
      this.dialog = !this.dialog
    },
  },
  computed: {
    pathType() {
      return 'howToBeginAndWatchAVideo.videos.' + this.videoType
    },
    firstLabelName() {
      if (this.firstLabelAlternative) {
        return 'firstLabelAlternative'
      }
      return 'firstLabel'
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
