<template>
  <div
    :class="{
      'opinion-box__sidebar': modeSidebar,
      'state-active': isOpen,
    }"
  >
    <div class="opinion-box__sidebar-glass" @click="closeDialog()"></div>
    <div class="opinion-box__card">
      <div class="opinion-box__sidebar-header" v-if="modeSidebar">
        <i
          aria-hidden="true"
          class="fi fi-rr-arrow-left opinion-box__sidebar-icon"
          @click="closeDialog()"
        ></i>

        <span class="opinion-box__sidebar-title">{{
          $t('opinionBox.sidebar.title')
        }}</span>

        <v-btn
          fab
          icon
          class="opinion-box__sidebar-close"
          @click="closeDialog()"
        >
          <v-icon> mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="opinion-box__header">
        <div
          v-if="!chunksLoading"
          class="opinion-box__header-name"
          :data-test-anonymous="opinion.isAnonymous.toString()"
        >
          <div class="opinion-box__header-avatar">
            <Avatar
              v-if="opinion.isAnonymous"
              :src="
                require(`@/assets/images/OpinionsConversation/anonymous.svg`)
              "
              :displayAttr="{
                class: 'anonymous-icon',
                alt: 'anonymous',
                'max-width': '22',
                'aspect-ratio': '1',
                contain: true,
              }"
              size="30px"
            />
            <Avatar
              v-else
              :src="ownerIdentity.photo"
              :text="ownerIdentity.name"
              :color="getAvatarIdToColors[`${ownerIdentity.id}`]"
              :displayAttr="{ alt: 'user' }"
              size="30px"
            />
          </div>

          <span class="opinion-box__header-name-text" v-if="isEmpty">--</span>

          <div class="opinion-box__header-name-coplex" v-else>
            <span class="opinion-box__header-name-text">{{
              ownerDisplayName
            }}</span>

            <v-tooltip
              v-if="
                $vuetify.breakpoint.width <= 374 ||
                $vuetify.breakpoint.width / ownerDisplayName.length < 20
              "
              bottom
              allowOverflow
              open-on-click
              v-bind="nameTooltipAttr"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="opinion-name-extended">
                  <v-img
                    max-width="10"
                    max-height="10"
                    aspect-ratio="1"
                    contain
                    class="name-icon"
                    :src="
                      require(`@/assets/images/OpinionsConversation/fi-rr-menu-dots.svg`)
                    "
                    alt="name-icon"
                  />
                </div>
              </template>
              <div class="opinion-name-extended-tooltip">
                {{ ownerIdentity.name }}
              </div>
            </v-tooltip>
          </div>
        </div>
        <div
          v-else
          class="opinion-box__header-name-loading"
          :data-test-anonymous="opinion.isAnonymous.toString()"
        >
          <v-skeleton-loader
            width="30"
            height="30"
            type="avatar"
          ></v-skeleton-loader>
          <v-skeleton-loader height="18" type="text"></v-skeleton-loader>
        </div>

        <div class="opinion-box__header-actions">
          <v-btn
            icon
            fab
            :disabled="isEmpty"
            class="opinion-box__header-action-unread"
            @click="opinion.isRead ? markAsUnread() : markAsReaded()"
          >
            <i
              aria-hidden="true"
              class="fi fi-rr-eye-crossed"
              v-if="opinion.isRead"
            ></i>
            <i aria-hidden="true" class="fi fi-rr-eye" v-else></i>
          </v-btn>

          <v-btn
            v-if="!opinion.closed"
            data-test-close-opinion-box
            outlined
            :disabled="isEmpty"
            class="opinion-box__header-action-close"
            @click="closeOpinion()"
          >
            <i aria-hidden="true" class="fi fi-rr-check"></i>
            <span>{{ $t('opinionBox.buttonCloseOpinion.notClosed') }}</span>
          </v-btn>
          <div v-else class="opinion-box__header-action-closed">
            <i aria-hidden="true" class="fi fi-rr-check"></i>
            <span>{{ $t('opinionBox.buttonCloseOpinion.closed') }}</span>
          </div>
        </div>
      </div>

      <div class="opinion-box__tags">
        <v-icon class="opinion-box__tag-icon">mdi-label</v-icon>

        <div
          class="opinion-box__chips"
          data-test-opinions-chips
          v-if="!isEmpty"
        >
          <v-chip
            class="opinion-box__tag-chip state-success"
            v-if="opinion.isPositive"
          >
            <span>{{ $t('opinionBox.labelPositive') }}</span>
          </v-chip>

          <v-chip class="opinion-box__tag-chip state-error" v-else>
            <span>{{ $t('opinionBox.labelConstructive') }}</span>
          </v-chip>

          <template v-for="tag in opinion.tags">
            <v-chip
              :key="tag.id"
              :id="`ob-tag-${tag.id}`"
              class="opinion-box__tag-chip"
              close
              close-icon="mdi-close"
              @click:close="$emit('handleRemoveTag', { tag })"
            >
              <span>{{ tag.label }}</span>
            </v-chip>
          </template>
        </div>

        <OpinionTagMenu
          v-if="_canEngagementOpinionsTags"
          :tags="tags"
          :opinionTags="opinion.tags"
          @handleTagsInfiniteScroll="$emit('handleTagsInfiniteScroll', $event)"
          @handleAddTag="$emit('handleAddTag', $event)"
          @handleRemoveTag="$emit('handleRemoveTag', $event)"
          @handleCreateTag="$emit('handleCreateTag', $event)"
        />
      </div>

      <div class="opinion-box__rating">
        <div
          class="opinion-box__rating-grade"
          :data-test-score="opinion.score"
          :data-test-grade="opinion?.isPositive?.toString()"
        >
          <span
            class="opinion-box__rating-grade-value state-empty"
            v-if="isEmpty"
            >--</span
          >
          <span :class="getScoreClass(opinion.score)" v-else>
            {{ opinion.score }}
          </span>
          <span class="opinion-box__rating-grade-text">
            {{ $t('opinionBox.labelScore') }}
          </span>
        </div>

        <div
          class="opinion-box__rating-questions"
          :data-test-quantitative="opinion.quantitativeQuestion"
          :data-test-qualitative="opinion.qualitativeQuestion"
        >
          <span
            class="opinion-box__rating-question"
            :title="opinion.quantitativeQuestion"
          >
            <span class="opinion-box__rating-questions-title"
              >{{ $t('opinionBox.labelQuantitativeQuestion') }}:</span
            >
            {{ opinion.quantitativeQuestion }}
          </span>

          <span
            class="opinion-box__rating-question"
            :title="opinion.qualitativeQuestion"
          >
            <span class="opinion-box__rating-questions-title"
              >{{ $t('opinionBox.labelQualitativeQuestion') }}:</span
            >
            {{ opinion.qualitativeQuestion }}
          </span>
        </div>
      </div>

      <div class="opinion-box__body">
        <div class="opinion-box__body-content" ref="messages">
          <div
            class="opinion-box__body-chunk"
            v-for="(chunk, chunkIndex) in chunks"
            :key="chunkIndex"
          >
            <div class="opinion-box__body-chunk-date">
              <hr />
              <time :datetime="chunk.date"
                >{{ normalizeDate(chunk.date) }}
              </time>
              <hr />
            </div>

            <template v-for="(message, messageIndex) in chunk.messages">
              <div
                :key="`${messageStyle(message)}-${message.id}`"
                :class="
                  isTheLastMessageFromSameSender(chunkIndex, messageIndex)
                    ? `opinion-box__body-${messageStyle(
                        message
                      )} state-minified`
                    : `opinion-box__body-${messageStyle(message)}`
                "
              >
                <div
                  :class="`opinion-box__body-${messageStyle(message)}-avatar`"
                >
                  <Avatar
                    v-if="message.isAnonymous"
                    :src="
                      require(`@/assets/images/OpinionsConversation/anonymous.svg`)
                    "
                    :displayAttr="{
                      class: 'anonymous-icon',
                      alt: 'anonymous',
                      'max-width': '22',
                      'aspect-ratio': '1',
                      contain: true,
                    }"
                    size="30px"
                  />
                  <Avatar
                    v-else
                    :src="message.author.photo"
                    :text="message.author.name"
                    :color="getAvatarIdToColors[`${message.author.id}`]"
                    :displayAttr="{ alt: 'user' }"
                    size="30px"
                  />
                </div>

                <div
                  :class="`opinion-box__body-${messageStyle(message)}-stack`"
                >
                  <span
                    :class="`opinion-box__body-${messageStyle(message)}-name`"
                  >
                    {{
                      message.isAnonymous
                        ? $t('opinionBox.anonymous')
                        : message.author.name
                    }}
                  </span>
                  <p
                    v-if="message.message !== null"
                    :class="`opinion-box__body-${messageStyle(
                      message
                    )}-message`"
                  >
                    {{ message.message === '' ? ' ' : message.message }}
                  </p>
                  <p v-if="message.rating" class="opinion-box__body-emoji">
                    {{ ratedScore(message.rating) }}
                  </p>
                  <p
                    v-if="message.closed && message.message === null"
                    :class="`opinion-box__body-${messageStyle(
                      message
                    )}-message`"
                  >
                    {{ $t('opinionBox.quickAnswers.closing') }}
                  </p>
                  <v-icon small class="opinion-box__body-status">{{
                    messageStatusIcon(message.messageStatus)
                  }}</v-icon>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="opinion-box__footer">
        <div class="opinion-box__footer-quick-answers">
          <span class="opinion-box__footer-quick-answers-icon">
            <i aria-hidden="true" class="fi fi-rr-time-fast"></i>
          </span>

          <div class="opinion-box__footer-quick-answers-itens">
            <v-chip
              :disabled="isEmpty"
              class="opinion-box__footer-quick-answers-item _text"
              @click="
                sendQuickAnswer($t('opinionBox.quickAnswers.options.itemOne'))
              "
            >
              <span class="opinion-box__footer-quick-answers-item-text">{{
                $t('opinionBox.quickAnswers.options.itemOne')
              }}</span>
            </v-chip>

            <v-chip
              :disabled="isEmpty"
              class="opinion-box__footer-quick-answers-item"
              @click="
                sendQuickAnswer($t('opinionBox.quickAnswers.options.itemTwo'))
              "
            >
              <span class="opinion-box__footer-quick-answers-item-text">{{
                $t('opinionBox.quickAnswers.options.itemTwo')
              }}</span>
            </v-chip>

            <v-chip
              :disabled="isEmpty"
              class="opinion-box__footer-quick-answers-item"
              @click="
                sendQuickAnswer($t('opinionBox.quickAnswers.options.itemThree'))
              "
            >
              <span class="opinion-box__footer-quick-answers-item-text">{{
                $t('opinionBox.quickAnswers.options.itemThree')
              }}</span>
            </v-chip>
          </div>
        </div>

        <div class="opinion-box__footer-textarea-box">
          <textarea
            ref="textbox"
            :disabled="isEmpty"
            class="opinion-box__footer-textarea"
            :placeholder="$t('opinionBox.textarea.placeholder')"
            v-model="newMessage"
            @keydown.enter.prevent="sendMessage()"
          ></textarea>

          <div class="opinion-box__footer-textarea-actions">
            <v-menu
              v-model="isEmojiPickerVisible"
              :close-on-content-click="false"
              z-index="999"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="isEmpty"
                  class="opinion-box__footer-textarea-actions-emoticons"
                  fab
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-emoticon-outline</v-icon>
                </v-btn>
              </template>

              <div class="opinion-box__emoji_picker">
                <VEmojiPicker
                  :showCategories="true"
                  :showSearch="false"
                  @select="selectEmoji"
                />
              </div>
            </v-menu>

            <v-btn
              :disabled="isEmpty"
              class="opinion-box__footer-textarea-actions-send"
              :class="{
                closed: opinion.closed,
              }"
              elevation="0"
              @click="sendMessage()"
            >
              <i aria-hidden="true" class="fi fi-rr-paper-plane"></i>
              <span v-if="!opinion.closed">
                {{ $t('opinionBox.textarea.send') }}
              </span>
              <span v-else>
                {{ $t('opinionBox.textarea.open') }}
              </span>
            </v-btn>
          </div>
        </div>

        <a
          class="opinion-box__footer-guide"
          v-if="modeSidebar"
          :href="$t('opinionBox.feedbackGuide.url')"
        >
          <i aria-hidden="true" class="fi fi-rr-graduation-cap"></i>
          <span>{{ $t('opinionBox.feedbackGuide.label') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import OpinionTagMenu from '@/components/OpinionTagMenu/OpinionTagMenu'
import Avatar from '@/components/Avatar/Avatar.vue'

import { mapGetters } from 'vuex'
import { VEmojiPicker } from 'v-emoji-picker'
import moment from 'moment'
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'OpinionBox',
  components: {
    VEmojiPicker,
    OpinionTagMenu,
    Avatar,
  },
  data() {
    return {
      newMessage: '',
      isEmojiPickerVisible: false,
    }
  },
  methods: {
    closeDialog() {
      this.open = false
      this.$emit('update:isOpen')
    },
    normalizeDate(chunkDate) {
      const locale = this.$t('opinionBox.moment.locale')
      moment.locale(locale)

      moment.defaultFormat = this.$t('opinionBox.moment.dateFormat')

      const today = moment()
      const todayLabel = this.$t('opinionBox.moment.today')
      const yesterdayLabel = this.$t('opinionBox.moment.yesterday')
      const prepositionLabel = this.$t('opinionBox.moment.preposition')

      if (chunkDate) {
        const date = moment(chunkDate, moment.defaultFormat)
        const passed = today.diff(date, 'days')

        switch (passed) {
          case 0:
            return date.format(`[${todayLabel}], DD MMM`)
          case 1:
            return date.format(`[${yesterdayLabel}], DD MMM`)
          default:
            return date.format(
              `DD [${prepositionLabel}] MMMM [${prepositionLabel}] YYYY`
            )
        }
      } else return today.format(`[${todayLabel}], DD MMM`)
    },
    ratedScore(avaliationNote) {
      switch (avaliationNote) {
        case 'very-unhappy':
          return '😠'
        case 'unhappy':
          return '🙁'
        case 'no-comments':
          return '😐'
        case 'good':
          return '😃'
        case 'love-it':
          return '😍'
      }
    },
    messageStatusIcon(status) {
      switch (status) {
        case 'sending':
          return 'mdi-check'
        case 'sent':
          return 'mdi-check-all'
        case 'error':
          return 'mdi-alert-circle-outline'
      }
    },
    messageStyle(message) {
      if (message.owner || !message.mine) return 'incoming'
      else return 'outgoing'
    },
    isTheLastMessageFromSameSender(chunkIndex, messageIndex) {
      const chunk = this.chunks[chunkIndex]
      const message = chunk.messages[messageIndex]

      if (messageIndex === 0) return false

      const previousMessage = chunk.messages[messageIndex - 1]

      return (
        (message.owner === previousMessage.owner && message.owner === true) ||
        message.author.id === previousMessage.author.id
      )
    },
    sendQuickAnswer(message) {
      this.$emit('handleSendMessage', { message })
    },
    selectEmoji(emoji) {
      const textbox = this.$refs.textbox
      const sentence = textbox.value
      const len = sentence.length
      let pos = textbox.selectionStart
      if (pos === undefined) {
        pos = 0
      }
      const before = sentence.substring(0, pos)
      const after = sentence.substring(pos, len) || ''

      this.newMessage = before + emoji.data + after

      pos = pos + emoji.data.length
      this.$nextTick().then(() => {
        textbox.selectionStart = pos
        textbox.selectionEnd = pos
        textbox.focus()
      })
    },
    sendMessage() {
      if (this.newMessage.trim() === '') return

      this.$emit('handleSendMessage', { message: this.newMessage })

      this.newMessage = ''
    },
    markAsReaded() {
      this.$emit('handleMarkAsReaded')
    },
    markAsUnread() {
      this.$emit('handleMarkAsUnread')
    },
    closeOpinion() {
      this.$emit('handleCloseOpinion')
    },
    getScoreClass(score) {
      let scoreClass = 'opinion-box__rating-grade-value '
      const LOW_SCORE_CUT = 4.9
      const HIGH_SCORE_CUT = 7.5

      switch (true) {
        case score <= LOW_SCORE_CUT:
          scoreClass += 'state-negative'
          break
        case score >= HIGH_SCORE_CUT:
          scoreClass += 'state-positive'
          break
        default:
          scoreClass += 'state-neutral'
          break
      }

      return scoreClass
    },
  },
  props: {
    modeSidebar: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Object,
    },
    opinion: {
      type: Object,
      required: true,
    },
    ownerIdentity: {
      type: Object,
      default: () => ({}),
    },
    chunks: {
      type: Array,
      default: () => [],
    },
    chunksLoading: {
      type: Boolean,
      default: () => false,
    },
    height: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    ...mapGetters(['getAvatarIdToColors']),
    isEmpty() {
      return this.opinion.id === '0'
    },
    ownerDisplayName() {
      if (this.ownerIdentity.name && !this.opinion.isAnonymous)
        return this.ownerIdentity.name
      else return this.$t('opinionBox.anonymous')
    },
    nameTooltipAttr() {
      let nudgeLeft = -23 + this.ownerDisplayName.length
      let nudgeTop = 3

      if (this.$vuetify.breakpoint.width <= 330) {
        nudgeLeft = -22 + this.ownerDisplayName.length
      }

      return {
        nudgeLeft,
        nudgeTop,
      }
    },

    _canEngagementOpinionsTags() {
      return this.$can('access', _permissions.engagement_opinions_tags)
    },
  },
  watch: {
    chunks() {
      const colores = this.$vuetify.theme.themes.light.treemapColors

      this.chunks.map((chunk) => {
        chunk.messages.map((message) => {
          if (!message.author.id) return

          const idm = message.author.id
          const idx = Math.floor(Math.random() * colores.length)

          this.$store.commit('setAvatarIdToColors', {
            idx: idm,
            color: colores[idx],
          })
        })
      })
    },
  },
  updated() {
    this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
