import api from '@/service/Http'

export const createResultSharing = async (data) =>
  (await api()).post('/results-sharing', data)

export const getResultSharing = async (data) =>
  (await api()).get(
    `/results-sharing?limit=${data.limit}&offset=${data.offset}&groupID=${data.groupID}&module=${data.module}`
  )

export const updateResultSharing = async (data) =>
  (await api()).put(`/results-sharing/${data.resultsSharingID}`, data.body)

export const deleteResultSharing = async (data) =>
  (await api()).delete(`/results-sharing/${data}`)
