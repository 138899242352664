<template>
  <div
    :class="`d-flex flex-column justify-center align-center
    ${sm ? 'sm' : ''}`"
  >
    <!--Accessibility-->
    <span
      class="accessibility-hide-text"
      tabindex="0"
      v-text="$t('answerRadioEmoticons.accessibility.help1')"
    />

    <!--Video Accessibility-->
    <div v-if="video" class="video-component d-flex justify-center ma-4">
      <VideoComponent class="video" :video="video" :autoplay="true" />
    </div>

    <!--EmoticonsContainer-->
    <div class="d-flex justify-center my-2">
      <!--EmoticonComponent-->
      <div
        data-test-emoticon
        v-for="(item, index) of items"
        :key="index"
        :class="`emoticon-component d-flex flex-column mx-1
        ${!readOnly ? 'clickable' : ''}
        ${isSelected(item.score) ? 'selected' : ''}`"
        @click="setScore(item.score, true)"
      >
        <!--Emoticon-->
        <div
          class="emoticon align-self-center d-flex align-center justify-center"
        >
          <span v-text="item.emoji" />
        </div>
        <!--Label-->
        <div class="d-flex align-center justify-center mt-3">
          <span
            class="emoticon-label"
            v-text="item.text"
            tabindex="0"
            role="button"
            :aria-pressed="isSelected(item.score).toString()"
            @keydown.space.enter="setScore(item.score, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoComponent from '@/components/VideoComponent/VideoComponent'

export default {
  name: 'AnswerRadioEmoticons',
  components: { VideoComponent },
  props: {
    sm: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
    init: {
      type: Number,
    },
    items: {
      // [{ emoticon: '😠', answerID: 'answer1a', score: -2, text: 'Discordo'},...]
      type: Array,
      required: true,
    },
    video: {
      type: String,
    },
  },
  data() {
    return {
      score: this.init || null,
    }
  },
  mounted() {
    this.setScore(this.init)
  },
  methods: {
    setScore(score, next) {
      if (this.readOnly) return
      if (!this.items.some((e) => e.score == score)) return

      this.score = score

      this.$emit('input', this.buildEmit())

      if (next) this.$emit('select', this.buildEmit())
    },
    buildEmit() {
      return {
        value: this.score,
        score: this.score,
        answerID: this.getAnswerID(this.score),
      }
    },
    getAnswerID(s) {
      const item = this.items.find((e) => e.score == s)
      if (!item) return

      return item['answerID']
    },
    isSelected(score) {
      return this.score == score
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
