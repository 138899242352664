import { render, staticRenderFns } from "./QuickSelectionBehaviors.vue?vue&type=template&id=15ab289a&scoped=true&"
import script from "./QuickSelectionBehaviors.vue?vue&type=script&lang=js&"
export * from "./QuickSelectionBehaviors.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=15ab289a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ab289a",
  null
  
)

export default component.exports