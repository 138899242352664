<template>
  <div class="culture-management-wrap">
    <culture-management-header
      :title="$t('cultureManagement.title')"
      :subTitle="$t('cultureManagement.subtitle')"
      prependTeamCultureIcon
      backArrowIcon
      howToBeginAndWatchAVideo
      howToBeginAndWatchAVideoKey="culture.management"
      @close="closeCultureManagementModalAndRoute()"
    ></culture-management-header>

    <v-container fluid v-if="!isEmptyPage" class="wrap">
      <section class="page-container">
        <div class="header">
          <div class="group-select">
            <ItemSelector
              ref="itemselector"
              class="page-selects-group"
              :inputConfig="{
                label: $t('engagementPage.pageCombobox'),
                clearable: true,
              }"
              :menuConfig="{
                showTabs: false,
              }"
              :menuOptions="[
                {
                  type: 'treeview',
                  value: 'groups',
                  label: $t('engagementPage.pageCombobox'),
                  items: groups,
                },
              ]"
              :highlightQuantity="8"
              :localSearch="true"
              @update:item="handleGroupChoice($event)"
            />
          </div>

          <div class="action">
            <v-btn
              v-if="canCreateCulture"
              depressed
              class="button-create-culture"
              data-test-new-culture
              @click="addCultureCode()"
            >
              <v-icon size="11">fi-rr-plus</v-icon>
              <span v-text="$t('cultureManagement.addNewCulture')" />
            </v-btn>
          </div>
        </div>

        <div class="search-container">
          <v-text-field
            data-test-search-input
            v-model="search"
            class="search-input"
            hide-details
            :label="$t('cultureManagement.input')"
          >
            <v-icon size="14" slot="prepend">fi-rr-search</v-icon>
          </v-text-field>
        </div>

        <div class="culture-code-card-list">
          <div class="content" data-test-culture-code-card-list>
            <template v-if="isLoading">
              <div v-for="i in 6" :key="i" class="card-content">
                <v-skeleton-loader
                  type="card-avatar,actions"
                ></v-skeleton-loader>
              </div>
            </template>

            <template v-else>
              <v-lazy
                v-for="culture in _dataCultures"
                :key="culture.id"
                min-height="290px"
              >
                <CultureCodeCard
                  :isActive="culture.isActive"
                  :culture="culture"
                  @clicked="editCultureCode(culture)"
                />
              </v-lazy>
            </template>
          </div>
        </div>
      </section>

      <CardCultureManagement class="aside" />
    </v-container>

    <v-container fluid v-else class="wrap empty-page">
      <section class="page-container empty-information">
        <embed
          :src="$t('engagementPage.opinions.cards.emptyOpinionsTip.video')"
          width="60%"
          height="400"
        />

        <div class="empty-content">
          <div class="empty-title">
            <h3>
              {{ $t('cultureManagement.emptyStage.title') }}
            </h3>
          </div>

          <div class="empty-link">
            <a href="#">{{ $t('cultureManagement.emptyStage.link') }}</a>
          </div>
        </div>

        <v-btn
          v-if="canCreateCulture"
          depressed
          class="button-create-culture"
          data-test-new-culture
          @click="addCultureCode()"
        >
          <v-icon size="11">fi-rr-plus</v-icon>
          <span v-text="$t('cultureManagement.addNewCulture')" />
        </v-btn>
      </section>

      <CardCultureManagement class="aside empty-aside" />
    </v-container>

    <culture-code
      v-if="showAddCultureModal"
      :show="showAddCultureModal"
      :cultureCodeSelected="cultureCodeSelected"
      @close="closeCultureModal()"
      @create-culture-code="createCultureCode($event)"
      @refresh-culture-code="refreshCultureCode($event)"
      @delete-culture-code="removeCultureCode($event)"
    />

    <v-card v-intersect="infiniteScrolling"></v-card>
  </div>
</template>

<script>
import CultureCode from '@/pages/EngagementPage/Tabs/Culture/Partials/CultureCode/CultureCode.vue'
import CardCultureManagement from '@/pages/EngagementPage/Tabs/Culture/Partials/AsideFixedCard/AsideFixedCard.vue'
import CultureCodeCard from '@/pages/EngagementPage/Tabs/Culture/Partials/CultureCodeCard/CultureCodeCard.vue'
import CultureManagementHeader from '@/pages/EngagementPage/Tabs/Culture/Partials/CultureManagementHeader/Header'
import {
  getCultureCode,
  getCultureCodes,
  getCultureBehaviors,
  deleteCultureCode,
  getCultureGroups,
} from '@/service/culture'

import { mapMutations } from 'vuex'

import toggleCultureManagementMixin from '@/mixins/toggleCultureManagementModal'
import CultureCodeGroups from '@/mixins/CultureCodeGroups'
import { _permissions } from '@/helpers/ability/engagement'

import ItemSelector from '@/components/ItemSelector/ItemSelector.vue'

export default {
  provide() {
    const cultureCodeSelected = {}
    const providedBehaviors = {}

    Object.defineProperty(cultureCodeSelected, 'get', {
      enumerable: true,
      get: () => this.cultureCodeSelected,
    })

    Object.defineProperty(providedBehaviors, 'get', {
      enumerable: true,
      get: () => this.behaviors,
    })

    return {
      cultureCodeSelected,
      providedBehaviors,
    }
  },
  mixins: [toggleCultureManagementMixin, CultureCodeGroups],
  components: {
    CultureCode,
    CultureManagementHeader,
    CardCultureManagement,
    CultureCodeCard,
    ItemSelector,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openedPanel: null,
      cultureCodeSelected: { id: '', name: '', values: [], groups: [] },
      defaultCultureCodeSelected: { id: '', name: '', values: [], groups: [] },
      dataCultures: [],
      dataCulturesActive: [],
      selectedsGroupsCulture: [],
      groups: [],
      behaviors: [],
      search: null,
      isCardActive: false,
      valueSelect: null,
      isEmptyPage: false,
      isLoading: false,
      isScrollable: true,
      showAddCultureModal: false,
      fetchParams: {
        pageCount: 1,
        limit: 20,
        offset: 0,
      },
      defaultFetchParams: {
        pageCount: 1,
        limit: 20,
        offset: 0,
      },
      interval: null,
      mounted: false,
    }
  },
  computed: {
    videoPath() {
      return 'howToBeginAndWatchAVideo.videos.cultureManagement.url'
    },
    _dataCultures() {
      return this.handleDataCultures(this.dataCultures, this.dataCulturesActive)
    },

    canCreateCulture() {
      return this.$can('access', _permissions.engagement_culture_creator)
    },
  },
  methods: {
    ...mapMutations(['setGroupId']),
    handleDataCultures(lastItensData, firstItensData) {
      const dontRepeat = lastItensData.filter(
        (outsideItens) =>
          !firstItensData
            .map((insideItens) => insideItens.id)
            .includes(outsideItens.id)
      )

      return firstItensData.concat(dontRepeat)
    },

    handleDialog() {
      this.dialogCardAddCultureValue = true
    },
    handleGroupChoice(choice) {
      this.valueSelect = choice?.id
      this.setGroupId(choice?.id)
      this.$emit('input', choice?.id)

      this.verifyIdGroupCard()
    },
    handleOpenExpansionPanel(index) {
      this.openedPanel = index
    },
    resetFetchParams() {
      Object.assign(this.fetchParams, this.defaultFetchParams)
      this.isScrollable = true
    },
    async filteredCards() {
      this.resetFetchParams()
      if (this.search) {
        try {
          clearTimeout(this.interval)
          this.interval = setTimeout(async () => {
            await this.fetchedDataManager(false)
          }, 500)
        } catch (error) {
          clearTimeout(this.interval)
          console.log(error)
        }
      }
    },
    async fetchData(limit, offset, search) {
      try {
        const { data } = await getCultureCodes(limit, offset, search || null)
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async fetchBehaviors(limit, offset, search) {
      try {
        const { data } = await getCultureBehaviors(
          limit,
          offset,
          search || null
        )

        this.getImgBehaviors(data)
        return data
      } catch (error) {
        console.log(error)
      }
    },
    getImgBehaviors(behaviors) {
      behaviors.map((behavior) => {
        behavior.image = `https://static.teamculture.com.br/images/culture/${behavior.id}.svg`
        return behavior
      })
    },
    closeCultureManagementModalAndRoute() {
      this.toggleCultureManagementModal(false)
      this.$router.push({ name: 'CulturePage' })
    },
    async addCultureCode() {
      Object.assign(this.cultureCodeSelected, this.defaultCultureCodeSelected)
      await this.$router.push({
        name: 'AddCultureCode',
      })
      this.toggleAddCultureModal(true)
    },
    async editCultureCode(cultureCode) {
      this.cultureCodeSelected = { ...cultureCode }
      await this.$router.push({
        name: 'EditCultureCode',
        params: { cultureId: cultureCode.id },
      })
      this.toggleAddCultureModal(true)
    },
    async deleteCultureCode(id) {
      await deleteCultureCode(id)
      this.resetFetchParams()
      this.isScrollable = false
      this.showAddCultureModal = false
      this.snackbarCultureCodeDeleted = true
      await this.fetchedDataManager(false)
    },
    toggleAddCultureModal(show) {
      this.showAddCultureModal = show
    },
    verifyEmptyPage(data) {
      if (data.length === 0 || data === '') {
        this.isEmptyPage = true
        return
      }

      this.isEmptyPage = false
    },
    verifyIdGroupCard() {
      const actives = this.dataCultures.filter(({ groups }) => {
        return groups.some(({ id }) => id === this.valueSelect)
      })

      this.dataCulturesActive = actives.map((item) => ({
        ...item,
        isActive: true,
      }))
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (!this.isScrollable) return ''

      if (!this.isLoading && this.mounted) {
        clearTimeout(this.interval)
        this.interval = setTimeout(async () => {
          this.fetchParams.offset =
            this.fetchParams.pageCount * this.fetchParams.limit
          this.fetchedDataManager(true)
          this.fetchParams.pageCount++
        }, 500)
      }
    },

    async fetchedDataManager(push = false) {
      this.isLoading = true
      const data = await this.fetchData(
        this.fetchParams.limit,
        this.fetchParams.offset,
        this.search
      )

      if (data.length < this.fetchParams.limit || data.length === 0) {
        this.isScrollable = false
      }

      /**
      data.forEach((culture) => {
        culture.values.forEach((value) => {
          value.behaviors = this.behaviors.filter((behavior) => {
            return value.behaviors.some((element) => element.id === behavior.id)
          })
        })
      })
      */

      if (push) {
        this.dataCultures.push(...data)
      } else {
        this.dataCultures = data
      }

      if (!this.mounted) {
        this.verifyEmptyPage(this.dataCultures)
      }
      this.verifyIdGroupCard()
      this.isLoading = false
    },

    createCultureCode(event) {
      this.dataCultures.push(event)
    },

    refreshCultureCode(event) {
      const index = this.dataCultures.findIndex((e) => e.id === event.id)

      this.dataCultures[index] = event
      this.cultureCodeSelected = event
    },

    removeCultureCode(event) {
      const index = this.dataCultures.findIndex((e) => e.id === event)

      this.dataCultures.splice(index, 1)
    },

    closeCultureModal() {
      Object.assign(this.cultureCodeSelected, this.defaultCultureCodeSelected)
      this.resetFetchParams()
      this.showAddCultureModal = false

      this.$router.push({ name: 'cultureManagement' })
    },
  },
  watch: {
    search(value) {
      if (!value) {
        this.fetchedDataManager(false)
      }
      this.filteredCards()
    },
  },
  async mounted() {
    try {
      await getCultureGroups().then((res) => {
        this.groups = res.data
      })

      this.fetchedDataManager()
      const behaviors = await this.fetchBehaviors(this.fetchParams.limit, 0, '')
      this.behaviors = behaviors
    } catch (error) {
      console.log(error)
    } finally {
      this.mounted = true
    }
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
