<template>
  <div
    class="PillarAndFactor"
    :class="{ 'no-image': image === undefined, 'small-pillar': small }"
  >
    <div v-if="loading" class="PillarAndFactor-container loading">
      <v-skeleton-loader
        type="avatar"
        class="PillarAndFactor-image"
      ></v-skeleton-loader>

      <div class="PillarAndFactor-info-container">
        <div class="PillarAndFactor-info">
          <div class="PillarAndFactor-info-header">
            <div class="PillarAndFactor-info-header-title">
              <v-skeleton-loader type="list-item"></v-skeleton-loader>
            </div>

            <div class="PillarAndFactor-info-header-variation">
              <Variation :loading="true"></Variation>
            </div>
          </div>

          <div class="PillarAndFactor-info-footer">
            <v-skeleton-loader type="list-item"></v-skeleton-loader>
          </div>
        </div>

        <div class="PillarAndFactor-grade">
          <v-skeleton-loader type="button"></v-skeleton-loader>
        </div>
      </div>
    </div>

    <div v-else class="PillarAndFactor-container" :class="{ locked: locked }">
      <v-img
        v-if="image !== undefined"
        class="PillarAndFactor-image"
        width="80"
        height="80"
        contain
        :src="image"
        :alt="title"
      ></v-img>

      <div class="PillarAndFactor-info-container">
        <div class="PillarAndFactor-info">
          <div class="PillarAndFactor-info-header">
            <div class="PillarAndFactor-info-header-title">
              <span v-text="title" />
            </div>

            <div class="PillarAndFactor-info-header-variation">
              <Variation
                v-if="variation !== undefined"
                data-test-variation-pillar
                :variation="!locked ? variation : null"
                :loading="variationLoading"
                :short="small"
              ></Variation>
            </div>
          </div>

          <div
            data-test-pillar-and-factor-progressbar
            class="PillarAndFactor-info-footer"
          >
            <ProgressBar :score="!locked ? score : null"></ProgressBar>

            <div v-if="locked" class="PillarAndFactor-overlay"></div>
          </div>
        </div>

        <div class="PillarAndFactor-grade">
          <span
            v-if="!locked && ![null, undefined].includes(grade)"
            :data-test-pillar-and-factor-grade="grade"
            v-text="grade"
          ></span>

          <span class="PillarAndFactor-grade-container no-grade" v-else>
            <span data-test-pillar-and-factor-grade v-text="'--'"></span>

            <div
              v-if="locked"
              class="PillarAndFactor-overlay with-lock"
              data-test-pillar-and-factor-lock-icon
              @click.prevent="handleDialogLock()"
            >
              <v-icon size="1rem" class="fi fi-sr-lock"></v-icon>
            </div>
          </span>
        </div>
      </div>
    </div>

    <div v-if="!loading && text" class="PillarAndFactor-description">
      <span v-html="text" />
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import Variation from '@/components/Variation/Variation.vue'

import { mapMutations } from 'vuex'

export default {
  name: 'PillarAndFactor',
  components: {
    Variation,
    ProgressBar,
  },
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },

    text: {
      type: String,
    },
    grade: {
      type: Number,
    },
    score: {
      type: Number,
    },

    variation: {
      type: Number,
    },

    locked: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
    variationLoading: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapMutations(['openUpdateYourPackageDialog']),

    handleDialogLock() {
      this.openUpdateYourPackageDialog('segmentation')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
