<template>
  <v-navigation-drawer
    data-test-nav-bar
    v-model="drawerModel"
    class="main-sidebar"
    :class="{ 'drawer-index': _isMobileBreakpoint }"
    app
    left
    floating
    width="325"
    mini-variant-width="85"
    mobile-breakpoint="sm"
    disable-resize-watcher
    :mini-variant="isMiniVariant"
    v-bind="_NavigationDrawerProps"
  >
    <header class="logo">
      <router-link to="/" class="main-menu--item">
        <v-img
          v-show="isMiniVariant"
          eager
          contain
          transition="fade-transition"
          width="3.75rem"
          height="2.5rem"
          alt="Team Culture"
          :src="`${CDN_BASE_URL}images/navigation/logo-b.svg`"
        ></v-img>

        <v-img
          v-show="!isMiniVariant"
          eager
          contain
          class="logo-large"
          transition="fade-transition"
          height="1.875rem"
          alt="Team Culture"
          :src="`${CDN_BASE_URL}images/navigation/logo.svg`"
        ></v-img>
      </router-link>
    </header>

    <nav class="main-menu">
      <template v-for="(menu, index) in menuLinks">
        <v-btn
          :key="index"
          icon
          tile
          elevation="0"
          class="main-menu--item main-menu--item-icon"
          :class="{ mini: isMiniVariant }"
          :href="menu.url"
          :style="{ color: menu.image.color }"
          v-on="menu?.customEvents"
        >
          <v-img
            max-width="3rem"
            width="3rem"
            height="3rem"
            contain
            :title="menu.image.title"
            :alt="menu.image.title"
            :src="menu.image.src"
          ></v-img>

          <span
            v-if="!isMiniVariant && menu.image?.title"
            class="main-menu--text"
            :class="{ 'main-menu--text-bold': menu.image.current }"
            :style="{ color: menu.image.color }"
            v-text="menu.image.title"
          ></span>
        </v-btn>
      </template>
    </nav>

    <v-spacer></v-spacer>

    <footer
      :class="{ 'mini-footer': isMiniVariant, 'main-footer': !isMiniVariant }"
    >
      <slot name="footer" :isMiniVariant="isMiniVariant"></slot>

      <div class="option-user" v-if="user">
        <v-menu
          offset-x
          min-width="19.25rem"
          nudgeRight="5"
          z-index="100"
          transition="slide-x-transition"
          content-class="elevation-neutral border-neutral"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              tile
              plain
              class="btn-user"
              :class="{ 'btn-user-text': !isMiniVariant }"
              v-bind="attrs"
              v-on="on"
            >
              <AvatarComponent
                :id="user.id"
                :src="user.photo"
                :text="user.name"
                :size="'2.5rem'"
                :textSize="'0.75rem'"
              ></AvatarComponent>

              <div v-if="!isMiniVariant" class="btn-user-text">
                <div class="limit-Width-Truncated">
                  <TruncatedArea :refText="refSpanUserName" tooltipOnHover>
                    <template #outside="{ setHover }">
                      <div
                        ref="spanUserName"
                        class="truncated-text text-on-hover btn-user-text-name"
                        @mouseover="setHover(true)"
                        @mouseleave="setHover(false)"
                        v-html="user.name"
                      ></div>
                    </template>
                    <template #default>
                      <div
                        data-test-tooltip-html-truncated-tooltip
                        v-html="user.name"
                      />
                    </template>
                  </TruncatedArea>
                </div>

                <div class="limit-Width-Truncated">
                  <TruncatedArea :refText="refSpanUserEmail" tooltipOnHover>
                    <template #outside="{ setHover }">
                      <div
                        ref="spanUserEmail"
                        class="truncated-text text-on-hover btn-user-text-email"
                        @mouseover="setHover(true)"
                        @mouseleave="setHover(false)"
                        v-html="user.email"
                      ></div>
                    </template>
                    <template #default>
                      <div
                        data-test-tooltip-html-truncated-tooltip
                        v-html="user.email"
                      />
                    </template>
                  </TruncatedArea>
                </div>
              </div>
            </v-btn>
          </template>

          <div class="option-user-menu">
            <v-btn
              text
              tile
              elevation="0"
              data-test-menu-user="'settings'"
              class="option-user-menu-item"
              :href="userLinks.settings.url"
            >
              <v-icon>mdi-cog</v-icon>
              <span v-text="userLinks.settings.text"></span>
            </v-btn>

            <v-btn
              text
              tile
              elevation="0"
              data-test-menu-user="'help'"
              class="option-user-menu-item"
              target="_blank"
              :href="userLinks.help.url"
            >
              <v-icon>fi-rr-interrogation</v-icon>
              <span v-text="userLinks.help.text"></span>
            </v-btn>

            <v-divider></v-divider>

            <v-btn
              text
              tile
              elevation="0"
              data-test-menu-user="'logout'"
              class="option-user-menu-item"
              @click="userLinks.logout.click"
            >
              <v-icon>mdi-logout</v-icon>
              <span v-text="userLinks.logout.text"></span>
            </v-btn>
          </div>
        </v-menu>
      </div>
    </footer>

    <v-btn
      data-test-toggle-min-lateral-menu
      icon
      class="btn-toggle-mini"
      @click="toggleMiniVariant"
    >
      <v-icon size="0.9375rem">{{ _iconToggleBtn }}</v-icon>
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
import AvatarComponent from '@/components/AvatarComponent/AvatarComponent.vue'
import TruncatedArea from '@/components/TruncatedArea/TruncatedArea.vue'

export default {
  name: 'NavBar',

  components: {
    AvatarComponent,
    TruncatedArea,
  },

  props: {
    menuLinks: {
      type: Array,
      default: () => [],
    },

    user: {
      type: Object,
      required: true,
    },

    userLinks: {
      type: Object,
      default: () => ({
        logout: {
          click: () => {},
          text: '',
        },
        help: {
          url: '',
          text: '',
        },
        settings: {
          url: '',
          text: '',
        },
      }),
    },
  },

  data() {
    return {
      drawerModel: false,
      isMiniVariant: true,

      refSpanUserEmail: null,
      refSpanUserName: null,
    }
  },

  computed: {
    _isMobileBreakpoint() {
      return this.$vuetify.breakpoint.smAndDown
    },
    _iconToggleBtn() {
      if (this.isMiniVariant) {
        return 'fi-rr-caret-right'
      }

      return 'fi-rr-caret-left'
    },

    _NavigationDrawerProps() {
      return this.calcDrawerProps(this._isMobileBreakpoint)
    },
  },

  methods: {
    setRefSpanUserEmail() {
      this.refSpanUserEmail = this.$refs.spanUserEmail
    },
    setRefSpanUserName() {
      this.refSpanUserName = this.$refs.spanUserName
    },

    toggleMiniVariant() {
      this.isMiniVariant = !this.isMiniVariant

      this.$root.$emit('lateral-menu-retract', !this.isMiniVariant)
    },

    listenerToggleLateralMenuEvent() {
      this.drawerModel = !this.drawerModel
    },

    handleDefaultDrawer(isMobileBreakpoint = this._isMobileBreakpoint) {
      if (!isMobileBreakpoint) {
        this.drawerModel = true
      }
    },

    calcDrawerProps(isMobileBreakpoint = this._isMobileBreakpoint) {
      if (!isMobileBreakpoint) {
        return {
          permanent: true,
          temporary: false,
        }
      }

      return {
        permanent: false,
        temporary: true,
      }
    },

    registerListenerToggleLateralMenuEvent() {
      this.$root.$on(
        'app:toggle:lateral-menu',
        this.listenerToggleLateralMenuEvent
      )
    },

    unregisterListenerToggleLateralMenuEvent() {
      this.$root.$off(
        'app:toggle:lateral-menu',
        this.listenerToggleLateralMenuEvent
      )
    },
  },

  watch: {
    _isMobileBreakpoint: {
      handler() {
        this.$root.$emit('app:drawner:lateral-menu')
      },
      immediate: true,
    },
  },

  created() {
    this.handleDefaultDrawer()
    this.registerListenerToggleLateralMenuEvent()
  },
  beforeDestroy() {
    this.unregisterListenerToggleLateralMenuEvent()
  },

  mounted() {
    this.setRefSpanUserEmail()
    this.setRefSpanUserName()
  },

  updated() {
    this.setRefSpanUserEmail()
    this.setRefSpanUserName()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
