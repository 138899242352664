import { getGroupsTree } from '@/service/groups'
const mixin = {
  methods: {
    async getGroups() {
      let groups = []

      await getGroupsTree()
        .then((groupData) => {
          groups = groupData.data
        })
        .catch((error) => {
          console.log(error)
        })

      return groups
    },
  },
}

export default mixin
