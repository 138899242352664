<template>
  <div class="d-flex align-center my-2">
    <v-avatar class="pa-2" size="22px" :color="selected.color">
      <v-icon :size="selected.size || 12" color="#FFF">{{
        selected.icon
      }}</v-icon>
    </v-avatar>
    <span class="phrase ms-2">{{ question }}</span>
    <v-btn
      class="mx-1"
      icon
      x-small
      @click="toggleDialog"
      data-test-question-details
    >
      <v-icon x-small color="neutral5">fi-rr-interrogation</v-icon>
    </v-btn>
    <!--modal-->
    <v-dialog v-model="dialog" width="600px">
      <v-card
        class="mx-auto align-center"
        elevation="1"
        data-test-modal-question
      >
        <div class="d-flex flex-column">
          <div class="d-flex align-center ma-2">
            <span
              class="title my-1 mx-3"
              v-text="$t('questions.question')"
              data-test-question-details-title
            />
            <v-spacer />
            <v-btn class="emoji" icon @click="dialog = false">
              <v-icon>fi-rr-cross-small</v-icon>
            </v-btn>
          </div>
          <v-divider class="dv-1" />
          <div class="d-flex justify-center mx-4 mt-4">
            <span class="subtitle">{{ question }}</span>
          </div>
          <div class="d-flex flex-column justify-center overflow-hidden pa-4">
            <keep-alive>
              <component
                v-bind:is="selected.component"
                :sm="true"
                :read-only="true"
                :items="selected.items || items"
              />
            </keep-alive>
          </div>
          <div class="d-flex justify-start align-center text-center">
            <span
              class="header-title ms-2"
              v-text="$t('questions.reliabilityRate')"
            />
            <span class="header-title ms-2" v-text="$t('questions.result')" />
          </div>
          <v-divider class="my-2 dv-2" />
          <div class="d-flex mx-2 align-center" data-test-question-reliability>
            <ReliabilityRate
              data-test-reliability-rate
              :score="score"
              :show-label="false"
              class="rating justify-center text-center"
            />
            <div
              class="d-flex justify-center mx-4 full-width"
              data-test-question-progress
            >
              <QuestionProgressBar :frequencies="frequencies" />
            </div>
          </div>
          <v-divider class="my-2 dv-2" />
          <div class="d-flex flex-column mx-4 mb-5" data-test-question-positive>
            <span
              class="question-direction green--text my-2"
              v-text="$t('questions.positive')"
            />
            <span
              class="mx-4 dialog-text"
              v-text="positiveQuestion"
              data-test-positive-question
            />
            <span
              class="question-direction red--text my-2"
              v-text="$t('questions.negative')"
            />
            <span
              class="mx-4 dialog-text"
              v-text="negativeQuestion"
              data-test-negative-question
            />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QuestionProgressBar from '@/components/QuestionProgressBar/QuestionProgressBar'
import ReliabilityRate from '@/components/ReliabilityRate/ReliabilityRate'
import AnswerRadioEmoticons from '@/components/AnswerRadioEmoticons/AnswerRadioEmoticons'
import AnswerStars from '@/components/AnswerStars/AnswerStars'
import AnswerRadioCards from '@/components/AnswerRadioCards/AnswerRadioCards'
import AnswerSlider from '@/components/AnswerSlider/AnswerSlider'
import AnswerSliderCard from '@/components/AnswerSliderCard/AnswerSliderCard'

import { getQuestions } from '@/service/climate'

export default {
  name: 'Question',
  components: {
    ReliabilityRate,
    QuestionProgressBar,
    AnswerRadioEmoticons,
    AnswerRadioCards,
    AnswerStars,
    AnswerSlider,
    AnswerSliderCard,
  },
  props: {
    questionType: {
      // types: ['radio-card', 'radio-string', 'star-rating', 'percent', 'slide-number']
      type: String,
      required: true,
    },
    questionID: {
      type: String,
      default: null,
    },
    question: {
      type: String,
      required: true,
      validator: (e) => e.length,
    },
    score: {
      type: Number,
      required: true,
    },
    frequencies: {
      type: Array,
      required: true,
    },
    positiveQuestion: {
      type: String,
      required: true,
    },
    negativeQuestion: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      types: {
        'radio-card': {
          color: 'pink',
          icon: 'fi-sr-apps',
          component: 'AnswerRadioCards',
        },
        'radio-string': {
          color: 'purple',
          icon: '$threeDotsOvalRounded',
          component: 'AnswerRadioEmoticons',
          size: '16',
        },
        'star-rating': {
          color: 'yellow darken-2',
          icon: 'fi-sr-star',
          component: 'AnswerStars',
        },
        percent: {
          color: 'blue',
          icon: '$sliderHand',
          component: 'AnswerSliderCard',
          size: '16',
        },
        'slide-number': {
          color: 'deep-purple',
          icon: '$sliderHand',
          component: 'AnswerSlider',
          size: '16',
        },
      },
      selected: {},
      answerData: {},
    }
  },
  methods: {
    async toggleDialog() {
      if (
        this.questionType === 'radio-card' ||
        this.questionType === 'percent'
      ) {
        await getQuestions(this.questionID).then((res) => {
          this.selected.items = res.data.find(
            (item) => item.type === this.questionType
          ).answers
        })
      }
      this.dialog = !this.dialog
    },
  },

  mounted() {
    this.selected = this.types[this.questionType]
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
