<template>
  <v-row justify="center" class="dialog-wrap">
    <v-dialog
      v-model="isShow"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <div v-if="header" data-test-full-screen-modal-header>
        <slot name="header"></slot>
      </div>
      <v-card class="full-screen-modal-content">
        <slot></slot>
      </v-card>
      <v-footer class="footer" v-if="footer" data-test-full-screen-modal-footer>
        <slot name="footer"></slot>
      </v-footer>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'FullScreenModal',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
