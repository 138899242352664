<template>
  <div>
    <v-container class="actions-container">
      <v-row>
        <v-col :class="{ 'px-0': isMini }" align-self="end">
          <div :class="isMini ? 'header-container-mini' : 'header-container'">
            <div class="title-container">
              <h2
                :class="[
                  'title',
                  'd-flex',
                  isMini ? 'flex-column mini-title' : 'align-start',
                ]"
              >
                <slot name="title-icon" :size="isMini ? 22 : 18"></slot>
                {{ title }}
              </h2>
              <HowToBeginAndWatchAVideo
                v-if="howToType && !isMini"
                :videoType="howToType"
                short
                class="how-to-icons"
              />
              <i v-if="showCrown" class="fi-sr-crown crown"></i>
            </div>
            <div v-if="!isMini" class="actions">
              <slot name="header-action"></slot>
              <v-btn
                class="pa-0 ma-0"
                data-test-toggle-visibility
                icon
                small
                @click="toggleVisibility"
              >
                <v-icon
                  class="toggle-icon"
                  ref="visibilityIcon"
                  data-test-toggle-visibility-icon
                  size="24"
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="!isMini" class="line" />
    <v-container
      v-if="!isMini"
      ref="itemContent"
      data-test-menu-item-content
      class="item-content px-3 py-0"
    >
      <slot />
    </v-container>
  </div>
</template>
<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

export default {
  name: 'LateralMenuItem',
  props: {
    isMini: Boolean,
    howToType: String,
    title: {
      type: String,
      required: true,
    },
    showCrown: Boolean,
  },
  components: { HowToBeginAndWatchAVideo },
  methods: {
    toggleVisibility() {
      const contentEl = this.$refs.itemContent
      const visibilityIconEl = this.$refs.visibilityIcon.$el

      contentEl.classList.toggle('item-content-hide')
      visibilityIconEl.classList.toggle('visibility-off-icon')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
