<template>
  <v-card
    data-test-recommendationCard
    :id="cardTag"
    class="engagement-card d-flex flex-column"
    elevation="1"
    max-width="250"
    max-height="310"
  >
    <v-img class="dark--text align-end" height="160px" :src="cardImage">
      <v-card-title class="engagement-card-tag">{{ cardTag }}</v-card-title>
    </v-img>

    <v-card-subtitle class="engagement-card-title">
      {{ cardTitle }}
    </v-card-subtitle>

    <v-card-text class="pb-0">
      <div class="engagement-card-description">{{ cardDescription }}</div>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions class="align-bottom">
      <v-btn
        class="engagement-card-action action__details"
        small
        text
        @click="toggleShowDetails(true, cardId)"
      >
        {{ $t('engagementRecommendations.card.moreDetails') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="canNewPlan"
        class="engagement-card-action action__plan"
        small
        text
        @click="callCreatePlan()"
      >
        {{ $t('engagementRecommendations.card.action') }}
      </v-btn>
    </v-card-actions>
    <div
      data-test-recommendationCardBlocked
      class="blocked"
      v-if="canISee"
      @click="callUpdateYourPackage('recommendation')"
    >
      <div class="glass"></div>
      <div class="blocked-icon">
        <i class="fi fi-sr-lock"></i>
      </div>
    </div>
  </v-card>
</template>

<script>
import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'EngagementRecommendationsCard',
  inject: ['toggleShowDetails', 'actionPlan', 'callUpdateYourPackage'],
  props: {
    cardId: {
      type: String,
      required: true,
    },
    cardTag: {
      type: String,
      required: true,
    },
    cardImage: {
      type: String,
      required: true,
    },
    cardTitle: {
      type: String,
      required: true,
    },
    cardDescription: {
      type: String,
      required: true,
    },
    canNewPlan: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    canISee() {
      return !this.$can('access', _permissions.academy_recommendations)
    },
  },
  methods: {
    callCreatePlan() {
      const payload = {
        title: this.cardTitle,
        description: this.cardDescription,
      }

      this.actionPlan.new = payload
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
