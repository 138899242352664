<template>
  <v-card data-test-page-card max-width="100%" class="engagement-wrapper">
    <div class="engagement-wrapper-header">
      <div class="engagement-wrapper-header-container">
        <v-card-title class="engagement-wrapper__title">
          <div v-if="!_canISee" class="crown">
            <i class="fi fi-sr-crown"></i>
          </div>
          <p>
            {{ title }}
          </p>
        </v-card-title>
        <div class="how-to-container">
          <HowToBeginAndWatchAVideo :videoType="videoType" />
        </div>
        <div class="ml-auto slot-append">
          <slot name="append"> </slot>
        </div>
      </div>
      <v-card-subtitle class="engagement-wrapper__subtitle">{{
        subtitle
      }}</v-card-subtitle>
    </div>
    <slot />
  </v-card>
</template>

<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

export default {
  name: 'PageCard',
  components: {
    HowToBeginAndWatchAVideo,
  },
  props: {
    videoType: String,
    title: String,
    subtitle: String,
    permission: String,
  },
  computed: {
    _canISee() {
      return this.$can('access', this.permission)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
