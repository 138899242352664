<template>
  <div id="listview">
    <v-list
      v-if="filteredItems.length > 0"
      class="listview-container"
      data-test-list-view-filtered-items
      dense
    >
      <template v-for="(item, index) in filteredItems">
        <v-list-item
          data-test-list-view-list-item
          :key="index"
          v-ripple="false"
          @click="submit(item)"
        >
          <ListOfItems
            v-if="itensComponent.name === 'ListOfItems'"
            :items="[item]"
            :hasHover="true"
            :hasChecked="false"
            :checked="actives.includes(item) ? 0 : undefined"
            :hasRemove="!persistent && actives.includes(item)"
            :secondLineFields="['group', 'class', 'cycle']"
          />
          <node-item
            v-else
            :node="item"
            :actives="actives"
            :showExpandIcon="false"
            :showAvatar="showAvatar"
            :showIcon="showIcon"
            :hideElements="hideElements"
            :showRemoveIcon="!persistent"
          />
        </v-list-item>
      </template>
    </v-list>

    <div v-else class="empty" data-test-list-view-empty>
      <span class="content-label">{{ $t('itemSelector.noResults') }}</span>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import NodeItem from '../NodeItem/NodeItem.vue'
import ListOfItems from '@/components/ListOfItems/ListOfItems.vue'

import {
  BreakAsThreadPromises,
  SearchWithAbortController,
  BigSearchToUniqueItems,
} from '@/helpers/search'

export default {
  components: { NodeItem, ListOfItems },

  data() {
    return {
      filteredItems: [],
      actives: [],

      historySearchControllers: {},
    }
  },

  props: {
    itensComponent: {
      type: Object,
      default: () => ({
        name: 'node-item',
      }),
    },

    selectedItem: null,

    items: {
      type: Array,
    },

    tab: {
      type: String,
      default: '',
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    search: {
      type: String,
      default: '',
    },

    submitMode: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    hideElements: {
      type: Array,
      default: () => [],
    },

    showAvatar: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    items() {
      this.handleItems()
    },

    hideElements() {
      this.handleItems()
    },

    selectedItem: {
      handler(v) {
        this.resetCurrentActive(v)
      },
      immediate: true,
      deep: true,
    },

    search(v) {
      this.searchItems(v)
    },
  },

  beforeMount() {
    this.handleItems()

    if (this.selectedItem) {
      this.resetCurrentActive(this.selectedItem)
    }

    if (this.selectedItem) {
      this.actives = this.selectedItem.data
    }
  },

  methods: {
    submit(data) {
      const indexOfDataOnActives = this.actives.findIndex(
        (e) => e.id === data.id
      )
      const isDataNotOnActives = indexOfDataOnActives < 0

      if (!isDataNotOnActives) {
        this.removeItemFromActives(indexOfDataOnActives)
      }

      if (isDataNotOnActives) {
        this.addItemToActives(data)
      }

      this.$emit('submit', {
        data: this.actives,
        origin: this.tab,
      })

      if (this.submitMode) {
        this.actives = []
      }
    },

    removeItemFromActives(indexOfItem) {
      if (this.persistent && this.actives.length <= 1) {
        return
      }

      this.actives.splice(indexOfItem, 1)
    },

    addItemToActives(item) {
      if (this.multiple) {
        this.actives.push(item)
        return
      }

      this.actives = [item]
    },

    async handleItems() {
      if (this.search) {
        this.searchItems(this.search)
        return
      }

      this.filterItems()
    },

    filterItems() {
      this.filteredItems = this.items.filter((item) => {
        return !this.hideElements.includes(item.id)
      })

      this.$emit('loading', false)
    },

    async searchItems(value) {
      for (const identity in this.historySearchControllers) {
        this.historySearchControllers[identity].controller.abort()
      }

      if (typeof value !== 'string' || value.trim().length === 0) {
        this.filterItems()
        return
      }

      const controller = {
        identity: uuidv4(),
        controller: new AbortController(),
      }
      this.historySearchControllers[controller.identity] = controller

      this.filteredItems = []

      this.$emit('loading', true)
      setTimeout(() => {
        this.callFilterItemsDeep(value, controller)
      }, 0)
    },

    async callFilterItemsDeep(search, controller) {
      const promises = BreakAsThreadPromises(
        this.items,
        search,
        controller,
        this.shallowTree
      )

      Promise.all(promises)
        .then(() => {
          this.handleSuccessFilterSearch()
        })
        .catch((err) => {
          this.handleErrorFilterSearchAborted(err)
        })
        .finally(() => {
          delete this.historySearchControllers[controller.identity]
        })
    },

    handleSuccessFilterSearch() {
      this.filteredItems = BigSearchToUniqueItems(this.filteredItems)
      this.$emit('loading', false)
    },

    handleErrorFilterSearchAborted(err) {
      const callHasBeenAborted =
        err && 'name' in err && err.name === 'AbortError'
      if (callHasBeenAborted) {
        delete this.historySearchControllers[err.message]
        this.$emit('loading', true)
      }
    },

    shallowTree(items, search, controller) {
      return SearchWithAbortController(
        items,
        search,
        controller,
        this.filteredItems,
        this.hideElements
      )
    },

    resetCurrentActive(value) {
      const hasData = Array.isArray(value?.data) && value.data.length > 0

      const isEmptyValue = !value || !hasData
      const isSingleSelection = !this.multiple || !hasData

      if (isEmptyValue) {
        if (isSingleSelection) {
          this.actives = []
        }

        return
      }

      if (value.origin === this.tab) {
        this.actives = value.data
        return
      }

      this.actives = []
    },

    handleActive(id) {
      return !!this.actives.find((e) => e.id === id)
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
