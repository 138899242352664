<template>
  <v-app>
    <div :class="{ 'filter-blur': filterBlur }">
      <LateralMenu v-if="isSignedin && _showLateralMenu"></LateralMenu>

      <NavBar
        v-if="isSignedin && !_showNavBar"
        :user="user"
        :userLinks="_navBarLinks.userLinks"
        :menuLinks="_navBarLinks.menuLinks"
        :toLegacy="true"
      >
        <template v-slot:footer="{ isMiniVariant }">
          <LanguageMenu
            class="option-language"
            :currentLanguage="_currentLanguage"
            :isMiniVariant="isMiniVariant"
            @selected-language="handleSyncLanguage"
          ></LanguageMenu>
        </template>
      </NavBar>

      <v-main class="page" :class="{ modal: showDetails }">
        <v-container fluid :class="checkIfPaddingIsRequired">
          <router-view ref="PageView" class="full-height"></router-view>
        </v-container>
      </v-main>
    </div>

    <ExplanationNPS
      :isOpen="getExplanationNPS_Status"
      :type="getExplanationNPSType"
      @update:isOpen="closeExplanationNPS()"
    ></ExplanationNPS>

    <DialogBenchmarkRank
      :isOpen="getOverallAverageStatus"
      @update:isOpen="handleBenchmarkRankingDialog"
    ></DialogBenchmarkRank>

    <OpinionBox
      modeSidebar
      data-test-sidebar-opinion-box
      :isOpen="getOpinionBoxStatus"
      :opinion="getOpinionBoxProps.selectedOpinion.data"
      :ownerIdentity="getOpinionBoxProps.selectedOpinion.ownerIdentity"
      :chunks="getOpinionBoxProps.selectedOpinion.chunks.data"
      :chunksLoading="getOpinionBoxProps.selectedOpinion.chunks.loading"
      :tags="getOpinionBoxProps.tags"
      @update:isOpen="closeOpinionBox()"
      @handleMarkAsReaded="getOpinionBoxProps.handleMarkAsReaded"
      @handleMarkAsUnread="getOpinionBoxProps.handleMarkAsUnread"
      @handleSendMessage="getOpinionBoxProps.handleSendMessage"
      @handleCloseOpinion="getOpinionBoxProps.handleCloseOpinion"
      @handleTagsInfiniteScroll="getOpinionBoxProps.handleTagsInfiniteScroll"
      @handleAddTag="getOpinionBoxProps.handleAddTag"
      @handleRemoveTag="getOpinionBoxProps.handleRemoveTag"
      @handleCreateTag="getOpinionBoxProps.handleCreateTag"
    ></OpinionBox>

    <EngagementRecommendationsMoreDetails
      v-if="showDetails"
      :approval="RecommendationsDetails.approvalDetails"
      :id="RecommendationsDetails.sections.id"
      :title="RecommendationsDetails.sections.title"
      :image="RecommendationsDetails.sections.image"
      :what-section="RecommendationsDetails.sections.what"
      :how-to-section="RecommendationsDetails.sections.how"
      :benefits-section="RecommendationsDetails.sections.benefit"
      @voted="callVoteApproval"
    ></EngagementRecommendationsMoreDetails>

    <ActionPlans
      v-if="showActionPlan"
      :planID="ActionPlansDetails.actionPlanId"
      :planTemplate="ActionPlansDetails.planTemplate"
    ></ActionPlans>

    <UpdateYourPackage
      :isOpen="getUpdateYourPackageStatus"
      :type="getUpdateYourPackageType"
      @dialog:close="closeUpdateYourPackageDialog()"
      @dialog:action="actionUpdateYourPackageDialog()"
    ></UpdateYourPackage>

    <AlertBar></AlertBar>
  </v-app>
</template>

<script>
import { projectAuth } from '@/firebase/config'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { getAccount, getAccountMe, putAccountMe } from '@/service/account'

import { defineAbility } from '@/helpers/ability'
import { logEventHandler } from '@/helpers/analytics'
import { calcLanguageToApi, calcLanguageToLocale } from '@/helpers/locale'

import { genSiteNavigationItens } from '@/helpers/menu-itens'

import LanguageMenu from '@/components/LanguageMenu/LanguageMenu.vue'
import NavBar from '@/components/NavBar/NavBar.vue'
// import ToLegacy from '@/components/ToLegacy/ToLegacy.vue'

import AlertBar from '@/components/AlertBar/AlertBar.vue'
import DialogBenchmarkRank from '@/components/DialogBenchmarkRank/DialogBenchmarkRank.vue'
import EngagementRecommendationsMoreDetails from '@/components/EngagementRecommendationsMoreDetails/EngagementRecommendationsMoreDetails.vue'
import ExplanationNPS from '@/components/ExplanationNPS/ExplanationNPS.vue'
import OpinionBox from '@/components/OpinionBox/OpinionBox.vue'
import UpdateYourPackage from '@/components/UpdateYourPackage/UpdateYourPackage.vue'

import ActionPlans from '@/pages/EngagementPage/Views/ActionPlans/ActionPlans.vue'
import LateralMenu from '@/pages/EngagementPage/Views/LateralMenu/LateralMenu.vue'

export default {
  name: 'App',
  components: {
    ActionPlans,
    AlertBar,
    DialogBenchmarkRank,
    EngagementRecommendationsMoreDetails,
    ExplanationNPS,
    LanguageMenu,
    LateralMenu,
    NavBar,
    OpinionBox,
    // ToLegacy,
    UpdateYourPackage,
  },

  provide() {
    const actionPlan = this.genProvideActionPlan()

    return {
      actionPlan,
      callUpdateYourPackage: this.callUpdateYourPackage,
      toggleShowDetails: this.toggleShowDetails,
      changeLanguage: this.handleChangeLanguage,
    }
  },

  data() {
    return {
      filterBlur: false,
      hideNavBarbyRouter: [
        'SurveyInit',
        'SurveySteps',
        'SurveyFeedback',
        'SurveyFinish',
        'SurveyForbidden',
        'SurveyOther',
      ],

      foreignPages: [
        'Login',
        'NotFoundPage',
        'Survey',
        'SurveyInit',
        'SurveySteps',
        'SurveyFeedback',
        'SurveyFinish',
        'SurveyForbidden',
        'SurveyOther',
      ],
    }
  },

  methods: {
    ...mapActions([
      'setAccount',
      'setSignedInLogin',
      'setSignedInLogout',

      'openRecommendationsMoreDetails',
      'closeRecommendationsMoreDetails',
      'voteRecommendationsMoreDetails',

      'openActionPlans',
      'editActionPlans',
      'newActionPlans',
    ]),
    ...mapMutations(['changeClientPackage']),

    genProvideActionPlan() {
      const provide = {}

      Object.defineProperty(provide, 'open', {
        enumerable: true,
        get: () => this.showActionPlan,
        set: (opened) => {
          this.openActionPlans(opened)
        },
      })

      Object.defineProperty(provide, 'edit', {
        enumerable: true,
        get: () => this.showActionPlan,
        set: (actionPlanId) => {
          this.editActionPlans(actionPlanId)
        },
      })

      Object.defineProperty(provide, 'new', {
        enumerable: true,
        get: () => this.showActionPlan,
        set: (planTemplate) => {
          this.newActionPlans(planTemplate)
        },
      })

      return provide
    },

    callUpdateYourPackage(type) {
      if (!this.isSignedin) {
        return
      }

      this.$store.commit('openUpdateYourPackageDialog', type)
    },
    closeUpdateYourPackageDialog() {
      this.$store.commit('closeUpdateYourPackageDialog')
    },
    actionUpdateYourPackageDialog() {
      this.$crisp.push(['do', 'chat:open'])
      this.$crisp.push([
        'do',
        'message:send',
        ['text', this.$t('updateYourPackage.chatMessage')],
      ])
    },

    closeExplanationNPS() {
      this.$store.commit('closeExplanationNPSDialog')
    },
    async toggleShowDetails(opened, recommendationId) {
      if (!opened) {
        this.closeRecommendationsMoreDetails()
        return Promise.resolve()
      }

      return await this.openRecommendationsMoreDetails(recommendationId)
    },
    async callVoteApproval(id, vote) {
      return await this.voteRecommendationsMoreDetails({ id, vote })
    },

    handleBenchmarkRankingDialog() {
      this.$store.commit('handleBenchmarkRankingDialog')
    },
    handleBlur() {
      this.filterBlur = !this.filterBlur
    },
    closeOpinionBox() {
      this.$store.commit('closeOpinionBox')
    },

    async fetchAccount() {
      return getAccount().then(({ data }) => this.setAccount(data))
    },
    onAuthStateChangedByLogin() {
      // wait for login
      this.setSignedInLogout()

      projectAuth.onAuthStateChanged(async () => {
        await getAccountMe()
          .then(async ({ data }) => {
            const client = {
              ...data,
              paymentMethod: 'other',
              package: data.planID,
            }
            this.$store.dispatch('setClient', client)
            this.$store.dispatch('currentUser/loadCurrentUser', data)

            const lang = calcLanguageToLocale(data.language)
            this.handleChangeLanguage(lang)

            // defineAbility(require('@/helpers/ability/engagement').permissions) // @TODO Granulado
            // defineAbility([]) // @TODO Em Branco
            defineAbility(data.permissions)

            this.setSignedInLogin()

            await this.fetchAccount()

            this.$watch(
              () => this.$route.matched,
              () => {
                this.handleRouteGuard()
              },
              { deep: true, immediate: true }
            )

            if (window.location.href.includes('login')) {
              const from = sessionStorage.getItem(
                'interface-recovery--redirectPath'
              )
              if (from) {
                sessionStorage.removeItem('interface-recovery--redirectPath')
                this.$router.replace(from)

                return
              }

              this.$router.push('/')
            }
          })
          .catch((_) => {
            // User is signed out.
            this.logout()
          })
      })
    },
    async logout() {
      return new Promise((resolve) => {
        this.setSignedInLogout()
        localStorage.removeItem('teamculture:token')

        projectAuth.signOut().then(() => {
          if (!window.location.href.includes('login')) {
            this.$router.push({ name: 'Login' })
          }

          resolve()
        })
      })
    },
    calcRoutePermissions(matched) {
      const requiresPermissions = Array.from(
        new Set(matched.flatMap((record) => record?.meta?.requiresPermission))
      ).filter(Boolean)

      const canPagePermissions = requiresPermissions.reduce(
        (obj, permission) => {
          const canPermission = this.$can('access', permission)
          return { ...obj, [permission]: canPermission }
        },
        {}
      )

      return canPagePermissions
    },
    async handleRouteGuard() {
      const matched = this.$route.matched

      const canPagePermissions = this.calcRoutePermissions(matched)
      const shouldGuardRoute = Object.keys(canPagePermissions).length
      const hasPermission = Object.values(canPagePermissions).every(
        (permission) => permission === true
      )

      if (shouldGuardRoute && hasPermission) {
        this.logEventFirebase()
      }

      if (shouldGuardRoute && !hasPermission) {
        this.goNextRoutePossible()
      }
    },
    goNextRoutePossible() {
      const EngagementPage = this.$router.options.routes.find(
        (r) => r.name === 'EngagementPage'
      )

      const EngagementChilds = EngagementPage.children.reduce((obj, page) => {
        const matched = [EngagementPage, page]
        const hasPermission = Object.values(
          this.calcRoutePermissions(matched)
        ).every((permission) => permission === true)

        return { ...obj, [page.name]: hasPermission }
      }, {})

      const nextRoutePossible = Object.keys(EngagementChilds).find(
        (key) => EngagementChilds[key] === true
      )

      if (!nextRoutePossible) {
        this.logout().then(() => {
          this.$nextTick(() => {
            this.$refs.PageView?.customErrorMessage({
              text: 'LoginPage.errorPermission.text',
            })
          })
        })

        return
      }

      this.$router.push(nextRoutePossible)
    },
    logEventFirebase() {
      const context =
        {
          ClimatePage: 'engagement_climate',
          CulturePage: 'engagement_culture',
          ParticipationPage: 'engagement_participation',
          ComparisonPage: 'engagement_comparison',
          BenchmarkPage: 'engagement_benchmark_indicators',
          IndicatorsPage: 'engagement_benchmark_indicators',
          ReceivedOpinionsPage: 'engagement_opinions_receivedopinions',
          ActionPlanPage: 'engagement_actionplans',
          ActionPlansPage: 'engagement_actionplans',
        }[this.$route.name] || ''

      if (context) {
        logEventHandler(context, { ...this.getUserDetailsLoggable })
      }
    },

    async handleSyncLanguage(lang) {
      const language = calcLanguageToApi(lang)
      await putAccountMe({ language })
        .then(() => {
          this.handleChangeLanguage(lang)
        })
        .catch(() => {})
    },

    async handleChangeLanguage(lang) {
      const languageBySession = localStorage.getItem(
        'interface-preferences--language'
      )

      const shouldGetSessionLanguage = !lang && languageBySession
      const shouldGetNavigatorLanguage = !lang && !languageBySession
      let reloadIsRequired = false

      if (shouldGetSessionLanguage) {
        lang = languageBySession
      }

      if (shouldGetNavigatorLanguage) {
        lang = calcLanguageToLocale(
          navigator.language || navigator.userLanguage
        )
      }

      if (languageBySession !== lang && !shouldGetNavigatorLanguage) {
        reloadIsRequired = true
      }

      localStorage.setItem('interface-preferences--language', lang)
      this.$root.$i18n.locale = lang

      if (reloadIsRequired) {
        window.location.reload()
      }
    },

    getViewLinkPermissioned(link) {
      return !link?.permissioned || this.$can('access', link.permissioned)
    },

    calcNavBarLinksByPermissions(userLinks, menuLinks) {
      const menuLinksByPermissions = menuLinks.filter((link) =>
        this.getViewLinkPermissioned(link)
      )

      return {
        userLinks,
        menuLinks: menuLinksByPermissions,
      }
    },
  },

  computed: {
    ...mapGetters([
      'getUserDetailsLoggable',

      'getUpdateYourPackageStatus',
      'getUpdateYourPackageType',
      'getPaymentMethod',

      'getExplanationNPS_Status',
      'getExplanationNPSType',
      'getOverallAverageStatus',

      'getOpinionBoxStatus',
      'getOpinionBoxProps',
    ]),
    ...mapGetters({
      showDetails: 'RecommendationsMoreDetails__Status',
      RecommendationsDetails: 'RecommendationsMoreDetails__Props',

      showActionPlan: 'ActionPlans__Status',
      ActionPlansDetails: 'ActionPlans__Props',

      isSignedin: 'getIsSignedIn',
      user: 'currentUser/user',
    }),

    _currentLanguage() {
      return this.$i18n.locale
    },
    _navBarLinks() {
      const { userLinks, menuLinks } = genSiteNavigationItens(this.logout)

      return this.calcNavBarLinksByPermissions(userLinks, menuLinks)
    },

    padding: function () {
      return this.foreignPages.indexOf(this.$route.name) < 0 ? 'padding' : ''
    },
    checkIfPaddingIsRequired: function () {
      return this.foreignPages.indexOf(this.$route.name) < 0
        ? 'padding-content'
        : 'pa-0'
    },
    _showLateralMenu() {
      const baseRouteName = this.$route.matched[0]
        ? this.$route.matched[0].name
        : this.$route.name

      return baseRouteName === 'EngagementPage'
    },

    _showNavBar() {
      return this.hideNavBarbyRouter.includes(this.$router.currentRoute.name)
    },
  },

  created() {
    this.$root.$on('handle-blur', () => {
      this.handleBlur()
    })

    this.handleChangeLanguage(false)
    this.onAuthStateChangedByLogin()
  },
}
</script>

<style lang="scss" scoped>
::v-deep.option-language {
  display: contents;
}
</style>
