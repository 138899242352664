import Vue from 'vue'
import VueRouter from 'vue-router'
import { moduleRoutes } from './module-router'
// import { beforeEachRouterEntry } from './before-each-router'

Vue.use(VueRouter)

const routes = [...moduleRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// router.beforeEach(beforeEachRouterEntry)

export default router
