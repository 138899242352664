<template>
  <v-footer
    elevation="1"
    class="survey-footer__content content"
    color="neutral"
    height="67"
  >
    <!--Back-->
    <v-btn
      data-test-survey-step-back-btn
      id="survey-step-back-btn"
      class="content-btn"
      elevation="0"
      :disabled="_backDisabled"
      :x-small="$vuetify.breakpoint.xsOnly"
      @click="handleBack"
    >
      <v-icon
        left
        :x-small="$vuetify.breakpoint.mdAndDown"
        v-text="'fi-rr-arrow-small-left'"
      />
      <span v-text="btnBack" />
    </v-btn>
    <!--Progress-->
    <div
      data-test-survey-progress
      class="survey-footer__progress content-progress"
      v-if="length > 0"
    >
      <div class="survey-footer__progress__content content-text">
        <span class="content-text" v-text="$t('survey.footer.questionIndex')" />
        <span v-html="'&nbsp;'" />
        <span
          data-test-survey-progress-index
          class="content-text-stronger"
          v-text="Number(index) + 1"
        />
        <span v-html="'&nbsp;'" />
        <span class="content-text" v-text="$t('survey.footer.ofIndex')" />
        <span v-html="'&nbsp;'" />
        <span
          data-test-survey-progress-size
          class="content-text-stronger"
          v-text="length"
        />
      </div>
      <v-progress-linear
        class="fill-width"
        :value="progress"
        color="primary"
        background-color="neutral8"
        height="6"
        rounded
      />
    </div>
    <!--Next-->
    <v-btn
      data-test-survey-next-btn
      id="survey-step-next-btn"
      class="content-btn"
      elevation="0"
      :x-small="$vuetify.breakpoint.xsOnly"
      :disabled="_nextDisabled"
      @click="handleNext"
    >
      <span v-text="btnNext" />
      <v-icon
        right
        :x-small="$vuetify.breakpoint.mdAndDown"
        v-text="'fi-rr-arrow-small-right'"
      />
    </v-btn>
  </v-footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SurveyFooter',
  data: () => ({}),

  computed: {
    ...mapState(['survey']),

    index: function () {
      return this.survey.step.index
    },
    length: function () {
      if (!this.survey.questions) return

      return this.survey.questions.length
    },
    progress: function () {
      return (100 / this.length) * (this.index + 1)
    },
    btnBack: function () {
      const backStates = ['other']
      const key = backStates.includes(this.survey.state) ? 'btnBack' : 'btnPrev'
      return this.$t(`survey.footer.${key}`)
    },
    btnNext: function () {
      const key =
        this.survey.haveAnswer || this.survey.isAnsweringOtherSubject
          ? 'Save'
          : 'Skip'
      return this.$t(`survey.footer.btn${key}`)
    },
    _backDisabled: function () {
      return this.survey.state === 'steps' && this.survey.step.index == 0
    },
    _nextDisabled: function () {
      return this.survey.state === 'other' && !this.survey.haveAnswer
    },
  },

  methods: {
    ...mapActions([
      'setSurveyStepIndex',
      'setSurveyState',
      'resetSurveyOptions',
      'resetSurveyAnswers',
    ]),

    handleBack() {
      if (this.survey.state == 'feedback')
        return this.setSurveyState({ state: 'steps' })

      if (this.survey.state == 'steps') {
        const newIndex = Number(this.index) - 1
        if (!this.survey.questions) return
        if (!this.survey.questions[newIndex]) return

        return this.setSurveyStepIndex({ index: newIndex })
      }

      if (this.survey.state == 'other') {
        this.survey.isAnsweringOtherSubject = false
        this.resetSurveyOptions()
        this.resetSurveyAnswers()
        return this.setSurveyState({ state: 'finish' })
      }
    },
    handleNext() {
      if (!this.survey.questions) return

      // Steps
      if (this.survey.state == 'steps') {
        const newIndex = Number(this.index) + 1

        // End Steps -> Next [Finish|Feedback]
        if (!this.survey.questions[newIndex]) {
          const { answered } = this.survey.questions[this.index]
          // Feedback|Finish
          return this.setSurveyState({
            state: `${answered ? 'feedback' : 'finish'}`,
          })
        }

        return this.setSurveyStepIndex({ index: newIndex })
      }

      // Feedback
      if (this.survey.state == 'feedback') {
        return this.setSurveyState({ state: 'finish' })
      }
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
