<template>
  <section class="d-flex flex-column content-section">
    <div class="content-section-title">
      {{ $t('shareResults.viewList.title') }}
    </div>

    <!-- table -->
    <v-data-table
      data-test-view-list
      :headers="headers"
      :items="results"
      class="custom-table mb-2"
      height="100%"
      :no-data-text="$t('shareResults.noData')"
      hide-default-footer
      disable-pagination
      disable-sort
      dense
    >
      <!-- title -->
      <template v-slot:[`item.person`]="{ item }">
        <div v-if="item.person" class="d-flex align-center">
          <v-avatar
            class="custom-table-avatar"
            :color="randomColor()"
            size="22"
          >
            <span class="custom-table-avatar-label">
              {{ getAcronym(item.person.name) }}
            </span>
          </v-avatar>

          <div class="d-flex flex-column">
            <span class="custom-table-label item-title">
              {{ item.person.name }}
            </span>

            <span class="custom-table-sublabel">
              {{ item.person.email }}
            </span>
          </div>
        </div>
      </template>

      <!-- info -->
      <template v-slot:[`item.info`]="{ item }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <div class="clickable" v-bind="attrs" v-on="on">
              <!-- > 1 -->
              <span
                v-if="item.access.length > 1"
                class="mr-1 custom-table-label"
              >
                {{ item.access.length }}
                {{ $t('shareResults.viewList.releasedInformation') }}
              </span>

              <!-- only 1 -->
              <span
                v-else-if="item.access.length === 1"
                class="mr-1 custom-table-label"
              >
                {{ $t(`shareResults.permissions.${item.access[0]}`) }}
              </span>

              <!-- empty -->
              <span v-else class="mr-1 custom-table-label">
                {{ $t('shareResults.noInformation') }}
              </span>

              <v-icon color="neutral1" x-small>fi-br-caret-down</v-icon>
            </div>
          </template>

          <v-list dense>
            <v-list-item-group v-model="item.access" multiple>
              <template v-for="(access, index) in accessList">
                <v-list-item
                  :key="index"
                  :value="access.value"
                  active-class="bgActive primary--text"
                  @click="updateResultSharing(item)"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-2">
                      <v-checkbox :input-value="active" color="primary" />
                    </v-list-item-action>

                    <v-list-item-title>{{ access.label }}</v-list-item-title>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </template>

      <!-- action -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon small @click="deleteResultSharing(item.person.id)">
          <v-icon color="neutral5" small> fi-rr-cross-circle </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- search -->
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          data-test-input-collab
          v-model="search"
          v-bind="attrs"
          v-on="on"
          class="custom-search"
          :placeholder="$t('shareResults.viewList.searchPlaceholder')"
          hide-details
          dense
          solo
          flat
          @focus="getPeople()"
          @input="searchPeople()"
        />
      </template>

      <v-list class="pa-0">
        <section v-if="people.length > 0">
          <v-list-item
            data-test-list-collab-itens
            v-for="(currentPeople, index) in people"
            :key="index"
            @click="createResultSharing(currentPeople.id)"
          >
            <div class="d-flex align-center">
              <v-avatar
                class="custom-table-avatar"
                :color="randomColor()"
                size="22"
              >
                <span class="custom-table-avatar-label">
                  {{ getAcronym(currentPeople.name) }}
                </span>
              </v-avatar>

              <div class="d-flex flex-column justify-center fill-height">
                <span class="custom-table-label item-title">
                  {{ currentPeople.name }}
                </span>

                <span class="custom-table-sublabel">
                  {{ currentPeople.email }}
                </span>
              </div>
            </div>
          </v-list-item>
        </section>

        <v-list-item v-else class="custom-table-label">
          {{ $t('shareResults.noData') }}
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- pagination -->
    <div class="d-flex justify-center align-center py-3">
      <v-btn class="mr-1" tile icon x-small @click="prev()">
        <v-icon x-small>fi fi-br-angle-left</v-icon>
      </v-btn>

      <v-sheet
        color="grayColor2"
        height="18"
        width="18"
        class="d-flex align-center justify-center fill-height rounded-md"
      >
        <span class="label-pagination"> {{ pagination.page }} </span>
      </v-sheet>

      <v-btn class="ml-1" tile icon x-small @click="next()">
        <v-icon x-small>fi fi-br-angle-right</v-icon>
      </v-btn>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      interval: null,
      search: '',
      headers: [
        {
          text: this.$t('shareResults.viewList.table.person'),
          value: 'person',
        },
        {
          text: this.$t('shareResults.viewList.table.info'),
          value: 'info',
        },
        {
          text: this.$t('shareResults.viewList.table.actions'),
          value: 'actions',
          width: '6em',
          align: 'center',
        },
      ],
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
      },
    }
  },

  props: {
    type: null,
    accessList: [],
    people: [],
    results: [],
  },

  mounted() {
    this.getResultSharing()
  },

  computed: {
    ...mapGetters(['getGroupId']),
  },

  methods: {
    async getResultSharing() {
      const payload = {
        groupID: this.getGroupId || null,
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        module: this.type,
      }

      this.$emit('get-result-sharing', payload)
    },

    async createResultSharing(id) {
      const payload = {
        groupID: this.getGroupId || null,
        personID: id,
        module: this.type,
        access: [],
      }

      this.$emit('create-result-sharing', payload)
    },

    async updateResultSharing(data) {
      const payload = {
        resultsSharingID: data.person.id,
        body: {
          access: data.access,
        },
      }
      this.$emit('update-result-sharing', payload)
    },

    async deleteResultSharing(id) {
      this.$emit('delete-result-sharing', id)
    },

    async getPeople() {
      const payload = {
        name: this.search,
        limit: 30,
        offset: 0,
      }

      this.$emit('get-people', payload)
    },

    async searchPeople() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.getPeople()
      }, 500)
    },

    getAcronym(value) {
      const names = value.split(' ')

      const firstName = names[0]
      const lastName = names[1]

      return `${firstName[0]}${lastName[0]}`
    },

    randomColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16)
    },

    prev() {
      if (this.pagination.page === 1) return

      this.pagination.page--
      this.pagination.offset = this.pagination.offset - this.pagination.limit
      this.getResultSharing()
    },

    next() {
      if (this.results.length < this.pagination.limit) return

      this.pagination.page++
      this.pagination.offset = this.pagination.offset + this.pagination.limit
      this.getResultSharing()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
