<template>
  <div class="shadow" id="card-tasks-report">
    <v-card elevation="0" height="100%">
      <div class="px-4 py-3">
        <span class="title">{{
          $t('engagementPage.actionPlan.cards.tasksReport.title')
        }}</span>
      </div>
      <v-divider class="custom-divider"></v-divider>
      <div
        :class="
          'body-container ' +
          (items.length === 0 && !loading ? 'empty-stage' : '')
        "
      >
        <v-data-table
          :items="items"
          mobile-breakpoint="100"
          class="custom-table tasks-report-table"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header>
            <thead class="custom-table-header">
              <tr>
                <th
                  role="columnheader"
                  scope="col"
                  class="text-start"
                  v-for="header in headers"
                  :key="header.value"
                >
                  <span class="d-flex align-center">
                    <span class="mr-2">{{ header.text }}</span>
                    <div
                      class="d-flex flex-column"
                      @click="handleSort(header.value)"
                    >
                      <i
                        :class="
                          sortBy === header.value && sortOrder === 'asc'
                            ? 'filter-active'
                            : ''
                        "
                        class="fi-sr-caret-up"
                      />
                      <i
                        :class="
                          sortBy === header.value && sortOrder === 'desc'
                            ? 'filter-active'
                            : ''
                        "
                        class="fi-sr-caret-down"
                      />
                    </div>
                  </span>
                </th>
              </tr>
              <v-divider class="custom-divider width-absolute"></v-divider>
            </thead>
          </template>
          <template v-slot:body="{ items }">
            <tbody
              class="custom-table-body tasks-report-body"
              v-if="items.length > 0 && !loading"
            >
              <tr
                class="custom-table-row"
                v-for="item in items"
                :key="item.id"
                width="100%"
              >
                <td id="people">
                  <div class="custom-name">
                    <Avatar :src="item.photo" :text="item.name" />
                    <span class="custom-name-text" :title="item.name">
                      {{ item.name }}
                    </span>
                  </div>
                </td>
                <td id="open-tasks">
                  <v-chip class="open-tasks chip">{{ item.open }}</v-chip>
                </td>
                <td id="done-tasks">
                  <v-chip class="closed-tasks chip">{{ item.closed }}</v-chip>
                </td>
                <td id="total-tasks">
                  <v-chip class="total-tasks chip">{{ item.total }}</v-chip>
                </td>
              </tr>
            </tbody>
            <tbody
              class="custom-table-body tasks-report-body"
              v-else-if="loading"
            >
              <tr
                class="custom-table-row"
                width="100%"
                v-for="fakeItem in fakeItems"
                :key="fakeItem.person.id"
              >
                <td>
                  <div class="custom-name">
                    <div class="d-flex">
                      <v-skeleton-loader type="avatar"></v-skeleton-loader>
                    </div>
                  </div>
                </td>
                <td>
                  <v-skeleton-loader type="chip"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="chip"></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader type="chip"></v-skeleton-loader>
                </td>
              </tr>
            </tbody>
            <tbody
              class="custom-table-body tasks-report-body empty-stage"
              v-if="items.length === 0 && !loading"
            >
              <tr
                class="custom-table-row"
                width="100%"
                v-for="fakeItem in fakeItems"
                :key="fakeItem.person.id"
              >
                <td>
                  <div class="custom-name">
                    <Avatar
                      :src="fakeItem.person.photo"
                      :text="fakeItem.person.name"
                    />
                    <span
                      class="custom-name-text"
                      :title="fakeItem.person.name"
                      >{{ fakeItem.person.name }}</span
                    >
                  </div>
                </td>
                <td>
                  <v-chip class="open-tasks chip">{{ fakeItem.open }}</v-chip>
                </td>
                <td>
                  <v-chip class="closed-tasks chip">{{
                    fakeItem.closed
                  }}</v-chip>
                </td>
                <td>
                  <v-chip class="total-tasks chip">{{ fakeItem.total }}</v-chip>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <div class="tasks-report-footer" v-if="items.length > 0">
              <v-pagination v-model="page" :length="pageLength" />
            </div>
            <div
              class="mb-8 tasks-report-footer custom-footer-empty-stage"
              v-else-if="items.length === 0 && !loading"
            >
              <div class="empty-stage-info">
                {{
                  $t(
                    'engagementPage.actionPlan.cards.tasksReport.table.emptyStage'
                  )
                }}
              </div>
              <a
                class="tip"
                :href="
                  $t(
                    'engagementPage.actionPlan.cards.tasksReport.table.tipLink'
                  )
                "
              >
                {{
                  $t('engagementPage.actionPlan.cards.tasksReport.table.tip')
                }}
              </a>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar/Avatar.vue'

export default {
  name: 'CardTasksReport',
  components: {
    Avatar,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLength: {
      type: Number,
      default: 3,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSort(value) {
      let changedNow = false
      this.sortBy = value
      if (this.sortOrder === 'none') {
        this.sortOrder = 'asc'
        changedNow = true
      }
      if (!changedNow) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.$emit('handleSort', this.sortOrder, value)
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t(
            'engagementPage.actionPlan.cards.tasksReport.table.person'
          ),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t(
            'engagementPage.actionPlan.cards.tasksReport.table.openTasks'
          ),
          value: 'open',
        },
        {
          text: this.$t(
            'engagementPage.actionPlan.cards.tasksReport.table.closedTasks'
          ),
          value: 'closed',
        },
        {
          text: this.$t(
            'engagementPage.actionPlan.cards.tasksReport.table.totalTasks'
          ),
          value: 'total',
        },
      ],
      sortOrder: 'none',
      sortBy: 'none',
      fakeItems: [
        {
          person: {
            id: 1,
            name:
              this.$t(
                'engagementPage.actionPlan.cards.tasksReport.table.fakePlaceholder'
              ) + ' 1',
          },
          open: 0,
          closed: 0,
          total: 0,
        },
        {
          person: {
            id: 2,
            name:
              this.$t(
                'engagementPage.actionPlan.cards.tasksReport.table.fakePlaceholder'
              ) + ' 2',
          },
          open: 0,
          closed: 0,
          total: 0,
        },
        {
          person: {
            id: 3,
            name:
              this.$t(
                'engagementPage.actionPlan.cards.tasksReport.table.fakePlaceholder'
              ) + ' 3',
          },
          open: 0,
          closed: 0,
          total: 0,
        },
      ],
    }
  },
  watch: {
    page(newPage) {
      this.$emit('handleUpdate', newPage)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
