<template>
  <div>
    <v-card elevation="0" outlined max-width="100%" height="350" class="pb-6">
      <v-card-title class="justify-center card-title-slot">
        <h4
          class="card-title"
          v-text="$t('engagementPage.cardInvitedCollaborators.cardTitle')"
        ></h4>
      </v-card-title>

      <div v-if="loading" class="load-content">
        <v-skeleton-loader width="300" type="list-item"></v-skeleton-loader>
        <v-skeleton-loader width="200" type="list-item"></v-skeleton-loader>
        <v-skeleton-loader width="300" type="list-item"></v-skeleton-loader>
      </div>

      <section v-else>
        <v-card-text>
          <div class="d-flex flex-column align-center card-content-container">
            <p
              data-test-invited-number
              :class="['card-invited-number', { 'mb-5': !_getStartDate }]"
              v-text="_getInvited"
            ></p>

            <p
              v-if="_getStartDate"
              class="card-start-date"
              v-text="
                $t('engagementPage.cardInvitedCollaborators.startDate', {
                  date: _getStartDate,
                })
              "
            ></p>
          </div>
        </v-card-text>

        <v-card-actions class="justify-center" data-test-invite-link>
          <v-btn
            v-if="_canManagementParticipations"
            :href="_linkManagement"
            target="_blank"
            class="card-button"
            elevation="0"
          >
            <span
              v-text="$t('engagementPage.cardInvitedCollaborators.button')"
            ></span>
          </v-btn>
        </v-card-actions>
      </section>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

import { _permissions } from '@/helpers/ability/engagement'

export default {
  name: 'CardInvitedCollaborators',
  props: {
    invited: {
      type: Number,
    },
    startDate: {
      type: String,
    },
    detailLink: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _canManagementParticipations() {
      return this.$can('access', _permissions.engagement_participations_invite)
    },

    _linkManagement() {
      const linkManagement = new URL(
        'management/people-and-groups/people',
        process.env.VUE_APP_MANAGEMENT_LINK
      )
      return linkManagement
    },
    _getInvited() {
      if (!this.invited) {
        return 0
      }

      return this.invited
    },
    _getStartDate() {
      if (!this.startDate) {
        return ''
      }

      const startDate = Date.parse(`${this.startDate} 00:00:00`)

      return moment(startDate).format(
        this.$t('engagementPage.cardInvitedCollaborators.dateFormat')
      )
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
