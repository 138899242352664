<template>
  <div data-test-selector-input-field>
    <div
      data-test-selector-input-field-highlight
      class="highlight-pulse"
      v-if="highlight && readonly"
    ></div>
    <v-hover v-model="isHover" :disabled="readonly">
      <v-combobox
        data-test-input-field-responsible
        ref="input"
        v-model="presentedModel"
        :class="[_classStyle]"
        :search-input.sync="search"
        :label="label"
        :readonly="readonly"
        :clearable="clearable"
        :append-icon="_appendIcon"
        :rules="rules"
        hide-details="auto"
        color="black"
        hide-selected
        small-chips
        multiple
        outlined
        @update:search-input="handleSearch($event)"
        @keydown="focus()"
        @focus="focus()"
        @blur="handleBlur()"
        @click.stop="focus()"
        @click:clear.stop="handleClear()"
        @update:error="handleError($event)"
      >
        <template v-slot:selection="{ attrs, item }">
          <div v-bind="attrs" @click.stop="focus()">
            <div v-if="item && _handleSelection && !isFocused" class="d-flex">
              <v-icon v-if="showIcon" small>{{ item.typeIcon }}</v-icon>

              <v-chip
                :class="{
                  'custom-chip-colored': multiple,
                  'custom-chip-transparent': !multiple,
                }"
                @click.stop="focus()"
              >
                <AvatarComponent
                  v-if="showAvatar"
                  :id="item.id || ''"
                  :text="item.label"
                  :src="item.image || ''"
                  :size="'24'"
                />

                <span
                  data-test-input-field-selection-label
                  ref="chiplabel"
                  :class="{
                    'custom-chip-label': multiple,
                    'field-item': !multiple,
                    highlight: highlight && readonly,
                  }"
                  v-text="item.label"
                />

                <v-tooltip
                  v-if="hasTooltip && isTruncated"
                  :[_tooltipConfig.labelTooltip.position]="true"
                  open-on-hover
                  :z-index="9999"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      data-test-input-field-truncated
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1 item-label"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </div>
                  </template>
                  <div class="item-label-tooltip">
                    <span v-text="item.label" />
                  </div>
                </v-tooltip>
              </v-chip>

              <v-tooltip
                v-if="hasTooltip && presentedMoreItens.length > 0"
                :[_tooltipConfig.moreTooltip.position]="true"
                open-on-hover
                :z-index="9999"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    data-test-input-field-more-itens
                    v-bind="attrs"
                    v-on="on"
                    class="more-items-chip"
                    :class="{
                      'custom-chip-colored': multiple,
                      'custom-chip-transparent': !multiple,
                    }"
                  >
                    <span
                      class="custom-chip-label"
                      :class="{ highlight: highlight && readonly }"
                      v-text="`+ ${presentedMoreItens.length}`"
                    />
                  </v-chip>
                </template>

                <div class="more-itens-tooltip d-flex">
                  <span class="mx-2" v-text="_moreItensTooltip" />
                </div>
              </v-tooltip>
            </div>
          </div>
        </template>

        <template v-slot:append>
          <v-icon
            v-if="showCaretArrow"
            class="mt-1"
            :color="_caretArrowColor"
            small
            v-text="_caretArrowIcon"
          />
        </template>
      </v-combobox>
    </v-hover>
  </div>
</template>

<script>
import AvatarComponent from '@/components/AvatarComponent/AvatarComponent'

export default {
  components: { AvatarComponent },
  data() {
    return {
      isHover: false,
      isFocused: false,
      isTruncated: false,

      presentedModel: null,
      presentedMoreItens: [],

      searchInterval: null,
      search: '',
    }
  },

  props: {
    selectedItem: null,

    label: {
      type: String,
      default: '',
    },

    stateMenu: {
      type: Boolean,
      default: false,
    },

    submitMode: {
      type: Boolean,
      default: false,
    },

    subtleMode: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    hasTooltip: {
      type: Boolean,
      default: true,
    },

    tooltipConfig: {
      type: Object,
      default: () => ({
        labelTooltip: {
          position: 'top',
        },
        moreTooltip: {
          position: 'bottom',
          linebreak: false,
        },
      }),
    },

    showAvatar: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: false,
    },

    showCaretArrow: {
      type: Boolean,
      default: true,
    },

    rules: {
      type: Array,
      default: () => [],
    },

    localSearch: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    selectedItem: {
      handler(val) {
        this.handleModel(val)
      },
      immediate: true,
    },

    _width() {
      this.isTruncatingText()
    },
  },

  mounted() {
    this.setPresentedModel()
    this.setTruncatedText()
    this.watchForErrorState()
  },

  updated() {
    this.setTruncatedText()
  },

  computed: {
    _handleSelection() {
      return this.hasData(this.selectedItem)
    },

    _appendIcon() {
      return this.handleAppendIcon(this.showCaretArrow)
    },
    _caretArrowColor() {
      return this.handleCaretArrowColor(this.submitMode, this.readonly)
    },
    _caretArrowIcon() {
      return this.handleCaretArrowIcon(this.stateMenu)
    },
    _moreItensTooltip() {
      return this.formatPresentedWithLinebreak(
        this.presentedMoreItens,
        this._tooltipConfig.moreTooltip.linebreak
      )
    },

    _classStyle() {
      return this.genClassStyle(
        this.subtleMode,
        this.submitMode,
        this.readonly,
        this.isHover,
        this.isFocused
      )
    },

    _width() {
      return this.$vuetify.breakpoint.width
    },

    _tooltipConfig() {
      return this.genTooltipConfig(this.tooltipConfig)
    },
  },

  methods: {
    hasData(prop) {
      return Array.isArray(prop?.data)
    },

    formatPresentedWithLinebreak(presented, linebreak) {
      if (!Array.isArray(presented)) {
        return ''
      }

      if (linebreak) {
        return presented.join('\r\n')
      }

      return presented.join(', ')
    },

    handleCaretArrowIcon(stateMenu) {
      if (stateMenu) {
        return 'fi-rr-caret-up'
      }

      return 'fi-rr-caret-down'
    },

    handleCaretArrowColor(submitMode, readonly) {
      if (submitMode || readonly) {
        return 'transparent'
      }

      return 'neutral5'
    },

    handleAppendIcon(showCaretArrow) {
      if (showCaretArrow) {
        return '$dropdown'
      }

      return ''
    },

    genTooltipConfig(tooltipConfig = {}) {
      const { labelTooltip = {}, moreTooltip = {} } = tooltipConfig

      return {
        labelTooltip: {
          position: 'top',
          ...labelTooltip,
        },
        moreTooltip: {
          position: 'bottom',
          linebreak: false,
          ...moreTooltip,
        },
      }
    },

    genClassStyle(subtleMode, submitMode, readonly, isHover, isFocused) {
      if (subtleMode && !isHover) {
        return 'subtle-input'
      }

      const hasData = this.selectedItem?.data?.length
      if (submitMode && !isFocused && !hasData) {
        return 'flat-input'
      }

      if (readonly) {
        return 'readonly'
      }

      return 'outlined-input'
    },

    setPresentedModel() {
      if (this.readonly) {
        this.presentedModel = [null]
      }
    },
    setTruncatedText() {
      this.$nextTick(() => {
        this.isTruncatingText()
      })
    },
    async watchForErrorState() {
      const unwatch = this.$watch(this.isErrorState, (v) => {
        this.handleError(v)
      })

      return unwatch
    },

    handleModel(value) {
      if (value && Array.isArray(value?.data) && value.data.length > 0) {
        this.presentedModel = [value.data[0]]
        this.presentedMoreItens = []

        if (value.data.length > 1) {
          this.presentedMoreItens = value.data.slice(1).map((e) => e.label)
        }

        return
      }

      if (this.readonly) {
        this.presentedModel = [null]
        return
      }

      this.presentedModel = null
    },

    handleSearch(event) {
      if (event === null) {
        return
      }

      if (this.searchInterval) {
        clearInterval(this.searchInterval)
      }

      this.searchInterval = setTimeout(() => {
        this.searchSubmit(event)
      }, 500)
    },

    searchSubmit(value) {
      this.$emit('search:item', value)
    },

    focus() {
      if (this.isFocused || this.readonly) {
        return
      }

      this.isFocused = true
      this.$emit('focus:input')
    },

    handleBlur() {
      this.isFocused = false
      this.search = ''
      this.$emit('blur:input')
      this.handleModel(this.selectedItem)
    },

    handleClear() {
      if (this.clearable) {
        this.$emit('clear:input')
        this.handleModel(null)
      }
    },

    handleError(value) {
      if (this.rules.length > 0) {
        this.$emit('error:input', !value)
      }
    },

    isErrorState() {
      const input = this.$refs.input
      if (input) {
        return input?.validationState === 'error'
      }

      return !this.rules.length > 0
    },

    isTruncatingText() {
      const el = this.$refs.chiplabel
      if (el) {
        this.isTruncated = el.scrollWidth > el.offsetWidth
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
