<template>
  <v-card data-test-selection-values class="card">
    <v-text-field
      v-model="search"
      :placeholder="$t('cultureManagement.quickSelectionValues.search')"
      hide-details
      dense
      solo
      flat
      @keyup="searchValue()"
    >
      <template v-slot:prepend>
        <v-icon class="icon-field" color="neutral5" size="16px">
          fi-rr-search
        </v-icon>
      </template>
    </v-text-field>

    <v-divider />

    <div class="content">
      <v-list
        v-if="filteredValues.length > 0 || values.length > 0"
        class="list"
        width="100%"
        dense
      >
        <v-list-item
          v-for="(item, i) in filteredValues.length > 0
            ? filteredValues
            : values"
          :key="i"
          data-test-organizational-value
          class="custom-item"
          @click="submit(item, i)"
        >
          <v-list-item-title class="custom-item-label">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <div v-else class="empty-content">
        <span class="empty-text">
          {{ $t('cultureManagement.quickSelectionValues.empty') }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      interval: null,
      search: '',
      filteredValues: [],
    }
  },

  props: {
    values: {
      type: Array,
      default: () => [],
    },

    behavior: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    submit(data, index) {
      const payload = {
        index: index,
        value: {
          ...data,
          behaviors: [...data.behaviors, this.behavior],
        },
      }

      this.$emit('update:value', payload)
      this.$emit('close')
    },

    searchValue() {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.filteredValues = this.values.filter((e) =>
          e.name.toLowerCase().includes(this.search.toLowerCase())
        )
      }, 250)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
