<template>
  <div
    data-test-loading-slot-container
    id="loading-slot"
    :class="[
      `loading-container loading-${slotConfig.name}`,
      _isNodeItem && 'loading-node',
      _isListOfItem && 'loading-entity',
      overlay && 'loading-overlay',
    ]"
  >
    <template v-for="(_el, i) in highlightQuantity">
      <slot :name="`loading.${slotConfig.path}`" v-bind="{ index: i }">
        <v-skeleton-loader
          data-test-loading-node
          v-if="_isNodeItem"
          type="list-item"
          max-height="2rem"
          height="2rem"
          :key="`loading.${slotConfig.path}.${i}`"
        />
        <template v-if="_isListOfItem">
          <div
            :key="`loading.${slotConfig.path}.${i}`"
            class="skeleton-node"
            data-test-loading-list
          >
            <div class="skeleton-first-line">
              <div class="skeleton-icon">
                <v-skeleton-loader
                  type="button"
                  width="1.63rem"
                  max-height="1.63rem !important"
                />
              </div>
              <div class="skeleton-text">
                <v-skeleton-loader type="text" width="13rem" />
                <v-skeleton-loader type="text" width="4.8rem" />
              </div>
            </div>
            <div class="skeleton-second-line">
              <v-skeleton-loader type="text" width="5rem" />
              <v-skeleton-loader type="text" width="5rem" />
              <v-skeleton-loader type="text" width="5rem" />
            </div>
          </div>
        </template>
      </slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    slotConfig: {
      type: Object,
      default: () => ({
        name: 'global',
        path: 'global',
      }),
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    itensComponentsName: {
      type: String,
      default: 'node-item',
    },
    highlightQuantity: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    _isNodeItem() {
      return this.isNodeItem(this.itensComponentsName)
    },
    _isListOfItem() {
      return this.isListOfItem(this.itensComponentsName)
    },
  },
  methods: {
    isNodeItem(itensComponentsName) {
      return itensComponentsName === 'node-item' || !itensComponentsName
    },
    isListOfItem(itensComponentsName) {
      return itensComponentsName === 'ListOfItems'
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
