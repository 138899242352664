<template>
  <div :id="`card-progress-circle-${id}`" class="shadow">
    <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
    <v-card v-else elevation="0" height="100%">
      <div class="px-4 py-3">
        <span class="title">{{ title }}</span>
      </div>
      <v-divider class="custom-divider" />
      <ProgressCircle
        class="pa-2"
        :data="data"
        :legendPosition="legendPosition"
        @legendClick="legendClick"
        :showLegendEmptyStage="showLegendEmptyStage"
      />
    </v-card>
  </div>
</template>

<script>
import ProgressCircle from '@/components/ProgressCircle/ProgressCircle.vue'

export default {
  name: 'CardProgressCircle',
  components: {
    ProgressCircle,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
    },
    legendPosition: {
      type: String,
      default: '',
    },
    showLegendEmptyStage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    legendClick(index) {
      this.$emit('legendClick', index)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
