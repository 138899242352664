<template>
  <RightMenu
    data-test-selector-field
    ref="menu"
    v-model="menuDrop"
    :content-class="
      `menu` +
      ((!_menuConfig.showTabs && ' menu-no-tabs') || '') +
      ((!_inputConfig.showAvatar && ' menu-no-avatar') || '') +
      ((!fillWidth && ' menu-old-style') || '')
    "
    :z-index="_menuConfig.zIndex"
    :attach="_menuConfig.attach"
    :right="_menuConfig.align === 'right'"
    :close-on-content-click="false"
    :disabled="readonly"
    v-bind="_BindMeOnMenu"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        data-test-selector-activator
        v-bind="attrs"
        v-on="on"
        class="field"
        :class="{ fillWidth: fillWidth, 'px-1': !fillWidth }"
      >
        <slot
          name="default"
          v-bind="{
            selectedItem: _selectedItem,
            stateMenu: menuDrop,
            focus: focusInput,
            blur: blurInput,
            search: handleSearch,
            clear: handleClear,
          }"
        >
          <InputField
            ref="inputField"
            :hasTooltip="_inputConfig.hasTooltip"
            :showAvatar="_inputConfig.showAvatar"
            :showIcon="_inputConfig.showIcon"
            :submitMode="_inputConfig.submitMode || inputStyle === 'submitMode'"
            :subtleMode="_inputConfig.subtleMode"
            :highlight="_inputConfig.highlight"
            :showCaretArrow="_inputConfig.showCaretArrow"
            :clearable="_inputConfig.clearable"
            :tooltipConfig="_inputConfig.tooltipConfig"
            :rules="_inputConfig.rules"
            :label="_inputConfig.label"
            :multiple="multiple"
            :readonly="readonly"
            :selectedItem="_selectedItem"
            :localSearch="localSearch"
            :stateMenu="menuDrop"
            @focus:input="focusInput()"
            @blur:input="blurInput()"
            @search:item="handleSearch($event)"
            @clear:input="handleClear()"
            @error:input="handleError($event)"
          ></InputField>
        </slot>
      </div>
    </template>

    <!-- menu view -->
    <v-card data-test-selector-menu class="menu-card" outlined @keydown.stop>
      <div v-if="_menuConfig.showInsiderSearch">
        <slot
          name="menu.insider.search"
          v-bind="{
            model: inputSearchInside,
            search: handleInsideSearch,
          }"
        >
          <v-text-field
            data-test-selector-search
            v-model="inputSearchInside"
            class="insider-search"
            :label="_inputConfig.label"
            hide-details
            single-line
            color="black"
            prepend-inner-icon="mdi-magnify"
            @input="handleInsideSearch"
          ></v-text-field>
        </slot>
      </div>

      <div
        v-if="_menuConfig.showTabs || !!_currentTabTree"
        class="header"
        data-test-selector-header
      >
        <section>
          <v-tabs
            v-show="_menuConfig.showTabs"
            v-model="tabModel"
            class="tabs"
            color="#dd0041"
            background-color="transparent"
            height="36"
            show-arrows
            left
          >
            <template v-for="(item, index) in menuOptions">
              <v-tab
                v-show="!item.hideTab"
                class="text-none"
                :href="`#${item.value}`"
                :key="`tab.${index}`"
                :data-test-selector-tab="item.label"
                @click="focusTab(item.value)"
              >
                <span class="header-tab-label" v-text="item.label" />
              </v-tab>
            </template>
          </v-tabs>
        </section>

        <v-spacer />

        <section v-if="!menuConfig.hideSideActions" class="side-actions">
          <v-btn
            v-if="!!_currentTabTree"
            data-test-selector-expand
            :data-test-selector-expanded="currentTabExpanded(tabModel)"
            class="side-actions-tree toggle-tree"
            text
            small
            :disabled="_loading"
            @click="currentTabExpandAction(tabModel)"
          >
            <span v-text="currentTabExpandLabelAction(tabModel)" />
          </v-btn>
        </section>

        <slot
          v-if="menuConfig.showActionSlot"
          name="sideActions"
          v-bind="{
            menuOptions: menuOptions,
            currentTab: tabModel,
            callbackCloseMenu: forceCloseMenu,
          }"
        ></slot>
      </div>

      <template v-if="!hasItens(menuOptions)">
        <template v-if="_loading">
          <LoadingSlot
            :slotConfig="{ name: 'global', path: 'global' }"
            :highlightQuantity="highlightQuantity"
            :itensComponentsName="itensComponent.name"
          ></LoadingSlot>
        </template>
      </template>

      <v-tabs-items
        v-else
        v-model="tabModel"
        data-test-selector-tabs-container
        class="tabs-container"
      >
        <template v-if="_loading">
          <LoadingSlot
            :slotConfig="{ name: 'tab', path: 'tab' }"
            :highlightQuantity="highlightQuantity"
            :itensComponentsName="itensComponent.name"
          ></LoadingSlot>
        </template>

        <template v-for="(item, index) in _menuOptions">
          <v-tab-item
            :data-test-selector-tab-content="item.value"
            :value="item.value"
            :key="`option.${item.value}.${index}`"
            class="tab-container"
          >
            <template v-if="_loading">
              <slot
                :name="`loading.option.${item.value}`"
                v-bind="{ highlightQuantity }"
              >
                <template v-if="!hasItens(item.items)">
                  <LoadingSlot
                    :slotConfig="{ name: item.value, path: item.value }"
                    :highlightQuantity="highlightQuantity"
                    :itensComponentsName="itensComponent.name"
                  ></LoadingSlot>
                </template>
              </slot>
            </template>

            <v-card
              :data-test-selector-content="item.value"
              class="content tab-card-content"
              color="transparent"
              height="auto"
              :min-height="'2.0625rem'"
              :max-height="`${highlightQuantity * 2.0625}rem`"
              flat
              v-on:scroll.passive="handleInfiniteScroll"
            >
              <TreeView
                v-if="item.type === 'treeview'"
                :ref="item.value"
                :selectedItem="_selectedItem"
                :persistent="persistent"
                :items="item.items"
                :tab="item.value"
                :multiple="multiple"
                :search="inputSearch"
                :highlightQuantity="highlightQuantity"
                :alwayExpand="alwayExpand"
                :hideElements="hideElements"
                :submitMode="_inputConfig.submitMode"
                :showAvatar="_inputConfig.showAvatar"
                :selectHierarchy="selectHierarchy"
                @loading="handleLocalLoading"
                @submit="updateSelectedItem($event)"
                @expanded="blockExpandTree = false"
                @expand:item="expandTreeItem($event)"
              ></TreeView>

              <ListView
                v-if="item.type === 'listview'"
                :ref="item.value"
                :selectedItem="_selectedItem"
                :persistent="persistent"
                :items="item.items"
                :tab="item.value"
                :multiple="multiple"
                :search="inputSearch"
                :hideElements="hideElements"
                :itensComponent="itensComponent"
                :submitMode="_inputConfig.submitMode"
                :showAvatar="_inputConfig.showAvatar"
                :showIcon="_inputConfig.showIcon"
                @loading="handleLocalLoading"
                @submit="updateSelectedItem($event)"
              ></ListView>

              <div v-if="item.type === 'slot'">
                <slot
                  :name="item.value"
                  v-bind="{
                    menuOptions: menuOptions,
                    currentTab: tabModel,
                    callbackCloseMenu: forceCloseMenu,
                  }"
                />
              </div>
            </v-card>
          </v-tab-item>
        </template>
      </v-tabs-items>

      <v-card-actions
        data-test-selector-footer
        class="footer"
        v-if="_menuConfig.showFooter"
      >
        <slot
          name="footer"
          v-bind="{
            menuOptions: menuOptions,
            currentTab: tabModel,
            callbackCloseMenu: forceCloseMenu,
          }"
        ></slot>
      </v-card-actions>
    </v-card>
  </RightMenu>
</template>

<script>
import RightMenu from '@/components/ItemSelector/Parts/RightMenu/RightMenu.vue'
import InputField from '@/components/ItemSelector/Parts/InputField/InputField.vue'
import TreeView from '@/components/ItemSelector/Parts/TreeView/TreeView.vue'
import ListView from '@/components/ItemSelector/Parts/ListView/ListView.vue'
import LoadingSlot from '@/components/ItemSelector/Parts/LoadingSlot/LoadingSlot.vue'

export default {
  name: 'ItemSelector',
  components: {
    InputField,
    TreeView,
    ListView,
    RightMenu,
    LoadingSlot,
  },

  data() {
    return {
      menuDrop: false,
      selectedItem: null,
      valid: true,

      localLoading: false,
      holdSpamInfinityScroll: false,

      searchGlobal: '',
      inputSearch: '',
      inputSearchInside: '',
      searchInsiderInterval: null,

      tabs: [],
      tabModel: '',

      mirrorExpandTree: [],
      blockExpandTree: false,
    }
  },

  props: {
    infinityScroll: {
      type: Boolean,
      default: false,
    },

    currentValue: null, // value to start component ex: { data: [element or elements], origin: value of tab }

    readonly: {
      type: Boolean,
      default: false, // enable readonly mode
    },

    multiple: {
      type: Boolean,
      default: false, // enable multiple selection
    },

    persistent: {
      type: Boolean,
      default: false, // block to not deselect element
    },

    fillWidth: {
      type: Boolean,
      default: true, // set width 100% on input
    },

    simultaneous: {
      type: Boolean,
      default: false,
    },

    alwayExpand: {
      type: Boolean,
      default: false,
    },

    watchCurrent: {
      type: Boolean,
      default: false, // enable watch current value
    },

    localSearch: {
      type: Boolean,
      default: false, // enable local array search (for when you don't have api for search)
    },

    highlightQuantity: {
      type: Number,
      default: 4, // sets the number of items highlighted in the listing
    },

    loading: {
      type: Boolean,
      default: false, // enable loading
    },

    hideElements: {
      type: Array,
      default: () => [], // hide elements on lists
    },

    showElements: {
      type: Boolean,
      default: false, // show selected elements on lists
    },

    itensComponent: {
      type: Object,
      default: () => ({
        name: 'node-item',
      }), // component to show on lists
    },

    menuConfig: {
      type: Object,
      default: () => ({
        attach: false,
        zIndex: 1999,
        align: 'left',
        showTabs: true,
        showInsiderSearch: false,
        showFooter: false,
        hideSideActions: false,
        showActionSlot: false,
      }),
    },

    menuOptions: {
      type: Array,
      default: () => [],
      required: true,
      /** array of tabs ex:
       * [
       *    { // tab
       *      value: 'identifier_of_tab',
       *      label: 'label of tab',
       *      type: 'treeview' || 'listview',
       *      items: [ {elements to select}, ... ]
       *    },
       *    ...
       * ]
       **/
    },

    inputStyle: {
      type: String,
      default: '',
    },

    inputConfig: {
      type: Object,
      default: () => ({
        closeOnSelect: false,
        hasTooltip: true,
        showAvatar: true,
        showIcon: false,
        submitMode: false,
        subtleMode: false,
        highlight: false,
        showCaretArrow: true,
        clearable: false,
        rules: [],
        label: '',
        tooltipConfig: {},
      }),
    },

    menuProps: {
      type: Object,
      default: () => ({}),
    },

    selectHierarchy: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _loading() {
      return this.isLoading(this.localLoading, this.loading)
    },

    _currentTabTree() {
      return this.currentTabTree(this.menuOptions, this.tabModel)
    },

    _selectedItem() {
      return this.currentTabSelectedItems(this.selectedItem, this.tabModel)
    },

    _menuOptions() {
      return this.menuOptions.map((option) => {
        return {
          ...option,
          items: this.getMenuOptionItems(option),
        }
      })
    },

    _menuConfig() {
      return {
        attach: false,
        zIndex: 1999,
        align: 'left',
        showTabs: true,
        showInsiderSearch: false,
        showFooter: false,
        ...this.menuConfig,
      }
    },

    _inputConfig() {
      return {
        closeOnSelect: false,
        hasTooltip: true,
        showAvatar: true,
        showIcon: false,
        submitMode: false,
        subtleMode: false,
        highlight: false,
        showCaretArrow: true,
        clearable: false,
        rules: [],
        label: '',
        tooltipConfig: {},
        ...this.inputConfig,
      }
    },
    _BindMeOnMenu() {
      return this.genBindMeOnMenu()
    },
  },

  watch: {
    currentValue(value) {
      if (!this.watchCurrent) {
        return
      }

      if (value) {
        this.setCurrentValue(value)
      }
    },

    valid() {
      this.$refs.menu.updatePosition()
    },

    _loading() {
      this.$refs.menu.updatePosition()
      this.holdSpamInfinityScroll = false
    },

    menuOptions: {
      handler() {
        this.handleDefaultTabModel(true)
      },
      deep: true,
      immediate: true,
    },
  },

  beforeMount() {
    this.handleDefaultTabModel()
  },

  mounted() {
    if (this.currentValue) {
      this.setCurrentValue(this.currentValue)
    }

    this.handleDefaultTabModel()
  },

  methods: {
    currentTabTree(menuOptions, tabModel) {
      if (!Array.isArray(menuOptions)) {
        return null
      }

      const element = menuOptions.find(
        (menu) => menu.value === tabModel && menu.type === 'treeview'
      )

      return element || null
    },

    currentTabSelectedItems(selectedItem, tabModel) {
      if (!selectedItem || typeof selectedItem !== 'object') {
        return null
      }

      if (Array.isArray(selectedItem?.[tabModel]?.data)) {
        return selectedItem[tabModel]
      }

      return null
    },

    handleInfiniteScroll(params) {
      if (!this.infinityScroll) {
        return false
      }

      const pixelTolerance = 5
      const currentPositionScroll =
        params.target.clientHeight + params.target.scrollTop + pixelTolerance
      const hasCurrentPositionPassedScrollableContent =
        currentPositionScroll >= params.target.scrollHeight

      if (hasCurrentPositionPassedScrollableContent) {
        this.confirmInfinityScroll()
      }
    },

    confirmInfinityScroll() {
      if (this.holdSpamInfinityScroll) {
        return
      }

      this.holdSpamInfinityScroll = true

      const payload = {
        origin: this.tabModel,
        search: this.searchGlobal,
      }

      this.$emit('infinity:scroll', payload)
    },

    forceHoldInfinityScroll(value = false) {
      this.holdSpamInfinityScroll = value
    },

    handleDefaultTabModel(checkIfExist = false) {
      if (!this.hasItens(this.menuOptions)) {
        this.tabs = []
        this.tabModel = null
        return
      }

      this.tabs = this.menuOptions.map((e) => e.value)

      if (checkIfExist && this.tabs.includes(this.tabModel)) {
        return
      }

      if (this.tabs.length > 0) {
        this.tabModel = this.tabs[0]
      }
    },

    setSelectedItem(value) {
      if (!this.simultaneous) {
        this.selectedItem = {
          [this.tabModel]: value,
        }

        return
      }

      if (!this.selectedItem || typeof this.selectedItem !== 'object') {
        this.selectedItem = {}
      }

      this.selectedItem[this.tabModel] = value
    },

    setCurrentValue(value) {
      this.tabModel = value.origin
      this.setSelectedItem(value)
    },

    updateSelectedItem(event) {
      const payload = this.genPayloadUpdateItem(event)

      this.$emit('update:item', payload)
      this.postUpdateSelectedItem(event)
    },

    expandTreeItem(event) {
      this.$emit('expand:item', event)
    },

    postUpdateSelectedItem(event) {
      if (!this._inputConfig.submitMode) {
        this.setSelectedItem(event)
      }

      this.closeSubmitOnSelect()
      this.closeOnSelect()
      this.resetSearchOnSelect()
    },

    closeSubmitOnSelect() {
      if (this._inputConfig.submitMode) {
        this.menuDrop = false
      }
    },

    closeOnSelect() {
      if (this._inputConfig.closeOnSelect) {
        this.menuDrop = false
      }
    },

    forceCloseMenu() {
      this.menuDrop = false
    },

    resetSearchOnSelect() {
      if (!this.multiple) {
        this.inputSearchInside = ''
        this.inputSearch = ''
        this.searchGlobal = ''
      }
    },

    genPayloadUpdateItem(event) {
      if (!event || !Array.isArray(event?.data)) {
        return null
      }

      const data = this.multiple ? event.data : event.data?.[0]

      if (this.simultaneous && BlobEvent) {
        return {
          data: data,
          origin: event.origin,
        }
      }

      if (this.simultaneous && !BlobEvent) {
        return null
      }

      return data
    },

    /**
     * @param {Object} menuOption
     * @param {String} menuOption.value
     * @param {Array} menuOption.items
     * @return {Array}
     */
    getMenuOptionItems(menuOption) {
      const shouldDisplayItems = this.showElements

      const tabModel = menuOption.value
      const isValidItems = this.hasItens(this.selectedItem?.[tabModel]?.data)

      if (shouldDisplayItems && isValidItems) {
        /**
         * @type {Array} items
         */
        const items = this.selectedItem[tabModel].data

        const idsOfItemsAlreadyInTabModel = items.map((item) => item?.id)
        return items.concat(
          menuOption.items.filter(
            (item) => !idsOfItemsAlreadyInTabModel.includes(item?.id)
          )
        )
      }

      return menuOption.items
    },

    expandCurrentTree() {
      if (this.blockExpandTree || this.menuOptions.length < 1) {
        return false
      }

      this.blockExpandTree = true
      if (this.$refs?.[this.tabModel]) {
        this.$refs[this.tabModel][0].handleOpenAll()
      }

      this.mirrorExpandTree.push(this.tabModel)
    },

    retractCurrentTree() {
      if (this.blockExpandTree || this.menuOptions.length < 1) {
        return false
      }

      if (this.$refs?.[this.tabModel]) {
        this.$refs[this.tabModel][0].handleCloseAll()
      }

      const index = this.mirrorExpandTree.findIndex(
        (model) => model === this.tabModel
      )
      this.mirrorExpandTree.splice(index, 1)
    },

    currentTabExpanded(tabModel) {
      return this.mirrorExpandTree.includes(tabModel)
    },
    currentTabExpandAction(tabModel) {
      if (this.currentTabExpanded(tabModel)) {
        this.retractCurrentTree()
        return
      }

      this.expandCurrentTree()
    },
    currentTabExpandLabelAction(tabModel) {
      if (this.currentTabExpanded(tabModel)) {
        return this.$t('itemSelector.retract')
      }

      return this.$t('itemSelector.expand')
    },

    handleInsideSearch(event) {
      if (this.searchInsiderInterval) {
        clearTimeout(this.searchInsiderInterval)
      }

      this.searchInsiderInterval = setTimeout(() => {
        this.handleSearch(event)
      }, 500)
    },
    handleSearch(event) {
      this.menuDrop = true

      this.searchGlobal = event

      if (this.localSearch) {
        this.inputSearch = event
        return
      }

      this.$emit('search:item', event)
    },

    focusTab(value) {
      setTimeout(() => {
        this.$refs?.inputField?.$refs?.input?.focus()
      }, 100)

      this.$emit('focus:tab', value)
      this.resetSearchOnFocus()
    },

    focusInput() {
      this.$emit('focus:input')
      this.resetSearchOnFocus()

      setTimeout(() => {
        this.menuDrop = true
      }, 500)
    },

    resetSearchOnFocus() {
      this.inputSearchInside = ''
      this.inputSearch = ''
      this.searchGlobal = ''
    },

    blurInput() {
      this.$emit('blur:input')
    },

    handleClear() {
      const hasItens = this.hasItens(this._selectedItem?.data)

      if (hasItens) {
        this.updateSelectedItem(null)
        this.$emit('cleaned:input')
      }
    },
    handleError(isValid) {
      this.valid = isValid
    },

    validate() {
      const hasItens = this.hasItens(this._selectedItem?.data)

      this.valid = hasItens
      return this.valid
    },

    manualValidate() {
      if (!this.persistent && this._inputConfig.rules.length === 0) {
        return true
      }

      let isValid = this.validate()
      return isValid
    },

    forceValidate() {
      /**
       * validate (force = false, value?: any): boolean
       */
      this.$refs?.inputField?.$refs?.input?.validate(true)
    },

    resetValidate() {
      this.$refs?.inputField?.$refs?.input?.resetValidation()
    },

    genBindMeOnMenu() {
      const VBind = {
        ...this.menuProps,
      }

      return VBind
    },

    handleLocalLoading(forcedValue = null) {
      const hasForcedValue = typeof forcedValue === 'boolean'
      this.localLoading = hasForcedValue ? forcedValue : !this.localLoading
    },

    isLoading(localLoading, loading) {
      return localLoading || loading
    },

    hasItens(data) {
      return Array.isArray(data) && data.length > 0
    },

    setTab(value) {
      this.tabModel = value
      this.resetSearchOnFocus()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
