function constructParams(keysFields, data) {
  const params = {}
  if (typeof data !== 'object') return params
  Object.keys(data).forEach((key) => {
    const validates = [
      typeof data[key] !== 'undefined',
      data[key] !== null,
      data[key] !== '',
    ]

    if (keysFields.includes(key) && validates.every(Boolean)) {
      Object.assign(params, { [key]: data[key] })
    }
  })

  return params
}

function extractSignal(data) {
  if (typeof data !== 'object') return {}
  if (typeof data.signal === 'undefined' || data.signal === null) return {}

  return { signal: data.signal }
}

module.exports = {
  constructParams,
  extractSignal,
}
