<template>
  <figure class="bar-chart">
    <div class="chart">
      <v-tooltip
        v-for="(item, index) in _frequenciesOnColorsOrder"
        :key="index"
        bottom
        :disabled="!frequencies.length"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="blocks"
            v-bind="attrs"
            v-on="on"
            :style="{
              width: item.val + '%',
              backgroundColor: item.colors,
            }"
          >
            <span
              v-if="item.name"
              class="legend no-answer"
              v-text="item.name"
            ></span>

            <span
              v-else
              class="legend"
              v-text="Math.trunc(item.val) + '%'"
            ></span>
          </div>
        </template>

        <div>
          <span v-text="getFrequencyTooltip(item.val) + '%'"></span>
        </div>
      </v-tooltip>
    </div>
  </figure>
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'QuestionProgressBar',
  props: {
    frequencies: {
      type: Array,
      default: () => [],
      required: true,
    },

    ProgressBarColorRules: {
      type: Array,
      default: () => [
        { rule: null, color: variables.blueColor3 },
        { rule: -2, color: variables.redColor },
        { rule: -1, color: variables.redColor2 },
        { rule: 0, color: variables.yellowColor },
        { rule: 1, color: variables.greenColor },
        { rule: 2, color: variables.greenColor2 },
      ],
    },

    NoFrequency: {
      type: Object,
      default: function () {
        return {
          colors: variables.gray2,
          val: 100,
          name: this.$t('questions.noAnswer'),
        }
      },
    },
  },

  computed: {
    _frequenciesOnColorsOrder() {
      if (!Array.isArray(this.frequencies) || this.frequencies.length === 0) {
        return [this.NoFrequency]
      }

      const frenquenciesByColors = this.ProgressBarColorRules.flatMap(
        (ColorRule) => {
          const frequencies = this.frequencies.filter(
            (item) => item.score === ColorRule.rule
          )

          return frequencies.map((item) => ({
            colors: ColorRule.color,
            val: item.frequency * 100,
          }))
        }
      ).filter(Boolean)

      return frenquenciesByColors
    },
  },

  methods: {
    getFrequencyTooltip(value) {
      const regex = /^-?\d+(?:\.\d{0,2})?/

      const match = value.toString().match(regex)
      return match ? match[0] : ''
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
