<template>
  <div
    :class="`d-flex flex-column align-center justify-center full-width flex-wrap
      ${sm ? 'sm' : ''}`"
  >
    <!--Accessibility-->
    <span
      class="accessibility-hide-text"
      tabindex="0"
      v-text="$t('answerRadioCards.accessibility.help1')"
    />

    <!--CardsContainer-->
    <div class="d-flex justify-center">
      <!--CardComponent-->
      <div
        data-test-card-answer
        v-for="(item, index) of items"
        :key="index"
        :class="`card-component d-flex flex-column mx-1
        ${!readOnly ? 'clickable' : ''}
        ${isSelected(item.score) ? 'selected' : ''}`"
        @click="setScore(item.score, true)"
      >
        <!--CardImage-->
        <v-img class="card-image" :src="item.image" />
        <!--Label-->
        <div class="card-label d-flex align-center justify-center fill-height">
          <span
            v-text="item.text"
            tabindex="0"
            role="button"
            :aria-pressed="isSelected(item.score).toString()"
            @keydown.space.enter="setScore(item.score, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnswerRadioCards',
  props: {
    sm: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
    init: {
      type: Number,
    },
    items: {
      // [{ image: '...', answerID: 'answer1a', score: -2, text: 'Discordo'},...]
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      score: this.init || null,
    }
  },
  mounted() {
    this.setScore(this.init)
  },
  methods: {
    setScore(score, next) {
      if (this.readOnly) return
      if (!this.items.some((e) => e.score == score)) return

      this.score = score

      this.$emit('input', this.buildEmit())

      if (next) this.$emit('select', this.buildEmit())
    },
    buildEmit() {
      return {
        value: this.score,
        score: this.score,
        answerID: this.getAnswerID(this.score),
      }
    },
    getAnswerID(s) {
      const item = this.items.find((e) => e.score == s)
      if (!item) return

      return item['answerID']
    },
    isSelected(score) {
      return this.score == score
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
