<template>
  <div id="benchmark-page" class="page-container" data-test-benchmark-page>
    <PageNavigation
      @rankingScaleClick="handleBenchmarkRankingDialog"
    ></PageNavigation>
    <router-view
      :groupID="groupID"
      :variationPeriod="variationPeriod"
      data-test-benchmark-page
    ></router-view>
  </div>
</template>

<script>
import PageNavigation from '@/pages/EngagementPage/Tabs/Benchmark/Partials/PageNavigation/PageNavigation.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'BenchmarkPage',
  components: {
    PageNavigation,
  },
  props: {
    groupID: {
      type: String,
      default: '',
    },
    variationPeriod: {
      type: String,
      default: 'real-time',
    },
  },
  methods: {
    ...mapMutations(['handleBenchmarkRankingDialog']),
  },
  created() {
    this.$store.commit('setCurrentTab', 'BenchmarkPage')
  },
}
</script>
