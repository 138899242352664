<template>
  <div id="pillars-of-engagement-group" data-test-pillars-loader>
    <div>
      <div class="header-container">
        <v-row>
          <v-col class="history">
            <label
              class="history-title"
              v-text="$t('pillarsOfEngagementGroup.card.title')"
            />
            <div>
              <HowToBeginAndWatchAVideo videoType="climate.pillars" />
            </div>
          </v-col>
        </v-row>

        <v-row class="subtitle">
          <span>{{ $t('pillarsOfEngagementGroup.card.subtitle') }}</span>
        </v-row>
      </div>
    </div>

    <v-divider class="custom-divider"></v-divider>

    <v-row v-for="(item, i) in 5" class="row-loader" :key="i" no-gutters>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          type="image"
          class="card-loader rounded-lg"
          max-height="96px"
        />
      </v-col>

      <v-col v-if="$vuetify.breakpoint.smAndUp" sm="6">
        <v-skeleton-loader
          type="image"
          class="card-loader rounded-lg"
          max-height="96px"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HowToBeginAndWatchAVideo from '@/components/HowToBeginAndWatchAVideo/HowToBeginAndWatchAVideo.vue'

export default {
  components: {
    HowToBeginAndWatchAVideo,
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
