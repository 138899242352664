<template>
  <div id="list-opinions">
    <div v-if="loading" class="mt-2">
      <div class="ma-3" v-for="item in [1, 2, 3, 4]" :key="item">
        <v-skeleton-loader type="text" width="15rem" />
        <v-skeleton-loader type="text" width="40rem" />
        <v-skeleton-loader type="text" width="20rem" />
        <v-divider class="opinion-message-divider" />
      </div>
    </div>
    <ul v-else class="opinion-list">
      <template v-for="opinion in data">
        <li
          data-test-opinion-item
          :key="opinion.id"
          class="opinion-item"
          :class="{
            'opinion-message-closed': opinion.closed,
            'opinion-message-read': opinion.isRead,
            'opinion-message-unread': !opinion.isRead,
          }"
        >
          <div class="d-flex align-center justify-space-between">
            <div class="opinion-body">
              <div class="d-flex align-center">
                <div class="avatar-box">
                  <Avatar
                    v-if="opinion.closed"
                    :src="
                      require(`@/assets/images/OpinionsConversation/fi-br-check.svg`)
                    "
                    :displayAttr="
                      getAvatarSvgConfig('closed-icon', 'closed', '16', '1')
                    "
                    size="8px"
                  />
                  <Avatar
                    v-else-if="normalizedAnonymous(opinion.lastMessage)"
                    :src="
                      require(`@/assets/images/OpinionsConversation/anonymous.svg`)
                    "
                    :displayAttr="
                      getAvatarSvgConfig(
                        'anonymous-icon',
                        'anonymous',
                        '22',
                        '1'
                      )
                    "
                    size="12px"
                  />
                  <Avatar
                    v-else
                    :src="opinion.lastMessage.photo"
                    :text="opinion.lastMessage.name"
                    :color="getAvatarIdToColors[`${opinion.lastMessage.id}`]"
                    :displayAttr="{ alt: 'user' }"
                    size="16px"
                  />
                </div>

                <div class="opinion-message-title">
                  {{ opinion.lastMessage.name }}
                </div>

                <i
                  aria-hidden="true"
                  class="fi fi-rr-calendar opinion-message-calendar"
                ></i>
                <div class="opinion-message-date">
                  {{ dateText(opinion.lastMessage.date) }}
                </div>
              </div>

              <div class="opinion-content-text">
                <div class="opinion-message-text">
                  {{ opinion.lastMessage.message }}
                </div>
              </div>

              <div class="opinion-item-bottom">
                <div class="opinion-message-group">
                  <i aria-hidden="true" class="fi fi-sr-users"></i>
                  <span>{{ opinion.group.name }}</span>
                </div>

                <div class="opinion-message-metrics">
                  <div
                    class="opinion-message-metrics-chip"
                    v-for="(message, index) in metricsAndTags(opinion)"
                    :key="message + index"
                  >
                    <i aria-hidden="true" class="fi fi-rr-label"></i>
                    <span>{{ message }}</span>
                  </div>
                </div>

                <div class="opinion-message-mini-actions">
                  <v-tooltip
                    v-if="opinion.tags.length > 1"
                    top
                    open-on-click
                    allowOverflow
                    offsetOverflow
                    v-bind="metricTooltipAttrLine(opinion).position"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="opinion-metric-extended"
                      >
                        <v-img
                          max-width="8"
                          max-height="8"
                          aspect-ratio="1"
                          contain
                          class="metric-icon"
                          :src="
                            require(`@/assets/images/OpinionsConversation/fi-rr-menu-dots.svg`)
                          "
                          alt="metric-icon"
                        />
                      </div>
                    </template>
                    <div class="tooltip-right">
                      {{ metricTooltipAttrLine(opinion).text }}
                    </div>
                  </v-tooltip>

                  <OpinionTagMenu
                    :small="true"
                    :tags="tags"
                    :opinionTags="opinion.tags"
                    :opinionID="opinion.id"
                    @handleTagsInfiniteScroll="
                      $emit('handleTagsInfiniteScroll', $event)
                    "
                    @handleAddTag="$emit('handleAddTag', $event)"
                    @handleRemoveTag="$emit('handleRemoveTag', $event)"
                    @handleCreateTag="$emit('handleCreateTag', $event)"
                  />

                  <i
                    v-if="opinion.isPositive"
                    aria-hidden="true"
                    class="fi fi-rr-thumbs-up opinion-message-positive"
                  ></i>
                  <i
                    v-else
                    aria-hidden="true"
                    class="fi fi-rr-thumbs-down opinion-message-negative"
                  ></i>
                  <span class="opinion-message-total-replies">
                    ({{ opinion.totalReplies }})
                    {{
                      $t(
                        'engagementPage.opinions.cards.cardListOpinions.replies'
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="opinion-option" @click="handleOpenSidebar(opinion)">
              <i
                aria-hidden="true"
                class="fi fi-rr-comment opinion-option-button"
              ></i>
            </div>
          </div>
          <v-divider class="opinion-message-divider" />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import OpinionTagMenu from '@/components/OpinionTagMenu/OpinionTagMenu'
import Avatar from '@/components/Avatar/Avatar.vue'

export default {
  name: 'ListOpinions',
  components: {
    OpinionTagMenu,
    Avatar,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    tags: {
      type: Object,
    },
  },
  methods: {
    dateText(date) {
      const locale = this.$t(
        'engagementPage.opinions.cards.cardListOpinions.locale'
      )
      const dateFormat = this.$t(
        'engagementPage.opinions.cards.cardListOpinions.dateFormat'
      )
      return moment(date, dateFormat).locale(locale).fromNow()
    },
    metricsAndTags(opinion) {
      const metricsAndTags = []

      if (!opinion.metric && !opinion.submetric) {
        const openOpinion = this.$t(
          'engagementPage.opinions.cards.cardListOpinions.openOpinion'
        )
        metricsAndTags.push(openOpinion)
      } else {
        metricsAndTags.push(opinion.metric.label)
        metricsAndTags.push(opinion.submetric.label)
      }

      if (opinion.tags.length > 0) {
        const mobile =
          this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
        const tags = opinion.tags.slice(0, mobile ? 1 : opinion.tags.length)
        tags.forEach((tag) => {
          metricsAndTags.push(tag.label)
        })
      }
      return metricsAndTags
    },
    metricTooltipAttrLine(opinion) {
      let maxWidth = 263
      let nudgeLeft = 6
      if (this.$vuetify.breakpoint.width <= 540) {
        nudgeLeft = 40
        maxWidth = 200
      }

      let fullTags = []
      opinion.tags.forEach((tag) => {
        fullTags.push(tag.label)
      })

      return {
        position: {
          nudgeLeft,
          maxWidth,
        },
        text: fullTags.join(', '),
      }
    },
    normalizedAnonymous(lastMessage) {
      return (
        lastMessage.name ===
        this.$t('engagementPage.opinions.cards.opinionList.anonymous')
      )
    },
    handleOpenSidebar(opinion) {
      this.$emit('handleOpenSidebar', opinion)
    },
    getAvatarSvgConfig(aClass, alt, maxWidth, aspRatio, contain = true) {
      return {
        class: aClass,
        alt,
        'max-width': maxWidth,
        'aspect-ratio': aspRatio,
        contain: contain,
      }
    },
  },
  computed: {
    ...mapGetters(['getAvatarIdToColors']),
  },
  watch: {
    data() {
      const colores = this.$vuetify.theme.themes.light.treemapColors

      this.data.map((opinion) => {
        if (!opinion.lastMessage.id) return

        const idm = opinion.lastMessage.id
        const idx = Math.floor(Math.random() * colores.length)

        this.$store.commit('setAvatarIdToColors', {
          idx: idm,
          color: colores[idx],
        })
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
