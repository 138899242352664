<template>
  <div id="empty-opinions-tip">
    <div class="ma-8 d-flex align-center justify-center flex-column">
      <embed
        :src="$t('engagementPage.opinions.cards.emptyOpinionsTip.video')"
        width="60%"
        height="400"
      />
      <div class="tip-title mt-4">
        {{ $t('engagementPage.opinions.cards.emptyOpinionsTip.title') }}
      </div>
      <div @click="goToHelpPage" class="tip-link mt-3">
        {{ $t('engagementPage.opinions.cards.emptyOpinionsTip.helpInfo') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyOpinionsTip',
  methods: {
    goToHelpPage() {
      const link = this.$t(
        'engagementPage.opinions.cards.emptyOpinionsTip.helpInfoLink'
      )
      window.open(link, '_blank')
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
