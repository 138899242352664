export default [
  {
    id: '61a993c1faa15c00288d25e4',
    title: 'Reuniões em movimento',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/Blog-Post-Como-felicidade-no-trabalho-pode-aumentar-a-produtividade-do-seu-time-300x169.jpg',
    metrics: [{ id: 'happiness', label: 'Felicidade' }],
  },
  {
    id: '61a993c1faa15c00288d25e2',
    title: 'Planos de carreira',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/vestir-a-camisa-300x169.jpg',
    metrics: [{ id: 'ambassadorship', label: 'Vestir a camisa' }],
  },
  {
    id: '61a993c1faa15c002881225e4',
    title: 'Reunião Mensal de Time',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/satisfacao-300x169.jpg',
    metrics: [{ id: 'satisfaction', label: 'Satisfação' }],
  },
  {
    id: '61a993c1faa15c00288d25e1',
    title: 'Reuniões em movimento',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/rel-equipe-300x169.jpg',
    metrics: [
      { id: 'managerRelationship', label: 'Relacionamento com o gestor' },
    ],
  },
  {
    id: '61a993c1faa15c00288d25e0',
    title: 'Aprendizagem entre pares',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/rel-pares-300x169.jpg',
    metrics: [
      { id: 'peersRelationship', label: 'Relacionamento com a equipe' },
    ],
  },
  {
    id: '61a993c1faa15c00288d25ef',
    title: 'Reconhecimento a partir de valores',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/reconhecimento-300x169.jpg',
    metrics: [{ id: 'recognition', label: 'Reconhecimento' }],
  },
  {
    id: '61a993c1faa15c00288d25e6',
    title: 'Reuniões 1-a-1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/reuniao-1-1-300x169.png',
    metrics: [
      { id: 'feedback', label: 'Feedback' },
      { id: 'happiness', label: 'Felicidade' },
    ],
  },
  {
    id: '61a993c1faa15c00288d25e9',
    title: 'Planos de carreira',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/satisfacao2-300x169.jpg',
    metrics: [{ id: 'personalGrowth', label: 'Crescimento pessoal' }],
  },
  {
    id: '61a993c1faa15c00288d25e8',
    title: 'Dia silencioso',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    image:
      'https://static.teamculture.com.br/recommendations/dia-silencioso-300x169.jpg',
    metrics: [{ id: 'wellness', label: 'Bem-estar' }],
  },
]
