<template>
  <div
    id="participation-page"
    data-test-participation-page
    class="card-container"
  >
    <CardParticipationReport>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <CardInvitedCollaborators
            :invited="participation.invited"
            :startDate="participation.startDate"
            :detailLink="_detailLink"
            :loading="loading.participation || loading.variation"
            data-test-invited-collaborators
          ></CardInvitedCollaborators>
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <CardConfirmed
            :confirmed="participation.confirmed"
            :invited="participation.invited"
            :detailLink="_detailLink"
            :variation="variation.confirmed"
            :variationText="_variationText"
            :loading="loading.participation || loading.variation"
            data-test-confirmed
          ></CardConfirmed>
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <CardResponders
            :responders="participation.responders"
            :invited="participation.confirmed"
            :detailLink="_detailLink"
            :variation="variation.responders"
            :variationText="_variationText"
            :loading="loading.participation || loading.variation"
            data-test-responders
          ></CardResponders>
        </v-col>
      </v-row>
    </CardParticipationReport>

    <v-row class="mt-1">
      <v-col sm="12" md="4" lg="4">
        <CardInvitedRespondersRate
          :invitedRespondersRate="participation.invitedRespondersRate"
          :invited="participation.invited"
          :responders="participation.responders"
          :surveyCycle="participation.surveyCycle"
          :variation="variation.invitedRespondersRate"
          :variationText="_variationText"
          :loading="loading.participation || loading.variation"
        ></CardInvitedRespondersRate>
      </v-col>

      <v-col sm="12" md="8" lg="8">
        <CardParticipationHistory
          type="invited"
          :metrics="history.invited"
          :dates="history.dates"
          :loading="loading.history"
        ></CardParticipationHistory>
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col sm="12" md="4" lg="4">
        <CardConfirmedRespondersRate
          :confirmedRespondersRate="participation.confirmedRespondersRate"
          :confirmed="participation.confirmed"
          :responders="participation.responders"
          :surveyCycle="participation.surveyCycle"
          :variation="variation.confirmedRespondersRate"
          :variationText="_variationText"
          :loading="loading.participation || loading.variation"
        ></CardConfirmedRespondersRate>
      </v-col>

      <v-col sm="12" md="8" lg="8">
        <CardParticipationHistory
          type="confirmed"
          :metrics="history.confirmed"
          :dates="history.dates"
          :loading="loading.history"
        ></CardParticipationHistory>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CardConfirmed from './Partials/CardConfirmed/CardConfirmed.vue'
import CardParticipationHistory from './Partials/CardParticipationHistory/CardParticipationHistory.vue'
import CardInvitedCollaborators from './Partials/CardInvitedCollaborators/CardInvitedCollaborators.vue'
import CardParticipationReport from './Partials/CardParticipationReport/CardParticipationReport.vue'
import CardConfirmedRespondersRate from './Partials/CardConfirmedRespondersRate/CardConfirmedRespondersRate.vue'

import CardInvitedRespondersRate from './Partials/CardInvitedRespondersRate/CardInvitedRespondersRate.vue'
import CardResponders from './Partials/CardResponders/CardResponders.vue'
import {
  getEngagementParticipation,
  getParticipationResultsHistory,
  getVariation,
} from '@/service/participation'

export default {
  name: 'ParticipationPage',
  components: {
    CardInvitedCollaborators,
    CardParticipationReport,
    CardConfirmedRespondersRate,
    CardInvitedRespondersRate,
    CardParticipationHistory,
    CardConfirmed,
    CardResponders,
  },
  props: {
    groupID: {
      type: String,
      default: '',
    },
    indicatorID: {
      type: String,
      default: 'participation',
    },
    variationPeriod: {
      type: String,
      default: 'real-time',
    },
  },

  data() {
    return {
      loading: {
        participation: true,
        history: true,
        variation: true,
      },
      participation: {
        startDate: '',
        surveyCycle: 0,
        responders: 0,
        confirmed: 0,
        confirmedRespondersRate: 0,
        invited: 0,
        invitedRespondersRate: 0,
      },
      history: {
        invited: [],
        confirmed: [],
        dates: [],
      },
      variation: {},
    }
  },

  computed: {
    _detailLink() {
      if (this.groupID) {
        return '/management/groups'
      } else {
        return '/management/people'
      }
    },

    _variationText() {
      const variationTextMap = {
        'real-time': this.$t('engagementPage.variationTextDays', { days: 7 }),
        'last-30': this.$t('engagementPage.variationTextDays', { days: 30 }),
        'last-90': this.$t('engagementPage.variationTextMonths', { months: 3 }),
        'last-180': this.$t('engagementPage.variationTextMonths', {
          months: 6,
        }),
        'last-360': this.$t('engagementPage.variationTextMonths', {
          months: 12,
        }),
        all: this.$t('engagementPage.variationTextAll'),
      }

      const variation = variationTextMap[this.variationPeriod]
      return variation || ''
    },
  },

  methods: {
    async fetchParticipation(groupID = this.groupID) {
      this.loading.participation = true

      return await getEngagementParticipation(groupID)
        .then(({ data }) => {
          this.participation = data
        })
        .finally(() => {
          this.loading.participation = false
        })
    },
    async fetchParticipationResultsHistory(groupID = this.groupID) {
      this.loading.history = true

      return await getParticipationResultsHistory(groupID)
        .then(({ data }) => {
          if (!data?.dates) {
            this.history = { invited: [], confirmed: [], dates: [] }
            return
          }

          this.history = {
            invited: [
              {
                id: 'invited',
                name: this.$t('cardParticipationHistory.label.invited'),
                scores: data.invitedRespondersRate,
              },
            ],
            confirmed: [
              {
                id: 'confirmed',
                name: this.$t('cardParticipationHistory.label.confirmed'),
                scores: data.confirmedRespondersRate,
              },
            ],
            dates: data.dates,
          }
        })
        .finally(() => {
          this.loading.history = false
        })
    },
    async fetchVariation(
      groupID = this.groupID,
      period = this.variationPeriod
    ) {
      this.loading.variation = true

      return await getVariation({
        groupID,
        period,
      })
        .then(({ data }) => {
          this.variation = data
        })
        .finally(() => {
          this.loading.variation = false
        })
    },

    initializeFetch() {
      this.fetchParticipation()
      this.fetchParticipationResultsHistory()
      this.fetchVariation()
    },
  },

  mounted() {
    this.initializeFetch()
  },

  created() {
    this.$store.commit('setCurrentTab', 'ParticipationPage')
  },

  watch: {
    groupID() {
      this.initializeFetch()
    },

    variationPeriod() {
      this.fetchVariation()
    },
  },
}
</script>

<style lang="scss" scoped>
.main-cards {
  display: flex;
  gap: 16px;
}

.width-33 {
  width: 33%;
}
</style>
