function search(node, key, value) {
  if (node[key] == value) return node

  for (const child of node.children || []) {
    const result = search(child, key, value)
    if (result) return result
  }

  return null
}

module.exports = { search }
