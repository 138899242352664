<template>
  <div>
    <v-card
      id="column"
      :class="`${!column.inactive || 'inactive-column'} column-card no-select`"
      height="9rem"
      width="4rem"
      :disabled="column.inactive"
      outlined
      @click.stop="submit()"
    >
      <div class="d-flex fill-height justify-center">
        <div id="content" class="vertical d-flex align-center">
          <!-- children icon -->
          <section
            v-if="!!column.parents && column.parents.length > 0"
            class="parent-icon"
          >
            <v-icon
              v-if="open"
              class="opened-icon"
              size="14"
              :color="_handleInactive"
            >
              fi fi-rr-arrow-small-right
            </v-icon>

            <v-icon
              v-else
              class="vertical-icon closed-icon"
              size="14"
              :color="_handleInactive"
            >
              mdi-subdirectory-arrow-right
            </v-icon>
          </section>

          <!-- label -->
          <span
            :class="`card-label ${_handleInactive}--text`"
            data-test-column-name
          >
            {{ column.name }}
          </span>

          <v-spacer />

          <!-- expand icon -->
          <v-icon
            v-if="column.children && column.children.length > 0"
            :color="_handleInactive"
            class="expand-icon mt-1"
            x-small
          >
            {{ open ? 'fi fi-rr-caret-right' : 'fi fi-rr-caret-left' }}
          </v-icon>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    column: {},

    open: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _handleInactive() {
      return this.column.inactive ? 'neutral6' : 'neutral4'
    },
  },

  methods: {
    submit() {
      if (!this.column.children || this.column.children.length < 1) return

      if (!this.open)
        this.$emit('reveal-children', {
          parentId: this.column.id,
          children: this.column.children,
        })
      else this.$emit('hide-children', this.column.id)

      this.$emit('handle-open')
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
