var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"0","data-test-ranking-card":_vm.type}},[_c('v-card-text',{staticClass:"card-header"},[_c('div',{staticClass:"benchmark-page-title",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.pillarsItens.length)?_c('div',{staticClass:"select-pillars"},[_c('ItemSelector',{attrs:{"data-test-select-pillars":"","inputConfig":{
          label: _vm.$t('engagementPage.benchmarkCards.pillarSelect'),
          showAvatar: false,
          closeOnSelect: true,
        },"menuConfig":{
          attach: true,
          showTabs: false,
        },"menuOptions":[
          {
            value: 'pillars',
            label: _vm.$t('engagementPage.benchmarkCards.pillarSelect'),
            type: 'listview',
            items: _vm.pillarsItens,
          },
        ],"currentValue":{
          data: [
            _vm.pillarsItens.find((item) => item.id === _vm.currentPillar),
          ].filter(Boolean),
          origin: 'pillars',
        },"localSearch":true,"persistent":true,"watchCurrent":true},on:{"update:item":function($event){return _vm.handleSelectPillar($event)}}})],1):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1",class:{
      blocked: !_vm.ranking.length,
    },attrs:{"data-test-table-ranking":_vm.type,"items":_vm._ranking,"headers":_vm.headers,"item-class":_vm.calcDataTableRowClasses,"hide-default-footer":true,"items-per-page":11},scopedSlots:_vm._u([{key:`item.marketSegment`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.getMarketSegmentText(value))+" ")]}},{key:`item.teamSize`,fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.getTeamSizeText(value))+" ")]}},{key:`item.variation`,fn:function({ value }){return [_c('Variation',{attrs:{"variation":parseInt(value),"iconConfig":_vm.variationIconConfig,"hideMinusSign":"","short":"","xxs":""}})]}}],null,true)}),(!_vm.ranking.length)?_c('div',{staticClass:"blocked-text",attrs:{"data-test-empty-rank-text":""}},[_c('div',{domProps:{"textContent":_vm._s(
        _vm.$t(
          'engagementPage.benchmarkCards.titleEmptyStageRankingBenchmark.title'
        )
      )}}),_c('div',[_c('a',{attrs:{"target":"_blank","href":_vm.$t('engagementPage.benchmarkCards.rankingBenchmarkHowWorks.link')},domProps:{"textContent":_vm._s(
          _vm.$t('engagementPage.benchmarkCards.rankingBenchmarkHowWorks.title')
        )}})])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }