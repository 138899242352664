<template>
  <section class="content" data-test-custom-input>
    <div
      id="custom-input"
      :style="{ position: 'relative' }"
      :class="{ 'error--text': stateError }"
    >
      <div :class="{ bordered: _hasBorder, unbordered: !_hasBorder }">
        <div v-if="value !== '' || stateError" class="input-label d-flex">
          <div
            data-test-custom-input-label-with-highlight
            v-if="_hasHighlight"
            class="highlight-pulse"
          ></div>
          <span v-text="label"></span>
        </div>

        <div class="input-presented" v-if="readonly && showTextPresented">
          <TruncatedArea
            :zIndex="99999"
            :refText="refTruncatedAreaPresentedText"
            tooltipOnHover
          >
            <template #outside="{ setHover }">
              <div
                ref="presentedText"
                data-test-custom-input-truncated
                class="truncated-text text-on-hover"
                :class="_hasHighlight ? 'highlight' : ''"
                @mouseover="setHover(true)"
                @mouseleave="setHover(false)"
                v-text="value"
              ></div>
            </template>

            <template #default>
              <span data-test-custom-input-tooltip v-text="value"></span>
            </template>
          </TruncatedArea>
        </div>

        <div class="input-container d-flex">
          <input
            data-test-custom-input-input
            data-test-input
            v-bind:value="value"
            v-mask="mask"
            class="input"
            :class="{ 'input-resized': suffixIcon !== '' }"
            :type="type"
            :placeholder="label"
            :readonly="readonly"
            @input="handleInput"
            @mouseover="handleMouseOver"
            @mouseout="handleMouseOut"
            @focusout="handleMouseOut"
          />

          <slot
            name="suffix"
            v-bind="{
              handleSuffixClick: handleSuffixClick,
              stateSuffixToggle: stateSuffixToggle,
              setValue: setValue,
              value: value,
            }"
          >
            <div
              data-test-custom-input-suffix
              v-if="suffixIcon !== ''"
              class="input-suffix"
              :class="{
                'input-suffix-action': suffixAction,
                'input-suffix--active': stateSuffixToggle && suffixAction,
              }"
              @click="handleSuffixClick"
            >
              <i :title="suffixTitle" :class="suffixIcon"></i>
            </div>
          </slot>
        </div>
      </div>

      <div class="input-options" v-if="showOptions">
        <div
          class="input-options-title"
          v-text="words('suggestionTitle')"
        ></div>

        <div
          data-test-custom-input-option
          v-for="(option, optionId) in options"
          :key="optionId"
          class="input-option"
          @click="handleOptionClick(option)"
          v-text="option.name"
        ></div>
      </div>
    </div>

    <div
      data-test-custom-input-error
      v-if="stateError && showTextMandatory"
      class="error-message mt-2"
      v-text="words('requiredField')"
    ></div>
  </section>
</template>

<script>
import TruncatedArea from '@/components/TruncatedArea/TruncatedArea.vue'

export default {
  name: 'CustomInput',
  components: {
    TruncatedArea,
  },
  props: {
    mask: {
      default: '',
    },

    viewMode: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    currentValue: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },

    suffixTitle: {
      type: String,
      default: '',
    },
    suffixIcon: {
      type: String,
      default: '',
    },
    suffixAction: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },

    showTextMandatory: {
      type: Boolean,
      default: true,
    },
    showTextPresented: {
      type: Boolean,
      default: true,
    },

    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '',

      showOptions: false,
      stateFocus: false,
      stateError: false,
      stateSuffixToggle: false,

      refTruncatedAreaPresentedText: null,
    }
  },

  watch: {
    currentValue(newValue) {
      this.setValue(newValue)
    },
  },

  computed: {
    _hasBorder() {
      return (
        (!this.viewMode || this.stateFocus || this.stateError) && !this.readonly
      )
    },
    _hasHighlight() {
      return this.highlight && this.viewMode && this.label !== ''
    },
  },

  methods: {
    validate() {
      this.stateError = this.mandatory && this.value === ''

      if (this.stateError) {
        return this.$el
      }
    },

    handleInput(event) {
      this.setValue(event.target.value)
      this.stateError = this.mandatory && this.value === ''
      this.stateSuffixToggle = false

      this.$emit('handleInput', this.value)
      if (this.options.length === 0) {
        return
      }

      const deleteContentBackward = event.inputType === 'deleteContentBackward'
      const nextIsAVariable = new RegExp(/\{\$[a-z_]*\}$/).test(
        this.value + '}'
      )

      if (nextIsAVariable && deleteContentBackward) {
        let count = this.value.length - 1

        while (count >= 0) {
          const variableStartWord = this.value[count] === '{'
          this.setValue(this.value.slice(0, -1))

          if (variableStartWord) {
            break
          }

          count--
        }
      }

      if (event.data === '/') {
        this.showOptions = true
        return
      }

      this.showOptions = false
    },

    handleOptionClick(option) {
      this.setValue(`${this.value.slice(0, -1)}{${option.name}}`)
      this.showOptions = false

      this.handleMouseOut()
    },

    handleMouseOver() {
      this.stateFocus = true
      this.$emit('focus:input')
    },
    handleMouseOut() {
      if (this.showOptions) {
        return
      }

      this.stateFocus = false
      this.$emit('blur', this.value)
    },

    words(complement) {
      return this.$t(`CustomInput.${complement}`)
    },

    setRefTruncatedAreaPresentedText() {
      this.refTruncatedAreaPresentedText = this.$refs.presentedText
    },

    handleSuffixClick() {
      this.stateSuffixToggle = !this.stateSuffixToggle
      this.$emit('click:suffix', this.stateSuffixToggle)
    },

    setValue(newValue) {
      this.value = newValue
    },
  },
  mounted() {
    this.setRefTruncatedAreaPresentedText()
    this.setValue(this.currentValue)
  },

  updated() {
    this.setRefTruncatedAreaPresentedText()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
