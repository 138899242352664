<template>
  <apexchart
    id="progress-circle"
    type="donut"
    :options="options"
    :series="series"
    height="280"
    @legendClick="legendClick"
  />
</template>

<script>
import variables from '@/styles/abstracts/_colors.scss'

export default {
  name: 'ProgressCircle',
  props: {
    legendPosition: {
      type: String,
      default: () => 'left',
    },
    data: {
      type: Array,
      default: () => [],
    },
    showLegendEmptyStage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      height: 280,
    }
  },
  created() {
    this.$root.$on('app:drawner:lateral-menu', this.handleResizeLateralMenu)
  },
  beforeDestroy() {
    this.$root.$off('app:drawner:lateral-menu', this.handleResizeLateralMenu)
  },
  computed: {
    series() {
      const emptySeries = this.data.every((e) => e.value === 0)
      if (emptySeries) {
        return this.emptyStage()
      }
      return this.data.map((e) => e.value)
    },
    mobile() {
      const dimension = this.$vuetify.breakpoint
      return dimension.xs || dimension.sm || dimension.md
    },
    options() {
      return {
        labels: this.data.map((e) => e.label),
        colors: this.data.map((e) =>
          !!e.value ? e.color : variables.neutral8
        ),
        chart: {
          height: this.height,
          type: 'donut',
        },
        legend: {
          show: true,
          position: this.mobile ? 'bottom' : this.legendPosition,
          fontSize: '12px',
          fontWeight: 400,
          fontFamily: 'poppins',
          customLegendItems: [],
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
        },
        stroke: {
          colors: undefined,
        },
        states: {
          hover: {
            filter: {
              type: 'lighten',
            },
          },
        },
      }
    },
  },
  methods: {
    emptyStage() {
      this.options.tooltip.enabled = false
      this.options.stroke.colors = [variables.neutral8]
      this.options.legend.customLegendItems = this.options.labels
      if (this.showLegendEmptyStage) {
        this.options.legend.customLegendItems = []
      }
      this.options.states.hover.filter.type = 'none'
      return [100]
    },
    legendClick(_chartContext, index, _config) {
      this.$emit('legendClick', index)
    },
    handleResizeLateralMenu() {
      setTimeout(() => {
        const circle = document.getElementById('progress-circle')
        const height = circle?.getBoundingClientRect().height

        this.height = height
      }, 200)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
