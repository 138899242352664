<template>
  <div id="correlated-pillars-dialog">
    <div class="correlated-pillars-container">
      <div class="correlated-pillars-info d-flex align-center">
        <i class="fi fi-br-interrogation mr-1 mt-1"></i>
        <span>
          {{ $t('pillarsOfEngagementGroup.card.correlatedPillars') }}
        </span>
      </div>
      <div class="tooltip-container">
        <CorrelatedPillars
          :loading="loading"
          :isBlocked="hideCorrelatedPillars"
          :correlations="correlatedPillars"
          :showCorrelatedPillars="showCorrelatedPillars"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CorrelatedPillars from '@/components/CorrelatedPillars/CorrelatedPillars.vue'

export default {
  name: 'CorrelatedPillarsDialog',
  components: {
    CorrelatedPillars,
  },
  props: {
    showCorrelatedPillars: {
      type: Boolean,
    },
    correlatedPillars: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideCorrelatedPillars: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
