<template>
  <section>
    <div class="content-section-header">
      <span class="content-section-header-title">
        {{ $t('actionPlan.sideBar.form.task.label') }}
      </span>
    </div>

    <div
      :class="`content-section-form ${taskList.length < 1 || 'task-spacing'}`"
    >
      <div v-if="loading" class="task-loading">
        <div v-for="d in 2" :key="d" class="task-loading-item">
          <v-skeleton-loader type="text" v-for="n in 3" :key="n" />
        </div>
      </div>

      <!-- list of task -->
      <v-list v-else-if="taskList.length > 0" class="task-list mb-3">
        <draggable
          v-model="taskList"
          :group="{ name: 'published', put: 'unpublished' }"
          :animation="120"
          :disabled="readonly || editMode"
        >
          <template v-for="(item, i) in taskList">
            <v-list-item
              :data-test-task-item="item._id || item.id"
              :key="item._id || item.id"
              class="task-list-item px-0"
              dense
            >
              <v-row no-gutters>
                <v-col cols="12" md="5" class="task-list-item-details">
                  <v-icon
                    v-if="!readonly && !editMode"
                    data-test-task-item-drag
                    size="12"
                    class="drag-icon"
                    v-text="'fi-rr-grid'"
                  />

                  <!-- people -->
                  <ItemSelector
                    :ref="refDynaimcOwners(item)"
                    :menuProps="{
                      offsetX: true,
                      top: true,
                    }"
                    :menuConfig="{
                      attach: true,
                      showInsiderSearch: true,
                    }"
                    :inputConfig="{
                      label: $t('dataTable.search', {
                        title: $t('newNode.details.selectors.people'),
                      }),
                    }"
                    :currentValue="{
                      data: item.assignedTo || [],
                      origin: 'peoples',
                    }"
                    :multiple="multiplePeople"
                    :menuOptions="ownersOptions"
                    :loading="ownersLoading"
                    :readonly="readonly"
                    @focus:input="selectorOwnersHandle"
                    @search:item="selectorOwnersHandle($event)"
                    @update:item="
                      handleInput('assignedTo', [...$event].filter(Boolean), i)
                    "
                  >
                    <template #default="{ focus, selectedItem }">
                      <button
                        v-ripple
                        class="selector-add-peoples"
                        :class="{
                          'with-values':
                            selectedItem &&
                            selectedItem.data &&
                            selectedItem.data.length > 0,
                          'error-btn':
                            invalidTasksPeople[item._id] ||
                            invalidTasksPeople[item.id] ||
                            false,
                        }"
                        @click="focus"
                      >
                        <AvatarGroup
                          v-if="
                            selectedItem &&
                            selectedItem.data &&
                            selectedItem.data.length > 0
                          "
                          :avatars="
                            selectedItem.data.map((e) => ({
                              id: e.id,
                              text: e.label,
                              image: e.image,
                            }))
                          "
                          :max="2"
                          :size="20"
                        />
                        <v-icon size="10" v-else>fi-rr-user-add</v-icon>
                      </button>
                    </template>
                  </ItemSelector>

                  <!-- title -->
                  <CustomInput
                    class="title"
                    :mandatory="true"
                    :showTextMandatory="false"
                    :showTextPresented="false"
                    :viewMode="true"
                    :readonly="readonly"
                    :currentValue="item.text"
                    @handleInput="handleInput('text', $event, i)"
                  />
                </v-col>

                <v-col cols="12" md="7" class="task-list-item-actions">
                  <!-- status -->
                  <v-list-item-action-text class="task-section-width-status">
                    <v-menu
                      :disabled="readonly || !canEditStatus"
                      attach
                      offset-x
                      offset-y
                      left
                      top
                      class="task-status"
                      content-class="task-status-menu"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          width="14"
                          height="14"
                          @click="form = item"
                        >
                          <v-icon
                            size="10"
                            :color="handleStatus(item.status).color"
                          >
                            mdi-circle
                          </v-icon>
                        </v-btn>

                        <div
                          class="status-picker-label"
                          :class="{ clickable: canEditStatus }"
                          v-on="on"
                          v-bind="attrs"
                        >
                          {{ handleStatus(item.status).label }}
                        </div>
                      </template>

                      <v-list class="container-list" dense>
                        <v-list-item
                          v-for="(subitem, j) in status"
                          class="container-list-item"
                          :class="{ active: subitem.value === item.status }"
                          :key="j"
                          @click="handleInput('status', subitem.value, i)"
                        >
                          <v-list-item-icon>
                            <v-icon :color="subitem.color" size="10"
                              >mdi-circle</v-icon
                            >
                          </v-list-item-icon>

                          <v-list-item-title class="label">
                            {{ subitem.label }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action-text>

                  <!-- dueDate -->
                  <v-list-item-action-text class="task-section-width-dueDate">
                    <v-menu
                      attach
                      offset-x
                      offset-y
                      left
                      top
                      :disabled="readonly"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-btn"
                          :class="{
                            'error-btn':
                              invalidTasksData[item._id] ||
                              invalidTasksData[item.id] ||
                              false,
                          }"
                          color="neutral6"
                          icon
                          outlined
                          width="14"
                          height="14"
                          @click="auxDate = item.dueDate"
                        >
                          <v-icon size="6">fi fi-rr-calendar</v-icon>
                        </v-btn>

                        <div
                          class="date-picker-label clickable"
                          :class="{
                            'error-btn':
                              invalidTasksData[item._id] ||
                              invalidTasksData[item.id] ||
                              false,
                          }"
                          v-on="on"
                        >
                          <span v-if="item.dueDate">
                            {{ formatDate(item.dueDate) }}
                          </span>

                          <span v-else>
                            {{ $t('actionPlan.sideBar.form.task.dueDate') }}
                          </span>
                        </div>
                      </template>

                      <v-date-picker
                        v-model="item.dueDate"
                        next-icon="fi-br-angle-small-right"
                        prev-icon="fi-br-angle-small-left"
                        :min="(dueDateHasMinDate && minDate) || ''"
                        :locale="$t('locale')"
                        color="primary"
                        scrollable
                        no-title
                        @input="handleInput('dueDate', $event, i)"
                      />
                    </v-menu>
                  </v-list-item-action-text>

                  <!-- delete -->
                  <v-list-item-action>
                    <v-btn
                      v-if="!readonly"
                      data-test-task-item-remove
                      class="delete-btn"
                      color="neutral6"
                      icon
                      width="12"
                      height="12"
                      @click.prevent="removeTask(item.id, i)"
                    >
                      <v-icon small>fi fi-rr-trash</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-col>
              </v-row>
            </v-list-item>
          </template>
        </draggable>
      </v-list>

      <div
        v-if="taskList.length === 0 && readonly && !loading"
        class="mb-1 task-no-data"
      >
        <span>
          {{ $t('DialogActionPlanTasks.noDataText') }}
        </span>
      </div>

      <div v-if="taskList.length === 0 && !valid && !readonly" class="mb-1">
        <span data-test-task-list-error class="error-message">
          {{ $t('actionPlan.sideBar.form.errorTaskList') }}
        </span>
      </div>

      <!-- new task -->
      <NewTask
        v-if="!readonly"
        :actionPlanID="actionPlanID"
        :editMode="editMode"
        :dueDateHasMinDate="dueDateHasMinDate"
        :multiplePeople="multiplePeople"
        :mandatoryDate="mandatoryDate"
        :mandatoryPeople="mandatoryPeople"
        @success-create="addTask($event)"
        @new-task-edit="$emit('new-task-edit', $event)"
      />
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import NewTask from '../NewTask/NewTask.vue'
import ItemSelector from '@/components/ItemSelector/ItemSelector.vue'
import AvatarGroup from '@/components/AvatarGroup/AvatarGroup.vue'
import CustomInput from '@/components/CustomInput/CustomInput.vue'

import { getPeople } from '@/service/people'

const moment = require('moment')

export default {
  components: {
    NewTask,
    ItemSelector,
    AvatarGroup,
    CustomInput,
    draggable,
  },

  data() {
    return {
      status: [
        {
          color: 'grey',
          label: this.$t('actionPlan.sideBar.form.task.status.pending'),
          value: 'pending',
        },
        {
          color: 'amber',
          label: this.$t('actionPlan.sideBar.form.task.status.progress'),
          value: 'doing',
        },
        {
          color: 'green',
          label: this.$t('actionPlan.sideBar.form.task.status.done'),
          value: 'done',
        },
      ],

      taskList: [],

      auxDate: '',
      minDate: new Date().toISOString().slice(0, 10),

      interval: null,
      valid: true,
      invalidTasksPeople: {},
      invalidTasksData: {},

      form: {
        id: '',
        text: '',
        dueDate: '',
        status: '',
      },

      ownersLoading: true,
      ownersItens: [],
    }
  },

  props: {
    actionPlanID: null,
    tasks: null,
    readonly: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    canEditStatus: {
      type: Boolean,
      default: true,
    },
    dueDateHasMinDate: {
      type: Boolean,
      default: true,
    },
    multiplePeople: {
      type: Boolean,
      default: false,
    },
    mandatoryDate: {
      type: Boolean,
      default: false,
    },
    mandatoryPeople: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ownersOptions() {
      return [
        {
          value: 'peoples',
          label: this.$t('newNode.details.selectors.people'),
          type: 'listview',
          items: this.ownersItens,
        },
      ]
    },
  },

  mounted() {
    if (!!this.tasks && Object.keys(this.tasks).length > 0)
      this.taskList = this.tasks
  },

  watch: {
    tasks(v) {
      if (v) this.taskList = v
    },
  },

  methods: {
    // submit for create mode
    submit() {
      const payload = this.taskList
        .map((item) => {
          if (item.text.trim() === '' || item.text === null) return null
          if (this.validateItemFields(item)) return null

          return {
            ...(item.id ? { id: item.id } : {}),
            text: item.text,
            dueDate: item.dueDate,
            ...(this.canEditStatus ? { status: item.status } : {}),
            assignedTo: item?.assignedTo,
          }
        })
        .filter(Boolean)

      this.$emit('submit', payload)
    },

    // submit for edit mode
    async editTask(index) {
      const item = this.taskList[index]
      if (this.validateItemFields(item)) return null
      const payload = {
        actionPlanID: this.actionPlanID,
        taskID: item.id,
        body: {
          text: item.text,
          dueDate: item.dueDate,
          status: item.status,
          assignedTo: item?.assignedTo.map((e) => ({ id: e.id })),
        },
      }

      this.$emit('edit-task', payload)
    },

    // add new task on create mode
    addTask(data) {
      this.validateItemFields(data)
      this.taskList.push(data)

      this.submit()
    },

    // delete task on edit mode
    async deleteTask(id) {
      const payload = {
        actionPlanID: this.actionPlanID,
        taskID: id,
      }

      this.$emit('delete-task', payload)
    },

    // change inputs
    changeDueDate(index, value) {
      if (value !== this.auxDate) this.auxDate = value
      else {
        this.auxDate = ''
        this.taskList[index].dueDate = null
      }
    },

    handleInput(field, value, index) {
      if (field === 'dueDate') this.changeDueDate(index, value)
      else this.taskList[index][field] = value

      if (this.editMode && ['text'].includes(field)) {
        this.handleDelayedChanges(index)
      } else {
        this.handleChanges(index)
      }
    },

    handleDelayedChanges(index) {
      if (this.interval) clearInterval(this.interval)

      this.interval = setTimeout(() => {
        this.handleChanges(index)
      }, 500)
    },

    // changes
    handleChanges(index) {
      if (!this.editMode) this.submit()
      else this.editTask(index)
    },

    // handle remove task
    removeTask(id, index) {
      if (!this.editMode) {
        this.taskList.splice(index, 1)
        this.submit()
      } else this.deleteTask(id)
    },

    // handle current status on task
    handleStatus(value) {
      if (value) return this.status.find((e) => e.value === value)
      else return this.status[0]
    },

    // validate task quantity on create mode
    validate() {
      // Remove tasks with empty text
      const shadowTasks = this.taskList.filter(
        (task) =>
          task.text.trim().length !== 0 && !this.validateItemFields(task)
      )

      if (shadowTasks.length === 0) this.valid = false
      else this.valid = true

      return this.valid
    },

    // validate task with mandatory props
    validateItemFields(item) {
      if (!item.text || item.text.trim().length === 0) return true

      if (this.mandatoryDate && !item.dueDate) {
        this.invalidTasksData[item._id || item.id] = true
      } else this.invalidTasksData[item._id || item.id] = false

      if (this.mandatoryPeople && !item.assignedTo.length) {
        this.invalidTasksPeople[item._id || item.id] = true
      } else this.invalidTasksPeople[item._id || item.id] = false

      return (
        this.invalidTasksData[item._id || item.id] ||
        this.invalidTasksPeople[item._id || item.id]
      )
    },

    // format date to DD/MM/YYYY
    formatDate(date) {
      if (!date) return null

      return moment(date).format(this.$t('actionPlan.sideBar.form.dateFormat'))
    },

    // handle owners
    refDynaimcOwners(item) {
      return `owners${item._id || item.id}`
    },

    // Selectors Getters
    async selectorOwnersHandle(search = '') {
      this.ownersLoading = true

      const payload = {
        limit: 20,
        offset: 0,
        name: search || '',
      }

      await getPeople(payload).then((res) => {
        this.ownersItens = res.data.map((e) => {
          return {
            id: e.id,
            label: e.name,
            image: e.photo,
            email: e.email,
            groups: e.groups?.map((e) => e.name),
          }
        })
      })

      this.ownersLoading = false
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
