<template>
  <div class="group-care-content">
    <div v-if="_isMultipleSelectGroup" data-test-multigroup-select>
      <ItemSelector
        ref="comparisonItemSelector"
        :currentValue="_groupIds"
        :menuOptions="_groupSelectorOptions"
        :highlightQuantity="8"
        :inputConfig="{
          label: $t('groupCare.multipleFieldLabel'),
          clearable: true,
        }"
        :menuConfig="{
          showFooter: true,
          hideSideActions: true,
          showActionSlot: true,
        }"
        :localSearch="true"
        :multiple="true"
        :fillWidth="true"
        :selectHierarchy="preferences.stagedSelectHierarchy"
        @update:item="handleMultiGroupSelect($event)"
      >
        <template v-slot:sideActions="{ currentTab }">
          <div
            class="preferences-label"
            data-test-preferences-button
            @click="
              handleTab(
                currentTab,
                currentTab === 'preferences' ? 'groups' : 'preferences'
              )
            "
          >
            {{
              $t(
                `groupCare.${
                  currentTab === 'preferences' ? 'back' : 'preferences'
                }`
              )
            }}
          </div>
        </template>

        <template v-slot:preferences>
          <div>
            <section class="preferences-content">
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="preferences.selectHierarchy"
                  hide-details
                />

                <span class="checkbox-label">
                  {{ $t('groupCare.checkboxHierarchy') }}
                </span>
              </div>
            </section>
          </div>
        </template>

        <template v-slot:footer="{ currentTab }">
          <v-row class="footer-item-selector" no-gutters>
            <v-col cols="6">
              <v-btn
                class="text-none rounded-0"
                height="2rem"
                block
                text
                tile
                @click="handleClear(currentTab)"
              >
                <span class="bttn-label">
                  {{ $t('groupCare.clear') }}
                </span>
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                data-test-button-apply
                class="text-none rounded-0"
                color="primary"
                height="2rem"
                block
                tile
                @click="handleApply(currentTab)"
              >
                <span class="bttn-label">
                  {{ $t('groupCare.apply') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ItemSelector>
    </div>

    <div v-show="!_isMultipleSelectGroup" data-test-group-select>
      <ItemSelector
        ref="singleItemSelector"
        :menuOptions="_groupSelectorOptions"
        :currentValue="selectedGroup"
        :highlightQuantity="8"
        :inputConfig="{ label: $t('groupCare.fieldLabel') }"
        :persistent="true"
        :localSearch="true"
        :watchCurrent="true"
        :fillWidth="true"
        @update:item="handleGroupSelect($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ItemSelector from '@/components/ItemSelector/ItemSelector.vue'

export default {
  components: {
    ItemSelector,
  },

  data() {
    return {
      selectedMultiGroups: [],
      preferences: {
        selectHierarchy: false,
        stagedSelectHierarchy: false,
      },
    }
  },

  props: {
    selectedGroup: null,

    fullGroups: {
      type: Array,
      required: true,
    },

    groups: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState(['groupId', 'groupIds']),

    _isMultipleSelectGroup() {
      return this.$route.name == 'ComparisonPage'
    },

    _groupItems() {
      if (this._isMultipleSelectGroup) {
        return this.fullGroups.filter((el) => !!el?.id)
      }

      return this.groups
    },

    _groupSelectorOptions() {
      return [
        {
          type: 'treeview',
          value: 'groups',
          label: this.$t('groupCare.groups'),
          items: this._groupItems,
        },
        {
          type: 'slot',
          value: 'preferences',
          label: this.$t('groupCare.preferences'),
          hideTab: true,
        },
      ]
    },

    _groupIds() {
      const groups = this.fetchGroupIds(this.fullGroups)

      return {
        data: groups,
        origin: 'groups',
      }
    },
  },

  methods: {
    ...mapMutations(['setGroupSelected', 'setGroupId', 'setGroupIds']),

    handleApply(tab) {
      if (tab === 'preferences') {
        this.preferences.stagedSelectHierarchy =
          this.preferences.selectHierarchy

        this.handleTab(null, 'groups')
        return
      }

      this.setGroupIds(this.selectedMultiGroups.map((el) => el.id))
      this.$refs.comparisonItemSelector.forceCloseMenu()
    },

    handleClear(tab) {
      if (tab === 'preferences') {
        this.preferences.selectHierarchy = false
        this.preferences.stagedSelectHierarchy = false

        return
      }

      this.$refs.comparisonItemSelector.$refs.inputField.handleClear()
    },

    handleGroupSelect(choice) {
      this.setGroupSelected([choice])

      if (choice !== null) {
        this.setGroupId(choice ? choice.id : null)
      }
    },

    handleMultiGroupSelect(event) {
      this.setGroupSelected(event)

      this.selectedMultiGroups = event || []
    },

    handleTab(currentTab, newTab) {
      this.$refs.comparisonItemSelector.setTab(newTab)

      if (
        currentTab === 'preferences' &&
        this.preferences.stagedSelectHierarchy !==
          this.preferences.selectHierarchy
      ) {
        this.preferences.selectHierarchy =
          this.preferences.stagedSelectHierarchy
      }
    },

    fetchGroupIds(array) {
      let data = []

      array.forEach((el) => {
        if (this.groupIds.includes(el.id)) data.push(el)

        if (el.children && el.children.length) {
          data = [...data, ...this.fetchGroupIds(el.children)]
        }
      })

      return data
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
