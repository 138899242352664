<template>
  <v-card
    data-test-culture-code-card
    class="card-content culture-code-card"
    :class="{ 'border-card': isActive }"
  >
    <v-card-subtitle v-if="isActive" class="selected-culture">
      <span v-text="$t('cultureManagement.card.cardActive')" />
    </v-card-subtitle>

    <v-img
      class="culture-code-image"
      min-width="200px"
      min-height="100px"
      :src="
        require('@/assets/images/managementCulture/card-cultural-content-image.png')
      "
      :alt="$t('cultureManagement.title')"
    />

    <v-card-subtitle class="culture-subtitle">
      <span v-text="$t('cultureManagement.card.culturalMaturity')" />
      <span class="level">
        <span v-text="'-'" />
        <span v-text="$t('engagementPage.culture.cultureCodeCard.level')" />
        <span
          v-text="
            culture.maturityLevelAverage ? culture.maturityLevelAverage : '0'
          "
        />
      </span>
    </v-card-subtitle>

    <v-card-title class="culture-name">
      <span v-text="culture.name" />
    </v-card-title>

    <v-divider />

    <div class="culture-values-groups">
      <v-chip class="chip-value-pink">
        <span
          v-text="culture.values.length + $t('cultureManagement.card.values')"
        />
      </v-chip>

      <v-chip class="chip-group-gray">
        <span
          v-text="culture.groups.length + $t('cultureManagement.card.groups')"
        />
      </v-chip>
    </div>

    <v-divider />

    <div class="culture-stats">
      <div class="cultural-alignment">
        <v-img
          max-height="20px"
          max-width="20px"
          width="20px"
          :src="
            require('@/assets/images/managementCulture/icons/cultural-alignment.svg')
          "
          :alt="$t('engagementPage.behaviorClassification.culturalAlignment')"
        />
        <span
          class="label"
          v-text="
            culture.culturalAlignmentAverage !== null
              ? culture.culturalAlignmentAverage + '%'
              : '--'
          "
        />
      </div>

      <v-divider vertical />

      <div class="unfavorable-culture">
        <v-img
          max-height="20px"
          max-width="20px"
          width="20px"
          :src="
            require('@/assets/images/managementCulture/icons/unfavorable-culture.svg')
          "
          :alt="$t('engagementPage.behaviorClassification.unfavorableCulture')"
        />
        <span
          class="label"
          v-text="
            culture.unfavorableCultureAverage !== null
              ? culture.unfavorableCultureAverage + '%'
              : '--'
          "
        />
      </div>
    </div>

    <v-divider />

    <div class="more-information">
      <v-btn
        data-test-btn-more-details
        depressed
        text
        small
        :ripple="false"
        @click="$emit('clicked')"
        class="btn-more-information"
        >{{ $t('cultureManagement.card.moreDetails') }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    culture: {
      type: [Array, Object],
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
