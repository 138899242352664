<template>
  <div id="pillars-of-engagement-group" data-test-selected-loader class="card">
    <v-skeleton-loader type="article" class="title-loader" />

    <v-divider class="custom-divider"></v-divider>

    <div v-for="(item, i) in 3" :key="i" class="graph-loader">
      <v-skeleton-loader type="image" class="graph" />

      <v-skeleton-loader type="paragraph@2" />
    </div>

    <v-row class="row-loader" no-gutters>
      <v-col v-for="(item, i) in 6" :key="i" class="card-loader" cols="6">
        <v-skeleton-loader type="image" max-height="3rem" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style src="./style.scss" lang="scss" scoped></style>
