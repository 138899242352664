var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test-selector-input-field":""}},[(_vm.highlight && _vm.readonly)?_c('div',{staticClass:"highlight-pulse",attrs:{"data-test-selector-input-field-highlight":""}}):_vm._e(),_c('v-hover',{attrs:{"disabled":_vm.readonly},model:{value:(_vm.isHover),callback:function ($$v) {_vm.isHover=$$v},expression:"isHover"}},[_c('v-combobox',{ref:"input",class:[_vm._classStyle],attrs:{"data-test-input-field-responsible":"","search-input":_vm.search,"label":_vm.label,"readonly":_vm.readonly,"clearable":_vm.clearable,"append-icon":_vm._appendIcon,"rules":_vm.rules,"hide-details":"auto","color":"black","hide-selected":"","small-chips":"","multiple":"","outlined":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":[function($event){_vm.search=$event},function($event){return _vm.handleSearch($event)}],"keydown":function($event){return _vm.focus()},"focus":function($event){return _vm.focus()},"blur":function($event){return _vm.handleBlur()},"click":function($event){$event.stopPropagation();return _vm.focus()},"click:clear":function($event){$event.stopPropagation();return _vm.handleClear()},"update:error":function($event){return _vm.handleError($event)}},scopedSlots:_vm._u([{key:"selection",fn:function({ attrs, item }){return [_c('div',_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.focus()}}},'div',attrs,false),[(item && _vm._handleSelection && !_vm.isFocused)?_c('div',{staticClass:"d-flex"},[(_vm.showIcon)?_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.typeIcon))]):_vm._e(),_c('v-chip',{class:{
                'custom-chip-colored': _vm.multiple,
                'custom-chip-transparent': !_vm.multiple,
              },on:{"click":function($event){$event.stopPropagation();return _vm.focus()}}},[(_vm.showAvatar)?_c('AvatarComponent',{attrs:{"id":item.id || '',"text":item.label,"src":item.image || '',"size":'24'}}):_vm._e(),_c('span',{ref:"chiplabel",class:{
                  'custom-chip-label': _vm.multiple,
                  'field-item': !_vm.multiple,
                  highlight: _vm.highlight && _vm.readonly,
                },attrs:{"data-test-input-field-selection-label":""},domProps:{"textContent":_vm._s(item.label)}}),(_vm.hasTooltip && _vm.isTruncated)?_c('v-tooltip',_vm._b({attrs:{"open-on-hover":"","z-index":9999},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"ml-1 item-label",attrs:{"data-test-input-field-truncated":""}},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},"v-tooltip",_vm._d({},[_vm._tooltipConfig.labelTooltip.position,true])),[_c('div',{staticClass:"item-label-tooltip"},[_c('span',{domProps:{"textContent":_vm._s(item.label)}})])]):_vm._e()],1),(_vm.hasTooltip && _vm.presentedMoreItens.length > 0)?_c('v-tooltip',_vm._b({attrs:{"open-on-hover":"","z-index":9999},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"more-items-chip",class:{
                    'custom-chip-colored': _vm.multiple,
                    'custom-chip-transparent': !_vm.multiple,
                  },attrs:{"data-test-input-field-more-itens":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"custom-chip-label",class:{ highlight: _vm.highlight && _vm.readonly },domProps:{"textContent":_vm._s(`+ ${_vm.presentedMoreItens.length}`)}})])]}}],null,true)},"v-tooltip",_vm._d({},[_vm._tooltipConfig.moreTooltip.position,true])),[_c('div',{staticClass:"more-itens-tooltip d-flex"},[_c('span',{staticClass:"mx-2",domProps:{"textContent":_vm._s(_vm._moreItensTooltip)}})])]):_vm._e()],1):_vm._e()])]}},{key:"append",fn:function(){return [(_vm.showCaretArrow)?_c('v-icon',{staticClass:"mt-1",attrs:{"color":_vm._caretArrowColor,"small":""},domProps:{"textContent":_vm._s(_vm._caretArrowIcon)}}):_vm._e()]},proxy:true}]),model:{value:(_vm.presentedModel),callback:function ($$v) {_vm.presentedModel=$$v},expression:"presentedModel"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }