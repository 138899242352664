<template>
  <div class="d-flex flex-column fill-height">
    <SurveyHeader
      v-if="showHeader && !this.showIframeForm"
      :client-logo="account.logo"
    />
    <router-view
      class="full"
      :blocked="blocked"
      :showIframeForm="showIframeForm"
      data-test-survey-page
    />
    <SurveyFooter v-if="showFooter && !this.showIframeForm" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SurveyHeader from '@/pages/SurveyPage/Partials/SurveyHeader/SurveyHeader'
import SurveyFooter from '@/pages/SurveyPage/Partials/SurveyFooter/SurveyFooter'

export default {
  name: 'SurveyPage',
  components: { SurveyHeader, SurveyFooter },

  computed: {
    ...mapState(['survey', 'account', 'client']),
    ...mapGetters(['getIsSignedIn']),

    state: function () {
      return this.survey.state
    },
    finished: function () {
      return this.survey.finished
    },
    blocked: function () {
      return this.survey.blocked
    },

    hasPermission: function () {
      const permission = 'engagement.survey'
      const { permissions } = this.client

      return permissions.includes(permission)
    },
  },

  watch: {
    state: function (e) {
      this.showHeader = this.showHeaderStates.includes(e)
      this.showFooter = this.showFooterStates.includes(e)

      const st = this.checkState()
      if (st && st != e) this.setSurveyState({ state: st })

      this.setRoute()
    },

    getIsSignedIn(value) {
      if (value) {
        const st = this.checkState()
        if (st && st != this.state) this.setSurveyState({ state: st })

        this.setRoute()
      }
    },
  },

  data: () => ({
    showIframeForm: false,
    showHeader: true,
    showFooter: false,
    showHeaderStates: [
      'init',
      'steps',
      'feedback',
      'finish',
      'forbidden',
      'other',
    ],
    showFooterStates: [
      'steps',
      'feedback',
      // 'other'
    ],
    stateRoutes: {
      init: 'SurveyInit',
      steps: 'SurveySteps',
      feedback: 'SurveyFeedback',
      finish: 'SurveyFinish',
      forbidden: 'SurveyForbidden',
      other: 'SurveyOther',
    },
  }),

  mounted() {
    const { show = false } = this.$route.query
    this.showIframeForm = show === 'true'
  },

  methods: {
    ...mapActions(['setSurveyState']),

    setRoute() {
      const fromRoute = this.$route.name
      const toRoute = this.stateRoutes[this.state]

      if (fromRoute != toRoute) this.$router.push({ name: toRoute })
    },
    checkState() {
      if (!this.hasPermission) return 'forbidden'
      if (this.blocked || this.finished) return 'finish'
    },
  },
}
</script>

<style scoped src="./style.scss" lang="scss" />
