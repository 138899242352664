import { render, staticRenderFns } from "./BehaviorClassification.vue?vue&type=template&id=e03e62fc&scoped=true&"
import script from "./BehaviorClassification.vue?vue&type=script&lang=js&"
export * from "./BehaviorClassification.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=e03e62fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e03e62fc",
  null
  
)

export default component.exports