<template>
  <v-navigation-drawer
    v-model="showDrawer"
    :class="{ 'drawer-index': _isMobileBreakpoint }"
    app
    right
    mobile-breakpoint="sm"
    floating
    :permanent="isPermanent"
    :mini-variant="isMiniVariant"
    :temporary="isTemporary"
    disable-resize-watcher
    width="297"
    mini-variant-width="65"
    @input="toggleInput"
    data-test-lateral-menu
  >
    <v-btn
      class="btn-toggle-mini"
      @click="toggleMiniVariant"
      data-test-toggle-min-lateral-menu
      icon
    >
      <i :class="['fi', _iconToggleBtn]"></i>
    </v-btn>

    <slot :isMini="isMiniVariant"></slot>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'LateralMenu',
  data() {
    return {
      isMiniVariant: true,
      showDrawer: false,
      isPermanent: true,
      isTemporary: false,
    }
  },
  created() {
    if (!this._isMobileBreakpoint) {
      this.showDrawer = true
    } else this.isMiniVariant = true

    this.$root.$on(
      'app:toggle:lateral-menu',
      this.listenerToggleLateralMenuEvent
    )

    this.$root.$on('lateral-menu-retract', (data) => {
      this.retractLateralMenu(data)
    })
  },
  beforeDestroy() {
    this.$root.$off(
      'app:toggle:lateral-menu',
      this.listenerToggleLateralMenuEvent
    )

    this.$root.$off('lateral-menu-retract')
  },

  computed: {
    _isMobileBreakpoint() {
      this.$root.$emit('app:drawner:lateral-menu')
      return this.$vuetify.breakpoint.smAndDown
    },
    _iconToggleBtn() {
      if (this.isMiniVariant) {
        return 'fi-rr-caret-left'
      }

      return 'fi-rr-caret-right'
    },
  },
  methods: {
    retractLateralMenu(data) {
      this.isMiniVariant = data
    },
    toggleMiniVariant() {
      this.isMiniVariant = !this.isMiniVariant
      if (this._isMobileBreakpoint) {
        this.isPermanent = false
        this.isTemporary = true
      }
    },
    listenerToggleLateralMenuEvent() {
      this.showDrawer = !this.showDrawer
    },
    toggleInput() {
      if (this._isMobileBreakpoint) {
        this.isMiniVariant = true
        this.isTemporary = false
        this.isPermanent = true
      }
    },
  },
  watch: {
    _isMobileBreakpoint() {
      if (this._isMobileBreakpoint) {
        this.isMiniVariant = true
        this.isTemporary = false
        this.isPermanent = true
      } else {
        this.isMiniVariant = false
        this.isTemporary = false
        this.isPermanent = true
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
